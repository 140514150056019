import { createSlice } from "@reduxjs/toolkit";
import { english } from "./english";
import { spanish } from "./spanish";

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: "es",
    dictionary: spanish,
  },
  reducers: {
    setLang: (state, { payload }) => {
      state.lang = payload;
      state.dictionary = payload === "es" ? spanish : english;
    },
  },
});

export const { setLang } = langSlice.actions;
