import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import queryString from "query-string";
// import DataTable from "react-data-table-component";
import {
  catalogByPart,
  ownCatalogues,
  listCatalogues,
} from "../../apis/apiCatalogues";
import { MCSelect } from "../MainComponents/Select/Select";
import { PreviewCatalogue } from "./PreviewCatalogue";
import { EditCatalogues } from "./EditCatalogues";
import { fillIfIsCatalogue } from "../JSONForms/PreviewForm/formStarter";
import { Loading } from "../Loading/String/Loading";
import { useTranslation } from "react-i18next";
import { MCTooltip } from "../MainComponents";
import { OutlineEdit, OutlineEye, OutlineEyeSlash } from "../Icon";
import { DataTable } from "../DataTable/DataTable";
import "./Catalogues.scss";

export const MyCatalogues = () => {
  const [t] = useTranslation("catalogues");

  // Variables for different options of catalogues
  const catalogueOptions = [
    {
      value: "EG",
      label: t("Catalogues.EG_catalog"),
    },
    {
      value: "OWN",
      label: t("Catalogues.my_catalogs"),
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // params in case of user currently editing catalogue and refresh the page
  const {
    catalogue: cat = null,
    data = null,
    own = null,
    sc = null,
    lbl = null,
  } = queryString.parse(location.search);
  // List of EthicsGlobal catalogues
  const [egCataloguesList, setEgCataloguesList] = useState(null);
  // List of Own catalogues
  const [ownCataloguesList, setOwnCataloguesList] = useState(null);
  // Current list of catalogues are showing
  const [selectedCatalog, setSelectedCatalog] = useState("OWN");
  // Variable to set current selected catalogues
  const [cataloguesList, setCataloguesList] = useState(null);
  // In case of user select one catalogue whether for Own or EticsGlobal, it saves here
  const [catalogue, setCatalogue] = useState(null);
  // Just for data control in CatalogueInput
  const [catData, setCatData] = useState({});
  // Selected catalogue alias
  const [alias, setAlias] = useState("");
  // For save selected catalogue status
  const [is_active, setIs_active] = useState(null);
  // For this page it have like tabs, and this variable control wich tab is active
  const [activeScreen, setActiveScreen] = useState("info"); // info || prev || edit
  // For rerender de component
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    mount();
  }, [rerender]);

  useEffect(() => {
    if (rerender) setRerender(false);
  }, [rerender]);

  /**
   * This function runs every time that catalogue suffers a change and save it
   * and the first time component renders
   */
  const mount = async () => {
    if (rerender === true) return;
    const [tempEgCatalogues, tempOwnCatalogues] = await Promise.all([
      listCatalogues(),
      ownCatalogues(),
    ]);
    setEgCataloguesList(tempEgCatalogues);
    setOwnCataloguesList(tempOwnCatalogues);
    if (sc !== null && cat !== null && own !== null && lbl !== null) {
      starWithQuerys({
        sc,
        cat,
        data,
        own,
        lbl,
        catEg: tempEgCatalogues,
        catOwn: tempOwnCatalogues,
      });
    } else {
      setCataloguesList(tempOwnCatalogues);
      setLoading(false);
    }
  };

  /**
   * This function change selected catalogues to show between Own and EthicsGlobal
   */
  const handleSelectedCatalogue = (value) => {
    setSelectedCatalog(value);
    setAlias("");
    setLoading(true);
    setCataloguesList(null);
    if (value === "EG") {
      setCataloguesList(egCataloguesList);
      setLoading(false);
    }
    if (value === "OWN") {
      setCataloguesList(ownCataloguesList);
      setLoading(false);
      // ownCatalogues().then(setCataloguesList).catch(console.log);
    }
    if (value === "") {
      setCataloguesList(null);
      setLoading(false);
    }
  };

  /**
   * Changue single selected catalogue, this runs when user select on edit or show catalogue
   */
  const changeAlias = async ({ alias, label, is_active }) => {
    setLoading(true);

    let findLocales;
    if (
      selectedCatalog === "OWN" &&
      Array.isArray(ownCataloguesList?.catalogues)
    ) {
      const catalogueInfo = ownCataloguesList.catalogues.find(
        (item) => item.alias === alias
      );
      findLocales = catalogueInfo.locales;
    } else if (
      selectedCatalog === "EG" &&
      Array.isArray(egCataloguesList?.catalogues)
    ) {
      console.log(egCataloguesList);
      const catalogueInfo = egCataloguesList.catalogues.find(
        (item) => item.code === alias
      );
      findLocales = catalogueInfo.locales;
    }

    const resp = await catalogByPart({
      is_own: selectedCatalog === "OWN" ? true : false,
      catalogue: alias,
      path: "/",
      show_all: true,
    });
    if (resp.error) return;
    let children = [...resp.data];
    let objCatalogue = {
      key: `C::EG::${alias}`,
      type: "catalog-select",
      label: label,
      placeholder: t("Catalogues.supporting_text"),
      required: false,
      sensitive: false,
      scope: "/",
      selected: "",
      isOwn: selectedCatalog === "OWN" ? true : false,
      catalogue: alias,
      locales: findLocales,
      children: children,
    };
    let objCatData = {
      [alias]: "",
      catalogue: alias,
      isOwn: false,
      sensitive: false,
    };
    setCatalogue([objCatalogue]);
    setCatData([objCatData]);
    setIs_active(is_active);
    setLoading(false);
  };

  /**
   * It runs when page is refreshing and when user edit a catalogue an save, it takes params of url for get
   * the updated catalogue
   */
  const starWithQuerys = async ({ sc, cat, data, own, lbl, catEg, catOwn }) => {
    setLoading(true);

    let findLocales;
    if (own === "y" && Array.isArray(catOwn?.catalogues)) {
      const catalogueInfo = catOwn.catalogues.find(
        (item) => item.alias === cat
      );
      findLocales = catalogueInfo.locales;
    } else if (own === "n" && Array.isArray(catEg?.catalogues)) {
      const catalogueInfo = catEg.catalogues.find((item) => item.code === cat);
      findLocales = catalogueInfo.locales;
    }

    const resp = await catalogByPart({
      is_own: own === "y" ? true : false,
      catalogue: cat,
      path: "/",
      show_all: true,
    });
    if (resp.error) return;
    let children = [...resp.data];
    let objCatalogue = {
      key: `C::EG::${cat}`,
      type: "catalog-select",
      label: lbl,
      placeholder: t("Catalogues.supporting_text"),
      required: false,
      sensitive: false,
      scope: "/",
      selected: "",
      isOwn: own === "y" ? true : false,
      catalogue: cat,
      locales: findLocales,
      children: children,
    };
    let objCatData = {
      [cat]: data || "",
      catalogue: cat,
      isOwn: false,
      sensitive: false,
    };
    const resp2 = await fillIfIsCatalogue([objCatalogue], [objCatData], true);
    setActiveScreen(sc);
    setAlias(cat);
    setSelectedCatalog(own === "y" ? "OWN" : "EG");
    setCataloguesList(own === "y" ? catOwn : catEg);
    setCatalogue(resp2);
    setCatData([objCatData]);
    setLoading(false);
  };

  /**
   * This function return how the table columns looks like
   */
  // const returnColumns = () => {
  //   return [
  //     {
  //       name: "Nombre",
  //       selector: (row) => row.label,
  //     },
  //     {
  //       name: "Accion",
  //       button: true,
  //       cell: (row) => (
  //         <MCButton
  //           label={t("catalogues.myCatalogues.info.buttons.edit")}
  //           variant="primary"
  //           outline
  //           size="sm"
  //           onClick={() =>{
  //             navigate(`?sc=edit&lbl=${row.label}&catalogue=${row.alias}&own=${selectedCatalog==="OWN"?"y":"n"}`);
  //             setActiveScreen('edit');
  //             changeAlias({ alias: row.alias, label: row.label, is_active: row.is_active });
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       name: "Vista previa",
  //       button: true,
  //       cell: (row) => (
  //         <div className="divs">
  //           <MCButton
  //             label={t("catalogues.myCatalogues.info.buttons.preview")}
  //             variant="primary"
  //             outline
  //             size="sm"
  //             onClick={() =>{
  //               setActiveScreen('prev');
  //               navigate(`?sc=prev&lbl=${row.label}&catalogue=${row.alias}&own=${selectedCatalog==="OWN"?"y":"n"}`);
  //               changeAlias({ alias: row.alias, label: row.label, is_active: row.is_active });
  //             }}
  //           />
  //         </div>
  //       ),
  //     },
  //   ];
  // };

  const actions = (row) => (
    <div className="actionBtn">
      <MCTooltip text={t("Catalogues.edit")} position="bottom">
        <button
          className="actionBtn__edit"
          onClick={() => {
            navigate(
              `?sc=edit&lbl=${row.label}&catalogue=${row.alias}&own=${
                selectedCatalog === "OWN" ? "y" : "n"
              }`
            );
            setActiveScreen("edit");
            changeAlias({
              alias: row.alias,
              label: row.label,
              is_active: row.is_active,
            });
          }}
        >
          <OutlineEdit />
        </button>
      </MCTooltip>
      <MCTooltip text={t("Catalogues.disable")} position="bottom">
        <button
          className="actionBtn__enable"
          onClick={() => {
            setActiveScreen("prev");
            navigate(
              `?sc=prev&lbl=${row.label}&catalogue=${row.alias}&own=${
                selectedCatalog === "OWN" ? "y" : "n"
              }`
            );
            changeAlias({
              alias: row.alias,
              label: row.label,
              is_active: row.is_active,
            });
          }}
        >
          <OutlineEye />
        </button>
      </MCTooltip>
    </div>
  );

  /**
   * Put the current list of catalogues in a new array, for take only the necessary keys, and change the name if is necessary
   */
  const tableInfo = cataloguesList?.catalogues.map((item, index) => ({
    id: index,
    label: item.label,
    alias: item.alias || item.code,
    is_active: item.is_active,
    locales: item.locales,
  }));

  const customTitle = {
    label: t("Catalogues.select_catalog"),
    actions: t("Catalogues.actions"),
  };

  const columns = ["label", "actions"];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {activeScreen === "info" && (
        <div className="dyTheme1 dyBorder1 rounded p-4">
          <>
            <small className="mb-1">{t("Catalogues.select_catalog")}</small>

            {/* SELECT DE LIGADOS */}
            <div style={{ width: "100%" }}>
              <MCSelect
                className="w-100 mb-1"
                options={catalogueOptions}
                onChange={(e) => handleSelectedCatalogue(e.value)}
                value={catalogueOptions.find(
                  (item) => item.value === selectedCatalog
                )}
              />
            </div>

            {/* SELECT DE CATALOGOS  */}
            {/* <DataTable
              data={tableInfo}
              columns={returnColumns()}
              defaultSortFieldId={1}
              pagination
            /> */}

            <DataTable
              columns={columns}
              data={tableInfo}
              actions={actions}
              customTitle={customTitle}
            />
          </>
        </div>
      )}

      {activeScreen === "prev" && catalogue && (
        <PreviewCatalogue
          scope={catalogue}
          data={catData}
          catalogue={catalogue[0]?.catalogue}
          isOwn={selectedCatalog === "OWN" ? true : false}
          setActiveScreen={setActiveScreen}
          alias={alias}
        />
      )}

      {activeScreen === "edit" && catalogue && (
        <EditCatalogues
          scope={catalogue}
          data={catData}
          catalogue={catalogue[0]?.catalogue}
          isOwn={selectedCatalog === "OWN" ? true : false}
          setActiveScreen={setActiveScreen}
          alias={alias}
          is_active={is_active}
          rerenderParent={() => setRerender(true)}
        />
      )}
    </>
  );
};
