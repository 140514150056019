import iconMap from "../../assets/icons/icon-map";

const Icon = ({ name, size, color, ...rest }) => {
  const EmptyIcon = () => <div />;
  const SvgIcon = iconMap[name] || EmptyIcon;

  return (
    <SvgIcon
      style={{
        width: size,
        heigth: size,
        fill: color,
      }}
      {...rest}
    />
  );
};

export default Icon;
