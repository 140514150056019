import PlanCard from './PlanCard';

const plans = [
  {
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  },
  {
    name: "Free Plan",
    description: "Todas las herramientas que necesitas para iniciar a nisi ac magna tristique lacinia",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>1 pais</li><li>1 idioma</li><li>1 centro de trabajo</li><li>10 mensajes con el denunciante</li><li>10 mensajes internos</li></ul>",
    color: "#3181FF"
  },
  {
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  },{
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  },
  {
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  },
  {
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  },
  {
    name: "Free Trial",
    description: "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration:"30 dias",
    price: "$0 pesos",
    features: "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF"
  }
]

const Plans = (props) => {

  return (
    <>
      {/* <SingleNav /> */}
      <div className="d-flex flex-wrap p-2" style={{gap:10, alignItems:'flex-start'}}>
        {plans.map((plan, idx)=>{
          return(
            <PlanCard 
              key={idx} 
              plan={plan} 
              headerColor={plan.color} 
              containerWidth={280} 
              isList={true}
              handleSelection={()=>{
                props.history.push('/sign-plan')
              }}
            />
          )
        })}
        
      </div>
    </>
  );
}
 
export default Plans;
