import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Correct import statement for useNavigate
import './styles.scss';

export const Breadcrum = ({
  className = "breadcrumb",
  icon,
  items // Pass the items prop
}) => {

  const navigate = useNavigate();

  const { gTheme } = useSelector(state => state.theme);

  const theme = gTheme !== "light" ? "dark" : "";

  return (
    <ul className={`${theme} ${className}`}>
      {items.map((item, index) => (
        <li key={index} className="item"> {/* Added key prop */}
          {item.route ? ( // Check if the item has a route
            <a onClick={() => navigate(item.route)}>
              {item.label}
            </a>
          ) : (
            <span>{item.label}</span>
          )}
        </li>
      ))}
    </ul>
  );
};
