import React, { useRef } from "react";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import { BoldCloseCircle, Paperclip } from "../../Icon";
import { MCTooltip } from "../Tooltip/Tooltip";
import "./FileUpload.scss";
import { Col, Row } from "react-bootstrap";
import { MCLabel } from "../Label/Label";
import { MCSelect } from "../Select/Select";
/**
 * A custom input for uploading multiple files
 * @param files: preview state files to upload.
 * @param setFiles: a function to set files in state.
 * @param onChange: event listening for changes when adding or deleting files
 */
export const FileUploadJustLinks = ({
    files = [],
    setFiles,
    filesToRender = [],
    setFilesToRender,
    formDataKey = "attachments",
}) => {
    const fileInputRef = useRef(null);
    const fileDivRef = useRef(null);
    const isDraggingOver = useRef(false);
    const [t] = useTranslation("components");
    // To generate formData
    const processFile = (file) => {
        const formData = new FormData();
        formData.append(formDataKey, file);
        return { file, formDataKey, formData };
    };
    // Input to load file
    const hdlFileInput = (e) => {
        const file = e.target.files;
        const newFileArray = Array.from(file).map(processFile);
        setFiles((prevFiles) => [
            ...prevFiles,
            ...newFileArray.filter(
                (newFile) =>
                    !prevFiles.some(
                        (prevFile) => prevFile.file.name === newFile.file.name
                    )
            ),
        ]);
        // fileDivRef.current.classList.remove("drag-over");
    };
    // Click into input to load file
    const hdlDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    // Erase a file uploaded
    const removeFile = (fileName) => {
        const updatedFiles = files.filter((file) => file.file.name !== fileName);
        setFiles(updatedFiles);
    };
    // Sets the file when dropped
    const hdlFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files;
        const newFileArray = Array.from(file).map(processFile);
        setFiles((prevFiles) => [
            ...prevFiles,
            ...newFileArray.filter(
                (newFile) =>
                    !prevFiles.some(
                        (prevFile) => prevFile.file.name === newFile.file.name
                    )
            ),
        ]);
        fileDivRef.current.classList.remove("drag-over");
    };
    // Drag files into input and add styles
    const hdlDragEnter = (e) => {
        e.preventDefault();
        isDraggingOver.current = true;
        fileDivRef.current.classList.add("drag-over");
    };
    // Space calculation when the mouse leave input
    const hdlDragLeave = (e) => {
        e.preventDefault();
        const target = e.target;
        const clientX = e.clientX;
        const clientY = e.clientY;
        const elementBox = target.getBoundingClientRect();

        if (
            clientX < elementBox.left - 1 ||
            clientX > elementBox.right - 1 ||
            clientY < elementBox.top - 1 ||
            clientY > elementBox.bottom - 1
        ) {
            isDraggingOver.current = false;
            fileDivRef.current.classList.remove("drag-over");
        }
    };
    // Remove styles drag and drop file
    const hdlMouseLeave = () => {
        if (isDraggingOver.current) {
            isDraggingOver.current = false;
            fileDivRef.current.classList.remove("drag-over");
        }
    };
    // Drag file with mouse and prevent default browser behavior
    const preventDefaultBehavior = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDelete = (idx) => {
        const newFiles = filesToRender.filter((_, index) => index !== idx);
        setFilesToRender(newFiles);
    };

    return (
        <Row className="fileUpload">
            {/* Preview of loaded files before to send endpoint */}
            
            {filesToRender.length > 0 && (
                <Col xl={12} className="px-1 mt-20">
                    <div className={`fileUpload__loaded-container rounded`}>
                        <>
                            <ul>
                                <li className="list-unstyled text-center">
                                    <h6>{`${t("fileUpload.uploaded_files")}:`}</h6>
                                </li>
                                {filesToRender.map((fileRender, idx) => (
                                    <li className="fileUpload__file" key={idx}>
                                        <Paperclip className="--paperClip" />
                                        <a href={fileRender.url} target="_blank" key={idx}>
                                            <small className="fileUpload__name">
                                                {fileRender.name}
                                            </small>
                                        </a>
                                   
                                    </li>
                                ))}
                            </ul>
                        </>
                    </div>
                </Col>
            )}
        </Row>
    );
};
