export const backgroundColorData = [
    'rgb( 68, 203, 103)', // Verde Claro
    'rgb( 255, 175, 0)', // Amarillo
    'rgb( 0, 158, 215)', // Celeste
    'rgb( 222, 70, 61)', // Rojo
    'rgb( 0, 123, 111)', // Verde Oscuro
    'rgb( 189, 202, 208)', // Gris
    'rgb( 216, 27, 96)', // Rasa
    'rgb( 124, 77, 255)', // Morado
    'rgb( 205, 220, 57)', // Verde Lima
    'rgb( 49, 129, 255)', // Azul
    'rgb( 255, 0, 0)', // Rojo brillante
    'rgb( 0, 255, 0)', // Verde brillante
    'rgb( 0, 0, 255)', // Azul brillante
    'rgb( 255, 0, 255)', // Magenta brillante
    'rgb( 255, 255, 0)', // Amarillo brillante
    'rgb( 0, 255, 255)', // Cian brillante
    'rgb( 128, 0, 0)', // Marrón oscuro
    'rgb( 0, 128, 0)', // Verde oscuro
    'rgb( 0, 0, 128)', // Azul oscuro
    'rgb( 128, 128, 0)', // Oliva
    'rgb( 128, 0, 128)', // Púrpura
    'rgb( 0, 128, 128)', // Verde azulado
    'rgb( 192, 192, 192)', // Plata
    'rgb( 128, 128, 128)', // Gris oscuro
    'rgb( 255, 99, 71)', // Tomate
    'rgb( 255, 127, 80)', // Coral
    'rgb( 255, 140, 0)', // Naranja oscuro
    'rgb( 255, 165, 0)', // Naranja
    'rgb( 255, 215, 0)', // Oro
    'rgb( 184, 134, 11)', // Oro viejo
    'rgb( 0, 128, 0)', // Verde
    'rgb( 173, 255, 47)', // Verde amarillo
    'rgb( 0, 255, 0)', // Lima
    'rgb( 50, 205, 50)', // Verde mar
    'rgb( 152, 251, 152)', // Verde claro
    'rgb( 0, 250, 154)', // Verde medio
    'rgb( 144, 238, 144)', // Verde pálido
    'rgb( 0, 255, 127)', // Verde primavera
    'rgb( 60, 179, 113)', // Verde marino
    'rgb( 46, 139, 87)', // Verde marino medio
    'rgb( 32, 178, 170)', // Turquesa
    'rgb( 64, 224, 208)', // Turquesa pálido
    'rgb( 72, 209, 204)', // Turquesa medio
    'rgb( 175, 238, 238)', // Cian pálido
    'rgb( 0, 206, 209)', // Cian oscuro
    'rgb( 95, 158, 160)', // Cian
    'rgb( 100, 149, 237)', // Azul acero
    'rgb( 70, 130, 180)', // Azul acero oscuro
    'rgb( 0, 0, 205)', // Azul medianoche
    'rgb( 0, 0, 139)', // Azul oscuro
    'rgb( 0, 0, 128)', // Azul marino
    
];

export const borderColorData = [
    'rgb( 68, 203, 103)', // Verde Claro
    'rgb( 255, 175, 0)', // Amarillo
    'rgb( 0, 158, 215)', // Celeste
    'rgb( 222, 70, 61)', // Rojo
    'rgb( 0, 123, 111)', // Verde Oscuro
    'rgb( 189, 202, 208)', // Gris
    'rgb( 216, 27, 96)', // Rasa
    'rgb( 124, 77, 255)', // Morado
    'rgb( 205, 220, 57)', // Verde Lima
    'rgb( 49, 129, 255)', // Azul
    'rgb( 255, 0, 0)', // Rojo brillante
    'rgb( 0, 255, 0)', // Verde brillante
    'rgb( 0, 0, 255)', // Azul brillante
    'rgb( 255, 0, 255)', // Magenta brillante
    'rgb( 255, 255, 0)', // Amarillo brillante
    'rgb( 0, 255, 255)', // Cian brillante
    'rgb( 128, 0, 0)', // Marrón oscuro
    'rgb( 0, 128, 0)', // Verde oscuro
    'rgb( 0, 0, 128)', // Azul oscuro
    'rgb( 128, 128, 0)', // Oliva
    'rgb( 128, 0, 128)', // Púrpura
    'rgb( 0, 128, 128)', // Verde azulado
    'rgb( 192, 192, 192)', // Plata
    'rgb( 128, 128, 128)', // Gris oscuro
    'rgb( 255, 99, 71)', // Tomate
    'rgb( 255, 127, 80)', // Coral
    'rgb( 255, 140, 0)', // Naranja oscuro
    'rgb( 255, 165, 0)', // Naranja
    'rgb( 255, 215, 0)', // Oro
    'rgb( 184, 134, 11)', // Oro viejo
    'rgb( 0, 128, 0)', // Verde
    'rgb( 173, 255, 47)', // Verde amarillo
    'rgb( 0, 255, 0)', // Lima
    'rgb( 50, 205, 50)', // Verde mar
    'rgb( 152, 251, 152)', // Verde claro
    'rgb( 0, 250, 154)', // Verde medio
    'rgb( 144, 238, 144)', // Verde pálido
    'rgb( 0, 255, 127)', // Verde primavera
    'rgb( 60, 179, 113)', // Verde marino
    'rgb( 46, 139, 87)', // Verde marino medio
    'rgb( 32, 178, 170)', // Turquesa
    'rgb( 64, 224, 208)', // Turquesa pálido
    'rgb( 72, 209, 204)', // Turquesa medio
    'rgb( 175, 238, 238)', // Cian pálido
    'rgb( 0, 206, 209)', // Cian oscuro
    'rgb( 95, 158, 160)', // Cian
    'rgb( 100, 149, 237)', // Azul acero
    'rgb( 70, 130, 180)', // Azul acero oscuro
    'rgb( 0, 0, 205)', // Azul medianoche
    'rgb( 0, 0, 139)', // Azul oscuro
    'rgb( 0, 0, 128)', // Azul marino
];