import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { patchInvestigation } from "../../../../../apis";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";

export const AddCause = ({ investigationAlias, formValues, toggleModal }) => {
  const [t] = useTranslation("investigation");
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [amend, setAmend] = useState("");
  const [avoid, setAvoid] = useState("");
  const [hostile, setHostile] = useState("");

  const handleSubmit = async (evt) => {
    let newForm;
    evt.preventDefault();
    if (formValues === null) {
      newForm = [];
    } else {
      newForm = _.cloneDeep(formValues);
    }
    newForm.push({
      reason,
      details,
      enviroments: {
        amend,
        avoid,
        hostile,
      },
    });
    const objToSave = {
      folder: {
        causes: newForm,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  return (
    <div>
      <Row>
        <h5>{t("General.add_cause")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.cause_origin")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_cause_origin")}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.cause_details")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_cause_details")}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.avoid")}</small>
          <MCInput
            type="text"
            placeholder=""
            value={avoid}
            onChange={(e) => setAvoid(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.amend")}</small>
          <MCInput
            type="text"
            placeholder=""
            value={amend}
            onChange={(e) => setAmend(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.hostile")}</small>
          <MCInput
            type="text"
            placeholder=""
            value={hostile}
            onChange={(e) => setHostile(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("General.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("General.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
