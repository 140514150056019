import React from "react";
import { useTranslation } from "react-i18next";
import { MCButton } from "../../../../MainComponents";
import { Col, Row } from "react-bootstrap";
import {
  parseDateToISOString2,
  validInitDates,
} from "../../../../../helpers/dateTransform";

export const Deadline = ({ toggleModal, dates }) => {
  const [t] = useTranslation("investigation");
  return (
    <div className="dyTheme1 dyBorder1 rounded p-20">
      <div>
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <h5>{t("General.promise_date")}</h5>
          <MCButton
            label={t("General.edit")}
            variant="primary"
            outline
            onClick={() => toggleModal("dates")}
          />
        </div>
        <Row className="mt-20">
          <Col lg="6" className="p-0">
            <div className="pr-lg-30">
              <small className="my-2 d-block">
                {t("General.resolution_init_date")}:
              </small>
              <h6>
                {validInitDates(dates?.will_close)
                  ? parseDateToISOString2(dates?.will_close)
                  : t("General.no_date")}
              </h6>
            </div>
          </Col>

          <Col lg="6" className="p-0">
            <div className="pl-lg-30">
              <small className="my-2 d-block">
                {t("General.resolution_end_date")}:
              </small>
              <h6>
                {validInitDates(dates?.will_finish_resolution)
                  ? parseDateToISOString2(dates?.will_finish_resolution)
                  : t("General.no_date")}
              </h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="p-0">
            <div className="pr-lg-30">
              <small className="my-2 d-block">
                {t("General.investigation_end_date")}:
              </small>
              <h6>
                {validInitDates(dates?.will_finish_investigation)
                  ? parseDateToISOString2(dates?.will_finish_investigation)
                  : t("General.no_date")}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
