import { Col, Row } from "react-bootstrap";
import { CDBBtn } from "cdbreact";
import PermitImg from "../../../assets/images/permit.png";
import { ArrowRight } from "../../Icon";
import { GeneralHeader } from "../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

export const PermitsUser = ({ vTheme }) => {
  const [t] = useTranslation("users");
  const BackTheme = {
    backgroundColor: vTheme === "light" ? "#f3f7fa" : "#141319",
  };
  const DynamicTheme = {
    backgroundColor: vTheme === "light" ? "#fff" : "#1a1d27",
  };
  return (
    <>
      <div
        className={vTheme === "light" ? "background" : "background dark-mode"}
        style={BackTheme}
      >
        <GeneralHeader title={t("Permits.user_permissions")} />

        <div className="pb-3 border-bottom">
          <Row className="mt-5">
            <Col lg="3" md="6">
              <div className="d-flex align-items-center justify-content-space-around">
                <img src={PermitImg} alt="permit" />
                <div className="ml-2">
                  <h4>{t("Permits.ethical_officer")}</h4>
                  <p className="mb-0">{t("Permits.ethical_investigator")}</p>
                </div>
              </div>
            </Col>
            <Col lg="3" md="6">
              <p>{t("Permits.permissions")}:</p>
              <h6 className="font-14">18 {t("Permits.active_permissions")}</h6>
            </Col>
            <Col lg="3" md="6">
              <p>{t("Permits.notifications")}:</p>
              <h6 className="font-14">{t("Permits.by_event")} - 10:00 horas</h6>
            </Col>
            <Col lg="3" md="6">
              <p>{t("Permits.alert_rule")}:</p>
              <h6 className="font-14">
                {t("Permits.complaint_with_more_than")} 30{" "}
                {t("Permits.days_open")}
              </h6>
            </Col>
          </Row>
        </div>
        <Row className="mt-20">
          <Col lg="6">
            <div className="p-20 mt-20" style={DynamicTheme}>
              <h6>{t("Permits.manage_compaints_actions")}</h6>
              <small className="btn athics-lignt-btn w-100 d-flex align-items-center justify-content-between font-12">
                {t("Permits.change_complaints_actions")} <ArrowRight />
              </small>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-20 mt-20" style={DynamicTheme}>
              <h6>{t("Permits.attach_investigation_files")}</h6>
              <small className="btn athics-lignt-btn w-100 d-flex align-items-center justify-content-between font-12">
                {t("Permits.upload_files_complaint_management")} <ArrowRight />
              </small>
            </div>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col lg="6">
            <div className="p-20 mt-20" style={DynamicTheme}>
              <h6>{t("Permits.close_complaint_final_comments_estimates")}</h6>
              <small className="btn athics-lignt-btn w-100 d-flex align-items-center justify-content-between font-12">
                {t("Permits.upload_files_complaint_management")} <ArrowRight />
              </small>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-20 mt-20" style={DynamicTheme}>
              <h6>{t("Permits.delete_complaints")}</h6>
              <small className="btn athics-lignt-btn w-100 d-flex align-items-center justify-content-between font-12">
                {t("Permits.move_complaint_trash_bin")} <ArrowRight />
              </small>
            </div>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col lg="6" className="permit-preview">
            <div className="p-20 mt-20" style={DynamicTheme}>
              <h6>{t("Permits.delete_complaints")}</h6>
              <small className="btn athics-lignt-btn w-100 d-flex align-items-center justify-content-between font-12">
                {t("Permits.move_complaint_trash_bin")}
                <ArrowRight />
              </small>
            </div>
            <div id="file-upload" className="text-center p-4">
              <section>
                <CDBBtn className="athics-outline-btn" outline>
                  {t("Permits.add_new_permission")}
                </CDBBtn>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
