import React from "react";
import "./Avatar.scss";
import { MCTooltip } from "../Tooltip/Tooltip";

export const AvatarLetter = ({ contact }) => {
  /**
   * @param {contact} Object
   *  The contact is obtained and the profile image is extracted.
   *  If it doesn't exist, take the first letter of the first and last name or just the contact's name.
   * @returns Profile contact avatar or the first letter of the contact's name
   */
  const getInitialLetters = (name) => {
    if (!name || name.trim() === "") {
      return "--";
    }
    const names = name.split(" ");
    if (names.length === 1) {
      return name.slice(0, 2).toUpperCase();
    } else {
      return names
        .reduce((initials, namePart) => initials + namePart[0], "")
        .slice(0, 2)
        .toUpperCase();
    }
  };
  return (
    <div>
      {/* Maybe change to contact.image, this depends on object containing the profile picture */}
      {/* (Adjust if necessary) */}
      {contact.avatar ? (
        <MCTooltip text={contact.name} position="top">
          <img
            src={contact.avatar}
            alt={contact.name}
            className="contact-image"
          />
        </MCTooltip>
      ) : (
        <MCTooltip text={contact.name} position="top">
          <div className="avatar">{getInitialLetters(contact.name)}</div>
        </MCTooltip>
      )}
    </div>
  );
};
