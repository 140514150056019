import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";
import { listExports, downloadFile, deleteFile } from "../../../apis/apiExports";
import { useState, useEffect } from "react";
import { Loading } from "../../Loading/String/Loading";
import { NoContent } from "../NoContent/NoContent";
import { CreateFileModal } from "../CreateFile/CreateFile";
import { enqueueSnackbar } from "notistack";
import { MCButton, MCLabel, MCTooltip } from "../../MainComponents";
import excelImage from "../../../assets/images/excel_image.png";
import Icon from "../../Icon/Icon";
import { useSnackbar } from "notistack";
import { EthicsModal } from "../../Modal/EthicsModal";
import "./Exports.scss";
import { Modal } from "react-bootstrap";

const PasswordComponent = ({ password }) => {
  const [t] = useTranslation("exports");
  const { enqueueSnackbar } = useSnackbar();
  // This state is used to show the password
  const [showPassword, setShowPassword] = useState(false);

  const fnCopyOnClipboard = () => {
    navigator.clipboard.writeText(password);
    enqueueSnackbar(t("Exports.copied"), { variant: "info" });
  };

  return (
    <>
      <MCLabel text={`${t("Exports.password")}:`} className="labels mb-1" />
      <div className="password-container">
        <MCTooltip position="bottom" text={t("Exports.copy_password")}>
          <div className="password" onClick={fnCopyOnClipboard}>
            <span>{showPassword ? password : "********"}</span>
          </div>
        </MCTooltip>
        <div className={`password-icon-eye ${showPassword ? "show" : ""}`}>
          <Icon
            name={showPassword ? "outline_eye_slash" : "outline_eye"}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
      </div>
    </>
  );
};

export const DetailExportables = ({ listOfExports, setListOfExports, setAreThereExports }) => {
  const [t] = useTranslation("exports");
  // This state is used to detect when a file was downloaded
  const [listDownloads, setListDownloads] = useState({});

  // This state is used to disable the download button
  const [selectedFile, setSelectedFile] = useState(null);
  
  // This state is used to disable the confirm delete excel file modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedAlias, setSelectedAlias] = useState('');

  const formatDates = (date) => {
    const objectDate = new Date(date);
    const day = objectDate.getDate();
    const month = objectDate.getMonth() + 1;
    const year = objectDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handledownloadFile = async (alias) => {
    setSelectedFile(alias);
    enqueueSnackbar(`${t("Exports.downloading")}...`, { variant: "info" });

    const response = await downloadFile(alias);

    if (response !== undefined) {
      enqueueSnackbar(t("Exports.successfully_downloaded"), {
        variant: "success",
      });

      window.open(response.data.file, "_blank");

      setListDownloads((prevState) => ({
        ...prevState,
        [alias]: response.data,
      }));
    }

    setSelectedFile(null);
  };

  const handledeleteFile = async () => {
    setSelectedFile(selectedAlias);

    const response = await deleteFile(selectedAlias);

    if (response !== undefined) {
      enqueueSnackbar(t("Exports.successfully_deleted"), {
        variant: "success",
      });

      const { data } = await listExports();
      if (data !== undefined && data.length > 0) {
        setListOfExports(data);
      } else {
        setAreThereExports(false);
      }
    }
    setShowConfirmModal(false);
  };

  const handleConfirmDelete = async (alias) => {
    await handledeleteFile();
  };
  
  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const activateModal = (alias) => {
    setSelectedAlias(alias);
    setShowConfirmModal(true);
  };

  const Confirm = () => {
    return (
      <>
        <Modal.Header closeButton>
          <h4>{t("Exports.delete_message")}</h4>
        </Modal.Header>
        <Modal.Body>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '8px'}}>
            <MCButton
              label={t("Exports.cancel")}
              onClick={handleCancelDelete}
              variant="secondary"
            />
            <MCButton
              label={t("Exports.confirm")}
              onClick={handleConfirmDelete}
              variant="danger"
            />
          </div>
        </Modal.Body>
      </>

    )
  };

  return (
    <div className="list-fields">
      <EthicsModal show={showConfirmModal} handleModal={() => setShowConfirmModal(!showConfirmModal)} modalContent={<Confirm />} />
      {listOfExports.map((item) => (
        <div className="card" key={item.alias}>
          <div className="card-body">
            <img src={excelImage} alt="Excel" />
            <div className="card-data">
              <span className="labels">{t("Exports.template_name")}:</span>
              <span className="values">{item.template__template_name}</span>

              <span className="values comment">
                {item.comment ? item.comment : t("Exports.no_comment")}
              </span>

              <span className="values range-date">{item.range_date}</span>

              <span className="labels">{t("Exports.created_at")}:</span>
              <span className="values created-at">
                {formatDates(item.created_at)}
              </span>

              {item.alias in listDownloads ? (
                <PasswordComponent
                  password={listDownloads[item.alias].password}
                />
              ) : (
                <>
                  <MCButton
                    label={
                      selectedFile !== item.alias
                        ? t("Exports.download")
                        : `${t("Exports.downloading")}...`
                    }
                    variant="success"
                    onClick={() => handledownloadFile(item.alias)}
                    disabled={selectedFile === item.alias}
                  />
                  <MCButton
                    style={{marginTop: '12px'}}
                    label={
                      selectedFile !== item.alias
                        ? `${t("Exports.delete")}`
                        : `${t("Exports.deleting")}...`
                    }
                    outline
                    variant="danger"
                    onClick={() => activateModal(item.alias)}
                    disabled={selectedFile === item.alias}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const Exportables = () => {
  const [t] = useTranslation("exports");
  // API states
  const [areThereExports, setAreThereExports] = useState(false);
  const [listOfExports, setListOfExports] = useState([]);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  // Modal states CretaeFile
  const [showModal, setShowModal] = useState(false);

  const getListExports = async () => {
    /**
         * This function gets the list of exports
         * and sets the state of the list of exports
         * and the state of the boolean that indicates
         * if there are exports
            @returns {
                "message": "Get data by last days",
                "data": [
                    {
                        "id": 2,
                        "alias": "EF40F417T33kL120C1f781iah24v031Ty1",
                        "range_date": "2023-09-19 - 2023-09-21",
                        "comment": "",
                        "created_at": "2023-10-13T17:17:13.082252",
                        "template__template_name": "Reportes del formulario"
                    },
                    ...
                ]
            }
            or
            @returns {
                "message": "No content"
            }
         */

    const { data } = await listExports();

    if (data !== undefined && data.length > 0) {
      setListOfExports(data);
      setAreThereExports(true);
    } else setAreThereExports(false);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!showModal) getListExports();
  }, [showModal]);

  const ModalContent = () => {
    return (
      <CreateFileModal
        showComponent={showModal}
        stateComponent={setShowModal}
      />
    );
  };

  const ButtonCreateFileModal = () => {
    const handleClick = () => {
      setShowModal(true);
    };
    return (
      <>
        <MCButton
          label={t("Exports.generate_report_file")}
          outline
          variant="primary"
          className="btn-create-file"
          onClick={handleClick}
        />
        <ModalContent />
      </>
    );
  };

  return (
    <div id="settings">
      <GeneralIconHeader
        title="Exportables"
        text={t("Exports.data_export_section")}
        icon="folder_open"
        component={areThereExports ? <ButtonCreateFileModal /> : null}
      />

      <div className="dyTheme1 dyBorder1 p-sm-4 reportsTable">
        {!isLoading ? (
          areThereExports ? (
            <DetailExportables listOfExports={listOfExports} setListOfExports={setListOfExports} setAreThereExports={setAreThereExports} />
          ) : (
            <NoContent
              text={t("Exports.no_reports_export")}
              useModal
              textBtn={t("Exports.generate_report_file")}
              content={<ModalContent />}
              stateContent={setShowModal}
            />
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
