import { useState } from "react";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import Icon from "../Icon/Icon";
import { CatalogueInput } from "../JSONForms/PreviewForm/CatalogueInput";
import { useNavigate } from "react-router";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

export const PreviewCatalogue = ({
  scope,
  data: initData,
  catalogue,
  isOwn,
  setActiveScreen,
}) => {
  const [t] = useTranslation("catalogues");
  const navigate = useNavigate();

  const [schema, setSchema] = useState([scope]);
  const [data, setData] = useState([initData]);

  useEffect(() => {
    setSchema(scope);
    setData(initData);
  }, [catalogue]);

  return (
    <div className="catalogues-prev-catalogue dyTheme1 dyBorder1 rounded p-4">
      <div className="header">
        <div className="d-flex">
          <div
            className="pointer"
            onClick={() => {
              setActiveScreen("info");
              navigate("");
            }}
          >
            <Icon name="arrow_left_3" className="dyIcon2" />
          </div>
          <h3>{t("Catalogues.catalog_preview")}</h3>
        </div>
        <div>
          <small>{t("Catalogues.brief_information")}</small>
        </div>
      </div>

      <div className="form-preview">
        <div className="header-prev-catalogue">
          {isOwn ? t("Catalogues.my_own_catalog") : t("Catalogues.EG_catalog")}
          {scope[0]?.label ? scope[0].label : t("Catalogues.untitled")}
        </div>

        <div className="catalog-container dyBorder1 rounded">
          {scope && (
            <Row>
              <CatalogueInput
                schema={schema[0]}
                scope={schema[0]}
                value={data[0][catalogue]}
                catalogue={catalogue}
                isOwn={isOwn}
                entireSchema={schema}
                entireFormData={data}
                setSchemaState={setSchema}
                setFormData={setData}
                pathSchema={"0"}
                pathData={"0"}
                returnValidClass={() => {}}
                handleValidate={() => {}}
              />
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
