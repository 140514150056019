
import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth/authSlice';
import { jaguarSlice } from './jaguar/jaguarSlice';
import { langSlice } from './lang/langSlice';
import { themeSlice } from './theme/themeSlice';
import onlineReducer from './online/onlineSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    theme: themeSlice.reducer,
    lang: langSlice.reducer,
    jaguar: jaguarSlice.reducer,
    online: onlineReducer,
  }
})
