import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    errorMessage: false,
    status: "checking",
    AccessToken: null,
    IdToken: null,
    RefreshToken: null,
    mfa: null,
    email: null,
    tenant: null,
    username: null
  },
  reducers: {
    setLoggedIn: (state, { payload }) => {
      state.isLoggedIn = true;
      state.AccessToken = payload.AccessToken;
      state.IdToken = payload.IdToken;
      state.RefreshToken = payload.RefreshToken;
      state.mfa = payload.mfa;
      state.email = payload.email;
      state.tenant = payload.tenant;
      state.username = payload.username;
      state.status = "authenticated";
    },
    setTenant: (state, { payload }) => {
      state.tenant = payload
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      state.AccessToken = null;
      state.IdToken = null;
      state.RefreshToken = null;
      state.mfa = null;
      state.email = null;
      state.tenant = null;
      state.username = null;
      state.status = "not-authenticated";
    },
    setChecking: (state) => {
      state.status = "checking";
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    }
  }
});

export const { setLoggedIn, setTenant, setLoggedOut, setErrorMessage, setChecking } = authSlice.actions;