import React from "react";
import { MCButton, MCLabel } from "../../../../MainComponents";
import { useTranslation } from "react-i18next";

//----- Section for Add or Eraser an Investigation  -----//
export const AddEraseInv = ({ toggleModal, handleUnlinkInvestigation }) => {
  //-- Swal2 to ask before delete information --//
  const [t] = useTranslation("investigation");

  return (
    <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
      <h6 className="addNewInv__label d-flex mb-2">
        <MCLabel
          text={t("General.additional")}
          helptip={t("General.additional_help")}
        />
      </h6>
      <div
        className="addNewInv__actionBtns p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          gap: "5px",
        }}
      >
        <MCButton
          label={t("General.additional_new")}
          variant="primary"
          onClick={() => toggleModal("add-newInvestigation")}
        />
        <MCButton
          label={t("General.additional_unlink")}
          variant="danger"
          outline
          onClick={handleUnlinkInvestigation}
        />
      </div>
    </div>
  );
};
