
//Get AccessToken
export const getSession = () => {
  const sessionLS = localStorage.getItem('Session');
  if (!sessionLS) return null;
  const session = JSON.parse(sessionLS);
  if (!session.IdToken) return null;
  if (!session.AccessToken) return null;
  return session; 
}
//Get UserName
export const getUsername = () => {
  const usernameLS = localStorage.getItem('Username');
  if (!usernameLS) return null;
  const username = usernameLS;
  return username; 
}