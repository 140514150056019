import en from './locales/en.json';
import es from './locales/es.json';

// Define the translations for each language
const translations = {
  "en": en,
  "es": es
};

// i18nText component that will render the translated text
const I18nText = ({ lang, translation = "" }) => {
  // Retrieve the translation for the specified language, defaulting to 'en'
  const i18n = translations[lang] || es;
  // You can render the translated text here
  return i18n[translation]
};

export default I18nText;