import { useState } from "react";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import {
  OutlinePeople,
  OutlineSecuritySafe,
  OutlineRowVertical,
  OutlineUser,
  PersonalId,
} from "../Icon";
import { MCButton } from "../MainComponents/Button/Button";
import { MCCheckbox } from "../MainComponents/Input/Input";
import { MCLabel } from "../MainComponents/Label/Label";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ModalConfigListUsers = ({
  applyConfig,
  checks,
  selectAllChecks,
  handleChecks,
  showModal,
  toggle,
}) => {
  const { gTheme } = useSelector((state) => state.theme);
  const [t] = useTranslation("users");
  return (
    <Modal
      show={showModal}
      onHide={toggle}
      size="lg"
      className={`${gTheme}-mode`}
      centered
    >
      <Modal.Header
        className={`${
          gTheme !== "light" ? "btn-close-white" : ""
        } border-0 p-20`}
        closeButton
      >
        <h4>{t("Users.user_list_configuration")}</h4>
      </Modal.Header>
      <div className="p-0 text-left">
        <div className="p-20 dyTheme1 dyBorder1 rounded">
          <div className="pb-3 border-bottom d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <MCCheckbox
                id="select-all-users-config"
                className="mx-2"
                checked={!checks.find((i) => i.checked === false)}
                onClick={selectAllChecks}
              />
              <MCLabel
                text={t("Users.select_all")}
                htmlFor="select-all-users-config"
              />
            </div>
            <MCButton
              onClick={toggle}
              label={t("Users.default_list")}
              variant="primary"
              outline
              className="mx-1"
            />
          </div>
          <h6 className="mt-20">
            {`${t("Users.showing")} ${checks.length} ${t("Users.categories")}:`}
          </h6>
          <Row>
            {checks.map((item, idx) => {
              return (
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  key={"list" + idx}
                  className={`my-3 d-flex align-items-center`}
                >
                  <div
                    className={`d-flex align-items-center w-100 pointer ${
                      item.checked ? "" : "in"
                    }active-check`}
                    onClick={() => handleChecks(idx)}
                    style={{ height: 55 }}
                  >
                    <MCCheckbox
                      id={`checks-users-config-${idx}`}
                      className="mx-2"
                      checked={item.checked}
                    />
                    <MCLabel text={item.name} />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="d-flex justify-content-between mt-20">
          <div className="d-flex align-items-center">
            <MCCheckbox id="apply-all-users" className="mx-1" />
            <MCLabel
              text={t("Users.apply_all_users")}
              htmlFor="apply-all-users"
            />
          </div>
          <div className="d-flex">
            <MCButton
              onClick={toggle}
              size="lg"
              label={t("Users.cancel")}
              variant="primary"
              outline
              className="mx-1"
              width={200}
            />
            <MCButton
              onClick={() => {
                applyConfig();
                toggle();
              }}
              size="lg"
              label={t("Users.apply_settings")}
              variant="primary"
              className="mx-1"
              width={200}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Config = ({ theme, checks, setChecks, applyConfig }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [t] = useTranslation("users");
  const selectAllChecks = () => {
    const newState = checks.map((check, idxChk) => ({
      ...check,
      checked: true,
    }));
    setChecks(newState);
  };

  const handleChecks = (idx) => {
    const newState = checks.map((check, idxChk) =>
      idx === idxChk ? { ...check, checked: !check.checked } : check
    );
    setChecks(newState);
  };

  return (
    <>
      <Dropdown className="d-flex justify-content-end">
        <Dropdown.Toggle className="mcbtn" variant="primary">
          {t("Users.settings")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModal(!showModal)}>
            <OutlineUser className="mx-2" />
            {t("Users.user_list_configuration")}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/segments")}>
            <OutlinePeople className="mx-2" />
            {t("Users.user_groups")}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/users/roles")}>
            <OutlineSecuritySafe className="mx-2" />
            {t("Users.user_roles")}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/users/permits")}>
            <PersonalId className="mx-2" />
            {t("Users.user_permissions")}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/users/menus")}>
            <OutlineRowVertical className="mx-2" />
            {t("Users.menus")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ModalConfigListUsers
        applyConfig={applyConfig}
        checks={checks}
        selectAllChecks={selectAllChecks}
        handleChecks={handleChecks}
        showModal={showModal}
        toggle={() => setShowModal(!setShowModal)}
        theme={theme}
      />
    </>
  );
};
export default Config;
