import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { MCLabel } from "../Label/Label";
import { MCSelect } from "../Select/Select";
import { BoldCloseCircle } from "../../Icon";
import "./SelectMulti.scss";

export const MCSelectMulti = ({
  label,
  helptip = false,
  placeholder = false,
  options = [],
  selectedValues = [],
  setSelectedValues,
  isValid = true,
}) => {
  // Custom Hooks
  const { t } = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);

  const selectedOptions = selectedValues.map((value) => {
    const option = options.find((option) => option.value === value);
    // Show the value as is if there is no match
    return option ? option : { value, label: value };
  });
  // Filter available options
  const filteredOptions = options.filter(
    (option) => !selectedValues.includes(option.value)
  );
  // Select options
  const selectingOptions = (selection) => {
    setSelectedValues([...selectedValues, selection.value]);
  };
  // Delete option
  const removeItem = (value) => {
    setSelectedValues(selectedValues.filter((v) => v !== value));
  };
  // Get options to Select
  const getOptions = (children) => {
    return children.map((child) => ({
      value: child.value || child.code,
      label: child.label || child.name,
    }));
  };
  // Dark mode
  const styleDarkMode = gTheme === "light" ? "" : "dark-mode";
  // If is required a tooltip with extra information
  const tooltip = <Tooltip id="tooltip">{t("SelectSome.delete")}</Tooltip>;

  return (
    <div className={`selectMulti ${styleDarkMode}`}>
      <div className="selectMulti__label mx-2 my-1">
        <MCLabel helptip={helptip} text={label} />
        <MCLabel text={`${selectedValues.length} / ${options.length}`} />
      </div>
      <div>
        <MCSelect
          isValid={isValid}
          placeholder={
            placeholder ? (
              <div>{placeholder}</div>
            ) : (
              <div>{t("SelectSome.select_option")}</div>
            )
          }
          options={getOptions(filteredOptions)}
          onChange={selectingOptions}
        />
      </div>
      {selectedValues.length >= 1 && (
        <div className="selectMulti__chipBox">
          {selectedOptions.map((item, idx) => (
            <div key={idx} className="selectMulti__chip ">
              <span>{item.label || item.name || item.country}</span>
              <OverlayTrigger placement="bottom" overlay={tooltip}>
                <button
                  onClick={() => removeItem(item.value)}
                  className="selectMulti__btn"
                >
                  <BoldCloseCircle className="selectMulti__icon" />
                </button>
              </OverlayTrigger>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
