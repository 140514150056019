import _ from "lodash";
import formStarterES from "../../../translations/EN/forms.json";
import formStarterEN from "../../../translations/ES/forms.json";
import { catalogByPart } from "../../../apis";

import { 
  INPUT_TYPE_CATALOGUE_ERROR,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_CHECKBOX_CONDITIONAL,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_FILE,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_INVOLVED,
  INPUT_TYPE_SUBJECT,
  INPUT_TYPE_DESCRIPTION,
  isForLanding
} from "../consts";

import I18nText from "./i18n";

// fill answered catalogues

const fillSelections = async ({ input, data, catalogue, is_own, nestNum = 0, pathForSchema = "", forEdition }) => {
  const newInput = _.cloneDeep(input);
  const splitedAnsr = data.split("::");
  const slicedAnsr = splitedAnsr.slice(0, 2 * nestNum);
  const slicedAnsrKey = splitedAnsr.slice(nestNum * 2, 2 * (nestNum + 1));
  const selected = slicedAnsrKey.length === 2 ? slicedAnsrKey[slicedAnsrKey.length - 1] : "";
  const joined = slicedAnsr.join("::");
  const resp = await catalogByPart({ is_own, catalogue, path: joined ? joined : "/", ...(forEdition && { show_all: true }) });
  if (resp.error) return "error";
  if (Array.isArray(resp.data) && resp.data.length > 0) {
    const newResp = resp.data.map((item) => {
      const itemWithoutChilds = { ...item };
      delete itemWithoutChilds.childs;
      return itemWithoutChilds;
    });
    _.set(newInput, `${pathForSchema ? pathForSchema + "." : ""}children`, newResp);
    _.set(newInput, `${pathForSchema ? pathForSchema + "." : ""}selected`, selected);
  }
  if (selected) {
    return await fillSelections({ input: newInput, data, catalogue, is_own, nestNum: nestNum + 1, pathForSchema: `${pathForSchema ? pathForSchema + ".next" : "next"}`, forEdition });
  } else {
    return newInput;
  }
};

export const fillIfIsCatalogue = async (inputsSchema, inputsData, forEdition) => {
  const newInputs = inputsSchema.map(async (inp, idx) => {
    if ("catalogue" in inp && "isOwn" in inp) {
      const filledInput = await fillSelections({
        input: inp,
        data: inputsData[idx][inp.catalogue],
        catalogue: inp.catalogue,
        is_own: inp.isOwn,
        forEdition,
      });
      if (filledInput === "error") {
        return returnCatalogueInputError(inp);
      } else {
        return filledInput;
      }
    }
    return inp;
  });
  const newInputsSolved = await Promise.all(newInputs);
  return newInputsSolved;
};

export const fillCatalogueAnswered = async(catalogueInput, data, forEdition = false) => {
  if ("catalogue" in catalogueInput && "isOwn" in catalogueInput) {
    const filledInput = await fillSelections({
      input: catalogueInput,
      data,
      catalogue: catalogueInput.catalogue,
      is_own: catalogueInput.isOwn,
      forEdition,
    });
    if (filledInput === "error") {
      return returnCatalogueInputError(catalogueInput)
    } else {
      return filledInput;
    }
  }
}



//? ---------- Formulario para responder ----------
//* Se encarga de construir el catalogo de un formulario ya respondido y asignarlo al schema
const checkCatalogues = async (inputs, lang = null) => {
  const newInputs = inputs.map(async (inp, idx) => {
    let newInput = _.cloneDeep(inp);
    _.unset(newInput, `children`);
    _.unset(newInput, `childs`);
    _.unset(newInput, `next`);
    _.set(newInput, `selected`, "");
    _.set(newInput, `lastChild`, true);

    if ("catalogue" in inp && "isOwn" in inp && !("conditionals" in inp)) {
      const resp = await catalogByPart({ is_own: inp.isOwn, catalogue: inp.catalogue, path: "/" }, lang);

      if (resp.error) return returnCatalogueInputError(inp);

      newInput.children = resp.data;
      return newInput;
    } else if ("catalogue" in inp && "isOwn" in inp && "conditionals" in inp) {
      const resp = await catalogByPart({ is_own: inp.isOwn, catalogue: inp.catalogue, path: "/" }, lang);

      if (resp.error) return returnCatalogueInputError(inp);

      const nested = await Promise.all(newInput.conditionals.map(async(item) => {
        const newNestedChildren = await checkCatalogues(item.nestChildren, lang);
        return { caseOf: item.caseOf, nestChildren: newNestedChildren };
      }));

      newInput.conditionals = nested;
      newInput.children = resp.data;
      return newInput;
      // Para setear los keys de los tipos de campo que lleven una key en especifico siempre
    } else if (inp.type === INPUT_TYPE_EMAIL || inp.type === INPUT_TYPE_INVOLVED || inp.type === INPUT_TYPE_SUBJECT || inp.type === INPUT_TYPE_DESCRIPTION ) {
      if (inp.type === INPUT_TYPE_INVOLVED) { // pone la key "involucrados" en todos los tipos de campo de involved
        newInput.key = "involucrados";
      } else {
        newInput.key = inp.type;
      }
      return newInput;
    }
    return inp;
  });
  const newInputsSolved = await Promise.all(newInputs);
  return newInputsSolved;
};

//* Verifica las condicionales para asignarles un valor en la data o en la validacion
const checkConditionals = (conditionals, value, origin, target = "data", lang) => {
  const currentConditionals = conditionals.find((item)=>checkTypeOfConditional(item, value));
  const currentConditionalsIdx = conditionals.findIndex((item)=>checkTypeOfConditional(item, value));
  if (currentConditionals && currentConditionalsIdx>=0) {
    if (target === "data") {
      return returnFormData(currentConditionals.nestChildren, `${origin}::conditionals::${currentConditionalsIdx}::nestChildren`, lang);
    } else if (target === "valid") {
      return returnIsValid(currentConditionals.nestChildren, lang);
    }
  }
  return [];
};

//* Verifica el valor de la condicional en caso de que sea string, boolean o cualquier otro tipo de dato
export const checkTypeOfConditional = (item, value) => {
  if (typeof value === "boolean") {
    if (value === item.caseOf) {
      return true;
    } else {
      return false;
    }
  }
  if (typeof value === "string") {
    if (value.includes(item.caseOf)) {
      return true;
    } else {
      return false;
    }
  }
};

//* Verifica el valor de la condicional en caso de que sea string, boolean o cualquier otro tipo de dato
//TODO: Cambiar de lugaresta funcion a donde se utiliza solamente
export const resetInputsCatalogues = (inputs) => {
  const newInputs = inputs.map((inp, idx) => {
    let newInput = _.cloneDeep(inp);
    if ("catalogue" in inp && "isOwn" in inp) {
      _.unset(newInput, `childs`);
      _.unset(newInput, `next`);
      _.set(newInput, `selected`, "");
    }
    return newInput;
  });
  return newInputs;
};

export const returnFormData = (inputs, path, lang) => {

  const newInputs = inputs.map((input, idx) => {
    const newPath = `${path}::${idx}`;
    if (input.type === INPUT_TYPE_DATE) {
      let today = new Date();
      return {
        [input.key]: today,
        sensitive: input.sensitive || false,
        origin: newPath,
      };
    }
    if (input.type === INPUT_TYPE_DATE_RANGE) {
      let today = new Date().toJSON();
      return {
        [input.key]: `${today}__${today}`,
        sensitive: input.sensitive || false,
        origin: newPath,
      };
    }
    if (input.type === INPUT_TYPE_FILE) {
      return {
        [input.key]: null,
        sensitive: input.sensitive || false,
        origin: newPath,
      };
    }
    if (input.type === INPUT_TYPE_CHECKBOX) {
      return {
        [input.key]: input.defaultAnswer === true ? true : false,
        sensitive: input.sensitive || false,
        origin: newPath,
      };
    }
    if (input.type === INPUT_TYPE_CHECKBOX_CONDITIONAL) {
      return {
        [input.key]: false,
        sensitive: input.sensitive || false,
        origin: newPath,
        conditionals: checkConditionals(input.conditionals, false, newPath, "data", lang)
      };
    }
    if (input.type === INPUT_TYPE_INVOLVED) {
      return {
        [input.key]: [],
        sensitive: input.sensitive || false,
        origin: newPath,
      };
    }
    if ("catalogue" in input && !("conditionals" in input)) {
      return {
        [input.catalogue]: "",
        sensitive: input.sensitive || false,
        origin: newPath,
        catalogue: input.catalogue,
        isOwn: input.isOwn,
      };
    }
    if ("catalogue" in input && "conditionals" in input) {
      return {
        [input.catalogue]: "",
        sensitive: input.sensitive || false,
        origin: newPath,
        catalogue: input.catalogue,
        isOwn: input.isOwn,
        conditionals: checkConditionals(input.conditionals, "", newPath, "data", lang)
      };
    }
    return {
      [input.key]: "",
      sensitive: input.sensitive || false,
      origin: newPath,
    };
  });
  return newInputs;
};

export const returnIsValid = (inputs, lang) => {

  const i18n = {
    "form_requiered": I18nText({ lang, translation: "form_requiered" }),
    "form_check_required": I18nText({ lang, translation: "form_check_required" }),
    "form_invalid_email": I18nText({ lang, translation: "form_invalid_email" }),
  }

  const newInputs = inputs.map((input) => {
    const key = input.catalogue ? input.catalogue : input.key;
    if (input.type === INPUT_TYPE_DATE ||input.type === INPUT_TYPE_DATE_RANGE) {
      return {
        [key]: [],
      };
    }
    if (input.type === INPUT_TYPE_FILE && input.required) {
      return {
        [key]: [i18n["form_requiered"]]
      }
    }
    if (input.type === INPUT_TYPE_CHECKBOX && input.required && !(input.defaultAnswer===true)) {
      return {
        [key]: [i18n["form_check_required"]]
      }
    }
    if (input.type === INPUT_TYPE_CHECKBOX_CONDITIONAL && input.required) {
      return {
        [key]: [i18n["form_check_required"]],
        "conditionals": checkConditionals(input.conditionals, false, "", "valid", lang)
      }
    }
    if (input.type === INPUT_TYPE_EMAIL && input.required) {
      return {
        [key]: [i18n["form_invalid_email"]]
      }
    }
    if ("catalogue" in input && !("conditionals" in input) && input.required) {
      return {
        [key]: [i18n["form_requiered"]]
      }
    }
    if ("catalogue" in input && "conditionals" in input) {
      if (input.required) {
        return {
          [key]: [i18n["form_requiered"]],
          "conditionals": checkConditionals(input.conditionals, "", "", "valid", lang)
        }  
      } else {
        return {
          [key]: [],
          "conditionals": checkConditionals(input.conditionals, "", "", "valid", lang)
        }
      }
    }
    if (input.required && !(input.defaultAnswer===true)) {
      return {
        [key]: [i18n["form_requiered"]]
      }
    }
    return { 
      [key]: [] 
    };
  });
  return newInputs;
};

//* Retorna la las validaciones globales que tiene el formulario, ya sea de inputs normales o condicionales
const returnConstraints = (inputs, baseOrigin) => {
  let newInputs = [];
  inputs.forEach((input, idx) => {
    const origin = `${baseOrigin}::${idx}`;
    if ("conditionals" in input) {
      input.conditionals.forEach((condition, idx2) => {
        newInputs.push(returnConstraints(condition?.nestChildren || [], `${origin}::conditionals::${idx2}::nestChildren`));
      });
      if ("otherCatConstraints" in input) {
        input.otherCatConstraints.forEach((constr) => {
          newInputs.push({ origin, selected: false, ...constr });
        });
      }
    } else {
      if ("otherCatConstraints" in input) {
        input.otherCatConstraints.forEach((constr) => {
          newInputs.push({ origin, selected: false, ...constr });
        });
      }
    }
  });
  return newInputs;
};

//* En caso de que el catalogo contenga un error o ya no exista se retorna este schema en el catalogo
const returnCatalogueInputError = (inp) => ({
  key: `C::${inp.isOwn}::${inp.catalogue}`,
  catalogue: inp.catalogue,
  type: INPUT_TYPE_CATALOGUE_ERROR,
  label: `Catalogue with error (${inp.catalogue})`,
  required: false,
  sensitive: false,
  selected: "",
});



//? ---------- Formulario Respondido ----------
//* Limpia las respuestas que trae el formulario respondido, ya que las respuestas de los catalogos vienen por partes
const cleanAnswers = (inputs = []) => {
  const orderData = inputs.sort((a, b) => a.path.localeCompare(b.path));
  const regex = /^C,\s*(?:OWN|EG),\s*\[?.*]?$/;
  const cleanedInputs = orderData.filter(item=>{
    if (item.catalogue === null) {
      return true;
    } else {
      const splitedKey = item.key.split("::");
      if (regex.test(splitedKey.join(','))) {
        return true;
      } else {
        return false;
      }
    }
  })
  return cleanedInputs;
}

//* Pone las respuestas del reporte en el SCHEMA del formulario
const buildCataloguesAnswered = async(answers, schema) => {
  const newSchema = _.cloneDeep(schema);
  for (const ans of answers) {
    if (ans.catalogue!==null && ans.value!==null) {
      let newPath = ans.path.split("::");
      newPath.splice(0, 3);
      newPath = newPath.join(".")
      const input = _.get(newSchema, `${newPath}`);
      const filledCatalogue = await fillCatalogueAnswered(input, ans.value);
      _.set(newSchema, `${newPath}`, filledCatalogue);
    }
  }
  return newSchema;
}

//* Pone las respuestas del reporte en la DATA del formulario
const putAnswersOnData = async(answers, data, schema) => {
  const newData = _.cloneDeep(data);
  for (const ans of answers) {
    let newPath = ans.path.split("::");
    newPath.splice(0, 3);
    const joinedPath = newPath.join(".");
    let pathForData = "";
    const inputSchema = _.get(schema, joinedPath);

    // Obtener los index para settear la data
    if (newPath.length===1) {
      pathForData = newPath[0];
    } else {
      const firstPosition = newPath.shift();
      pathForData = `${firstPosition}`
      for (const [idx, item] of newPath.entries()) {
        if (((idx+1)%4===0)) {
          pathForData = `${pathForData}.conditionals.${item}`
        }
      }
    }

    const key = "catalogue" in inputSchema ? inputSchema.catalogue :  inputSchema.key;

    const objData = {
      [key]: ans.value,
      "sensitive": inputSchema.sensitive,
      "origin": `stepers::${ans.path}`,
      ...("catalogue" in inputSchema && {
        "catalogue": inputSchema.catalogue,
        "isOwn": inputSchema.isOwn,
      }),
    }

    _.set(newData, `${pathForData}`, objData);
  }
  return newData;
}

//* Pone las respuestas del reporte en el VALID del formulario
const putAnswersOnValid = async(answers, valid, schema) => {
  const newIsValid = _.cloneDeep(valid);
  for (const ans of answers) {
    let newPath = ans.path.split("::");
    newPath.splice(0, 3);
    const joinedPath = newPath.join(".");
    let pathForIsValid = "";
    const inputSchema = _.get(schema, joinedPath);

    if (newPath.length===1) {
      pathForIsValid = newPath[0];
    } else {
      const firstPosition = newPath.shift();
      pathForIsValid = `${firstPosition}`
      for (const [idx, item] of newPath.entries()) {
        if (((idx+1)%4===0)) {
          pathForIsValid = `${pathForIsValid}.conditionals.${item}`
        }
      }
    }

    const key = "catalogue" in inputSchema ? inputSchema.catalogue :  inputSchema.key;

    const objIsValid = { [key]: [] }

    _.set(newIsValid, `${pathForIsValid}`, objIsValid);
  }
  return newIsValid;
}

// target: 'normal' || 'new' || 'answered'

export const formStarter = async(formLoaded, target = "normal", formAnswers, langDef = null) => {

  const landingLSLang = localStorage.getItem("selectedLanguage");
  const caseLSLang = localStorage.getItem("lang");

  const lang = langDef ? langDef : isForLanding ? landingLSLang : caseLSLang ?? "en";

  let formIdentifier = "";
  let newSteps = [];
  let newSchemaState = [];
  let newFormData = [];
  let newIsValid = [];
  let newConstraints = [];
  let newValidCreateSteps = [];
  let newValidCreateInputs = [];

  const begin = async() => {
    formIdentifier = formLoaded.identifier_name;
    if (Array.isArray(formLoaded.stepers)) {
      for (const [idx, step] of formLoaded.stepers.entries()) {
        newSteps.push({
          name: step.name,
          title: step.title,
          description: step.description,
        });
        newValidCreateSteps.push([]);
        const jsonSchemaTemp = _.get(step, `form.json-schema`);
        const resp = await checkCatalogues(jsonSchemaTemp, lang);
        newSchemaState.push(resp);
        newFormData.push(returnFormData(resp, `stepers::${idx}::form::json-schema`));
        newIsValid.push(returnIsValid(resp, lang));
        newConstraints.push(returnConstraints(resp, `stepers::${idx}::form::json-schema`));
        newValidCreateInputs.push(resp.map((input) => []));
      }
    }
  };

  const beginNew = () => {
    const lang = localStorage.getItem("lang");
    const language = lang === "es" ? formStarterES : formStarterEN;
    newSteps = [
      {
        name: "step_title",
        title: language.formStarter.step_title,
        description: language.formStarter.some_description,
      },
    ];
    newSchemaState = [
      [
        {
          key: language.formStarter.input_key,
          type: INPUT_TYPE_TEXT,
          label: language.formStarter.enter_label,
          placeholder: language.formStarter.support_text,
          required: false,
          sensitive: false,
          grid: 4,
          break: false,
        },
      ],
    ];
    newFormData = [[{ input_label: "" }]];
    newIsValid = [[{ input_label: [] }]];
    newValidCreateSteps = [[]];
    newValidCreateInputs = [[[]]];
  };

  const beginAnswered = async() => {
    formIdentifier = formLoaded.identifier_name;
    if (Array.isArray(formLoaded.stepers)) {
      //Este for hace el recorrido de los steps solamente
      for (const [ idx, step ] of formLoaded.stepers.entries()) {
        newSteps.push({
          name: step.name,
          title: step.title,
          description: step.description,
        })
        newValidCreateSteps.push([]);
        const jsonSchemaTemp = _.get(step,`form.json-schema`);
        const thisSchemaState = await checkCatalogues(jsonSchemaTemp);
        // newSchemaState.push(thisSchemaState);
        
        const thisNewFormData = returnFormData(thisSchemaState, `stepers::${idx}::form::json-schema`);
        const thisNewIsValid = returnIsValid(thisSchemaState);
        const thisNewConstraints = returnConstraints(thisSchemaState, `stepers::${idx}::form::json-schema`)
        
        
        const answers = _.get(formAnswers,`stepers.${idx}.form`);
        const answersCleaned = cleanAnswers(answers);
        const schemaBuildedCatalogues = await buildCataloguesAnswered(answersCleaned, thisSchemaState);
        const dataWithAnswersSetted = await putAnswersOnData(answersCleaned, thisNewFormData, schemaBuildedCatalogues);
        const validWithAnswersSetted = await putAnswersOnValid(answersCleaned, thisNewIsValid, schemaBuildedCatalogues);

        newSchemaState.push(schemaBuildedCatalogues);
        newFormData.push(dataWithAnswersSetted);
        newIsValid.push(validWithAnswersSetted);
        newConstraints.push(thisNewConstraints);
        
      }
    }
  }

  const beginTest = async(formLoaded) => {
    formIdentifier = formLoaded.identifier_name;
    if (Array.isArray(formLoaded.stepers)) {
      for (const [idx, step] of formLoaded.stepers.entries()) {
        newSteps.push({
          name: step.name,
          title: step.title,
          description: step.description,
        });
        newValidCreateSteps.push([]);
        const jsonSchemaTemp = _.get(step, `form.json-schema`);
        const resp = await checkCatalogues(jsonSchemaTemp);
        newSchemaState.push(resp);
        newFormData.push(
          returnFormData(resp, `stepers::${idx}::form::json-schema`)
        );
        newIsValid.push(returnIsValid(resp, lang));
        newConstraints.push(
          returnConstraints(resp, `stepers::${idx}::form::json-schema`)
        );
        newValidCreateInputs.push(resp.map((input) => []));
      }
    }
  };

  if (target === "normal") {
    await begin();
  } else if (target === "new") {
    beginNew();
  } else if (target === "answered") {
    await beginAnswered();
  } else if (target === 'test') {
    // const formul = {
    //   id: 101,
    //   created_at: "2023-12-18T13:01:09.974024",
    //   updated_at: "2023-12-18T13:01:09.974037",
    //   deleted_at: null,
    //   identifier_name: "report",
    //   stepers: [
    //     {
    //       form: {
    //         "json-schema": [
    //           {
    //             key: "involved",
    //             grid: 12,
    //             type: "involved",
    //             break: false,
    //             label: "Involucrados",
    //             required: false,
    //             sensitive: false,
    //             placeholder: "involucrados placeholder",
    //           },
    //           {
    //             key: "C::EG::RC-100",
    //             grid: 12,
    //             type: "catalog-radio",
    //             break: false,
    //             isOwn: false,
    //             label: language.formStarter.report_classification,
    //             scope: "/",
    //             required: true,
    //             catalogue: "RC-100",
    //             sensitive: false,
    //             placeholder: language.formStarter.support_text,
    //             otherCatConstraints: [
    //               {
    //                 value: "type::questions",
    //                 catKey: "C::OWN::1",
    //                 constraints: ["type::1"],
    //               },
    //               {
    //                 value: "type::suggestion",
    //                 catKey: "C::OWN::1",
    //                 constraints: ["type::www"],
    //               },
    //               {
    //                 value: "type::other",
    //                 catKey: "C::EG::IC-101",
    //                 constraints: ["type::other"],
    //               },
    //             ],
    //           },
    //           {
    //             key: "C::OWN::1",
    //             grid: 12,
    //             type: "catalog-radio-conditional",
    //             break: false,
    //             isOwn: true,
    //             label: "1",
    //             scope: "/",
    //             required: false,
    //             catalogue: "1",
    //             sensitive: false,
    //             placeholder: language.formStarter.support_text,
    //             conditionals: [
    //               {
    //                 caseOf: "type::1",
    //                 nestChildren: [
    //                   {
    //                     key: "enter_label",
    //                     grid: 4,
    //                     type: "string",
    //                     break: false,
    //                     label: language.formStarter.enter_label,
    //                     required: false,
    //                     sensitive: false,
    //                     placeholder: language.formStarter.support_text,
    //                   },
    //                   {
    //                     key: "enter_label",
    //                     grid: 4,
    //                     type: "string",
    //                     break: false,
    //                     label: language.formStarter.enter_label,
    //                     required: false,
    //                     sensitive: false,
    //                     placeholder: language.formStarter.support_text,
    //                   },
    //                   {
    //                     key: "enter_label",
    //                     grid: 4,
    //                     type: "string",
    //                     break: false,
    //                     label: language.formStarter.enter_label,
    //                     required: false,
    //                     sensitive: false,
    //                     placeholder: language.formStarter.support_text,
    //                   },
    //                   {
    //                     key: "enter_label",
    //                     grid: 4,
    //                     type: "string",
    //                     break: false,
    //                     label: language.formStarter.enter_label,
    //                     required: false,
    //                     sensitive: false,
    //                     placeholder: language.formStarter.support_text,
    //                   },
    //                 ],
    //               },
    //             ],
    //             otherCatConstraints: [
    //               {
    //                 value: "type::1",
    //                 catKey: "C::EG::IC-101",
    //                 constraints: ["type::other"],
    //               },
    //             ],
    //           },
    //           {
    //             key: "C::EG::IC-101",
    //             grid: 12,
    //             type: "catalog-select",
    //             break: false,
    //             isOwn: false,
    //             label: language.formStarter.industry_classification,
    //             scope: "/",
    //             required: false,
    //             catalogue: "IC-101",
    //             sensitive: false,
    //             placeholder: language.formStarter.support_text,
    //           },
    //         ],
    //       },
    //       name: "informacion_del_denunciante",
    //       title: language.formStarter.complainant_information,
    //       description: language.formStarter.write_description,
    //     }
    //   ],
    // };
    // await beginTest(formul);
  }

  return {
    initFormIdentifier: formIdentifier,
    initSteps: newSteps,
    initSchemaState: newSchemaState,
    initFormData: newFormData,
    initIsValid: newIsValid,
    initConstraints: newConstraints.flat(Infinity),
    initValidCreateSteps: newValidCreateSteps,
    initValidCreateInputs: newValidCreateInputs,
  };
};
