import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormCheck, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useFormLogin } from "../../hooks/useFormLogin";
import { useSnackbar } from "notistack";
import Logo from "../../assets/images/imagotipo-ethics.png";
import Icon from "../../components/Icon/Icon";
import "./Auth.scss";
import { MCButton, MCTooltip } from "../../components/MainComponents";
import _ from "lodash";
import { useDispatch } from "react-redux";

const SingleCodeSquare = ({ position, entireValue, value, setOtp }) => {

  const handleKeyDown = (e) => {
    // Cuando el campo esta vacio y presiona borrar
    e.stopPropagation()
    const newEntireValue = _.cloneDeep(entireValue);
    if (!isNaN(e.key)) {
      newEntireValue[position] = e.key;
      if (position < entireValue.length-1) {
        const nextCodeSquere = document.querySelector(`#code-squere-ch${position+1}`);
        nextCodeSquere.focus();
      } else {
        setTimeout(() => {
          const buttonSubmit = document.querySelector(`#submit-otp`);
          buttonSubmit.focus();
        }, 10);
      }
    } else if (e.key === "Backspace") {
      if (position !== 0 && e.target.value === "") {
        const previowsCodeSquere = document.querySelector(`#code-squere-ch${position-1}`);
        previowsCodeSquere.focus();
      } else {
        newEntireValue[position] = "";
      }
    }
    setOtp(newEntireValue);
  }

  const handleFocus = () => {
    setTimeout(() => {
      if (position > 0) {
        const previowsCodeSquere = document.querySelector(`#code-squere-ch${position-1}`);
        if (previowsCodeSquere.value.trim() === "") {
          previowsCodeSquere.focus();
        }
      }
    }, 10);
  }
  
  return (
    <input className="code-squere" type="number" value={value} id={`code-squere-ch${position}`} onKeyDown={handleKeyDown} onFocus={handleFocus} />
  )
}

const CodeSquares = ({ otp, handleChange }) => {

  useEffect(() => {
    const firstCodeSquere = document.querySelector(`#code-squere-ch0`);
    firstCodeSquere.focus();
  }, []);

  const setOtp = (newOtp) => {
    handleChange({ target: { name: "otp_code", value: newOtp } })
  }

  return (
    <div className="code-squeres mt-2">
      <SingleCodeSquare position={0} entireValue={otp} value={otp[0]} setOtp={setOtp} />
      <SingleCodeSquare position={1} entireValue={otp} value={otp[1]} setOtp={setOtp} />
      <SingleCodeSquare position={2} entireValue={otp} value={otp[2]} setOtp={setOtp} />
      <SingleCodeSquare position={3} entireValue={otp} value={otp[3]} setOtp={setOtp} />
      <SingleCodeSquare position={4} entireValue={otp} value={otp[4]} setOtp={setOtp} />
      <SingleCodeSquare position={5} entireValue={otp} value={otp[5]} setOtp={setOtp} />
    </div>
  )
}

//---Signin Form---//
const Signin = ({ mainTitle, setDomainList, setSelectedDomain }) => {

  const [t] = useTranslation("login");
  const { errorMessage } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const {  //-----Destructuring useFormLogin-----//
    form, 
    errors, 
    loading, 
    handleChange, 
    handleBlur, 
    handleSubmit,
    // handleSelectTenant,
    mfa, 
    mfaSubmit,
    sectionShowed,
    // domainList,
    // selectedDomain,
    // setSelectedDomain
  } = useFormLogin({ setDomainList, setSelectedDomain });

  //-----Use button Enter to submit-----//
  useEffect(() => {
    const keyDown = (e) => {
      if (e.key === "Enter" && sectionShowed === "form") {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [sectionShowed]);

  //-----Show/hide password-----//
  function handleClickShowPassword() { setShowPassword((show) => !show) };
  function handleMouseDownPassword(e) { e.preventDefault() };

  //-----It disables button submit while otp code are not completed-----//
  function checkAllOtpFilled() {
    let allFilledWithNumbers = true;
    form.otp_code.forEach(item => {
      if (item.trim() === "") {
        allFilledWithNumbers = false;
      }
    })
    return allFilledWithNumbers;
  }

  //-----Alert with EthicsStyle------//
  if (errorMessage) {
    enqueueSnackbar(t("Signin.invalid_mail_or_password"), {
      variant: "error",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  }

  return (
    <div className="signin">
      <fieldset className="signin__fieldSet">
        <img className="signin__img" src={Logo} />
        <h3 className="signin__mainTitle">{mainTitle}</h3>

        {/* <pre>{JSON.stringify(mfa,null,2)}</pre> */}

        {!mfa && sectionShowed === "form" &&
          <form onSubmit={handleSubmit} className="signin__form">
            <div className="signin__group">
              <InputGroup size="sm">
                <label className="signin__label" htmlFor="email">
                  {t("Signin.business_email")}:
                </label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t("Signin.example_email")}
                  className="signin__input --inputEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={form.email}
                  autoComplete="username"
                />
              </InputGroup>
              {errors.email && (
                <p className="signin__errorInput my-1 px-1">{errors.email}</p>
              )}
            </div>
            <div className="signin__group">
              <InputGroup size="sm">
                <label className="signin__label" htmlFor="password">
                  {t("Signin.password")}:
                </label>
                <Form.Control
                  id="password"
                  name="password"
                  type={!showPassword ? "password" : "text"}
                  placeholder={`${!showPassword ? "**********" : t("Signin.password_label")
                    }`}
                  className="signin__input --inputPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={form.password}
                  autoComplete="current-password"
                />
                <InputGroup.Text className="signin__inputBtn">
                  <MCTooltip
                    text={`${!showPassword
                        ? t("Signin.show_password")
                        : t("Signin.hide_password")
                      }`}
                    position="top"
                  >
                    <Button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Icon name="outline_eye" />
                      ) : (
                        <Icon name="outline_eye_slash" />
                      )}
                    </Button>
                  </MCTooltip>
                </InputGroup.Text>
              </InputGroup>
              {errors.password && (
                <p className="signin__errorInput my-1 px-1">{errors.password}</p>
              )}
            </div>
            {/* <div className="signinTools">
              <FormCheck
                className="signinTools__remember"
                label={t("Signin.rememberme")}
                inline
              />
              <Link className="signinTools__forgotPassword" to="#">
                {t("Signin.forgot_password")}
              </Link>
            </div> */}
            <Button type="submit" className="signin__btn">
              {loading ? (
                <div>
                  {t("Signin.loading")}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                </div>
              ) : (
                t("Signin.signin")
              )}
            </Button>
          </form>
        }


        {mfa && sectionShowed === "mfa" &&
          <form onSubmit={mfaSubmit} className="signin__form">
            <div className="signin__group">
              {/* <InputGroup size="sm"> */}
                <label className="signin__label" htmlFor="email">
                  {t("Signin.otp_code")}:
                </label>
              {/* 
                <Form.Control
                  id="otp_code"
                  name="otp_code"
                  type="otp_code"
                  placeholder="000000"
                  className="signin__input --inputEmail"
                  onChange={handleChange}
                  value={form.otp_code}
                  max_length="6"
                />
              </InputGroup> */}

              {/* {errors.otp_code && (
                <p className="signin__errorInput my-1 px-1">{errors.otp_code}</p>
              )} */}
              
              <CodeSquares 
                otp={form.otp_code}
                handleChange={handleChange}
              />

            </div>


            <Button type="submit" className="signin__btn mt-2" id="submit-otp" disabled={!checkAllOtpFilled()}>
              {loading ? (
                <div>
                  {t("Signin.loading")}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                </div>
              ) : (
                t("Signin.signin")
              )}
            </Button>
          </form>
        }

        {/* <div className="createAccount">
          <p>{t("Signin.dont_have_account")}&nbsp;</p>
          <Link className="createAccount__link" to="#">
            <p>{t("Signin.create_account")}</p>
          </Link>
        </div> */}

      </fieldset>
    </div>
  );
};

export default Signin;
