import { Col, Row } from "react-bootstrap";
import ActivityViewer from "./ActivityViewer/ActivityViewer";
import FileUpload from "./FileUpload/FileUpload";
import Graph from "./Graph/Graph";
import Greetings from "./Greetings/Greetings";
// import News from "./News/News";
import RecentReports from "./RecentReports/RecentReports";
import { getGraphicsDashboard, getLastReport } from "../../apis/apiDashboard";
import { MCButton } from "../MainComponents/Button/Button";
import ModalGraph from "../Modal/ModalGraph";
import { useEffect, useState } from "react";
import { Loading } from "../Loading/String/Loading";
import { Indicators } from "./Indicator/Indicators";
import { Graphics } from "./Graphic/Graphics";
import { useSelector } from "react-redux";
import "./Dashboard.scss";

import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";
import News from "./News/News";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const [t] = useTranslation("dashboard");
  const [isLoading, setIsLoading] = useState(true);
  // State loading de indicadores
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  // State loading de graficas
  const [loadingGraphics, setLoadingGraphics] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // State los elementos del dashboard
  // Graficas
  const [graphics, setGraphics] = useState({});
  // Indicadores
  const [indicators, setIndicators] = useState({});
  // Last report
  const [lastReport, setLastReport] = useState({});

  useEffect(() => {
    // Solo cuando el modal se cierra se actualiza la informacion
    if (!modalOpen) {
      setIsLoading(true);
      getDataElement();
    }
  }, []);
  // Obtener los elementos del dashboard cuando el state loadingIndicators cambia
  useEffect(() => {
    if (loadingIndicators) getDataElement();
  }, [loadingIndicators]);
  // Obtener los elementos del dashboard cuando el state loadingGraphics cambia
  useEffect(() => {
    if (loadingGraphics) getDataElement();
  }, [loadingGraphics]);

  const getDataElement = async () => {
    const response = await getGraphicsDashboard();
    const lastReport = await getLastReport();
    setGraphics(response.graphics);
    setIndicators(response.indicators);
    setLastReport(lastReport);
    setIsLoading(false);
    setLoadingIndicators(false);
    setLoadingGraphics(false);
  };

  const breadcrumbItems = [
    { label: t("dashboard.home"), route: "/" },
    { label: "Dashboard" },
  ];

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div id="dashboard" className={`dashboard page`}>
          <Greetings />

          <div
            id="greetings"
            className=" mx-3 "
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <Breadcrum items={breadcrumbItems} />
          </div>

          <div className="p-1">
            <Row>
              <Col lg="8">
                <div className="new-graph">
                  <MCButton
                    label={t("dashboard.add_graphic_or_indicator")}
                    variant="primary"
                    outline
                    onClick={() => setModalOpen(true)}
                  />
                </div>
                <div className="indicators">
                  {loadingIndicators ? (
                    <Loading />
                  ) : (
                    <Indicators
                      indicatorsData={indicators}
                      stateLoading={setLoadingIndicators}
                    />
                  )}
                </div>

                <div className="graphics-content">
                  {loadingGraphics ? (
                    <Loading />
                  ) : (
                    <Graphics
                      graphicsData={graphics}
                      stateLoading={setLoadingGraphics}
                    />
                  )}
                </div>

                {modalOpen && (
                  <ModalGraph
                    show={modalOpen}
                    onHide={() => setModalOpen(false)}
                    stateLoadingIndicators={setLoadingIndicators}
                    stateLoadingGraphics={setLoadingGraphics}
                  />
                )}
              </Col>
              <Col lg="4">
                {/* <News /> */}
                {/* <ActivityViewer /> */}
                <RecentReports reportsData={lastReport} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
