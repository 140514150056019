import { Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { MCButton } from "../../MainComponents/Button/Button";
import { parseDateToDDMMYYYY } from "../../../helpers/dateTransform";
import { useTranslation } from "react-i18next";
import "./FormsList.scss";

export const EGForms = ({
  formList,
  setSelectedPreview,
  setShowPreviewModal,
}) => {
  const [t] = useTranslation("forms");
  const navigate = useNavigate();

  const formListwithoutCurrRep = () => {
    return formList.filter((item) => item.identifier_name === "report");
  };

  const handlePreviewForm = (id) => {
    setSelectedPreview(id);
    setShowPreviewModal(true);
  };

  return (
    <>
      <div className="mb-3">
        <h6>{t("EGForms.EG_forms")}</h6>
        <small>{t("EGForms.forms_default_eg")}</small>
      </div>
      <div className="table-border">
        <Table responsive striped className="custom-table">
          <thead>
            <tr>
              <th>{t("EGForms.name")}</th>
              <th>{t("EGForms.create_date")}</th>
              <th>{t("EGForms.action")}</th>
              <th>{t("EGForms.preview")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(formList) &&
              formListwithoutCurrRep()
                .reverse()
                .map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div>
                        {item.identifier_name}_{item.id}
                      </div>
                    </td>
                    <td>
                      <div>{parseDateToDDMMYYYY(item.created_at)}</div>
                    </td>
                    <td>
                      <MCButton
                        label={t("EGForms.edit")}
                        variant="primary"
                        size="sm"
                        outline
                        onClick={() => navigate(`/editform/${item.id}`)}
                      />
                    </td>
                    <td>
                      <MCButton
                        label={t("EGForms.preview")}
                        variant="primary"
                        size="sm"
                        outline
                        onClick={() => handlePreviewForm(item.id)}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
