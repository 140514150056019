import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  MCCheckbox,
  MCInput,
  MCLabel,
  MCTooltip,
} from "../../../MainComponents";

import "./PDFCreator.scss";
import { useSnackbar } from "notistack";
import { downloadPDFReport } from "../../../../apis/apiExports";
import {
  OutlineCopy,
  OutlineEye,
  OutlineEyeSlash,
  OutlineRefresh,
  BoldLock,
  BoldUnlock,
} from "../../../Icon";

const ItemCheckbox = (props) => {
  const id = props.id;
  const updateStack = (e) => {
      const isChecked = e.target.checked;
      const isReport = props.origin === "report";

      if (isReport)
          isChecked
              ? props.validatorFn(props.validator + 1)
              : props.validatorFn(props.validator - 1);

      props.fieldsFn({
          ...props.fields,
          [id]: {
              ...props.fields[id],
              value: isChecked,
          },
      });
  };
  
  // Agregar la propiedad 'checked' para marcar la casilla según el estado en 'fields'
  return (
      <Row className="mt-1">
          <Col>
              <MCCheckbox 
                  className="mx-1" 
                  onChange={(e) => updateStack(e)} 
                  id={id}
                  checked={props.fields[id].value} // Marcar o desmarcar según el estado en 'fields'
              />
              <MCLabel text={props.text} htmlFor={id} />
          </Col>
      </Row>
  );
};



export const PDFCreator = ({ closeModal, reportId }) => {
  const [t] = useTranslation("report");
  const { enqueueSnackbar } = useSnackbar();

    const [reportFields, setReportFields] = useState({
        "r1": {
            "keyName": "cover",
            "label": t("pdf_fields.cover"),
            "value": false
            
        },
        "r2": {
            "keyName": "subject",
            "label": t("pdf_fields.subject"),
            "value": false
        },
        "r3": {
            "keyName": "description",
            "label": t("pdf_fields.description"),
            "value": false
        },
        "r4": {
            "keyName": "involved",
            "label": t("pdf_fields.involved"),
            "value": false
        },
        "r5": {
            "keyName": "metadata",
            "label": t("pdf_fields.metadata"),
            "value": false
        },
        "r6": {
            "keyName": "encrypted",
            "label": t("pdf_fields.encrypted"),
            "value": false
        },
        "r7": {
            "keyName": "investigation",
            "label": t("pdf_fields.investigation"),
            "value": false
        },
    });
    const [investigationFields, setInvestigationFields] = useState({
        "i1": {
            "keyName": "status",
            "label": t("pdf_fields.status"),
            "value": false
        },
        "i2": {
            "keyName": "created_by",
            "label": t("pdf_fields.created_by"),
            "value": false
        },
        "i3": {
            "keyName": "created_at",
            "label": t("pdf_fields.created_at"),
            "value": false
        },
        "i4": {
            "keyName": "conclusion",
            "label": t("pdf_fields.conclusion"),
            "value": false
        },
        "i5": {
            "keyName": "involved",
            "label": t("pdf_fields.involved"),
            "value": false
        },
        "i6": {
            "keyName": "plans",
            "label": t("pdf_fields.plans"),
            "value": false
        },
        "i7": {
            "keyName": "interviews",
            "label": t("pdf_fields.interviews"),
            "value": false
        },
        "i8": {
            "keyName": "evidences",
            "label": t("pdf_fields.evidences"),
            "value": false
        },
    });
    const [password, setPassword] = useState("");

  // Estados para funcionalidad de la plantilla
  // *---> Validador de campos seleccionados
  const [validator, setValidator] = useState(0);
  // *---> Mostar contraseña
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    createRandomPassword(13);
  }, []);

    // Handlers
    // *---> Busca los campos seleccionados en los checkbox
    const searchCheckedFields = (fields) => {
        let checkedFields = [];
        Object.keys(fields).map((key) => {
            if (fields[key].value) {
                checkedFields.push(fields[key].keyName);
            }
        });
        return checkedFields;
    };
    

    const check_all = (fields, setFields, setValidator) => {
      // Copiar el estado actual de los campos
      const updatedFields = { ...fields };
    
      // Verificar si todos los campos están marcados
      const allChecked = Object.values(updatedFields).every((field) => field.value);
    
      // Si todos los campos están marcados, revertir cambios
      if (allChecked) {
        Object.keys(updatedFields).forEach((key) => {
          updatedFields[key].value = false;
    
          // Simular clic en el elemento de la casilla
          const checkboxId = updatedFields[key].keyName;
          const checkboxElement = document.getElementById(checkboxId);

        });
    
        // Actualizar el estado de los campos con las casillas desmarcadas
        setFields(updatedFields);
        
        // Actualizar el estado del validador
        setValidator(0);
    
        // Devolver un arreglo vacío ya que ningún campo está marcado
        return [];
      }
    
      // Si no todos los campos están marcados, marcar todos los campos
      Object.keys(updatedFields).forEach((key) => {
        updatedFields[key].value = true;
    
        // Simular clic en el elemento de la casilla
        const checkboxId = updatedFields[key].keyName;
        const checkboxElement = document.getElementById(checkboxId);
        
      });
    
      // Actualizar el estado de los campos con las casillas marcadas
      setFields(updatedFields);
    
      // Actualizar el estado del validador
      const numCheckedFields = Object.keys(updatedFields).length;
      setValidator(numCheckedFields);
    
      // Devolver un arreglo con los nombres de los campos marcados
      return Object.keys(updatedFields).map((key) => updatedFields[key].keyName);
    };
  
  // *---> Generar contraseña aleatoria
  const createRandomPassword = (length) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setPassword(result);
  };

  // *---> Copiar contraseña al portapapeles
  const copyPassword = () => {
    navigator.clipboard.writeText(password);
    enqueueSnackbar(t("Creator.copy_password"), {
      variant: "success",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
    });
  };

    // *---> Simular el envio de la información al backend
    const sendPayload = async () => {
      const transformedReport = Object.keys(reportFields).reduce((acc, key) => {
        const { keyName, value } = reportFields[key];
        acc[keyName] = value;
        return acc;
      }, {});


      const password_to_sent = sendPassword ? password : '';


      const data = {
        ...transformedReport,
        "investigation": searchCheckedFields(investigationFields),
        "password": password_to_sent
      };

    const response = await downloadPDFReport(reportId, data);

    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const fileName = createFileName();

      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      enqueueSnackbar(t("Creator.generated_successfully"), {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    } else {
      enqueueSnackbar(t("Creator.generated_successfully"), {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    }
  };

  // const Crear nombre del archivo por fecha
  const createFileName = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const fileName = `${t(
      "file_name"
    )}_${day}_${month}_${year}_${hour}_${minutes}_${seconds}.pdf`;

    return fileName;
  };

  const [sendPassword, setSendPassword] = useState(true);
  const [requiredPassword, setRequiredPassword] = useState(true);
  const handlePasswordMode = () => {
    setSendPassword(!sendPassword);
    setRequiredPassword(!requiredPassword);
  }

  return (
    <>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>{t("Creator.create_pdf_report")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          {/* Campos del reporte */}
          <Col className="container-fields">
            <Row>
              <MCLabel
                text={t("Creator.select_fields_report")}
                isRequired={true}
                helptip={t("Creator.select_least_one_field")}
              />
            </Row>
            <Row className="mt-3 mb-3">
              {Object.keys(reportFields).map((key) => {
                if (reportFields.r1.value && reportFields[key].keyName !== "cover") return;
                return (
                  <ItemCheckbox
                    key={key}
                    id={key}
                    text={reportFields[key].label}
                    fields={reportFields}
                    fieldsFn={setReportFields}
                    validator={validator}
                    validatorFn={setValidator}
                    origin="report"
                  />
                );
              })}
              {Object.keys(reportFields).map((key) => {
                if (reportFields.r1.value && reportFields[key].keyName === "subject") {
                  return (
                    <ItemCheckbox
                      key={key}
                      id={key}
                      text={reportFields[key].label}
                      fields={reportFields}
                      fieldsFn={setReportFields}
                      validator={validator}
                      validatorFn={setValidator}
                      origin="report"
                    />
                  );
                }
              })}
            </Row>
          </Col>

          {/* Campos de Investigación */}
          <Col className="container-fields" style={{display: (reportFields.r1.value || !reportFields.r7.value) && 'None'}}>
            <Row>
              <MCLabel
                text={t("Creator.select_fields_investigation")}
                helptip={t("Creator.fields_optional")}
                />
            </Row>
            <Row className="mt-3 mb-3">
              {Object.keys(investigationFields).map((key) => {
                if (reportFields.r1.value) return;
                return (
                  <ItemCheckbox
                    key={key}
                    id={key}
                    text={investigationFields[key].label}
                    fields={investigationFields}
                    fieldsFn={setInvestigationFields}
                    validator={validator}
                    validatorFn={setValidator}
                    origin="investigation"
                  />
                );
              })}
            </Row>
          </Col>
        </Row>
        {/* Campo donde se mostrará la contraseña o se vuelva a generar */}
        <Row className="justify-content-center mb-4">
          <Row className="text-left">
            <MCLabel text={t("Creator.password")} />
          </Row>
          <Row>
            {requiredPassword && (
              <>
                <Col md={6} className="text-center mt-2">
                  <MCInput
                    type={showPassword ? "text" : "password"}
                    className="text-center"
                    value={password}
                    disabled={true}
                  />
                </Col>
                <Col className="text-center mt-2">
                  {/* Crear 4 botones, 1 para regenerar la contraseña, otro para copiarla y otro para mostrarla */}
                  <MCTooltip
                    text={t("Creator.create_new_password")}
                    position="bottom"
                  >
                    <button
                      className="btn btn-primary mx-1 icon-btn"
                      onClick={() => createRandomPassword(13)}
                    >
                      <OutlineRefresh />
                    </button>
                  </MCTooltip>
                  <MCTooltip text={t("Creator.copy_password")} position="bottom">
                    <button
                      className="btn btn-primary mx-1 icon-btn"
                      onClick={() => copyPassword()}
                    >
                      <OutlineCopy />
                    </button>
                  </MCTooltip>
                  <MCTooltip
                    text={
                      !showPassword
                        ? t("Creator.show_password")
                        : t("Creator.hide_password")
                    }
                    position="bottom"
                  >
                    <button
                      className="btn btn-primary mx-1 icon-btn"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <OutlineEyeSlash /> : <OutlineEye />}
                    </button>
                  </MCTooltip>
                  <MCTooltip text={t("pdf_fields.not_use_password")} position="bottom">
                    <button
                      className="btn btn-primary mx-1 icon-btn"
                      onClick={handlePasswordMode}
                    >
                      <BoldUnlock />
                    </button>
                  </MCTooltip>
                </Col>
              </>
            )}
            {!requiredPassword && (
              <>
                <Col className="text-center mt-2">
                  <MCTooltip text={t("pdf_fields.use_password")} position="bottom">
                    <button
                      className="btn btn-primary mx-1 icon-btn"
                      onClick={handlePasswordMode}
                    >
                      <BoldLock />
                    </button>
                  </MCTooltip>
                </Col>
              </>
            )}
          </Row>
        </Row>
        {/* Boton para generar PDF */}
        <Row className="justify-content-center">
          <button
            className="btn btn-primary"
            onClick={() => sendPayload()}
            disabled={validator <= 0}
          >
            {t("Creator.generate_pdf")}
          </button>
        </Row>
      </Modal.Body>
    </>
  );
};
