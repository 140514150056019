import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { GeneralHeader } from "../../../../Title/GeneralHeader";
import "./Entrevista.scss";
import { useTranslation } from "react-i18next";

export const AnalyzeInterview = () => {
  const [t] = useTranslation("investigation");

  const returnRequired = (req) => {
    if (req) return t("Interview.required_question");
    return t("Interview.optional_question");
  };

  const kind = (kn) => {
    if (kn === "in-person") return t("Interview.in_person");
    if (kn === "video-conference") return t("Interview.videocall");
    return t("Interview.unspecified");
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { gTheme } = useSelector((state) => state.theme);
  const investigationAlias = location.state.investigationAlias;
  const interviews = location?.state?.interviews || null;
  const currentInterview = location?.state?.currentInterview || null;
  const currentInterviewIndex = location?.state?.index;
  const [interview, setInterview] = useState(
    Array.isArray(currentInterview?.interview)
      ? currentInterview?.interview
      : []
  );

  return (
    <div className={`page ${gTheme !== "light" ? "dark-mode" : ""}`}>
      <GeneralHeader title={t("Interview.interview_summary")} />

      <div className="dyTheme1 dyBorder1 rounded px-3 py-4 mt-4">
        <div className="d-block d-sm-flex align-items-end justify-content-between">
          <div>
            <h3>
              {`${t("Interview.interviews")} - ${
                currentInterview?.interviewed
              }`}
            </h3>
          </div>
        </div>

        <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
          <div className="d-block d-md-flex align-items-end justify-content-end">
            <MCButton
              label={kind(currentInterview.kind)}
              className="small-info-btn my-2 start_interview_btn"
            />
            {/* <CDBBtn className="small-info-btn my-2 start_interview_btn">
              {kind(currentInterview.kind)}
              <CDBIcon className="ml-2" icon="link" />
            </CDBBtn> */}
            {/* <div className="d-flex my-2 justify-content-between">
              <CDBBtn className="download-btn mr-2">
                <CDBIcon className="mr-md-2" icon="upload" />
                <span className="d-none d-md-inline-block">subir</span>
              </CDBBtn>
              <CDBBtn className="download-btn mr-2">
                <CDBIcon className="mr-md-2" icon="download" />
                <span className="d-none d-md-inline-block">descargar</span>
              </CDBBtn>
              <CDBBtn className="download-btn mr-2">
                <CDBIcon className="mr-md-2" icon="print" />
                <span className="d-none d-md-inline-block">imprimir</span>
              </CDBBtn>
              <CDBBtn className="download-btn mr-2">
                <CDBIcon icon="question-circle" />
                <span className="d-none d-md-inline-block">ayuda</span>
              </CDBBtn>
            </div> */}
          </div>

          <h6>
            {`${t("Interview.interviewer")}: ${
              currentInterview?.interviewer?.name || t("Interview.unspecified")
            }`}
          </h6>
          <h6 className="my-3">{t("Interview.objectives_topics")}</h6>
          <small>
            {currentInterview?.details || t("Interview.unspecified")}
          </small>
        </div>
      </div>

      <div className="mt-4 p-20 dyTheme1 dyBorder1 rounded">
        {interview.map((item, idx) => {
          return (
            <div className="p-20 p-md-4 mt-20 dyBorder1 rounded" key={idx}>
              <div className="pb-3 border-bottom">
                <h6 className="m-0">
                  {t("Interview.question")} {idx + 1}
                </h6>
              </div>
              <small className="mt-20">{item?.question}</small>
              <span>{item.answer}</span>
              <small className="mt-20">
                {t("Interview.interviewer_comments")}
              </small>
              <span>{item.observations}</span>
              <div className="d-block d-sm-flex align-items-center text-center mt-20">
                <small className="ms-3">{returnRequired(item?.required)}</small>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="mt-4 p-20 dyTheme1 dyBorder1 rounded">
        <div className="p-20">
          <h5 className="m-0">{t("investigation.interviews.conclusion")}</h5>
          <small className="mt-20">
            {t("investigation.interviews.conclusion_field")}
          </small>
          <MCInput
            type="textarea"
            rows="3"
            placeholder={t("investigation.interviews.conclusion_placeholder")}
          />
        </div>
        <div className="text-center text-md-right mt-30">
          <MCButton
            label="Regresar"
            variant="primary"
            className="me-3"
            outline
            onClick={() => navigate(-1)}
          />
          <Link to="/report/all">
            <CDBBtn
              className="athics-outline-btn my-2 mr-sm-3 w-200"
              outline
              onClick={finish_interview}
            >
              CANCELAR
            </CDBBtn>
          </Link>
          <Link to="/report/all">
            <CDBBtn
              className="athics-btn my-2 w-200"
              onClick={finish_interview}
            >
              FINALIZAR
            </CDBBtn>
          </Link>
        </div>
      </div> */}
    </div>
  );
};
