import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Row, Col, Card } from "react-bootstrap";
import { CDBIcon } from "cdbreact";
import "react-datepicker/dist/react-datepicker.css";
import Mexico from "../../../../../assets/images/mexico.png";
import _ from "lodash";
import { patchInvestigation } from "../../../../../apis";
import { parseDatoToLgAndHZ } from "../../../../../helpers/dateTransform";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents/Input/Input";
import Icon from "../../../../Icon/Icon";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { GeneralHeader } from "../../../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

export const CustodyEvidence = () => {
  const [t] = useTranslation("investigation");

  const kindMoveOpts = [
    {
      value: "income",
      label: t("Custody.evidence_intake"),
    },
    {
      value: "collection",
      label: t("Custody.evidence_collection"),
    },
    {
      value: "move",
      label: t("Custody.evidence_movements"),
    },
  ];

  const kindLabel = (kind) => {
    if (kind === "income") return t("Custody.evidence_intake");
    if (kind === "collection") return t("Custody.evidence_collection");
    if (kind === "move") return t("Custody.evidence_movements");
    return t("investigation.custody.undefined");
  };

  const returnColor = (kind) => {
    if (kind === "income") return "border-left-success";
    if (kind === "collection") return "border-left-danger";
    if (kind === "move") return "border-left-info";
    return "border-left-info";
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const [gps, setGps] = useState("");
  const [kind, setKind] = useState(null);
  const [place, setPlace] = useState("");
  const [status, setStatus] = useState("");
  const [package_, setPackage_] = useState("");
  const [location, setLocation] = useState("");
  const [collector, setCollector] = useState("");
  const [facilitator, setFacilitator] = useState("");
  const [date_collection, setDate_collection] = useState(new Date());

  const investigationAlias = state.investigationAlias;
  const evidences = state.evidences;
  const currentEvidence = state.currentEvidence;
  const indexCurrentEvidence = state.indexCurrentEvidence;
  const currentFile = state.currentFile;
  const currentCocs = state.currentFile?.coc || null;
  const indexCurrentFile = state.indexCurrentFile;

  const handleSubmit = async () => {
    let newCocs;
    try {
      const newCoc = {
        gps,
        kind: kind.value || null,
        place,
        status,
        package: package_,
        location,
        collector,
        facilitator,
        date_collection,
      };
      if (Array.isArray(currentCocs)) {
        newCocs = _.cloneDeep(currentCocs);
      } else {
        newCocs = [];
      }
      newCocs.push(newCoc);

      let newForm = _.cloneDeep(evidences);
      _.set(
        newForm,
        `${indexCurrentEvidence}.files.${indexCurrentFile}.coc`,
        newCocs
      );

      const objToSave = {
        folder: {
          evidences: newForm,
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      //   console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="custody-page page">
      <GeneralHeader title={t("Custody.chain_custody")} />

      <Row>
        <Col lg="6" className="my-3 ps-lg-0">
          <div className="p-20 dyTheme1 dyBorder1 rounded">
            <div className="pb-3 border-bottom">
              <h5 className="mt-20">{`${
                currentEvidence?.name || t("Custody.unnamed_evidence")
              } `}</h5>
              <small className="mt-20">{`(${
                currentFile?.name || t("Custody.unnamed_file")
              })`}</small>
            </div>
            <h6 className="my-4">{t("Custody.new_activity")}</h6>
            <small className="mb-2">{t("Custody.activity_type")}:</small>
            <MCSelect options={kindMoveOpts} onChange={setKind} />
            <small className="mt-20 mb-2">{t("Custody.location")}:</small>
            <MCInput
              placeholder={t("Custody.enter_location")}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <div className="py-5 px-3 dyBorder1 rounded mt-20">
              <small className="mb-2">{t("Custody.custodian_evidence")}:</small>
              <MCInput
                placeholder={t("Custody.designate_custodian_evidence")}
                value={collector}
                onChange={(e) => setCollector(e.target.value)}
              />

              <Row>
                <Col md="12" className="p-0 my-2">
                  <small className="mb-2">
                    {t("Custody.occurrence_date")}:
                  </small>
                  <DatePicker
                    className="form-control"
                    showTimeSelect
                    selected={date_collection}
                    onChange={(d) => setDate_collection(d)}
                    dateFormat="MMMM d, yyyy, hh:mm a"
                  />
                </Col>
              </Row>
              <small className="mt-20 mb-2">
                {t("Custody.evidence_condition_upon_receipt")}:
              </small>
              <MCInput
                type="textarea"
                rows="4"
                placeholder={t(
                  "Custody.description_evidence_condition_upon_receipt"
                )}
                onChange={(e) => setStatus(e.target.value)}
              />
            </div>
            <div className="p-20 dyBorder1 rounded mt-20">
              <small className="mb-2">{t("Custody.receiver")}:</small>
              <MCInput
                placeholder={t("Custody.enter_name_receiving")}
                value={facilitator}
                onChange={(e) => setFacilitator(e.target.value)}
              />
              <small className="mt-20 mb-2">
                {t("Custody.location_receiver")}:
              </small>
              <MCInput
                placeholder={t("Custody.enter_location_recipient")}
                value={place}
                onChange={(e) => setPlace(e.target.value)}
              />
            </div>

            <div className="text-center text-md-right mt-20">
              <MCButton
                label={t("Custody.cancel")}
                variant="primary"
                className="me-3"
                width={160}
                outline
                onClick={() => navigate(-1)}
              />
              <MCButton
                label={t("Custody.save")}
                variant="primary"
                width={160}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Col>
        <Col lg="6" className="my-3 pe-lg-0">
          <div className="dyTheme1 dyBorder1 rounded p-20 mb-3">
            <h6 className="font-14 mb-0">
              {Array.isArray(currentCocs) && currentCocs.length > 0
                ? t("Custody.history")
                : t("Custody.no_history")}
            </h6>
          </div>
          {Array.isArray(currentCocs) ? (
            currentCocs.map((item, id) => (
              <div
                className={`dyTheme1 dyBorder1 rounded card mb-3 ${returnColor(
                  item.kind
                )}`}
                key={id}
              >
                <Card.Body>
                  <Card.Title className="mt-0">
                    <small>
                      {parseDatoToLgAndHZ(
                        item.date_collection,
                        t("Custody.locale")
                      )}
                    </small>
                    <small>{kindLabel(item.kind)}</small>
                  </Card.Title>
                  <div className="card-text mt-20">
                    <span className="d-flex align-items-start">
                      <div className="custody-icon">
                        <Icon
                          className="custody-icon"
                          name={
                            item.status === "info"
                              ? "outline_location"
                              : item.status === "danger"
                              ? "outline_bus"
                              : "outline_direct_normal"
                          }
                        />
                      </div>
                      {/* outline_bus */}
                      {/* <CDBIcon
                        fas
                        icon={
                          item.status === "info"
                            ? "map-marker-alt"
                            : item.status === "danger"
                            ? "truck"
                            : "cube"
                        }
                        className="custody-icon"
                      /> */}
                      <div className="ms-2">
                        <h6 className="m-0">{t("Custody.location")}:</h6>
                        <small>{item.location}</small>
                      </div>
                    </span>
                    <Row>
                      <Col md="4" sm="12" className="mt-20">
                        <small>{t("Custody.custodian_evidence")}:</small>
                        <h6>{item.collector}</h6>
                      </Col>
                      <Col md="4" sm="12" className="mt-20">
                        <small>{t("Custody.receiver")}:</small>
                        <h6>{item.facilitator}</h6>
                      </Col>
                      <Col md="4" sm="12" className="mt-20">
                        <small>{t("Custody.location")}:</small>
                        <h6>{item.status}</h6>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </div>
            ))
          ) : (
            <div></div>
          )}

          {/* {custody.map((item,id) => {
                        return(
                            <div className={"card mb-3 border-left-" + item.status} key={id} style={ DynamicTheme } >
                                <CDBCardBody>
                                    <CDBCardTitle className="mt-0">
                                        <small>{item.date}</small>
                                        <small>{item.type}</small>
                                    </CDBCardTitle>
                                    <div className="card-text mt-20">
                                        <span className="d-flex align-items-start">
                                            <CDBIcon fas icon={item.status === "info" ? "map-marker-alt" : item.status === "danger" ? "truck" : "cube"} className="custody-icon" />
                                            <div className='ml-2'>                                        
                                                <h6 className='m-0'>{item.place1}</h6>
                                                <small>{item.place2}</small>
                                            </div>
                                        </span>
                                        <Row>
                                            <Col md="4" sm="12" className='mt-20'>
                                                <small>Persona que recolectó:</small>
                                                <h6>{item.person}</h6>
                                            </Col>
                                            <Col md="4" sm="12" className='mt-20'>
                                                <small> Fecha de recolección:</small>
                                                <h6>{item.collection_date}</h6>
                                            </Col>
                                            <Col md="4" sm="12" className='mt-20'>
                                                <small> Persona que recolectó:</small>
                                                <h6>{item.collection_time}</h6>
                                            </Col>
                                        </Row>
                                    </div>
                                </CDBCardBody>
                            </div>
                        )
                    })} */}
        </Col>
      </Row>
    </div>
  );
};
