import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { createAttachment, patchInvestigation } from "../../../../../apis";
import Icon from "../../../../Icon/Icon";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { GeneralHeader } from "../../../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

export const ConfigEvidence = () => {
  const [t] = useTranslation("investigation");

  const facilitatorOpts = [
    {
      value: "human-resources",
      label: t("Evidences.facilitator"),
    },
    {
      value: "other",
      label: t("Evidences.other"),
    },
  ];

  const compromisedOpts = [
    {
      value: false,
      label: t("Evidences.intact"),
    },
    {
      value: true,
      label: t("Evidences.compromised"),
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const isNew = location.pathname === "/evidence/new" ? true : false;
  const investigationAlias = location.state.investigationAlias;
  const evidences = location.state.evidences;
  const currentEvidence = location.state.currentEvidence;
  const indexCurrentEvidence = location.state.index;

  const inputFiles = useRef(null);
  const [filesArray, setfilesArray] = useState([]);
  const [files, setFiles] = useState(null); //* Poner el arrastre de attachment del formulario
  const [existingFiles, setExistingFiles] = useState(
    currentEvidence?.files || null
  );

  const [kind, setKind] = useState(
    currentEvidence?.kind || t("Evidences.physical")
  ); //*  Es con los botones de fisica y digital
  const [status, setStatus] = useState(currentEvidence?.status || ""); //* Este en automatico esta en activo
  const [name, setName] = useState(currentEvidence?.name || "");
  const [upload_by, setUpload_by] = useState(currentEvidence?.upload_by || "");
  const [relationship, setRelationship] = useState(
    currentEvidence?.relationship || ""
  );
  const [description, setDescription] = useState(
    currentEvidence?.description || ""
  );
  const [created_by, setCreated_by] = useState(
    currentEvidence?.information?.created_by || ""
  ); //? Conducido por: 1
  const [position, setPosition] = useState(
    currentEvidence?.information?.position || ""
  ); //? Puesto: 2
  const [descriptionInfo, setDescriptionInfo] = useState(
    currentEvidence?.information?.description || ""
  ); //?  Descripción: 3
  const [budget, setBudget] = useState(
    currentEvidence?.information?.budget || 0
  ); //? Presupuesto: 4
  const [deadline, setDeadline] = useState(
    currentEvidence?.information?.deadline || 0
  ); //? Tiempo estimado de ejecución: 6
  const [facilitator, setFacilitator] = useState(
    (currentEvidence?.information?.facilitator &&
      facilitatorOpts.find(
        (item) => item.value === currentEvidence?.information?.facilitator
      )) ||
      null
  ); //? Facilitador: 7
  const [measures, setMeasures] = useState(
    currentEvidence?.information?.measures || ""
  ); //? Medidas de seguridad: 8
  const [compromised, setCompromised] = useState(
    (typeof currentEvidence?.information?.compromised === "boolean" &&
      compromisedOpts.find(
        (item) => item.value === currentEvidence?.information?.compromised
      )) ||
      null
  ); //? Resguardo comprometido: 9

  const [details, setDetails] = useState(
    currentEvidence?.information?.details || ""
  ); //? Descripción de los hallazgos: 10
  const [comment, setComment] = useState(
    currentEvidence?.information?.comment || ""
  ); //? Conclusiones de la evidencia: 11
  const [created_at, setCreated_at] = useState(new Date()); //? Fecha de ejecución: 5

  const handleSubmit = async () => {
    let newForm;
    let files_id;
    try {
      if (files) {
        const filesRes = await createAttachment(files);
        files_id = filesRes.attachments_id;
      }
      const filesToSend = filesArray.map((file, idx) => ({
        file: files_id[idx],
        name: file.name,
        purpose: "General",
        coc: null,
      }));
      const newEvidence = {
        kind,
        status,
        name,
        files: filesToSend,
        upload_by,
        description,
        information: {
          budget,
          comment,
          details,
          deadline,
          measures,
          position,
          created_at,
          created_by,
          compromised: compromised !== null ? compromised.value : null,
          description: descriptionInfo,
          facilitator: facilitator !== null ? facilitator.value : null,
        },
        relationship,
      };
      if (evidences === null) {
        newForm = [];
      } else {
        newForm = _.cloneDeep(evidences);
      }
      newForm.push(newEvidence);

      const objToSave = {
        folder: {
          evidences: newForm,
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      // console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    let newForm;
    let files_id;
    try {
      if (files) {
        const filesRes = await createAttachment(files);
        files_id = filesRes.attachments_id;
      }
      const newFiles = filesArray.map((file, idx) => ({
        file: files_id[idx],
        name: file.name,
        purpose: "General",
        coc: null,
      }));
      const filesToSend = [
        ...(existingFiles ? existingFiles : []),
        ...newFiles,
      ];

      const newEvidence = {
        kind,
        status,
        name,
        files: filesToSend,
        upload_by,
        description,
        information: {
          budget,
          comment,
          details,
          deadline,
          measures,
          position,
          created_at,
          created_by,
          compromised: compromised !== null ? compromised.value : null,
          description: descriptionInfo,
          facilitator: facilitator !== null ? facilitator.value : null,
        },
        relationship,
      };
      newForm = _.cloneDeep(evidences);
      console.log(newEvidence);
      _.set(newForm, `${indexCurrentEvidence}`, newEvidence);
      const objToSave = {
        folder: {
          evidences: newForm,
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  // FOR ATTACHMENTS
  const hdlAttch = (e) => {
    const newFiles = [...e.target.files];
    setfilesArray((prevFiles) => [...prevFiles, ...newFiles]);
    const formData = files || new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("attachments", newFiles[i]);
    }
    setFiles(formData);
  };

  const hdlDragOvAttch = (e) => {
    e.preventDefault();
  };

  const hdlDropAttch = (e) => {
    e.preventDefault();
    const newFiles = [...e.dataTransfer.files];
    setfilesArray((prevFiles) => [...prevFiles, ...newFiles]);
    const formData = files || new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("attachments", newFiles[i]);
    }
    setFiles(formData);
  };

  return (
    <div className="new-evidence-page page">
      <GeneralHeader
        title={`${
          isNew ? t("Evidences.new_evidence") : t("Evidences.review_evidence")
        }`}
      />

      <div className="my-4 p-20 dyTheme1 dyBorder1 rounded">
        <h4>{t("Evidences.evidence_information")}</h4>
        <Row>
          <Col lg="4" md="6" className="p-0 mt-2">
            <div className="pr-md-30">
              <div className="d-flex align-items-center">
                <small className="me-1 mb-1">{t("Evidences.name")}:</small>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip placement="right" style={{ marginTop: 15 }}>
                      <strong>{t("Evidences.enter_evidence_name")}</strong>
                    </Tooltip>
                  }
                >
                  <div className="d-flex justify-content-end aling-items-center pointer">
                    <Icon name="question_bubble_bold" size={18} />
                  </div>
                </OverlayTrigger>
              </div>
              <MCInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Col>
          <Col lg="4" md="6" className="p-0 mt-2">
            <div className="pr-md-30 pl-md-30">
              <small className="mb-1">{t("Evidences.upload_by")}:</small>
              <MCInput
                type="text"
                value={upload_by}
                onChange={(e) => setUpload_by(e.target.value)}
              />
            </div>
          </Col>
          <Col lg="4" md="6" className="p-0 mt-2">
            <div className="pl-md-30">
              <small className="mb-1">
                {t("Evidences.company_relationship")}:
              </small>
              <MCInput
                type="text"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-4 p-20 dyTheme1 dyBorder1 rounded">
        <small className="mt-20">{t("Evidences.what_type_evidence")}</small>
        <div>
          <MCButton
            label={t("Evidences.physical")}
            variant="primary"
            width={160}
            outline={!(kind === "fisic")}
            className="me-3"
            onClick={() => setKind("fisic")}
            disabled={!isNew}
          />
          <MCButton
            label={t("Evidences.digital")}
            variant="primary"
            width={160}
            outline={!(kind === "digital")}
            onClick={() => setKind("digital")}
            disabled={!isNew}
          />
        </div>
        {kind === "digital" && (
          <>
            <div
              className={`preview-input-container fade-in-image mt-20`}
              onDrop={hdlDropAttch}
              onDragOver={hdlDragOvAttch}
            >
              <div
                className="attachments pointer"
                onClick={() => inputFiles.current.click()}
              >
                <div className="attachments-icon-container">
                  <Icon name="gallery" />
                  <Icon name="outline_clipboard_text" />
                  <Icon name="volume_high" />
                  <Icon name="outline_video" />
                  <Icon name="outline_document" />
                </div>

                <div className="attachments-instructions">
                  <p>{t("Evidences.drag_file")}</p>
                  <button className="btn attachments-btn">
                    {t("Evidences.select_file")}
                  </button>
                </div>
              </div>
              <input
                className={`form-control w-100`}
                type="file"
                onChange={hdlAttch}
                multiple
                hidden
                ref={inputFiles}
              />
            </div>
            <Row>
              {existingFiles !== null && !isNew && (
                <>
                  <h6 className="mt-20">{t("Evidences.digital_evidence")}</h6>
                  {existingFiles.map((item, idx) => (
                    <div key={idx}>
                      <Col key={idx} lg="3" md="6" className="p-0">
                        <div className="pr-md-30">
                          <MCButton
                            label={item.name}
                            icon="more_dots"
                            className="download-btn w-100 my-2"
                            size="sm"
                            onClick={() =>
                              navigate("/evidence/custody", {
                                state: {
                                  investigationAlias,
                                  evidences,
                                  currentEvidence,
                                  indexCurrentEvidence,
                                  currentFile: item,
                                  indexCurrentFile: idx,
                                },
                              })
                            }
                          />
                        </div>
                      </Col>
                    </div>
                  ))}
                </>
              )}
              <></>
              {files !== null && (
                <>
                  <h6 className="mt-20">{t("Evidences.upload_evidence")}</h6>
                  {[...files.entries()].map((item, idx) => (
                    <Col key={idx} lg="3" md="6" className="p-0">
                      <div className="pr-md-30">
                        <MCButton
                          label={item[1].name}
                          className="download-btn w-100 my-2"
                          size="sm"
                          icon="outline_send_square"
                        />
                      </div>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </>
        )}
      </div>
      <div className="mb-4 p-20 dyTheme1 dyBorder1 rounded">
        <h5>{t("Evidences.evidence_objectives")}</h5>
        <small className="mt-20 mb-2">
          {t("Evidences.description_objectives_evidences")}:
        </small>
        <MCInput
          type="textarea"
          rows="3"
          placeholder={t("Evidences.enter_objectives_evidences")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="mb-4 p-20 dyTheme1 dyBorder1 rounded">
        <h5>{t("Evidences.extra_information")}</h5>
        <Row className="mt-20">
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.managed_by")}:</small>
            <MCInput
              type="text"
              value={created_by}
              onChange={(e) => setCreated_by(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.job_position")}:</small>
            <MCInput
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.description")}:</small>
            <MCInput
              type="text"
              value={descriptionInfo}
              onChange={(e) => setDescriptionInfo(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.budget")}:</small>
            <MCInput
              type="number"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.created_at")}:</small>
            <DatePicker
              selected={created_at}
              onChange={(date) => setCreated_at(date)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.deadline")}:</small>
            <MCInput
              type="number"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.facilitator")}:</small>
            <MCSelect
              options={facilitatorOpts}
              value={facilitator}
              onChange={setFacilitator}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.measures")}:</small>
            <MCInput
              type="text"
              value={measures}
              onChange={(e) => setMeasures(e.target.value)}
            />
          </Col>
          <Col lg="4" md="6" className="my-2">
            <small className="mb-1">{t("Evidences.is_compromised")}</small>
            <MCSelect
              options={compromisedOpts}
              value={compromised}
              onChange={setCompromised}
            />
          </Col>
          <Col lg="6" md="12" className="my-2">
            <small className="mb-1">
              {t("Evidences.description_findings")}:
            </small>
            <MCInput
              type="textarea"
              rows="3"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Col>
          <Col lg="6" md="12" className="my-2">
            <small className="mb-1">
              {t("Evidences.evidence_conclusions")}:
            </small>
            <MCInput
              type="textarea"
              rows="3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Col>
        </Row>
        {/* <pre>{JSON.stringify(facilitator,null,2)}</pre> */}
        <div className="text-center text-md-right mt-20">
          <MCButton
            label={t("Evidences.cancel")}
            variant="primary"
            width={160}
            outline
            className="me-2"
            onClick={() => navigate(-1)}
          />
          <MCButton
            label={t("Evidences.save")}
            variant="primary"
            width={160}
            onClick={() => (isNew ? handleSubmit() : handleUpdate())}
          />
        </div>
      </div>
    </div>
  );
};
