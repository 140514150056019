import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import { getListBusiness, updatedStatus } from "../../../apis/apiClientConfig";
import { MCButton, MCTooltip } from "../../../components/MainComponents";
import { GeneralIconHeader } from "../../../components/Title/GeneralHeader";
import { useSnackbar } from "notistack";
import { Loading } from "../../../components/Loading/String/Loading";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DataTable } from "../../../components/DataTable/DataTable";
import {
  AddIconSquare,
  BoldDanger,
  OutlineEye,
  OutlineEyeSlash,
  Settings2,
} from "../../../components/Icon";
import { Breadcrum } from "../../../components/MainComponents/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import "./Business.scss";

export const BusinessUnitList = () => {
  //--- State ---//
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [alias, setAlias] = useState("");
  const [label, setLabel] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  //--- Hooks ---//
  const { enqueueSnackbar } = useSnackbar();
  const { gTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [t] = useTranslation("settings");
  //Set it if close/open modal
  const handleModal = () => setShow((show) => !show);
  //Show possible errors
  const showError = (error) => {
    enqueueSnackbar(`${error.message}`, {
      variant: "error",
      autoHideDuration: 15000,
    });
  };

  //--- get List Business Info ---//
  const getInfo = () => {
    getListBusiness()
      .then((resp) => setBusinessUnitList(resp.data))
      .then(setLoading(false))
      .catch((err) => {
        showError(err);
      });
  };
  useEffect(() => {
    getInfo();
  }, []);
  //--- Enable Bussiness Unit ---//
  const handleEnable = async (alias, currentStatus, label) => {
    if (currentStatus === true) return;
    try {
      const resp = await updatedStatus({
        alias,
        is_active: true,
      });
      setBusinessUnitList(
        businessUnitList.map((item) =>
          item.alias === alias ? { ...item, is_active: true } : item
        )
      );
      enqueueSnackbar(`${label} ${t("business_units.successfully_enabled")}`, {
        variant: "success",
      });
      //--- update immediately ---//
      // getInfo();
    } catch (error) {
      showError(error);
    }
  };
  //--- Disable Bussiness Unit --//
  const handleDisable = async (alias, currentStatus) => {
    if (currentStatus === false) return;
    try {
      const resp = await updatedStatus({
        alias,
        is_active: false,
      });
      setBusinessUnitList(
        businessUnitList.map((item) =>
          item.alias === alias ? { ...item, is_active: false } : item
        )
      );
      // getInfo();
      handleModal();
      enqueueSnackbar(`${label} ${t("business_units.successfully_disabled")}`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 15000,
      });
    }
  };

  //--- Data Column Info ---//
  //Order to appear columns
  const columns = ["label", "actions"];
  //Custom titles
  const customTitle = {
    label: t("business_units.business_unit"),
    actions: t("business_units.actions"),
  };
  //Action buttons
  const actions = (row) => (
    <div className="actionBtn">
      <MCTooltip text={t("business_units.configure")} position="bottom">
        <button
          className="actionBtn__edit"
          onClick={() => navigate(`/settings/business/${row.alias}`)}
        >
          <Settings2/>
        </button>
      </MCTooltip>
      {row.is_active ? (
        <MCTooltip text={t("business_units.disable")} position="bottom">
          <button
            className="actionBtn__enable"
            onClick={(e) => {
              handleModal();
              setAlias(row.alias);
              setCurrentStatus(row.is_active);
              setLabel(row.label);
            }}
          >
            <OutlineEye />
          </button>
        </MCTooltip>
      ) : (
        <MCTooltip text={t("business_units.enable")} position="bottom">
          <button
            className="actionBtn__disable"
            onClick={(e) => {
              handleEnable(row.alias, row.is_active, row.label);
            }}
          >
            <OutlineEyeSlash />
          </button>
        </MCTooltip>
      )}
    </div>
  );
  const breadcrumbItems = [
    { label: t("business_units.home"), route: "/" },
    { label: t("business_units.settings"), route: "/settings" },
    { label: t("business_units.business_units") },
  ];
  return (
    <div id="settings" className="unitList">
      <GeneralIconHeader
        title={t("business_units.setup_business_unit")}
        text={t("business_units.description")}
        icon="buildings"
      />

      <Breadcrum items={breadcrumbItems} />

      <Modal
        show={show}
        onHide={handleModal}
        className={`modalUnitList ${gTheme !== "light" ? "dark-mode" : ""}`}
        centered
      >
        <Modal.Header className="modalUnitList__header">
          <BoldDanger className="modalUnitList__icon" />
          <h4 className="modalUnitList__title">
            {`${t("business_units.disable")} ${t(
              "business_units.business_unit"
            )}`}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="modalUnitList__text">
            <span>
              {`${t("business_units.by_disibling")}`}{" "}<b>{label}</b>,{" "}
              {`${t("business_units.will_become_inactive")}`}
            </span>
          </div>
          <div className="modalUnitList__actionBtns">
            <MCButton
              label={t("business_units.cancel")}
              variant="secondary"
              onClick={handleModal}
            />
            <MCButton
              label={t("business_units.confirm")}
              variant="danger"
              onClick={() => {
                handleDisable(alias, currentStatus);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {loading ? (
        <Loading />
      ) : (
        <div className="dyTheme1 rounded p-20 mt-20 unitList__list">
          <MCTooltip text={t("business_units.add")} position="bottom">
            <button
              className="unitList__addNew"
              onClick={() => navigate("/settings/business/create")}
            >
              <AddIconSquare />
            </button>
          </MCTooltip>
          <DataTable
            columns={columns}
            data={businessUnitList}
            actions={actions}
            customTitle={customTitle}
          />
        </div>
      )}
    </div>
  );
};
