import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { patchInvestigation } from "../../../../../apis";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCTooltip } from "../../../../MainComponents/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

export const AddPlan = ({ investigationAlias, formValues, toggleModal }) => {
  const [t] = useTranslation("investigation");

  const [kind, setKind] = useState(""); // pending || process || completed
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [todos, setTodos] = useState([]);
  const [todoInput, setTodoInput] = useState("");
  const [party, setParty] = useState([]);
  const [partyInput, setPartyInput] = useState("");

  const handleSubmit = async (evt) => {
    let newPlans;
    evt.preventDefault();
    if (formValues === null) {
      newPlans = [];
    } else {
      newPlans = _.cloneDeep(formValues);
    }
    newPlans.push({
      kind,
      name,
      details,
      deadline: 0,
      party: ["vbn"],
      todo: ["fgh"],
      status: "pending",
      created_at: new Date(),
    });
    const objToSave = {
      folder: {
        plans: newPlans,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  const handleAddTodo = () => {
    setTodos([...todos, todoInput]);
    setTodoInput("");
  };

  const handleAddParty = () => {
    setParty([...party, partyInput]);
    setPartyInput("");
  };

  const initialDate = new Date();

  return (
    <div className="modal-add-plan">
      <Row>
        <h5>{t("Plan.add_activity_plan")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">{t("Plan.date")}:</small>
          <MCInput type="date" selected={initialDate} />
        </Col>
        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">{t("Plan.name")}:</small>
          <MCInput
            type="text"
            placeholder={t("Plan.enter_plan_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col md={12} lg={12} className="input-container">
          <small className="my-2 d-block">{t("Plan.description")}:</small>
          <MCInput
            type="textarea"
            placeholder={t("Plan.enter_plan_description")}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Col>

        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">
            {t("Plan.to_do_list")}:
          </small>
          <ul>
            {todos.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          <div className="d-flex align-items-center">
            <MCInput
              type="text"
              placeholder={t("Plan.enter_to_do")}
              value={todoInput}
              onChange={(e) => setTodoInput(e.target.value)}
            />
            <MCTooltip
              position="bottom"
              text={t("Plan.add_task")}
            >
              <div className="me-2">
                <MCButton
                  label=""
                  icon="add_icon"
                  variant="primary"
                  size="sm"
                  className="add-icon ms-1 p-0"
                  width={28}
                  outline
                  onClick={handleAddTodo}
                />
              </div>
            </MCTooltip>
          </div>
        </Col>
        <Col md={6} lg={6} className="input-container">
          <small className="my-2 d-block">
            {t("Plan.add_people_task")}:
          </small>
          <ul>
            {party.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          <div className="d-flex align-items-center">
            <MCInput
              type="text"
              placeholder={t("Plan.select_people_task")}
              value={partyInput}
              onChange={(e) => setPartyInput(e.target.value)}
            />
            <MCTooltip
              position="bottom"
              text={t("Plan.add_person")}
            >
              <div className="me-2">
                <MCButton
                  label=""
                  icon="add_icon"
                  variant="primary"
                  size="sm"
                  className="add-icon ms-1 p-0"
                  width={28}
                  outline
                  onClick={handleAddParty}
                />
              </div>
            </MCTooltip>
          </div>
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("Plan.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("Plan.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
