import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './Mobilestyles.scss';
import { login, retrieveCatalogueByPath } from "../../apis";
import { searchNavbarAPI } from '../../apis/apiReports';
import {
    catalogByPart,
    listCatalogues,
} from "../../apis/apiCatalogues";
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { MCButton } from "../MainComponents";
import { useTranslation } from "react-i18next";
// import DatePicker from 'react-datepicker';
import {
    SearchIcon2,
    OutlineMessages3,
    Monitor,
    Call,
    OutlineSms,
    OutlineMessages2,
    Mobile,
    Grammerly,
    CdIcon,
    OutlineSmsTracking,
    OutlineWhatsapp,
    CalendarSearch
} from "../Icon";
import { parseDateToISOString } from "../../helpers/dateTransform";

import { utilitiesDashboard } from "../../apis/apiDashboard";


import MultipleSelectChip from '../MainComponents/ChipSelect/ChipSelect';
// import MultipleSelectDefault from './MultipleSelectDefault';
import MultipleSelectDefault from '../MainComponents/MUISelectDefault/MultipleSelectDefault';
import MultipleSelectDefaultUno from './MobileMultipleSelectDefault';

import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español








const AdvancedSearch = ({ placeholder, onChange, options, value, onKeyDown, searchTermSecondary, onSubmit = () => { }, ...rest }, props) => {
    const [t] = useTranslation("report");
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(false);
    const modalRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [changeLabel, setChangeLabel] = useState(false);
    const [kindOfStatusValue, setKindOfStatusValue] = useState([]);

    const [kindOfCatalog, setKindOfCatalog] = useState([]);
    const [kindOfCatalogValue, setKindOfCatalogValue] = useState([]);
    const [kindOfSubCatalogValue, setKindOfSubCatalogValue] = useState([]);
    const [selects, setSelects] = useState([]);
    const [subSelects, setSubSelects] = useState([]);
    const [kindOfSubCatalogValues, setKindOfSubCatalogValues] = useState([]);
    const [kindOfSubCatalogKey, setKindOfSubCatalogKey] = useState([]);
    const [kindOfSubSubCatalogKey, setKindOfSubSubCatalogKey] = useState([]);
    const [stateData, setStateData] = useState({ utilities: [] });
    const location = useLocation();
    const [resetSubCatalog, setResetSubCatalog] = useState(false)



    useEffect(() => {
        setSearchTerm(searchTermSecondary)
    }, [searchTerm, searchTermSecondary]);




    const iconEntryway = {
        WEBSITE: {
            element: <Monitor className="icon-indicator" />,
        },
        PHONE: {
            element: <Call className="¡icon-indicator" />,
        },
        EMAIL: {
            element: <OutlineSmsTracking className=" icon-indicator" />,
            // element: <OutlineSms className=" icon-indicator" />,
        },
        CHAT: {
            element: <OutlineMessages3 className=" icon-indicator" />,
        },
        WHATSAPP: {
            element: <OutlineWhatsapp className=" icon-indicator" />,
        },
        APP: {
            element: <Mobile className="icon-indicator" />,
        },
        OUTDOOR: {
            element: <Grammerly className=" icon-indicator" />,
        },
        OTHER: {
            element: <CdIcon className="icon-indicator" />,
        },
    };



    useEffect(() => {
        // Cierra el modal cuando la URL cambie
        setSearchTerm('');
        setShowDetails(false);
        setSubSelects([]);
        setKindOfSubCatalogKey([]);
        setKindOfSubCatalogValue([]);
        setKindOfSubCatalogValues([]);
        setKindOfStatusValue([]);
        setKindOfCatalogValue([]);
        setEndDate(null);
        setStartDate(null);
    }, [location, setShowDetails]);







    const handleKindOfCatalogChange = async (selectedOptions) => {
        const selectedValue = selectedOptions.map(option => option.value);
        const labelValue = selectedOptions.map(option => option.label);
        const isOwn = selectedOptions.map(option => option.containsOWN);
        const newApiContador = selectedValue.length - 1;


        if (selectedOptions.length <= 0) {
            setSubSelects([]);
            setKindOfSubCatalogKey([]);
            setKindOfSubCatalogValue([]);
            setKindOfSubCatalogValues([]);
            setKindOfCatalogValue([]);
            return;
        }



        // Actualizar estados
        setKindOfCatalogValue(selectedValue);

        // Guardar los valores seleccionados actuales
        const previousSelectsValues = selects.map((select, index) => ({
            index,
            selectedValues: kindOfSubCatalogValues[index + 1] || [] // Asegurarse de que sea un array
        }));

        // Vaciar selects y reconstruirlos
        setSelects([]);

        // Introducir un retraso para el efecto visual
        await new Promise(resolve => setTimeout(resolve, 500));

        // Función para manejar llamadas a la API
        const fetchCatalogData = async (index) => {
            try {
                const response = await catalogByPart({
                    catalogue: selectedValue[index],
                    is_own: isOwn[index],
                    path: "/",
                });

                const newOptions = response.data.map(item => ({
                    label: item.locales[t("lang")] || item.label,
                    value: item.path || item.locales[t("lang")] || item.label,
                    path_locales: item.path_locales
                }));

                return newOptions;

            } catch (error) {
                console.error("Error fetching catalog part: ", error);
                return [];
            }
        };

        // Crear nuevos selects
        const newSelects = [];
        for (let i = 0; i <= newApiContador; i++) {
            const newOptions = await fetchCatalogData(i);
            newSelects.push({
                options: newOptions,
                value: selectedValue[i],
                label: labelValue[i]
            });
        }


        // Actualizar el estado con los nuevos selects
        setSelects(newSelects);

        // Introducir un breve retraso para asegurar el renderizado
        await new Promise(resolve => setTimeout(resolve, 100));




        for (let i = 0; i < 50; i++) {
            if (i >= newSelects.length) break;

            for (let j = 0; j < previousSelectsValues.length; j++) {
                const select = newSelects[i];
                const previous = previousSelectsValues[j] || { selectedValues: [] };
                const previousValues = previous.selectedValues || []; // Asegurarse de que sea un array

                // Ajustar la simulación de opciones
                const simulatedOptions = select.options.filter(option => {
                    const isIncluded = previousValues.includes(option.value);
                    return isIncluded;
                });




                // Solo llamar si hay valores simulados para restaurar
                if (simulatedOptions.length > 0) {
                    // Actualiza el estado de los valores seleccionados para que se reflejen en el renderizado
                    setKindOfSubCatalogValues(prevValues => {
                        const updatedValues = [...prevValues];
                        updatedValues[i] = simulatedOptions.map(option => option.value);

                        return updatedValues;
                    });
                    setResetSubCatalog(true);
                }
            }
        }




    };

    // Asegúrate de que `renderSelects` use `kindOfSubCatalogValues` para establecer el valor de cada select
    const renderSelects = () => {
        return selects.map((select, index) => {
            const currentValue = kindOfSubCatalogValues[index]?.flatMap(value => {
                const matchingOption = select.options.find(option => option.value === value);
                return matchingOption ? [matchingOption.label] : [];
            }) || [];

            return (
                <Col xs={4} md={4} xxl={4}>

                    <div className='subcatalog'>
                        {select.options.length > 0 && (


                            <MultipleSelectDefaultUno
                                title={`${t("searchBar.filterBySubCatalog")} ${select.label}`}
                                options={select.options}
                                onChange={(selectedOptions) => handleKindOfSubCatalogChange(index, selectedOptions)}
                                valuesito={currentValue}
                                resetAll={resetSubCatalog}
                                newValues={currentValue}
                            />

                        )}
                    </div>
                </Col>
            );
        });
    }





    const handleKindOfSubCatalogChange = (index, selectedOptions) => {


        setResetSubCatalog(false);

        const selectedValue = selectedOptions.map(option => option.value);
        const selectedValueKey = selectedOptions.map(option => selects[index]?.value + "::" + option.path_locales);
        const selectedLabels = selectedOptions.map(option => option.label);
        let selectedValues = selectedOptions.map(option => option.value);
        const areValuesEqual = selectedValues.every((value, i) => value === selectedLabels[i]);

        if (areValuesEqual) {
            selectedValues = selectedOptions.map(option => option.label);
        } else {
            handleKindOfSUBCatalogChange(selectedOptions, selectedValueKey, index);
        }


        setKindOfSubCatalogKey(prevKeys => {
            const updatedKeys = [...prevKeys];
            updatedKeys[index] = selectedValueKey;
            return updatedKeys;
        });

        setKindOfSubCatalogValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = selectedValue;
            return updatedValues;
        });
    };

    const handleKindOfSUBCatalogChange = (selectedOptions, selectedValueKey, index) => {
        const selectedValue = selectedOptions.map(option => option.value);
        const labelValue = selectedOptions.map(option => option.label);
        const parts = selectedValueKey[0].split("::")
        let selectedValueKeyActualized = parts[0];
        let newApiContador = selectedValue.length - 1;
        setKindOfSubCatalogValue(selectedValue);


        if (newApiContador >= 0 && selectedValue[newApiContador] != labelValue[newApiContador]) {
            catalogByPart({
                catalogue: selectedValueKeyActualized,
                is_own: false,
                path: selectedValue[newApiContador],
            }).then((response) => {
                const newOptions = response.data.map(item => ({
                    label: item.locales[t("lang")] || item.label,
                    value: item.path || item.locales[t("lang")] || item.label,
                    path_locales: item.path_locales
                }));
                setSubSelects(prevSelects => {
                    const updatedSelects = [...prevSelects];
                    if (newApiContador < updatedSelects.length) {
                        updatedSelects[newApiContador] = {
                            options: newOptions,
                            value: selectedValue,
                            label: labelValue
                        };
                    } else {
                        updatedSelects.push({
                            options: newOptions,
                            value: selectedValue,
                            label: labelValue
                        });
                    }
                    return updatedSelects.filter((_, index) => index <= newApiContador + 1);
                });

            }).catch((error) => {
                console.error("Error fetching catalog part: ", error);
            });
        } else {
            setSubSelects(prevSelects => {
                const updatedSelects = [...prevSelects];
                if (newApiContador < updatedSelects.length) {
                    updatedSelects[newApiContador] = {
                        options: "",
                        value: "",
                        label: ""
                    };
                } else {
                    updatedSelects.push({
                        options: "",
                        value: "",
                        label: ""
                    });
                }
                return updatedSelects.filter((_, index) => index <= newApiContador);
            });

        }
    };




    const getOptionsFromElement = (element) => {
        return element.childs.map((item) => {
            const valueParts = item.path.split("::");
            const value = valueParts.length > 1 ? valueParts[valueParts.length - 1] : item.path;

            const containsOWN = item.path.includes("OWN");

            return {
                label: item.locales[t("lang")] ? item.locales[t("lang")] : item.label,
                value: value,
                containsOWN: containsOWN
            };
        });
    };

    const getElementFromUtilities = (path) => {
        if (!stateData.utilities || !Array.isArray(stateData.utilities)) {
            console.warn(`Utilities not found in stateData or stateData.utilities is not an array.`);
            return [];
        }

        const elements = stateData.utilities.filter((item) => item.path === path);
        if (elements.length === 0) {
            console.warn(`Elements with path ${path} not found.`);
        }
        return elements;
    };

    const getOptionsFromElementUtilities = (path) => {
        const elements = getElementFromUtilities(path);
        let allOptions = [];


        elements.forEach((element) => {
            const options = getOptionsFromElement(element);
            allOptions = allOptions.concat(options);
        });

        return allOptions;
    };


    useEffect(() => {
        if (stateData.utilities.length > 0) {
            const options = getOptionsFromElementUtilities("filter_fields_report");
            setKindOfCatalog(options);
        }
    }, [stateData]);


    ////////////////////////////////////////////////////////////////
    // CALL TO APIS
    ////////////////////////////////////////////////////////////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await utilitiesDashboard();
                setStateData(response);
            } catch (error) {
                console.error("Error fetching utilities dashboard data: ", error);
            }
        };
        fetchData();
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await listCatalogues();
                const transformedCatalogues = response.catalogues.map(catalogue => ({
                    label: catalogue.label,
                    value: catalogue.code
                }));
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);







    const searchAndFilter = async () => {



        let fecha_inicio = startDate ? parseDateToISOString(startDate) : null;
        let fecha_fin = endDate ? parseDateToISOString(endDate) : null;

        // Limpia y extrae las claves de los catálogos
        let catalogCleanedKey = kindOfCatalogValue.filter(subArray => subArray.length > 0);
        let catalogKeys = catalogCleanedKey.map(key => key.split('::')[0]);

        // Limpia los subcatálogos y subsubcatálogos
        let subcatalogCleanedKey = kindOfSubCatalogKey.filter(subArray => subArray.length > 0);
        let subsubcatalogCleanedKey = kindOfSubSubCatalogKey.filter(subArray => subArray.length > 0);


        // Filtra los subcatálogos y subsubcatálogos que contienen las claves de los catálogos
        subcatalogCleanedKey = subcatalogCleanedKey.map(subArray =>
            subArray.filter(key => catalogKeys.includes(key.split('::')[0]))
        ).filter(subArray => subArray.length > 0);

        subsubcatalogCleanedKey = subsubcatalogCleanedKey.map(subArray =>
            subArray.filter(key => catalogKeys.includes(key.split('::')[0]))
        ).filter(subArray => subArray.length > 0);


        // Actualiza catalogCleanedKey basado en subcatalogCleanedKey y subsubcatalogCleanedKey
        if (subcatalogCleanedKey.length > 0) {
            catalogCleanedKey = subcatalogCleanedKey;
            if (subsubcatalogCleanedKey.length > 0) {
                catalogCleanedKey = subsubcatalogCleanedKey;
            }
        }


        const data = {
            folio: searchTerm,
            start_date: fecha_inicio,
            end_date: fecha_fin,
            catalog: `${catalogCleanedKey}`,
        };
        // console.log("DATA", data);
        try {
            const response = await searchNavbarAPI(data);
            const limitedResults = response.results.reports.slice(0, 7);
            setSearchResults(limitedResults);

        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                searchAndFilter();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [startDate, endDate, kindOfCatalogValue, kindOfSubCatalogKey, kindOfSubSubCatalogKey, searchTerm]);


    //aply filters and redirect

    useEffect(() => {
        searchAndFilter();
        // }, [searchTerm, startDate, endDate, kindOfEntrywaysValue, kindOfStatusValue, kindOfSeverityValue, kindOfSubCatalogKey, kindOfSubCatalogValues]);
    // }, [searchTerm]);
    }, []);
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const goToReport = (result) => {
        navigate(`/reports/${result.id}`)
        setSearchTerm('');
        setShowDetails(false);
        setKindOfCatalogValue([]);
        setKindOfSubCatalogKey([]);
        setKindOfSubCatalogValue([]);
        setKindOfSubCatalogValues([]);
        setEndDate(null);
        setStartDate(null);
    };

    dayjs.locale('es'); // Establece el idioma español como predeterminado

    return (
        <div className='mobile'>
            <>
                <div className="search-details-searcher" ref={modalRef}>
                    <Row>
                        <div className="pruebita" >
                            <Row>
                                <Col xs={4} md={4} xxl={4}>
                                    <div className="created-from">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                            <DatePicker
                                                label={t("searchBar.start_date")}
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                className="custom-date-picker"
                                                format="DD/MMM/YYYY"
                                                sx={{ width: '103%' }}
                                                disableFuture={true}
                                                slots={{ openPickerIcon: CalendarSearch }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Col>
                                {startDate && (
                                    <Col xs={4} md={4} xxl={4}>
                                        <div className="created-to">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t("searchBar.end_date")}
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                    className="custom-date-picker"
                                                    format="DD/MMM/YYYY"
                                                    sx={{ width: '103%' }}
                                                    minDate={startDate}
                                                    disableFuture={true}
                                                    slots={{ openPickerIcon: CalendarSearch }}


                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Col>
                                )}
                                <Col xs={4} md={4} xxl={4}>
                                    <div className="catalog">
                                        <MultipleSelectDefault
                                            title={t("searchBar.filterByCatalog")}
                                            options={kindOfCatalog}
                                            onChange={handleKindOfCatalogChange}
                                        />
                                    </div>
                                </Col>
                                {kindOfCatalogValue.length > 0 && (
                                    <>
                                        {renderSelects()}
                                    </>
                                )}
                                <Col xs={4} md={4} xxl={4}>
                                    <MCButton
                                        label={t("searchBar.apply_filter")}
                                        variant="primary"
                                        width={180}
                                        onClick={() => {
                                            searchAndFilter();
                                            setChangeLabel(true);
                                        }}
                                        className="apply-filters-advanced"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} xxl={12}>
                            <div className="search-details-content">
                                <div className="search-details-rows ">
                                    {changeLabel === false ? (
                                        <p>{t("searchBar.last_report_received")}</p>
                                    ) : (
                                        <p>{t("searchBar.related_reports")}</p>
                                    )}
                                    <div className="search-details-divider"></div>

                                    {searchResults.map((result, index) => (
                                        <Row key={index}>
                                            <div
                                                key={index}
                                                className={`search-details-row ${selectedItem === result.id ? 'selected' : ''}`}
                                                onClick={() => goToReport(result)}
                                            >
                                                <div className={"search-details-column-icon" + result.status.current}>
                                                    {iconEntryway[result.entryway]?.element}
                                                </div>
                                                <Col xs={2} md={3} xxl={6}>
                                                    <div className="search-details-column-folio">
                                                        <p>{result.folio}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={3} md={3} xxl={3}>
                                                    <div className="search-details-column-tracking">
                                                        <p>{result.tracking_code}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={3} md={3} xxl={3}>
                                                    <div className="search-details-column-subject-container">
                                                        <div className="search-details-column-subject">
                                                            <p>{result.subject}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                    ))}
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
            </>

        </div>
    );
}


export default AdvancedSearch;
