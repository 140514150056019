import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { MCInput } from "../MainComponents/Input/Input";
import {
  updateCatalogueContainerName,
  updateCatalogueDescription,
  updateCatalogueExamples,
  updateCatalogueLabel,
  updateStatusCatalogue,
} from "../../apis";
import { MCButton, MCTooltip } from "../MainComponents";
import { useTranslation } from "react-i18next";

export const ModalEditInfoCatalogue = ({
  objSelection = null,
  catalogue,
  is_own,
  path: initiPath,
  show,
  onHide,
  nestNum,
  rerenderParent,
}) => {
  const pathTemp = initiPath ? initiPath : "";
  const splitedArr = pathTemp.split("::");
  const slicedArr = splitedArr.slice(0, 2 * (nestNum + 1));
  const path = slicedArr.join("::");

  const [t] = useTranslation("catalogues");
  const { gTheme } = useSelector((state) => state.theme);

  const [keysChanged, setKeysChanged] = useState({
    label: false,
    container_name: false,
    description: false,
    examples: false,
    children: false,
    is_active: false,
  });
  const [label, setLabel] = useState("");
  const [container_name, setContainer_name] = useState("");
  const [description, setDescription] = useState("");
  const [examples, setExamples] = useState([]);
  const [exampleToAdd, setExampleToAdd] = useState("");

  useEffect(() => {
    if (objSelection) {
      setLabel(objSelection?.label || "");
      setContainer_name(objSelection?.container_name || "");
      setDescription(objSelection?.description || "");
      setExamples(objSelection?.examples || []);
    }
  }, [objSelection]);

  const handleAddExample = () => {
    if (exampleToAdd.trim() === "") return;
    setKeysChanged({ ...keysChanged, examples: true });
    setExamples([...examples, exampleToAdd]);
    setExampleToAdd("");
  };

  const handleDeleteExample = (idx) => {
    const newExamples = examples.filter((item, idx2) => idx !== idx2);
    setKeysChanged({ ...keysChanged, examples: true });
    setExamples(newExamples);
  };

  const handleChange = ({ target }) => {
    const { name, value, checked } = target;
    setKeysChanged({ ...keysChanged, [name]: true });
    if (name === "label") {
      setLabel(value);
    }
    if (name === "description") {
      setDescription(value);
    }
    if (name === "container_name") {
      setContainer_name(value);
    }
  };

  const handleSaveCatalogue = async () => {
    const promisesArray = [];
    if (keysChanged.label)
      promisesArray.push(
        updateCatalogueLabel({ is_own, catalogue, path, label })
      );
    if (keysChanged.container_name)
      promisesArray.push(
        updateCatalogueContainerName({
          is_own,
          catalogue,
          path,
          container_name: container_name || null,
        })
      );
    if (keysChanged.description)
      promisesArray.push(
        updateCatalogueDescription({
          is_own,
          catalogue,
          path,
          description: description || null,
        })
      );
    if (keysChanged.examples)
      promisesArray.push(
        updateCatalogueExamples({ is_own, catalogue, path, examples })
      );
    try {
      const resp = await Promise.all(promisesArray);
      rerenderParent();
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show
      onHide={onHide}
      className={`modal-edit-catalogue ${
        gTheme !== "light" ? "dark-mode" : ""
      }`}
    >
      <Modal.Header closeButton>
        <h3>{t("Catalogues.edit_catalog_selection")}</h3>
      </Modal.Header>
      <Modal.Body>
        {path !== "/" && (
          <>
            <label className="mt-3">{t("Catalogues.label")}:</label>
            <MCInput
              type="text"
              name="label"
              className="mt-1"
              value={label}
              onChange={handleChange}
            />

            <label className="mt-3">{t("Catalogues.container_name")}:</label>
            <MCInput
              type="text"
              name="container_name"
              className="mt-1"
              value={container_name}
              onChange={handleChange}
            />

            <label className="mt-3">{t("Catalogues.description")}:</label>
            <MCInput
              type="text"
              name="description"
              className="mt-1"
              value={description}
              onChange={handleChange}
            />

            <label className="mt-3">{t("Catalogues.label")}:</label>
            <ul className="mt-2">
              {Array.isArray(examples) &&
                examples.map((item, idx) => (
                  <li key={idx} className="d-flex mb-2">
                    <span className="me-2">{item}</span>
                    <MCTooltip position="bottom" text={t("Catalogues.delete")}>
                      <div className="me-2">
                        <MCButton
                          label=""
                          icon="trash_can"
                          variant="danger"
                          size="sm"
                          width={28}
                          className="p-0"
                          outline
                          onClick={() => handleDeleteExample(idx)}
                        />
                      </div>
                    </MCTooltip>
                  </li>
                ))}
            </ul>
            <div className="d-flex align-items-center">
              <MCInput
                type="text"
                value={exampleToAdd}
                onChange={(e) => setExampleToAdd(e.target.value)}
              />
              <MCTooltip position="bottom" text={t("Catalogues.add_examples")}>
                <div className="me-2">
                  <MCButton
                    label=""
                    icon="add_icon"
                    variant="primary"
                    size="sm"
                    className="add-icon ms-1 p-0"
                    width={28}
                    outline
                    onClick={handleAddExample}
                  />
                </div>
              </MCTooltip>
            </div>
          </>
        )}

        <MCButton
          label={t("Catalogues.save")}
          variant="primary"
          outline
          className="mt-20"
          onClick={handleSaveCatalogue}
        />
      </Modal.Body>
    </Modal>
  );
};