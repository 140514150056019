import React from "react";
import { Modal } from "react-bootstrap";
import { MCButton } from "../../../../MainComponents";
import "./styles.scss";
import { BoldDanger } from "../../../../Icon";
import { useTranslation } from "react-i18next";

//-----Content of the modal to confirm reopen a completed investigationd----//
export const ReOpenInv = ({ handleEditionInfo, closeModal }) => {
  const [t] = useTranslation("investigation");
  return (
    <div className="reOpenInv">
      <Modal.Header closeButton>
        <h4 className="reOpenInv__title">
          <BoldDanger className="reOpenInv__icon" />
          {t("Conclusion.reopen_investigation")}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <span className="reOpenInv__text">{t("Conclusion.reopen_text")}</span>
        <div className="reOpenInv__actionBtns">
          <MCButton
            label={t("Conclusion.cancel")}
            variant="secondary"
            onClick={closeModal}
          />
          <MCButton
            label={t("Conclusion.confirm")}
            variant="primary"
            onClick={handleEditionInfo}
          />
        </div>
      </Modal.Body>
    </div>
  );
};
