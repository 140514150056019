import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { CDBBtn, CDBInput, CDBIcon, CDBSwitch } from 'cdbreact';
import SimpleSelect from '../../../components/SimpleSelect';
import { GeneralHeader } from '../../Title/GeneralHeader';

export const NewRoleUser = ({ vTheme }) => {
    const BackTheme = {
        backgroundColor: vTheme === "light" ? '#f3f7fa' : '#141319'
    }
    const DynamicTheme = {
        backgroundColor: vTheme === "light" ? '#fff' : '#1a1d27'
    }
    const [general_option] = useState([
        {
          text: "Option 1",
          value: "1",
        },
        {
          text: "Option 2",
          value: "2",
        },
        {
          text: "Option 3",
          value: "3",
        },
    ]);
    const roles = [
        {
            header: "Archivos adjuntos",
            content: [
                { text: "Puede editar archivos", status: true },
                { text: "Puede consultar archivos", status: true },
                { text: "Puede subir documentos", status: true },
                { text: "Puede descargar documentos", status: true }
            ]
        },
        {
            header: "Reportes",
            content: [
                { text: "Puede generar reportes", status: true },
                { text: "Puede editar reportes existentes", status: true },
                { text: "Puede administrar todos los reportes", status: true },
                { text: "Puede descargar reportes", status: true }
            ]
        },
        {
            header: "Flujos de investigación",
            content: [
                { text: "Puede crear nuevos flujos", status: true },
                { text: "Puede editar flujos", status: true },
                { text: "Puede administrar flujos", status: true },
            ]
        },
    ]

    return (
        <>
            <div id="settings" className={vTheme === "light" ? "" : "dark-mode"} style={ BackTheme } >

                <GeneralHeader
                    title="Nuevo rol"
                    text="Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis bibendum felis sit amet fringilla."
                    showLang
                    showPath
                    path="Usuarios > Roles de usuario > Nuevo rol"
                />

                <div className="d-block d-lg-flex mt-30">
                    <div className="profile-first-block">
                        <div className='p-20 border' style={ DynamicTheme }>
                            <div className="d-flex flex-column roundBorder cardNoBorder">
                                <h5 className="mb-20 text-left">Información del rol</h5>
                                <div className="d-block d-md-flex flex-row">
                                    <div className="form-group mb-30 w-100 mr-5">
                                        <label className="mb-2">Nombre en sistema*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Placeholder"
                                        ></input>
                                    </div>
                                    <div className="form-group mb-30 w-100">
                                        <label className="mb-2">Nombre del rol*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Placeholder"
                                        ></input>
                                    </div>
                                </div>
                                <div className="d-block d-md-flex flex-row">
                                    <div className="form-group mb-30 w-100 mr-5">
                                        <SimpleSelect label="Nivel*" options={general_option} />
                                    </div>
                                    <div className="form-group mb-30 w-100">
                                        <div className="d-flex align-items-center justify-content-between mt-20">
                                            <h6 className='mb-0'>Filtrado de denuncias</h6>
                                            <CDBSwitch checked/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <h6 className='mb-0'>¿El rol está activo?</h6>
                                            <CDBSwitch checked/>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-md-flex flex-row">
                                    <div className="form-group mb-30 w-100 mr-5">
                                        <SimpleSelect label="Flujo de investigación*" options={general_option} />
                                    </div>
                                    <div className="form-group mb-30 w-100 mt-4">
                                        <CDBBtn className="athics-outline-btn w-100" outline>CREAR NUEVO FLUJO</CDBBtn>
                                    </div>
                                </div>
                                <div className="form-group mb-30 w-100">
                                    <label className="mb-2">Descripción</label>
                                    <CDBInput
                                        className="form-control"
                                        type="textarea"
                                        rows="3"
                                        size="sm"
                                        hint="Praesent consectetur, nibh a porttitor vehicula, ligula lorem luctus justo, nec sagittis ante justo at nulla. Lacinia imperdiet est tellus vel arcu."
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='p-20 my-20 border' style={ DynamicTheme }>
                            <h5 className="mb-20 text-left">Información del rol</h5>
                            <div className="d-block d-md-flex flex-row">
                                <div className="form-group mb-30 w-100 mr-5">
                                    <SimpleSelect label="Tipo de notificación*" options={general_option} />
                                </div>
                                <div className="form-group mb-30 w-100">
                                    <SimpleSelect label="Horario de notificación*" options={general_option} />
                                </div>
                            </div>
                            <div className="form-group mb-30 w-100">
                                <SimpleSelect label="Regla de alerta*" options={general_option} />
                            </div>
                        </div>
                    </div>
                    <div className="profile-second-block p-20 user-second-bar border" style={ DynamicTheme }>
                        <h5>Permisos del rol</h5>
                        <p>Actualmente hay 12 permisos activos para este rol</p>
                        <Accordion defaultActiveKey={['1']} alwaysOpen>
                            {roles.map((item, id) => {
                                return (
                                    <Accordion.Item className="assign-accordion" key={"roles"+ id} eventKey={id}>
                                        <Accordion.Header>{item.header}</Accordion.Header>
                                        <Accordion.Body>
                                            {item.content.map((list, idx) => {
                                                return (
                                                    <div className='d-flex p-2' key={idx}>
                                                        <CDBIcon icon="check" />
                                                        <h6 className='ml-2 mb-0'>{list.text}</h6>
                                                    </div>
                                                )
                                            })}
                                        </Accordion.Body>                            
                                    </Accordion.Item>                            
                                )
                            })}
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    );
};
