import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PieChart } from "./charts/PieChart/PieChart";
import { Row, Col, FormCheck } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import {
  deleteElement,
  getDataGraphicByPath,
} from "../../../apis/apiDashboard";
import BarChart from "./charts/BarChart/BarChart";
import { GraphicComplements } from "./complements/GraphicComplements";
import "./graphics.scss";

import Icon from "../../Icon/Icon";

import { useSnackbar } from "notistack";
import { LoadingGraphic } from "../../Loading/Graphic/Graphic";
import { useTranslation } from "react-i18next";

export const Graphics = ({ graphicsData, stateLoading = null }) => {
  const [t] = useTranslation("dashboard");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme === "dark" ? "dark" : "light";
  const { enqueueSnackbar } = useSnackbar();

  // State de la lista de graficas
  const [graphics, setGraphics] = useState([]);
  // State para eliminar una grafica
  const [deleting, setDeleting] = useState("");
  // State para saber si ya existe un reemplazo de los datos
  const [replaceData, setReplaceData] = useState(false);
  // State para reespladar los datos originales
  const [originalData, setOriginalData] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (!replaceData) {
      setOriginalData(Object.values(graphicsData));
      setGraphics(Object.values(graphicsData));
      setReplaceData(true);
    }
  }, []);

  // Funcion para eliminar una grafica
  const deleteGraphic = async (graphicPath) => {
    setDeleting(graphicPath);

    const response = await deleteElement({ path: graphicPath });
    if (stateLoading !== null && response !== undefined) stateLoading(true);
  };

  // Define a function to toggle the visibility of elements based on item index
  const toggleElementVisibility = (index) => {
    // Use a state variable or an array of boolean values to track visibility for each item
    // Here, we use an array called 'elementVisibility'
    const updatedElementVisibility = [...elementVisibility];
    updatedElementVisibility[index] = !updatedElementVisibility[index];
    setElementVisibility(updatedElementVisibility);
  };

  const [elementVisibility, setElementVisibility] = useState([]);

  // Funcion para obtener los datos de la grafica
  // Con filtros de los ultimos 30, 60 y 90 dias
  const getGraphicData = async (graphicPath, days, index) => {
    setCurrentIndex(index);
    setIsLoading(true);

    const response = await getDataGraphicByPath({
      path: graphicPath,
      last_days: days,
    });

    if (response.status === 204) {
      enqueueSnackbar(t("Graphics.no_data"), {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 404) {
      enqueueSnackbar(response.data, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 200) {
      enqueueSnackbar(t("Graphics.obtained_data"), {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });

      var tmpGraphics = [...graphics];
      tmpGraphics[index] = response.data;
      setGraphics(tmpGraphics);
    }
    setCurrentIndex(-1);
    setIsLoading(false);
  };

  return (
    graphics.length > 0 &&
    graphics.map((graphicItem, index) => {
      return (
        <Row key={index}>
          <div className={`graphic-content ${theme}`}>
            <div className={`graphic-header ${theme}`}>
              <Col className="subtitle" lg={8}>
                {graphicItem.title === deleting
                  ? `${t("Graphics.deleting")}...`
                  : graphicItem.title}
              </Col>
              <Col lg={4} className={`options ${theme}`}>
                <Col sm={10}>
                  <MCTooltip text={t("Graphics.get_results")} position="bottom">
                    <Form.Select
                      size="sm"
                      onChange={(e) =>
                        getGraphicData(graphicItem.path, e.target.value, index)
                      }
                    >
                      <option value={30}>{t("Graphics.last_30")}</option>
                      <option value={60}>{t("Graphics.last_60")}</option>
                      <option value={90}>{t("Graphics.last_90")}</option>
                      <option value={183}>
                        {t("Graphics.past_six_months")}
                      </option>
                      <option value={365}>{t("Graphics.last_year")}</option>
                    </Form.Select>
                  </MCTooltip>
                </Col>
                <Col sm={2} className={`option-menu ${theme}`}>
                  <div className={`graphic-options ${theme}`}>
                    <MCTooltip text={t("Graphics.options")} position="bottom">
                      <DropdownButton
                        key="end"
                        id="dropdown-button-drop-end"
                        drop="end"
                        title="ooo"
                      >
                        {/* <Dropdown.Item eventKey="1">
                          {t("dashboard.graphics.dropdown.changeRange")}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2">
                          {t("dashboard.graphics.dropdown.asignRangue")}
                        </Dropdown.Item>
                        <Dropdown.Divider /> */}
                        <Dropdown.Item
                          eventKey="4"
                          onClick={() => deleteGraphic(graphicItem.path)}
                        >
                          {t("Graphics.delete")}
                        </Dropdown.Item>
                      </DropdownButton>
                    </MCTooltip>
                  </div>
                </Col>
              </Col>
            </div>
            <div
              className={`graphic-container ${
                isLoading && currentIndex === index ? "show-loading" : ""
              }`}
            >
              <LoadingGraphic />
              <Row className="graphic-body">
                <div className="graphic-switch">
                  <Icon
                    size={16}
                    className="graphic-option"
                    name="bar_chart_bold"
                  />
                  <FormCheck
                    type="switch"
                    checked={elementVisibility[index]}
                    onChange={() => toggleElementVisibility(index)}
                  />
                  <Icon
                    size={16}
                    className="data-option"
                    name="outline_chart_bold"
                  />
                </div>

                {elementVisibility[index] ? (
                  <div className="graphic-indicators">
                    <GraphicComplements chartData={graphicItem} />
                  </div>
                ) : (
                  <Col lg={12} className="graphic-element">
                    {graphicItem.show_graphic === "bars" ? (
                      <BarChart chartData={graphicItem} />
                    ) : (
                      <PieChart chartData={graphicItem} />
                    )}
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Row>
      );
    })
  );
};
