import React, { useState,useEffect } from 'react';
import "./TimeLine.scss";
import { useTranslation } from "react-i18next";

const timelineData = [
        { 
          id: 1,
          status: 'new', 
          title: 'Nuevo', 
          color: '#3180ff',
          content: 'Contenido detallado para el Paso 1. Aquí puedes incluir más información, instrucciones o cualquier otro detalle relevante para esta etapa.'
        },
        { 
          id: 2,
          status: 'pending', 
          title: 'Paso 2', 
          color: '#7c4dff',
          content: 'Información específica del Paso 2. Este espacio puede usarse para explicar lo que se debe hacer en esta fase o proporcionar contexto adicional.'
        },
        { 
          id: 3,
          status: 'process', 
          title: 'Paso 3', 
          color: '#45cb67',
          content: 'Detalles importantes para el Paso 3. Utiliza esta sección para destacar puntos clave, requerimientos o cualquier dato relevante para esta etapa del proceso.'
        },
        { 
          id: 4,
          status: 'finish', 
          title: 'Paso 4', 
          color: '#ffaf00',
          content: 'Contenido extenso para el Paso 4. Aquí puedes elaborar sobre las acciones necesarias, proporcionar ejemplos o incluir recursos adicionales relacionados con esta fase.'
        },
        { 
          id: 5,
          status: 'closed', 
          title: 'Paso 5', 
          color: '#de453d',
          content: 'Información final para el Paso 5. Este espacio es ideal para concluir el proceso, ofrecer resúmenes o indicar los próximos pasos a seguir después de completar todas las etapas.'
        },
      ];

      const TimelineItem = ({ title, color, isActive, isCurrent, isFuture, onClick, isTop }) => {
        const itemStyle = {
          flex: 1,
          position: 'relative',
          cursor: isFuture ? 'not-allowed' : 'pointer',
          opacity: isFuture ? 0.5 : 1,
          transition: 'opacity 0.3s',
          zIndex: 10
        };
      
        const sphereStyle = {
          width: isActive ? '64px' : '40px',
          height: isActive ? '64px' : '40px',
          /*backgroundColor: 'white',*/
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s',
          position: 'relative',
          zIndex: 20
        };
      
        const innerSphereStyle = {
          width: isActive ? '48px' : '24px',
          height: isActive ? '48px' : '24px',
          backgroundColor: color,
          borderRadius: '50%',
          opacity: isFuture ? 0.3 : 1,
          transition: 'all 0.3s'
        };
      
        const titleStyle = {
          position: 'absolute',
          left: 0,
          right: 0,
          textAlign: 'center',
          [isTop ? 'bottom' : 'top']: '100%',
          marginTop: isTop ? '0' : '16px',
          marginBottom: isTop ? '16px' : '0',
          fontSize: isActive ? '18px' : '16px',
          fontWeight: 'bold',
          color: isActive ? '#1F2937' : isFuture ? '#9CA3AF' : '#4B5563',
          zIndex: 20
        };
      
        const triangleStyle = {
          width: 0,
          height: 0,
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
          [isTop ? 'borderTop' : 'borderBottom']: '8px solid #10B981',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          [isTop ? 'bottom' : 'top']: isTop ? 'calc(100% + 8px)' : 'calc(100% + 8px)',
          zIndex: 20
        };
      
        return (
          <div style={itemStyle} onClick={() => !isFuture && onClick()}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '64px' }}>
              <div style={sphereStyle}>
                <div style={innerSphereStyle}></div>
              </div>
            </div>
            <div style={titleStyle}>
              {isCurrent && !isTop && <div style={triangleStyle}></div>}
              {title}
              {isCurrent && isTop && <div style={triangleStyle}></div>}
            </div>
          </div>
        );
      };

export const TimeLine = ({reportDetail, onMessage }) => {
        const sendMessage = (status2,stepId) => {
                const message = status2;
                const status_anterior= dynamicStyle[2].findIndex((status)=> status==reportDetail?.status?.current)
                onMessage(message, status_anterior ? dynamicStyle[2][status_anterior-1] : false); // Llamar a la función del padre con el mensaje
                if (stepId <= currentStep) {
                        setActiveStep(stepId);
                        }
               
              };
              const percentage=15;
              const progressStyle = {
                background: `linear-gradient(to right, #3180ff ${percentage}%, #7c4dff 30%, #45cb67 45%, #ffaf00 60%, #de453d 75%, #fff ${percentage}%)`
              };  
              
              const highlightStyle = { transform: 'scale(1.2)' }; // Estilo para resaltar el estado actual
              
                 const dynamicStyle = [
                {opacity: '50%',"background-color": '#ccc'},
                {"background-color": '#ccc'},
                ['new','pending','process','finish','closed'] 
                ];
                let status_report=reportDetail?.status?.current
                const [t] = useTranslation("report");
                let hasReachedCurrentStatus = false;
                
                /**
                 * 
                 */
                const [currentStep, setCurrentStep] = useState();
                const [activeStep, setActiveStep] = useState(1);
                // console.log(reportDetail?.status?.details)
                // console.log(reportDetail?.status?.details !=undefined )
                // console.log(reportDetail?.status?.details !=undefined ? reportDetail?.status?.details: "item.status")
                useEffect(() => {
                        const activo= dynamicStyle[2].findIndex((status)=> status==reportDetail?.status?.current)
                        setCurrentStep(activo ? activo+1: 1)
                        setActiveStep(activo ? activo+1: 1);
                      }, [reportDetail?.status?.current]);

                const handleStepClick = (stepId) => {
                if (stepId <= currentStep) {
                setActiveStep(stepId);
                }
                };

                const containerStyle = {
                width: '100%',
                
                padding: '0px'
                };

                const timelineStyle = {
                padding: '60px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative'
                };

                const lineStyle = {
                position: 'absolute',
                top: '50%',
                left: 0,
                width: '100%',
                height: '2px',
                background: `linear-gradient(
                  to right, 
                  ${currentStep > 1 ? "rgba(49, 128, 255, 1) 20%" : "rgba(255, 255, 255, 0) 20%"}, 
                  ${currentStep > 2 ? "rgba(124, 77, 255, 1) 40%" : "rgba(255, 255, 255, 0) 40%"}, 
                  ${currentStep > 3 ? "rgba(69, 203, 103, 1) 60%" : "rgba(255, 255, 255, 0) 60%"}, 
                  ${currentStep > 4 ? "rgba(255, 175, 0, 1) 80%" : "rgba(255, 255, 255, 0) 80%"}, 
                  ${currentStep > 5 ? "rgba(222, 69, 61, 1) 100%" : "rgba(255, 255, 255, 0) 100%"}
                )`,                transform: 'translateY(-50%)',
                zIndex: 1
                };

                const contentStyle = {
                marginTop: '32px',
                padding: '24px',
                /*backgroundColor: 'white',*/
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                };
    return <>
            


            <div style={containerStyle}>
      <div style={timelineStyle}>
        <div style={lineStyle} className={progressStyle}></div>
        {timelineData.map((item, index) => (
          <TimelineItem
            key={item.id}
            title={t("status." + ((reportDetail?.status?.details ==undefined && item.status=="closed") ?  "closing_process":item.status))}
            color={item.color}
            isActive={item.id === activeStep}
            isCurrent={item.id === currentStep}
            isFuture={item.id > currentStep}
            onClick={() => sendMessage(item.status,item.id)}
            isTop={index % 2 === 0}
          />
        ))}
      </div>
    </div>
            
    </>
    
}