import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  MCButton,
  MCInput,
  MCLabel,
  MCSelect,
  MCTooltip,
} from "../../../MainComponents";
import { Col, Row } from "react-bootstrap";
import { TrashCan } from "../../../Icon";
import { Loading } from "../../../Loading/String/Loading";
import "./Resolution.scss";

export const CorrectivePreventiveMeasures = ({
  resolution,
  openModal,
  handleTabChange,
  reportDetail
}) => {
  const [t] = useTranslation("report");
  const {
    measures,
    editResolution,
    setEditResolution,
    measureOptions,
    hdlSelectResolutionChange,
    hdlAnoterOptionChange,
    hdlDetailsChange,
    hdlAddResolution,
    isFormValid,
    sendResolutionData,
    loading,
  } = resolution;

  // Measures type translate
  const measuresType = {
    internal_process_modifications: t(
      "Resolution.internal_process_modifications"
    ),
    acquisition_equipment_materials: t(
      "Resolution.acquisition_equipment_materials"
    ),
    repairs: t("Resolution.repairs"),
    hiring: t("Resolution.hiring"),
    compesations: t("Resolution.compensations"),
    dismissal_third_party_employees: t(
      "Resolution.dismissal_third_party_employees"
    ),
    another: t("Resolution.another"),
  };

  const [enableReadMode, setEnableReadMode] = useState(false);

  useEffect(() => {
    if (reportDetail != "finish")
      setEnableReadMode(true);

  }, [])

  return (
    enableReadMode === false ? (
      <div className="dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.corrective_preventive_measures")}</h4>
          {measures.length >= 1 && (
            <>
              {!editResolution ? (
                <MCButton
                  label={t("Resolution.edit")}
                  variant="light"
                  className="resolutions__btnAction"
                  onClick={() => setEditResolution(true)}
                  icon="outline_edit"
                />
              ) : (
                <MCButton
                  label={t("Resolution.cancel")}
                  variant="light"
                  className="resolutions__btnAction"
                  onClick={() => {
                    handleTabChange(2);
                  }}
                  icon="collapse"
                />
              )}
            </>
          )}
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            measures?.length === 0 ? (
              <h5>
                {t(
                  "Resolution.corrective_preventive_measures_have_not_been_assigned_yet"
                )}
              </h5>
            ) : (
              measures?.map((measureElement, i) => (
                <Col key={i} lg={12} xl={6} xxl={4} className="p-1">
                  {measureElement.length === 0 ? (
                    <h5 className="text-center my-3">
                      {t(
                        "Resolution.corrective_preventive_measures_have_not_been_assigned_yet"
                      )}
                    </h5>
                  ) : (
                    <div className="resolutions__measure rounded">
                      <div className="dyTheme2 px-3 py-1 rounded">
                        <MCLabel
                          text={`${t("Resolution.corrective_measure")}:`}
                          className="mt-2 mb-1"
                        />
                        <small className="p-2">
                          {measureElement.measure === "another"
                            ? measureElement?.another_measure
                            : measuresType[measureElement.measure]}
                        </small>

                        <hr className="my-2" />
                        <MCLabel
                          text={`${t("Resolution.details")}:`}
                          className="mb-1 mt-0"
                        />
                        <p className="p-2 pb-2">
                          {measureElement.comment ||
                            t("Resolution.not_specified")}
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              ))
            )
          ) : measures.length === 0 ? (
            <h5>{t("Resolution.all_preventive_corrective_have_been_removed")}</h5>
          ) : (
            measures.map((_, i) => (
              <Col key={i} lg={12} xl={6} xxl={4} className="p-1">
                <div className="resolutions__measure rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "measures")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.corrective_measure")}:`}
                    className="mt-3 mb-1 mx-2"
                  />
                  {editResolution ? (
                    <MCSelect
                      isValid={measures[i].measure !== ""}
                      options={measureOptions}
                      onChange={(selectedResolution) =>
                        hdlSelectResolutionChange(
                          i,
                          selectedResolution,
                          "measures"
                        )
                      }
                      value={
                        measureOptions.find(
                          (measureOption) =>
                            measureOption.value === measures[i].measure
                        ) || ""
                      }
                      className="w-100"
                    />
                  ) : (
                    <small>{measures[i].measure}</small>
                  )}

                  {measures[i].measure === "another" && (
                    <MCInput
                      className="my-2"
                      type="textarea"
                      placeholder={`${t("Resolution.specify")} *`}
                      value={measures[i].another_measure}
                      onChange={(e) => hdlAnoterOptionChange(i, e, "measures")}
                      isValid={measures[i].another_measure.trim() !== ""}
                    />
                  )}
                  <hr className="my-2" />
                  <MCLabel
                    text={`${t("Resolution.details")}:`}
                    className="mb-1 mt-0 mx-2"
                  />
                  <MCInput
                    type="textarea"
                    onChange={(e) => hdlDetailsChange(i, e, "measures")}
                    value={measures[i].comment}
                    rows={3}
                    placeholder={`${t(
                      "Resolution.enter_details_related_selected_measure"
                    )}`}
                  />
                </div>
              </Col>
            ))
          )}
          {/* Add/Save measures */}
          <Col className="resolutions__sendResolutions mt-3" sm={12}>
            {measures.length === 0 ? (
              <>
                <MCButton
                  label={t("Resolution.add_new_measure")}
                  onClick={() => {
                    hdlAddResolution("measures");
                    setEditResolution(true);
                  }}
                  variant="primary"
                  icon="add_square"
                />
                {editResolution && (
                  <>
                    <MCButton
                      label={t("Resolution.save")}
                      variant="success"
                      outline={isFormValid("measures")}
                      disabled={isFormValid("measures")}
                      onClick={sendResolutionData}
                      icon={`${isFormValid("measures") ? "" : "check_circle"}`}
                    />
                    <MCButton
                      label={t("Resolution.cancel")}
                      variant="light"
                      className="resolutions__btnAction"
                      onClick={() => {
                        handleTabChange(2);
                      }}
                      icon="collapse"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <MCButton
                      label={`${t("Resolution.add_another_measure")}`}
                      onClick={() => {
                        hdlAddResolution("measures");
                        setEditResolution(true);
                      }}
                      disabled={isFormValid("measures")}
                      outline={isFormValid("measures")}
                      variant="primary"
                      icon={`${isFormValid("measures") ? "" : "add_square"}`}
                    />
                    {editResolution && (
                      <MCButton
                        label={t("Resolution.save")}
                        variant="success"
                        outline={isFormValid("measures")}
                        disabled={isFormValid("measures")}
                        onClick={sendResolutionData}
                        icon={`${isFormValid("measures") ? "" : "check_circle"}`}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    ) : (















      <div className="readMode dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.corrective_preventive_measures")}</h4>
          {measures.length >= 1 && (
            <>
            </>
          )}
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            measures?.length === 0 ? (
              <h5>
                {t(
                  "Resolution.corrective_preventive_measures_have_not_been_assigned_yet"
                )}
              </h5>
            ) : (
              measures?.map((measureElement, i) => (
                <Col key={i} lg={12} xl={6} xxl={4} className="p-1">
                  {measureElement.length === 0 ? (
                    <h5 className="text-center my-3">
                      {t(
                        "Resolution.corrective_preventive_measures_have_not_been_assigned_yet"
                      )}
                    </h5>
                  ) : (
                    <div className="resolutions__measure rounded">
                      <div className="dyTheme2 px-3 py-1 rounded">
                        <MCLabel
                          text={`${t("Resolution.corrective_measure")}:`}
                          className="mt-2 mb-1"
                        />
                        <small className="p-2">
                          {measureElement.measure === "another"
                            ? measureElement?.another_measure
                            : measuresType[measureElement.measure]}
                        </small>

                        <hr className="my-2" />
                        <MCLabel
                          text={`${t("Resolution.details")}:`}
                          className="mb-1 mt-0"
                        />
                        <p className="p-2 pb-2">
                          {measureElement.comment ||
                            t("Resolution.not_specified")}
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              ))
            )
          ) : measures.length === 0 ? (
            <h5>{t("Resolution.all_preventive_corrective_have_been_removed")}</h5>
          ) : (
            measures.map((_, i) => (
              <Col key={i} lg={12} xl={6} xxl={4} className="p-1">
                <div className="resolutions__measure rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "measures")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.corrective_measure")}:`}
                    className="mt-3 mb-1 mx-2"
                  />
                  {editResolution ? (
                    <MCSelect
                      isValid={measures[i].measure !== ""}
                      options={measureOptions}
                      onChange={(selectedResolution) =>
                        hdlSelectResolutionChange(
                          i,
                          selectedResolution,
                          "measures"
                        )
                      }
                      value={
                        measureOptions.find(
                          (measureOption) =>
                            measureOption.value === measures[i].measure
                        ) || ""
                      }
                      className="w-100"
                    />
                  ) : (
                    <small>{measures[i].measure}</small>
                  )}

                  {measures[i].measure === "another" && (
                    <MCInput
                      className="my-2"
                      type="textarea"
                      placeholder={`${t("Resolution.specify")} *`}
                      value={measures[i].another_measure}
                      onChange={(e) => hdlAnoterOptionChange(i, e, "measures")}
                      isValid={measures[i].another_measure.trim() !== ""}
                    />
                  )}
                  <hr className="my-2" />
                  <MCLabel
                    text={`${t("Resolution.details")}:`}
                    className="mb-1 mt-0 mx-2"
                  />
                  <MCInput
                    type="textarea"
                    onChange={(e) => hdlDetailsChange(i, e, "measures")}
                    value={measures[i].comment}
                    rows={3}
                    placeholder={`${t(
                      "Resolution.enter_details_related_selected_measure"
                    )}`}
                  />
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    )
  );
};
