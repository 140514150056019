import { useNavigate } from "react-router-dom";
import { Row, Col, Accordion, Dropdown } from "react-bootstrap";
import {
  Calendar,
  OutlineEdit2,
  Setting,
  TrashSquareIcon,
} from "../../../../Icon";
import Icon from "../../../../Icon/Icon";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";
import NotFound from "../../../../GlobalComponents/NotFound";
import { parseDateToISOString2 } from "../../../../../helpers/dateTransform";

export const Entrevistas = ({ interviews, investigationAlias }) => {
  const [t] = useTranslation("investigation");

  const kind = (kn) => {
    if (kn === "in-person") return t("Interview.in_person");
    if (kn === "video-conference") return t("Interview.videocall");
    return t("Interview.unspecified");
  };

  const navigate = useNavigate();

  let pending = [],
    process = [],
    completed = [];

  interviews.forEach((item, idx) => {
    if (item.status === "pending")
      pending.push({ index: idx, interview: item });
    if (item.status === "process")
      process.push({ index: idx, interview: item });
    if (item.status === "finish")
      completed.push({ index: idx, interview: item });
  });

  const accordion = [
    {
      key: "pending",
      header: t("Interview.pending"),
      data: pending,
    },
    {
      key: "process",
      header: t("Interview.in_process"),
      data: process,
    },
    {
      key: "completed",
      header: t("Interview.finished"),
      data: completed,
    },
  ];

  var have_one = [
    pending ? pending.length : 0,
    process ? process.length : 0,
    completed ? completed.length : 0,
  ];

  const total = have_one.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0); // 0 is the initial value of the accumulator

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded content p-20">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div>
            <h4 className="m-0">{t("Interview.interviews")}</h4>
            <small></small>
          </div>
          <MCButton
            label={t("Interview.new_interview")}
            variant="primary"
            outline
            onClick={() =>
              navigate("/interview/config", {
                state: { investigationAlias, interviews },
              })
            }
          />
        </div>
      </div>

      <div className="dyTheme1 dyBorder1 rounded content p-20 mt-20">
        {total >= 1 ? (
          <Accordion
            defaultActiveKey={accordion.map((item, idx) => `${idx + 1}`)}
            alwaysOpen
          >
            {accordion.map((item, idx) => (
              <Accordion.Item key={idx} eventKey={`${idx + 1}`}>
                <Accordion.Header>{item.header}</Accordion.Header>
                {item?.data.length !== 0 &&
                  item.data.map((item2, idx2) => (
                    <Accordion.Body key={idx2}>
                      <div className="d-block d-lg-flex align-items-center justify-content-between py-3 border-bottom">
                        <div className="my-2">
                          <h6>{t("Interview.meet_date")}:</h6>
                          <small>
                            {`${t("Interview.scheduled_for")}  ${
                              parseDateToISOString2(
                                item2?.interview?.scheduled
                              ) || t("Interview.unspecified")
                            }`}
                          </small>
                        </div>
                        <MCButton
                          label={kind(item2?.interview?.kind)}
                          className="small-info-btn"
                        />
                      </div>
                      <div className="py-3 border-bottom">
                        <h6 className="my-3 d-flex align-items-center">
                          <span className="me-2">
                            {t("investigation.interviews.people_involved")}
                          </span>
                          {/* <Dropdown>
                            <Dropdown.Toggle variant="">
                              <Icon name="more_dots" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Setting />
                                <span className="ms-2">
                                  {t("Interview.config")}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Calendar />
                                <span className="ms-2">
                                  {t("Interview.questions")}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <OutlineEdit2 />
                                <span className="ms-2">
                                  {t("Interview.edit")}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <TrashSquareIcon />
                                <span className="ms-2">
                                  {t("Interview.delete")}
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}
                        </h6>
                        <Row>
                          <Col lg="4" md="12" className="my-2 p-0">
                            <small>{t("Interview.interviewee")}</small>
                            <h6>
                              {item2?.interview?.interviewed ||
                                t("Interview.unspecified")}
                            </h6>
                          </Col>

                          <Col lg="4" md="12" className="my-2 p-0">
                            <small>{t("Interview.interviewer")}</small>
                            <h6>
                              {item2?.interview?.interviewer?.name ||
                                t("Interview.unspecified")}
                            </h6>
                          </Col>
                        </Row>
                      </div>
                      <h6 className="my-3">
                        {t("Interview.objectives_topics")}
                      </h6>
                      <small>
                        {item2?.interview?.details ||
                          t("Interview.unspecified")}
                      </small>
                      <div className="text-center text-md-right mt-20">
                        {(idx === 0 || idx === 1) && (
                          <MCButton
                            label={t("Interview.review_interview")}
                            variant="primary"
                            className="me-2"
                            outline
                            onClick={() =>
                              navigate("/interview/config", {
                                state: {
                                  investigationAlias,
                                  interviews,
                                  currentInterview: item2.interview,
                                  index: item2.index,
                                },
                              })
                            }
                          />
                        )}
                        {idx === 0 ? (
                          <MCButton
                            label={t("Interview.start_interview")}
                            variant="primary"
                            className="me-2"
                            onClick={() =>
                              navigate("/interview/start", {
                                state: {
                                  investigationAlias,
                                  interviews,
                                  currentInterview: item2.interview,
                                  index: item2.index,
                                },
                              })
                            }
                          />
                        ) : idx === 1 ? (
                          <MCButton
                            label={t("Interview.continue_interview")}
                            variant="primary"
                            className="me-2"
                            onClick={() =>
                              navigate("/interview/start", {
                                state: {
                                  investigationAlias,
                                  interviews,
                                  currentInterview: item2.interview,
                                  index: item2.index,
                                },
                              })
                            }
                          />
                        ) : (
                          <MCButton
                            label={t("Interview.review_interview")}
                            variant="primary"
                            onClick={() =>
                              navigate("/interview/analysis", {
                                state: {
                                  investigationAlias,
                                  interviews,
                                  currentInterview: item2.interview,
                                  index: item2.index,
                                },
                              })
                            }
                          />
                        )}
                      </div>
                    </Accordion.Body>
                  ))}
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
