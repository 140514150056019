import { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Modal, Row } from "react-bootstrap";
import { catalogByPart } from "../../../apis";
import { CatalogueInput } from "../../JSONForms/PreviewForm/CatalogueInput";
import { MCSelect } from "../../MainComponents/Select/Select";
import { MCButton } from "../../MainComponents/Button/Button";
import { useSelector } from "react-redux";
import Icon from "../../Icon/Icon";
import Swal from "sweetalert2";
import { INPUT_TYPE_CATALOGUE_ERROR } from "../../JSONForms/consts";
import { useTranslation } from "react-i18next";

const EveryValue = ({
  value,
  catalogueInit,
  catDataInit,
  onChange = () => {},
}) => {
  const [catalogue, setCatalogue] = useState(catalogueInit);
  const [catData, setCatData] = useState(catDataInit);
  const [isLoading, setIsLoading] = useState(true);
  const [t] = useTranslation("users");

  useEffect(() => {
    if (value && catalogue && catDataInit) {
      const newData = _.cloneDeep(catDataInit);
      _.set(newData, `0.${newData[0]?.catalogue}`, value);
      fillIfIsCatalogue(catalogueInit, newData).then((resp) => {
        setCatalogue(resp);
        setCatData(newData);
        setIsLoading(false);
      });
    } else {
      setCatalogue(catalogueInit);
      setCatData(catDataInit);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setCatalogue(catalogueInit);
    setCatData(catDataInit);
  }, [catalogueInit, catDataInit]);

  useEffect(() => {
    if (catalogue && catData) {
      onChange(catData[0][catalogue[0].catalogue], catalogue[0].label);
    }
  }, [catalogue, catData]);

  const fillSelections = async ({
    input,
    data,
    catalogue,
    is_own,
    nestNum = 0,
    pathForSchema = "",
  }) => {
    const newInput = _.cloneDeep(input);
    const splitedAnsr = data.split("::");
    const slicedAnsr = splitedAnsr.slice(0, 2 * nestNum);
    const slicedAnsrKey = splitedAnsr.slice(nestNum * 2, 2 * (nestNum + 1));
    const selected =
      slicedAnsrKey.length === 2 ? slicedAnsrKey[slicedAnsrKey.length - 1] : "";
    const joined = slicedAnsr.join("::");
    const resp = await catalogByPart({
      is_own,
      catalogue,
      path: joined ? joined : "/",
    });
    if (resp.error) return "error";
    if (Array.isArray(resp.data) && resp.data.length > 0) {
      const newResp = resp.data.map((item) => {
        const itemWithoutChilds = { ...item };
        delete itemWithoutChilds.childs;
        return itemWithoutChilds;
      });
      _.set(
        newInput,
        `${pathForSchema ? pathForSchema + "." : ""}children`,
        newResp
      );
      _.set(
        newInput,
        `${pathForSchema ? pathForSchema + "." : ""}selected`,
        selected
      );
    }
    if (selected) {
      return await fillSelections({
        input: newInput,
        data,
        catalogue,
        is_own,
        nestNum: nestNum + 1,
        pathForSchema: `${pathForSchema ? pathForSchema + ".next" : "next"}`,
      });
    } else {
      return newInput;
    }
  };

  const fillIfIsCatalogue = async (inputsSchema, inputsData) => {
    const newInputs = inputsSchema.map(async (inp, idx) => {
      if ("catalogue" in inp && "isOwn" in inp) {
        const filledInput = await fillSelections({
          input: inp,
          data: inputsData[idx][inp.catalogue],
          catalogue: inp.catalogue,
          is_own: inp.isOwn,
        });
        if (filledInput === "error") {
          return {
            key: `C::${inp.isOwn}::${inp.catalogue}`,
            catalogue: inp.catalogue,
            type: INPUT_TYPE_CATALOGUE_ERROR,
            label: `${t("segmentEdit.catalog_error")} (${inp.catalogue})`,
            required: false,
            sensitive: false,
            selected: "",
          };
        } else {
          return filledInput;
        }
      }
      return inp;
    });
    const newInputsSolved = await Promise.all(newInputs);
    return newInputsSolved;
  };

  return (
    <div>
      {catalogue && catData && !isLoading && (
        <Row>
          <CatalogueInput
            schema={catalogue[0]}
            scope={catalogue[0]}
            value={catData[0][catalogue[0].catalogue]}
            catalogue={catalogue[0].catalogue}
            isOwn={catalogue[0].isOwn}
            entireSchema={catalogue}
            entireFormData={catData}
            setSchemaState={setCatalogue}
            setFormData={setCatData}
            pathSchema={"0"}
            pathData={"0"}
            returnValidClass={() => {}}
            handleValidate={() => {}}
          />
        </Row>
      )}
    </div>
  );
};

export const ModalSegmentCatalogueEdit = ({
  show,
  onHide,
  scope,
  path,
  handleEditValues,
  utilitiesCatalogues,
}) => {
  const { gTheme } = useSelector((state) => state.theme);
  const [t] = useTranslation("users");
  const [selectedCatalogue, setSelectedCatalogue] = useState(null);
  const [catalogue, setCatalogue] = useState(null);
  const [catData, setCatData] = useState(null);
  const [values, setValues] = useState(scope?.value || []);
  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    const splitedField = scope.field.split("::");
    const catalogue = splitedField[splitedField.length - 1];
    const selectedCatalogue = utilitiesCatalogues.find(
      (item) => item.catalogue === catalogue
    );
    setSelectedCatalogue(
      utilitiesCatalogues.find((item) => item.catalogue === catalogue)
    );
    setValues(scope?.value);
    buildCatalogueAndData(selectedCatalogue).then(({ catalogue, data }) => {
      setCatalogue([catalogue]);
      setCatData([data]);
      setIsLoading(false);
    });
  }, []);

  const buildCatalogueAndData = async (selectedCatalogue) => {
    const resp = await catalogByPart({
      is_own: selectedCatalogue.isOwn,
      catalogue: selectedCatalogue.catalogue,
      path: "/",
    });

    let children = [...resp.data];
    let objCatalogue = {
      key: `C::EG::${selectedCatalogue.catalogue}`,
      type: "catalog-select",
      label: selectedCatalogue.label,
      placeholder: t("segmentEdit.support_text"),
      required: false,
      sensitive: false,
      scope: "/",
      selected: "",
      isOwn: selectedCatalogue.isOwn ? true : false,
      catalogue: selectedCatalogue.catalogue,
      children: children,
    };
    let objCatData = {
      [selectedCatalogue.catalogue]: "",
      catalogue: selectedCatalogue.catalogue,
      isOwn: false,
      sensitive: false,
    };
    return { catalogue: objCatalogue, data: objCatData };
  };

  const handleChangueCatalogue = async (selectedCatalogue) => {
    setSelectedCatalogue(selectedCatalogue);
    const { catalogue, data } = await buildCatalogueAndData(selectedCatalogue);
    setCatalogue([catalogue]);
    setCatData([data]);
    setValues([]);
  };

  const handleValues = (val, idx) => {
    if (val) {
      const newValues = _.cloneDeep(values);
      newValues[idx] = val;
      setValues(newValues);
    }
  };

  const handleDeleteValue = (idx) => {
    const newValues = _.cloneDeep(values);
    newValues.splice(idx, 1);
    setValues(newValues);
    setRerender(true);
  };

  const handleEditValuesLocal = () => {
    if (values.includes("")) {
      Swal.fire(t("segmentEdit.values_havent_selected"));
      return;
    }
    const child = {
      child: scope.child,
      field: catalogue[0].key,
      value: values,
      label: catalogue[0].label,
    };
    handleEditValues(path, child);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
      centered
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      >
        <Modal.Title>{t("segmentEdit.edit_values")}</Modal.Title>
      </Modal.Header>
      {utilitiesCatalogues && (
        <div className="py-2 pt-4">
          <MCSelect
            options={utilitiesCatalogues}
            value={selectedCatalogue}
            onChange={handleChangueCatalogue}
          />
        </div>
      )}

      <Row>
        {Array.isArray(values) &&
          !isLoading &&
          !rerender &&
          values.map((item, idx) => (
            <Col sm={1} md={4} key={idx} className="pb-2">
              <div className="dyTheme1 dyBorder1 rounded">
                <div className="d-flex justify-content-end pe-1 pt-2">
                  <Icon
                    name="cancel_circle"
                    className="pointer"
                    onClick={() => handleDeleteValue(idx)}
                  />
                </div>
                <hr className=" mt-1 " />
                <EveryValue
                  value={item}
                  catalogueInit={catalogue}
                  catDataInit={catData}
                  onChange={(val) => handleValues(val, idx)}
                />
              </div>
            </Col>
          ))}
      </Row>

      <div className="d-flex justify-content-end">
        <MCButton
          label={t("segmentEdit.cancel")}
          variant="danger"
          outline
          className="me-2"
          onClick={onHide}
        />
        <MCButton
          label={t("segmentEdit.add_filter")}
          variant="primary"
          outline
          className="me-2"
          onClick={() => setValues([...values, ""])}
        />
        {values.length > 0 && (
          <MCButton
            label={t("segmentEdit.save")}
            variant="success"
            outline
            onClick={handleEditValuesLocal}
          />
        )}
      </div>
    </Modal>
  );
};
