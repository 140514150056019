import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  parseDateToDDMMYYYY2,
  parseDateToISOString2,
  validInitDates,
  parseDatoToLgAndHZ,
} from "../../../../../helpers/dateTransform";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { useRef } from "react";
import { patchInvestigation } from "../../../../../apis";
import { AddEraseInv } from "../EditForms/AddEraseInv";
import { useTranslation } from "react-i18next";
import "./Datos.scss";

export const Datos = ({
  causes,
  dates,
  general,
  handleUnlinkInvestigation,
  id,
  investigationAlias,
  scenarios,
  toggleModal,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedCause, setSelectedCause] = useState(null);
  const [details, setDetails] = useState("");
  const [hostile, setHostile] = useState("");
  const [amend, setAmend] = useState("");
  const [avoid, setAvoid] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const prevDetailsRef = useRef();
  const prevHostileRef = useRef();
  const prevAmendRef = useRef();
  const prevAvoidRef = useRef();

  const [t] = useTranslation("investigation");

  const severity = (severityId) => {
    if (severityId == 1) return t("severity.low");
    if (severityId == 2) return t("severity.medium");
    if (severityId == 3) return t("severity.high");
    // return t("severity.none");
    return "";
  };
  const severityClass = (severityId) => {
    if (severityId == 1) return "small-success-btn";
    if (severityId == 2) return "small-warning-btn";
    if (severityId == 3) return "small-danger-btn";
    return "";
  };
  const entryway = (entry) => {
    if (entry === "phone") return t("entryways.phone");
    if (entry === "email") return t("entryways.email");
    if (entry === "web") return t("entryways.web");
    if (entry === "whatsapp") return t("entryways.whatsapp");
    if (entry === "chat") return t("entryways.chat");
    if (entry === "app") return t("entryways.app");
    if (entry === "other") return t("entryways.other");
    return "";
  };
  const handleSaveCauseDetails = async (data) => {
    const {
      reason = "",
      details = false,
      hostile = false,
      amend = false,
      avoid = false,
      index,
    } = data;
    const newCauses = _.cloneDeep(causes);
    _.set(newCauses, `${index}`, {
      reason,
      details,
      enviroments: {
        hostile,
        amend,
        avoid,
      },
    });
    const objToSave = {
      folder: {
        causes: newCauses,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.error(error);
    }
  };
  const debouncedSaveCauseDerailsRef = useRef(
    _.debounce(handleSaveCauseDetails, 1100)
  );

  useEffect(() => {
    if (Array.isArray(causes)) {
      const newCauses = causes.map((item, index) => ({
        label: item.reason,
        value: item,
        index,
      }));
      setOptions(newCauses);
    }
  }, [causes]);

  useEffect(() => {
    if (selectedCause) {
      setDetails(selectedCause.value.details);
      setHostile(selectedCause.value.enviroments.hostile);
      setAmend(selectedCause.value.enviroments.amend);
      setAvoid(selectedCause.value.enviroments.avoid);
      prevDetailsRef.current = selectedCause.value.details;
      prevHostileRef.current = selectedCause.value.enviroments.hostile;
      prevAmendRef.current = selectedCause.value.enviroments.amend;
      prevAvoidRef.current = selectedCause.value.enviroments.avoid;
    }
  }, [selectedCause]);

  useEffect(() => {
    if (
      selectedCause &&
      (prevDetailsRef.current !== details ||
        prevHostileRef.current !== hostile ||
        prevAmendRef.current !== amend ||
        prevAvoidRef.current !== avoid)
    ) {
      prevDetailsRef.current = details;
      prevHostileRef.current = hostile;
      prevAmendRef.current = amend;
      prevAvoidRef.current = avoid;
      debouncedSaveCauseDerailsRef.current({
        reason: selectedCause.value.reason,
        details,
        hostile,
        amend,
        avoid,
        index: selectedCause.index,
      });
    }
  }, [details, hostile, amend, avoid]);

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded p-20">
        <div className="pb-20 border-bottom">
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <div>
              <h5>{t("General.general_investigation_data")} </h5>
              <small>
                {t("General.reception_date")}:{" "}
                {validInitDates(general?.about?.when)
                  ? parseDatoToLgAndHZ(
                      general?.about?.when,
                      t("General.locale")
                    )
                  : t("General.no_date")}
              </small>
            </div>
            <div>
              <MCButton
                label={t("General.edit")}
                variant="primary"
                outline
                className="me-1"
                onClick={() => toggleModal("general")}
              />
              <MCButton
                label={severity(general?.about?.severity)}
                className={`${severityClass(general?.about?.severity)}`}
              />
            </div>
          </div>
        </div>

        <div className="pb-20 border-bottom">
          <h6 className="mt-20 mb-0">{t("General.complainant")}</h6>
          <Row>
            <Col lg="4" md="12" className="mt-20 p-0">
              <small className="mb-2">{t("General.name")}:</small>
              <h6>{general?.reporter?.name || t("General.no_name")}</h6>
            </Col>
            <Col lg="4" md="12" className="mt-20 p-0">
              <small className="mb-2">
                {t("General.business_relationship")}:
              </small>
              <h6>
                {general?.reporter?.relationship || t("General.unspecified")}
              </h6>
            </Col>
            <Col lg="4" md="12" className="mt-20 p-0">
              <small className="mb-2">{t("General.entryway")}:</small>
              <h6>
                {entryway(general?.reporter?.entryway) ||
                  t("General.unspecified")}
              </h6>
            </Col>
          </Row>
        </div>

        <div className="py-3 border-bottom">
          <small className="mb-2">{t("General.range_date")}:</small>
          {general?.about?.event_happen?.from ||
          general?.about?.event_happen?.to ? (
            <h6 className="m-0">
              {`${
                validInitDates(general?.about?.event_happen?.from)
                  ? parseDateToDDMMYYYY2(general?.about?.event_happen?.from)
                  : t("General.no_date")
              } ${t("General.at")} 
              ${
                validInitDates(general?.about?.event_happen?.to)
                  ? parseDateToDDMMYYYY2(general?.about?.event_happen?.to)
                  : t("General.no_date")
              }`}
            </h6>
          ) : (
            <h6 className="m-0">{t("General.unspecified")}</h6>
          )}
        </div>

        <div className="py-3">
          <small className="mb-2">
            {t("General.grievance_classification")}:
          </small>
          <h6 className="mb-20">
            {general?.clasification?.category || t("General.unspecified")}
          </h6>
          <small>{general?.clasification?.subcategory}</small>
        </div>
      </div>
      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
        <div className="pb-20 border-bottom">
          <h6 className="my-3">{t("General.advisor_comments_eg")}</h6>
          <small>
            {general?.comentaries?.ethics_officer ||
              t("General.comments_eg_template")}
          </small>
        </div>
        <div className="py-3">
          <h6 className="my-3">{t("General.approvers_comments")}</h6>
          <small>
            {general?.comentaries?.ethics_adviser ||
              t("General.comments_example")}
          </small>
        </div>
      </div>

      <AddEraseInv
        toggleModal={toggleModal}
        handleUnlinkInvestigation={handleUnlinkInvestigation}
      />
    </div>
  );
};
