import { Col, Modal, Row } from "react-bootstrap";
import { MCButton } from "../../MainComponents/Button/Button";
import {
  listReportTemplates,
  retrieveReportTemplate,
  createExcelExport,
} from "../../../apis/apiExports";
import { useState, useEffect } from "react";
import { Loading } from "../../Loading/String/Loading";
import { FormReportTemplate } from "../ReportTemplates/FormReportTemplate";
import { MCInput, MCLabel, MCSelect, MCCheckbox } from "../../MainComponents";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import "./CreateFile.scss";

const MsgError = ({ state, message }) => {
  return state && <p className="required-field-msg">{message}</p>;
};

const DetailReportTemplateFields = ({ listFields }) => {
  const [t] = useTranslation("exports");
  return (
    <div className="container-detail-fields">
      {listFields.map((item) => (
        <div className="item-field " key={item.key}>
          <div>
            <p className="label-field">
              {t("createFile.field_name")} <strong>{item.label}</strong>
            </p>
          </div>

          <div>
            <p className="label-field">
              {t("createFile.translation")}
              <strong>{JSON.stringify(item.locales)}</strong>
            </p>
          </div>

          {item.is_sensitive && (
            <div className="sensitive">
              <p className="label-field">
                <strong>{t("createFile.field_sensitive")}</strong>
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const FormCreateFile = ({
  listOfReportTemplates,
  stateCloseModal,
  stateShowTemplatesForm = null,
}) => {
  const [t] = useTranslation("exports");
  const { enqueueSnackbar } = useSnackbar();

  // Error messages
  const isRequiredField = `${t("createFile.field_required")} *`;
  const errorMsgStartDate = `${t("createFile.start_date_err")} *`;
  const errorMsgEndDate = `${t("createFile.end_date_err")} *`;

  // List languajes
  const listLanguajes = [
    { value: "es", label: t("createFile.spanish") },
    { value: "en", label: t("createFile.english") },
  ];

  // list report templates states
  const [listReportTemplates, setListReportTemplates] = useState([]);

  // API states
  const [aliasReportTemplate, setAliasReportTemplate] = useState(null);
  const [languajeReportTemplate, setLanguajeReportTemplate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [comment, setComment] = useState(null);
  const [detailReportTemplate, setDetailReportTemplate] = useState([]);

  // ClassNames states
  const [classNameStartDate, setClassNameStartDate] = useState("");
  const [classNameEndDate, setClassNameEndDate] = useState("");

  // Validations states
  const [isValidStartDate, setIsValidStartDate] = useState(null);
  const [isValidEndDate, setIsValidEndDate] = useState(null);

  // loading states
  const [isLoading, setIsLoading] = useState(true);

  // Show Detail Report Template
  const [showDetailReportTemplate, setShowDetailReportTemplate] =
    useState(false);

  // excel columns
  const [excelColumns, setExcelColumns] = useState({
    entryway: false,
    subject: false,
    description: false,
    comment: false,
    email: false,
  });

  const transformDataToSelect = (data) => {
    /**
         * @param {Array} data
         * "data": [
                {
                    "alias": "RT2f2903RKc18r9da4n9M181Z0512U08Q3",
                    "template_name": "Plantilla del reporte 2 GLOBAL",
                    "created_at": "2023-09-11T10:49:51.840802",
                    "updated_at": "2023-09-11T10:49:51.840836",
                    "is_global": true
                },
                ...
            ]
            * @returns {Array} listReportTemplates
            * "listReportTemplates": [
                {
                    "value": "RT2f2903RKc18r9da4n9M181Z0512U08Q3",
                    "label": "Plantilla del reporte 2 (Global)"
                },
                ...
            ]
         */
    setListReportTemplates(
      data.map((item) => {
        return {
          value: item.alias,
          label: item.is_global
            ? `${item.template_name} (Global)`
            : item.template_name,
        };
      })
    );
  };

  useEffect(() => {
    transformDataToSelect(listOfReportTemplates);
  }, []);

  const getDetailDataReportTemplate = async (alias) => {
    const { data } = await retrieveReportTemplate(alias);
    if (data !== undefined || data !== null) {
      const { template_fields } = data;
      setIsLoading(false);
      setDetailReportTemplate(template_fields);
    }
  };

  const handleSelectedReportTemplate = (e) => {
    setIsLoading(true);
    setAliasReportTemplate(e.value);
    setShowDetailReportTemplate(true);
    getDetailDataReportTemplate(e.value);
  };

  const handleSelectedLanguajeReportTemplate = (e) => {
    setLanguajeReportTemplate(e.value);
  };

  const formatDate = (date) => {
    const dateWithoutFormat = new Date(date);
    const year = dateWithoutFormat.getFullYear();
    const month = dateWithoutFormat.getMonth() + 1;
    const day = dateWithoutFormat.getDate();
    return `${year}-${month}-${day}`;
  };

  const handleStartDate = (e) => {
    if (e !== null) {
      validateStartDate(e);
      setStartDate(e);
    } else {
      setStartDate(null);
      setIsValidStartDate(false);
      setClassNameStartDate("is-invalid");
    }
  };

  const handleEndDate = (e) => {
    if (e !== null) {
      setEndDate(e);
      validateEndDate(e);
    } else {
      setEndDate(null);
      setIsValidEndDate(false);
      setClassNameEndDate("is-invalid");
    }
  };

  const handleComment = (e) => {
    const comment = e.target.value.trim();
    if (comment !== "") setComment(comment);
    else setComment(null);
  };

  // Validations
  // Validate Dates start and end
  const validateStartDate = (date) => {
    const dateWithoutFormat = new Date(date);

    if (endDate === null) {
      fnSetValidStartDate();
      return;
    }

    if (dateWithoutFormat < endDate) {
      fnSetValidStartDate();
      fnSetValidEndDate();
      return;
    }

    fnSetInvalidStartDate();
  };

  const validateEndDate = (date) => {
    const dateWithoutFormat = new Date(date);

    if (startDate === null) {
      fnSetValidEndDate();
      return;
    }

    if (dateWithoutFormat > startDate) {
      fnSetValidEndDate();
      fnSetValidStartDate();
      return;
    }

    fnSetInvalidEndDate();
  };

  const fnSetInvalidStartDate = () => {
    setIsValidStartDate(false);
    setClassNameStartDate("is-invalid");
  };

  const fnSetInvalidEndDate = () => {
    setIsValidEndDate(false);
    setClassNameEndDate("is-invalid");
  };

  const fnSetValidStartDate = () => {
    setIsValidStartDate(true);
    setClassNameStartDate("");
  };

  const fnSetValidEndDate = () => {
    setIsValidEndDate(true);
    setClassNameEndDate("");
  };

  const handleBlurStartDate = (e) => {
    if (e.target.value !== "") {
      setClassNameStartDate("");
      validateStartDate(startDate);
    } else {
      setStartDate(null);
      setIsValidStartDate(false);
      setClassNameStartDate("is-invalid");
    }
  };

  const handleBlurEndDate = (e) => {
    if (e.target.value !== "") {
      setClassNameEndDate("");
      validateEndDate(endDate);
    } else {
      setEndDate(null);
      setIsValidEndDate(false);
      setClassNameEndDate("is-invalid");
    }
  };

  // Change excel checklist
  const handleCheckboxChange = (key) => (event) => {
    setExcelColumns((prevState) => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  const columnKeys = Object.keys(excelColumns);
  const groupedKeys = [];
  
  for (let i = 0; i < columnKeys.length; i += 2) {
    groupedKeys.push(columnKeys.slice(i, i + 2));
  }

  // Save Data
  const handleSaveData = async () => {
    enqueueSnackbar(t("createFile.saving_data"), {
      variant: "info",
    });
    const data = {
      template_alias: aliasReportTemplate,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      excel_columns: excelColumns,
    };

    if (comment !== null) data.comment = comment;

    const response = await createExcelExport(data, languajeReportTemplate);
    if (response !== undefined || response !== null) {
      stateCloseModal(false);
      enqueueSnackbar(t("createFile.data_saved"), {
        variant: "success",
      });
    }
  };

  return (
    <>
      <Row className="mt-2">
        <div className="new-template-btn">
          <MCButton
            label={t("createFile.new_template")}
            outline
            variant="primary"
            onClick={() => stateShowTemplatesForm(true)}
          />
        </div>
      </Row>

      <Row className="mt-2">
        <MCLabel text={t("createFile.select_template_label")} isRequired />
        <MCSelect
          label={t("createFile.report_template")}
          options={listReportTemplates}
          onChange={handleSelectedReportTemplate}
        />
      </Row>

      <Row className="mt-2">
        <Col>
          <div className={classNameStartDate}>
            <MCLabel text={`${t("createFile.start_date")}:`} isRequired />
            <MCInput
              type="date"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={handleStartDate}
              onBlur={handleBlurStartDate}
            />
            <MsgError
              state={isValidStartDate === false}
              message={startDate === null ? isRequiredField : errorMsgStartDate}
            />
          </div>
        </Col>
        <Col>
          <div className={classNameEndDate}>
            <MCLabel text={`${t("createFile.end_date")}:`} isRequired />
            <MCInput
              type="date"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={handleEndDate}
              onBlur={handleBlurEndDate}
            />
            <MsgError
              state={isValidEndDate === false}
              message={endDate === null ? isRequiredField : errorMsgEndDate}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col lg="6">
          <MCLabel
            text={`${t("createFile.select_language")} (${t(
              "createFile.optional"
            )}):`}
          />
          <MCSelect
            options={listLanguajes}
            onChange={handleSelectedLanguajeReportTemplate}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <MCLabel
          text={`Columnas fijas:`}
        />
      </Row>

      {groupedKeys.map((group, index) => (
        <Row className="mt-1" key={index}>
          {group.map((key) => (
            <Col key={key}>
              <MCCheckbox
                className="mx-1"
                onChange={handleCheckboxChange(key)}
                id={key}
                checked={excelColumns[key]}
              />
              <MCLabel text={t(`excelCheckList.${key}`)} htmlFor={key} />
            </Col>
          ))}
          {group.length === 1 && <Col />}
        </Row>
      ))}

      <Row className="mt-2">
        <MCLabel
          text={`${t("createFile.comment")} (${t("createFile.optional")}):`}
        />
        <MCInput
          type="textarea"
          placeholder={t("createFile.write_your_comment")}
          onChange={handleComment}
        />
      </Row>

      <Row className="mt-4">
        <MCButton
          label={t("createFile.create_report")}
          outline
          variant="primary"
          disabled={
            aliasReportTemplate === null ||
            isValidStartDate === null ||
            isValidStartDate === false ||
            isValidEndDate === null ||
            isValidEndDate === false
          }
          onClick={handleSaveData}
        />
      </Row>

      <Row className="mt-4">
        {showDetailReportTemplate &&
          (!isLoading ? (
            <>
              <DetailReportTemplateFields listFields={detailReportTemplate} />
            </>
          ) : (
            <Loading className="mt-3" />
          ))}
      </Row>
    </>
  );
};

export const CreateFile = ({ stateCloseModal }) => {
  // API states
  const [areThereReportTemplates, setAreThereReportTemplates] = useState(false);
  const [listOfReportTemplates, setListOfReportTemplates] = useState([]);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  // Show Report Template Form
  const [showReportTemplateForm, setShowReportTemplateForm] = useState(false);

  const getListReportTemplates = async () => {
    setIsLoading(true);
    const { data } = await listReportTemplates();
    if (data !== undefined && data.length > 0) {
      setListOfReportTemplates(data);
      setAreThereReportTemplates(true);
    } else setAreThereReportTemplates(false);

    setIsLoading(false);
  };

  useEffect(() => {
    getListReportTemplates();
  }, []);

  useEffect(() => {
    if (!showReportTemplateForm) getListReportTemplates();
  }, [showReportTemplateForm]);

  return (
    <>
      {!isLoading ? (
        areThereReportTemplates ? (
          !showReportTemplateForm ? (
            <FormCreateFile
              listOfReportTemplates={listOfReportTemplates}
              stateShowTemplatesForm={setShowReportTemplateForm}
              stateCloseModal={stateCloseModal}
            />
          ) : (
            <FormReportTemplate
              areThereReportForms={areThereReportTemplates}
              stateToReturn={setShowReportTemplateForm}
            />
          )
        ) : (
          <FormReportTemplate stateToReturn={setAreThereReportTemplates} />
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export const CreateFileModal = ({ showComponent, stateComponent }) => {
  const [t] = useTranslation("exports");
  return (
    <Modal show={showComponent} centered onHide={stateComponent} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{`${t("createFile.create_file")}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CreateFile stateCloseModal={stateComponent} />
      </Modal.Body>
    </Modal>
  );
};
