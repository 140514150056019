import { Row, Col, Tab, Nav } from "react-bootstrap";
import { OutlineMessageText } from "../../../Icon";
import { Chat } from "./Chat";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  getAnonymousPanel,
  getPrivateInternalAgentPanel,
  getPublicExternalAgentPanel,
} from "../../../../apis";
// Main component of EthicsMessenger
export const Messages = ({ tracking_code, id, reportDetail }) => {
  const [t] = useTranslation("messages");
  const [messagesPublic, setMessagesPublic] = useState(null);
  const [messagesPrivate, setMessagesPrivate] = useState(null);
  const [messagesAnonymus, setMessagesAnonymus] = useState(null);
  //Selection of type of message wants send
  const messages_folder = [
    {
      title: t("messages.to_complainant"),
      content: t("messages.to_complainant_content"),
      key: "public",
    },
    {
      title: t("messages.to_staff"),
      content: t("messages.to_staff_content"),
      key: "anonymous",
    },
  ];

  const onReportDetailChange = async () => {
    if (reportDetail) {
      const [apiMessagesAnonymus, apiMessagesPublic, apiMessagesPrivate] =
        await Promise.all([
          getAnonymousPanel(reportDetail.tracking_code),
          getPublicExternalAgentPanel(reportDetail.tracking_code),
          getPrivateInternalAgentPanel(reportDetail.tracking_code),
        ]);
      setMessagesAnonymus(apiMessagesAnonymus.reverse());
      setMessagesPublic(apiMessagesPublic.reverse());
      setMessagesPrivate(apiMessagesPrivate.reverse());
    }
  };

  useEffect(() => {
    onReportDetailChange();
  }, []);

  return (
    <div className="research-folder border-0 pl-0">
      <Tab.Container defaultActiveKey="public">
        <Row>
          {/* Select type of messages */}
          <Col lg={4} className="p-0">
            <div className="me-lg-4 p-20 mt-20 dyTheme1 dyBorder1 rounded">
              <h6 className="mt-20">{t("messages.select")}:</h6>
              <Nav className="flex-lg-column flex-row flex-nowrap">
                {messages_folder.map((item, id) => {
                  return (
                    <Nav.Item key={id}>
                      <Nav.Link eventKey={item.key}>
                        <div className="folder-block">
                          <div>
                            <OutlineMessageText />
                          </div>
                          <div className="ms-2">
                            <h6 className="m-0">{item.title}</h6>
                            <p className="d-none d-lg-block">{item.content}</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
          </Col>
          {/* Messages */}
          <Col lg={8} className="p-0">
            <div className="ms-lg-4 mt-20 dyTheme1 dyBorder1 rounded message">
              <Tab.Content>
                <Tab.Pane eventKey="public">
                  <Chat
                    messages={messagesPublic}
                    typeMsgs="public"
                    tracking_code={tracking_code}
                    onReportDetailChange={onReportDetailChange}
                    id={id}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="anonymous">
                  <Chat
                    messages={messagesPrivate}
                    typeMsgs="private"
                    tracking_code={tracking_code}
                    onReportDetailChange={onReportDetailChange}
                    id={id}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};
