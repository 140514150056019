import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MCCheckbox, MCLabel } from "../../../MainComponents";
import { updateStatusCheckboxes } from "../../../../apis";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
/** List of activities to do */
export const ToDoCare = ({
  checkboxes,
  setCheckboxes,
  selectAll,
  setSelectAll,
  status
}) => {


  // console.log("checkboxes", checkboxes);

  const { username } = useSelector((state) => state.auth);
  const [t] = useTranslation("report");
  const { id } = useParams();
  // Individual checkbox selection
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para mostrar el cuadro de carga

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();




  // useEffect(() => {
  //   console.log(selectAll)
  //  // console.log(reportDetail?.status?.sub_status?.[0]?.[0].length)
  //   /**Esto habilita el cambia estatus si no hay checkbox */
  //  // if(!reportDetail?.status?.sub_status?.[0]?.[0].length)    setSelectAll(true)

  // }, [selectAll]);


  const handleCheckboxChange = (index) => {
    if (isDisabled) return; // Si las casillas están desactivadas, no hacer nada

 
    enqueueSnackbar("Actualizado", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 1000,
    });


    // Mostrar cuadro de carga
    setIsLoading(true);
    const updatedCheckboxes = checkboxes.map((checkbox, i) => {
      if (i === index) {
        return {
          ...checkbox,
          checked: !checkbox.checked,
          updated_at: new Date(),  // Actualizar updated_at a la fecha y hora actual
          updated_by: username
        };
      }
      return checkbox;
    });

    setCheckboxes(updatedCheckboxes);
    handleUpdatecheckbox(updatedCheckboxes);

    // Verificar si todas las casillas están marcadas
    //const allSelected = updatedCheckboxes.every((checkbox) => checkbox.checked);
    const allSelected = updatedCheckboxes.length > 0 && updatedCheckboxes.every((checkbox) => checkbox.checked);
    // console.log(allSelected)
    setSelectAll(allSelected);

    // Desactivar las casillas temporalmente
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
      setIsLoading(false); // Ocultar cuadro de carga después de 1 segundo
    }, 1000); // Cambiado a 1000 ms para la desactivación individual
  };







  const handleSelectAllChange = () => {
    if (isDisabled) return; // Si las casillas están desactivadas, no hacer nada

    // Mostrar cuadro de carga
    setIsLoading(true);

    enqueueSnackbar("Actualizado", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 1000,
    });
    const allSelected2 = checkboxes.length > 0 && checkboxes.every((checkbox) => checkbox.checked);
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      // Solo actualizar las casillas que no están marcadas o que se van a desmarcar
      if (!allSelected2 && !checkbox.checked) {
        return {
          ...checkbox,
          checked: true,
          updated_at: new Date(),
          updated_by: username
        };
      } else if (allSelected2 && checkbox.checked) {
        return {
          ...checkbox,
          checked: false,
          updated_at: new Date(),
          updated_by: username
        };
      }
      return checkbox; // Devuelve la casilla sin modificar si no cambia su estado
    });
    const allSelected = updatedCheckboxes.length > 0 && updatedCheckboxes.every((checkbox) => checkbox.checked);
    
    setSelectAll(allSelected);
    setCheckboxes(updatedCheckboxes);
    handleUpdatecheckbox(updatedCheckboxes);
    

    // Desactivar las casillas temporalmente
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
      setIsLoading(false); // Ocultar cuadro de carga después de 1 segundo
    }, 1000); // Cambiado a 1000 ms para la desactivación de todos
  };










  const handleUpdatecheckbox = async (updatedCheckboxes) => {
    try {
      const resp = await updateStatusCheckboxes(
        id,
        updatedCheckboxes // Usar las casillas actualizadas pasadas como argumento
      );
    } catch (error) {
    }
  };





  // {isLoading && ( // Mostrar cuadro de carga si isLoading es true
  //   <div className="loading-overlay">
  //     <p>Cargando...</p>
  //   </div>
  // )}
  return (
    <>

      {checkboxes.length !== 0 && (
        <>
          <h5 className="p-3 mb-0">Lista de Actividades</h5>
          <div className="statusTask">
            <div className="statusTask__selectAll">
              <MCCheckbox
                id="selectsAll"
                onChange={handleSelectAllChange}
                checked={selectAll}
              />
              <MCLabel
                htmlFor="selectsAll"
                text={!selectAll ? t("Care.select_all") : t("Care.deselect_all")}
              />
            </div>
          </div>
        </>
      )}

      <ul className={`statusTask__list p-0 ${isLoading ? 'loading' : ''}`}>
        {checkboxes.map((checkbox, index) => (
          <li key={index} className={isLoading ? 'loading' : ''}>
            <MCCheckbox
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange(index)}
              id={`task-${index}`}
              disabled={isLoading} // Deshabilitar casilla mientras está cargando
            />
            <MCLabel
              htmlFor={`task-${index}`}
              className={`${checkbox.checked ? "done" : ""} label`}
              text={checkbox.label}
            />
          </li>
        ))}
      </ul>
    </>
  );
};