import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
//function to handle warning message and display them to the user
const showWarningMessage = (msg) => {
  enqueueSnackbar(`${msg}`, {
    variant: "warning",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
//function to handle warning message and display them to the user
const showSuccessMessage = (msg) => {
  enqueueSnackbar(`${msg}`, {
    variant: "success",
    preventDuplicate: true,
  });
};
// => Actualizar datos de la landing
export const updateLandingInfo = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;

  try {
    const resp = await apiCall.put(`${tenant}/api/landing/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_update_data}`);
    return error.response.data;
  }
};

// => Actualizar logos de landing
export const updateLandingLogos = async (files) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/landing/logos/`, files, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    showSuccessMessage(`${language.data_update_successful}`);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_update_data}`);
    return error.response.data;
  }
};

// => Get Material Support Files
export const getSupportMaterial = async (endpoint) => {
  try {
    const resp = await apiCall.get(`/landing/files/${endpoint}/`);
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

// => Actualizar el material de soporte
export const updateSupportMaterial = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall
      .post(`${tenant}/api/landing/support-material/`, data, {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      })
      .then(() => {
        showSuccessMessage(`${language.support_material_updated_successfully}`);
        return resp.data;
      });
  } catch (error) {
    showErrorMessage(`${language.error_updating_support_material}`);
    console.error(error);
    return error.response.data;
  }
};

// => Actualizar el material de soporte
export const getLanding = async (endpoint) => {
  try {
    const resp = await apiCall.get(`/landing/get/${endpoint}/?setup=true`);
    return resp.data;
  } catch (error) {
    if (error.response.status === 404) {
      showWarningMessage(`${language.landing_page_not_found}`);
      console.error(error);
    }
  }
};
