import { useState } from 'react';
import { CDBInput, CDBBtn } from 'cdbreact';
import { Row, Col,ProgressBar, Accordion } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import SimpleSelect from '../../../components/SimpleSelect';
import { GeneralHeader } from '../../../components/Title/GeneralHeader';

export const WorkplaceSettings = ({ vTheme }) => {
    const BackTheme = {
        backgroundColor: vTheme === "light" ? '#f3f7fa' : '#141319'
    }
    const DynamicTheme = {
        backgroundColor: vTheme === "light" ? '#fff' : '#1a1d27'
    }
    const BlueTheme = {
        backgroundColor: vTheme === "light" ? '#E9F5FD' : '#E9F5FD',
        color: vTheme === "light" ? '#263238' : '#263238',
        fontSize: 12
    }

    const [general_option] = useState([
        {
          text: "Option 1",
          value: "1",
        },
        {
          text: "Option 2",
          value: "2",
        },
        {
          text: "Option 3",
          value: "3",
        },
    ]);
    const assgin = [
        {
            header: "Asignar empresas",
            title: "Asignar todas las empresas",
            content: [
                { text: "GNP", status: true },
                { text: "Profuturo", status: true },
                { text: "Palacio de Hierro", status: false },
                { text: "Minería Peñoles", status: true },
                { text: "Alumno / Student", status: false },
                { text: "ITAM", status: true },
                { text: "Crédito Afianzador", status: true },
                { text: "Sofom Valmex", status: false },
                { text: "Médica Móvil", status: true }
            ]
        },
        {
            header: "Asignar centros de trabajo",
            title: "Asignar todos los centros del trabajo",
            content: [
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Colombia", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false }
            ]
        },
        {
            header: "Asignar áreas o departamentos",
            title: "Asignar todos los departamentos",
            content: [
                { text: "Recursos Humanos / Human Resources", status: true },
                { text: "Capital Humano / Human Capital", status: false },
                { text: "Recursos y Materiales / Resources and Materials", status: false },
                { text: "Compras y Adquisiciones / Purchases and Acquisitions", status: true },
                { text: "Recursos Humanos / Human Resources", status: false }
            ]
        },
        {
            header: "Asignar denuncias",
            title: "Asignar todo el catálogo de denuncias",
            content: [
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false }
            ]
        },
        {
            header: "Asignar preguntas",
            title: "Asignar todo el catálogo de preguntas",
            content: [
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
            ]
        },
        {
            header: "Asignar sugerencias",
            title: "Asignar todo el catálogo de sugerencias",
            content: [
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
            ]
        }
    ]
    const [lang1, setLang1] = useState('MX');
    const [lang2, setLang2] = useState('MX');

    return (
        <div id="settings" className={vTheme === "light" ? "" : "dark-mode"} style={ BackTheme } >

            <GeneralHeader 
                title="Configurar Corporativo Centro"
                text="Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis bibendum felis sit amet fringilla."
                showLang
                showPath
                path="Configuración > Centros de trabajo > Corporativo"
            />

            <Row>
                <Col lg={7} className="p-0">
                    <div className="pr-lg-30">
                        <div className='p-20 mt-4 border' style={ DynamicTheme } >
                            <h5 className='ml-3 mt-20'>Configuración de empleados</h5>
                            <small className='ml-3'>Agrega empleados para todas tus empresas</small>
                            <Row className='mt-20'>
                                <Col lg="6">
                                    <SimpleSelect label="País*" options={general_option} /> 
                                </Col>
                                <Col lg="6">
                                    <SimpleSelect label="Estado*" options={general_option} /> 
                                </Col>
                                <Col lg="6">
                                    <SimpleSelect label="Alcaldía o Municipio*" options={general_option} /> 
                                </Col>
                                <Col lg="6">
                                    <SimpleSelect label="Código Postal*" options={general_option} /> 
                                </Col>
                                <Col lg="12">
                                    <small className='mb-2'>Dirección completa (opcional)</small>
                                    <CDBInput hint="Place holder" size="sm" />
                                </Col>
                                <Col lg="12">
                                    <Row className='mt-20'>
                                        <Col lg="6" className="p-0">
                                            <small className='mb-20 mt-20'>¿Cuantos empleados totales tiene tu <br /> corporativo?</small>
                                            <CDBInput hint="4,500 empleados totales" size="sm" />
                                            <ProgressBar now={30} className="mt-4" />
                                        </Col>
                                        <Col lg="6" className="p-0">
                                            <div className='p-2 ml-lg-3 mt-20' style={ BlueTheme } >
                                                <strong>Plan Standard</strong>
                                                <br />
                                                200 empleados y costo por .89c/m empleado adicional <br />
                                                <strong>Plan Corporativo</strong>
                                                <br />
                                                .79c/m empleado adicional (actual)
                                                <br />
                                                <strong>Plan Premium</strong>
                                                <br />
                                                .59c/m empleado adicional
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className='p-20 mt-4 border' style={ DynamicTheme } >
                            <Accordion defaultActiveKey={['1']} alwaysOpen>
                                {assgin.map((item, id) => {
                                    return (
                                        <Accordion.Item className="assign-accordion" key={"assgin"+ id} eventKey={id}>
                                            <Accordion.Header>{item.header}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='d-block d-md-flex align-items-center justify-content-between pb-3 px-3 border-bottom'>
                                                    <CDBInput type="checkbox" label={item.title} id={"assign" + id} />
                                                    <CDBBtn className="small-primary-btn text-uppercase mt-2">{item.header}</CDBBtn>
                                                </div>
                                                <Row>
                                                    {item.content.map((list, idx) => {
                                                        return (
                                                            (
                                                                id === 0 ? 
                                                                <Col lg="6" key={id + "_" + idx}>
                                                                    <div className="mt-20 assign-check">
                                                                        <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                                    </div>
                                                                </Col>
                                                                : 
                                                                <Col lg="12" key={id + "_" + idx}>
                                                                    <div className="mt-20 assign-check">
                                                                        <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        )
                                                    })}
                                                </Row>
                                            </Accordion.Body>                            
                                        </Accordion.Item>                            
                                    )
                                })}
                            </Accordion>
                        </div>
                    </div>
                </Col>
                <Col lg={5} className="mt-4 p-0">
                    <div className='pl-lg-30'>
                        <div className='p-20 border' style={ DynamicTheme } >
                            <div className="pb-3 border-bottom">
                                <h6>Configurar etiqueta</h6>
                                <small>Ajusta el nombre de la etiqueta por cada idioma de atención disponible.</small>
                            </div>
                            <Row className='mt-20'>
                                <Col lg="6">
                                    <small className='mb-2'>Nombre de la etiqueta</small>
                                    <CDBInput hint="Corporativo Centro" size="sm" />
                                </Col>
                                <Col lg="6">
                                    <small className='mb-2'>Nombre de la etiqueta</small>
                                    <ReactFlagsSelect
                                        selected={lang1}
                                        onSelect={code => {setLang1(code)}}
                                        placeholder="Idioma"
                                        selectButtonClassName='dropdown-flags'
                                        countries={["US", "MX"]}
                                        customLabels={{"US": "Ingles","MX": "Español"}} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <small className='mb-2'>Nombre de la etiqueta</small>
                                    <CDBInput hint="Corporativo Centro" size="sm" />
                                </Col>
                                <Col lg="6">
                                    <small className='mb-2'>Nombre de la etiqueta</small>
                                    <ReactFlagsSelect
                                        selected={lang2}
                                        onSelect={code => {setLang2(code)}}
                                        placeholder="Idioma"
                                        selectButtonClassName='dropdown-flags'
                                        countries={["US", "MX"]}
                                        customLabels={{"US": "Ingles","MX": "Español"}} 
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
