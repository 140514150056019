import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { patchInvestigation } from "../../../../../apis";
import { getUserList } from "../../../../../apis/apiUsers";
import { useEffect } from "react";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";
import { userTypeOpts } from "../Usuarios/userTypeOpts";

export const AddUser = ({ investigationAlias, formValues, toggleModal }) => {
  const [userType, setUserType] = useState(userTypeOpts[0]);
  const [userList, setUserList] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [t] = useTranslation("investigation");
  // const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getUserList().then((resp) => {
      setUserList(resp.users);
    });
  }, []);

  const handleSubmit = async (evt) => {
    let newForm;
    evt.preventDefault();
    if (formValues === null) {
      newForm = {
        access: [],
        financial: [],
        human: [],
        material: [],
      };
    } else {
      newForm = _.cloneDeep(formValues);
    }
    if (
      userType.value === "additional" ||
      userType.value === "assigned" ||
      userType.value === "incharge" ||
      userType.value === "virtual"
    ) {
      if (userType.value in newForm && Array.isArray(newForm[userType.value])) {
        newForm[userType.value].push({
          rol: userType.value,
          name: selectedUser?.firstname,
          email: selectedUser?.email,
          access: true,
          user_id: selectedUser?.id,
        });
      } else {
        newForm[userType.value] = [
          {
            rol: userType.value,
            name: selectedUser?.firstname,
            email: selectedUser?.email,
            access: true,
            user_id: selectedUser?.id,
          },
        ];
      }
    }
    const objToSave = {
      folder: {
        users: newForm,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.error(error);
    }
    toggleModal(false);
  };

  return (
    <div>
      <Row>
        <h5>{t("addUser.general_investigation_data")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("addUser.user_type")}:</small>
          <MCSelect
            options={userTypeOpts}
            value={userType}
            onChange={setUserType}
          />
        </Col>
        {userList && (
          <Col md={6} lg={4} className="input-container">
            <small className="my-2 d-block">
              {t("addUser.available_users")}:
            </small>
            <MCSelect
              options={userList}
              value={selectedUser}
              onChange={setSelectedUser}
              formatOptionLabel={({ first_name }) => `${first_name}`}
            />
          </Col>
        )}
      </Row>

      {selectedUser && (
        <>
          <Row>
            <Col md={6} lg={4} className="input-container">
              <small className="my-2 d-block">{t("addUser.user")}:</small>
              <MCInput type="text" value={selectedUser?.username} disabled />
            </Col>
            <Col md={6} lg={4} className="input-container">
              <small className="my-2 d-block">{t("addUser.email")}:</small>
              <MCInput type="text" value={selectedUser?.email} disabled />
            </Col>
            <Col md={6} lg={4} className="input-container">
              <small className="my-2 d-block">{t("addUser.phone")}:</small>
              <MCInput
                type="text"
                value={selectedUser?.phone_number}
                disabled
              />
            </Col>
            <Col md={6} lg={4} className="input-container">
              <small className="my-2 d-block">{t("addUser.status")}:</small>
              <MCInput
                type="text"
                value={
                  selectedUser?.is_active
                    ? t("addUser.active_user")
                    : t("addUser.inactive_user")
                }
                disabled
              />
            </Col>
          </Row>

          <Row>
            <div className="mt-4 d-flex justify-content-end">
              <MCButton
                label={t("addUser.cancel")}
                variant="primary"
                outline
                onClick={() => toggleModal("just-close")}
                className="me-2"
              />
              <MCButton
                label={t("addUser.save")}
                variant="primary"
                outline
                onClick={handleSubmit}
              />
            </div>
          </Row>
        </>
      )}
    </div>
  );
};
