import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import {
    SearchIcon2,
} from "../Icon";
import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import SimpleSearch from "./SimpleSearch"
import AdvancedSearch from "./AdvancedSearch"





const SearchBar = ({ placeholder, onChange, options, value, onKeyDown, onSubmit = () => { }, ...rest }, props) => {
    const [t] = useTranslation("report");
    const [showDetails, setShowDetails] = useState(false);
    const modalRef = useRef(null);
    const modalRefHeader = useRef(null);
    const modalRefSearchBar = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const smallSearchRef = useRef(null);
    const bigSearchRef = useRef(null);
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('general');





    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setShowDetails(false);
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const closeTap = () => {
        setShowDetails(false);
        setSearchTerm('');
    };

    useEffect(() => {
        // Cierra el modal cuando la URL cambie
        setSearchTerm('');
        setShowDetails(false);
    }, [location, setShowDetails]);

    const handleFocus = () => {
        //se tarda 2 milisegundos mas para colocar la barra de escritura del modal
        setTimeout(() => {
            setShowDetails(true);
        }, 150);
        setTimeout(() => {
            bigSearchRef.current.focus();
        }, 152);
    };

    useEffect(() => {
        // }, [searchTerm, startDate, endDate, kindOfEntrywaysValue, kindOfStatusValue, kindOfSeverityValue, kindOfSubCatalogKey, kindOfSubCatalogValues]);
    }, [searchTerm]);
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };




    return (
        <div>
            {/* <div className='pruebita-de-clase'>
                <SearchIcon2  onClick={handleFocus} />
            </div> */}
            {!showDetails && (

                <div className="custom-search">
                    <form className="searchsInputs w-100" onSubmit={(e) => e.preventDefault()}>
                        <div className="searchIcon">
                            <SearchIcon2 className={"iconito"} onClick={handleFocus}></SearchIcon2>
                        </div>
                        <input
                            type="search"
                            placeholder={t("searchBar.search")}
                            id="header-search"
                            name="report"
                            autoComplete="off"
                            onFocus={handleFocus}
                            onChange={() => { }}
                            ref={smallSearchRef}
                            className='input-search'
                        />
                    </form>
                </div>
            )}
            {showDetails && (
                <>
                    <div className="custom-searchBiggest" ref={modalRefHeader}>
                        <form className="searchsInputs w-120" onSubmit={(e) => e.preventDefault()} ref={modalRefSearchBar}>
                            <div className="searchIcon">
                                <SearchIcon2 size={16} />
                            </div>
                            <input
                                placeholder={t("searchBar.search_in_casemanager")}
                                id="header-search"
                                name="report"
                                autoComplete="off"
                                onFocus={handleFocus}
                                onChange={handleInputChange}
                                ref={bigSearchRef}
                            />
                            <div className="close-icon" onClick={closeTap} >
                                <FaTimes size={16} />
                            </div>
                        </form>
                    </div>
                    <div className="search-details" ref={modalRef}>
                        <div className="search-details-section">
                            <Row>
                                <Col xs={12} md={12} xxl={12}>
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={activeKey}
                                        onSelect={(k) => setActiveKey(k)}
                                        className="tabs-class mb-3"
                                    >
                                        <Tab
                                            eventKey="general"
                                            title={
                                                <span
                                                    style={{
                                                        fontFamily: 'Segoe UI',
                                                        fontSize: '20px',
                                                        fontWeight: 600,
                                                        color: activeKey === 'general' ? '#009ed7' : '#546E7A'
                                                    }}
                                                >
                                                    {t("searchBar.basic_search")}
                                                </span>
                                            }
                                            className="table-results"
                                        >
                                            <SimpleSearch searchTermSecondary={searchTerm} />
                                        </Tab>
                                        <Tab
                                            eventKey="notifications"
                                            title={
                                                <span
                                                    style={{
                                                        fontFamily: 'Segoe UI',
                                                        fontSize: '20px',
                                                        fontWeight: 600,
                                                        color: activeKey === 'notifications' ? '#009ed7' : '#546E7A'
                                                    }}
                                                >
                                                    {t("searchBar.advanced_search")}
                                                </span>
                                            }
                                            className="table-results"
                                        >
                                            <AdvancedSearch searchTermSecondary={searchTerm} />
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}


export default SearchBar;
