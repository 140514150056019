import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOnline: navigator.onLine };

export const onlineSlice = createSlice({
  name: "online",
  initialState,
  reducers: {
    setOnline: (state, action) => {
      const { isOnline } = action.payload;
      state.isOnline = isOnline;
    },
  },
});

export const { setOnline } = onlineSlice.actions;
export default onlineSlice.reducer;
