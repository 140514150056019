import { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Row, Col, Accordion } from 'react-bootstrap';
import { CDBInput, CDBBtn } from 'cdbreact';
import SearchBar from '../../../components/SearchBar/Search';
import { GeneralHeader } from '../../../components/Title/GeneralHeader';

export const PeopleSettings = ({ vTheme }) => {
    const BackTheme = {
        backgroundColor: vTheme === "light" ? '#f3f7fa' : '#141319'
    }
    const DynamicTheme = {
        backgroundColor: vTheme === "light" ? '#fff' : '#1a1d27'
    }
    const assgin = [
        {
            key: "",
            header: "Asignar empresas",
            title: "Asignar todas las empresas",
            content: [
                { text: "GNP", status: true },
                { text: "Profuturo", status: true },
                { text: "Palacio de Hierro", status: false },
                { text: "Minería Peñoles", status: true },
                { text: "Alumno / Student", status: false },
                { text: "ITAM", status: true },
                { text: "Crédito Afianzador", status: true },
                { text: "Sofom Valmex", status: false },
                { text: "Médica Móvil", status: true }
            ]
        },
        {
            key: "",
            header: "Asignar centros de trabajo",
            title: "Asignar todos los centros del trabajo",
            content: [
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Colombia", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false }
            ]
        },
        {
            key: "",
            header: "Asignar áreas o departamentos",
            title: "Asignar todos los departamentos",
            content: [
                { text: "Recursos Humanos / Human Resources", status: true },
                { text: "Capital Humano / Human Capital", status: false },
                { text: "Recursos y Materiales / Resources and Materials", status: false },
                { text: "Compras y Adquisiciones / Purchases and Acquisitions", status: true },
                { text: "Recursos Humanos / Human Resources", status: false }
            ]
        },
        {
            key: "denuncias",
            header: "Asignar denuncias",
            title: "Asignar todo el catálogo de denuncias",
            content: [
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false }
            ]
        },
        {
            key: "preguntas",
            header: "Asignar preguntas",
            title: "Asignar todo el catálogo de preguntas",
            content: [
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
            ]
        },
        {
            key: "sugerencias",
            header: "Asignar sugerencias",
            title: "Asignar todo el catálogo de sugerencias",
            content: [
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
            ]
        }
    ]
    const [lang, setLang] = useState('MX')

    return (
        <div id="settings" className={vTheme === "light" ? "" : "dark-mode"} style={ BackTheme } >

            <GeneralHeader 
                title="Configurar Empleados"
                text="Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis bibendum felis sit amet fringilla."
                showLang
                showPath
                path="Configuración > Personas > Empleados"
            />

            <Row>
                <Col lg={7} className="mt-30 p-0">
                    <div className='pr-lg-30'>
                        <div className='py-20 px-30 border' style={ DynamicTheme } >
                            <Accordion defaultActiveKey={['0','1','2','3','4']}>
                                {assgin.map((item, id) => {
                                    return (
                                        <Accordion.Item className="assign-accordion" key={id} eventKey={id}>
                                            <Accordion.Header>{item.header}</Accordion.Header>
                                            <Accordion.Body>
                                                <SearchBar placeholder={"Buscar " + item.key + "..."} />
                                                <div className='d-block d-md-flex align-items-center justify-content-between py-20 border-bottom'>
                                                    <CDBInput type="checkbox" label={item.title} id={"assign" + id} />
                                                    <CDBBtn className="small-primary-btn text-uppercase mt-2">{item.header}</CDBBtn>
                                                </div>
                                                <Row>
                                                    {item.content.map((list, idx) => {
                                                        return (
                                                            (
                                                                id === 0 ? 
                                                                <Col lg="6" className="p-0" key={id + "_" + idx}>
                                                                    { idx % 2 !== 0 ?
                                                                        <div className="mt-20 assign-check ml-lg-30">
                                                                            <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                                        </div> :
                                                                        <div className="mt-20 assign-check mr-lg-30">
                                                                            <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                : 
                                                                <Col lg="12" className="p-0" key={id + "_" + idx}>
                                                                    <div className="mt-20 assign-check">
                                                                        <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                                    </div>
                                                                </Col>
                                                            )
                                                        )
                                                    })}
                                                </Row>
                                            </Accordion.Body>                            
                                        </Accordion.Item>                            
                                    )
                                })}
                            </Accordion>
                        </div>
                    </div>
                </Col>
                <Col lg={5} className="mt-30 p-0">
                    <div className='p-20 border' style={ DynamicTheme } >
                        <div className="pb-3 border-bottom">
                            <h6>Configurar etiqueta</h6>
                            <small>Ajusta el nombre de la etiqueta por cada idioma de atención disponible.</small>
                        </div>
                        <Row className='mt-20'>
                            <Col lg="6">
                                <small className='mb-2'>Nombre de la etiqueta</small>
                                <CDBInput hint="Corporativo Centro" size="sm" />
                            </Col>
                            <Col lg="6">
                                <small className='mb-2'>Nombre de la etiqueta</small>
                                <ReactFlagsSelect
                                    selected={lang}
                                    onSelect={code => {setLang(code)}}
                                    placeholder="Idioma"
                                    selectButtonClassName='dropdown-flags'
                                    countries={["US", "MX"]}
                                    customLabels={{"US": "Ingles","MX": "Español"}} 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <small className='mb-2'>Nombre de la etiqueta</small>
                                <CDBInput hint="Corporativo Centro" size="sm" />
                            </Col>
                            <Col lg="6">
                                <small className='mb-2'>Nombre de la etiqueta</small>
                                <ReactFlagsSelect
                                    selected={lang}
                                    onSelect={code => {setLang(code)}}
                                    placeholder="Idioma"
                                    selectButtonClassName='dropdown-flags'
                                    countries={["US", "MX"]}
                                    customLabels={{"US": "Ingles","MX": "Español"}} 
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

