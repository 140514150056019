import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import queryString from "query-string";
import { Dropdown, Row } from "react-bootstrap";
import {
  updateCatalogueLabel,
  updateStatusCatalogue,
} from "../../apis/apiCatalogues";
import Icon from "../Icon/Icon";
import {
  CatalogueInput,
  ModalAddChild,
} from "../JSONForms/PreviewForm/CatalogueInput";
import { MCCheckbox, MCInput } from "../MainComponents/Input/Input";
import { MCButton } from "../MainComponents/Button/Button";
import { MCLabel } from "../MainComponents";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";
import { ModalLocales } from "./ModalLocales";
import { useTranslation } from "react-i18next";
import "./Catalogues.scss";
// import { ModalLocales } from "./ModalLocales";

export const EditCatalogues = ({
  scope,
  data: initData,
  catalogue,
  isOwn,
  is_active,
  setActiveScreen,
  rerenderParent,
}) => {
  const [t] = useTranslation("catalogues");
  const location = useLocation();
  const navigate = useNavigate();
  const [schema, setSchema] = useState(scope);
  const [data, setData] = useState(initData);
  const [isActive, setIsActive] = useState(is_active);
  const [title, setTitle] = useState(scope[0].label);
  const [rerender, setRerender] = useState(false);
  const [showModalLocales, setShowModalLocales] = useState(false);
  const [showModalAddChild, setShowModalAddChild] = useState(false);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    setSchema(scope);
    setData(initData);
    const q = queryString.parse(location.search);
    if (q.act) {
      if (q.act === "n") {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    } else {
      setIsActive(is_active);
    }
    setRerender(true);
  }, [catalogue]);

  useEffect(() => {
    const q = queryString.parse(location.search);
    q.data = data[0][catalogue];
    navigate(`?${queryString.stringify(q)}`);
  }, [data[0][catalogue]]);

  const handleSaveTitle = async () => {
    const arrPromises = [];
    try {
      arrPromises.push(
        updateCatalogueLabel({
          is_own: isOwn,
          catalogue,
          path: "/",
          label: title,
        })
      );
      arrPromises.push(
        updateStatusCatalogue({
          is_own: isOwn,
          catalogue,
          path: "/",
          is_active: isActive,
        })
      );
      const resp = await Promise.all(arrPromises);
      const q = queryString.parse(location.search);
      q.lbl = title;
      q.act = isActive ? "y" : "n";
      navigate(`?${queryString.stringify(q)}`);
      rerenderParent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="single-catalogue-page dyTheme1 dyBorder1 rounded p-4">
      {showModalLocales && (
        <ModalLocales
          temmp={schema[0]}
          objSelection={
            schema[0]?.locales
              ? { locales: schema[0].locales }
              : { locales: {} }
          }
          catalogue={catalogue}
          is_own={isOwn}
          onHide={() => setShowModalLocales(false)}
          path="/"
          nestNum={0}
          rerenderParent={rerenderParent}
        />
      )}
      {showModalAddChild && (
        <ModalAddChild
          path="/"
          catalogue={catalogue}
          is_own={isOwn}
          onHide={() => setShowModalAddChild(false)}
          rerenderParent={rerenderParent}
        />
      )}
      <div>
        <div className="d-flex">
          <Icon
            name="arrow_left_3"
            className="mr-4 dyIcon2"
            onClick={() => {
              setActiveScreen("info");
              navigate("");
            }}
          />
          <h4>{t("Catalogues.edit_catalog")}</h4>
        </div>
        <small>{t("Catalogues.can_modify_labels")}</small>
      </div>
      {!rerender && (
        <>
          <div className="catalogue-name-container disabled">
            <div className="d-flex justify-content-start align-items-center">
              <span className="me-3">{t("Catalogues.catalog")}:</span>
              <MCInput
                value={title}
                style={{ width: "300px" }}
                className="mt-0"
                onChange={(e) => setTitle(e.target.value)}
              />
              {schema[0]?.locales && (
                <div className="tooltip-icon">
                  <MCTooltip
                    text={
                      <>
                        {Object.entries(schema[0].locales).map(
                          ([key, value]) => (
                            <li key={key}>
                              {key.toUpperCase()}: {value}
                            </li>
                          )
                        )}
                      </>
                    }
                    position="top"
                  >
                    <span className="helptip">i</span>
                  </MCTooltip>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle variant="">
                  <Icon name="more_dots" className="dyIcon2" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowModalLocales(true)}>
                    <Icon name="lenguage_square" />
                    <span className="ms-2">{t("Catalogues.languages")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowModalAddChild(true)}>
                    <Icon name="outline_edit_2" />
                    <span className="ms-2">
                      {t("Catalogues.add_condition")}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/*               
              <div className="catalogue-icon-for-edition pointer" onClick={()=>setShowModalEditInfo(true)}>
                <Icon name="outline_edit" />
              </div>
              <div className="catalogue-icon-for-edition pointer ms-2" onClick={()=>setShowModalLocales(true)}>
                <Icon name="lenguage_square" />
              </div> */}

              <div className="mx-2">
                <MCCheckbox
                  name="is_active"
                  className="me-1"
                  id="check-for-active-catalogue"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
                <MCLabel
                  text={t("Catalogues.catalog_enabled")}
                  htmlFor="check-for-active-catalogue"
                />
              </div>
              <MCButton
                label={t("Catalogues.save")}
                variant="primary"
                onClick={handleSaveTitle}
              />
            </div>
          </div>

          <div className="catalog-container dyBorder1 rounded edit-catalogue">
            {scope && (
              <Row>
                <CatalogueInput
                  schema={schema[0]}
                  scope={schema[0]}
                  value={data[0][catalogue]}
                  catalogue={catalogue}
                  isOwn={isOwn}
                  entireSchema={schema}
                  entireFormData={data}
                  setSchemaState={setSchema}
                  setFormData={setData}
                  pathSchema={"0"}
                  pathData={"0"}
                  returnValidClass={() => {}}
                  handleValidate={() => {}}
                  rerenderParent={rerenderParent}
                  forEdition={true}
                />
              </Row>
            )}
          </div>

          {/* {modalOpen && (
            <ModalLang
              show={modalOpen}
              onHide={() => setModalOpen()}
              scope={scope}
              aliasEdit={aliasEdit}
              onStateChange={handleStateChangeModal}
            />
          )} */}
        </>
      )}
      {/* <pre>{JSON.stringify(data,null,2)}</pre> */}
      {/* {JSON.stringify(schema,null,2)} */}
    </div>
  );
};
