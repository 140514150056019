import { forwardRef } from "react";
import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import {
  CDBIcon,
  CDBBtn,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
} from "cdbreact";
import { BoldDocumentText2, MenuDotBold } from "../../Icon";
import Config from "../Config-dropdown";
import Filter from "../Filter";
import { GeneralHeader } from "../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

// const columns = [
//     {
//         button: true,
//         name: "Icono",
//         cell: () => (
//             <BoldDocumentText2 />
//         )
//     },
//     {
//         name: "Controlador",
//         selector: (row) => row.controller,
//         sortable: true
//     },
//     {
//         name: "Acción",
//         selector: (row) => row.action,
//         sortable: true
//     },
//     {
//         name: "Etiqueta",
//         selector: (row) => row.label,
//         sortable: true,
//     },
//     {
//         name: "Superior",
//         selector: (row) => row.higher,
//         sortable: true,
//     },
//     {
//         name: "Orden",
//         selector: (row) => row.order,
//         sortable: true,
//     },
//     {
//         name: "Acciones",
//         cell: () => (
//             <div className="App">
//                 <div className="text-center">
//                     <CDBIcon icon="eye" data-bs-toggle="modal" data-bs-target="#previewModal" />
//                     <CDBIcon icon="edit" className="ml-3"/>
//                     <div className="modal" id="previewModal">
//                         <div className="modal-dialog">
//                             <div className="modal-content">
//                                 <div className="modal-header">
//                                     <h5 className="modal-title">Modal title</h5>
//                                     <button
//                                         type="button"
//                                         className="btn-close"
//                                         data-bs-dismiss="modal"
//                                         aria-label="Close"
//                                     ></button>
//                                 </div>
//                                 <div className="modal-body">
//                                     <p>Modal body text goes here.</p>
//                                 </div>
//                                 <div className="modal-footer">
//                                     <button
//                                         type="button"
//                                         className="btn btn-secondary"
//                                         data-bs-dismiss="modal"
//                                     >
//                                         Close
//                                     </button>
//                                     <button type="button" className="btn btn-primary">
//                                         Save changes
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// ];

// const BootyCheckbox = forwardRef(({ onClick, ...rest }, ref) => (
//     <div className="form-check">
//         <input
//             htmlFor="booty-check"
//             type="checkbox"
//             className="form-check-input"
//             ref={ref}
//             onClick={onClick}
//             {...rest}
//         />
//         <label className="form-check-label" id="booty-check" />
//     </div>
// ));

export const MenusUser = ({ vTheme }) => {
  const [t] = useTranslation("users");
  const BackTheme = {
    backgroundColor: vTheme === "light" ? "#f3f7fa" : "#141319",
  };
  const DynamicTheme = {
    backgroundColor: vTheme === "light" ? "#fff" : "#1a1d27",
  };
  return (
    <div
      id="settings"
      className={vTheme === "light" ? "" : "dark-mode"}
      style={BackTheme}
    >
      <GeneralHeader title={t("menus.menus")} />

      <div className="p-20 mt-20 border users" style={DynamicTheme}>
        <div className="sub-header mt-0 mb-3">
          <div className="d-flex align-items-center gap-1">
            <MenuDotBold className="ml-2 switch-icon" />
            <small className="ml-2">{`${t("menus.showing")} 1 ${t(
              "menus.at"
            )} 10 ${t("menus.of")} 21`}</small>
          </div>
          <div className="review-icon user-header">
            <div className="d-none d-sm-flex align-items-center justify-space-between mr-3">
              <CDBDropDown>
                <CDBDropDownToggle caret dropdown className="custom-dropdown">
                  {`${t("menus.last")} 30 ${t("menus.days")}`}
                </CDBDropDownToggle>
                <CDBDropDownMenu>
                  <ul className="m-0 p-0">
                    <li>Option 1</li>
                    <li>Option 2</li>
                  </ul>
                </CDBDropDownMenu>
              </CDBDropDown>
              <Filter theme={vTheme} />
            </div>
            <CDBBtn className="athics-btn w-150 mt-3 mt-sm-0">
              <Link to="/users/menus/new">{t("menus.new_menu")}</Link>
            </CDBBtn>
          </div>
        </div>
        {/* <DataTable
          columns={columns}
          data={data}
          defaultSortFieldID={1}
          pagination
          selectableRows
          selectableRowsComponent={BootyCheckbox}
        /> */}
      </div>
    </div>
  );
};
