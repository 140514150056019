import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export const EthicsModal = ({
  show,
  handleModal,
  modalContent,
  size = "md",
}) => {
  const { gTheme } = useSelector((state) => state.theme);

  return (
    <Modal
      show={show}
      centered
      onHide={handleModal}
      size={size}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      {modalContent}
    </Modal>
  );
};
