import { useCallback, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { CDBBtn, CDBCard, CDBCardBody, CDBInput } from "cdbreact";
import SimpleSelect from "../../components/SimpleSelect";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import PaymentCard from "./PaymentCard";
import Step from "../Auth/Step";
import { GeneralHeader } from "../../components/Title/GeneralHeader";

const PaymentInfo = () => {
  const [t] = useTranslation("settings");
  const [paymentStep, setPaymentStep] = useState(infoPayments[0]);

  const infoPayments = [
    { value: 1, label: t("PaymentInfo.step1") },
    { value: 2, label: t("PaymenyInfo.step2") },
  ];

  const countries = [
    { value: "MX", text: t("PaymentInfo.mexico") },
    { value: "US", text: t("PaymentInfo.usa") },
  ];

  const cities = [{ value: "MX", text: t("PaymentInfo.mexico_city") }];

  const zipCodes = [{ value: "80001", text: "80001" }];

  const plan = {
    name: t("PaymentInfo.basic_plan"),
    subtotal: "20,350",
    currency: "MXN",
    taxes: "3,256",
    total: "23,606",
    color: "#3181FF",
  };

  const PaymentInfo = () => (
    <>
      <h5 className="mb-4">
        <b>{t("PaymentInfo.payment_information")}</b>
      </h5>
      <SimpleSelect
        label={`$(t("PaymentInfo.country")): *`}
        options={countries}
        onChange={handleCountry}
      />
      <div className="d-block d-sm-flex flex-row">
        <div className="w-100 pr-sm-3 mt-20">
          {/* <InputText
            label={t("paymentInfo.names")}
            type="text"
            placeholder=""
            required={true}
          /> */}
        </div>
        <div className="w-100 pl-sm-3 mt-20 mb-20">
          {/* <InputText
            label={t("paymentInfo.surname")}
            type="text"
            placeholder=""
            required={true}
          /> */}
        </div>
      </div>
      <CustomPhoneInput label={`${t("PaymentInfo.phone")}:`} />
      <div className="mt-20">
        {/* <InputText
          label={t("paymentInfo.address")}
          type="text"
          placeholder=""
          required={true}
        /> */}
      </div>

      <div className="d-block d-sm-flex flex-row">
        <div className="w-100 pr-sm-3 mt-20">
          {/* <InputText
            label={t("paymentInfo.colony")}
            type="text"
            placeholder=""
            required={true}
          /> */}
        </div>
        <div className="w-100 pl-sm-3 mt-20">
          <SimpleSelect
            label={`t("PaymentInfo.city"): *`}
            options={cities}
            onChange={handleCity}
          />
        </div>
      </div>
      <div className="d-block d-sm-flex flex-row">
        <div className="w-100 my-20">
          <SimpleSelect
            label={`${t("PaymentInfo.zip_code")}: *`}
            options={zipCodes}
            onChange={handleZipCode}
          />
        </div>
      </div>
      <CDBInput type="checkbox" label={t("PaymentInfo.use_billing_address")} />
      <br />
      <CDBBtn block={false} disabled={false} className="athics-btn mt-20 w-100">
        {t("PaymentInfo.save")}
      </CDBBtn>
    </>
  );
  const PaymentMethod = () => (
    <div className="d-flex flex-column">
      <h5 className="mb-0">{t("PaymentInfo.payment_method")}</h5>
      <div className="d-block d-sm-flex flex-row text-left">
        <div className="d-flex justify-content-center w-100 pr-md-20 mt-30">
          {/* <ButtonCheck text={'Tarjeta de credito'} icon={'bold_cards'} width={'50%'} /> */}
        </div>
        <div className="d-flex justify-content-center w-100 pl-md-20 mt-30 mercado_check">
          {/* <ButtonCheck text={'Mercado Pago'} icon={'mercadolibre'} width={'50%'} /> */}
        </div>
      </div>

      <div className="d-block d-sm-flex flex-row text-left pb-30 border-bottom">
        <div className="d-flex w-100 pr-md-20 mt-30 paypal_check">
          {/* <ButtonCheck text={'Paypal'} width={'50%'} icon={'paypal'} /> */}
        </div>
        <div className="d-flex w-100 pl-md-20 mt-30">
          {/* <ButtonCheck text={'Bitcoin y Criptomonedas'} icon={'bold_bitcoin_card'} width={'50%'} /> */}
        </div>
      </div>

      <div className="d-block d-sm-flex flex-row text-left mt-30">
        <div className="pr-md-20 w-100">
          {/* <InputText 
                            label="Nombre Completo *" 
                            type="text"
                            placeholder=""
                            required={true}
                        /> */}
        </div>
        <div className="pl-md-20 w-100">
          {/* <InputText 
                            label="Numero de tarjeta *" 
                            type="text"
                            placeholder=""
                            required={true}
                        /> */}
        </div>
      </div>
      <div className="d-block d-sm-flex flex-row text-left mt-10">
        <div className="pr-md-20 w-100">
          {/* <InputText 
                            label="MM/AA *" 
                            type="text"
                            placeholder=""
                            required={true}
                        /> */}
        </div>
        <div className="pl-md-20 w-100">
          {/* <InputText 
                            label="CVV *" 
                            type="text"
                            placeholder=""
                            required={true}
                        /> */}
        </div>
      </div>
      <br />
      <CDBBtn className="athics-btn" block={false} disabled={false}>
        {t("PaymentInfo.pay_now")}
      </CDBBtn>
    </div>
  );

  const getTitle = useCallback(() => {
    if (paymentStep.value === 1) {
      return t("PaymentInfo.payment_information");
    } else {
      return t("PaymentInfo.select_payment_method");
    }
  }, [paymentStep]);

  // const [title] = useState(getTitle())

  const handleInfoSelected = (selected) => {
    setPaymentStep(selected);
  };

  const handleCountry = (val) => {};

  const handleCity = (val) => {};

  const handleZipCode = (val) => {};

  return (
    <>
      {/* <SingleNav /> */}
      <div className="plan-container">
        <CDBCard className="pb-3">
          <Step />
          <GeneralHeader
            title={getTitle()}
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </CDBCard>
        <div className="d-lg-flex d-block flex-row mt-30">
          <div id="plan-left-card">
            <div className="d-flex flex-column">
              <CDBCard>
                <CDBCardBody className="p-30">
                  <Select
                    placeholder={t("PaymentInfo.select")}
                    options={infoPayments}
                    defaultValue={paymentStep}
                    onChange={handleInfoSelected}
                  />
                </CDBCardBody>
              </CDBCard>

              <CDBCard className="mt-30">
                <CDBCardBody className="p-30">
                  {paymentStep.value === 1 ? (
                    <PaymentInfo />
                  ) : (
                    <PaymentMethod />
                  )}
                </CDBCardBody>
              </CDBCard>
            </div>
          </div>
          <div id="plan-right-card">
            <div className="d-flex flex-column">
              <CDBCard>
                <CDBCardBody className="p-30">
                  <div className="d-flex flex-column">
                    <div className="boxResumen">
                      <h3>{t("PaymentInfo.summary")}</h3>
                    </div>
                    <PaymentCard plan={plan} headerColor={"#7C4DFF"} />
                  </div>
                </CDBCardBody>
              </CDBCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
