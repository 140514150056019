import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

// Function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

//--- PUBLIC PANELS ---//
// ANONYMOUS
export const getAnonymousPanel = async (tracking_code) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report/${tracking_code}/public-messages/`
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error.message);
  }
};

export const createAnonymousMessage = async ({ tracking_code, content }) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/${tracking_code}/public-messages/`,
      { content }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_sending_data}`);
    console.error(error.message);
  }
};

// PUBLIC AGENT
export const getPublicExternalAgentPanel = async (tracking_code) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report/${tracking_code}/internal-messages/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error.message);
  }
};

export const createPublicInternalMessage = async ({
  tracking_code,
  content,
}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/${tracking_code}/internal-messages/`,
      { content },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_sending_data}`);
    console.error(error.message);
  }
};

// PRIVATE PANEL ///
export const getPrivateInternalAgentPanel = async (tracking_code) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report/${tracking_code}/private-messages/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error.message);
  }
};

export const createPrivateInternalMessage = async ({
  tracking_code,
  content,
  selectedContacts
}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/${tracking_code}/private-messages/`,
      { content, selectedContacts },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_sending_data}`);
    console.error(error.message);
  }
};
