import { enqueueSnackbar } from "notistack";
import { FolderCross } from "../../Icon";
import Icon from "../../Icon/Icon";
import { MCButton } from "../../MainComponents/Button/Button";
import "./NoContent.scss";

export const NoContent = ({
  text,
  useModal = false,
  textBtn = "Set text",
  content = <p>Set content</p>,
  stateContent = null,
}) => {
  return (
    <div className="no-content">
      <FolderCross className="icon-no-content" />
      <p>{text}</p>
      {useModal && (
        <>
          <MCButton
            label={textBtn}
            variant="primary"
            outline
            className="btn-no-content mt-2"
            onClick={() => {
              stateContent(true);
            }}
          />
          {content}
        </>
      )}
    </div>
  );
};
