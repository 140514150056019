import { useTranslation } from "react-i18next";
import "./NotFound.scss";

export const NotFound = () => {
  const [t] = useTranslation("statuscode");
  return (
    <div className="notfound-page">
      <h1>{t("not_found")}</h1>
    </div>
  );
};
