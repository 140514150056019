import { Accordion } from "react-bootstrap";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCCheckbox } from "../../../../MainComponents/Input/Input";
import { MCLabel } from "../../../../MainComponents/Label/Label";
import NotFound from "../../../../GlobalComponents/NotFound";
import { useTranslation } from "react-i18next";

export const Recursos = ({ resources, toggleModal }) => {
  const [t] = useTranslation("investigation");
  const accordion = [
    {
      header: t("resources.financial"), // Internationalize the header
      resources: resources?.financial,
    },
    {
      header: t("resources.human"), // Internationalize the header
      resources: resources?.human,
    },
    {
      header: t("resources.material"), // Internationalize the header
      resources: resources?.material,
    },
    {
      header: t("resources.access"), // Internationalize the header
      resources: resources?.access,
    },
  ];

  var have_one = [
    resources?.financial ? resources?.financial.length : 0,
    resources?.human ? resources?.human.length : 0,
    resources?.material ? resources?.material.length : 0,
    resources?.access ? resources?.access.length : 0,
  ];

  const total = have_one.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0); // 0 is the initial value of the accumulator

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded p-20">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div>
            <h4 className="m-0">{t("resources.resources_investigation")}</h4>
          </div>
          <MCButton
            label={t("resources.add_resource")}
            variant="primary"
            outline
            onClick={() => toggleModal("add-resource")}
          />
        </div>
      </div>

      <div className="dyTheme1 dyBorder1 rounded p-30 mt-20">
        {total >= 1 ? (
          <Accordion
            defaultActiveKey={accordion.map((item, idx) => `${idx}`)}
            alwaysOpen
          >
            {accordion.map((item, idx) => (
              <Accordion.Item key={idx} eventKey={`${idx + 1}`}>
                <Accordion.Header>{item.header}</Accordion.Header>
                {item?.resources &&
                  item.resources.map((item, idx2) => {
                    return (
                      <Accordion.Body key={idx2}>
                        <h6 className="font-14">{item.name}</h6>
                        <div className="research-text dyTheme3 dyBorder2 rounded">
                          <strong>{item.about}</strong>
                          <br />
                          {t("resources.facilitator")}: {item.details}
                          {/* {item.lists.length > 0 ? (
                            <div>
                              <small className="d-block mt-20">
                                Las tareas a realizar son
                              </small>
                              <ul>
                                {item.lists.map((list, idxdx) => {
                                  return <li key={idxdx}>{list.list}</li>;
                                })}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className="d-block d-md-flex align-items-center  mt-20">
                          <MCCheckbox
                            className="me-2 mt-0"
                            id={"checkbox" + idx + "_" + idx2}
                          />
                          <MCLabel
                            className="m-0"
                            text={t("resources.approve")}
                            htmlFor={"checkbox" + idx + "_" + idx2}
                          />
                          <h6>{item.amount}</h6>
                        </div>
                      </Accordion.Body>
                    );
                  })}
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
