import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

// Exports
export const listExports = async () => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;

  try {
    const resp = await apiCall.get(`${tenant}/api/export/list/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const createExcelExport = async (data, lg) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;

  const params = {};

  if (lg) {
    params.lg = lg;
  }

  try {
    const resp = await apiCall.post(`${tenant}/api/export/excel/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
      params,
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_creating_report_file}`);
    console.error(error);
  }
};

export const downloadFile = async (alias) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;

  try {
    const resp = await apiCall.get(`${tenant}/api/export/${alias}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_download_file}`);
    console.error(error);
  }
};

export const deleteFile = async (alias) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;

  try {
    const resp = await apiCall.delete(`${tenant}/api/export/delete/${alias}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_download_file}`);
    console.error(error);
  }
};

// Report Templates

export const utilitiesReportTemplates = async (formId) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report-templates/utilities/${formId}/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const listReportTemplates = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/report-templates/list/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const createReportTemplate = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report-templates/create/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_create_report_template}`);
    console.error(error);
  }
};

export const retrieveReportTemplate = async (reportTemplateAlias) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report-templates/retrieve/${reportTemplateAlias}/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

// Hacer una peticion para obtener el reporte en pdf
export const downloadPDFReport = async (reportId, data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/pdf/${reportId}/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
        responseType: "blob",
      }
    );
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_pdf_file}`);
    console.error(error);
  }
};
