import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormCheck } from "react-bootstrap";
import { CDBProgress } from "cdbreact";
import { ProfileIcon, OutlineExport2, CheckIconCircle, BoldMessageText2 } from "../Icon";
import { enableMFA, resetMyPasswordConfirm, verifyMFA } from "../../apis/apiUsers";
import { startLoggedOut } from "../../store/auth/authThunks";
import AppStore from "../../assets/images/app-store.png";
import GooglePlay from "../../assets/images/google-play.png";
import { validarContrasenia } from "../Users/User";
import { MCInput } from "../MainComponents/Input/Input";
import { MCButton } from "../MainComponents/Button/Button";
import { MCLabel } from "../MainComponents";
import "./Profile.scss";
import { enqueueSnackbar } from "notistack";

const returnProfileProgress = (variables = []) => {

  const numVariables = variables.length;
  const newVariables = variables.map(item=>!!item);

  const trueCount = newVariables.filter(Boolean).length;

  const truePercentage = (trueCount / numVariables) * 100;

  return truePercentage;
}

const ResetPassword = () => {

  const email = JSON.parse(localStorage.getItem("Session"))?.email;

  const [t] = useTranslation("users");
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [wantReset, setWantReset] = useState(false);

  async function handleResetPassword(e) {
    e.preventDefault();

    try {
      if (password.trim() === "" || confirmPassword.trim() === "") {
        enqueueSnackbar(t("User.password_must_not_empty"), {
          variant: "error",
        });
        return;
      }
      if (password !== confirmPassword) {
        enqueueSnackbar(t("User.password_no_match"), {
          variant: "error",
        });
        return;
      }
      if (!validarContrasenia(password)) {
        enqueueSnackbar(t("User.password_must_contain"), {
          variant: "error",
        });
        return;
      }

      const resp = await resetMyPasswordConfirm(email, password, confirmPassword);
      enqueueSnackbar(t("User.password_changed"), {
        variant: "success",
      });
      handleHideInputs();
    } catch (error) {
      enqueueSnackbar(t("User.error_trying_reset_password"), {
        variant: "error",
      });
    }
  }

  function handleHideInputs(params) {
    setPassword("");
    setConfirmPassword("");
    setWantReset(false);
  }


  // Retorna un boton antes de querer reiniciar la password
  if (!wantReset) {
    return (
      <MCButton variant="primary" label="Cambiar contraseña" className="w-100" onClick={()=>setWantReset(true)} />
    )
  }

  // Comoponente para reiniciar la password
  return (
    <form className="edit-profile-reset-password" onSubmit={handleResetPassword}>
      
      <div>
        <MCLabel text="Nueva contraseña" className="mb-1" />
        <MCInput type="password" value={password} className="mb-2" onChange={(e)=>setPassword(e.target.value)} />
      </div>

      <div>
        <MCLabel text="Confirmar contraseña contraseña" className="mb-1" />
        <MCInput type="password" value={confirmPassword} className="mb-2" onChange={(e)=>setConfirmPassword(e.target.value)} />
      </div>

      <div className="d-flex justify-content-end">
        <MCButton label="Cancelar" variant="primary" outline onClick={handleHideInputs} />
        <MCButton label="Guardar" variant="primary" className="ms-2" type="submit" onClick={handleResetPassword} />
      </div>

    </form>
  )
}


export const EditarPerfil = () => {

  const { mfa } = useSelector((state) => state.auth);

  const [t] = useTranslation("perfil");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [configureAccount] = useState(true);
  const [mfaSetted, setMfaSetted] = useState(mfa);
  const [mfaCheck, setMfaCheck] = useState(false);
  const [code, setCode] = useState("");
  const [qrimage, setQrImage] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSecureChecks = async (e) => {
    const value = e.target.checked;
    try {
      if(value){
        const resp = await enableMFA({});
        setQrImage(resp["qr_code"]); 
      }
      setMfaCheck(value);
    } catch (error) {
      console.log(error);
    }

  };

  const mfaCode = (e) => { 
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ''); // Filtra solo números
    const limitedValue = numericValue.slice(0, 6); // Limita a 6 caracteres
    setCode(limitedValue);
  }

  const handleSubmit = async (evt) => {

    if (code.length !== 6) {
      setIsInvalid(true);
      setTimeout(() => setIsInvalid(false), 500); // Remueve el estado inválido después de 500ms
    } else {
      // Lógica para activar si el código es válido
      console.log('Código válido:', code);

      const otp_code = code;

      const resp = await verifyMFA(otp_code);

      if(resp["message"] == "MFA enabled successfully"){
        dispatch(startLoggedOut());
        navigate("/login");
      }
    }

  }

  return (
    <div className="d-block d-lg-flex">

      <div className="profile-first-block">

        {/* <div className="p-30 dyTheme1 dyBorder1 rounded">
          <div className="d-flex flex-column roundBorder cardNoBorder">
            <h5 className="mb-20 text-left">
              {t("editarPerfil.personal_info")}
            </h5>
            <div className="d-flex flex-row text-center">
              <a href="/">
                <ProfileIcon size="80px" className="edit-profile-img" />
              </a>
              <div className="d-flex flex-column text-left">
                <span className="Selecciona-un-avatar mb-3 mt-2">
                  {t("editarPerfil.select_avatar_or_photo")}
                </span>
                <div>
                  <button className="btn btnUpload align-items-center text-center d-inline-block">
                    <span className="Sube-tu-foto">
                      {t("editarPerfil.upload_photo")}
                    </span>
                    <OutlineExport2 size="14px" />
                  </button>
                  <button className="btn btnUpload align-items-center text-center d-inline-block">
                    <span className="Escoge-tu-avatar">
                      {t("editarPerfil.select_avatar")}
                    </span>
                    <ProfileIcon size="14px" />
                  </button>
                </div>
              </div>
            </div>
            <div className="d-block d-md-flex flex-row">
              <div className="form-group mb-30 w-100 mr-5">
                <label className="mb-2">{`${t(
                  "editarPerfil.name"
                )}(s): *`}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("editarPerfil.enter_name")}
                  aria-label="Nombres"
                />
              </div>
              <div className="form-group mb-30 w-100">
                <label className="mb-2">{`${t(
                  "editarPerfil.lastname"
                )}: *`}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("editarPerfil.enter_lastname")}
                  aria-label="Apellidos"
                />
              </div>
            </div>
            <div className="d-block d-md-flex flex-row">
              <div className="form-group w-100 mr-5">
                <label className="mb-2">{`${t(
                  "editarPerfil.job_position"
                )}: *`}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("editarPerfil.enter_job_position")}
                  aria-label="Puesto de trabajo"
                ></input>
              </div>
              <div className="form-group w-100">
                <label className="mb-2">{`${t(
                  "editarPerfil.country"
                )}: *`}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("editarPerfil.enter_country")}
                  aria-label="País"
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
          <div className="d-flex flex-column roundBorder">
            <h5 className="mb-30">{t("editarPerfil.password")}</h5>
            <div className="d-block d-md-flex flex-row">
              <div className="form-group mb-30 w-100 mr-5">
                <label className="mb-2">Email: *</label>
                <input
                  className="form-control"
                  type="email"
                  placeholder={t("editarPerfil.enter_email")}
                  aria-label="Email"
                ></input>
              </div>
              <div className="form-group mb-30 w-100">
                <label className="mb-2">{`${t(
                  "editarPerfil.current_password"
                )}: *`}</label>
                <input
                  className="form-control"
                  type="password"
                  placeholder="**********"
                  aria-label="ContraseñaActual"
                ></input>
              </div>
            </div>
            <small>
              Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis
              bibendum felis sit amet fringilla.
            </small>
            <CDBBtn className="btn small-disable-btn w-180 mt-20" outline>
              {t("editarPerfil.change_password")}
            </CDBBtn>
          </div>
        </div>
        <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
          <div className="d-flex flex-column roundBorder">
            <h5 className="mb-30">{t("editarPerfil.my_permissions")}</h5>
            <small>{`${t("editarPerfil.you_have")} (?) ${t(
              "editarPerfil.permissions"
            )}`}</small>
            <CDBBtn className="small-primary-outline-btn w-180 mt-20" outline>
              {t("editarPerfil.view_permissions")}
            </CDBBtn>
          </div>
        </div> */}

        <div className="p-30 mt-4 dyTheme1 dyBorder1" >

          <div className="d-flex flex-column roundBorder">
            <div className="d-block d-md-flex flex-row justify-content-between">
              <h5 className="mb-30">
                <b>{t("seguridad.two-factor_authentication")}</b>
              </h5>
              
            </div>
            <small>{t("seguridad.two-factor_authentication_adds")}</small>
            <small className="mt-20">
              {t("seguridad.to_use_two-factor_authentication")}
            </small>


            <h6 className="mt-20 mb-0 font-14">Authy</h6>
            <div className="d-flex flex-row mb-30">
              <a href="https://apps.apple.com/es/app/authy/id494168017" target="_blank">
                <img
                  src={AppStore}
                  alt="App Store"
                  className="mr-30 mt-30 social-img"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank">
                <img
                  src={GooglePlay}
                  alt="Google Play"
                  className="mt-30 social-img"
                />
              </a>
            </div>

            {/* Cuando mfa ya esta configurado no se muestra el check para activarlo */}
            {
              !!!mfa &&
              <>
                <small>{t("seguridad.two-factor-enable")}</small>
                <FormCheck
                  id="improcedent"
                  type="switch"
                  checked={mfaCheck}
                  onChange={handleSecureChecks}
                />
              </>
            }

            {/* Cuando mfa ya esta configurado no se muestra el check para activarlo */}
            {
              !!mfaSetted &&
              <MCButton 
                label="Volver a configurar la autenticacion de doble factor" 
                variant="primary" 
                outline 
                onClick={async()=>{
                  const resp = await enableMFA({});
                  setQrImage(resp["qr_code"]);
                  setMfaSetted(null);
                  setMfaCheck(true);
                }}
              />
            }

            {/* Cuando mfa ya esta configurado no se muestra el check para activarlo */}
            { !!!mfaSetted && mfaCheck && 
              <div className="mfa-instructions">
                
                <p>
                  <strong>{t("seguridad.two-factor-instruction-scan")}</strong> <br /> 
                  <img src={`data:image/png;base64, ${qrimage}`} alt="Red dot" />
                </p>

                <p>
                  <strong>{t("seguridad.two-factor-instruction-code")}</strong> <br /> 
                  <MCInput 
                    type="text" 
                    value={code} 
                    onChange={mfaCode} 
                    className={`centered-input ${isInvalid ? 'input-invalid' : ''}`} 

                  />
                  <MCButton
                    label={t("seguridad.two-factor-enable-btn")}
                    variant="primary"
                    outline
                    onClick={handleSubmit}
                  />
                </p>

              </div>
            }

          </div>
        </div>

        <div className="p-30 mt-4 dyTheme1 dyBorder1" >
          <ResetPassword />
        </div>


      </div>


      <div className="profile-second-block">

        {/* <div className="d-flex flex-column">
          <div
            style={{
              ...DynamicTheme,
              borderLeft: `8px solid #7C4DFF`,
              borderBottomLeftRadius: 5,
              borderTopLeftRadius: 5,
              padding: 20,
            }}
          >
            <div className="d-sm-flex align-items-center justify-content-between text-left">
              <div>
                <small className="mb-2">{`${t(
                  "editarPerfil.current_plan"
                )}: `}</small>
                <h5>{`${t("editarPerfil.free_trial")} - 30 ${t(
                  "editarPerfil.days"
                )}`}</h5>
              </div>
              <button className="btn btnBlankBG btnTrialColor text-center w-150">
                {t("editarPerfil.view_details")}
              </button>
            </div>
          </div>
        </div> */}

        <div className="d-flex flex-column px-20 pt-30  dyTheme1 dyBorder1 rounded">
          <div className="mb-3">
            <div className="d-flex align-items-center pb-20 border-bottom">
              <h5 className="m-0 w-100">
                {t("editarPerfil.complete_profile")}
              </h5>
              <div className="w-100">
                <CDBProgress value={returnProfileProgress([ configureAccount, mfa ])} height={6} />
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center mt-20 check-icons active">
                <CheckIconCircle size={"24px"} />
                <h6 className="ms-2 mb-0">
                  {t("editarPerfil.configure_account")}
                </h6>
              </div>
              <div className={`d-flex align-items-center mt-20 check-icons ${mfa ? "active" : ""}`}>
                <CheckIconCircle size={"24px"} />
                <h6 className="ms-2 mb-0">
                  {t("editarPerfil.configure_mfa")}
                </h6>
              </div>
              {/* <div className="d-flex align-items-center mt-20 check-icons">
                <CheckIconCircle size={"24px"} />
                <h6 className="ml-2 mb-0">
                  {t("editarPerfil.setup_company")}
                </h6>
              </div>
              <div className="d-flex align-items-center mt-20 check-icons">
                <CheckIconCircle size={"24px"} />
                <h6 className="ml-2 mb-0">
                  {t("editarPerfil.setup_employees")}
                </h6>
              </div>
              <div className="d-flex align-items-center mt-20 check-icons">
                <CheckIconCircle size={"24px"} />
                <h6 className="ml-2 mb-0">
                  {`${t("editarPerfil.install_app")} (iOS / Android)`}
                </h6>
              </div> */}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-start mt-20 p-20 dyTheme3 dyBorder2 rounded">
          <BoldMessageText2 size={"24px"} className="bold-message" />
          <div className="ml-2">
            <small>
              <strong>{t("editarPerfil.send_suggestions")}</strong>
            </small>
            <small className="mt-2">
              {t("editarPerfil.send_us_suggestions")}
            </small>
          </div>
        </div>
      </div>


    </div>
  );
};
