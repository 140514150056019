import { useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Profile from "../../../assets/images/profile1.png";
import { useSelector } from "react-redux";

import { MCButton } from "../../MainComponents/Button/Button";
import "./styles.scss";

const Greetings = () => {
  const { username } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [t] = useTranslation("dashboard");
  const [showNoti, setShowNoti] = useState(true);
  
  return (
    <div id="greetings" className="dyTheme1 dyBorder1 rounded mt-20 mx-3 mb-1">
      <Row>
        <Col xs="6">
          <div className="d-flex align-items-center align-items-md-start">
            <img src={Profile} alt="profile" />
            <div className="mx-2">
              <h3>
                {t("greetings.hi")}, {username}!
              </h3>
            </div>
          </div>
        </Col>
        <Col
          xs="6"
          id="noti-block"
          className="d-flex align-items-center align-items-md-start"
        >
          <MCButton
            label={t("greetings.review")}
            variant="primary"
            outline
            onClick={() => navigate("/reports/all")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Greetings;
