import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  detailBusinessUnit,
  getLanding,
  getSupportMaterial,
  updateLandingInfo,
} from "../../../apis";
import { enqueueSnackbar } from "notistack";
import "./LandingPage.scss";

export const useLandingPageSettings = () => {
  // ---> Cutom Hooks <--- //
  const { businessUnit } = useParams();
  const [t] = useTranslation("settings");
  // -----> States <----- //
  // ----> Campaing Name <---- //
  const [campaing_name, setCampaing_name] = useState(t("landing_page.listens"));
  const [errorCampaingName, setErrorCampaingName] = useState(false);
  // ----> Content <---- //
  const [content, setContent] = useState({
    form_msg: t("landing_page.form_msg"),
    questions: [
      {
        text: t("landing_page.text1"),
        question: t("landing_page.question1"),
      },
      {
        text: t("landing_page.text2"),
        question: t("landing_page.question2"),
      },
      {
        text: t("landing_page.text3"),
        question: t("landing_page.question3"),
      },
      {
        text: t("landing_page.text4"),
        question: t("landing_page.question4"),
      },
      {
        text: t("landing_page.text5"),
        question: t("landing_page.question5"),
      },
      {
        text: t("landing_page.text6"),
        question: t("landing_page.question6"),
      },
    ],
    track_btn: t("landing_page.complaint_tracking"),
    form_title: t("landing_page.agents_of_change"),
    welcome_msg: t("landing_page.welcome_msg"),
    form_success: t("landing_page.form_success"),
    how_function: {
      follow_up: {
        text: t("landing_page.follow_up_text"),
        question: t("landing_page.follow_up_question"),
      },
      what_it_is: {
        text: t("landing_page.what_it_is_text"),
        question: t("landing_page.what_it_is_question"),
      },
      generate_report: {
        text: t("landing_page.generate_report_text"),
        question: t("landing_page.generate_report_question"),
      },
      procedure_report: {
        text: t("landing_page.procedure_report_text"),
        question: t("landing_page.procedure_report_question"),
      },
    },
    form_tracking: t("landing_page.form_tracking"),
    grievance_btn: t("landing_page.report_now"),
    attachment_btn: t("landing_page.attachment_btn"),
    tracking_grievance_msg: t("landing_page.tracking_grievance_msg"),
    tracking_options_title: t("landing_page.tracking_options_title"),
    tracking_grievance_title: t("landing_page.tracking_grievance_title"),
  });
  const initialErrorContent = {
    form_msg: false,
    track_btn: false,
    form_title: false,
    welcome_msg: false,
    form_success: false,
    how_function: {
      follow_up: {
        text: false,
        question: false,
      },
      what_it_is: {
        text: false,
        question: false,
      },
      generate_report: {
        text: false,
        question: false,
      },
      procedure_report: {
        text: false,
        question: false,
      },
    },
    form_tracking: false,
    grievance_btn: false,
    attachment_btn: false,
    tracking_grievance_msg: false,
    tracking_options_title: false,
    tracking_grievance_title: false,
  };
  const [errorContent, setErrorContent] = useState(initialErrorContent);
  // ----> Question to Add <---- //
  const [questionToAdd, setQuestionToAdd] = useState({
    question: "",
    text: "",
  });
  const [editQuestion, setEditQuestion] = useState(null); //index to edit question
  const [editAvailable, setEditAvailable] = useState(false);
  const [errorQuestions, setErrorQuesions] = useState({
    text: false,
    question: false,
  });
  // ----> Colors <---- //
  const [colors, setColors] = useState({
    primary_color: "#009ed7",
    secondary_color: "#546e7a",
    track_btn_color: "#f5a623",
    primary_text_color: "#ffffff",
    grievance_btn_color: "#f5a888",
    secondary_text_color: "#ffffff",
    track_btn_hover_color: "#f5a623",
  });
  //Hovered Effect for Button Examples
  const [ethicsCodeHovered, setEthicsCodeHovered] = useState(false);
  const [trackingHovered, setTrackingHovered] = useState(false);
  //Channels
  const [channels, setChannels] = useState({
    app_mobile: {
      is_active: false,
      text: "",
    },
    chat: {
      is_active: false,
      text: "",
    },
    chat_app: {
      is_active: false,
      text: "",
    },
    email: {
      is_active: false,
      email: "",
    },
    phone_app: false,
    phones: {
      is_active: false,
      list_phones: [],
    },
    website: true,
    whatsapp: {
      is_active: false,
      phone_number: "",
    },
  });
  const [errorChannels, setErrorChannels] = useState({
    app_mobile: false,
    chat: false,
    chat_app: false,
    email: false,
    phones: false,
    whatsapp: false,
  });
  //Previous support files updated
  const [files, setFiles] = useState([]);
  // Call enpodint to set the stage
  const mount = async () => {
    try {
      //General data from Configuration of BusinessUnit
      const { data } = await detailBusinessUnit(businessUnit);
      //Data that makes up the construction of the Landing Page of each Business Unit
      const { landing } = await getLanding(data.end_point);
      const { files } = await getSupportMaterial(data.end_point);
      //Destructure previous data on landing page to set initial states
      const {
        campaing_name,
        channels,
        colors,
        content,
        default_lenguage,
        multilenguage,
      } = landing;
      setFiles(files);
      setContent(content);
      setColors(colors);
      setCampaing_name(campaing_name);
      setChannels(channels);
    } catch (error) {
      if (error.message === "Landing not found") {
        enqueueSnackbar(t("landing_page.page_not_configured"), {
          variant: "error",
          // anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
    }
  };
  //Call function mount() to get previous registered data
  useEffect(() => {
    mount();
  }, []);
  //----------> Form Sections <----------//
  // -----> Campaing Name <----- //
  const onChangeCampaingName = (e) => {
    const { value } = e.target;
    setCampaing_name(value);
    setErrorCampaingName(value.trim() === "");
  };
  // -----> Content & How Function <----- //
  // OnChange Content
  const handleContentChange = (e) => {
    const { name, value } = e.target;
    //for how_function section
    if (name.includes("how_function.")) {
      //Nested element is desestructured and it's set in the how_function object inside Content
      const [objeto, opcion, propiedad] = name.split(".");
      //set values
      setContent((prevContent) => ({
        ...prevContent,
        [objeto]: {
          ...prevContent[objeto],
          [opcion]: {
            ...prevContent[objeto][opcion],
            [propiedad]: value,
          },
        },
      }));
      // Validate and set errors
      setErrorContent((prevErrors) => ({
        ...prevErrors,
        [objeto]: {
          ...prevErrors[objeto],
          [opcion]: {
            ...prevErrors[objeto][opcion],
            [propiedad]: value.trim() === "",
          },
        },
      }));
    } else {
      // Set values
      setContent((prevContent) => ({ ...prevContent, [name]: value }));
      // Set errors
      setErrorContent((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };
  // Dynamic render error message in 'Content'
  const renderErrorContent = (field, key) => {
    if (
      field &&
      errorContent.how_function[field] &&
      errorContent.how_function[field][key]
    ) {
      return errorContent.how_function[field][key] ? (
        <p className="error">{t("landing_page.field_required")}</p>
      ) : null;
    } else if (errorContent[field]) {
      return errorContent[field] ? (
        <p className="error">{t("landing_page.field_required")}</p>
      ) : null;
    }
  };
  // -----> Questions <--- //
  // ----> Add a new question <----- //
  const handleAddQuestion = () => {
    setContent((prevContent) => ({
      ...prevContent,
      questions: [
        ...prevContent.questions,
        { question: questionToAdd.question, text: questionToAdd.text },
      ],
    }));
    // State Clean
    setQuestionToAdd({ question: "", text: "" });
  };
  // onClickEvent to delete a question registered
  const handleDeleteQuestion = (index) => {
    setContent((prevContent) => ({
      ...prevContent,
      questions: prevContent.questions.filter((_, i) => i !== index),
    }));
  };
  //Update Question and validate not empty
  const updateQuestion = (index, field, value) => {
    const newQuestion = {
      ...content.questions[index],
      [field]: value,
    };
    setErrorQuesions((prevErrors) => ({
      ...prevErrors,
      [field]: value.trim() === "",
    }));
    if (!errorQuestions.question && !errorQuestions.text) {
      setEditAvailable(true);
    } else {
      setEditAvailable(false);
    }
    //Set State
    setContent((prevContent) => ({
      ...prevContent,
      questions: prevContent.questions.map((item, i) =>
        i === index ? newQuestion : item
      ),
    }));
  };
  //Edit question
  const handleEditQuestion = (index) => {
    setEditQuestion(index);
  };
  // --- Colors --- //
  const handleColorChange = (e) => {
    const { name, value } = e.target;
    setColors((prevColors) => ({
      ...prevColors,
      [name]: value,
    }));
  };

  // --- Example Button Styles for Landing Page ---//
  const ethicsCodeButton = {
    backgroundColor: ethicsCodeHovered
      ? `${colors.grievance_btn_color}`
      : "white",
    color: ethicsCodeHovered ? "white" : `${colors.grievance_btn_color}`,
    border: `solid 1px ${colors.grievance_btn_color}`,
    boxShadow: ethicsCodeHovered
      ? `inset 0 0 0 rgba(26,71,146,0.5), 0 0 1.5em rgba(26,71,146,0.57)`
      : "",
  };

  const grievanceButton = {
    backgroundColor: `${colors.grievance_btn_color}`,
    color: "white",
    border: `solid 1px${colors.grievance_btn_color}`,
  };

  const trackingButton = {
    backgroundColor: trackingHovered
      ? `${colors.track_btn_color}`
      : `${colors.track_btn_hover_color}`,
    color: trackingHovered
      ? `${colors.secondary_text_color}`
      : `${colors.primary_text_color}`,
    border: "transparent",
    boxShadow: "0 0 5px -1px gray",
  };

  const mainColorText = {
    backgroundColor: `${colors.primary_color}`,
    color: `${colors.primary_text_color}`,
  };
  const secondaryColorText = {
    backgroundColor: `${colors.secondary_color}`,
    color: `${colors.secondary_text_color}`,
  };

  // --- Channels --- //
  const handleChannelsValue = (e) => {
    const { name, value } = e.target;
    //el manejo de errores comprobará que el switch esté en true y el campo correspondiente no esté vacío
    // comprobar que cada error corresponda a cada campo de acuerdo al caso correspondiente
    if (name === "chat" || name === "chat_app" || name === "app_mobile") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [name]: {
          ...prevChannels[name],
          text: value,
        },
      }));
      setErrorChannels((prevErrors) => ({
        ...prevErrors,
        [name]: channels[name].is_active && value.trim() === "",
      }));
    }
    if (name === "email") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [name]: {
          ...prevChannels[name],
          email: value,
        },
      }));
      //`
      const regexMail = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/;
      setErrorChannels((prevErrors) => ({
        ...prevErrors,
        [name]: !regexMail.test(value),
      }));
    }
    if (name === "phones") {
      const newPhoneNumber = value?.split(",").map((number) => number.trim());
      setChannels((prevChannels) => ({
        ...prevChannels,
        [name]: {
          ...prevChannels[name],
          list_phones: newPhoneNumber,
        },
      }));
      setErrorChannels((prevErrors) => ({
        ...prevErrors,
        [name]: !value,
      }));
    }
    if (name === "whatsapp") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [name]: {
          ...prevChannels[name],
          phone_number: value,
        },
      }));
      setErrorChannels((prevErrors) => ({
        ...prevErrors,
        [name]: !value,
      }));
    }
  };

  const switchBoolean = (key) => (e) => {
    const { checked } = e.target;
    setChannels((prevChannels) => ({
      ...prevChannels,
      [key]: checked,
    }));
  };

  const handleSwitchChange = (key) => (e) => {
    const { checked } = e.target;
    if (key === "chat" || key === "chat_app" || key === "app_mobile") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [key]: {
          ...prevChannels[key],
          is_active: checked,
          text: "",
        },
      }));
    }
    if (key === "whatsapp") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [key]: {
          ...prevChannels[key],
          is_active: checked,
          phone_number: "",
        },
      }));
    }
    if (key === "phones") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [key]: {
          ...prevChannels[key],
          is_active: checked,
          list_phones: [],
        },
      }));
    }
    if (key === "email") {
      setChannels((prevChannels) => ({
        ...prevChannels,
        [key]: {
          ...prevChannels[key],
          is_active: checked,
          email: "",
        },
      }));
    }
    setErrorChannels((prevErrors) => ({
      ...prevErrors,
      [key]: checked,
    }));
  };

  const createPayload = () => {
    let payload = {};
    for (const key in channels) {
      if (typeof channels[key] === "object" && channels[key].is_active) {
        payload[key] = {
          is_active: true,
          ...channels[key],
        };
      } else if (typeof channels[key] === "boolean") {
        payload[key] = channels[key];
      } else {
        payload[key] = {
          is_active: false,
        };
      }
    }
    return payload;
  };
  const payload = createPayload();
  // array Phones
  const phoneNumberStrings = channels?.phones?.list_phones?.join(", ");

  // ----> Validate & Submit Landing Page <---- //
  //validate content before send data at endpoint
  const validateContent = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        const hasError = validateContent(obj[key]);
        if (hasError) {
          return true;
        }
      } else if (obj[key] === true) {
        return true;
      }
    }
    return false;
  };

  const handleSaveLanding = async (e) => {
    e.preventDefault();
    const hasContentError = validateContent(errorContent);
    const hasChannelsError = validateContent(errorChannels);

    const objToSave = {
      business_unit_key: businessUnit,
      campaing_name,
      channels: payload,
      content,
      colors,
    };

    if (hasContentError || errorCampaingName || hasChannelsError) {
      enqueueSnackbar(`${t("landing_page.fill_required_fields")}`, {
        variant: "error",
      });
    } else {
      try {
        const resp = await updateLandingInfo(objToSave).then((resp) => {
          mount();
          enqueueSnackbar(
            `${t("landing_page.customization_data_successfully_updated")}`,
            { variant: "success" }
          );
        });
      } catch (error) {
        enqueueSnackbar(`${error.message}`, { variant: "error" });
      }
    }
  };

  return {
    //building the stage
    mount,
    //campaing_name
    campaing_name,
    errorCampaingName,
    onChangeCampaingName,
    //content
    content,
    handleContentChange,
    renderErrorContent,
    //colors
    colors,
    mainColorText,
    secondaryColorText,
    handleColorChange,
    //questions
    questionToAdd,
    editQuestion,
    errorQuestions,
    handleAddQuestion,
    handleDeleteQuestion,
    handleEditQuestion,
    setQuestionToAdd,
    setEditQuestion,
    updateQuestion,
    //channels
    channels,
    errorChannels,
    phoneNumberStrings,
    switchBoolean, //for boolean channels
    handleSwitchChange,
    handleChannelsValue,
    //button example styles
    ethicsCodeButton,
    grievanceButton,
    trackingButton,
    setTrackingHovered,
    setEthicsCodeHovered,
    //submit
    handleSaveLanding,
    //files
    files,
  };
};
