import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { parseDateToDDMMYYYY2 } from "../../../../helpers/dateTransform";
import { MCInput } from "../../../MainComponents";
import { MCTooltip } from "../../../MainComponents";
import { formStarter } from "../../../JSONForms/PreviewForm/formStarter";
import { Loading } from "../../../Loading/String/Loading";
import { PreviewForm } from "../../../JSONForms/PreviewForm/PreviewForm";
import { useTranslation } from "react-i18next";
import { Complements } from "./Complements";
import { MCAccordion } from "../../../MainComponents/Accordion/MCAccordion/Accordion";
import { useSnackbar } from "notistack";
import { BasicSelect } from "../../../MainComponents/Select/BasicSelect";
import { EGIconButton } from "../../../MainComponents/Button/IconButton/IconButton";
import { EGSwitch } from "../../../MainComponents/Switch/Switch";
import { EGSpeedDial } from "../../../MainComponents/Button/SpeedDial/SpeedDial";
import { InvestigationSummary } from "./InvestigationSummary";
import {
  Fab,
  Link,
  SpeedDial,
  SpeedDialAction,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  changeReportSecure,
  changeSeverity,
  getFormDetails,
  getRelated,
  createRelated,
  changeRelated,
  deleteRelated,
  changeCompromisedAnonymity,
  changeHostileEnvironment,
  updateRangeDates,
} from "../../../../apis";
import {
  OutlineEdit,
  CancelIconSquare,
  AddIcon,
  TrashCan,
  CheckIcon,
  CloseIcon,
  BoldFlash2,
  Timer2Bold,
  BoldLockSlash,
  OutlineUnlock,
  BoldUserRemove,
  BoldRadar,
} from "../../../Icon";
import ConfirmationModal from "./ConfirmationModal";
import Comments from "./Comments";
import Icon from "../../../Icon/Icon";
import EGDatePicker from "../../../MainComponents/DatePicker/EGDatePicker";
import useUnsavedChangesWarning from "../../../../hooks/useUnsavedChangesWarning";
import { RelatedReportItem } from "./ReportRelatedItem";
import dayjs from "dayjs";
import "./Information.scss";

// -----> Tab Report Information <-----//
export const Information = ({
  reportDetail,
  investigationList,
  severityCat,
  improperCat,
  lockedCat,
  responseReport,
  id,
  isLoading,
  setIsLoading,
  setShowModalAttachments,
}) => {
  const [t] = useTranslation("information");
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  ////////// General Report //////////
  const [edit, setEdit] = useState(false);

  // General Report Editing
  const [steps, setSteps] = useState(null);
  const [schemaState, setSchemaState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [constraints, setConstraints] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  ////////// Editing Column Indicators //////////
  const [editing, setEditing] = useState({
    severity: false,
    locked: false,
    compromised: false,
    hostile: false,
    schedule: false,
  });
  useUnsavedChangesWarning(editing);

  ////////// Loading /////////
  const [loading, setLoading] = useState({
    severity: false,
    locked: false,
    compromised: false,
    hostile: false,
    schedule: false,
  });
  ////////// Switch Value //////////
  const [switches, setSwitches] = useState({
    locked: reportDetail?.locked?.is_locked ?? false,
    compromised:
      reportDetail?.compromised_anonymity?.compromised_anonymity_value ?? false,
    hostile: reportDetail?.hostile_place?.is_hostile_place_value ?? false,
  });
  ////////// Sections + Add Details //////////
  const [details, setDetails] = useState({
    schedule: reportDetail?.custom_dates?.comment || false,
    severity: reportDetail?.severity?.comment || false,
    locked: reportDetail?.locked?.message || false,
  });
  ////////// Completion Deadline //////////
  // Schedule dates
  const [conclusionDeadline, setConclusionDeadline] = useState(null);
  // Range dates comment
  const [deadLineDetails, setDeadLineDetails] = useState("");
  ////////// Severity Level //////////
  // Severity level options
  const [severityCatTransformed, setSeverityCatTransformed] = useState([]);
  // Severity level selected
  const [severity, setSeverity] = useState("");
  // Comment on selected severity level
  const [severityDetails, setSeverityDetails] = useState("");
  ////////// Report Blocked //////////
  // Report lock options
  const [blockedCategory, setBlockedCategory] = useState([]);
  // Selected lock option
  const [blockSelected, setBlockSelected] = useState("");
  // Locked report observations
  const [blockedDetails, setBlockedDetails] = useState("");
  ////////// Anonymity Compromised //////////
  // Observations on anonymity compromised
  const [textCompromised, setTextCompromised] = useState("");
  ////////// Hostil Enviroment //////////
  // Hostile environment observations
  const [textHostile, setTextHostile] = useState("");
  ////////// Report Related //////////
  const [editRelated, setEditRelated] = useState(false);
  const [uploadRelated, setUploadRelated] = useState(false);
  // Reports related list
  const [relatedList, setRelatedList] = useState(reportDetail?.related || []);
  // Report related folio
  const [relatedFolio, setRelatedFolio] = useState("");
  // Report related comment
  const [relatedComment, setRelatedComment] = useState("");
  ////////// Modal Configuration //////////
  // Modal confirm action
  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    confirmAction: () => {},
    modalTitle: "",
    description1: "",
    description2: "",
    modalQuestion: "",
  });
  ////////// Details //////////
  // Report comment shortened text
  const [expandedRelatedComments, setExpandedRelatedComments] = useState([]);
  ////////// For animation purposes //////////
  const [getOut, setGetOut] = useState({
    locked: false,
    compromised: false,
    hostile: false,
  });

  useEffect(() => {
    // if (severityCat?.data) {
    const optionsList = [
      { value: "type::null", label: t("information.none") },
      { value: "type::low", label: t("information.low") },
      { value: "type::medium", label: t("information.medium") },
      { value: "type::hight", label: t("information.hight") },
      { value: "type::other", label: t("information.other") },
    ];
    setSeverityCatTransformed(optionsList);
    // }
    setSeverity(reportDetail?.severity?.path.toLowerCase() || "");

    if (lockedCat?.data) {
      const optionsList = lockedCat.data.map((option) => ({
        value: option.path_locales,
        label: option.label,
      }));
      setBlockedCategory(optionsList);
    }
  }, [improperCat, lockedCat]);

  ////////// Get Related Report List //////////
  useEffect(() => {
    setRelatedList(reportDetail?.related);
  }, [reportDetail]);

  const initializeForm = () => {
    setIsLoadingForm(true);
    getFormDetails(reportDetail?.form_id)
      .then((formLoaded) => {
        formStarter(formLoaded, "answered", reportDetail).then(
          ({
            initSteps,
            initSchemaState,
            initFormData,
            initIsValid,
            initConstraints,
          }) => {
            setSteps(initSteps);
            setSchemaState(initSchemaState);
            setFormData(initFormData);
            setIsValid(initIsValid);
            setConstraints(initConstraints);
            setIsLoadingForm(false);
          }
        );
      })
      .catch(console.error);
  };
  ////////// General edit report //////////
  const handleEditChange = (newEditValue) => {
    setEdit(newEditValue);
    if (newEditValue) {
      initializeForm();
    }
  };

  ////////// Generalization of states, endpoints and other indicator management tools //////////
  const indicatorsMap = {
    // Completion deadline
    schedule: {
      report_identifier: Number(id),
      endPoint: updateRangeDates,
      detailsDB: reportDetail?.custom_dates?.comment ?? "",
      comment: deadLineDetails,
      setComment: setDeadLineDetails,
      setDeadLine: setConclusionDeadline,
      deadLine: conclusionDeadline,
    },
    // Report Severity
    severity: {
      report_id: Number(id),
      endPoint: changeSeverity,
      detailsDB: reportDetail?.severity?.comment ?? "",
      comment: severityDetails,
      setComment: setSeverityDetails,
      setSeverity,
      severity,
    },
    // Report Blocked
    locked: {
      report_id: Number(id),
      endPoint: changeReportSecure,
      detailsDB: reportDetail?.locked?.message ?? "",
      comment: blockedDetails,
      setComment: setBlockedDetails,
      comment_path: blockSelected,
      setComment_path: setBlockSelected,
      confirmed: reportDetail?.locked?.is_locked ?? false,
      value: switches.locked,
      setGetOut,
    },
    // Anonymity Compromised
    compromised: {
      report_identifier: Number(id),
      endPoint: changeCompromisedAnonymity,
      confirmed:
        reportDetail?.compromised_anonymity?.compromised_anonymity_value ??
        false,
      detailsDB: reportDetail?.compromised_anonymity?.comment ?? "",
      comment: textCompromised,
      setComment: setTextCompromised,
      value_to_assign: switches.compromised,
      setGetOut,
    },
    // Hostil Enviroment
    hostile: {
      report_identifier: Number(id),
      endPoint: changeHostileEnvironment,
      confirmed: reportDetail?.hostile_place?.is_hostile_place_value ?? false,
      detailsDB: reportDetail?.hostile_place?.comment,
      comment: textHostile,
      setComment: setTextHostile,
      value_to_assign: switches.hostile,
      setGetOut,
    },
  };

  ////////// Switch onChange event //////////
  const hdlSwitchIndicatorChange = (e) => {
    const status_type = e.target.id;
    const value = e.target.checked;
    setSwitches((prev) => ({ ...prev, [status_type]: value }));
    setEditing((prev) => ({ ...prev, [status_type]: value }));
  };

  ////////// Cancel edit indicator //////////
  const cancelIndicator = (status_type) => {
    const { confirmed, setMessage, setComment_path, setComment } =
      indicatorsMap[status_type];
    // Si ya fue confirmado en la base
    if (confirmed) {
      if (setMessage) {
        setMessage("");
      }
      if (status_type === "locked") {
        setComment_path("");
        setComment("");
      }
      // Si apenas está editando pero quieren cancelar
    } else {
      setSwitches((prev) => ({ ...prev, [status_type]: false }));
      if (status_type === "locked") {
        setComment_path("");
        setComment("");
      }
      if (setMessage) {
        setMessage("");
      }
    }
    setEditing((prevState) => ({
      ...prevState,
      [status_type]: !prevState[status_type],
    }));
  };

  ////////// Saves the information when the switch is turned off //////////
  const turnOffSwitchSave = async (status_type) => {
    const {
      report_id,
      endPoint,
      report_identifier,
      value_to_assign,
      setGetOut,
    } = indicatorsMap[status_type];

    const data = {
      ...(report_id && { report_id }),
      ...(report_identifier && { report_identifier }),
      ...(status_type && { status_type }),
      comment: null,
      comment_path: null,
      ...(value_to_assign && { value_to_assign: false }),
      value: false,
    };
    setLoading((prev) => ({ ...prev, [status_type]: true }));
    const snackLoading = enqueueSnackbar(t("information.sending"), {
      variant: "info",
      persist: true,
      preventDuplicate: true,
    });

    try {
      const resp = await endPoint(data).then((resp) => {
        if (
          resp?.message === "changed" ||
          resp?.success === "custom_dates value was updated successfully"
        ) {
          closeModal();
          setLoading((prev) => ({ ...prev, [status_type]: false }));

          if (setGetOut) {
            setGetOut((prev) => ({ ...prev, [status_type]: true }));
          }
          setTimeout(() => {
            closeSnackbar(snackLoading);
            responseReport();
            if (setGetOut) {
              setGetOut((prev) => ({ ...prev, [status_type]: false }));
            }
            setSwitches((prev) => ({ ...prev, [status_type]: false }));
            enqueueSnackbar(t("information.successfully_updated"), {
              variant: "success",
            });
          }, 2000);
        }
      });
    } catch (error) {
      setLoading((prev) => ({ ...prev, [status_type]: false }));
      closeSnackbar(snackLoading);
      console.error(error);
    }
  };

  ////////// Save indicator info //////////
  const saveIndicatorInfo = async (status_type) => {
    const {
      report_identifier,
      report_id,
      endPoint,
      deadLine,
      comment,
      setComment,
      value,
      comment_path,
      setComment_path,
    } = indicatorsMap[status_type];

    // Data to endpoint
    const data = {
      ...(report_identifier && {
        report_identifier,
      }),
      ...(report_id && {
        report_id,
      }),
      ...(severity && { path: severity }),
      ...(deadLine && {
        end_day: deadLine.toISOString().split("T")[0],
      }),
      ...(comment && { comment }),
      ...(value && { value }),
      value_to_assign: true,
      ...(comment_path && { comment_path }),
      status_type,
    };

    setLoading((prev) => ({ ...prev, [status_type]: true }));
    const snackLoading = enqueueSnackbar(t("information.sending"), {
      variant: "info",
      persist: true,
      preventDuplicate: true,
    });
    try {
      const resp = await endPoint(data).then((resp) => {
        if (
          resp?.success === "custom_dates value was updated successfully" ||
          resp?.severity === "changed" ||
          resp?.message === "changed"
        ) {
          if (setComment) {
            setComment("");
          }
          if (setComment_path) {
            setComment_path("");
          }
          closeSnackbar(snackLoading);
          setEditing((prev) => ({ ...prev, [status_type]: false }));
          setLoading((prev) => ({ ...prev, [status_type]: false }));
          responseReport();
          closeModal();
          enqueueSnackbar(t("information.successfully_updated"), {
            variant: "success",
          });
        }
      });
    } catch (error) {
      setLoading((prev) => ({ ...prev, [status_type]: false }));
      closeSnackbar(snackLoading);
      console.error(error);
    }
  };

  ////////// Edit indicator info //////////
  const editIndicator = (status_type) => {
    const {
      detailsDB,
      setDeadLine,
      setSeverity,
      setComment_path,
      setComment,
      value,
    } = indicatorsMap[status_type];
    // Editing
    if (!editing[status_type]) {
      setComment(detailsDB || "");
      if (status_type === "locked") {
        // Find label in the pre-selected value catalog
        const blockedSelectedLabel = blockedCategory.find(
          (item) => item?.label === reportDetail?.locked?.comment?.label
        );
        setComment_path(blockedSelectedLabel?.value ?? "");
      }
      if (setSeverity) {
        setSeverity(reportDetail?.severity?.path.toLowerCase() || "");
      }
      if (setDeadLine) {
        setDeadLine(dayjs(reportDetail?.custom_dates?.end_day || dateDeadLine));
      }
    } else {
      if (!value) {
        setSwitches((prev) => ({ ...prev, [status_type]: false }));
      }
      // Cancel editing
      setComment("");
      if (setDeadLine) {
        setDeadLine(null);
      }
      if (setComment_path) {
        setComment_path("");
      }
    }
    // Change of status of the selected edition
    setEditing((prevState) => ({
      ...prevState,
      [status_type]: !prevState[status_type],
    }));
  };

  ////////// Modal to confirm action //////////
  const openModalAction = (action, title, desc1, desc2, question) => {
    setModalConfig({
      showModal: true,
      confirmAction: action,
      modalTitle: title,
      description1: desc1,
      description2: desc2,
      modalQuestion: question,
    });
  };
  const closeModal = () => {
    setModalConfig({
      ...modalConfig,
      showModal: false,
      confirmAction: () => {},
      modalTitle: "",
      description1: "",
      description2: "",
      modalQuestion: "",
    });
  };

  ////////// Dynamic Action buttons //////////
  const actionsBlocking = [
    {
      icon: <OutlineEdit color={theme.palette.primary.main} />,
      name: t("information.edit"),
      onClick: () => editIndicator("locked"),
    },
    {
      icon: <OutlineUnlock color={theme.palette.error.main} />,
      name: t("information.unblock"),
      onClick: () =>
        openModalAction(
          () => {
            turnOffSwitchSave("locked");
          },
          t("information.important"),
          t("information.you_about_unlock_this_report"),
          t("information.please_note_that_this_may_grant_access_other_users"),
          t("information.do_you_want_continue")
        ),
    },
  ];
  const actionsAnonymity = [
    {
      icon: <OutlineEdit color={theme.palette.primary.main} />,
      name: t("information.edit"),
      onClick: () => editIndicator("compromised"),
    },
    {
      icon: <TrashCan color={theme.palette.error.main} />,
      name: t("information.delete"),
      onClick: () =>
        openModalAction(
          () => {
            turnOffSwitchSave("compromised");
          },
          t("information.attention"),
          t("information.you_about_modify_most_recently_data"),
          t("information.this_action_is_irreversible_we_recommend_checking"),
          t("information.do_you_want_continue")
        ),
    },
  ];
  const actionsHostileEnv = [
    {
      icon: <OutlineEdit color={theme.palette.primary.main} />,
      name: t("information.edit"),
      onClick: () => editIndicator("hostile"),
    },
    {
      icon: <TrashCan color={theme.palette.error.main} />,
      name: t("information.delete"),
      onClick: () =>
        openModalAction(
          () => {
            turnOffSwitchSave("hostile");
          },
          t("information.attention"),
          t("information.you_about_modify_most_recently_data"),
          t("information.this_action_is_irreversible_we_recommend_checking"),
          t("information.do_you_want_continue")
        ),
    },
  ];

  ////////// Initial value from date calculation //////////
  const dateDeadLine = new Date(reportDetail.created_at);
  dateDeadLine.setDate(dateDeadLine.getDate() + reportDetail.expired_report);

  ////////// Selection severity level //////////
  const selectedSeverityLvl = severityCatTransformed.find(
    (option) => option.value === reportDetail?.severity?.path
  );

  ////////// Related Report //////////
  // Save related report
  const handleRelatedSave = async () => {
    const dataToSend = {
      report_identifier: Number(id),
      report_to_relate_identifier: relatedFolio,
      comment: relatedComment,
    };
    if (uploadRelated) {
      try {
        const resp = await changeRelated(dataToSend).then((resp) => {
          if (resp) {
            setRelatedFolio("");
            setRelatedComment("");
            setEditRelated(false);
            enqueueSnackbar(
              t("information.linked_report_successfully_updated"),
              { variant: "success" }
            );
            responseReport();
            setUploadRelated(false);
          } else {
            return;
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const resp = await createRelated(dataToSend).then((resp) => {
          if (resp) {
            setRelatedFolio("");
            setRelatedComment("");
            setEditRelated(false);
            enqueueSnackbar(t("information.successfully_linked_report"), {
              variant: "success",
            });
            responseReport();
          } else {
            return;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  // Unlink associated report
  const removeRelatedSave = async (report) => {
    try {
      const resp = await deleteRelated({
        report_identifier: Number(id),
        report_to_relate_identifier: report.tracking_code,
      }).then((resp) => {
        if (resp) {
          enqueueSnackbar(
            t("information.report_has_been_successfully_unlinked"),
            { variant: "success" }
          );
          closeModal();
          responseReport();
        } else {
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // See more...
  const hdlSeeMore = (index) => {
    setExpandedRelatedComments((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
  // Cancel and clean related report configuration
  const hdlCancelRelatedReport = () => {
    setRelatedComment("");
    setRelatedFolio("");
    setEditRelated(false);
    setUploadRelated(false);
  };

  return (
    <Row>
      <ConfirmationModal
        show={modalConfig.showModal}
        onHide={closeModal}
        onConfirm={modalConfig.confirmAction}
        title={modalConfig.modalTitle}
        description1={modalConfig.description1}
        description2={modalConfig.description2}
        question={modalConfig.modalQuestion}
      />
      {!edit ? (
        <>
          {/* General Report Information */}
          <Col lg={8} className="p-0 information">
            <div className="mt-20 me-lg-4 dyTheme1 dyBorder1 rounded">
              <InvestigationSummary
                reportDetail={reportDetail}
                setShowModalAttachments={setShowModalAttachments}
              />
            </div>
            
          </Col>

          {/* Additional report parameters */}
          <Col lg={4} className="p-0 properties">
            {/* Edit Report Button */}
            <Row className="mt-20">
              <Button
                className="properties__edit-btn"
                variant="light"
                onClick={() => handleEditChange((edit) => !edit)}
              >
                <div>
                  <OutlineEdit
                    className="properties__edit-icon mx-1"
                    width={18}
                  />
                  <small className="mx-1">{t("information.edit_report")}</small>
                </div>
              </Button>
            </Row>
            {/* Deadline for conclusion */}
            <Row
              className={`conclusionDeadline rounded dyTheme1 dyBorder1 mt-20 px-1 ${
                editing.schedule ? "py-20" : "py-10"
              }`}
            >
              <div className="conclusionDeadline__title">
                <Stack
                  display="flex"
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  width="100%"
                  spacing={1}
                >
                  <Timer2Bold color={`${theme.palette.ethicsBlue.main}`} />
                  {editing.schedule ? (
                    <EGDatePicker
                      label={`${t("information.completion_deadline")}`}
                      value={conclusionDeadline}
                      onChange={(newDate) => setConclusionDeadline(newDate)}
                      minDate={dayjs(reportDetail.created_at)}
                    />
                  ) : (
                    <Typography variant="h6" align="center">
                      {t("information.completion_deadline")}:
                      <b
                        className="mx-2"
                        style={{ color: theme.palette.blue.main }}
                      >{`${
                        reportDetail?.custom_dates?.end_day
                          ? parseDateToDDMMYYYY2(
                              dayjs(reportDetail.custom_dates.end_day)
                            )
                          : parseDateToDDMMYYYY2(dateDeadLine.toDateString())
                      }`}</b>
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  spacing={1}
                  marginLeft={2}
                >
                  {editing.schedule && (
                    <EGIconButton
                      disabled={loading.schedule}
                      iconName="check_icon"
                      title={t("information.save")}
                      placement="bottom"
                      color="success"
                      iconColor="white"
                      onClick={() =>
                        openModalAction(
                          () => saveIndicatorInfo("schedule"),
                          t("information.deadline_modification"),
                          t(
                            "information.changing_conclusion_date_affects_all_involved_users"
                          ),
                          t(
                            "information.its_essential_notify_this_change_ensure_that_everyone_informed"
                          ),
                          t("information.are_you_sure_you_want_continue")
                        )
                      }
                    />
                  )}
                  <EGIconButton
                    disabled={loading.schedule}
                    iconName={`${
                      editing.schedule ? "close_icon" : "outline_edit"
                    }`}
                    color={`${editing.schedule ? "error" : ""}`}
                    iconColor={`${editing.schedule ? "white" : ""}`}
                    title={
                      editing.schedule
                        ? t("information.cancel")
                        : t("information.edit")
                    }
                    placement="bottom"
                    onClick={() => editIndicator("schedule")}
                  />
                </Stack>
              </div>
              <div>
                {editing.schedule ? (
                  <>
                    {details.schedule ? (
                      <TextField
                        id="outlined-multiline-static-detail-date"
                        label={`${t("information.details")}:`}
                        placeholder={t(
                          "information.enter_details_for_date_range"
                        )}
                        value={deadLineDetails}
                        onChange={(e) => setDeadLineDetails(e.target.value)}
                        multiline
                        rows={4}
                        className="w-100 mt-3"
                      />
                    ) : (
                      <Stack display="flex" alignItems="end" marginTop="0.2rem">
                        <Link
                          onClick={() =>
                            setDetails((prev) => ({ ...prev, schedule: true }))
                          }
                          underline="hover"
                        >
                          + {t("information.add_details")}
                        </Link>
                      </Stack>
                    )}
                  </>
                ) : (
                  <>
                    {reportDetail?.custom_dates?.comment && (
                      <div className="my-2">
                        <Comments
                          rounded
                          text={reportDetail?.custom_dates?.comment}
                          border="primary"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </Row>
            {/* Severity */}
            <Row
              className={`severity rounded dyTheme1 dyBorder1 mt-20 px-1 ${
                editing.severity ? "py-20" : "py-10"
              }`}
            >
              <div className="severity__title">
                <Stack
                  display="flex"
                  direction="row"
                  alignItems="center"
                  width="100%"
                  justifyContent="start"
                >
                  <BoldFlash2 color={theme.palette.yellow.main} />
                  <Typography variant="h6" className="mx-2">
                    {t("information.report_severity")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  spacing={1}
                  marginLeft={2}
                >
                  {editing.severity && (
                    <EGIconButton
                      disabled={loading.severity}
                      iconName="check_icon"
                      title={t("information.save")}
                      placement="bottom"
                      color="success"
                      iconColor="white"
                      onClick={() =>
                        openModalAction(
                          () => saveIndicatorInfo("severity"),
                          t("information.attention"),
                          t("information.you_about_modify_most_recently_data"),
                          t(
                            "information.this_action_is_irreversible_we_recommend_checking"
                          ),
                          t("information.do_you_want_continue")
                        )
                      }
                    />
                  )}
                  <EGIconButton
                    disabled={loading.severity}
                    iconName={`${
                      editing.severity ? "close_icon" : "outline_edit"
                    }`}
                    color={`${editing.severity ? "error" : ""}`}
                    iconColor={`${editing.severity ? "white" : ""}`}
                    title={
                      editing.severity
                        ? t("information.cancel")
                        : t("information.edit")
                    }
                    placement="bottom"
                    onClick={() => editIndicator("severity")}
                  />
                </Stack>
              </div>
              <div className={`${editing.severity ? "my-3" : "my-1"}`}>
                {editing.severity ? (
                  <>
                    <BasicSelect
                      value={severity}
                      options={severityCatTransformed}
                      onChange={(e) => setSeverity(e.target.value)}
                      required
                    />
                    {details.severity ? (
                      <TextField
                        id="outlined-multiline-static-detail-date"
                        label={`${t("information.details")}:`}
                        placeholder={t(
                          "information.enter_details_for_date_range"
                        )}
                        value={severityDetails}
                        onChange={(e) => setSeverityDetails(e.target.value)}
                        rows={4}
                        className="w-100 mt-3"
                        multiline
                      />
                    ) : (
                      <Stack display="flex" alignItems="end" marginTop="0.2rem">
                        <Link
                          onClick={() =>
                            setDetails((prev) => ({
                              ...prev,
                              severity: true,
                            }))
                          }
                          underline="hover"
                        >
                          + {t("information.add_details")}
                        </Link>
                      </Stack>
                    )}
                  </>
                ) : (
                  <div className="severity__info rounded my-1">
                    <h6 className="label m-0">
                      {selectedSeverityLvl?.label ||
                        t("information.not_defined")}
                    </h6>
                    {reportDetail.severity.comment && (
                      <div className="comment">
                        <Comments text={reportDetail.severity.comment} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Row>
            {/* Blocked */}
            <Row
              className={`${
                reportDetail?.locked?.is_locked && !editing.locked
                  ? "confirm py-10"
                  : "py-20"
              } blocked rounded dyTheme1 dyBorder1 mt-20 px-1`}
            >
              <div className="blocked__title">
                <Stack display="flex" direction="row" alignItems="center">
                  <BoldLockSlash color={theme.palette.error.main} />
                  <Typography variant="h6" className="mx-2">
                    {t("information.block_report")}
                  </Typography>
                </Stack>
                <Stack
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                  flexWrap="nowrap"
                  spacing={1}
                >
                  {switches.locked ? (
                    editing.locked ? (
                      <>
                        <EGIconButton
                          disabled={loading.locked}
                          iconName="check_icon"
                          color="success"
                          iconColor="white"
                          onClick={() => saveIndicatorInfo("locked")}
                        />
                        <EGIconButton
                          disabled={loading.locked}
                          iconName="close_icon"
                          color="error"
                          iconColor="white"
                          onClick={() => cancelIndicator("locked")}
                        />
                      </>
                    ) : (
                      <>
                        <EGSpeedDial
                          actions={actionsBlocking}
                          tooltipPlacement="bottom"
                        />
                      </>
                    )
                  ) : (
                    <>
                      <EGSwitch
                        onChange={hdlSwitchIndicatorChange}
                        checked={switches.locked}
                        id="locked"
                      />
                    </>
                  )}
                </Stack>
              </div>
              {switches.locked && (
                <div className={editing.locked ? "my-3" : "my-1"}>
                  {editing.locked ? (
                    <>
                      <BasicSelect
                        value={blockSelected}
                        options={blockedCategory}
                        onChange={(e) => setBlockSelected(e.target.value)}
                      />

                      {details.locked ? (
                        <>
                          <TextField
                            id="outlined-multiline-static-detail-blocked-report"
                            label={`${t("information.details")}:`}
                            placeholder={t("information.specify_details_block")}
                            value={blockedDetails}
                            onChange={(e) => setBlockedDetails(e.target.value)}
                            rows={4}
                            className="w-100 mt-3"
                            multiline
                          />
                        </>
                      ) : (
                        <>
                          <Stack
                            display="flex"
                            alignItems="end"
                            marginTop="0.2rem"
                          >
                            <Link
                              onClick={() =>
                                setDetails((prev) => ({
                                  ...prev,
                                  locked: true,
                                }))
                              }
                              underline="hover"
                            >
                              + {t("information.add_details")}
                            </Link>
                          </Stack>
                        </>
                      )}
                    </>
                  ) : (
                    <div
                      className={`blocked__info ${
                        getOut.locked && "--getOut"
                      } rounded my-1`}
                    >
                      <h6 className="label m-0">
                        {reportDetail?.locked?.comment?.label ||
                          t("information.this_report_is_blocked")}
                      </h6>
                      {reportDetail?.locked?.message && (
                        <div className="comment">
                          <Comments text={reportDetail.locked.message} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Row>
            {/* Report aditional indicators */}
            <Row className="rounded dyTheme1 dyBorder1 mt-20 px-1 py-10">
              {/* Compromised anonymity  */}
              <div className="anonymity py-10 border-bottom">
                <div className="anonymity__title">
                  <Stack
                    display="flex"
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <BoldUserRemove color={theme.palette.teal.main} />
                    <Typography variant="h6" className="mx-2">
                      {t("information.mark_as_anonymity_compromised")}
                    </Typography>
                  </Stack>
                  <Stack
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                    spacing={1}
                  >
                    {switches.compromised ? (
                      editing.compromised ? (
                        <>
                          <EGIconButton
                            disabled={loading.compromised}
                            iconName="check_icon"
                            color="success"
                            iconColor="white"
                            onClick={() => saveIndicatorInfo("compromised")}
                          />
                          <EGIconButton
                            disabled={loading.compromised}
                            iconName="close_icon"
                            color="error"
                            iconColor="white"
                            onClick={() => cancelIndicator("compromised")}
                          />
                        </>
                      ) : (
                        <EGSpeedDial
                          actions={actionsAnonymity}
                          tooltipPlacement="bottom"
                        />
                      )
                    ) : (
                      <EGSwitch
                        onChange={hdlSwitchIndicatorChange}
                        checked={switches.compromised}
                        id="compromised"
                      />
                    )}
                  </Stack>
                </div>
                {switches.compromised && (
                  <div className={editing.compromised ? "my-1" : "mt-2"}>
                    {editing.compromised ? (
                      <TextField
                        id="outlined-multiline-static-detail-anonymity-compromised"
                        label={`${t("information.details")}:`}
                        value={textCompromised}
                        onChange={(e) => setTextCompromised(e.target.value)}
                        rows={4}
                        className="w-100 mt-3"
                        multiline
                      />
                    ) : (
                      <>
                        <div
                          className={`anonymity__info rounded my-1 ${
                            getOut.compromised && "--getOut"
                          }`}
                        >
                          <h6 className="label m-0">
                            {t("information.anonymity_compromised")}
                          </h6>
                          {reportDetail?.compromised_anonymity?.comment && (
                            <div className="comment">
                              <Comments
                                text={
                                  reportDetail.compromised_anonymity.comment
                                }
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/* Hostile environment */}
              <div className="hostile py-10">
                <div className="hostile__title">
                  <Stack
                    display="flex"
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <BoldRadar color={theme.palette.purple.main} />
                    <Typography variant="h6" className="mx-2">
                      {t("information.did_report_create_hostile_environment")}
                    </Typography>
                  </Stack>
                  <Stack
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                    spacing={1}
                  >
                    {switches.hostile ? (
                      editing.hostile ? (
                        <>
                          <EGIconButton
                            disabled={loading.hostile}
                            iconName="check_icon"
                            color="success"
                            iconColor="white"
                            onClick={() => saveIndicatorInfo("hostile")}
                          />
                          <EGIconButton
                            disabled={loading.hostile}
                            iconName="close_icon"
                            color="error"
                            iconColor="white"
                            onClick={() => cancelIndicator("hostile")}
                          />
                        </>
                      ) : (
                        <EGSpeedDial
                          actions={actionsHostileEnv}
                          tooltipPlacement="bottom"
                        />
                      )
                    ) : (
                      <EGSwitch
                        onChange={hdlSwitchIndicatorChange}
                        checked={switches.hostile}
                        id="hostile"
                      />
                    )}
                  </Stack>
                </div>
                {switches.hostile && (
                  <div className={editing.hostile ? "my-1" : "mt-2"}>
                    {editing.hostile ? (
                      <TextField
                        id="outlined-multiline-static-detail-hostil-environment"
                        label={`${t("information.details")}:`}
                        value={textHostile}
                        onChange={(e) => setTextHostile(e.target.value)}
                        rows={4}
                        className="w-100 mt-3"
                        multiline
                      />
                    ) : (
                      <div
                        className={`hostile__info rounded my-1 ${
                          getOut.hostile && "--getOut"
                        }`}
                      >
                        <h6 className="label m-0">
                          {t(
                            "information.hostile_environment_has_been_identified_report"
                          )}
                        </h6>
                        {reportDetail?.hostile_place?.comment && (
                          <div className="comment">
                            <Comments
                              text={reportDetail.hostile_place.comment}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Row>

            {/* Related Reports */}
            <Row className="p-20 my-20 dyTheme1 dyBorder1 rounded">
              <div className="properties__title-related pt-2 pb-3">
                <h5 className="my-auto">
                  <Icon
                    name="outline_programing_arrow"
                    className="mx-2"
                    width={20}
                  />
                  {`${
                    relatedList && relatedList.length > 0
                      ? t("information.related_reports")
                      : t("information.add_folio_related_case")
                  }`}
                </h5>

                <div className="d-flex">
                  {editRelated && (
                    <MCTooltip text={t("information.save")} position="top">
                      <Fab
                        size="small"
                        onClick={handleRelatedSave}
                        className="--save mx-1"
                      >
                        <CheckIcon size={35} />
                      </Fab>
                    </MCTooltip>
                  )}
                  <MCTooltip
                    text={`${
                      editRelated
                        ? t("information.cancel")
                        : t("information.add_related_report_folio")
                    }`}
                    position="top"
                  >
                    <Fab
                      size="small"
                      className="mx-1"
                      color="default"
                      onClick={
                        editRelated && uploadRelated
                          ? hdlCancelRelatedReport
                          : () => setEditRelated(!editRelated)
                      }
                    >
                      {editRelated ? (
                        <CloseIcon size={30} className="--cancelar" />
                      ) : (
                        <AddIcon />
                      )}
                    </Fab>
                  </MCTooltip>
                </div>
              </div>
              {editRelated ? (
                <div className="p-1">
                  <label className="m-2">
                    {t("information.add_related_report")}:
                  </label>
                  <MCInput
                    type="search"
                    placeholder={t(
                      "information.enter_report_folio_tracking_code"
                    )}
                    value={relatedFolio}
                    disabled={uploadRelated}
                    onChange={(e) => {
                      setRelatedFolio(e.target.value);
                    }}
                  />
                  <>
                    <TextField
                      id="outlined-multiline-static"
                      label={`${t("information.details")}:`}
                      placeholder={t(
                        "information.enter_report_folio_tracking_code"
                      )}
                      value={relatedComment}
                      onChange={(e) => {
                        setRelatedComment(e.target.value);
                      }}
                      multiline
                      rows={4}
                      className="mt-4 w-100"
                    />
                  </>
                  <hr />
                </div>
              ) : (
                <>
                  {relatedList && relatedList.length > 0 && (
                    <div className="mb-2">
                      {relatedList.map((related, i) => (
                        <RelatedReportItem
                          key={i}
                          i={i}
                          related={related}
                          investigationList={investigationList}
                          expandedRelatedComments={expandedRelatedComments}
                          setEditRelated={setEditRelated}
                          setRelatedFolio={setRelatedFolio}
                          setRelatedComment={setRelatedComment}
                          setUploadRelated={setUploadRelated}
                          openModalAction={openModalAction}
                          removeRelatedSave={removeRelatedSave}
                          hdlSeeMore={hdlSeeMore}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </Row>
          </Col>
        </>
      ) : (
        <Col lg={12} className="p-0">
          <div className="mt-20">
            <div className="cancel-edit-btn mt-20">
              <Button
                className="mx-2 right"
                onClick={() => handleEditChange(edit ? false : true)}
              >
                <CancelIconSquare className="icons m-1" />
                <span className="d-none d-md-inline-block m-1">
                  {t("information.cancel_edit")}
                </span>
              </Button>
            </div>
            {/* Edicion del formulario */}
            {isLoadingForm ? (
              <Loading />
            ) : (
              <PreviewForm
                steps={steps}
                schemaState={schemaState}
                setSchemaState={setSchemaState}
                formData={formData}
                setFormData={setFormData}
                isValid={isValid}
                setIsValid={setIsValid}
                constraints={constraints}
                setConstraints={setConstraints}
                showButtons={true}
                stepClick={false}
                answered={true}
                id={reportDetail?.id}
                edit={edit}
              />
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};
