import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserRoutes } from "./UserRoutes";
import { PageRoutes } from "./PageRoutes";
import { useEffect } from "react";
import { checkToken } from "../apis";
import { useDispatch } from "react-redux";
import { startLoggedOut } from "../store/auth/authThunks";
import { ModalKeepSessionAlive } from "../components/Modal/ModalKeepSessionAlive";

const session = JSON.parse(localStorage.getItem("Session")) || null;

export const AppRouter = () => {
  const { isLoggedIn, tenant, status } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = session?.IdToken;
    const accessToken = session?.AccessToken;
    if (idToken && accessToken) {
      checkToken(idToken, accessToken)
        .then(() => {
          // console.log("Valid credentials")
        })
        .catch(() => {
          dispatch(startLoggedOut());
          navigate("/");
        });
    } else {
      dispatch(startLoggedOut());
      navigate("/");
    }
  }, []);

  if (status === "checking") {
    return <></>;
  }

  return (
    <>
    <ModalKeepSessionAlive />
    <Routes>
      {isLoggedIn && tenant ? (
        <Route path="/*" element={<UserRoutes />} />
      ) : (
        <Route path="/*" element={<PageRoutes />} />
      )}
    </Routes>
    </>
  );
};
