// import { Accordion } from 'react-bootstrap';
// import { CDBBtn } from 'cdbreact';
// import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";

const PlanCard = (props) => {
  const [t] = useTranslation("login");
  return (
    <div
      style={{
        height: "auto",
        width: props.containerWidth ? props.containerWidth : "100%",
        borderRadius: 4,
        border: "1px solid #e3ebf5",
      }}
    >
      <div className="d-flex flex-column">
        <div
          style={{
            borderLeft: `5px solid ${props.headerColor}`,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          className="p-30"
        >
          <div className="d-block d-sm-flex flex-row text-left">
            <div className="w-100">
              <span style={{ fontSize: 24, fontWeight: "bold" }}>
                {props.plan.name}
              </span>
            </div>
            <div className="w-100 text-sm-right">
              <span style={{ fontSize: 24 }}>{props.plan.duration}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: 4,
            borderTop: "1px solid #e3ebf5",
          }}
        >
          {props.isList ? (
            <>
              {/* <div style={{fontSize:12, color:"#262F38"}}>{ReactHtmlParser(props.plan.description)}</div>
                                <div style={{fontWeight:'bold', padding:10, fontSize:13}}>
                                    {props.plan.price}
                                </div>
                                <hr style={{margin:10}} />
                                <div style={{fontSize:12, color:"#262F38"}}>{ReactHtmlParser(props.plan.features)}</div>
                                <hr style={{margin:10}} />
                            
                                <CDBBtn className="athics-btn" block={true} disabled={false} onClick={props.handleSelection}>SELECCIONAR PLAN</CDBBtn> */}
              <h1>PLAN CARD</h1>
            </>
          ) : (
            <>
              {/* <div className="d-block d-sm-flex align-items-center flex-row p-4">
                                    <div className='w-100' style={{color:"#262F38",fontSize:14}}>{ReactHtmlParser(props.plan.description)}</div>
                                    <div className='w-100 text-sm-right' style={{fontWeight:'bold', padding:10, fontSize:22}}>
                                        {props.plan.price}
                                    </div>
                                </div>
                                <hr style={{margin:10}} />
                                <div className='p-4'>
                                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="auth-accordion-header">Report Channel</Accordion.Header>
                                            <Accordion.Body className="auth-accordion-body">
                                                <div className="card-body p-0">
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de empresas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de personas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de Centros de trabajo</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de areas y departamentos</small>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="auth-accordion-header">Case Manager</Accordion.Header>
                                            <Accordion.Body className="auth-accordion-body">
                                                <div className="card-body p-0">
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de empresas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de personas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de Centros de trabajo</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de areas y departamentos</small>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="auth-accordion-header">Integrity Adviser</Accordion.Header>
                                            <Accordion.Body className="auth-accordion-body">
                                                <div className="card-body p-0">
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de empresas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de personas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de Centros de trabajo</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de areas y departamentos</small>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header className="auth-accordion-header">Ethics Training</Accordion.Header>
                                            <Accordion.Body className="auth-accordion-body">
                                                <div className="card-body p-0">
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de empresas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de personas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de Centros de trabajo</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de areas y departamentos</small>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header className="auth-accordion-header">Ethics Aware</Accordion.Header>
                                            <Accordion.Body className="auth-accordion-body">
                                                <div className="card-body p-0">
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de empresas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de personas</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de Centros de trabajo</small>
                                                    </div>
                                                    <div className="d-flex d-row align-items-center mb-2">
                                                        <CheckIconCircle size={"16px"} />
                                                        <small className="ml-2 mb-0">Catálogo de areas y departamentos</small>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div> */}
              <h1>PLAN CARD 2</h1>
            </>
          )}
          <p style={{ fontSize: 12, padding: 10 }}>
            <b>{t("PlanCard.once_paid_languages_charged")}</b>
          </p>
        </div>
        {!props.isList && (
          <div className="text-center">
            <h5>{t("PlanCard.need_specific_funcionalities")}</h5>
            <small className="font-14">{t("PlanCard.contact_us")}</small>
            <br />
            <br />
            <div className="plan-country"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCard;

// .auth-accordion-header button{
//     background-color: #f3f7fa!important;
//     font-size: 12px!important;
//     font-weight: bold!important;
//     height: 40px!important;
//     color: inherit!important;
// }
