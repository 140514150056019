import { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import { MCButton } from "../../MainComponents/Button/Button";
import _ from "lodash";
import { ModalSegmentCatalogue } from "./ModalSegmentCatalogue";
import { ModalSegmentCatalogueEdit } from "./ModalSegmentCatalogueEdit";
import {
  createGroupEAM,
  getGroupEAM,
  getUtilitiesEAM,
  updateGroupEAM,
  updateUserEAM,
} from "../../../apis/apiEAM";
import { MCInput } from "../../MainComponents/Input/Input";
import { MCLabel } from "../../MainComponents/Label/Label";
import { useLocation, useNavigate, useParams } from "react-router";
import { catalogByPart } from "../../../apis";
import { GeneralHeader, GeneralIconHeader } from "../../Title/GeneralHeader";
import Icon from "../../Icon/Icon";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";

const ListItemValues = ({ path, field }) => {
  const [label, setLabel] = useState(null);

  useEffect(() => {
    const splitedField = field.split("::");
    const splitedPath = path.split("::");
    const value = splitedPath.splice(-2);
    const joined = splitedPath.join("::");
    catalogByPart({
      catalogue: splitedField[2],
      path: joined === "" ? "/" : joined,
      is_own: splitedField[1] === "OWN" ? true : false,
    }).then((resp) => {
      const labelTemp = resp.data.find((item) => item.key === value[1]).label;
      setLabel(labelTemp);
    });
  }, [path]);

  return <li className="list-values-value">{label ? label : "..."}</li>;
};

const SegmentChild = ({
  scope,
  path,
  handleAddChild,
  handleEditValues,
  handleDeleteChild,
  utilitiesCatalogues,
  showAllFilters,
  nestNum,
}) => {
  const [showModalSegment, setShowModalSegment] = useState(true);
  const [showModalSegmentEdit, setShowModalSegmentEdit] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [t] = useTranslation("users");

  useEffect(() => {
    setShowFilters(showAllFilters);
  }, [showAllFilters]);

  return (
    <div className="ps-4">
      {showModalSegment && (
        <ModalSegmentCatalogue
          scope={scope}
          path={path}
          show={showModalSegment}
          onHide={() => setShowModalSegment(false)}
          handleAddChild={handleAddChild}
          utilitiesCatalogues={utilitiesCatalogues}
        />
      )}
      {showModalSegmentEdit && (
        <ModalSegmentCatalogueEdit
          scope={scope}
          path={path}
          show={showModalSegmentEdit}
          onHide={() => setShowModalSegmentEdit(false)}
          handleAddChild={handleAddChild}
          handleEditValues={handleEditValues}
          utilitiesCatalogues={utilitiesCatalogues}
        />
      )}

      <div className={`mb-2 ${nestNum >= 1 ? "doted-rline" : ""}`}>
        <div className={`child dyTheme1 dyBorder1 nest-lvl-${nestNum}`}>
          {nestNum >= 1 && <div className="color-lbar"></div>}

          <div className="w-100">
            <div className="d-flex align-items-center p-2">
              {Array.isArray(scope.child) && scope.child.length > 0 && (
                <div onClick={() => setShowChildren(!showChildren)}>
                  <Icon
                    name="arrow_down_2"
                    className={`mr-1 arrow-colapse ${
                      !showChildren ? "collapsed" : ""
                    }`}
                  />
                </div>
              )}
              <div className="w-100 d-flex">
                <div className="d-flex align-items-center">
                  <span className="me-2">{scope.label}</span>
                  <MCTooltip position="bottom" text={t("Segmentation.edit")}>
                    <div className="me-2">
                      <MCButton
                        label=""
                        icon="more_dots"
                        variant="primary"
                        size="sm"
                        className="p-0"
                        width={28}
                        outline
                        onClick={() => setShowModalSegmentEdit(true)}
                      />
                    </div>
                  </MCTooltip>
                  <MCTooltip
                    position="bottom"
                    text={t("Segmentation.add_segment")}
                  >
                    <div className="me-2">
                      <MCButton
                        label=""
                        icon="add_icon"
                        variant="primary"
                        size="sm"
                        className="add-icon p-0"
                        width={28}
                        outline
                        onClick={() => setShowModalSegment(true)}
                      />
                    </div>
                  </MCTooltip>
                  <MCTooltip position="bottom" text={t("Segmentation.delete")}>
                    <div className="me-2">
                      <MCButton
                        label=""
                        icon="trash_can"
                        variant="danger"
                        size="sm"
                        width={28}
                        className="p-0"
                        outline
                        onClick={() => handleDeleteChild(path)}
                      />
                    </div>
                  </MCTooltip>
                  <MCTooltip
                    position="bottom"
                    text={t("Segmentation.view_filters")}
                  >
                    <div>
                      <MCButton
                        label=""
                        icon={showFilters ? "outline_eye" : "outline_eye_slash"}
                        variant="primary"
                        size="sm"
                        className="p-0"
                        width={28}
                        outline
                        onClick={() => setShowFilters(!showFilters)}
                      />
                    </div>
                  </MCTooltip>
                </div>
              </div>
            </div>

            {showFilters && Array.isArray(scope?.value) && (
              <ul className="list-values mb-1">
                {scope.value.map((item, idx) => (
                  <ListItemValues key={idx} path={item} field={scope.field} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div
        className={`segment-children ${showChildren ? "show" : ""} ${
          nestNum === 1 ? "pr-3" : ""
        }`}
      >
        {Array.isArray(scope.child) &&
          scope.child.map((item, idx) => (
            <SegmentChild
              key={idx}
              scope={item}
              path={`${path}.child.${idx}`}
              nestNum={nestNum === 3 ? 1 : nestNum + 1}
              handleAddChild={handleAddChild}
              handleEditValues={handleEditValues}
              handleDeleteChild={handleDeleteChild}
              showModalSegment={showModalSegment}
              setShowModalSegment={setShowModalSegment}
              utilitiesCatalogues={utilitiesCatalogues}
              showAllFilters={showAllFilters}
            />
          ))}
      </div>
    </div>
  );
};

export const Segmentation = () => {
  const { gTheme } = useSelector((state) => state.theme);
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [utilitiesCatalogues, setUtilitiesCatalogues] = useState(null);
  const [showModalSegment, setShowModalSegment] = useState(false);
  const [name, setName] = useState("");
  const [action, setAction] = useState([]);
  const [is_active, setIs_active] = useState(true);
  const [segment, setSegment] = useState([]);
  const isUser = state?.user || false;
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [t] = useTranslation("users");

  useEffect(() => {
    getUtilitiesEAM().then((utilities) => {
      setUtilitiesCatalogues(utilities.catalogues);
    });
    if (params?.id) {
      getGroupEAM(params.id).then((resp) => {
        setIs_active(resp.is_active);
        setAction(resp.action);
        setName(resp.name);
        const arrSegment = Object.values(resp.segment);
        setSegment(arrSegment);
      });
    } else if (isUser) {
      setName(state.user.username);
      const arrSegment = Object.values(state.user.segment);
      setSegment(arrSegment);
    }
  }, []);

  const handleAddChild = (path, child) => {
    const newSegment = _.cloneDeep(segment);
    let arrToAdd;
    if (path === "") {
      arrToAdd = _.cloneDeep(segment);
      if (!Array.isArray(arrToAdd)) {
        arrToAdd = [];
      }
    } else {
      const arrTemp = _.get(newSegment, `${path}.child`);
      arrToAdd = Array.isArray(arrTemp) ? arrTemp : [];
    }
    arrToAdd.push(child);
    if (path === "") {
      setSegment(arrToAdd);
    } else {
      _.set(newSegment, `${path}.child`, arrToAdd);
      setSegment(newSegment);
    }
  };

  const handleEditValues = (path, edited) => {
    const newSegment = _.cloneDeep(segment);
    _.set(newSegment, `${path}`, edited);
    setSegment(newSegment);
  };

  const handleDeleteChild = (path) => {
    const newSegment = _.cloneDeep(segment);
    const splitedPath = path.split(".");
    let idx = splitedPath.pop();
    let arrToDel;
    if (splitedPath.length === 0) {
      arrToDel = newSegment;
    } else {
      arrToDel = _.get(newSegment, splitedPath.join("."));
    }
    arrToDel.splice(idx, 1);
    arrToDel = arrToDel.length > 0 ? arrToDel : null;
    if (splitedPath.length === 0) {
      setSegment(arrToDel);
    } else {
      _.set(newSegment, splitedPath.join("."), arrToDel);
      setSegment(newSegment);
    }
  };

  const handleCreateSegment = async () => {
    if (name.trim() === "") {
      Swal.fire({
        title: t("Segmentation.name_empty"),
        icon: "error",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Ok",
      });
      return;
    }
    await createGroupEAM({ name, action, segment, is_active });
    navigate(-1);
  };

  const handleUpdateSegment = async () => {
    await updateGroupEAM(params.id, { name, action, segment, is_active });
    navigate(-1);
  };

  const handleSegmentUser = async () => {
    const resp = await updateUserEAM({
      user_id: state.user.id,
      segment: segment ? segment : null,
    });
    if (!resp?.error) {
      navigate(-1);
    } else {
      console.error(resp);
    }
  };

  const breadcrumbItems = [
    { label: t("Segmentation.home"), route: "/" },
    { label: t("Segmentation.users"), route: "/users" },
    { label: t("Segmentation.groups"), route: "/segments" },
    {
      label: `${
        params.id ? t("Segmentation.edit") : t("Segmentation.create")
      } ${
        isUser ? t("Segmentation.user_filter") : t("Segmentation.group_segment")
      }`,
    },
  ];

  return (
    <div
      className={`segments-page page ${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      {showModalSegment && (
        <ModalSegmentCatalogue
          path={""}
          scope={segment}
          show={showModalSegment}
          onHide={() => setShowModalSegment(false)}
          handleAddChild={handleAddChild}
          utilitiesCatalogues={utilitiesCatalogues}
        />
      )}

      <GeneralIconHeader
        title={`${
          params.id ? t("Segmentation.edit") : t("Segmentation.create")
        } ${
          isUser
            ? t("Segmentation.user_filter")
            : t("Segmentation.group_segment")
        }`}
        text={`${
          isUser
            ? t("Segmentation.configures_reports_which_user_access")
            : t("Segmentation.assigns_set_reports_which_group_access")
        }`}
        icon={isUser ? "outline_profile_tick" : "outline_people"}
      />

      <Breadcrum items={breadcrumbItems} />

      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
        <div className="mb-3">
          {isUser ? (
            <MCLabel text={`${t("Segmentation.username")}:`} />
          ) : (
            <MCLabel text={`${t("Segmentation.group_name")}:`} />
          )}

          <div className="d-flex align-items-center">
            <MCInput
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isUser}
            />
          
          </div>
        </div>

        {/* <pre>{JSON.stringify(name,null,2)}</pre> */}

        <div className="children-container">
          {Array.isArray(segment) &&
            segment.map((item, idx) => (
              <SegmentChild
                key={idx}
                scope={item}
                path={`${idx}`}
                handleAddChild={handleAddChild}
                handleDeleteChild={handleDeleteChild}
                handleEditValues={handleEditValues}
                utilitiesCatalogues={utilitiesCatalogues}
                showAllFilters={showAllFilters}
                nestNum={1}
              />
            ))}
        </div>

        <MCButton
          label={t("Segmentation.add_segment")}
          variant="primary"
          outline
          className="mt-3 me-2"
          onClick={() => setShowModalSegment(true)}
        />
        {params.id ? (
          <MCButton
            label={t("Segmentation.save")}
            variant="success"
            outline
            className="mt-3"
            onClick={handleUpdateSegment}
          />
        ) : state?.user ? (
          <MCButton
            label={t("Segmentation.save")}
            variant="success"
            outline
            className="mt-3"
            onClick={handleSegmentUser}
          />
        ) : (
          <MCButton
            label={t("Segmentation.create")}
            variant="success"
            outline
            className="mt-3"
            onClick={handleCreateSegment}
          />
        )}
      </div>
    </div>
  );
};
