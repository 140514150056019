
import _ from "lodash";
import { useEffect, useState } from "react";
import { getDetailCurrentReport } from "../../../apis";
import { Loading } from "../../Loading/String/Loading";
import { PreviewForm } from "../PreviewForm/PreviewForm"
import { formStarter } from "../PreviewForm/formStarter";
import { useSelector } from "react-redux";
// import { Accordion } from "react-bootstrap";

export const NewReport = () => {

  const {gTheme} = useSelector(state=>state.theme);
  
  const [formLoaded, setFormLoaded] = useState(null);
  // const [formIdentifier, setFormIdentifier] = useState(null);
  // const [formDescription, setFormDescription] = useState(null);
  const [steps, setSteps] = useState(null);
  const [schemaState, setSchemaState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [constraints, setConstraints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetailCurrentReport()
    .then(resp=>{
      setFormLoaded(resp);
    })
    .catch(console.log)
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (formLoaded) {
      formStarter(formLoaded, "normal").
      then(({ initSteps, initSchemaState, initFormData, initIsValid, initConstraints })=>{
        setSteps(initSteps);
        setSchemaState(initSchemaState);
        setFormData(initFormData);
        setIsValid(initIsValid);
        setConstraints(initConstraints);
        setIsLoading(false);
      })
      .catch(console.log)
    }
  }, [formLoaded]);


  return (
    <div id="settings" className={`report-grid page ${gTheme!=="light"?"dark-mode":""}`}>
      {
        isLoading 
        ? <Loading />
        : <PreviewForm
            steps={steps}
            schemaState={schemaState}
            setSchemaState={setSchemaState}
            formData={formData}
            setFormData={setFormData}
            isValid={isValid}
            setIsValid={setIsValid}
            constraints={constraints}
            setConstraints={setConstraints}
            showButtons={true}
            stepClick={false}
          />
      }

      {/* <Accordion>
          <Accordion.Item eventKey="schema">
            <Accordion.Header>Schema</Accordion.Header>
            <Accordion.Body><pre>{JSON.stringify(schemaState,null,2)}</pre></Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="data">
            <Accordion.Header>Data</Accordion.Header>
            <Accordion.Body><pre>{JSON.stringify(schemaState,null,2)}</pre></Accordion.Body>
          </Accordion.Item>
      </Accordion> */}

    </div>
  )
}
