import formStarterES from "./PreviewForm/locales/es.json";
import formStarterEN from "./PreviewForm/locales/en.json";
// import formStarterES from "../../translations/ES/forms.json";
// import formStarterEN from "../../translations/EN/forms.json";

const lang = localStorage.getItem("lang");

export const isForLanding = false;

export const INPUT_TYPE_TEXT = "string"
export const INPUT_TYPE_NUMBER = "number"
export const INPUT_TYPE_TEXTAREA = "textarea"
export const INPUT_TYPE_CHECKBOX = "checkbox"
export const INPUT_TYPE_CHECKBOX_CONDITIONAL = "checkbox-conditional"
export const INPUT_TYPE_FILE = "file"
export const INPUT_TYPE_DATE = "date"
export const INPUT_TYPE_DATE_RANGE = "date-range"
export const INPUT_TYPE_SUBJECT = "subject"
export const INPUT_TYPE_DESCRIPTION = "description"
export const INPUT_TYPE_EMAIL = "email"
export const INPUT_TYPE_INVOLVED = "involved"

export const CATALOGUES_PREFIX = `catalog`;
export const INPUT_TYPE_CATALOGUE_SELECT = `${CATALOGUES_PREFIX}-select`
export const INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL = `${CATALOGUES_PREFIX}-select-conditional`
export const INPUT_TYPE_CATALOGUE_RADIO = `${CATALOGUES_PREFIX}-radio`
export const INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION = `${CATALOGUES_PREFIX}-radio-description`
export const INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL = `${CATALOGUES_PREFIX}-radio-conditional`
export const INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL = `${CATALOGUES_PREFIX}-radio-description-conditional`
export const INPUT_TYPE_CATALOGUE_ERROR = `${CATALOGUES_PREFIX}-error`


export const especialCatalogues = [
  {
    catalogue: "business_units",
    isOwn: true,
    nestNum: 0,
    query: "bu"
  },
];

export const isEspecialCatalogue = (catalogue) => {
  const isEspecialCatalogue = especialCatalogues.find(item=>item.catalogue===catalogue)
  if (isEspecialCatalogue) {
    return true
  }
  return false;
}

export const optionsInvolved = () => {
  const language = lang === "es" ? formStarterES : formStarterEN;
  return [
    { label: language.formPreview.option_victim, value: "victim" },
    { label: language.formPreview.option_witness, value: "witness" },
    { label: language.formPreview.option_offender, value: "offender" },
  ]
}