import { useState } from "react";
import { useSelector } from "react-redux";
import Icon from "../../Icon/Icon";
import "./EditTool.scss";

export const MCEditTool = ({
  className = "",
  label = "Button",
  variant = "",
  outline = false,
  width,
  size,
  icon,
  style,
  onClick = () => {},
  onSave = () => {},
  disabled = false,
  loading = false,
  children,
  ...rest
}) => {
  const [edit, setEdit] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const editSubmit = (e) => {
    setEdit((prevEdit) => {
      const newEdit = !prevEdit;
      onClick(e, newEdit);
      setShowSave(newEdit);
      return newEdit;
    });
  };

  const saveSubmit = (e) => {
    onSave(e);
    setShowSave(false);
  };

  return (
    <div className={`edit-tool ${className}`} {...rest}>
      {loading && <div className="loading-overlay">...</div>}
      <div
        onClick={editSubmit}
        className={`edit-tool-box ${edit ? "fixed" : ""}`}
        style={style}
      >
        {edit ? <Icon name="cancel_square" /> : <Icon name="outline_edit" />}
        {showSave && (
          <div onClick={saveSubmit} className="save-tool-box">
            <Icon name="check_square" />
          </div>
        )}
      </div>

      {children(edit)}
    </div>
  );
};
