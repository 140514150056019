import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { App } from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "./translations/index";
import { SnackbarProvider } from "notistack";
import {
  ethicsSuccess,
  ethicsError,
  ethicsInfo,
  ethicsWarning,
} from "./components/Flags/AlertFlags";
import useCustomTheme from "./theme/themeMUI/useCustomTheme";
import { ThemeProvider } from "@mui/material";

const container = document.getElementById("root");
const root = createRoot(container);

const Main = () => {
  const theme = useCustomTheme();
  
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        Components={{
          success: ethicsSuccess,
          error: ethicsError,
          warning: ethicsWarning,
          info: ethicsInfo,
        }}
        maxSnack={5}
        autoHideDuration={5000}
      >
        <App tab="home" />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Main />
    </I18nextProvider>
  </Provider>
);
