import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import { useNavigate } from "react-router-dom";
import './Mobilestyles.scss';
import { login, retrieveCatalogueByPath } from "../../apis";
import { searchNavbarAPI } from '../../apis/apiReports';
import {
    catalogByPart,
    listCatalogues,
} from "../../apis/apiCatalogues";
import { Row, Col } from 'react-bootstrap';
import {
    MCCheckbox,
    MCLabel,
} from "../MainComponents";
import { useTranslation } from "react-i18next";
import {
    SearchIcon2,
    OutlineMessages3,
    Monitor,
    Call,
    OutlineSms,
    OutlineMessages2,
    Mobile,
    Grammerly,
    CdIcon,
    OutlineSmsTracking,
    OutlineWhatsapp,
} from "../Icon";
import { parseDateToISOString } from "../../helpers/dateTransform";

import { utilitiesDashboard } from "../../apis/apiDashboard";

import { MCButton } from "../MainComponents";

import MultipleSelectChip from '../MainComponents/ChipSelect/ChipSelect';

// import MultipleSelectDefault from './MultipleSelectDefault';
import MultipleSelectDefault from '../MainComponents/MUISelectDefault/MultipleSelectDefault';
import MultipleSelectDefaultUno from './MobileMultipleSelectDefault';


import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';







const MobileSimpleSearch = ({ placeholder, onChange, options, value, onKeyDown, searchTermSecondary, onSubmit = () => { }, ...rest }, props) => {
    const [t] = useTranslation("report");
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(false);
    const modalRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchTerm, setSearchTerm] = useState(searchTermSecondary);
    const [searchResults, setSearchResults] = useState([]);
    const [changeLabel, setChangeLabel] = useState(false);
    const [kindOfEntrywaysValue, setKindOfEntrywaysValue] = useState([]);
    const [kindOfStatusValue, setKindOfStatusValue] = useState([]);
    const [kindOfSeverity, setKindOfSeverity] = useState([]);
    const [kindOfSeverityValue, setKindOfSeverityValue] = useState([]);
    const [stateData, setStateData] = useState({ utilities: [] });
    const location = useLocation();





    useEffect(() => {
        // console.log("SEEARCH TERM", searchTermSecondary);
        setSearchTerm(searchTermSecondary)
    }, [searchTerm, searchTermSecondary]);


    const kindOfEntryways = [
        {
            value: "WEBSITE",
            label: t("entryways.website"),
            key: "entryways.website"
        },
        {
            value: "PHONE",
            label: t("entryways.phone"),
            key: "entryways.phone"
        },
        {
            value: "EMAIL",
            label: t("entryways.email"),
            key: "entryways.email"
        },
        {
            value: "CHAT",
            label: t("entryways.chat"),
            key: "entryways.chat"
        },
        {
            value: "WHATSAPP",
            label: t("entryways.whatsapp"),
            key: "entryways.whatsapp"
        },
        {
            value: "APP",
            label: t("entryways.app"),
            key: "entryways.app"
        },
        {
            value: "OUTDOOR",
            label: t("entryways.outdoor"),
            key: "entryways.outdoor"
        },
        {
            value: "OTHER",
            label: t("entryways.other"),
            key: "entryways.other"
        },
    ];

    const optionsStatus = [
        {
            value: "new",
            label: t("status.new"),
            key: "status.new"
        },
        {
            value: "process",
            label: t("status.process"),
            key: "status.process"
        },
        {
            value: "finish",
            label: t("status.finish"),
            key: "status.finish"
        },
        {
            value: "pending",
            label: t("status.pending"),
            key: "status.pending"
        },
        {
            value: "closed",
            label: t("status.closed"),
            key: "status.closed"
        },
        {
            value: "draft",
            label: t("status.draft"),
            key: "status.draft"
        },
    ];

    const iconEntryway = {
        WEBSITE: {
            element: <Monitor className="mobile-icon-indicator" />,
        },
        PHONE: {
            element: <Call className="mobile-icon-indicator" />,
        },
        EMAIL: {
            element: <OutlineSmsTracking className=" mobile-icon-indicator" />,
            // element: <OutlineSms className=" mobile-icon-indicator" />,
        },
        CHAT: {
            element: <OutlineMessages3 className=" mobile-icon-indicator" />,
        },
        WHATSAPP: {
            element: <OutlineWhatsapp className=" mobile-icon-indicator" />,
        },
        APP: {
            element: <Mobile className="mobile-icon-indicator" />,
        },
        OUTDOOR: {
            element: <Grammerly className=" mobile-icon-indicator" />,
        },
        OTHER: {
            element: <CdIcon className="mobile-icon-indicator" />,
        },
    };



    useEffect(() => {
        // Cierra el modal cuando la URL cambie
        setSearchTerm('');
        setShowDetails(false);
        setKindOfSeverityValue([]);
        setKindOfEntrywaysValue([]);
        setKindOfStatusValue([]);
    }, [location, setShowDetails]);





    //Maneja los cambios de estado en los selects 
    const handleKindOfEntrywaysChange = (selectedOptions) => {
        setChangeLabel(true);
        const selectedValue = selectedOptions.map(options => options.value);
        setKindOfEntrywaysValue(selectedValue);
    };


    const handleKindOfSeverityChange = (event) => {
        setChangeLabel(true);
        const selectedValue = event.target.value;
        // Actualiza el estado aquí según lo necesites
        console.log("Selected Value: ", selectedValue);
        setKindOfSeverityValue((prevValues) => {
            if (prevValues.includes(selectedValue)) {
                return prevValues.filter((value) => value !== selectedValue);
            } else {
                return [...prevValues, selectedValue];
            }
        });
    };
    console.log(kindOfSeverityValue);

    const handleKindOfStatusChange = (selectedOptions) => {
        setChangeLabel(true);
        const selectedValue = selectedOptions.map(options => options.value);
        setKindOfStatusValue(selectedValue);
    };
    // const handleKindOfStatusChange = (event) => {
    //     setChangeLabel(true);
    //     const selectedValue = event.target.value;
    //     // Actualiza el estado aquí según lo necesites
    //     // console.log("Selected Value: ", selectedValue);
    //     setKindOfStatusValue((prevValues) => {
    //         if (prevValues.includes(selectedValue)) {
    //             return prevValues.filter((value) => value !== selectedValue);
    //         } else {
    //             return [...prevValues, selectedValue];
    //         }
    //     });
    // };

    // const handleKindOfSeverityChange = (selectedOptions) => {
    //     setChangeLabel(true);
    //     const selectedValue = selectedOptions.map(options => options.value);
    //     setKindOfSeverityValue(selectedValue);
    // };


    const transformData = (data, key = "path") => {
        return data.map((item) => {
            const path = item[key];
            return {
                value: path,
                label: item.label,
            };
        });
    };


    const getOptionsFromElement = (element) => {
        return element.childs.map((item) => {
            const valueParts = item.path.split("::");
            const value = valueParts.length > 1 ? valueParts[valueParts.length - 1] : item.path;

            const containsOWN = item.path.includes("OWN");

            return {
                label: item.locales[t("lang")] ? item.locales[t("lang")] : item.label,
                value: value,
                containsOWN: containsOWN
            };
        });
    };

    const getElementFromUtilities = (path) => {
        if (!stateData.utilities || !Array.isArray(stateData.utilities)) {
            console.warn(`Utilities not found in stateData or stateData.utilities is not an array.`);
            return [];
        }

        const elements = stateData.utilities.filter((item) => item.path === path);
        if (elements.length === 0) {
            console.warn(`Elements with path ${path} not found.`);
        }
        return elements;
    };

    const getOptionsFromElementUtilities = (path) => {
        const elements = getElementFromUtilities(path);
        let allOptions = [];


        elements.forEach((element) => {
            const options = getOptionsFromElement(element);
            allOptions = allOptions.concat(options);
        });

        return allOptions;
    };




    useEffect(() => {
        getCatalogue("/", "reportClassification");
        getCatalogue("type::grievance", "kindOfGrievance");
        getCatalogue("/", "kindOfSeverity", "RD-102106");
    }, []);
    ////////////////////////////////////////////////////////////////
    // CALL TO APIS
    ////////////////////////////////////////////////////////////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await utilitiesDashboard();
                setStateData(response);
            } catch (error) {
                console.error("Error fetching utilities dashboard data: ", error);
            }
        };
        fetchData();
    }, []);






    const getCatalogue = async (
        path = "/",
        origin = "reportClassification",
        catalogue = "RC-100",
        is_own = false
    ) => {
        const response = await retrieveCatalogueByPath({ is_own, catalogue, path });
        if (response.status === 200) {
            if (origin === "kindOfSeverity") {
                setKindOfSeverity(transformData(response.data.data, "path_locales"));
            }
        }
        return [];
    };




    const searchAndFilter = async () => {


        const data = {
            entryway: `${kindOfEntrywaysValue}`,
            status: `${kindOfStatusValue}`,
            folio: searchTerm,
            severity: `${kindOfSeverityValue}`,
        };
        // console.log("DATA", data);
        try {
            const response = await searchNavbarAPI(data);
            const limitedResults = response.results.reports.slice(0, 10);
            setSearchResults(limitedResults);

        } catch (error) {
            console.error(error);
        }

    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                searchAndFilter();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [kindOfEntrywaysValue, kindOfStatusValue, searchTerm, kindOfSeverityValue]);


    //aply filters and redirect

    useEffect(() => {
        searchAndFilter();
        // }, [searchTerm, kindOfEntrywaysValue, kindOfStatusValue, kindOfSeverityValue]);
        // }, [searchTerm]);
    }, []);
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const goToReport = (result) => {
        navigate(`/reports/${result.id}`)
        setSearchTerm('');
        setShowDetails(false);
        setKindOfSeverityValue([]);
        setKindOfEntrywaysValue([]);
        setKindOfStatusValue([]);
    };




    return (
        <div>
            <>
                <div className="mobile-search-details-searcher" ref={modalRef}>
                    <div className="mobile-search-details-section-searcher">
                        <Row >
                            <Col xs={12} md={12} xxl={12} >
                                <div className="mobile-search-details-content" >
                                    <div className="mobile-search-details-rows" >
                                        <div className='title-design'>
                                            {changeLabel === false ? (
                                                <p>{t("searchBar.last_report_received")}</p>
                                            ) : (
                                                <p>{t("searchBar.related_reports")}</p>
                                            )}
                                        </div>
                                        <div className="mobile-search-details-divider"></div>
                                        {searchResults.map((result, index) => (
                                            <Row key={index}
                                                onClick={() => goToReport(result)}
                                                className={`mobile-search-details-row ${selectedItem === result.id ? 'selected' : ''}`}
                                                >
                                                <Col xs={2} md={2} xxl={2}>
                                                    <div className={"mobile-search-details-column-icon" + result.status.current}>
                                                        {iconEntryway[result.entryway]?.element}
                                                    </div>
                                                </Col>
                                                <Col xs={10} md={10} xxl={10}>
                                                    <Row
                                                    >

                                                        <Col xs={8} md={6} xxl={8} >
                                                            <div className="mobile-search-details-column-folio">

                                                                <p>{result.folio}</p>
                                                            </div>
                                                        </Col>

                                                        <Col xs={4} md={6} xxl={3} >
                                                            <div className="mobile-search-details-column-tracking">
                                                                <p>{result.tracking_code}</p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={12} xxl={3} >
                                                            <div className="mobile-search-details-column-subject-container">
                                                                <p>{result.subject}</p>
                                                            </div>
                                                        </Col>
                                                        {/* </div> */}
                                                    </Row>
                                                </Col>

                                                

                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} xxl={12} className="mobile-search-results-section">
                                <div>
                                    <div className="mobile-section" >
                                        <div className="mobile-scroll-container">
                                            <div className="mobile-severity">
                                                <h6>{t("searchBar.filterByStatus")}</h6>
                                                <FormGroup>
                                                    <div className="checkbox-container">
                                                        {Object.keys(kindOfSeverity).map(key => (
                                                            <FormControlLabel
                                                                key={key}
                                                                control={
                                                                    <Checkbox
                                                                        checked={kindOfSeverityValue.includes(kindOfSeverity[key].value)}
                                                                        onChange={handleKindOfSeverityChange}
                                                                        value={kindOfSeverity[key].value}
                                                                        sx={{
                                                                            color: '#009ed7',
                                                                            '&.Mui-checked': {
                                                                                color: '#009ed7',
                                                                            },
                                                                            fontSize: '12px',
                                                                            fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                                                                            fontWeight: 700
                                                                        }}
                                                                    />
                                                                }
                                                                label={kindOfSeverity[key].label}
                                                                className="mobile-custom-form-control-label"
                                                            />
                                                        ))}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="mobile-entryway" >
                                                <MultipleSelectDefaultUno
                                                    title={t("searchBar.filterByEntryWay")}
                                                    options={kindOfEntryways}
                                                    onChange={handleKindOfEntrywaysChange}
                                                />
                                            </div>
                                            <div className="mobile-entry-medium">
                                                <MultipleSelectDefaultUno
                                                    title={t("searchBar.report_status")}
                                                    options={optionsStatus}
                                                    onChange={handleKindOfStatusChange}
                                                />
                                            </div>
                                            <div>
                                                <MCButton
                                                    label={t("searchBar.apply_filter")}
                                                    variant="primary"
                                                    width={180}
                                                    onClick={() => {
                                                        searchAndFilter();
                                                        setChangeLabel(true);
                                                    }}
                                                    className="mobile-apply-filters"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </div>
            </>

        </div>
    );
}


export default MobileSimpleSearch;
