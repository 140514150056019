import Icon from "./Icon";
export const RemoveFromPlaylist = (props) => (
  <Icon {...props} name="remove_from_playlist" />
);
export const OutlineDocumentText3 = (props) => (
  <Icon {...props} name="outline_document_text_3" />
);

//MONEY:
export const WalletAdd = (props) => <Icon {...props} name="wallet_add" />;
export const ReceiptItem = (props) => <Icon {...props} name="receipt_item" />;
export const EmptyWallet = (props) => <Icon {...props} name="empty_wallet" />;
export const ReceiptDiscount = (props) => (
  <Icon {...props} name="receipt_discount" />
);
export const EmptyWalletRemove = (props) => (
  <Icon {...props} name="empty_wallet_remove" />
);
export const EmptyWalletAdd = (props) => (
  <Icon {...props} name="empty_wallet_add" />
);
export const ReceiptMinus = (props) => <Icon {...props} name="receipt_minus" />;
export const WalletSearch = (props) => <Icon {...props} name="wallet_search" />;
export const Strongbox = (props) => <Icon {...props} name="strongbox" />;
export const WalletTick = (props) => <Icon {...props} name="wallet_tick" />;
export const ReceiptAdd = (props) => <Icon {...props} name="receipt_add" />;
export const OutlineMoney = (props) => <Icon {...props} name="outline_money" />;
export const EmptyWalletTime = (props) => (
  <Icon {...props} name="empty_wallet_time" />
);

export const TransactionMinus = (props) => (
  <Icon {...props} name="transaction_minus" />
);
export const OutlineMoney2 = (props) => (
  <Icon {...props} name="outline_money_2" />
);
export const EmptyWalletChange = (props) => (
  <Icon {...props} name="empty_wallet_change" />
);
export const OutlineMoney3 = (props) => (
  <Icon {...props} name="outline_money_3" />
);
export const PercentageSquare = (props) => (
  <Icon {...props} name="percentage_square" />
);
export const OutlineMoney4 = (props) => (
  <Icon {...props} name="outline_money_4" />
);
export const PercentageCircle = (props) => (
  <Icon {...props} name="percentage_circle" />
);
export const ChartSquareMoney = (props) => (
  <Icon {...props} name="chart_square_money" />
);

export const OutlineMoneys = (props) => (
  <Icon {...props} name="outline_moneys" />
);
export const OutlineCoin = (props) => <Icon {...props} name="outline_coin" />;
export const ReceiptEdit = (props) => <Icon {...props} name="receipt_edit" />;
export const MoneyTick = (props) => <Icon {...props} name="money_tick" />;
export const DiscountShape = (props) => (
  <Icon {...props} name="discount_shape" />
);
export const ReceiptSearch = (props) => (
  <Icon {...props} name="receipt_search" />
);
export const MoneyAdd = (props) => <Icon {...props} name="money_add" />;
export const ConvertCard = (props) => <Icon {...props} name="convert_card" />;
export const DiscountCircle = (props) => (
  <Icon {...props} name="discount_circle" />
);
export const OutlineWallet = (props) => (
  <Icon {...props} name="outline_wallet" />
);
export const MoneyRemove = (props) => <Icon {...props} name="money_remove" />;

export const WalletMoney = (props) => <Icon {...props} name="wallet_money" />;
export const CardRemove = (props) => <Icon {...props} name="card_remove" />;
export const OutlineCards = (props) => <Icon {...props} name="outline_cards" />;
export const MoneyForbiden = (props) => (
  <Icon {...props} name="money_forbiden" />
);
export const OutlineWallet3 = (props) => (
  <Icon {...props} name="outline_wallet_3" />
);
export const CardTick = (props) => <Icon {...props} name="card_tick" />;
export const OutlineReceipt2 = (props) => (
  <Icon {...props} name="outline_receipt_2" />
);
export const OutlineReceipt = (props) => (
  <Icon {...props} name="outline_receipt" />
);
export const MoneyChange = (props) => <Icon {...props} name="money_change" />;
export const WalletRemove = (props) => <Icon {...props} name="wallet_remove" />;
export const MoneyTime = (props) => <Icon {...props} name="money_time" />;

export const ReceiptText = (props) => <Icon {...props} name="receipt_text" />;
export const OutlineStrongbox = (props) => (
  <Icon {...props} name="outline_strongbox" />
);
export const OutlineWallet2 = (props) => (
  <Icon {...props} name="outline_wallet_2" />
);
export const SecurityCard = (props) => <Icon {...props} name="security_card" />;
export const MoneyRecive = (props) => <Icon {...props} name="money_recive" />;
export const MoneySend = (props) => <Icon {...props} name="money_send" />;
export const OutlineArchive = (props) => (
  <Icon {...props} name="outline_archive" />
);
export const OutlineTag = (props) => <Icon {...props} name="outline_tag" />;
export const OutlineTag2 = (props) => <Icon {...props} name="outline_tag_2" />;
export const OutlineCoin2 = (props) => (
  <Icon {...props} name="outline_coin_2" />
);
export const OutlineDocument = (props) => (
  <Icon {...props} name="outline_document" />
);
export const Wallet4 = (props) => <Icon {...props} name="wallet_4" />;
export const CardAdd = (props) => <Icon {...props} name="card_add" />;
export const CardRemove2 = (props) => <Icon {...props} name="card_remove_2" />;
export const CardTick2 = (props) => <Icon {...props} name="card_tick_2" />;
export const CardSlash = (props) => <Icon {...props} name="card_slash" />;
export const CardPos = (props) => <Icon {...props} name="card_pos" />;
export const Card = (props) => <Icon {...props} name="card" />;
export const CardReceive = (props) => <Icon {...props} name="card_receive" />;
export const CardSend = (props) => <Icon {...props} name="card_send" />;
export const CardEdit = (props) => <Icon {...props} name="card_edit" />;
export const WalletMinus = (props) => <Icon {...props} name="wallet_minus" />;
export const WalletAdd2 = (props) => <Icon {...props} name="wallet_add_2" />;
export const DollarSquare = (props) => <Icon {...props} name="dollar_square" />;
export const DollarCircle = (props) => <Icon {...props} name="dollar_circle" />;
export const Receipt1 = (props) => <Icon {...props} name="receipt_1" />;
export const Receipt3 = (props) => <Icon {...props} name="receipt_3" />;
export const ReceiptDisscount = (props) => (
  <Icon {...props} name="receipt_disscount" />
);
export const Ticket = (props) => <Icon {...props} name="ticket" />;
export const TicketStar = (props) => <Icon {...props} name="ticket_star" />;
export const TicketExpired = (props) => (
  <Icon {...props} name="ticket_expired" />
);
export const Ticket2 = (props) => <Icon {...props} name="ticket_2" />;
export const TicketDiscount = (props) => (
  <Icon {...props} name="ticket_discount" />
);
export const Math = (props) => <Icon {...props} name="math" />;

//TYPE-PARAGRAPH-CHARACTER:
export const TextalingLeft = (props) => (
  <Icon {...props} name="textaling_left" />
);
export const TextalingCenter = (props) => (
  <Icon {...props} name="textaling_center" />
);
export const TextalingRight = (props) => (
  <Icon {...props} name="textaling_right" />
);
export const TextalingJustifyRight = (props) => (
  <Icon {...props} name="textaling_justify_right" />
);
export const TextalingJustifyCenter = (props) => (
  <Icon {...props} name="textaling_justify_center" />
);
export const TextalingJustifyLeft = (props) => (
  <Icon {...props} name="textaling_justify_left" />
);
export const FirstLine = (props) => <Icon {...props} name="first_line" />;
export const Paperclip2 = (props) => <Icon {...props} name="paperclip_2" />;
export const Paperclip = (props) => <Icon {...props} name="paperclip" />;
export const Link = (props) => <Icon {...props} name="link" />;
export const Link1 = (props) => <Icon {...props} name="link_1" />;
export const Link2 = (props) => <Icon {...props} name="link_2" />;
export const TextBlock = (props) => <Icon {...props} name="textblock" />;
export const TextItalic = (props) => <Icon {...props} name="textitalic" />;
export const Text = (props) => <Icon {...props} name="text" />;
export const Smallcaps = (props) => <Icon {...props} name="smallcaps" />;
export const TextBold = (props) => <Icon {...props} name="textbold" />;
export const Underline = (props) => <Icon {...props} name="underline" />;
export const Pharagraphspacing = (props) => (
  <Icon {...props} name="pharagraphspacing" />
);
export const Maximize = (props) => <Icon {...props} name="maximize" />;
export const Eraser = (props) => <Icon {...props} name="eraser" />;
export const Square = (props) => <Icon {...props} name="square" />;
export const AttachCircle = (props) => <Icon {...props} name="attach_circle" />;
export const QuoteUp = (props) => <Icon {...props} name="quote_up" />;
export const QuoteDown = (props) => <Icon {...props} name="quote_down" />;
export const QuoteUpSquare = (props) => (
  <Icon {...props} name="quoteup_square" />
);
export const QuoteDownSquare = (props) => (
  <Icon {...props} name="quotedown_square" />
);
export const QuoteDownCircle = (props) => (
  <Icon {...props} name="quotedown_circle" />
);
export const QuoteUpCircle = (props) => (
  <Icon {...props} name="quoteup_circle" />
);
export const Link3 = (props) => <Icon {...props} name="link_3" />;
export const LinkSquare = (props) => <Icon {...props} name="link_square" />;
export const LinkCircle = (props) => <Icon {...props} name="link_circle" />;
export const Translate = (props) => <Icon {...props} name="translate" />;
export const LenguageSquare = (props) => (
  <Icon {...props} name="lenguage_square" />
);
export const LenguageCircle = (props) => (
  <Icon {...props} name="lenguage_circle" />
);

//Weather
export const Moon = (props) => <Icon {...props} name="moon" />;
export const Sun = (props) => <Icon {...props} name="sun" />;
export const Cloud = (props) => <Icon {...props} name="cloud" />;
export const CloudPlus = (props) => <Icon {...props} name="cloud_plus" />;
export const CloudMinus = (props) => <Icon {...props} name="cloud_minus" />;
export const CloudCross = (props) => <Icon {...props} name="cloud_cross" />;
export const CloudSunny = (props) => <Icon {...props} name="cloud_sunny" />;
export const CloudNotif = (props) => <Icon {...props} name="cloud_notif" />;
export const CloudDrizzle = (props) => <Icon {...props} name="cloud_drizzle" />;
export const CloudSnow = (props) => <Icon {...props} name="cloud_snow" />;
export const CloudLightning = (props) => (
  <Icon {...props} name="cloud_lightning" />
);
export const OutlineFlash2 = (props) => <Icon {...props} name="outline_flash2" />;
export const OutlineIcon = (props) => <Icon {...props} name="outline_icon" />;
export const OutlineWind = (props) => <Icon {...props} name="outline_wind" />;
export const Wind2 = (props) => <Icon {...props} name="wind_2" />;
export const CloudFog = (props) => <Icon {...props} name="cloud_fog" />;
export const SunFog = (props) => <Icon {...props} name="sun_fog" />;
export const OutlineSun = (props) => <Icon {...props} name="outline_sun" />;

//Files
export const Folder = (props) => <Icon {...props} name="folder" />;
export const FolderCross = (props) => <Icon {...props} name="folder_cross" />;
export const FolderAdd = (props) => <Icon {...props} name="folder_add" />;
export const FolderMinus = (props) => <Icon {...props} name="folder_minus" />;
export const FolderFavorite = (props) => (
  <Icon {...props} name="folder_favorite" />
);
export const Folder_2 = (props) => <Icon {...props} name="folder_2" />;
export const FolderOpen = (props) => <Icon {...props} name="folder_open" />;
export const FolderCloud = (props) => <Icon {...props} name="folder_cloud" />;

//Video, Audio, Image
export const Microphone = (props) => <Icon {...props} name="microphone" />;
export const MicrophoneSlash = (props) => (
  <Icon {...props} name="microphone_slash" />
);
export const Microphone2 = (props) => <Icon {...props} name="microphone_2" />;
export const MicrophoneSlash2 = (props) => (
  <Icon {...props} name="microphone_slash_2" />
);
export const VolumeCross = (props) => <Icon {...props} name="volume_cross" />;
export const VolumeSlash = (props) => <Icon {...props} name="volume_slash" />;
export const VolumeUp = (props) => <Icon {...props} name="volume_up" />;
export const VolumeLow2 = (props) => <Icon {...props} name="volume_low_2" />;
export const VolumeLow = (props) => <Icon {...props} name="volume_low" />;
export const VolumeHigh = (props) => <Icon {...props} name="volume_high" />;
export const VolumeMute = (props) => <Icon {...props} name="volume_mute" />;
export const OutlineVideo = (props) => <Icon {...props} name="outline_video" />;
export const VideoSlash = (props) => <Icon {...props} name="video_slash" />;
export const VideoSquare = (props) => <Icon {...props} name="video_square" />;
export const VideoCircle = (props) => <Icon {...props} name="video_circle" />;
export const Gallery = (props) => <Icon {...props} name="gallery" />;
export const GalleryFavorite = (props) => (
  <Icon {...props} name="gallery_favorite" />
);
export const GallerySlash = (props) => <Icon {...props} name="gallery_slash" />;
export const GalleryTick = (props) => <Icon {...props} name="gallery_tick" />;
export const GalleryImport = (props) => (
  <Icon {...props} name="gallery_import" />
);
export const GalleryExport = (props) => (
  <Icon {...props} name="gallery_export" />
);

export const GalleryAdd = (props) => <Icon {...props} name="gallery_add" />;
export const GalleryRemove = (props) => (
  <Icon {...props} name="gallery_remove" />
);
export const GalleryEdit = (props) => <Icon {...props} name="gallery_edit" />;
export const Image = (props) => <Icon {...props} name="image" />;
export const Sreenmirroring = (props) => (
  <Icon {...props} name="sreenmirroring" />
);
export const Camera = (props) => <Icon {...props} name="camera" />;
export const CameraSlash = (props) => <Icon {...props} name="camera_slash" />;
export const Play = (props) => <Icon {...props} name="play" />;
export const Record = (props) => <Icon {...props} name="record" />;
export const Stop = (props) => <Icon {...props} name="stop" />;
export const MusicNote = (props) => <Icon {...props} name="music_note" />;
export const Music = (props) => <Icon {...props} name="music" />;
export const VideoVertical = (props) => (
  <Icon {...props} name="video_vertical" />
);
export const VideoHorizontal = (props) => (
  <Icon {...props} name="video_horizontal" />
);
export const Subtitle = (props) => <Icon {...props} name="subtitle" />;
export const PlayCircle = (props) => <Icon {...props} name="play_circle" />;
export const RecordCircle = (props) => <Icon {...props} name="record_circle" />;
export const StopCircle = (props) => <Icon {...props} name="stop_circle" />;
export const PauseCircle = (props) => <Icon {...props} name="pause_circle" />;
export const Pause = (props) => <Icon {...props} name="pause" />;
export const Scissor = (props) => <Icon {...props} name="scissor" />;
export const Backward = (props) => <Icon {...props} name="backward" />;
export const Forward = (props) => <Icon {...props} name="forward" />;
export const Previous = (props) => <Icon {...props} name="previous" />;

export const Next = (props) => <Icon {...props} name="netx" />;
export const Radio = (props) => <Icon {...props} name="radio" />;
export const MusicCircle = (props) => <Icon {...props} name="music_circle" />;
export const PlayCricle = (props) => <Icon {...props} name="play_cricle" />;
export const PlayRemove = (props) => <Icon {...props} name="play_remove" />;
export const PlayAdd = (props) => <Icon {...props} name="play_add" />;
export const Backward5 = (props) => <Icon {...props} name="backward_5" />;
export const Backward10 = (props) => <Icon {...props} name="backward_10" />;
export const Backward15 = (props) => <Icon {...props} name="backward_15" />;
export const Forward5 = (props) => <Icon {...props} name="forward_5" />;
export const Forward10 = (props) => <Icon {...props} name="forward_10" />;
export const Forward15 = (props) => <Icon {...props} name="forward_15" />;
export const MusicFilter2 = (props) => (
  <Icon {...props} name="music_filter_2" />
);
export const MusicDasboard = (props) => (
  <Icon {...props} name="music_dasboard" />
);
export const SquareRemove = (props) => <Icon {...props} name="square_remove" />;
export const SaquareAdd = (props) => <Icon {...props} name="saquare_add" />;
export const SquareSearh = (props) => <Icon {...props} name="square_searh" />;
export const RepeatMusic = (props) => <Icon {...props} name="repeat_music" />;
export const RepeatOne = (props) => <Icon {...props} name="repeat_one" />;
export const Shuffle = (props) => <Icon {...props} name="shuffle" />;

export const Devices = (props) => <Icon {...props} name="devices" />;
export const Playlist = (props) => <Icon {...props} name="playlist" />;
export const Library2 = (props) => <Icon {...props} name="library_2" />;
export const MusicSquare = (props) => <Icon {...props} name="music_square" />;
export const AudioSquare = (props) => <Icon {...props} name="audio_square" />;
export const NoteSquare = (props) => <Icon {...props} name="note_square" />;
export const MiniNoteSquare = (props) => (
  <Icon {...props} name="mini_note_square" />
);
export const VideoAdd = (props) => <Icon {...props} name="video_add" />;
export const VideoRemove = (props) => <Icon {...props} name="video_remove" />;
export const VideoTick = (props) => <Icon {...props} name="video_tick" />;
export const VideoTime = (props) => <Icon {...props} name="video_time" />;
export const VideoOctagon = (props) => <Icon {...props} name="video_octagon" />;
export const VoiceSquare = (props) => <Icon {...props} name="voice_square" />;
export const VoiceCircle = (props) => <Icon {...props} name="voice_circle" />;
export const MaximizeCircle = (props) => (
  <Icon {...props} name="maximize_circle" />
);

//Arrow
export const Received = (props) => <Icon {...props} name="received" />;
export const Send = (props) => <Icon {...props} name="send" />;
export const Arrow2 = (props) => <Icon {...props} name="arrow_2" />;
export const Arrow3 = (props) => <Icon {...props} name="arrow_3" />;
export const ArrowSquareUp = (props) => (
  <Icon {...props} name="arrow_square_up" />
);
export const ArrowSquareDown = (props) => (
  <Icon {...props} name="arrow_square_down" />
);
export const ArrowSquareLeft = (props) => (
  <Icon {...props} name="arrow_square_left" />
);
export const ArrowSquareRight = (props) => (
  <Icon {...props} name="arrow_square_right" />
);
export const ArrowRight = (props) => <Icon {...props} name="arrow_right" />;
export const ArrowLeft = (props) => <Icon {...props} name="arrow_left" />;
export const ArrowUp4 = (props) => <Icon {...props} name="arrow_up_4" />;
export const ArrowDown = (props) => <Icon {...props} name="arrow_down" />;
export const ArrowUp3 = (props) => <Icon {...props} name="arrow_up_3" />;
export const ArrowDown2 = (props) => <Icon {...props} name="arrow_down_2" />;
export const OutlineArrowRight4 = (props) => (
  <Icon {...props} name="outline_arrow_right_4" />
);
export const Arrowleft3 = (props) => <Icon {...props} name="arrow_left_3" />;

export const ArrowCircleLeft = (props) => (
  <Icon {...props} name="arrow_circle_left" />
);
export const ArrowCircleRight = (props) => (
  <Icon {...props} name="arrow_circle_right" />
);
export const ArrowCircleDown = (props) => (
  <Icon {...props} name="arrow_circle_down" />
);
export const ArrowCircleUp = (props) => (
  <Icon {...props} name="arrow_circle_up" />
);
export const ArrowSwap = (props) => <Icon {...props} name="arrow_swap" />;
export const ArrowSwapHorizontal = (props) => (
  <Icon {...props} name="arrow_swap_horizontal" />
);
export const OutlineUndo = (props) => <Icon {...props} name="outline_undo" />;
export const OutlineRedo = (props) => <Icon {...props} name="outline_redo" />;
export const OutlineRotateLeft = (props) => (
  <Icon {...props} name="outline_rotate_left" />
);
export const OutlineRotateRight = (props) => (
  <Icon {...props} name="outline_rotate_right" />
);
export const OutlineRefresh2 = (props) => (
  <Icon {...props} name="outline_refresh_2" />
);
export const OutlineArrowLeft2 = (props) => (
  <Icon {...props} name="outline_arrow_left_2" />
);
export const OutlineArrowRight5 = (props) => (
  <Icon {...props} name="outline_arrow_right_5" />
);
export const OutlineArrowUp = (props) => (
  <Icon {...props} name="outline_arrow_up" />
);
export const OutlineArrowBottom = (props) => (
  <Icon {...props} name="outline_arrow_bottom" />
);
export const OutlineArrowUp2 = (props) => (
  <Icon {...props} name="outline_arrow_up_2" />
);
export const OutlineArrowRight3 = (props) => (
  <Icon {...props} name="outline_arrow_right_3" />
);
export const OutlineArrowLeft4 = (props) => (
  <Icon {...props} name="outline_arrow_left_4" />
);
export const OutlineArrowDown = (props) => (
  <Icon {...props} name="outline_arrow_down" />
);
export const OutlineConvert = (props) => (
  <Icon {...props} name="outline_convert" />
);
export const OutlineArrangeSquare = (props) => (
  <Icon {...props} name="outline_arrange_square" />
);
export const OutlineArrageSquare2 = (props) => (
  <Icon {...props} name="outline_arrage_square_2" />
);
export const OutlineBackSquare = (props) => (
  <Icon {...props} name="outline_back_square" />
);
export const OutlineForwardSquare = (props) => (
  <Icon {...props} name="outline_forward_square" />
);
export const OutlineRefreshSquare2 = (props) => (
  <Icon {...props} name="outline_refresh_square_2" />
);
export const OutlineRefreshRightSquare = (props) => (
  <Icon {...props} name="outline_refresh_right_square" />
);
export const OutlineRefreshLeftSquare = (props) => (
  <Icon {...props} name="outline_refresh_left_square" />
);
export const OutlineReceiveSquare = (props) => (
  <Icon {...props} name="outline_receive_square" />
);

export const OutlineSendSquare = (props) => (
  <Icon {...props} name="outline_send_square" />
);
export const OutlineSendSquare2 = (props) => (
  <Icon {...props} name="outline_send_square_2" />
);
export const OutlineReceiveSquare2 = (props) => (
  <Icon {...props} name="outline_receive_square_2" />
);
export const outline_receive_square_2 = (props) => (
  <Icon {...props} name="OutlineReceiveSquare2" />
);
export const OutlineLogin2 = (props) => (
  <Icon {...props} name="outline_login_2" />
);
export const OutlineLogout2 = (props) => (
  <Icon {...props} name="outline_logout_2" />
);
export const OutlineImport = (props) => (
  <Icon {...props} name="outline_import" />
);
export const OutlineExport = (props) => (
  <Icon {...props} name="outline_export" />
);
export const OutlineExport2 = (props) => (
  <Icon {...props} name="outlin_export_2" />
);
export const OutlineImport2 = (props) => (
  <Icon {...props} name="outline_import_2" />
);
export const OutlineLogin = (props) => <Icon {...props} name="outline_login" />;
export const OutlineLogout = (props) => (
  <Icon {...props} name="outline_logout" />
);

export const OutlineExport3 = (props) => (
  <Icon {...props} name="outline_export_3" />
);
export const OutlineFrame = (props) => <Icon {...props} name="outline_frame" />;
export const OutlineImport3 = (props) => (
  <Icon {...props} name="outline_import_3" />
);
export const OutlineExport4 = (props) => (
  <Icon {...props} name="outline_export_4" />
);
export const OutlineRefresh = (props) => (
  <Icon {...props} name="outline_refresh" />
);
export const OutlineArrangeCircle = (props) => (
  <Icon {...props} name="outline_arrange_circle" />
);
export const OutlineArrangeCircle2 = (props) => (
  <Icon {...props} name="outline_arrange_circle_2" />
);
export const OutlineRepeat = (props) => (
  <Icon {...props} name="outline_repeat" />
);
export const OutlineRepeatCircle = (props) => (
  <Icon {...props} name="outline_repeat_circle" />
);
export const OutlineRefreshCircle = (props) => (
  <Icon {...props} name="outline_refresh_circle" />
);

//Users
export const OutlineUser = (props) => <Icon {...props} name="outline_user" />;
export const OutlineUserAdd = (props) => (
  <Icon {...props} name="outline_user_add" />
);
export const OutlineUserRemove = (props) => (
  <Icon {...props} name="outline_user_remove" />
);
export const OutlineUserTick = (props) => (
  <Icon {...props} name="outline_user_tick" />
);
export const OutlineUserMinus = (props) => (
  <Icon {...props} name="outline_user_minus" />
);
export const OutlineUserSearch = (props) => (
  <Icon {...props} name="outline_user_search" />
);
export const OutlineUserEdit = (props) => (
  <Icon {...props} name="outline_user_edit" />
);
export const OutlineUserTag = (props) => (
  <Icon {...props} name="outline_user_tag" />
);
export const OutlineUserOctagon = (props) => (
  <Icon {...props} name="outline_user_octagon" />
);
export const OutlineUserCircleAdd = (props) => (
  <Icon {...props} name="outline_user_circle_add" />
);
export const OutlineUserSquare = (props) => (
  <Icon {...props} name="outline_user_square" />
);
export const OutlineFrame2 = (props) => (
  <Icon {...props} name="outline_frame_2" />
);
export const OutlineProfile2User = (props) => (
  <Icon {...props} name="outline_profile_2_user" />
);
export const OutlineProfileRemove = (props) => (
  <Icon {...props} name="outline_profile_remove" />
);
export const OutlineProfileTick = (props) => (
  <Icon {...props} name="outline_profile_tick" />
);
export const OutlineProfileAdd = (props) => (
  <Icon {...props} name="outline_profile_add" />
);
export const OutlineProfileDelete = (props) => (
  <Icon {...props} name="outline_profile_delete" />
);
export const OutlineProfileCircle = (props) => (
  <Icon {...props} name="outline_profile_circle" />
);
export const OutlineTagUser = (props) => (
  <Icon {...props} name="outline_tag_user" />
);
export const OutlinePeople = (props) => (
  <Icon {...props} name="outline_people" />
);

//Essetional
export const TrashCan = (props) => <Icon {...props} name="trash_can" />;
export const EmptyBattery = (props) => <Icon {...props} name="empty_battery" />;
export const OneThirdBattery = (props) => (
  <Icon {...props} name="1_3_battery" />
);
export const TwoThirdBattery = (props) => (
  <Icon {...props} name="2_3_battery" />
);
export const FullBattery = (props) => <Icon {...props} name="full_battery" />;
export const ChargeBattery = (props) => (
  <Icon {...props} name="charge_battery" />
);
export const DisableBattery = (props) => (
  <Icon {...props} name="disable_battery" />
);
export const HomeIcon = (props) => <Icon {...props} name="home" />;
export const HomeIconTwo = (props) => <Icon {...props} name="home_two" />;
export const MoreIcon = (props) => <Icon {...props} name="more" />;
export const CheckIcon = (props) => <Icon {...props} name="check_icon" />;
export const CheckIconCircle = (props) => (
  <Icon {...props} name="check_circle" />
);
export const AddIconCircle = (props) => <Icon {...props} name="add_circle" />;
export const MinusIconCircle = (props) => (
  <Icon {...props} name="minus_circle" />
);
// Outline close circle 
export const CancelIconCircle = (props) => (
  <Icon {...props} name="cancel_circle" />
);
export const MoreIconCircle = (props) => <Icon {...props} name="more_circle" />;
export const InfoCircle = (props) => <Icon {...props} name="info_circle" />;
export const WarningIcon = (props) => <Icon {...props} name="warning" />;
export const AddIcon = (props) => <Icon {...props} name="add" />;
export const MinusIcon = (props) => <Icon {...props} name="minus" />;
export const AddIconSquare = (props) => <Icon {...props} name="add_square" />;
export const MinusIconSquare = (props) => (
  <Icon {...props} name="minus_square" />
);
export const CheckIconSquare = (props) => (
  <Icon {...props} name="check_square" />
);
export const CancelIconSquare = (props) => (
  <Icon {...props} name="cancel_square" />
);
export const CloseIcon = (props) => <Icon {...props} name="close_icon" />;
export const SortIcon = (props) => <Icon {...props} name="sort" />;
export const SendIcon = (props) => <Icon {...props} name="send_essational" />;
export const SendIcon2 = (props) => <Icon {...props} name="send2" />;
export const ShareCircle = (props) => <Icon {...props} name="share_circle" />;
export const OutlineFlash = (props) => <Icon {...props} name="outline_flash" />;
export const CancelFlashIcon = (props) => (
  <Icon {...props} name="flash_cancel" />
);
export const FlashCircle = (props) => <Icon {...props} name="flash_circle" />;
export const CancelIcon = (props) => (
  <Icon {...props} name="cancel_icon_slash" />
);
export const CakeIcon = (props) => <Icon {...props} name="cake_icon" />;
export const Grammerly = (props) => <Icon {...props} name="grammerly" />;
export const CrownKingIcon = (props) => <Icon {...props} name="crown_king" />;
export const CrownQueenIcon = (props) => <Icon {...props} name="crown_queen" />;
export const FlagIcon = (props) => <Icon {...props} name="flag_square" />;
export const FlagIcon2 = (props) => <Icon {...props} name="flag_triangle" />;
export const TrophyIcon = (props) => <Icon {...props} name="trophy" />;
export const HappySquare = (props) => <Icon {...props} name="happy_square" />;
export const HappySquare2 = (props) => <Icon {...props} name="happy_square2" />;
export const SadSquare = (props) => <Icon {...props} name="sad_square" />;
export const TagCancelIcon = (props) => <Icon {...props} name="cancel_tag" />;
export const HamburguerMenu = (props) => (
  <Icon {...props} name="hamburguer_menu" />
);
export const CdIcon = (props) => <Icon {...props} name="cd_icon" />;
export const CircleGraph = (props) => <Icon {...props} name="circle_graph" />;
export const ArchiveIcon = (props) => <Icon {...props} name="archive" />;
export const ChromeIcon = (props) => <Icon {...props} name="chrome" />;
export const InstagramIcon = (props) => <Icon {...props} name="instagram" />;
export const CoffeeCup = (props) => <Icon {...props} name="coffee_cup" />;
export const PetPaw = (props) => <Icon {...props} name="pet_paw" />;
export const HappyCircle = (props) => <Icon {...props} name="happy_circle" />;
export const ReservationBell = (props) => (
  <Icon {...props} name="reservation_bell" />
);
export const PostItCicle = (props) => <Icon {...props} name="postIt_circle" />;
export const ComputingSquare = (props) => (
  <Icon {...props} name="computing_square" />
);
export const VerifyCircle = (props) => <Icon {...props} name="verify_circle" />;
export const InformationCircle = (props) => (
  <Icon {...props} name="information_circle" />
);
export const AutoBright = (props) => <Icon {...props} name="autoBright" />;
export const TriangleChrome = (props) => (
  <Icon {...props} name="triangleChrome" />
);
export const Cube3D = (props) => <Icon {...props} name="cube3D" />;
export const Glasses = (props) => <Icon {...props} name="glasses" />;
export const Discover = (props) => <Icon {...props} name="discover" />;
export const Weight = (props) => <Icon {...props} name="weight" />;
export const Filter = (props) => <Icon {...props} name="filter" />;
export const FilterSquare = (props) => <Icon {...props} name="filter_square" />;
export const SoundWave = (props) => <Icon {...props} name="sound_wave" />;
export const StoryIcon = (props) => <Icon {...props} name="story" />;
export const SliderIcon = (props) => <Icon {...props} name="slider" />;
export const StatusIcon = (props) => <Icon {...props} name="status" />;
export const WifiSquare = (props) => <Icon {...props} name="wifi_square" />;
export const OutlineSquareBox = (props) => (
  <Icon {...props} name="outline_box_square" />
);
export const TrashSquareIcon = (props) => <Icon {...props} name="trash_icon" />;
export const WifiIcon = (props) => <Icon {...props} name="wifi_icon" />;
export const Speedometer = (props) => <Icon {...props} name="speedometer" />;
export const Diamonds = (props) => <Icon {...props} name="diamonds" />;
export const LevelCircle = (props) => <Icon {...props} name="level_circle" />;
export const JudgeHammer = (props) => <Icon {...props} name="judge_hammer" />;
export const LampIcon = (props) => <Icon {...props} name="lamp" />;
export const TreeIcon = (props) => <Icon {...props} name="tree" />;
export const Bubbles = (props) => <Icon {...props} name="bubbles" />;
export const Mirror = (props) => <Icon {...props} name="mirror" />;
export const TagRight = (props) => <Icon {...props} name="tag_right" />;
export const MilkBottleIcon = (props) => <Icon {...props} name="milk_bottle" />;
export const GhostIcon = (props) => <Icon {...props} name="ghost" />;
export const PodiumIcon = (props) => <Icon {...props} name="podium" />;
export const MouseSquare = (props) => <Icon {...props} name="mouse_square" />;
export const MouseCircle = (props) => <Icon {...props} name="mouse_circle" />;
export const MouseInsideCircle = (props) => (
  <Icon {...props} name="mouse_inside_circle" />
);
export const ForbiddenIcon = (props) => <Icon {...props} name="forbidden" />;
export const ForbiddenIcon2 = (props) => <Icon {...props} name="forbidden2" />;
export const WarningIcon2 = (props) => (
  <Icon {...props} name="warning_hexagon" />
);
export const BroomIcon = (props) => <Icon {...props} name="broom" />;
export const SignPost = (props) => <Icon {...props} name="signpost" />;
export const FilterRemove = (props) => <Icon {...props} name="filter_remove" />;
export const FilterAdd = (props) => <Icon {...props} name="filter_add" />;
export const FilterSearch = (props) => <Icon {...props} name="filter_search" />;
export const FilterEdit = (props) => <Icon {...props} name="filter_edit" />;
export const FilterCheck = (props) => <Icon {...props} name="filter_check" />;
export const HomeIconThree = (props) => (
  <Icon {...props} name="home_pentagon" />
);
export const SmartHome = (props) => <Icon {...props} name="smart_home" />;
export const HomeWifi = (props) => <Icon {...props} name="home_wifi" />;
export const SafeHome = (props) => <Icon {...props} name="home_safe" />;

//Support, Like, question
export const StarIcon = (props) => <Icon {...props} name="star" />;
export const StarCancelIcon = (props) => <Icon {...props} name="star_cancel" />;
export const LoveIcon = (props) => <Icon {...props} name="love" />;
export const LikeIcon = (props) => <Icon {...props} name="like" />;
export const DisLikeIcon = (props) => <Icon {...props} name="dislike" />;
export const DashStarIcon = (props) => <Icon {...props} name="star_dash" />;
export const DashDownStarIcon = (props) => (
  <Icon {...props} name="star_down_dash" />
);
export const MagicStar = (props) => <Icon {...props} name="star_magic" />;
export const LoveEdit = (props) => <Icon {...props} name="love_edit" />;
export const LoveCheck = (props) => <Icon {...props} name="love_check" />;
export const LoveSearch = (props) => <Icon {...props} name="love_search" />;
export const LoveCancel = (props) => <Icon {...props} name="love_cancel" />;
export const LoveRemove = (props) => <Icon {...props} name="love_remove" />;
export const LoveAdd = (props) => <Icon {...props} name="love_add" />;
export const Smileys = (props) => <Icon {...props} name="smileys" />;
export const LikeDislike = (props) => <Icon {...props} name="like_dislike" />;
export const LikeTag = (props) => <Icon {...props} name="like_tag" />;
export const TwentyFourSupport = (props) => (
  <Icon {...props} name="24_support" />
);
export const LikeShape = (props) => <Icon {...props} name="like_shape" />;
export const QuestionBubble = (props) => (
  <Icon {...props} name="question_bubble" />
);
export const PrizeIcon = (props) => <Icon {...props} name="prize" />;
export const LovelyIcon = (props) => <Icon {...props} name="lovely" />;
export const Medal = (props) => <Icon {...props} name="medal" />;
export const Unlimited = (props) => <Icon {...props} name="infinity" />;
export const OutlineLike = (props) => <Icon {...props} name="outline_like" />;
export const LoveCircle = (props) => <Icon {...props} name="love_circle" />;

//Notifications
export const NotificationFill = (props) => (
  <Icon {...props} name="notification_fill" />
);
export const NotificationIcon = (props) => (
  <Icon {...props} name="notification" />
);
export const PendingNotificationSquare = (props) => (
  <Icon {...props} name="pending_notification_square" />
);
export const PendingNotificationFavourite = (props) => (
  <Icon {...props} name="pending_notification_favourite" />
);
export const PendingNotificationStatus = (props) => (
  <Icon {...props} name="pending_notification_status" />
);
export const PendingNotificationCircle = (props) => (
  <Icon {...props} name="pending_notification_circle" />
);
export const LightBulb = (props) => <Icon {...props} name="lightBulb" />;
export const LightBulbCharge = (props) => (
  <Icon {...props} name="lightBulb_charge" />
);
export const LightBulbSlash = (props) => (
  <Icon {...props} name="lightBulb_slash" />
);

//Settings
export const Category = (props) => <Icon {...props} name="category" />;
export const Category2 = (props) => <Icon {...props} name="category2" />;
export const Setting = (props) => <Icon {...props} name="setting" />;
export const Settings = (props) => <Icon {...props} name="settings" />;
export const Settings2 = (props) => <Icon {...props} name="settings2" />;
export const Settings3 = (props) => <Icon {...props} name="settings3" />;
export const Settings4 = (props) => <Icon {...props} name="settings4" />;
export const MoreIconDots = (props) => <Icon {...props} name="more_dots" />;
export const MoreIconDots2 = (props) => <Icon {...props} name="more_dots2" />;
export const ToggleOff = (props) => <Icon {...props} name="toggleOff" />;
export const ToggleOffCircle = (props) => (
  <Icon {...props} name="toggleOff_circle" />
);
export const ToggleOn = (props) => <Icon {...props} name="toggleOn" />;
export const ToggleOnCircle = (props) => (
  <Icon {...props} name="toggleOn_circle" />
);
export const MenuDot = (props) => <Icon {...props} name="menu_dot" />;
export const OptionsIcon = (props) => <Icon {...props} name="options" />;
export const OptionsIcon2 = (props) => <Icon {...props} name="options2" />;
export const Option = (props) => <Icon {...props} name="option" />;

//Archive
export const MarkCheck = (props) => <Icon {...props} name="mark_check" />;
export const ArchiveTag = (props) => <Icon {...props} name="archive_tag" />;
export const ArchiveTagCancel = (props) => (
  <Icon {...props} name="archive_tag_cancel" />
);
export const ArchiveTagAdd = (props) => (
  <Icon {...props} name="archive_tag_add" />
);
export const ArchiveTagMinus = (props) => (
  <Icon {...props} name="archive_tag_minus" />
);
export const ReceiptSquare = (props) => (
  <Icon {...props} name="receipt_square" />
);
export const BookSquare = (props) => <Icon {...props} name="book_square" />;
export const SaveIcon = (props) => <Icon {...props} name="save" />;
export const SaveAdd = (props) => <Icon {...props} name="save_add" />;
export const SaveMinus = (props) => <Icon {...props} name="save_minus" />;
export const SaveRemove = (props) => <Icon {...props} name="save_remove" />;
export const BookSave = (props) => <Icon {...props} name="book_save" />;

//Time
export const Timer = (props) => <Icon {...props} name="timer" />;
export const Clock = (props) => <Icon {...props} name="clock" />;
export const Timer2 = (props) => <Icon {...props} name="timer2" />;
export const TimerPause = (props) => <Icon {...props} name="timer_pause" />;
export const TimerPlay = (props) => <Icon {...props} name="timer_play" />;
export const TimeSecurity = (props) => (
  <Icon {...props} name="timer_security" />
);
export const Calendar = (props) => <Icon {...props} name="calendar" />;
export const CalendarCheck = (props) => (
  <Icon {...props} name="calendar_check" />
);
export const CalendarSearch = (props) => (
  <Icon {...props} name="calendar_search" />
);
export const CalendarAdd = (props) => <Icon {...props} name="calendar_add" />;
export const CalendarRemove = (props) => (
  <Icon {...props} name="calendar_remove" />
);
export const CalendarEdit = (props) => <Icon {...props} name="calendar_edit" />;
export const Calendar2 = (props) => <Icon {...props} name="calendar2" />;
export const CalendarCircle = (props) => (
  <Icon {...props} name="calendar_circle" />
);
export const Calendar3 = (props) => <Icon {...props} name="calendar3" />;

//Computers, Devices, Electronics
export const Printer = (props) => <Icon {...props} name="printer" />;
export const PrinterCancel = (props) => (
  <Icon {...props} name="printer_cancel" />
);
export const Chip = (props) => <Icon {...props} name="chip" />;
export const ChipCharge = (props) => <Icon {...props} name="chip_charge" />;
export const ChipSettings = (props) => <Icon {...props} name="chip_settings" />;
export const Monitor = (props) => <Icon {...props} name="monitor" />;
export const MonitorRecorder = (props) => (
  <Icon {...props} name="monitor_recorder" />
);
export const MonitorMobile = (props) => (
  <Icon {...props} name="monitor_mobile" />
);
export const Headphones = (props) => <Icon {...props} name="headphones" />;
export const CableHeadphones = (props) => (
  <Icon {...props} name="cable_headphones" />
);
export const HeadphonesPlay = (props) => (
  <Icon {...props} name="headphones_play" />
);
export const Airpod = (props) => <Icon {...props} name="airpod" />;
export const Speaker = (props) => <Icon {...props} name="speaker" />;
export const Driver = (props) => <Icon {...props} name="driver" />;
export const Driver2 = (props) => <Icon {...props} name="driver2" />;
export const DriverRefresh = (props) => (
  <Icon {...props} name="driver_refresh" />
);
export const CloudAdd = (props) => <Icon {...props} name="cloud_add" />;
export const CloudRemove = (props) => <Icon {...props} name="cloud_remove" />;
export const CloudConnection = (props) => (
  <Icon {...props} name="cloud_connection" />
);
export const ExternalDrive = (props) => (
  <Icon {...props} name="external_drive" />
);
export const Devices2 = (props) => <Icon {...props} name="devices" />;
export const Airpods = (props) => <Icon {...props} name="Airpods" />;
export const FolderConnection = (props) => (
  <Icon {...props} name="folder_connection" />
);
export const GameControl = (props) => <Icon {...props} name="game_control" />;
export const WeightControl = (props) => (
  <Icon {...props} name="weight_control" />
);
export const Keyboard = (props) => <Icon {...props} name="keyboard" />;
export const Mouse = (props) => <Icon {...props} name="mouse" />;
export const Watch = (props) => <Icon {...props} name="watch" />;
export const Watch2 = (props) => <Icon {...props} name="watch2" />;
export const WatchStatus = (props) => <Icon {...props} name="watch_status" />;
export const Ram = (props) => <Icon {...props} name="ram" />;
export const Ram2 = (props) => <Icon {...props} name="ram2" />;
export const SimCard = (props) => <Icon {...props} name="sim_card" />;
export const SimCard2 = (props) => <Icon {...props} name="sim_card2" />;
export const SimCard3 = (props) => <Icon {...props} name="sim_card3" />;
export const Desk = (props) => <Icon {...props} name="desk" />;
export const Mobile = (props) => <Icon {...props} name="mobile" />;
export const Plug = (props) => <Icon {...props} name="plug" />;
export const ScreenMirroring = (props) => (
  <Icon {...props} name="screen_mirroring" />
);
export const Telescope = (props) => <Icon {...props} name="telescope" />;
export const OpenKeyboard = (props) => <Icon {...props} name="open_keyboard" />;
export const AirDrop = (props) => <Icon {...props} name="air_drop" />;
export const GameBoy = (props) => <Icon {...props} name="gameBoy" />;
export const Bluetooth = (props) => <Icon {...props} name="bluetooth" />;
export const Bluetooth2 = (props) => <Icon {...props} name="bluetooth2" />;
export const BluetoothCircle = (props) => (
  <Icon {...props} name="bluetooth_circle" />
);
export const BluetoothSquare = (props) => (
  <Icon {...props} name="bluetooth_square" />
);
export const CloudChange = (props) => <Icon {...props} name="cloud_change" />;

//Call
export const Call = (props) => <Icon {...props} name="call" />;
export const IncomingCall = (props) => <Icon {...props} name="incoming_call" />;
export const Calling = (props) => <Icon {...props} name="calling" />;
export const OutgoingCall = (props) => <Icon {...props} name="outgoing_call" />;
export const CallAdd = (props) => <Icon {...props} name="add_call" />;
export const CallMinus = (props) => <Icon {...props} name="minus_call" />;
export const CallRemove = (props) => <Icon {...props} name="remove_call" />;
export const CallReceived = (props) => <Icon {...props} name="received_call" />;
export const CallCancel = (props) => <Icon {...props} name="cancel_call" />;

//Business
export const ChartSuccess = (props) => <Icon {...props} name="chart_success" />;
export const ChartFail = (props) => <Icon {...props} name="chart_fail" />;
export const ChartSquare = (props) => <Icon {...props} name="chart_square" />;
export const PieChart = (props) => <Icon {...props} name="pie_chart" />;
export const Activity = (props) => <Icon {...props} name="activity" />;
export const HomeTrending = (props) => <Icon {...props} name="home_trending" />;
export const HomeTrendingDown = (props) => (
  <Icon {...props} name="home_trending_down" />
);
export const HomeHash = (props) => <Icon {...props} name="home_hash" />;
export const TrendUp = (props) => <Icon {...props} name="trend_up" />;
export const TrendDown = (props) => <Icon {...props} name="trend_down" />;
export const HashSquare = (props) => <Icon {...props} name="hash_square" />;
export const OutlineChart = (props) => <Icon {...props} name="outline_chart" />;
export const PersonalId = (props) => <Icon {...props} name="personal_id" />;
export const StatusUp = (props) => <Icon {...props} name="status_up" />;
export const LineChart = (props) => <Icon {...props} name="line_chart" />;
export const Presentation = (props) => <Icon {...props} name="presentation" />;
export const BarChart = (props) => <Icon {...props} name="bar_chart" />;
export const BarChart2 = (props) => <Icon {...props} name="bar_chart2" />;
export const Health = (props) => <Icon {...props} name="health" />;
export const FavouriteChart = (props) => (
  <Icon {...props} name="favourite_chart" />
);

//Building
export const Building = (props) => <Icon {...props} name="building" />;
export const Building2 = (props) => <Icon {...props} name="building2" />;
export const Building3 = (props) => <Icon {...props} name="building3" />;
export const Building4 = (props) => <Icon {...props} name="building4" />;
export const Buildings = (props) => <Icon {...props} name="buildings" />;
export const Buildings2 = (props) => <Icon {...props} name="buildings2" />;
export const CourtHouse = (props) => <Icon {...props} name="courtHouse" />;
export const House = (props) => <Icon {...props} name="house" />;
export const House2 = (props) => <Icon {...props} name="house2" />;
export const Bank = (props) => <Icon {...props} name="bank" />;
export const Hospital = (props) => <Icon {...props} name="hospital" />;

//Astrology
export const Aquarius = (props) => <Icon {...props} name="aquarius" />;
export const Gemini = (props) => <Icon {...props} name="gemini" />;
export const Gemini2 = (props) => <Icon {...props} name="gemini2" />;
export const Sagittarius = (props) => <Icon {...props} name="sagittarius" />;
export const Male = (props) => <Icon {...props} name="male" />;
export const Female = (props) => <Icon {...props} name="female" />;

//Search
export const SearchIcon = (props) => <Icon {...props} name="search" />;
export const SearchIcon2 = (props) => <Icon {...props} name="search2" />;
export const ZoomIn = (props) => <Icon {...props} name="zoom_in" />;
export const ZoomIn2 = (props) => <Icon {...props} name="zoom_in2" />;
export const ZoomOut = (props) => <Icon {...props} name="zoom_out" />;
export const ZoomOut2 = (props) => <Icon {...props} name="zoom_out2" />;
export const SearchFavourite = (props) => (
  <Icon {...props} name="search_favourite" />
);
export const SearchFavourite2 = (props) => (
  <Icon {...props} name="search_favourite2" />
);
export const SearchStatus = (props) => <Icon {...props} name="search_status" />;
export const SearchStatus2 = (props) => (
  <Icon {...props} name="search_status2" />
);
/* 
export const    = props => <Icon {...props} name= "" />;
export const    = props => <Icon {...props} name= "" />; 
export const    = props => <Icon {...props} name= "" />;
export const    = props => <Icon {...props} name= "" />; 
export const    = props => <Icon {...props} name= "" />;
export const    = props => <Icon {...props} name= "" />; 
export const    = props => <Icon {...props} name= "" />;
export const    = props => <Icon {...props} name= "" />; 
export const    = props => <Icon {...props} name= "" />;
export const    = props => <Icon {...props} name= "" />; */
//Car
export const OutlineAirplane = (props) => (
  <Icon {...props} name="outline_airplane" />
);
export const OutlineCar = (props) => <Icon {...props} name="outline_car" />;
export const OutlineBus = (props) => <Icon {...props} name="outline_bus" />;
export const OutlineSmartCar = (props) => (
  <Icon {...props} name="outline_smart_car" />
);
export const OutlineDriving = (props) => (
  <Icon {...props} name="outline_driving" />
);
export const OutlineShip = (props) => <Icon {...props} name="outline_ship" />;
export const OutlineAirplaneSquare = (props) => (
  <Icon {...props} name="outline_airplane_square" />
);
export const OutlineGasStation = (props) => (
  <Icon {...props} name="outline_gas_station" />
);

//Emails, Messages
export const OutlineDirectboxDeafault = (props) => (
  <Icon {...props} name="outline_directbox_deafault" />
);
export const OutlineDirectboxNotif = (props) => (
  <Icon {...props} name="outline_directbox_notif" />
);
export const OutlineDirectboxReceive = (props) => (
  <Icon {...props} name="outline_directbox_receive" />
);
export const OutlineDirectboxSend = (props) => (
  <Icon {...props} name="outline_directbox_send" />
);
export const OutlineDirectNormal = (props) => (
  <Icon {...props} name="outline_direct_normal" />
);
export const OutlineDirectDirect = (props) => (
  <Icon {...props} name="outline_direct_direct" />
);
export const OutlineDirectNotification = (props) => (
  <Icon {...props} name="outline_direct_notification" />
);
export const OutlineDirectInbox = (props) => (
  <Icon {...props} name="outline_direct_inbox" />
);
export const OutlineDirectSend = (props) => (
  <Icon {...props} name="outline_direct_send" />
);
export const OutlineMessage = (props) => (
  <Icon {...props} name="outline_message" />
);
export const OutlineMessageFavorite = (props) => (
  <Icon {...props} name="outline_message_favorite" />
);
export const OutlineMessageNotif = (props) => (
  <Icon {...props} name="outline_message_notif" />
);
export const OutlineMessageSearch = (props) => (
  <Icon {...props} name="outline_message_search" />
);
export const OutlineMessageText = (props) => (
  <Icon {...props} name="outline_message_text" />
);
export const OutlineDeviceMessage = (props) => (
  <Icon {...props} name="outline_device_message" />
);
export const OutlineMessages = (props) => (
  <Icon {...props} name="outline_messages" />
);
export const OutlineMessages2 = (props) => (
  <Icon {...props} name="outline_messages_2" />
);
export const OutlineMessages3 = (props) => (
  <Icon {...props} name="outline_messages_3" />
);
export const OutlineSms = (props) => <Icon {...props} name="outline_sms" />;
export const OutlineSmsTracking = (props) => (
  <Icon {...props} name="outline_sms_tracking" />
);
export const OutlineSmsNotification = (props) => (
  <Icon {...props} name="outline_sms_notification" />
);
export const OutlineSmsEdit = (props) => (
  <Icon {...props} name="outline_sms_edit" />
);
export const OutlineSmsStar = (props) => (
  <Icon {...props} name="outline-sms_star" />
);
export const OutlineSmsSearch = (props) => (
  <Icon {...props} name="outline_sms-search" />
);
export const OutlineMessageRemove = (props) => (
  <Icon {...props} name="outline_message_remove" />
);
export const OutlineMessageAdd = (props) => (
  <Icon {...props} name="outline_message_add" />
);
export const OutlineMessageTick = (props) => (
  <Icon {...props} name="outline_message_tick" />
);
export const OutlineMessageTime = (props) => (
  <Icon {...props} name="outline_message_time" />
);
export const OutlineMessage4 = (props) => (
  <Icon {...props} name="outline_message_4" />
);
export const OutlineMessages4 = (props) => (
  <Icon {...props} name="outline_messages_4" />
);
export const OutlineMessageCircle = (props) => (
  <Icon {...props} name="outline_message_circle" />
);
export const OutlineMessageSquare = (props) => (
  <Icon {...props} name="outline_message_square" />
);
export const OutlineMessageText2 = (props) => (
  <Icon {...props} name="outline_message_text_2" />
);
export const OutlineMessageMinus = (props) => (
  <Icon {...props} name="outline_message_minus" />
);
export const OutlineMessageAdd2 = (props) => (
  <Icon {...props} name="outline_message_add_2" />
);
export const OutlineMessageEdit = (props) => (
  <Icon {...props} name="outline_message_edit" />
);

//Location
export const OutlineLocationMinus = (props) => (
  <Icon {...props} name="outline_location_minus" />
);
export const OutlineLocationCross = (props) => (
  <Icon {...props} name="outline_location_cross" />
);
export const OutlineLocationTick = (props) => (
  <Icon {...props} name="outline_location_tick" />
);
export const OutlineLocationSlash = (props) => (
  <Icon {...props} name="outline_location_slash" />
);
export const OutlineDiscover = (props) => (
  <Icon {...props} name="outline_discover" />
);
export const OutlineLocation = (props) => (
  <Icon {...props} name="outline_location" />
);
export const OutlineLocationAdd = (props) => (
  <Icon {...props} name="outline_location_add" />
);
export const OutlineRadar = (props) => <Icon {...props} name="outline_radar" />;
export const OutlineRadar2 = (props) => (
  <Icon {...props} name="outline_radar_2" />
);
export const OutlineGlobalSearch = (props) => (
  <Icon {...props} name="outline_global_search" />
);
export const OutlineGlobalEdit = (props) => (
  <Icon {...props} name="outline_global_edit" />
);
export const OutlineGlobalRefresh = (props) => (
  <Icon {...props} name="outline_global_refresh" />
);
export const OutlineGlobal = (props) => (
  <Icon {...props} name="outline_global" />
);
export const OutlineDirectUp = (props) => (
  <Icon {...props} name="outline_direct_up" />
);
export const OutlineDirectDown = (props) => (
  <Icon {...props} name="outline_direct_down" />
);
export const OutlineDirectLeft = (props) => (
  <Icon {...props} name="outline_direct_left" />
);
export const OutlineDirectRight = (props) => (
  <Icon {...props} name="outline_direct_right" />
);
export const OutlineMap = (props) => <Icon {...props} name="outline_map" />;
export const OutlineGps = (props) => <Icon {...props} name="outline_gps" />;
export const OutlineGpsSlash = (props) => (
  <Icon {...props} name="outline_gps_slash" />
);
export const OutlineMap2 = (props) => <Icon {...props} name="outline_map_2" />;
export const OutlineRouting = (props) => (
  <Icon {...props} name="outline_routing" />
);
export const OutlineRouting2 = (props) => (
  <Icon {...props} name="outline_routing_2" />
);
export const OutlineArrowSquare = (props) => (
  <Icon {...props} name="outline_arrow_square" />
);
export const OutlinePictureFrame = (props) => (
  <Icon {...props} name="outline_picture_frame" />
);
export const OutlineArrow = (props) => <Icon {...props} name="outline_arrow" />;
export const OutlineRouteSquare = (props) => (
  <Icon {...props} name="outline_route_quare" />
);

//School, Learning
export const OutlineBriefcase = (props) => (
  <Icon {...props} name="outline_briefcase" />
);
export const OutlineBrifecaseTimer = (props) => (
  <Icon {...props} name="outline_brifecase_timer" />
);
export const OutlineBriefcaseTick = (props) => (
  <Icon {...props} name="outline_briefcase_tick" />
);
export const OutlineBriefcaseCross = (props) => (
  <Icon {...props} name="outline_briefcase_cross" />
);
export const OutlineBriefcaseBookmark = (props) => (
  <Icon {...props} name="outline_briefcase_bookmark" />
);
export const OutlineAward = (props) => <Icon {...props} name="outline_award" />;
export const OutlineBook = (props) => <Icon {...props} name="outline_book" />;
export const OutlineBook2 = (props) => (
  <Icon {...props} name="outline_book_2" />
);
export const OutlineBookmark2 = (props) => (
  <Icon {...props} name="outline_bookmark_2" />
);
export const OutlineClipboard = (props) => (
  <Icon {...props} name="outline_clipboard" />
);
export const OutlineNote = (props) => <Icon {...props} name="outline_note" />;
export const OutlineNote2 = (props) => (
  <Icon {...props} name="outline_note_2" />
);
export const OutlineTeacher = (props) => (
  <Icon {...props} name="outline_teacher" />
);
export const OutlineGift = (props) => <Icon {...props} name="outline_gift" />;
export const OutlineCalculator = (props) => (
  <Icon {...props} name="outline_calculator" />
);

//Delivery
export const OutlineBox = (props) => <Icon {...props} name="outline_box" />;
export const Outline3dCubeScan = (props) => (
  <Icon {...props} name="outline_3d_cube_scan" />
);
export const Outline3dCube = (props) => (
  <Icon {...props} name="outline_3d_cube" />
);
export const OutlineBoxAdd = (props) => (
  <Icon {...props} name="outline_box_add" />
);
export const OutlineBoxRemove = (props) => (
  <Icon {...props} name="outline_box_remove" />
);
export const OutlineBoxTick = (props) => (
  <Icon {...props} name="outline_box_tick" />
);
export const OutlineBoxTime = (props) => (
  <Icon {...props} name="outline_box_time" />
);
export const OutlineBox2 = (props) => <Icon {...props} name="outline_box_2" />;
export const OutlineBoxSearch = (props) => (
  <Icon {...props} name="outline_box_search" />
);
export const OutlineTruck = (props) => <Icon {...props} name="outline_truck" />;
export const OutlineTruckFast = (props) => (
  <Icon {...props} name="outline_truck_fast" />
);
export const OutlineTruckTick = (props) => (
  <Icon {...props} name="outline_truck_tick" />
);
export const OutlineTruckTime = (props) => (
  <Icon {...props} name="outline_truck_time" />
);
export const OutlineTruckRemove = (props) => (
  <Icon {...props} name="outline_truck_remove" />
);
export const Outline3dSquare = (props) => (
  <Icon {...props} name="outline_3d_square" />
);
export const Outline3dRotate = (props) => (
  <Icon {...props} name="outline_3d_rotate" />
);

//Security
export const OutlineEye = (props) => <Icon {...props} name="outline_eye" />;
export const OutlineEyeSlash = (props) => (
  <Icon {...props} name="outline_eye_slash" />
);
export const OutlineShield = (props) => (
  <Icon {...props} name="outline_shield" />
);
export const OutlineShieldTick = (props) => (
  <Icon {...props} name="outline_shield_tick" />
);
export const OutlineShieldCross = (props) => (
  <Icon {...props} name="outline_shield_cross" />
);
export const OutlineShieldSlash = (props) => (
  <Icon {...props} name="outline_shield_slash" />
);
export const OutlineFrame3 = (props) => (
  <Icon {...props} name="outline_frame_3" />
);
export const OutlinePasswordCheck = (props) => (
  <Icon {...props} name="outline_password_check" />
);
export const OutlineShieldSearch = (props) => (
  <Icon {...props} name="outline_shield_search" />
);
export const OutlineKey = (props) => <Icon {...props} name="outline_key" />;
export const OutlineKeySquare = (props) => (
  <Icon {...props} name="outline_key_square" />
);
export const OutlineCheck = (props) => <Icon {...props} name="outline_check" />;
export const OutlineLock = (props) => <Icon {...props} name="outline_lock" />;
export const OutlineUnlock = (props) => (
  <Icon {...props} name="outline_unlock" />
);
export const OutlineLockSlash = (props) => (
  <Icon {...props} name="outline_lock_slash" />
);
export const OutlineScan = (props) => <Icon {...props} name="outline_scan" />;
export const OutlineScanBarcode = (props) => (
  <Icon {...props} name="outline_scan_barcode" />
);
export const OutlineScanning = (props) => (
  <Icon {...props} name="outline_scanning" />
);
export const OutlineFingerScan = (props) => (
  <Icon {...props} name="outline_finger_scan" />
);
export const OutlineFingerCircle = (props) => (
  <Icon {...props} name="outline_finger_circle" />
);
export const OutlineScanner = (props) => (
  <Icon {...props} name="outline_scanner" />
);
export const OutlineSecurityUser = (props) => (
  <Icon {...props} name="outline-security_user" />
);
export const OutlineLock2 = (props) => (
  <Icon {...props} name="outline_lock_2" />
);
export const OutlineLockCircle = (props) => (
  <Icon {...props} name="outline_lock_circle" />
);
export const OutlineAlarm = (props) => <Icon {...props} name="outline_alarm" />;
export const OutlineRadar3 = (props) => (
  <Icon {...props} name="outline_radar_3" />
);
export const OutlineSecuritySafe = (props) => (
  <Icon {...props} name="outline_security_safe" />
);
export const OutlineSecurity = (props) => (
  <Icon {...props} name="outline_security" />
);

//Desing, tools
export const OutlineBezier = (props) => (
  <Icon {...props} name="outline_bezier" />
);
export const OutlineBrush = (props) => <Icon {...props} name="outline_brush" />;
export const OutlineBrush2 = (props) => (
  <Icon {...props} name="outline_brush_2" />
);
export const OutlineMagicpen = (props) => (
  <Icon {...props} name="outline_magicpen" />
);
export const BrokenPenTool = (props) => (
  <Icon {...props} name="broken_pen_tool" />
);
export const BrokenBucket = (props) => <Icon {...props} name="broken_bucket" />;
export const OutlinePaintbucket = (props) => (
  <Icon {...props} name="outline_paintbucket" />
);
export const OutlineAdditem = (props) => (
  <Icon {...props} name="outline_additem" />
);
export const OutlineRuler = (props) => <Icon {...props} name="outline_ruler" />;
export const OutlineDesingtools = (props) => (
  <Icon {...props} name="outline_desingtools" />
);
export const OutlineShapes = (props) => (
  <Icon {...props} name="outline_shapes" />
);
export const OutlineLifebuoy = (props) => (
  <Icon {...props} name="outline_lifebuoy" />
);
export const OutlinePenTool2 = (props) => (
  <Icon {...props} name="outline_pen_tool_2" />
);
export const OutlineBrush3 = (props) => (
  <Icon {...props} name="outline_brush_3" />
);
export const OutlinePenClose = (props) => (
  <Icon {...props} name="outline_pen_close" />
);
export const OutlinePenAdd = (props) => (
  <Icon {...props} name="outline_pen_add" />
);
export const OutlinePenRemove = (props) => (
  <Icon {...props} name="outline_pen_remove" />
);
export const OutlinePath = (props) => <Icon {...props} name="outline_path" />;
export const OutlinePath2 = (props) => (
  <Icon {...props} name="outline_path_2" />
);
export const OutlineBucketCircle = (props) => (
  <Icon {...props} name="outline_bucket_circle" />
);
export const OutlineBucketSquare = (props) => (
  <Icon {...props} name="outline_bucket_square" />
);
export const OutlineBrush4 = (props) => (
  <Icon {...props} name="outline_brush_4" />
);
export const OutlineBrokenScissor = (props) => (
  <Icon {...props} name="outline_broken_scissor" />
);
export const OutlineGlass = (props) => <Icon {...props} name="outline_glass" />;
export const OutlineRulerpen = (props) => (
  <Icon {...props} name="outline_rulerpen" />
);
export const BrokenColorSwatch = (props) => (
  <Icon {...props} name="broken_color_swatch" />
);
export const OutlineBlend2 = (props) => (
  <Icon {...props} name="outline_blend_2" />
);
export const OutlinePathSquare = (props) => (
  <Icon {...props} name="outline_path_square" />
);
export const OutlineOmegaCircle = (props) => (
  <Icon {...props} name="outline_omega_circle" />
);
export const OutlineOmegaSquare = (props) => (
  <Icon {...props} name="outline_omega_square" />
);
export const OutlineShapes2 = (props) => (
  <Icon {...props} name="outline_shapes_2" />
);
export const OutlineSize = (props) => <Icon {...props} name="outline_size" />;
export const OutlineFlashCircle = (props) => (
  <Icon {...props} name="outline_flash_circle" />
);
export const OutlineEraser = (props) => (
  <Icon {...props} name="outline_eraser" />
);
export const OutlineMask = (props) => <Icon {...props} name="outline_mask" />;
export const OutlineBlur = (props) => <Icon {...props} name="outline_blur" />;
export const OutlineBlend = (props) => <Icon {...props} name="outline_blend" />;
export const OutlineColorfilter = (props) => (
  <Icon {...props} name="outline_colorfilter" />
);
export const OutlineColorsSquare = (props) => (
  <Icon {...props} name="outline_colors_square" />
);
export const OutlineMask2 = (props) => (
  <Icon {...props} name="outline_mask_2" />
);
export const OutlineBrush5 = (props) => (
  <Icon {...props} name="outline_brush_5" />
);
export const OutlineLayer = (props) => <Icon {...props} name="outline_layer" />;
export const OutlineCopy = (props) => <Icon {...props} name="outline_copy" />;
export const OutlineCopySucces = (props) => (
  <Icon {...props} name="outline_copy_succes" />
);
export const OutlineRecoveryConvert = (props) => (
  <Icon {...props} name="outline_recovery_convert" />
);
export const OutlineMainComponet = (props) => (
  <Icon {...props} name="outline_main_componet" />
);
export const OutlineComponent = (props) => (
  <Icon {...props} name="outline_component" />
);
export const OutlineMask3 = (props) => (
  <Icon {...props} name="outline_mask_3" />
);
export const OutlineForwardItem = (props) => (
  <Icon {...props} name="outline_forward_item" />
);
export const OutlineBackwardItem = (props) => (
  <Icon {...props} name="outline_backward_item" />
);

//Grid
export const OutlineKanban = (props) => (
  <Icon {...props} name="outline_kanban" />
);
export const OutlineFatrows = (props) => (
  <Icon {...props} name="outline_fatrows" />
);
export const OutlineRowVertical = (props) => (
  <Icon {...props} name="outline_row_vertical" />
);
export const OutlineRowHorizontal = (props) => (
  <Icon {...props} name="outline_row_horizontal" />
);
export const OutlineSliderVertical = (props) => (
  <Icon {...props} name="outline_slider_vertical" />
);
export const OutlineSliderHorizontal = (props) => (
  <Icon {...props} name="outline_slider_horizontal" />
);
export const OutlineSliderVertical2 = (props) => (
  <Icon {...props} name="outline_slider_vertical_2" />
);
export const OutlineSliderHorizontal2 = (props) => (
  <Icon {...props} name="outline_slider_horizontal_2" />
);
export const OutlineGrid4 = (props) => (
  <Icon {...props} name="outline_grid_4" />
);
export const OutlineGrid9 = (props) => (
  <Icon {...props} name="outline_grid_9" />
);
export const OutlineGrid2 = (props) => (
  <Icon {...props} name="outline_grid_2" />
);
export const OutlineGrid3 = (props) => (
  <Icon {...props} name="outline_grid_3" />
);
export const OutlineGrid5 = (props) => (
  <Icon {...props} name="outline_grid_5" />
);
export const OutlineGrid6 = (props) => (
  <Icon {...props} name="outline_grid_6" />
);
export const OutlineGrid7 = (props) => (
  <Icon {...props} name="outline_grid_7" />
);
export const OutlineGrid8 = (props) => (
  <Icon {...props} name="outline_grid_8" />
);
export const OutlineGrid1 = (props) => (
  <Icon {...props} name="outline_grid_1" />
);
export const OutlineElement2 = (props) => (
  <Icon {...props} name="outline_element_2" />
);
export const OutlineFrame4 = (props) => (
  <Icon {...props} name="outline_frame_4" />
);
export const OutlineElement3 = (props) => (
  <Icon {...props} name="outline_element_3" />
);
export const OutlineElement4 = (props) => (
  <Icon {...props} name="outline_element_4" />
);
export const OutlineElementPlus = (props) => (
  <Icon {...props} name="outline_element_plus" />
);
export const OutlineElementEqual = (props) => (
  <Icon {...props} name="outline_element_equal" />
);
export const Outline3Square = (props) => (
  <Icon {...props} name="outline_3_square" />
);
export const OutlineAlingVertically = (props) => (
  <Icon {...props} name="outline_aling_vertically" />
);
export const OutlineAlingHorizontally = (props) => (
  <Icon {...props} name="outline_aling_horizontally" />
);
export const OutlineAlingLeft = (props) => (
  <Icon {...props} name="outline_aling_left" />
);
export const OutlineAlingRight = (props) => (
  <Icon {...props} name="outline_aling_right" />
);
export const OutlineAlingBottom = (props) => (
  <Icon {...props} name="outline_aling_bottom" />
);
export const OutlineFrame5 = (props) => (
  <Icon {...props} name="outline_frame_5" />
);
export const OutlineMaximize = (props) => (
  <Icon {...props} name="outline_maximize" />
);
export const OutlineMaximize2 = (props) => (
  <Icon {...props} name="outline_maximize_2" />
);
export const OutlineMaximize3 = (props) => (
  <Icon {...props} name="outline_maximize_3" />
);
export const OutlineMaximize4 = (props) => (
  <Icon {...props} name="outline_maximize_4" />
);
export const OutlineFormatCircle = (props) => (
  <Icon {...props} name="outline_format_circle" />
);
export const OutlineFormatSquare = (props) => (
  <Icon {...props} name="outline_format_square" />
);
export const OutlineRotateRight2 = (props) => (
  <Icon {...props} name="outline_rotate_right_2" />
);
export const OutlineRotateLeft2 = (props) => (
  <Icon {...props} name="outline_rotate_left_2" />
);
export const OutlineCrop = (props) => <Icon {...props} name="outline_crop" />;
export const OutlineMaximize5 = (props) => (
  <Icon {...props} name="outline_maximize_5" />
);
export const OutlineConvertshape = (props) => (
  <Icon {...props} name="outline_convertshape" />
);
export const OutlineConvertshape2 = (props) => (
  <Icon {...props} name="outline_convertshape_2" />
);
export const OutlineGridEraser = (props) => (
  <Icon {...props} name="outline_grid_eraser" />
);
export const OutlineGridLock = (props) => (
  <Icon {...props} name="outline_grid_lock" />
);
export const OutlineGridEdit = (props) => (
  <Icon {...props} name="outline_grid_edit" />
);

//Shop
export const OutlineBag = (props) => <Icon {...props} name="outline_bag" />;
export const OutlineBagHappy = (props) => (
  <Icon {...props} name="outline_bag_happy" />
);
export const OutlineBagTick = (props) => (
  <Icon {...props} name="outline_bag_tick" />
);
export const OutlineBagCross = (props) => (
  <Icon {...props} name="outline_bag_cross" />
);
export const OutlineBagTimer = (props) => (
  <Icon {...props} name="outline_bag_timer" />
);
export const OutlineBagTick2 = (props) => (
  <Icon {...props} name="outline_bag_tick_2" />
);
export const OutlineBagCross2 = (props) => (
  <Icon {...props} name="outline_bag_cross_2" />
);
export const OutlineBag2 = (props) => <Icon {...props} name="outline_bag_2" />;
export const OutlineShoppingCart = (props) => (
  <Icon {...props} name="outline_shopping_cart" />
);
export const OutlineShppingBag = (props) => (
  <Icon {...props} name="outline_shpping_bag" />
);
export const OutlineShopAdd = (props) => (
  <Icon {...props} name="outline_shop_add" />
);
export const OutlineShopRemove = (props) => (
  <Icon {...props} name="outline_shop_remove" />
);
export const OutlineShop = (props) => <Icon {...props} name="outline_shop" />;
export const OutlineBarcode = (props) => (
  <Icon {...props} name="outline_barcode" />
);
export const OutlineWhatsapp = (props) => (
  <Icon {...props} name="outline_whatsapp" />
);

//Crypto Currency
export const OutlineCardCoin = (props) => (
  <Icon {...props} name="outline_card_coin" />
);
export const OutlineTrade = (props) => <Icon {...props} name="outline_trade" />;
export const OutlineBuyCrypto = (props) => (
  <Icon {...props} name="outline_buy_crypto" />
);
export const OutlineBitcoinCard = (props) => (
  <Icon {...props} name="outline_bitcoin_card" />
);
export const OutlineBitcoinConvert = (props) => (
  <Icon {...props} name="outline_bitcoin_convert" />
);
export const OutlineBitcoinRefresh = (props) => (
  <Icon {...props} name="outline_bitcoin_refresh" />
);
export const Mercadolibre = (props) => <Icon {...props} name="mercadolibre" />;

//Content, edit
export const OutlineDocument2 = (props) => (
  <Icon {...props} name="outline_document_2" />
);
export const OutlineDocumentText = (props) => (
  <Icon {...props} name="outline_document_text" />
);
export const OutlineDocumentFavorite = (props) => (
  <Icon {...props} name="outline_document_favorite" />
);
export const OutlineDocumentDownload = (props) => (
  <Icon {...props} name="outline_document_download" />
);
export const OutlineDocumentUpload = (props) => (
  <Icon {...props} name="outline_document_upload" />
);
export const OutlineDocumentForward = (props) => (
  <Icon {...props} name="outline_document_forward" />
);
export const OutlineDocumentPrevious = (props) => (
  <Icon {...props} name="outline_document_previous" />
);
export const OutlineDocumentSketch = (props) => (
  <Icon {...props} name="outline_document_sketch" />
);
export const OutlineDocumentCloud = (props) => (
  <Icon {...props} name="outline_document_cloud" />
);
export const OutlineDocumentCopy = (props) => (
  <Icon {...props} name="outline_document_copy" />
);
export const OutlineDocumentNormal = (props) => (
  <Icon {...props} name="outline_document_normal" />
);
export const OutlineClipboardText = (props) => (
  <Icon {...props} name="outline_clipboard_text" />
);
export const OutlineClipboardTick = (props) => (
  <Icon {...props} name="outline_clipboard_tick" />
);
export const OutlineClipboardClose = (props) => (
  <Icon {...props} name="outline_clipboard_close" />
);
export const OutlineClipboardExport = (props) => (
  <Icon {...props} name="outline_clipboard_export" />
);
export const OutlineCliprboardImport = (props) => (
  <Icon {...props} name="outline_cliprboard_import" />
);
export const OutlineNote3 = (props) => (
  <Icon {...props} name="outline_note_3" />
);
export const OutlineDocumentText2 = (props) => (
  <Icon {...props} name="outline_document_text_2" />
);
export const OutlineDocumentLike = (props) => (
  <Icon {...props} name="outline_document_like" />
);
export const OutlineEdit = (props) => <Icon {...props} name="outline_edit" />;
export const OutlineEdit2 = (props) => (
  <Icon {...props} name="outline_edit_2" />
);
export const OutlineCopyright = (props) => (
  <Icon {...props} name="outline_copyright" />
);
export const OutlineCreativeCommos = (props) => (
  <Icon {...props} name="outline_creative_commos" />
);
export const OutlineNote4 = (props) => (
  <Icon {...props} name="outline_note_4" />
);
export const OutlineNoteRemove = (props) => (
  <Icon {...props} name="outline_note_remove" />
);
export const OutlineStickynote = (props) => (
  <Icon {...props} name="outline_stickynote" />
);
export const OutlineAdd = (props) => <Icon {...props} name="outline_add" />;
export const OutlineTask = (props) => <Icon {...props} name="outline_task" />;
export const OutlineTaskSquare = (props) => (
  <Icon {...props} name="outline_task_square" />
);
export const OutlineBill = (props) => <Icon {...props} name="outline_bill" />;
export const OutlineDocumentFilter = (props) => (
  <Icon {...props} name="outline_document_filter" />
);
export const OutlineArchiveBook = (props) => (
  <Icon {...props} name="outline_archive_book" />
);
export const OutlineNoteText = (props) => (
  <Icon {...props} name="outline_note_text" />
);
export const OutlineNoteFavorite = (props) => (
  <Icon {...props} name="outline_note_favorite" />
);
export const OutlineMenuBoard = (props) => (
  <Icon {...props} name="outline_menu_board" />
);

//Programing
export const OutlineCommand = (props) => (
  <Icon {...props} name="outline_command" />
);
export const OutlineProgramingArrow = (props) => (
  <Icon {...props} name="outline_programing_arrow" />
);
export const OutlineHierarchy = (props) => (
  <Icon {...props} name="outline_hierarchy" />
);
export const OutlineHierarchy2 = (props) => (
  <Icon {...props} name="outline_hierarchy_2" />
);
export const OutlineCommandSquare = (props) => (
  <Icon {...props} name="outline_command_square" />
);
export const OutlineHierarchySquare = (props) => (
  <Icon {...props} name="outline_hierarchy_square" />
);
export const OutlineHierarchySquare2 = (props) => (
  <Icon {...props} name="outline_hierarchy_square_2" />
);
export const OutlineHierarchySquare3 = (props) => (
  <Icon {...props} name="outline_hierarchy_square_3" />
);
export const OutlineData = (props) => <Icon {...props} name="outline_data" />;
export const OutlineData2 = (props) => (
  <Icon {...props} name="outline_data_2" />
);
export const OutlineDocumentCode = (props) => (
  <Icon {...props} name="outline_document_code" />
);
export const OutlineCodeCircle = (props) => (
  <Icon {...props} name="outline_code_circle" />
);
export const OutlineHierarchy3 = (props) => (
  <Icon {...props} name="outline_hierarchy_3" />
);
export const OutlineProgramingArrows = (props) => (
  <Icon {...props} name="outline_programing_arrows" />
);
export const OutlineDocumentCode2 = (props) => (
  <Icon {...props} name="outline_document_code_2" />
);
export const OutlineMessageProgramming = (props) => (
  <Icon {...props} name="outline_message_programming" />
);
export const OutlineMobilePrograming = (props) => (
  <Icon {...props} name="outline_mobile_programing" />
);
export const OutlineCode = (props) => <Icon {...props} name="outline_code" />;
export const OutlineCode2 = (props) => (
  <Icon {...props} name="outline_code_2" />
);
export const OutlineHastagUp = (props) => (
  <Icon {...props} name="outline_hastag_up" />
);
export const OutlineHastag = (props) => (
  <Icon {...props} name="outline_hastag" />
);
export const OutlineHashtagDown = (props) => (
  <Icon {...props} name="outline_hashtag_down" />
);
export const OutlineScroll = (props) => (
  <Icon {...props} name="outline_scroll" />
);
export const OutlineSidebarLeft = (props) => (
  <Icon {...props} name="outline_sidebar_left" />
);
export const OutlineSidebarRight = (props) => (
  <Icon {...props} name="outline_sidebar_right" />
);
export const OutlineSidebarTop = (props) => (
  <Icon {...props} name="outline_sidebar_top" />
);
export const OutlineSidebarBottom = (props) => (
  <Icon {...props} name="outline_sidebar_bottom" />
);

/******************************************************************************************************************************************************************************** */
/*************************************************************       ICONOS BOLD       *******************************************************************************************/
/****************************************************************************************************************************************************************************** */

//Money
export const BoldWalletAdd = (props) => (
  <Icon {...props} name="bold_wallet_add" />
);
export const BoldReceiptItem = (props) => (
  <Icon {...props} name="bold_receipt_item" />
);
export const BoldEmptyWallet = (props) => (
  <Icon {...props} name="bold_empty_wallet" />
);
export const BoldWalletCheck = (props) => (
  <Icon {...props} name="bold_wallet_check" />
);
export const BoldReceiptDiscount = (props) => (
  <Icon {...props} name="bold_receip_discount" />
);
export const BoldEmptyWalletRemove = (props) => (
  <Icon {...props} name="bold_empty_wallet_remove" />
);
export const BoldEmptyWalletAdd = (props) => (
  <Icon {...props} name="bold_empty_wallet_add" />
);
export const BoldReceiptMinus = (props) => (
  <Icon {...props} name="bold_receipt_minus" />
);
export const BoldWalletSearch = (props) => (
  <Icon {...props} name="bold_wallet_search" />
);
export const BoldStrongBox2 = (props) => (
  <Icon {...props} name="bold_strong_box_2" />
);
export const BoldEmptyWalletTick = (props) => (
  <Icon {...props} name="bold_empty_wallet_tick" />
);
export const BoldReceiptAdd = (props) => (
  <Icon {...props} name="bold_receipt_add" />
);
export const BoldMoney = (props) => <Icon {...props} name="bold_money" />;
export const BoldEmptyWalletTime = (props) => (
  <Icon {...props} name="bold_empty_wallet_time" />
);
export const BoldTransactionMinus = (props) => (
  <Icon {...props} name="bold_transaction_minus" />
);
export const BoldMoney2 = (props) => <Icon {...props} name="bold_money_2" />;
export const BoldEmptyWalletChange = (props) => (
  <Icon {...props} name="bold_empty_wallet_change" />
);
export const BoldMoney3 = (props) => <Icon {...props} name="bold_money_3" />;
export const BoldPercentageSquare = (props) => (
  <Icon {...props} name="bold_percentage_square" />
);
export const BoldMoney4 = (props) => <Icon {...props} name="bold_money_4" />;
export const BoldPercentageCircle = (props) => (
  <Icon {...props} name="bold_percentage_circle" />
);
export const BoldChartSquare = (props) => (
  <Icon {...props} name="bold_chart_square" />
);
export const BoldMoney5 = (props) => <Icon {...props} name="bold_money_5" />;
export const BoldCoin = (props) => <Icon {...props} name="bold_coin" />;
export const BoldReceiptEdit = (props) => (
  <Icon {...props} name="bold_receipt_edit" />
);
export const BoldMoneyTick = (props) => (
  <Icon {...props} name="bold_money_tick" />
);
export const BoldDiscountShape = (props) => (
  <Icon {...props} name="bold_discount_shape" />
);
export const BoldReceiptSearch = (props) => (
  <Icon {...props} name="bold_receipt_search" />
);
export const BoldMoneyAdd = (props) => (
  <Icon {...props} name="bold_money_add" />
);
export const BoldConvertCard = (props) => (
  <Icon {...props} name="bold_convert_card" />
);
export const BoldDiscountCircle = (props) => (
  <Icon {...props} name="bold_discount_circle" />
);
export const BoldWallet2 = (props) => <Icon {...props} name="bold_wallet_2" />;
export const BoldMoneyRemove = (props) => (
  <Icon {...props} name="bold_money_remove" />
);
export const BoldWalletMoney = (props) => (
  <Icon {...props} name="bold_wallet_money" />
);
export const BoldCardRemove = (props) => (
  <Icon {...props} name="bold_card_remove" />
);
export const BoldCards = (props) => <Icon {...props} name="bold_cards" />;
export const BoldMoneyForbidden = (props) => (
  <Icon {...props} name="bold_money_forbidden" />
);
export const BoldWallet3 = (props) => <Icon {...props} name="bold_wallet_3" />;
export const BoldCardTick = (props) => (
  <Icon {...props} name="bold_card_tick" />
);
export const BoldReceipt2 = (props) => (
  <Icon {...props} name="bold_receipt_2" />
);
export const BoldReceipt = (props) => <Icon {...props} name="bold_receipt" />;
export const BoldMoneyChange = (props) => (
  <Icon {...props} name="bold_money_change" />
);
export const BoldWalletRemove = (props) => (
  <Icon {...props} name="bold_wallet_remove" />
);
export const BoldMoneyTime = (props) => (
  <Icon {...props} name="bold_money_time" />
);
export const BoldReceiptText = (props) => (
  <Icon {...props} name="bold_receipt_text" />
);
export const BoldStrongbox = (props) => (
  <Icon {...props} name="bold_strongbox" />
);
export const BoldWallet4 = (props) => <Icon {...props} name="bold_wallet_4" />;
export const BoldSecurityCard = (props) => (
  <Icon {...props} name="bold_security_card" />
);
export const BoldMoneyReceive = (props) => (
  <Icon {...props} name="bold_money_receive" />
);
export const BoldMoneySend = (props) => (
  <Icon {...props} name="bold_money_send" />
);
export const BoldArchive = (props) => <Icon {...props} name="bold_archive" />;
export const BoldTag = (props) => <Icon {...props} name="bold_tag" />;
export const BoldTag2 = (props) => <Icon {...props} name="bold_tag_2" />;
export const BoldCoin2 = (props) => <Icon {...props} name="bold_coin_2" />;
export const BoldDocument = (props) => <Icon {...props} name="bold_document" />;
export const BoldWallet5 = (props) => <Icon {...props} name="bold_wallet_5" />;
export const BoldCardAdd = (props) => <Icon {...props} name="bold_card_add" />;
export const BoldCardRemove2 = (props) => (
  <Icon {...props} name="bold_card_remove_2" />
);
export const BoldCardTick2 = (props) => (
  <Icon {...props} name="bold_card_tick_2" />
);
export const BoldCardSlash = (props) => (
  <Icon {...props} name="bold_card_slash" />
);
export const BoldCardPos = (props) => <Icon {...props} name="bold_card_pos" />;
export const BoldCard = (props) => <Icon {...props} name="bold_card" />;
export const BoldCardReceive = (props) => (
  <Icon {...props} name="bold_card_receive" />
);
export const BoldCardSend = (props) => (
  <Icon {...props} name="bold_card_send" />
);
export const BoldCardEdit = (props) => (
  <Icon {...props} name="bold_card_edit" />
);
export const BoldWalletMinus = (props) => (
  <Icon {...props} name="bold_wallet_minus" />
);
export const BoldWalletAdd2 = (props) => (
  <Icon {...props} name="bold_wallet_add_2" />
);
export const BoldDollarSquare = (props) => (
  <Icon {...props} name="bold_dollar_square" />
);
export const BoldDollarCircle = (props) => (
  <Icon {...props} name="bold_dollar_circle" />
);
export const BoldReceipt3 = (props) => (
  <Icon {...props} name="bold_receipt_3" />
);
export const BoldReceipt4 = (props) => (
  <Icon {...props} name="bold_receipt_4" />
);
export const BoldReceiptDisscount = (props) => (
  <Icon {...props} name="bold_receipt_disscount" />
);
export const BoldTicket = (props) => <Icon {...props} name="bold_ticket" />;
export const BoldTicketStar = (props) => (
  <Icon {...props} name="bold_ticket_star" />
);
export const BoldTicketExpired = (props) => (
  <Icon {...props} name="bold_ticket_expired" />
);
export const BoldTicket2 = (props) => <Icon {...props} name="bold_ticket_2" />;
export const BoldTicketDiscount = (props) => (
  <Icon {...props} name="bold_ticket_discount" />
);
export const BoldMath = (props) => <Icon {...props} name="bold_math" />;

//Type, paragraph, character
export const BoldTextalingLeft = (props) => (
  <Icon {...props} name="bold_textaling_left" />
);
export const BoldTextalingCenter = (props) => (
  <Icon {...props} name="bold_textaling_center" />
);
export const BoldTextalingRiht = (props) => (
  <Icon {...props} name="bold_textaling_riht" />
);
export const BoldTextalingJustifyRight = (props) => (
  <Icon {...props} name="bold_textaling_justify_right" />
);
export const BoldTextalingJustifyCenter = (props) => (
  <Icon {...props} name="bold_textaling_justify_center" />
);
export const BoldTextalingJustifyLeft = (props) => (
  <Icon {...props} name="bold_textaling_justify_left" />
);
export const BoldFristline = (props) => (
  <Icon {...props} name="bold_fristline" />
);
export const BoldPaperclip2 = (props) => (
  <Icon {...props} name="bold_paperclip_2" />
);
export const BoldPaperclip = (props) => (
  <Icon {...props} name="bold_paperclip" />
);
export const BoldLink = (props) => <Icon {...props} name="bold_link" />;
export const BoldLink3 = (props) => <Icon {...props} name="bold_link_3" />;
export const BoldLink2 = (props) => <Icon {...props} name="bold_link_2" />;
export const BoldTextBlock = (props) => (
  <Icon {...props} name="bold_text_block" />
);
export const BoldTextAling = (props) => (
  <Icon {...props} name="bold_textaling" />
);
export const BoldText = (props) => <Icon {...props} name="bold_text" />;
export const BoldSmallcaps = (props) => (
  <Icon {...props} name="bold_smallcaps" />
);
export const BoldTextBold = (props) => <Icon {...props} name="bold_textBold" />;
export const BoldTextUnderline = (props) => (
  <Icon {...props} name="bold_text_underline" />
);
export const BoldPharagraphspacing = (props) => (
  <Icon {...props} name="bold_pharagraphspacing" />
);
export const BoldMaximize = (props) => <Icon {...props} name="bold_maximize" />;
export const BoldEraser = (props) => <Icon {...props} name="bold_eraser" />;
export const BoldAtthachSquare = (props) => (
  <Icon {...props} name="bold_atthach_square" />
);
export const BoldAttachCircle = (props) => (
  <Icon {...props} name="bold_attach_circle" />
);
export const BoldQuoteUp = (props) => <Icon {...props} name="bold_quote_up" />;
export const BoldQuoteDown = (props) => (
  <Icon {...props} name="bold_quote_down" />
);
export const BoldQuoteUpSquare = (props) => (
  <Icon {...props} name="bold_quote_up_square" />
);
export const BoldQuoteDownSquare = (props) => (
  <Icon {...props} name="bold_quote_down_square" />
);
export const BoldQuoteDownCircle = (props) => (
  <Icon {...props} name="bold_quote_down_circle" />
);
export const BoldQuoteUpCircle = (props) => (
  <Icon {...props} name="bold_quote_up_circle" />
);
export const BoldLink4 = (props) => <Icon {...props} name="bold_link_4" />;
export const BoldLinkSquare = (props) => (
  <Icon {...props} name="bold_link_square" />
);
export const BoldLinkCircle = (props) => (
  <Icon {...props} name="bold_link_circle" />
);
export const BoldTranslate = (props) => (
  <Icon {...props} name="bold_translate" />
);
export const BoldLangugeSquare = (props) => (
  <Icon {...props} name="bold_languge_square" />
);
export const BoldLanguageCircle = (props) => (
  <Icon {...props} name="bold_language_circle" />
);

//Weather
export const BoldMoon = (props) => <Icon {...props} name="bold_moon" />;
export const BoldSun = (props) => <Icon {...props} name="bold_sun" />;
export const BoldCloud = (props) => <Icon {...props} name="bold_cloud" />;
export const BoldCloudPlus = (props) => (
  <Icon {...props} name="bold_cloud_plus" />
);
export const BoldCloudMinus = (props) => (
  <Icon {...props} name="bold_cloud_minus" />
);
export const BoldCloudCross = (props) => (
  <Icon {...props} name="bold_cloud_cross" />
);
export const BoldCloudSunny = (props) => (
  <Icon {...props} name="bold_cloud_sunny" />
);
export const BoldCloudNotif = (props) => (
  <Icon {...props} name="bold_cloud_notif" />
);
export const BoldCloudDrizzle = (props) => (
  <Icon {...props} name="bold_cloud_drizzle" />
);
export const BoldCloudSnow = (props) => (
  <Icon {...props} name="bold_cloud_snow" />
);
export const BoldCloudLightning = (props) => (
  <Icon {...props} name="bold_cloud_lightning" />
);
export const BoldFlash = (props) => <Icon {...props} name="bold_flash" />;
export const BoldDrop = (props) => <Icon {...props} name="bold_drop" />;
export const BoldWind = (props) => <Icon {...props} name="bold_wind" />;
export const BoldWind2 = (props) => <Icon {...props} name="bold_wind_2" />;
export const BoldCloudFog = (props) => (
  <Icon {...props} name="bold_cloud_fog" />
);
export const BoldSunFog = (props) => <Icon {...props} name="bold_sun_fog" />;
export const BoldSun2 = (props) => <Icon {...props} name="bold_sun_2" />;

//Files
export const BoldFolder = (props) => <Icon {...props} name="bold_folder" />;
export const BoldFolderCross = (props) => (
  <Icon {...props} name="bold_folder_cross" />
);
export const BoldFolderAdd = (props) => (
  <Icon {...props} name="bold_folder_add" />
);
export const BoldFolderMinus = (props) => (
  <Icon {...props} name="bold_folder_minus" />
);
export const BoldFolderFavorite = (props) => (
  <Icon {...props} name="bold_folder_favorite" />
);
export const BoldFolder2 = (props) => <Icon {...props} name="bold_folder_2" />;
export const BoldFolderOpen = (props) => (
  <Icon {...props} name="bold_folder_open" />
);
export const BoldFolderCloud = (props) => (
  <Icon {...props} name="bold_folder_cloud" />
);

//Video. Audio, Image
export const BoldMicrophone = (props) => (
  <Icon {...props} name="bold_microphone" />
);
export const BoldMicrphoneSlash = (props) => (
  <Icon {...props} name="bold_micrphone_slash" />
);
export const BoldMicrophone2 = (props) => (
  <Icon {...props} name="bold_microphone_2" />
);
export const BoldMicrophoneSlash = (props) => (
  <Icon {...props} name="bold_microphone_slash" />
);
export const BoldVolumeCross = (props) => (
  <Icon {...props} name="bold_volume_cross" />
);
export const BoldVolumeSlash = (props) => (
  <Icon {...props} name="bold_volume_slash" />
);
export const BoldVolumeUp = (props) => (
  <Icon {...props} name="bold_volume_up" />
);
export const BoldVolumeLow = (props) => (
  <Icon {...props} name="bold_volume_low" />
);
export const BoldVolumeLow2 = (props) => (
  <Icon {...props} name="bold_volume_low_2" />
);
export const BoldVolumeHigh = (props) => (
  <Icon {...props} name="bold_volume_high" />
);
export const BoldVolumeMute = (props) => (
  <Icon {...props} name="bold_volume_mute" />
);
export const BoldVideo = (props) => <Icon {...props} name="bold_video" />;
export const BoldVideoSlash = (props) => (
  <Icon {...props} name="bold_video_slash" />
);
export const BoldVideoSquare = (props) => (
  <Icon {...props} name="bold_video_square" />
);
export const BoldVideoCircle = (props) => (
  <Icon {...props} name="bold_video_circle" />
);
export const BoldGallery = (props) => <Icon {...props} name="bold_gallery" />;
export const BoldGalleryFavorite = (props) => (
  <Icon {...props} name="bold_gallery_favorite" />
);
export const BoldGallerySlash = (props) => (
  <Icon {...props} name="bold_gallery_slash" />
);
export const BoldGalleryTick = (props) => (
  <Icon {...props} name="bold_gallery_tick" />
);
export const BoldGalleryImport = (props) => (
  <Icon {...props} name="bold_gallery_import" />
);
export const BoldGalleryExport = (props) => (
  <Icon {...props} name="bold_gallery_export" />
);
export const BoldGalleryAdd = (props) => (
  <Icon {...props} name="bold_gallery_add" />
);
export const BoldGalleryRemove = (props) => (
  <Icon {...props} name="bold_gallery_remove" />
);
export const BoldGalleryEdit = (props) => (
  <Icon {...props} name="bold_gallery_edit" />
);
export const BoldImage = (props) => <Icon {...props} name="bold_image" />;
export const BoldScrenmirroring = (props) => (
  <Icon {...props} name="bold_screnmirroring" />
);
export const BoldCamera = (props) => <Icon {...props} name="bold_camera" />;
export const BoldCameraSlash = (props) => (
  <Icon {...props} name="bold_camera_slash" />
);
export const BoldPlay = (props) => <Icon {...props} name="bold_play" />;
export const BoldRecord = (props) => <Icon {...props} name="bold_record" />;
export const BoldStop = (props) => <Icon {...props} name="bold_stop" />;
export const BoldMusicnote = (props) => (
  <Icon {...props} name="bold_musicnote" />
);
export const BoldMusic = (props) => <Icon {...props} name="bold_music" />;
export const BoldVideoVertical = (props) => (
  <Icon {...props} name="bold_video_vertical" />
);
export const BoldVideoHorizontal = (props) => (
  <Icon {...props} name="bold_video_horizontal" />
);
export const BoldVideoPlay = (props) => (
  <Icon {...props} name="bold_video_play" />
);
export const BoldSubtitle = (props) => <Icon {...props} name="bold_subtitle" />;
export const BoldPlayCircle = (props) => (
  <Icon {...props} name="bold_play_circle" />
);
export const BoldRecordCircle = (props) => (
  <Icon {...props} name="bold_record_circle" />
);
export const BoldStopCircle = (props) => (
  <Icon {...props} name="bold_stop_circle" />
);
export const BoldPauseCircle = (props) => (
  <Icon {...props} name="bold_pause_circle" />
);
export const BoldPause = (props) => <Icon {...props} name="bold_pause" />;
export const BoldScissor = (props) => <Icon {...props} name="bold_scissor" />;
export const BoldBackward = (props) => <Icon {...props} name="bold_backward" />;
export const BoldForward = (props) => <Icon {...props} name="bold_forward" />;
export const BoldPrevious = (props) => <Icon {...props} name="bold_previous" />;
export const BoldNext = (props) => <Icon {...props} name="bold_next" />;
export const BoldRadio = (props) => <Icon {...props} name="bold_radio" />;
export const BoldMusicCircle = (props) => (
  <Icon {...props} name="bold_music_circle" />
);
export const BoldPlayCircle2 = (props) => (
  <Icon {...props} name="bold_play_circle_2" />
);
export const BoldPlayRemove = (props) => (
  <Icon {...props} name="bold_play_remove" />
);
export const BoldPlayAdd = (props) => <Icon {...props} name="bold_play_add" />;
export const BoldBackward5Seconds = (props) => (
  <Icon {...props} name="bold_backward_5_seconds" />
);
export const BoldBackward10Seconds = (props) => (
  <Icon {...props} name="bold_backward_10_seconds" />
);
export const BoldBackward15Seconds = (props) => (
  <Icon {...props} name="bold_backward_15_seconds" />
);
export const BoldForward5Seconds = (props) => (
  <Icon {...props} name="bold_forward_5_seconds" />
);
export const BoldForward10Seconds = (props) => (
  <Icon {...props} name="bold_forward_10_seconds" />
);
export const BoldForward15Seconds = (props) => (
  <Icon {...props} name="bold_forward_15_seconds" />
);
export const BoldMusicPlaylist = (props) => (
  <Icon {...props} name="bold_music_playlist" />
);
export const BoldMusicFilter = (props) => (
  <Icon {...props} name="bold_music_filter" />
);
export const BoldMusicDashboard = (props) => (
  <Icon {...props} name="bold_music_dashboard" />
);
export const BoldMusicSquareRemove = (props) => (
  <Icon {...props} name="bold_music_square_remove" />
);
export const BoldMusicSquareAdd = (props) => (
  <Icon {...props} name="bold_music_square_add" />
);
export const BoldMusicSquareSearch = (props) => (
  <Icon {...props} name="bold_music_square_search" />
);
export const BoldRepeatMusic = (props) => (
  <Icon {...props} name="bold_repeat_music" />
);
export const BoldRepeatOne = (props) => (
  <Icon {...props} name="bold_repeat_one" />
);
export const BoldShuffle = (props) => <Icon {...props} name="bold_shuffle" />;
export const BoldDevices = (props) => <Icon {...props} name="bold_devices" />;
export const BoldMusicLibrary2 = (props) => (
  <Icon {...props} name="bold_music_library_2" />
);
export const BoldMusicSquare = (props) => (
  <Icon {...props} name="bold_music_square" />
);
export const BoldAudioSquare = (props) => (
  <Icon {...props} name="bold_audio_square" />
);
export const BoldNoteSquare = (props) => (
  <Icon {...props} name="bold_note_square" />
);
export const BoldMiniMusicSquare = (props) => (
  <Icon {...props} name="bold_mini_music_square" />
);
export const BoldVideoAdd = (props) => (
  <Icon {...props} name="bold_video_add" />
);
export const BoldVideoRemove = (props) => (
  <Icon {...props} name="bold_video_remove" />
);
export const BoldVideoTick = (props) => (
  <Icon {...props} name="bold_video_tick" />
);
export const BoldVideoTime = (props) => (
  <Icon {...props} name="bold_video_time" />
);
export const BoldVideoOctagon = (props) => (
  <Icon {...props} name="bold_video_octagon" />
);
export const BoldVoiceSquare = (props) => (
  <Icon {...props} name="bold_voice_square" />
);
export const BoldVoiceCircle = (props) => (
  <Icon {...props} name="bold_voice_circle" />
);
export const BoldMaximizeCircle = (props) => (
  <Icon {...props} name="bold_maximize_circle" />
);

//Arrow
export const BoldReceived = (props) => <Icon {...props} name="bold_received" />;
export const BoldSend = (props) => <Icon {...props} name="bold_send" />;
export const BoldArrow2 = (props) => <Icon {...props} name="bold_arrow_2" />;
export const BoldArrow3 = (props) => <Icon {...props} name="bold_arrow_3" />;
export const BoldArrowSquareUp = (props) => (
  <Icon {...props} name="bold_arrow_square_up" />
);
export const BoldArrowSquareDown = (props) => (
  <Icon {...props} name="bold_arrow_square_down" />
);
export const BoldArrowSquareLeft = (props) => (
  <Icon {...props} name="bold_rrow_square_left" />
);
export const BoldArrowSquareRight = (props) => (
  <Icon {...props} name="bold_arrow_square_right" />
);
export const BoldArrowRight = (props) => (
  <Icon {...props} name="bold_arrow_right" />
);
export const BoldArrowLeft = (props) => (
  <Icon {...props} name="bold_arrow_left" />
);
export const BoldArrowUp = (props) => <Icon {...props} name="bold_arrow_up" />;
export const BoldArrowDown = (props) => (
  <Icon {...props} name="bold_arrow_down" />
);
export const BoldArrowUp2 = (props) => (
  <Icon {...props} name="bold_arrow_up_2" />
);
export const BoldArrowDown2 = (props) => (
  <Icon {...props} name="bold_arrow_down_2" />
);
export const BoldArrowRight2 = (props) => (
  <Icon {...props} name="bold_arrow_right_2" />
);
export const BoldArrowLeft2 = (props) => (
  <Icon {...props} name="bold_arrow_left_2" />
);
export const BoldArrowCircleLeft = (props) => (
  <Icon {...props} name="bold_arrow_circle_left" />
);
export const BoldArrowCircleRight = (props) => (
  <Icon {...props} name="bold_arrow_circle_right" />
);
export const BoldArrowCircleDown = (props) => (
  <Icon {...props} name="bold_arrow_circle_down" />
);
export const BoldArrowCircleUp = (props) => (
  <Icon {...props} name="bold_arrow_circle_up" />
);
export const BoldArrowSwap = (props) => (
  <Icon {...props} name="bold_arrow_swap" />
);
export const BoldArrowSwapHorizontal = (props) => (
  <Icon {...props} name="bold_arrow_swap_horizontal" />
);
export const BoldUndo = (props) => <Icon {...props} name="bold_undo" />;
export const BoldRedo = (props) => <Icon {...props} name="bold_redo" />;
export const BoldRoteateLeft = (props) => (
  <Icon {...props} name="bold_roteate_left" />
);
export const BoldRotateRight = (props) => (
  <Icon {...props} name="bold_rotate_right" />
);
export const BoldRefresh2 = (props) => (
  <Icon {...props} name="bold_refresh_2" />
);
export const BoldArrowLeft3 = (props) => (
  <Icon {...props} name="bold_arrow_left_3" />
);
export const BoldArrowRight3 = (props) => (
  <Icon {...props} name="bold_arrow_right_3" />
);
export const BoldArrowUp3 = (props) => (
  <Icon {...props} name="bold_arrow_up_3" />
);
export const BoldArrowBottom = (props) => (
  <Icon {...props} name="bold_arrow_bottom" />
);
export const BoldArrowUp4 = (props) => (
  <Icon {...props} name="bold_arrow_up_4" />
);
export const BoldArrowRight4 = (props) => (
  <Icon {...props} name="bold_arrow_right_4" />
);
export const BoldArrowLeft4 = (props) => (
  <Icon {...props} name="bold_arrow_left_4" />
);
export const BoldArrowDown3 = (props) => (
  <Icon {...props} name="bold_arrow_down_3" />
);
export const BoldConvert = (props) => <Icon {...props} name="bold_convert" />;
export const BoldArrangeSquare = (props) => (
  <Icon {...props} name="bold_arrange_square" />
);
export const BoldArrangeSquare2 = (props) => (
  <Icon {...props} name="bold_arrange_square_2" />
);
export const BoldBackSquare = (props) => (
  <Icon {...props} name="bold_back_square" />
);
export const BoldForwardSquare = (props) => (
  <Icon {...props} name="bold_forward_square" />
);
export const BoldRefreshSquare2 = (props) => (
  <Icon {...props} name="bold_refresh_square_2" />
);
export const BoldRefreshLeftSquare = (props) => (
  <Icon {...props} name="bold_refresh_left_square" />
);
export const BoldRefreshRightSquare = (props) => (
  <Icon {...props} name="bold_refresh_right_square" />
);
export const BoldReceiveSquare = (props) => (
  <Icon {...props} name="bold_receive_square" />
);
export const BoldSendSquare = (props) => (
  <Icon {...props} name="bold_send_square" />
);
export const BoldSendSquare2 = (props) => (
  <Icon {...props} name="bold_send_square_2" />
);
export const BoldReceiveSquare2 = (props) => (
  <Icon {...props} name="bold_receive_square_2" />
);
export const BoldLogin = (props) => <Icon {...props} name="bold_login" />;
export const BoldLogout = (props) => <Icon {...props} name="bold_logout" />;
export const BoldImport = (props) => <Icon {...props} name="bold_import" />;
export const BoldExport = (props) => <Icon {...props} name="bold_export" />;
export const BoldExport2 = (props) => <Icon {...props} name="bold_export_2" />;
export const BoldImport2 = (props) => <Icon {...props} name="bold_import_2" />;
export const BoldLogin2 = (props) => <Icon {...props} name="bold_login_2" />;
export const BoldLogout2 = (props) => <Icon {...props} name="bold_logout_2" />;
export const BoldExport3 = (props) => <Icon {...props} name="bold_export_3" />;
export const BoldImport3 = (props) => <Icon {...props} name="bold_import_3" />;
export const BoldImport4 = (props) => <Icon {...props} name="bold_import_4" />;
export const BoldExport4 = (props) => <Icon {...props} name="bold_export_4" />;
export const BoldRefresh3 = (props) => (
  <Icon {...props} name="bold_refresh_3" />
);
export const BoldArrangeCircle = (props) => (
  <Icon {...props} name="bold_arrange_circle" />
);
export const BoldArrangeCircle2 = (props) => (
  <Icon {...props} name="bold_arrange_circle_2" />
);
export const BoldRepeat = (props) => <Icon {...props} name="bold_repeat" />;
export const BoldRepeatCircle = (props) => (
  <Icon {...props} name="bold_repeat_circle" />
);
export const BoldRefreshCircle = (props) => (
  <Icon {...props} name="bold_refresh_circle" />
);

//Users
export const BoldUser = (props) => <Icon {...props} name="bold_user" />;
export const BoldUserAdd = (props) => <Icon {...props} name="bold_user_add" />;
export const BoldUserRemove = (props) => (
  <Icon {...props} name="bold_user_remove" />
);
export const BoldUserTick = (props) => (
  <Icon {...props} name="bold_user_tick" />
);
export const BoldUserMinus = (props) => (
  <Icon {...props} name="bold_user_minus" />
);
export const BoldUserSearch = (props) => (
  <Icon {...props} name="bold_user_search" />
);
export const BoldUserEdit = (props) => (
  <Icon {...props} name="bold_user_edit" />
);
export const BoldUserTag = (props) => <Icon {...props} name="bold_user_tag" />;
export const BoldUserOctagon = (props) => (
  <Icon {...props} name="bold_user_octagon" />
);
export const BoldUserCircleAdd = (props) => (
  <Icon {...props} name="bold_user_circle_add" />
);
export const BoldUserSquare = (props) => (
  <Icon {...props} name="bold_user_square" />
);
export const BoldFrame = (props) => <Icon {...props} name="bold_frame" />;
export const BoldProfile2User = (props) => (
  <Icon {...props} name="bold_profile_2_ser" />
);
export const BoldProfileRemove = (props) => (
  <Icon {...props} name="bold_profile_remove" />
);
export const BoldProfileTick = (props) => (
  <Icon {...props} name="bold_profile_tick" />
);
export const BoldProfileAdd = (props) => (
  <Icon {...props} name="bold_profile_add" />
);
export const BoldProfileDelete = (props) => (
  <Icon {...props} name="bold_profile_delete" />
);
export const BoldProfileCircle = (props) => (
  <Icon {...props} name="bold_profile_circle" />
);
export const BoldTagUser = (props) => <Icon {...props} name="bold_tag_user" />;
export const BoldPeople = (props) => <Icon {...props} name="bold_people" />;

//Car
export const BoldAirplane = (props) => <Icon {...props} name="bold_airplane" />;
export const BoldCar = (props) => <Icon {...props} name="bold_car" />;
export const BoldBus = (props) => <Icon {...props} name="bold_bus" />;
export const BoldSmartCar = (props) => (
  <Icon {...props} name="bold_smart_car" />
);
export const BoldDriving = (props) => <Icon {...props} name="bold_driving" />;
export const BoldShip = (props) => <Icon {...props} name="bold_ship" />;
export const BoldAirplaneSquare = (props) => (
  <Icon {...props} name="bold_airplane_square" />
);
export const BoldGasStation = (props) => (
  <Icon {...props} name="bold_gas_station" />
);

//Emails,Messages
export const BoldDirectboxDefault = (props) => (
  <Icon {...props} name="bold_directbox_default" />
);
export const BoldDirectboxNotif = (props) => (
  <Icon {...props} name="bold_directbox_notif" />
);
export const BoldDirectboxReceive = (props) => (
  <Icon {...props} name="bold_directbox_receive" />
);
export const BoldDirectboxSend = (props) => (
  <Icon {...props} name="bold_directbox_send" />
);
export const BoldDirectNormal = (props) => (
  <Icon {...props} name="bold_direct_normal" />
);
export const BoldDirect = (props) => <Icon {...props} name="bold_direct" />;
export const BoldDirectNotification = (props) => (
  <Icon {...props} name="bold_direct_notification" />
);
export const BoldDirectInbox = (props) => (
  <Icon {...props} name="bold_direct_inbox" />
);
export const BoldDirectSend = (props) => (
  <Icon {...props} name="bold_direct_send" />
);
export const BoldMessage = (props) => <Icon {...props} name="bold_message" />;
export const BoldMessageFavorite = (props) => (
  <Icon {...props} name="bold_message_favorite" />
);
export const BoldMessageNotif = (props) => (
  <Icon {...props} name="bold_message_notif" />
);
export const BoldMessageSearch = (props) => (
  <Icon {...props} name="bold_message_search" />
);
export const BoldMessageText = (props) => (
  <Icon {...props} name="bold_message_text" />
);
export const BoldDeviceMessage = (props) => (
  <Icon {...props} name="bold_device_message" />
);
export const BoldMessages = (props) => <Icon {...props} name="bold_messages" />;
export const BoldMessages2 = (props) => (
  <Icon {...props} name="bold_messages_2" />
);
export const BoldMessages3 = (props) => (
  <Icon {...props} name="bold_messages_3" />
);
export const BoldSms = (props) => <Icon {...props} name="bold_sms" />;
export const BoldSmsTracking = (props) => (
  <Icon {...props} name="bold_sms_tracking" />
);
export const BoldSmsNotification = (props) => (
  <Icon {...props} name="bold_sms_notification" />
);
export const BoldSmsEdit = (props) => <Icon {...props} name="bold_sms_edit" />;
export const BoldSmsStar = (props) => <Icon {...props} name="bold_sms_star" />;
export const BoldSmsSearch = (props) => (
  <Icon {...props} name="bold_sms_search" />
);
export const BoldMessageRemove = (props) => (
  <Icon {...props} name="bold_message_remove" />
);
export const BoldMessageAdd = (props) => (
  <Icon {...props} name="bold_message_add" />
);
export const BoldMessageTick = (props) => (
  <Icon {...props} name="bold_message_tick" />
);
export const BoldMessageTime = (props) => (
  <Icon {...props} name="bold_message_time" />
);
export const BoldMessage2 = (props) => (
  <Icon {...props} name="bold_message_2" />
);
export const BoldMessages4 = (props) => (
  <Icon {...props} name="bold_messages_4" />
);
export const BoldMessageCircle = (props) => (
  <Icon {...props} name="bold_message_circle" />
);
export const BoldMessageSquare = (props) => (
  <Icon {...props} name="bold_message_square" />
);
export const BoldMessageText2 = (props) => (
  <Icon {...props} name="bold_message_text_2" />
);
export const BoldMessageMinus = (props) => (
  <Icon {...props} name="bold_message_minus" />
);
export const BoldMessageAdd2 = (props) => (
  <Icon {...props} name="bold_message_add_2" />
);
export const BoldMessageEdit = (props) => (
  <Icon {...props} name="bold_message_edit" />
);

//Location
export const BoldLocationMinus = (props) => (
  <Icon {...props} name="bold_location_minus" />
);
export const BoldLocationCross = (props) => (
  <Icon {...props} name="bold_location_cross" />
);
export const BoldLocationTick = (props) => (
  <Icon {...props} name="bold_location_tick" />
);
export const BoldLocationSlash = (props) => (
  <Icon {...props} name="bold_location_slash" />
);
export const BoldDiscover = (props) => <Icon {...props} name="bold_discover" />;
export const BoldLocation = (props) => <Icon {...props} name="bold_location" />;
export const BoldLocationAdd = (props) => (
  <Icon {...props} name="bold_location_add" />
);
export const BoldRadar = (props) => <Icon {...props} name="bold_radar" />;
export const BoldRadar2 = (props) => <Icon {...props} name="bold_radar_2" />;
export const BoldGlobalSearch = (props) => (
  <Icon {...props} name="bold_global_search" />
);
export const BoldGlobalEdit = (props) => (
  <Icon {...props} name="bold_global_edit" />
);
export const BoldGlobalRefresh = (props) => (
  <Icon {...props} name="bold_global_refresh" />
);
export const BoldGlobal = (props) => <Icon {...props} name="bold_global" />;
export const BoldDirectUp = (props) => (
  <Icon {...props} name="bold_direct_up" />
);
export const BoldDirectDown = (props) => (
  <Icon {...props} name="bold_direct_down" />
);
export const BoldDirectLeft = (props) => (
  <Icon {...props} name="bold_direct_left" />
);
export const BoldDirectRight = (props) => (
  <Icon {...props} name="bold_direct_right" />
);
export const BoldMap = (props) => <Icon {...props} name="bold_map" />;
export const BoldGps = (props) => <Icon {...props} name="bold_gps" />;
export const BoldGpsSlash = (props) => (
  <Icon {...props} name="bold_gps_slash" />
);
export const BoldMap2 = (props) => <Icon {...props} name="bold_map_2" />;
export const BoldRouting = (props) => <Icon {...props} name="bold_routing" />;
export const BoldRouting2 = (props) => (
  <Icon {...props} name="bold_routing_2" />
);
export const BoldArrowSquare = (props) => (
  <Icon {...props} name="bold_arrow_square" />
);
export const BoldPictureFrame = (props) => (
  <Icon {...props} name="bold_picture_frame" />
);
export const BoldArrow = (props) => <Icon {...props} name="bold_arrow" />;
export const BoldRouteSquare = (props) => (
  <Icon {...props} name="bold_route_square" />
);

//Scholl, Learning
export const BoldBriefcase = (props) => (
  <Icon {...props} name="bold_briefcase" />
);
export const BoldBriefcaseTimer = (props) => (
  <Icon {...props} name="bold_briefcase_timer" />
);
export const BoldBriefcaseTick = (props) => (
  <Icon {...props} name="bold_briefcase_tick" />
);
export const BoldBriefcaseCross = (props) => (
  <Icon {...props} name="bold_briefcase_cross" />
);
export const BoldBookmark = (props) => <Icon {...props} name="bold_bookmark" />;
export const BoldAward = (props) => <Icon {...props} name="bold_award" />;
export const BoldBook = (props) => <Icon {...props} name="bold_book" />;
export const BoldBook3 = (props) => <Icon {...props} name="bold_book_3" />;
export const BoldBookmark2 = (props) => (
  <Icon {...props} name="bold_bookmark_2" />
);
export const BoldClipboard = (props) => (
  <Icon {...props} name="bold_clipboard" />
);
export const BoldNote = (props) => <Icon {...props} name="bold_note" />;
export const BoldNote2 = (props) => <Icon {...props} name="bold_note_2" />;
export const BoldTeacher = (props) => <Icon {...props} name="bold_teacher" />;
export const BoldGift = (props) => <Icon {...props} name="bold_gift" />;
export const BoldCalculator = (props) => (
  <Icon {...props} name="bold_calculator" />
);

//Delivery
export const BoldBox = (props) => <Icon {...props} name="bold_box" />;
export const Bold3dCubeScan = (props) => (
  <Icon {...props} name="bold_3d_cube_scan" />
);
export const BoldConvert3dCube = (props) => (
  <Icon {...props} name="bold_convert_3d_cube" />
);
export const BoldBoxAdd = (props) => <Icon {...props} name="bold_box_add" />;
export const BoldBoxRemove = (props) => (
  <Icon {...props} name="bold_box_remove" />
);
export const BoldBoxTick = (props) => <Icon {...props} name="bold_box_tick" />;
export const BoldBoxTime = (props) => <Icon {...props} name="bold_box_time" />;
export const BoldBox2 = (props) => <Icon {...props} name="bold_box_2" />;
export const BoldBoxSearch = (props) => (
  <Icon {...props} name="bold_box_search" />
);
export const BoldTruck = (props) => <Icon {...props} name="bold_truck" />;
export const BoldTruckFast = (props) => (
  <Icon {...props} name="bold_truck_fast" />
);
export const BoldTruckTick = (props) => (
  <Icon {...props} name="bold_truck_tick" />
);
export const BoldTruckTime = (props) => (
  <Icon {...props} name="bold_truck_time" />
);
export const BoldTruckRemove = (props) => (
  <Icon {...props} name="bold_truck_remove" />
);
export const Bold3dSquare = (props) => (
  <Icon {...props} name="bold_3d_square" />
);
export const Bold3dRotate = (props) => (
  <Icon {...props} name="bold_3d_rotate" />
);

//Security
export const BoldEye = (props) => <Icon {...props} name="bold_eye" />;
export const BoldEyeSlash = (props) => (
  <Icon {...props} name="bold_eye_slash" />
);
export const BoldFrame2 = (props) => <Icon {...props} name="bold_frame_2" />;
export const BoldShieldTick = (props) => (
  <Icon {...props} name="bold_shield_tick" />
);
export const BoldShieldCross = (props) => (
  <Icon {...props} name="bold_shield_cross" />
);
export const BoldSheildSlash = (props) => (
  <Icon {...props} name="bold_sheild_slash" />
);
export const BoldSheildSearch = (props) => (
  <Icon {...props} name="bold_sheild_search" />
);
export const BoldShieldSecurity = (props) => (
  <Icon {...props} name="bold_shield_security" />
);
export const BoldPasswordCheck = (props) => (
  <Icon {...props} name="bold_password_check" />
);
export const BoldKey = (props) => <Icon {...props} name="bold_key" />;
export const BoldKeySquare = (props) => (
  <Icon {...props} name="bold_key_square" />
);
export const BoldCheck = (props) => <Icon {...props} name="bold_check" />;
export const BoldLock = (props) => <Icon {...props} name="bold_lock" />;
export const BoldUnlock = (props) => <Icon {...props} name="bold_unlock" />;
export const BoldLockSlash = (props) => (
  <Icon {...props} name="bold_lock_slash" />
);
export const BoldScan = (props) => <Icon {...props} name="bold_scan" />;
export const BoldScanBarcode = (props) => (
  <Icon {...props} name="bold_scan_barcode" />
);
export const BoldScanning = (props) => <Icon {...props} name="bold_scanning" />;
export const BoldFingerScan = (props) => (
  <Icon {...props} name="bold_finger_scan" />
);
export const BoldFingerCircle = (props) => (
  <Icon {...props} name="bold_finger_circle" />
);
export const BoldScanner = (props) => <Icon {...props} name="bold_scanner" />;
export const BoldSecurityUser = (props) => (
  <Icon {...props} name="bold_security_user" />
);
export const BoldLock2 = (props) => <Icon {...props} name="bold_lock_2" />;
export const BoldLockCircle = (props) => (
  <Icon {...props} name="bold_lock_circle" />
);
export const BoldAlarm = (props) => <Icon {...props} name="bold_alarm" />;
export const BoldRadar3 = (props) => <Icon {...props} name="bold_radar_3" />;
export const BoldSecuritySafe = (props) => (
  <Icon {...props} name="bold_security_safe" />
);
export const BoldSecurity = (props) => <Icon {...props} name="bold_security" />;
//

//Desing, Tools
export const BoldBezier = (props) => <Icon {...props} name="bold_bezier" />;
export const BoldBrush = (props) => <Icon {...props} name="bold_brush" />;
export const BoldBrush2 = (props) => <Icon {...props} name="bold_brush_2" />;
export const BoldMagicpen = (props) => <Icon {...props} name="bold_magicpen" />;
export const BoldPenTool = (props) => <Icon {...props} name="bold_pen_tool" />;
export const BoldBucket = (props) => <Icon {...props} name="bold_bucket" />;
export const BoldPaintbucket = (props) => (
  <Icon {...props} name="bold_paintbucket" />
);
export const BoldAdditem = (props) => <Icon {...props} name="bold_additem" />;
export const BoldRuler = (props) => <Icon {...props} name="bold_ruler" />;
export const BoldDesigntools = (props) => (
  <Icon {...props} name="bold_designtools" />
);
export const BoldShapes = (props) => <Icon {...props} name="bold_shapes" />;
export const BoldLifebuoy = (props) => <Icon {...props} name="bold_lifebuoy" />;
export const BoldPenTool2 = (props) => (
  <Icon {...props} name="bold_pen_tool_2" />
);
export const BoldBrush3 = (props) => <Icon {...props} name="bold_brush_3" />;
export const BoldPenClose = (props) => (
  <Icon {...props} name="bold_pen_close" />
);
export const BoldPenAdd = (props) => <Icon {...props} name="bold_pen_add" />;
export const BoldPenRemove = (props) => (
  <Icon {...props} name="bold_pen_remove" />
);
export const BoldPath = (props) => <Icon {...props} name="bold_path" />;
export const BoldPath2 = (props) => <Icon {...props} name="bold_path_2" />;
export const BoldBucketCircle = (props) => (
  <Icon {...props} name="bold_bucket_circle" />
);
export const BoldBucketSquare = (props) => (
  <Icon {...props} name="bold_bucket_square" />
);
export const BoldBrush4 = (props) => <Icon {...props} name="bold_brush_4" />;
export const BoldScissor2 = (props) => (
  <Icon {...props} name="bold_scissor_2" />
);
export const BoldGlass = (props) => <Icon {...props} name="bold_glass" />;
export const BoldRulerPen = (props) => (
  <Icon {...props} name="bold_ruler_pen" />
);
export const BoldColorSwatch = (props) => (
  <Icon {...props} name="bold_color_swatch" />
);
export const BoldBlend2 = (props) => <Icon {...props} name="bold_blend_2" />;
export const BoldPathSquare = (props) => (
  <Icon {...props} name="bold_path_square" />
);
export const BoldOmegaCircle = (props) => (
  <Icon {...props} name="bold_omega_circle" />
);
export const BoldOmegaSquare = (props) => (
  <Icon {...props} name="bold_omega_square" />
);
export const BoldSize = (props) => <Icon {...props} name="bold_size" />;
export const BoldShapes2 = (props) => <Icon {...props} name="bold_shapes_2" />;
export const BoldFlashCircle = (props) => (
  <Icon {...props} name="bold_flash_circle" />
);
export const BoldEraser2 = (props) => <Icon {...props} name="bold_eraser_2" />;
export const BoldMask = (props) => <Icon {...props} name="bold_mask" />;
export const BoldBlur = (props) => <Icon {...props} name="bold_blur" />;
export const BoldBlend3 = (props) => <Icon {...props} name="bold_blend_3" />;
export const BoldColorfilter = (props) => (
  <Icon {...props} name="bold_colorfilter" />
);
export const BoldColorsSquare = (props) => (
  <Icon {...props} name="bold_colors_square" />
);
export const BoldMask2 = (props) => <Icon {...props} name="bold_mask_2" />;
export const BoldBrush5 = (props) => <Icon {...props} name="bold_brush_5" />;
export const BoldLayer = (props) => <Icon {...props} name="bold_layer" />;
export const BoldCopy = (props) => <Icon {...props} name="bold_copy" />;
export const BoldCopySucces = (props) => (
  <Icon {...props} name="bold_copy_succes" />
);
export const BoldRecoveryConvert = (props) => (
  <Icon {...props} name="bold_recovery_convert" />
);
export const BoldMainComponent = (props) => (
  <Icon {...props} name="bold_main_component" />
);
export const BoldComponent = (props) => (
  <Icon {...props} name="bold_component" />
);
export const BoldMask3 = (props) => <Icon {...props} name="bold_mask_3" />;
export const BoldForwardItem = (props) => (
  <Icon {...props} name="bold_forward_item" />
);
export const BoldBackwardItem = (props) => (
  <Icon {...props} name="bold_backward_item" />
);

//Grid
export const BoldKanban = (props) => <Icon {...props} name="bold_kanban" />;
export const BoldFatrows = (props) => <Icon {...props} name="bold_fatrows" />;
export const BoldRowVertical = (props) => (
  <Icon {...props} name="bold_row_vertical" />
);
export const BoldRowHorizontal = (props) => (
  <Icon {...props} name="bold_row_horizontal" />
);
export const BoldSliderHorizontal = (props) => (
  <Icon {...props} name="bold_slider_horizontal" />
);
export const BoldSliderVertical = (props) => (
  <Icon {...props} name="bold_slider_vertical" />
);
export const BoldSliderVertical2 = (props) => (
  <Icon {...props} name="bold_slider_horizontal_2" />
);
export const BoldGrid4 = (props) => <Icon {...props} name="bold_grid_4" />;
export const BoldGrid9 = (props) => <Icon {...props} name="bold_grid_9" />;
export const BoldGrid2 = (props) => <Icon {...props} name="bold_grid_2" />;
export const BoldGrid3 = (props) => <Icon {...props} name="bold_grid_3" />;
export const BoldGrid5 = (props) => <Icon {...props} name="bold_grid_5" />;
export const BoldGrid6 = (props) => <Icon {...props} name="bold_grid_6" />;
export const BoldGrid7 = (props) => <Icon {...props} name="bold_grid_7" />;
export const BoldGrid8 = (props) => <Icon {...props} name="bold_grid_8" />;
export const BoldGrid1 = (props) => <Icon {...props} name="bold_grid_1" />;
export const BoldElement2 = (props) => (
  <Icon {...props} name="bold_element_2" />
);
export const BoldFrame3 = (props) => <Icon {...props} name="bold_frame_3" />;
export const BoldElement3 = (props) => (
  <Icon {...props} name="bold_element_3" />
);
export const BoldElement4 = (props) => (
  <Icon {...props} name="bold_element_4" />
);
export const BoldElelementPlus = (props) => (
  <Icon {...props} name="bold_elelement_plus" />
);
export const BoldElementEqual = (props) => (
  <Icon {...props} name="bold_element_equal" />
);
export const Bold3Square = (props) => <Icon {...props} name="bold_3_square" />;
export const BoldAlingVertically = (props) => (
  <Icon {...props} name="bold_aling_vertically" />
);
export const BoldAlignHorizontal = (props) => (
  <Icon {...props} name="bold_align_horizontal" />
);
export const BoldAlignLeft = (props) => (
  <Icon {...props} name="bold_align_left" />
);
export const BoldAlignRight = (props) => (
  <Icon {...props} name="bold_align_right" />
);
export const BoldAlignBottom = (props) => (
  <Icon {...props} name="bold_align_bottom" />
);
export const BoldFrame4 = (props) => <Icon {...props} name="bold_frame_4" />;
export const BoldMaximize1 = (props) => (
  <Icon {...props} name="bold_maximize_1" />
);
export const BoldMaximize2 = (props) => (
  <Icon {...props} name="bold_maximize_2" />
);
export const BoldMaximize3 = (props) => (
  <Icon {...props} name="bold_maximize_3" />
);
export const BoldMaximize4 = (props) => (
  <Icon {...props} name="bold_maximize_4" />
);
export const BoldFormatCircle = (props) => (
  <Icon {...props} name="bold_format_circle" />
);
export const BoldFormatSquare = (props) => (
  <Icon {...props} name="bold_format_square" />
);
export const BoldRotateLeft = (props) => (
  <Icon {...props} name="bold_rotate_left" />
);
export const BoldRotateRight2 = (props) => (
  <Icon {...props} name="bold_rotate_right_2" />
);
export const BoldCrop = (props) => <Icon {...props} name="bold_crop" />;
export const BoldMaximize5 = (props) => (
  <Icon {...props} name="boldMaximize_5" />
);
export const BoldConvertshape = (props) => (
  <Icon {...props} name="bold_convertshape" />
);
export const BoldConvertshape2 = (props) => (
  <Icon {...props} name="bold_convertshape_2" />
);
export const BoldGridEraser = (props) => (
  <Icon {...props} name="bold_grid_eraser" />
);
export const BoldGridLock = (props) => (
  <Icon {...props} name="bold_grid_lock" />
);
export const BoldGridEdit = (props) => (
  <Icon {...props} name="bold_ grid_edit" />
);

//Shop
export const BoldBag = (props) => <Icon {...props} name="bold_bag" />;
export const BoldBagHappy = (props) => (
  <Icon {...props} name="bold_bag_happy" />
);
export const BoldBagTick = (props) => <Icon {...props} name="bold_bag_tick" />;
export const BoldBagCross = (props) => (
  <Icon {...props} name="bold_bag_cross" />
);
export const BoldBagTimer = (props) => (
  <Icon {...props} name="bold_bag_timer" />
);
export const BoldBagTick2 = (props) => (
  <Icon {...props} name="bold_bag_tick_2" />
);
export const BoldBagCross2 = (props) => (
  <Icon {...props} name="bold_bag_cross_2" />
);
export const BoldBag2 = (props) => <Icon {...props} name="bold_bag_2" />;
export const BoldShoppingCart = (props) => (
  <Icon {...props} name="bold_shopping_cart" />
);
export const BoldShoppingBag = (props) => (
  <Icon {...props} name="bold_shopping_bag" />
);
export const BoldShopAdd = (props) => <Icon {...props} name="bold_shop_add" />;
export const BoldShopRemove = (props) => (
  <Icon {...props} name="bold_shop_remove" />
);
export const BoldShop = (props) => <Icon {...props} name="bold_shop" />;
export const BoldBarcode = (props) => <Icon {...props} name="bold_barcode" />;

//Crypto, Currency
export const BoldCardCoin = (props) => (
  <Icon {...props} name="bold_card_coin" />
);
export const BoldTrade = (props) => <Icon {...props} name="bold_trade" />;
export const BoldBuyCrypto = (props) => (
  <Icon {...props} name="bold_buy_crypto" />
);
export const BoldBitcoinCard = (props) => (
  <Icon {...props} name="bold_bitcoin_card" />
);
export const BoldBitcoinConvert = (props) => (
  <Icon {...props} name="bold_bitcoin_convert" />
);
export const BoldBitcoinRefresh = (props) => (
  <Icon {...props} name="bold_bitcoin_refresh" />
);
export const MercadoPago = (props) => <Icon {...props} name="mercado_pago" />;
export const BitCoinLogo = (props) => <Icon {...props} name="bitcoin_logo" />;
export const BoldWhatsapp = (props) => <Icon {...props} name="bold_whatsapp" />;

//Content, Edit
export const BoldDocument2 = (props) => (
  <Icon {...props} name="bold_document_2" />
);
export const BoldDocumentText = (props) => (
  <Icon {...props} name="bold_document_text" />
);
export const BoldDocumentFavorite = (props) => (
  <Icon {...props} name="bold_document_favorite" />
);
export const BoldDocumentDownload = (props) => (
  <Icon {...props} name="bold_document_download" />
);
export const BoldDocumentUpload = (props) => (
  <Icon {...props} name="bold_document_upload" />
);
export const BoldDocumentForward = (props) => (
  <Icon {...props} name="bold_document_forward" />
);
export const BoldDocumentPrevious = (props) => (
  <Icon {...props} name="bold_document_previous" />
);
export const BoldDocumentSketch = (props) => (
  <Icon {...props} name="bold_document_sketch" />
);
export const BoldDocumentCloud = (props) => (
  <Icon {...props} name="bold_document_cloud" />
);
export const BoldDocumentCopy = (props) => (
  <Icon {...props} name="bold_document_copy" />
);
export const BoldDocumentNormal = (props) => (
  <Icon {...props} name="bold_document_normal" />
);
export const BoldClipboardText = (props) => (
  <Icon {...props} name="bold_clipboard_text" />
);
export const BoldClipboardTick = (props) => (
  <Icon {...props} name="bold_clipboard_tick" />
);
export const BoldClipboardClose = (props) => (
  <Icon {...props} name="bold_clipboard_close" />
);
export const BoldClipboardExport = (props) => (
  <Icon {...props} name="bold_clipboard_export" />
);
export const BoldClipboardImport = (props) => (
  <Icon {...props} name="bold_clipboard_import" />
);
export const BoldNote3 = (props) => <Icon {...props} name="bold_note_3" />;
export const BoldDocumentText2 = (props) => (
  <Icon {...props} name="bold_document_text_2" />
);
export const BoldDocumentLike = (props) => (
  <Icon {...props} name="bold_document_like" />
);
export const BoldEdit = (props) => <Icon {...props} name="bold_edit" />;
export const BoldEdit2 = (props) => <Icon {...props} name="bold_edit_2" />;
export const BoldCopyright = (props) => (
  <Icon {...props} name="bold_copyright" />
);
export const BoldCreativeCommons = (props) => (
  <Icon {...props} name="bold_creative_commons" />
);
export const BoldNote4 = (props) => <Icon {...props} name="bold_note_4" />;
export const BoldNoteRemove = (props) => (
  <Icon {...props} name="bold_note_remove" />
);
export const BoldStrickynote = (props) => (
  <Icon {...props} name="bold_strickynote" />
);
export const BoldNoteAdd = (props) => <Icon {...props} name="bold_note_add" />;
export const BoldTask = (props) => <Icon {...props} name="bold_task" />;
export const BoldTaskSquare = (props) => (
  <Icon {...props} name="bold_task_square" />
);
export const BoldBill = (props) => <Icon {...props} name="bold_bill" />;
export const BoldDocumentFilter = (props) => (
  <Icon {...props} name="bold_document_filter" />
);
export const BoldArchiveBook = (props) => (
  <Icon {...props} name="bold_archive_book" />
);
export const BoldNoteText = (props) => (
  <Icon {...props} name="bold_note_text" />
);
export const BoldNoteFavorite = (props) => (
  <Icon {...props} name="bold_note_favorite" />
);
export const BoldMenuBoard = (props) => (
  <Icon {...props} name="bold_menu_board" />
);

//Programing
export const BoldCommand = (props) => <Icon {...props} name="bold_command" />;
export const BoldProgrammingArrow = (props) => (
  <Icon {...props} name="bold_programming_arrow" />
);
export const BoldHierarchy = (props) => (
  <Icon {...props} name="bold_hierarchy" />
);
export const BoldHierarchy2 = (props) => (
  <Icon {...props} name="bold_hierarchy_2" />
);
export const BoldCommandSquare = (props) => (
  <Icon {...props} name="bold_command_square" />
);
export const BoldHierarchySquare = (props) => (
  <Icon {...props} name="bold_hierarchy_square" />
);
export const BoldHierarchySquare2 = (props) => (
  <Icon {...props} name="bold_hierarchy_square_2" />
);
export const BoldHierarchySquare3 = (props) => (
  <Icon {...props} name="bold_hierarchy_square_3" />
);
export const BoldData = (props) => <Icon {...props} name="bold_data" />;
export const BoldData2 = (props) => <Icon {...props} name="bold_data_2" />;
export const BoldDocumentCode = (props) => (
  <Icon {...props} name="bold_document_code" />
);
export const BoldCodeCircle = (props) => (
  <Icon {...props} name="bold_code_circle" />
);
export const BoldHierarchy3 = (props) => (
  <Icon {...props} name="bold_hierarchy_3" />
);
export const BoldProgrammingArrows = (props) => (
  <Icon {...props} name="bold_programming_arrows" />
);
export const BoldDocumentCode2 = (props) => (
  <Icon {...props} name="bold_document_code_2" />
);
export const BoldMessageProgramming = (props) => (
  <Icon {...props} name="bold_message_programming" />
);
export const bold_message_programming = (props) => (
  <Icon {...props} name="bold_mobile_programming" />
);
export const BoldCode = (props) => <Icon {...props} name="bold_code" />;
export const Boldcode2 = (props) => <Icon {...props} name="bold_code_2" />;
export const BoldHashtagUp = (props) => (
  <Icon {...props} name="bold_hashtag_up" />
);
export const BoldHashtag = (props) => <Icon {...props} name="bold_hashtag" />;
export const BoldHashtagDown = (props) => (
  <Icon {...props} name="bold_hashtag_down" />
);
export const BoldScroll = (props) => <Icon {...props} name="bold_scroll" />;
export const BoldSidebarLeft = (props) => (
  <Icon {...props} name="bold_sidebar_left" />
);
export const BoldSidebarRight = (props) => (
  <Icon {...props} name="bold_sidebar_right" />
);
export const BoldSidebarTop = (props) => (
  <Icon {...props} name="bold_sidebar_top" />
);
export const BoldSidebarBottom = (props) => (
  <Icon {...props} name="bold_sidebar_bottom" />
);

//Essetional
export const BoldTrash = (props) => <Icon {...props} name="bold_trash" />;
export const BoldBatteryEmpty = (props) => (
  <Icon {...props} name="bold_battery_empty" />
);
export const BoldBatteryEmpty2 = (props) => (
  <Icon {...props} name="bold_battery_empty_2" />
);
export const BoldBattery23 = (props) => (
  <Icon {...props} name="bold_battery_23" />
);
export const BoldBatteryFull = (props) => (
  <Icon {...props} name="bold_battery_full" />
);
export const BoldBatteryCharging = (props) => (
  <Icon {...props} name="bold_battery_charging" />
);
export const BoldBatteryDisable = (props) => (
  <Icon {...props} name="bold_battery_disable" />
);
export const BoldHome = (props) => <Icon {...props} name="bold_home" />;
export const BoldHome2 = (props) => <Icon {...props} name="bold_home_2" />;
export const BoldMoreSquare = (props) => (
  <Icon {...props} name="bold_more_square" />
);
export const BoldTickCircle = (props) => (
  <Icon {...props} name="bold_tick_circle" />
);
export const BoldAddCircle = (props) => (
  <Icon {...props} name="bold_add_circle" />
);
export const BoldMinusCircle = (props) => (
  <Icon {...props} name="bold_minus_circle" />
);
export const BoldCloseCircle = (props) => (
  <Icon {...props} name="bold_close_circle" />
);
export const BoldMoreCircle = (props) => (
  <Icon {...props} name="BoldMoreCircle" />
);
export const BoldInfoCircle = (props) => (
  <Icon {...props} name="bold_info_circle" />
);
export const BoldDanger = (props) => <Icon {...props} name="bold_danger" />;
export const BoldAdd = (props) => <Icon {...props} name="bold_add" />;
export const BoldMinus = (props) => <Icon {...props} name="bold_minus" />;
export const BoldAddSquare = (props) => (
  <Icon {...props} name="bold_add_square" />
);
export const BoldMinusSquare = (props) => (
  <Icon {...props} name="bold_minus_square" />
);
export const BoldTickSquare = (props) => (
  <Icon {...props} name="bold_tick_square" />
);

export const BoldClosedSquare = (props) => (
  <Icon {...props} name="bold_closed_square" />
);
export const BoldSort = (props) => <Icon {...props} name="bold_sort" />;
export const BoldSend3 = (props) => <Icon {...props} name="bold_send_3" />;
export const BoldSend2 = (props) => <Icon {...props} name="bold_send_2" />;
export const BoldShare = (props) => <Icon {...props} name="bold_share" />;
export const BoldFlash2 = (props) => <Icon {...props} name="bold_flash_2" />;
export const BoldFlash3 = (props) => <Icon {...props} name="bold_flash_3" />;
export const BoldFlashCircle2 = (props) => (
  <Icon {...props} name="bold_flash_circle_2" />
);
export const BoldSlash = (props) => <Icon {...props} name="bold_slash" />;
export const BoldCake = (props) => <Icon {...props} name="bold_cake" />;
export const BoldGrammerly = (props) => (
  <Icon {...props} name="bold_grammerly" />
);
export const BoldCrown = (props) => <Icon {...props} name="bold_crown" />;
export const BoldCrown2 = (props) => <Icon {...props} name="bold_crown_2" />;
export const BoldFlag = (props) => <Icon {...props} name="bold_flag" />;
export const BoldFlag2 = (props) => <Icon {...props} name="bold_flag_2" />;
export const BoldCup = (props) => <Icon {...props} name="bold_cup" />;
export const BoldEmojiHappy = (props) => (
  <Icon {...props} name="bold_emoji_happy" />
);
export const BoldEmojiNormal = (props) => (
  <Icon {...props} name="bold_emoji_normal" />
);
export const BoldEmojiSad = (props) => (
  <Icon {...props} name="bold_emoji_sad" />
);
export const BoldTagCross = (props) => (
  <Icon {...props} name="bold_tag_cross" />
);
export const BoldMenu = (props) => <Icon {...props} name="bold_menu" />;
export const BoldCd = (props) => <Icon {...props} name="bold_cd" />;

export const BoldChart = (props) => <Icon {...props} name="bold_chart" />;
export const BoldArchive2 = (props) => (
  <Icon {...props} name="bold_archive_2" />
);
export const BoldChrome = (props) => <Icon {...props} name="bold_chrome" />;
export const BoldInstagram = (props) => (
  <Icon {...props} name="bold_instagram" />
);
export const BoldCoffe = (props) => <Icon {...props} name="bold_coffe" />;
export const BoldPet = (props) => <Icon {...props} name="bold_pet" />;
export const BoldHappyemoji = (props) => (
  <Icon {...props} name="bold_happyemoji" />
);
export const BoldReserve = (props) => <Icon {...props} name="bold_reserve" />;
export const BoldSticker = (props) => <Icon {...props} name="bold_sticker" />;
export const BoldComputing = (props) => (
  <Icon {...props} name="bold_computing" />
);
export const BoldVerify = (props) => <Icon {...props} name="bold_verify" />;
export const BoldInformation = (props) => (
  <Icon {...props} name="bold_information" />
);
export const BoldAutobrightness = (props) => (
  <Icon {...props} name="bold_autobrightness" />
);
export const BoldTriangle = (props) => <Icon {...props} name="bold_triangle" />;
export const Bold3dCube = (props) => <Icon {...props} name="bold_3d_cube" />;
export const BoldGlass2 = (props) => <Icon {...props} name="bold_glass_2" />;
export const BoldWeight = (props) => <Icon {...props} name="bold_weight" />;
export const BoldDiscover2 = (props) => (
  <Icon {...props} name="bold_discover_2" />
);
export const BoldFilter = (props) => <Icon {...props} name="bold_filter" />;
export const BoldFilterSquare = (props) => (
  <Icon {...props} name="bold_filter_square" />
);
export const BoldSound = (props) => <Icon {...props} name="bold_sound" />;
export const BoldStory = (props) => <Icon {...props} name="bold_story" />;

export const BoldSlider = (props) => <Icon {...props} name="bold_slider" />;
export const BoldStatus = (props) => <Icon {...props} name="bold_status" />;
export const BoldWifiSquare = (props) => (
  <Icon {...props} name="bold_wifi_square" />
);
export const BoldBox3 = (props) => <Icon {...props} name="bold_box_3" />;
export const BoldTrushSquare = (props) => (
  <Icon {...props} name="bold_trush_square" />
);
export const BoldWifi = (props) => <Icon {...props} name="bold_wifi" />;
export const BoldSpeedometer = (props) => (
  <Icon {...props} name="bold_speedometer" />
);
export const BoldDiamonds = (props) => <Icon {...props} name="bold_diamonds" />;
export const BoldLevel = (props) => <Icon {...props} name="bold_level" />;
export const BoldJudge = (props) => <Icon {...props} name="bold_judge" />;
export const BoldLamp = (props) => <Icon {...props} name="bold_lamp" />;
export const BoldTree = (props) => <Icon {...props} name="bold_tree" />;
export const BoldBubble = (props) => <Icon {...props} name="bold_bubble" />;
export const BoldMirror = (props) => <Icon {...props} name="bold_mirror" />;
export const BoldTagRight = (props) => (
  <Icon {...props} name="bold_tag_right" />
);
export const BoldMilk = (props) => <Icon {...props} name="bold_milk" />;
export const BoldGhost = (props) => <Icon {...props} name="bold_ghost" />;
export const BoldRanking = (props) => <Icon {...props} name="bold_ranking" />;
export const BoldMouseSquare = (props) => (
  <Icon {...props} name="bold_mouse_square" />
);
export const BoldMouseCircle = (props) => (
  <Icon {...props} name="bold_mouse_circle" />
);
export const BoldMouse = (props) => <Icon {...props} name="bold_mouse" />;
export const BoldForbidden = (props) => (
  <Icon {...props} name="bold_forbidden" />
);

export const BoldForbidden2 = (props) => (
  <Icon {...props} name="bold_forbidden_2" />
);
export const BoldWarning2 = (props) => (
  <Icon {...props} name="bold_warning_2" />
);
export const BoldBroom = (props) => <Icon {...props} name="bold_broom" />;
export const BoldSignpost = (props) => <Icon {...props} name="bold_signpost" />;
export const BoldFilterRemove = (props) => (
  <Icon {...props} name="bold_filter_remove" />
);
export const BoldFilterAdd = (props) => (
  <Icon {...props} name="bold_filter_add" />
);
export const BoldFilterSearch = (props) => (
  <Icon {...props} name="bold_filter_search" />
);
export const BoldFilterEdit = (props) => (
  <Icon {...props} name="bold_filter_edit" />
);
export const BoldFilterTick = (props) => (
  <Icon {...props} name="bold_filter_tick" />
);
export const BoldHome3 = (props) => <Icon {...props} name="bold_home_3" />;
export const BoldSmartHome = (props) => (
  <Icon {...props} name="bold_smart_home" />
);
export const BoldHomeWifi = (props) => (
  <Icon {...props} name="bold_home_wifi" />
);
export const BoldSafeHome = (props) => (
  <Icon {...props} name="bold_safe_home" />
);

/*********************
 *********************
 *********************
 *********************
 *********************
 *********************
        BOLD ICONS
 *********************
 *********************
 *********************
 *********************
 *********************
 *********************/

//Support, Like, question
export const StarIconBold = (props) => <Icon {...props} name="star_bold" />;
export const StarCancelIconBold = (props) => (
  <Icon {...props} name="star_cancel_bold" />
);
export const LoveIconBold = (props) => <Icon {...props} name="love_bold" />;
export const LikeIconBold = (props) => <Icon {...props} name="like_bold" />;
export const DisLikeIconBold = (props) => (
  <Icon {...props} name="dislike_bold" />
);
export const DashStarIconBold = (props) => (
  <Icon {...props} name="star_dash_bold" />
);
export const DashDownStarIconBold = (props) => (
  <Icon {...props} name="star_down_dash_bold" />
);
export const MagicStarBold = (props) => (
  <Icon {...props} name="star_magic_bold" />
);
export const LoveEditBold = (props) => (
  <Icon {...props} name="love_edit_bold" />
);
export const LoveCheckBold = (props) => (
  <Icon {...props} name="love_check_bold" />
);
export const LoveSearchBold = (props) => (
  <Icon {...props} name="love_search_bold" />
);
export const LoveCancelBold = (props) => (
  <Icon {...props} name="love_cancel_bold" />
);
export const LoveRemoveBold = (props) => (
  <Icon {...props} name="love_remove_bold" />
);
export const LoveAddBold = (props) => <Icon {...props} name="love_add_bold" />;
export const SmileysBold = (props) => <Icon {...props} name="smileys_bold" />;
export const LikeDislikeBold = (props) => (
  <Icon {...props} name="like_dislike_bold" />
);
export const LikeTagBold = (props) => <Icon {...props} name="like_tag_bold" />;
export const TwentyFourSupportBold = (props) => (
  <Icon {...props} name="24_support_bold" />
);
export const LikeShapeBold = (props) => (
  <Icon {...props} name="like_shape_bold" />
);
export const QuestionBubbleBold = (props) => (
  <Icon {...props} name="question_bubble_bold" />
);
export const PrizeIconBold = (props) => <Icon {...props} name="prize_bold" />;
export const LovelyIconBold = (props) => <Icon {...props} name="lovely_bold" />;
export const MedalBold = (props) => <Icon {...props} name="medal_bold" />;
export const UnlimitedBold = (props) => (
  <Icon {...props} name="infinity_bold" />
);
export const BoldLike = (props) => <Icon {...props} name="outline_like_bold" />;
export const LoveCircleBold = (props) => (
  <Icon {...props} name="love_circle_bold" />
);

//Notifications
export const NotificationFillBold = (props) => (
  <Icon {...props} name="notification_fill_bold" />
);
export const NotificationIconBold = (props) => (
  <Icon {...props} name="notification_bold" />
);
export const PendingNotificationSquareBold = (props) => (
  <Icon {...props} name="pending_notification_square_bold" />
);
export const PendingNotificationFavouriteBold = (props) => (
  <Icon {...props} name="pending_notification_favourite_bold" />
);
export const PendingNotificationStatusBold = (props) => (
  <Icon {...props} name="pending_notification_status_bold" />
);
export const PendingNotificationCircleBold = (props) => (
  <Icon {...props} name="pending_notification_circle_bold" />
);
export const LightBulbBold = (props) => (
  <Icon {...props} name="lightBulb_bold" />
);
export const LightBulbChargeBold = (props) => (
  <Icon {...props} name="lightBulb_charge_bold" />
);
export const LightBulbSlashBold = (props) => (
  <Icon {...props} name="lightBulb_slash_bold" />
);

//Settings
export const CategoryBold = (props) => <Icon {...props} name="category_bold" />;
export const Category2Bold = (props) => (
  <Icon {...props} name="category2_bold" />
);
export const SettingBold = (props) => <Icon {...props} name="setting_bold" />;
export const SettingsBold = (props) => <Icon {...props} name="settings_bold" />;
export const Settings2Bold = (props) => (
  <Icon {...props} name="settings2_bold" />
);
export const Settings3Bold = (props) => (
  <Icon {...props} name="settings3_bold" />
);
export const Settings4Bold = (props) => (
  <Icon {...props} name="settings4_bold" />
);
export const MoreIconDotsBold = (props) => (
  <Icon {...props} name="more_dots_bold" />
);
export const MoreIconDots2Bold = (props) => (
  <Icon {...props} name="more_dots2_bold" />
);
export const ToggleOffBold = (props) => (
  <Icon {...props} name="toggleOff_bold" />
);
export const ToggleOffCircleBold = (props) => (
  <Icon {...props} name="toggleOff_circle_bold" />
);
export const ToggleOnBold = (props) => <Icon {...props} name="toggleOn_bold" />;
export const ToggleOnCircleBold = (props) => (
  <Icon {...props} name="toggleOn_circle_bold" />
);
export const MenuDotBold = (props) => <Icon {...props} name="menu_dot_bold" />;
export const OptionsIconBold = (props) => (
  <Icon {...props} name="options_bold" />
);
export const OptionsIcon2Bold = (props) => (
  <Icon {...props} name="options2_bold" />
);
export const OptionBold = (props) => <Icon {...props} name="option_bold" />;
//Marca
export const LogoEthicsCopy = (props) => (
  <Icon {...props} name="logo_ethics_copy" />
);
export const LogoEthicsWhite = (props) => (
  <Icon {...props} name="logo_ethics_white" />
);
export const ImagotipoEthics = (props) => (
  <Icon {...props} name="imagotipo_ethics" />
);
export const LogoEthicsCopyWhite = (props) => (
  <Icon {...props} name="logo_ethics_copy_white" />
);
export const Collapse = (props) => <Icon {...props} name="collapse" />;
export const BoldElement = (props) => <Icon {...props} name="bold_element" />;
export const BoldDocumentText1 = (props) => (
  <Icon {...props} name="bold_document_text_1" />
);
export const BoldChart1 = (props) => <Icon {...props} name="bold_chart_1" />;
export const BoldNotification = (props) => (
  <Icon {...props} name="bold_notification" />
);
export const BoldDirect1 = (props) => <Icon {...props} name="bold_direct_1" />;
export const Rocket = (props) => <Icon {...props} name="rocket" />;
export const BoldMessageQuestion = (props) => (
  <Icon {...props} name="bold_message_question" />
);
export const LogoEthicsCopy3 = (props) => (
  <Icon {...props} name="logo-ethics_copy_3" />
);
export const Collapse2 = (props) => <Icon {...props} name="collapse_2" />;
export const BoldMessageQuestion2 = (props) => (
  <Icon {...props} name="bold_message_question_2" />
);
export const ImagotipoEthics2 = (props) => (
  <Icon {...props} name="imagotipo_ethics_2" />
);
export const Group39 = (props) => <Icon {...props} name="group_39" />;
export const ImagotipoEthics4 = (props) => (
  <Icon {...props} name="imagotipo_ethics_4" />
);
export const Group392 = (props) => <Icon {...props} name="group_39_2" />;
export const ImagotipoEthics5 = (props) => (
  <Icon {...props} name="imagotipo_ethics_5" />
);
export const ProfileIcon = (props) => <Icon {...props} name="profile_icon" />;

export const Profile = (props) => <Icon {...props} name="profile" />;
export const CircleYellow = (props) => <Icon {...props} name="circle_yellow" />;
export const Shape = (props) => <Icon {...props} name="shape" />;

//Time
export const TimerBold = (props) => <Icon {...props} name="timer_bold" />;
export const ClockBold = (props) => <Icon {...props} name="clock_bold" />;
export const Timer2Bold = (props) => <Icon {...props} name="timer2_bold" />;
export const TimerPauseBold = (props) => (
  <Icon {...props} name="timer_pause_bold" />
);
export const TimerPlayBold = (props) => (
  <Icon {...props} name="timer_play_bold" />
);
export const TimeSecurityBold = (props) => (
  <Icon {...props} name="timer_security_bold" />
);
export const CalendarBold = (props) => <Icon {...props} name="calendar_bold" />;
export const CalendarCheckBold = (props) => (
  <Icon {...props} name="calendar_check_bold" />
);
export const CalendarSearchBold = (props) => (
  <Icon {...props} name="calendar_search_bold" />
);
export const CalendarAddBold = (props) => (
  <Icon {...props} name="calendar_add_bold" />
);
export const CalendarRemoveBold = (props) => (
  <Icon {...props} name="calendar_remove_bold" />
);
export const CalendarEditBold = (props) => (
  <Icon {...props} name="calendar_edit_bold" />
);
export const Calendar2Bold = (props) => (
  <Icon {...props} name="calendar2_bold" />
);
export const CalendarCircleBold = (props) => (
  <Icon {...props} name="calendar_circle_bold" />
);
export const Calendar3Bold = (props) => (
  <Icon {...props} name="calendar3_bold" />
);

//Computers, Devices, Electronics
export const PrinterBold = (props) => <Icon {...props} name="printer_bold" />;
export const PrinterCancelBold = (props) => (
  <Icon {...props} name="printer_cancel_bold" />
);
export const ChipBold = (props) => <Icon {...props} name="chip_bold" />;
export const ChipChargeBold = (props) => (
  <Icon {...props} name="chip_charge_bold" />
);
export const ChipSettingsBold = (props) => (
  <Icon {...props} name="chip_settings_bold" />
);
export const MonitorBold = (props) => <Icon {...props} name="monitor_bold" />;
export const MonitorRecorderBold = (props) => (
  <Icon {...props} name="monitor_recorder_bold" />
);
export const MonitorMobileBold = (props) => (
  <Icon {...props} name="monitor_mobile_bold" />
);
export const HeadphonesBold = (props) => (
  <Icon {...props} name="headphones_bold" />
);
export const CableHeadphonesBold = (props) => (
  <Icon {...props} name="cable_headphones_bold" />
);
export const HeadphonesPlayBold = (props) => (
  <Icon {...props} name="headphones_play_bold" />
);
export const AirpodBold = (props) => <Icon {...props} name="airpod_bold" />;
export const SpeakerBold = (props) => <Icon {...props} name="speaker_bold" />;
export const DriverBold = (props) => <Icon {...props} name="driver_bold" />;
export const Driver2Bold = (props) => <Icon {...props} name="driver2_bold" />;
export const DriverRefreshBold = (props) => (
  <Icon {...props} name="driver_refresh_bold" />
);
export const CloudAddBold = (props) => (
  <Icon {...props} name="cloud_add_bold" />
);
export const CloudRemoveBold = (props) => (
  <Icon {...props} name="cloud_remove_bold" />
);
export const CloudConnectionBold = (props) => (
  <Icon {...props} name="cloud_connection_bold" />
);
export const ExternalDriveBold = (props) => (
  <Icon {...props} name="external_drive_bold" />
);
export const Devices2Bold = (props) => <Icon {...props} name="devices_bold" />;
export const AirpodsBold = (props) => <Icon {...props} name="Airpods_bold" />;
export const FolderConnectionBold = (props) => (
  <Icon {...props} name="folder_connection_bold" />
);
export const GameControlBold = (props) => (
  <Icon {...props} name="game_control_bold" />
);
export const WeightControlBold = (props) => (
  <Icon {...props} name="weight_control_bold" />
);
export const KeyboardBold = (props) => <Icon {...props} name="keyboard_bold" />;
export const MouseBold = (props) => <Icon {...props} name="mouse_bold" />;
export const WatchBold = (props) => <Icon {...props} name="watch_bold" />;
export const Watch2Bold = (props) => <Icon {...props} name="watch2_bold" />;
export const WatchStatusBold = (props) => (
  <Icon {...props} name="watch_status_bold" />
);
export const RamBold = (props) => <Icon {...props} name="ram_bold" />;
export const Ram2Bold = (props) => <Icon {...props} name="ram2_bold" />;
export const SimCardBold = (props) => <Icon {...props} name="sim_card_bold" />;
export const SimCard2Bold = (props) => (
  <Icon {...props} name="sim_card2_bold" />
);
export const SimCard3Bold = (props) => (
  <Icon {...props} name="sim_card3_bold" />
);
export const DeskBold = (props) => <Icon {...props} name="desk_bold" />;
export const MobileBold = (props) => <Icon {...props} name="mobile_bold" />;
export const PlugBold = (props) => <Icon {...props} name="plug_bold" />;
export const ScreenMirroringBold = (props) => (
  <Icon {...props} name="screen_mirroring_bold" />
);
export const TelescopeBold = (props) => (
  <Icon {...props} name="telescope_bold" />
);
export const OpenKeyboardBold = (props) => (
  <Icon {...props} name="open_keyboard_bold" />
);
export const AirDropBold = (props) => <Icon {...props} name="air_drop_bold" />;
export const GameBoyBold = (props) => <Icon {...props} name="gameBoy_bold" />;
export const BluetoothBold = (props) => (
  <Icon {...props} name="bluetooth_bold" />
);
export const Bluetooth2Bold = (props) => (
  <Icon {...props} name="bluetooth2_bold" />
);
export const BluetoothCircleBold = (props) => (
  <Icon {...props} name="bluetooth_circle_bold" />
);
export const BluetoothSquareBold = (props) => (
  <Icon {...props} name="bluetooth_square_bold" />
);
export const CloudChangeBold = (props) => (
  <Icon {...props} name="cloud_change_bold" />
);

//Call
export const CallBold = (props) => <Icon {...props} name="call_bold" />;
export const IncomingCallBold = (props) => (
  <Icon {...props} name="incoming_call_bold" />
);
export const CallingBold = (props) => <Icon {...props} name="calling_bold" />;
export const OutgoingCallBold = (props) => (
  <Icon {...props} name="outgoing_call_bold" />
);
export const CallAddBold = (props) => <Icon {...props} name="add_call_bold" />;
export const CallMinusBold = (props) => (
  <Icon {...props} name="minus_call_bold" />
);
export const CallRemoveBold = (props) => (
  <Icon {...props} name="remove_call_bold" />
);
export const CallReceivedBold = (props) => (
  <Icon {...props} name="received_call_bold" />
);
export const CallCancelBold = (props) => (
  <Icon {...props} name="cancel_call_bold" />
);

//Business
export const ChartSuccessBold = (props) => (
  <Icon {...props} name="chart_success_bold" />
);
export const ChartFailBold = (props) => (
  <Icon {...props} name="chart_fail_bold" />
);
export const ChartSquareBold = (props) => (
  <Icon {...props} name="chart_square_bold" />
);
export const PieChartBold = (props) => (
  <Icon {...props} name="pie_chart_bold" />
);
export const ActivityBold = (props) => <Icon {...props} name="activity_bold" />;
export const HomeTrendingBold = (props) => (
  <Icon {...props} name="home_trending_bold" />
);
export const HomeTrendingDownBold = (props) => (
  <Icon {...props} name="home_trending_down_bold" />
);
export const HomeHashBold = (props) => (
  <Icon {...props} name="home_hash_bold" />
);
export const TrendUpBold = (props) => <Icon {...props} name="trend_up_bold" />;
export const TrendDownBold = (props) => (
  <Icon {...props} name="trend_down_bold" />
);
export const HashSquareBold = (props) => (
  <Icon {...props} name="hash_square_bold" />
);
export const BoldChartBusiness = (props) => (
  <Icon {...props} name="outline_chart_bold" />
);
export const PersonalIdBold = (props) => (
  <Icon {...props} name="personal_id_bold" />
);
export const StatusUpBold = (props) => (
  <Icon {...props} name="status_up_bold" />
);
export const LineChartBold = (props) => (
  <Icon {...props} name="line_chart_bold" />
);
export const PresentationBold = (props) => (
  <Icon {...props} name="presentation_bold" />
);
export const BarChartBold = (props) => (
  <Icon {...props} name="bar_chart_bold" />
);
export const BarChart2Bold = (props) => (
  <Icon {...props} name="bar_chart2_bold" />
);
export const HealthBold = (props) => <Icon {...props} name="health_bold" />;
export const FavouriteChartBold = (props) => (
  <Icon {...props} name="favourite_chart_bold" />
);

//Building
export const BuildingBold = (props) => <Icon {...props} name="building_bold" />;
export const Building2Bold = (props) => (
  <Icon {...props} name="building2_bold" />
);
export const Building3Bold = (props) => (
  <Icon {...props} name="building3_bold" />
);
export const Building4Bold = (props) => (
  <Icon {...props} name="building4_bold" />
);
export const BuildingsBold = (props) => (
  <Icon {...props} name="buildings_bold" />
);
export const Buildings2Bold = (props) => (
  <Icon {...props} name="buildings2_bold" />
);
export const CourtHouseBold = (props) => (
  <Icon {...props} name="courtHouse_bold" />
);
export const HouseBold = (props) => <Icon {...props} name="house_bold" />;
export const House2Bold = (props) => <Icon {...props} name="house2_bold" />;
export const BankBold = (props) => <Icon {...props} name="bank_bold" />;
export const HospitalBold = (props) => <Icon {...props} name="hospital_bold" />;

//Astrology
export const AquariusBold = (props) => <Icon {...props} name="aquarius_bold" />;
export const GeminiBold = (props) => <Icon {...props} name="gemini_bold" />;
export const Gemini2Bold = (props) => <Icon {...props} name="gemini2_bold" />;
export const SagittariusBold = (props) => (
  <Icon {...props} name="sagittarius_bold" />
);
export const MaleBold = (props) => <Icon {...props} name="male_bold" />;
export const FemaleBold = (props) => <Icon {...props} name="female_bold" />;

//Search
export const SearchIconBold = (props) => <Icon {...props} name="search_bold" />;
export const SearchIcon2Bold = (props) => (
  <Icon {...props} name="search2_bold" />
);
export const ZoomInBold = (props) => <Icon {...props} name="zoom_in_bold" />;
export const ZoomIn2Bold = (props) => <Icon {...props} name="zoom_in2_bold" />;
export const ZoomOutBold = (props) => <Icon {...props} name="zoom_out_bold" />;
export const ZoomOut2Bold = (props) => (
  <Icon {...props} name="zoom_out2_bold" />
);
export const SearchFavouriteBold = (props) => (
  <Icon {...props} name="search_favourite_bold" />
);
export const SearchFavourite2Bold = (props) => (
  <Icon {...props} name="search_favourite2_bold" />
);
export const SearchStatusBold = (props) => (
  <Icon {...props} name="search_status_bold" />
);
export const SearchStatus2Bold = (props) => (
  <Icon {...props} name="search_status2_bold" />
);
