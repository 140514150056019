import React, { useState } from "react";
import "./Avatar.scss";
import { AvatarLetter } from "./AvatarLetter";
import { MCTooltip } from "../Tooltip/Tooltip";
import { Modal, ModalBody } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const List = ({ contacts }) => {
  const [t] = useTranslation("messages");
  const limitedList = 10;
  const visibleContactList = contacts.slice(0, limitedList);
  const remainingCount = contacts.length - limitedList;

  return (
    <ul className="list-unstyled my-auto p-2 avatar-group__list-tooltip">
      {visibleContactList.map((contact, i) => (
        <li key={i} className="avatar-group__list-contact">
          <small>{contact.name}</small>
        </li>
      ))}
      {remainingCount > 0 && (
        <li className="mx-1">
          <small>{`${t("avatarGroup.and")} ${remainingCount} ${t(
            "avatarGroup.more_people"
          )}...`}</small>
        </li>
      )}
    </ul>
  );
};

export const AvatarGroup = ({ contacts, max, title = "" }) => {
  const { gTheme } = useSelector((state) => state.theme);
  const [show, setShow] = useState(false);
  const displayContacts = contacts.slice(0, max);
  const extraCount = contacts.length - max;
  const [t] = useTranslation("messages");

  const hdlShow = () => setShow(!show);

  return (
    <>
      <Modal animation={true} size="md" centered show={show} onHide={hdlShow}>
        <Modal.Header
          closeButton
          className={`${
            gTheme !== "light" ? "btn-close-white" : ""
          } border-0 p-0`}
        >
          <Modal.Title>
            <h5 className="px-2 my-auto">{title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled avatar-group__modal-list">
            {contacts.map((contact, i) => (
              <li key={i} className="avatar-group__modal-contact px-2">
                <AvatarLetter contact={contact} />
                <div className="p-2">
                  <h6 className="m-0">{contact.name}</h6>
                  <p className="m-0">{contact?.email}</p>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>

      <div className="avatar-group">
        {displayContacts.map((contact, i) => (
          <AvatarLetter contact={contact} key={i} />
        ))}
        {extraCount > 0 && (
          <MCTooltip text={<List contacts={contacts} />} position="left">
            <button className="avatar extra-count" onClick={hdlShow}>
              +{extraCount}
            </button>
          </MCTooltip>
        )}
      </div>
    </>
  );
};
