import React, { useMemo } from "react";
import { createTheme } from "@mui/material";
import { useSelector } from "react-redux";

const useCustomTheme = () => {
  // gTheme =  "light" || "dark" mode
  const gTheme = useSelector((state) => state.theme.gTheme);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: gTheme,
          ethicsBlue: { main: "#009ed7" },
          oxfordBlue: { main: "#152235" },
          oxfordBlue2: { main: "#32384d" },
          cobalt: { main: "#1a4792" },
          // Blue Gray (Neutral Colors)
          blueGray: { main: "#546e7a" },
          blueGray2: { main: "#78909c" },
          blueGray50: { main: "#f3f7fa" },
          blueGray100: { main: "#e6ebef" },
          blueGray200: { main: "#d3dbe1" },
          blueGray500: { main: "#b5c0c8" },
          blueGray600: { main: "#909ca4" },
          blueGray700: { main: "#5c666e" },
          blueGray800: { main: "#262f38" },
          blueGray900: { main: "#324148" },
          // Blue
          lightishBlue: { main: "#536dfe" },
          blue2: { main: "#0277bd" },
          blue: { main: "#3181ff" },
          blueII: { main: "#5a9aff" },
          blueIII: { main: "#83b3ff" },
          blueIV: { main: "#adcdff" },
          blueV: { main: "#d6e6ff" },
          // Green
          green: { main: "#44cb67" },
          greenII: { main: "#69d585" },
          greenIII: { main: "#8fe0a4" },
          greenIV: { main: "#b4eac2" },
          greenV: { main: "#daf5e1" },
          // Yellow
          lightYellow: { main: "#ffefc0" },
          yellow: { main: "#ffaf00" },
          yellowII: { main: "#ffbf32" },
          yellowIII: { main: "#ffcf66" },
          yellowIV: { main: "#ffdf9a" },
          yellowV: { main: "#ffefcc" },
          // Red
          red: { main: "#de463d" },
          redII: { main: "#e56b64" },
          redIII: { main: "#eb908b" },
          redIV: { main: "#f2b5b1" },
          redV: { main: "#f8dad8" },
          // Teal
          teal: { main: "#007b6f" },
          tealII: { main: "#33958c" },
          tealIII: { main: "#66b0a9" },
          tealVI: { main: "#99cac6" },
          tealV: { main: "#cce5e2" },
          // Purple
          purple: { main: "#7c4dff" },
          purpleII: { main: "#9671ff" },
          purpleIII: { main: "#b094ff" },
          purpleIV: { main: "#cbb8ff" },
          purpleV: { main: "#e5dbff" },
          // Pink
          pink: { main: "#d81b60" },
          pinkII: { main: "#e04a80" },
          pinkIII: { main: "#e876a0" },
          pinkIV: { main: "#efa4bf" },
          pinkV: { main: "#f7d1df" },
          // Lime
          lime: { main: "#cddc39" },
          limeII: { main: "#d7e361" },
          limeIII: { main: "#e1ea88" },
          limeIV: { main: "#ebf1b0" },
          limeV: { main: "#f5f8d7" },
          // Gray
          charcoalGrey: { main: "#31323a" },
          coolGray: { main: "#b0bec5" },
          gray: { main: "#66818d" },
          gray2: { main: "#d8dfe3" },
          gunmetal: { main: "#5d5e5e" },
          // Slate
          slate: { main: "#455a64" },
          slate3: { main: "#566d77" },
          // Dark
          black: { main: "#000000" },
          darkBorder: { main: "#363742" },
          darkSidebar: { main: "#0d0c11" },
          darkBackground: { main: "#141319" },
          dark: { main: "#2c2c3a" },
          darkTwo: { main: "#263238" },
          darkThree: { main: "#1a1d27" },
          darkFour: { main: "#242836" },
          //light
          white: { main: "#ffffff" },
          lightBlue: { main: "#e9f5fd" },
          lightBlue2: { main: "#a0d4fa" },
          lightBorder: { main: "#dee2e6" },
          // Pale
          paleBlue: { main: "#e8f5fd" },
          paleBlue2: { main: "#e1f5fe" },
          paleGrey: { main: "#eceff1" },
          paleGrey2: { main: "#e3ebf5" },
          paleGrey3: { main: "#f0f2f4" },
          palePink: { main: "#ffd7da" },
          // Silver
          silver: { main: "#bdcad0" },
          // COMMON
          common: { black: "#000000", white: "#ffffff" },
          // PRIMARY:
          primary: { main: "#009ed7" },
          // SECONDARY
          secondary: { main: "#546E7A" },
          // ERROR
          error: { main: "#de463d" },
          // WARNING
          warning: { main: "#ffaf00" },
          // INFO
          info: { main: "#3181ff" },
          //
          // SUCCESS
          success: { main: "#44cb67" },
        },
        components: {
          MuiLink: {
            styleOverrides: {
              root: {
                cursor: "pointer",
                fontSize: "0.8rem",
                width: "auto",
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                "&.MuiFormLabel-root": {
                  top: "-0.5rem",
                  left: 0,
                },
                "&.MuiInputLabel-shrink": {
                  top: 0,
                  fontSize: "0.5 rem",
                  margin: "0 auto",
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                padding: "0.4rem",
                borderRadius: "0.4rem",
              },
              input: {
                padding: "0.45rem",
              },
            },
          },
          MuiStack: {
            defaultProps: {
              useFlexGap: true,
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: ({ theme }) => ({
                fontSize: "0.8rem",
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: "6px",
                padding: "auto 2px",
              }),
              arrow: {
                color: "#000000",
              },
            },
          },
        },
        typography: {
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightBold: 700,
          htmlFontSize: 10,

          h1: {
            fontSize: "4rem",
            fontWeight: 700,
            lineHeight: 1.167,
          },

          h2: {
            fontSize: "3.2rem",
            fontWeight: 700,
            lineHeight: 1.2,
          },

          h3: {
            fontSize: "2.8rem",
            fontWeight: 700,
            lineHeight: 1.167,
          },

          h4: {
            fontSize: "2.4rem",
            fontWeight: 500,
            lineHeight: 1.235,
          },

          h5: {
            fontSize: "2rem",
            fontWeight: 500,
            lineHeight: 1.334,
          },

          h6: {
            fontSize: "1.6rem",
            fontWeight: 500,
            lineHeight: 1.6,
          },
          // Label
          body1: {
            fontSize: "0.8rem",
            fontWeight: 400,
            lineHeight: 1.5,
          },
          // small
          body2: {
            fontSize: "0.8rem",
            fontWeight: 300,
            lineHeight: 1.43,
          },
        },
      }),
    [gTheme]
  );
  return theme;
};

export default useCustomTheme;
