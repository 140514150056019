import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import {
    OutlineArrowUp,
    ArrowDown2
} from "../../Icon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectDefault({ title = "Chip", options = names, onChange }) {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        const newValue = typeof value === 'string' ? value.split(',') : value;

        const selectedOptions = options.filter(option => newValue.includes(option.label));
        setPersonName(newValue);



        onChange(selectedOptions);

    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel
                    id="demo-multiple-chip-label"
                    sx={{
                        marginTop: '-9px',
                        zIndex: '-1',
                        '&.Mui-focused': {
                            marginTop: '0px', // Restablece el margen cuando el campo está enfocado
                        },
                        '&.MuiInputLabel-shrink': {
                            marginTop: '0px', // Restablece el margen cuando el label se contrae (al seleccionar)
                        }
                    }}
                >
                    {title}
                </InputLabel>
                <Select
                    sx={{
                        minHeight: '38px',
                        maxHeight: '38px',
                    }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={title} />}
                    MenuProps={MenuProps}
                    IconComponent={(props) => (
                        props.open ? (
                            <OutlineArrowUp style={{ marginRight: '8px' }} />
                        ) : (
                            <ArrowDown2 style={{ marginRight: '8px' }} />
                        )
                    )}            >
                    {options.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.label}
                            sx={{
                                maxHeight: '50% !important',
                                minHeight: '50% !important',
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}