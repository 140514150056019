import Icon from "../../Icon/Icon";
import "./Strings.scss";
import { useSelector } from "react-redux";
// String 'Cargando...' or 'Loading...' & effects
export const Loading = () => {
  const { lang } = useSelector((state) => state.lang);
  return (
    <div className="loading_container dyTheme1 rounded">
      <div className="loading_icon">
        <Icon name="imagotipo_ethics_4" />
      </div>
      {lang === "es" && (
        <div className="loading_text">
          <span className="loader1">C</span>
          <span className="loader2">a</span>
          <span className="loader3">r</span>
          <span className="loader4">g</span>
          <span className="loader5">a</span>
          <span className="loader6">n</span>
          <span className="loader7">d</span>
          <span className="loader8">o</span>
          <span className="loader9">.</span>
          <span className="loader10">.</span>
          <span className="loader11">.</span>
        </div>
      )}
      {lang === "en" && (
        <div className="loading_text">
          <span className="loader1">L</span>
          <span className="loader2">o</span>
          <span className="loader3">a</span>
          <span className="loader4">d</span>
          <span className="loader5">i</span>
          <span className="loader6">n</span>
          <span className="loader7">g</span>
          <span className="loader8">.</span>
          <span className="loader9">.</span>
          <span className="loader10">.</span>
        </div>
      )}
    </div>
  );
};
