import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown2, BoldTrash, OutlineDocumentText } from "../../Icon";
import "./SupportFile.scss";
/**
 * Component to render documents in list from cloud
 */
export const SupportFilesReader = ({ files }) => {
  const [sortedElements, setSortedElements] = useState([]);
  const [aZ, setAZ] = useState(true);
  const [iconography, setIconography] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [t] = useTranslation("components");

  useEffect(() => {
    setSortedElements(files);
  }, [files]);

  //Opens file URL in a new window
  const redirectToURL = (url) => {
    const urlValidate = url.split("?");
    const urlSplited = urlValidate[0];
    window.open(urlSplited, "_blank");
  };

  const arrangeElements = () => {
    const newOrder = files.slice().sort((a, b) => {
      const propA = a.name.toUpperCase();
      const propB = b.name.toUpperCase();

      return aZ ? (propA > propB ? 1 : -1) : propA < propB ? 1 : -1;
    });
    setSortedElements(newOrder);
    setAZ(!aZ);
    setIconography(true);
    setToggled(!toggled);
  };
  return (
    <div>
      <h6 onClick={arrangeElements}>
        {`${t("SupportFilesReader.files")}: `}{" "}
        {iconography && (
          <ArrowDown2
            width={15}
            height={15}
            className={toggled ? "--noToggled" : "--toggled"}
          />
        )}
      </h6>
      <div className="supportFilesReaders">
        {sortedElements.length === 0 && (
          <p className="supportFilesReaders__nothing">
            {t("SupportFilesReader.no_file")}
          </p>
        )}
        {sortedElements.map((file) => {
          const parts = file.file.split("/");
          const fileType = parts[parts.length - 1];
          const extension = fileType.split(".")[1];
          return (
            <div key={file.id} className="supportFilesReaders__file">
              <button
                className="supportFilesReaders__viewer"
                onClick={() => redirectToURL(file.url)}
              >
                <OutlineDocumentText height={14} width={14} />{" "}
                {file.name.length > 15
                  ? `${file.name.slice(0, 15)} ...`
                  : `${file.name} `}
                {`(${extension})`}
              </button>
              <button
                className="supportFilesReaders__delete"
                onClick={() =>
                  alert(
                    `Estamos trabajando para eliminar el archivo "${file.name}"`
                  )
                }
              >
                <BoldTrash />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
