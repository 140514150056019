import { setLang } from "./langSlice";

export const startGettingLanguageLS = (i18n) => (dispatch, getState) => {
  const langLS = localStorage.getItem('lang') || "es";
  localStorage.setItem('lang', langLS);
  i18n.changeLanguage(langLS);
  dispatch( setLang(langLS) );
}

export const startToChangueLang = (newLang) => (dispatch, getState) => {
  localStorage.setItem('lang', newLang);
  dispatch( setLang(newLang) );
}