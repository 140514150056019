import { useRef } from "react";
import { useNavigate } from "react-router";
import Icon from "../../Icon/Icon";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MCTooltip } from "../../MainComponents";
import "./FormsList.scss";

export const OwnForms = ({
  formList,
  setSelectedPreview,
  setShowPreviewModal,
}) => {
  const [t] = useTranslation("forms");
  const navigate = useNavigate();
  const ownFormRef = useRef(null);

  const formListwithoutCurrRep = () => {
    return formList.filter((item) => item.identifier_name !== "report");
  };

  const scrollMore = () => {
    ownFormRef.current.scrollTo({
      left: ownFormRef.current.scrollLeft + 400,
      behavior: "smooth",
    });
  };
  const scrollLess = () => {
    ownFormRef.current.scrollTo({
      left: ownFormRef.current.scrollLeft - 400,
      behavior: "smooth",
    });
  };

  const handlePreviewForm = (id) => {
    setSelectedPreview(id);
    setShowPreviewModal(true);
  };

  return (
    <>
      <div className="own-forms-title">
        <h5>{t("OwnForms.my_forms")}:</h5>
        <div className="d-flex">
          <div onClick={scrollLess} className="mr-3">
            <Icon name="arrow_left_3" className="dyIcon2 pointer" />
          </div>
          <div onClick={scrollMore}>
            <Icon name="outline_arrow_right_4" className="dyIcon2 pointer" />
          </div>
        </div>
      </div>

      <div className="own-form-list" ref={ownFormRef}>
        {/* Nuevo Formulario */}
        <div className="own-form-new">
          <div className="own-form-new-container">
            <div className="form-list-item">
              <MCTooltip text={t("OwnForms.create_new_form")} position="bottom">
                <div
                  className="form-list-item-square"
                  onClick={() => navigate("/createform")}
                >
                  <Icon name="add_square" />
                </div>
              </MCTooltip>
              <label>{t("OwnForms.new")}</label>
            </div>
            <div className="own-form-new-border"></div>
          </div>
        </div>
        {/* Current report */}
        {Array.isArray(formList) &&
          formListwithoutCurrRep().map((item, idx) => (
            <div className="form-list-item exist" key={item.id}>
              <div className="form-list-item-square">
                <Icon name="outline_clipboard_text" />
                <Dropdown>
                  <Dropdown.Toggle variant="">
                    <div className="form-list-item-more">
                      <Icon name="more_dots" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => navigate(`/editform/${item.id}`)}
                    >
                      <Icon name="outline_edit" />
                      <span className="ms-2">{t("OwnForms.edit")}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePreviewForm(item.id)}>
                      <Icon name="outline_eye" />
                      <span className="ms-2">{t("OwnForms.preview")}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <MCTooltip text={item.identifier_name} position="bottom">
                <div className="containerFormName">
                  <label className="formName">{item.identifier_name}</label>
                </div>
              </MCTooltip>
            </div>
          ))}
      </div>
    </>
  );
};
