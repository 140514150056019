import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Row } from "react-bootstrap";
import { MCButton } from "../../MainComponents/Button/Button";
import { getListGroupEAM } from "../../../apis/apiEAM";
import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

export const SegmentationList = () => {
  const { gTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [listSegments, setListSegments] = useState(null);
  const [t] = useTranslation("users");

  useEffect(() => {
    getListGroupEAM().then((resp) => setListSegments(resp.response));
  }, []);

  const breadcrumbItems = [
    { label: t("Groups.home"), route: "/" },
    { label: t("Groups.users"), route: "/users" },
    { label: t("Groups.groups") },
  ];

  return (
    <div
      id="settings"
      className={`page ${gTheme === "light" ? "" : "dark-mode"}`}
    >
      <GeneralIconHeader
        title={t("Groups.groups")}
        text={t("Groups.manage_create_user_groups")}
        icon="outline_people"
      />

      <Breadcrum items={breadcrumbItems} />

      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
        {Array.isArray(listSegments) &&
          listSegments.map((item, idx) => (
            <div
              className="d-flex justify-content-between align-items-center w-50 my-2"
              key={idx}
            >
              <span className="me-3">{item.name}</span>
              <div>
                <MCButton
                  label={t("Groups.edit")}
                  variant="primary"
                  size="sm"
                  className="me-2"
                  outline
                  onClick={() => navigate(`/segments/${item.id}`)}
                />
                <MCButton label={t("Groups.delete")} variant="danger" size="sm" outline />
              </div>
            </div>
          ))}
        <MCButton
          label={t("Groups.create_group")}
          variant="primary"
          outline
          className="mt-3"
          onClick={() => navigate("/segments/new")}
        />
      </div>
    </div>
  );
};
