import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import Icon from "../Icon/Icon";
import Mexico from "../../assets/images/mexico.png";
import {
  parseDatoToLgAndHZ,
  validInitDates,
} from "../../helpers/dateTransform";
import "./GeneralHeader.scss";

export const GeneralHeader = ({
  title = "",
  text = "",
  path = "",
  showBackButton = true,
  showLang = false,
  showPath = false,
  showText = true,
  date = "",
  showDate = false,
  textRight = false,
  className = "",
}) => {
  const navigate = useNavigate();

  return (
    <div className={`general-header dyTheme1 dyBorder1 rounded ${className}`}>
      <Row>
        <Col lg="6" className="p-0 d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center">
            {showBackButton && (
              <span onClick={() => navigate(-1)} className="pointer backButton">
                <Icon name="arrow_left_3" />
              </span>
            )}
            <h3 className="mb-0 general-header-title">{title}</h3>
          </div>
          <div>
            {showText && !textRight && <p className="mb-0 font-14">{text}</p>}
          </div>
          {showDate && (
            <div>
              <p className="mb-0">
                {validInitDates(date)
                  ? parseDatoToLgAndHZ(date)
                  : "mié, 12 de jul de 2023 a 21:40:12 GMT-6"}
              </p>
            </div>
          )}
        </Col>
        <Col
          lg="6"
          className="p-0 text-lg-end d-flex flex-column justify-content-end"
        >
          {showLang && (
            <div className="d-flex align-items-center justify-content-start justify-content-lg-end mt-3">
              <img src={Mexico} alt="mexico" className="me-2" />
              <h6 className="mb-0">Español - México</h6>
            </div>
          )}
          {showText && textRight && (
            <p className="d-none d-sm-block mb-0 my-2 font-14">{text}</p>
          )}
          {showPath && <small className="mt-3">{path}</small>}
        </Col>
      </Row>
    </div>
  );
};

export const GeneralIconHeader = ({
  title = "",
  text = "",
  path = "",
  icon = "",
  showBackButton = true,
  showLang = false,
  showPath = false,
  showText = true,
  date = "",
  textRight = false,
  className = "",
  component = null,
  subtitle = "",
}) => {
  const navigate = useNavigate();

  return (
    <div className={`general-header dyTheme1 dyBorder1 rounded ${className}`}>
      <Row>
        <Col lg="12" className="p-0 d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center">
            <span className="title-icon">
              <Icon float="left" width="48" height="48" name={icon} />
            </span>
            <div>
              <h3 className="mb-0 general-header-title">{title}</h3>
              {subtitle.trim() !== "" && <h5 className="my-1">{subtitle}</h5>}
              <p className="mb-0 font-14">{text}</p>
              {date.trim() !== "" && <p className="mb-0 font-14">{date}</p>}
            </div>
          </div>
        </Col>
        <Col
          lg="6"
          className="p-0 text-lg-end d-flex flex-column justify-content-end"
        >
          {showLang && (
            <div className="d-flex align-items-center justify-content-start justify-content-lg-end mt-3">
              <img src={Mexico} alt="mexico" className="me-2" />
              <h6 className="mb-0">Español - México</h6>
            </div>
          )}
          {showText && textRight && (
            <p className="d-none d-sm-block mb-0 my-2 font-14">{text}</p>
          )}
          {showPath && <small className="mt-3">{path}</small>}
          {component && <div className="justify-content-end">{component}</div>}
        </Col>
      </Row>
    </div>
  );
};
