import { useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap"
import { createNotificationSystem, deleteNotificationCenter, fakeGetNotificationCenter, getNotificationCenter, updateNotificationCenter } from "../../../apis";
import { MCInput } from "../../../components/MainComponents/Input/Input";
import { MCButton } from "../../../components/MainComponents/Button/Button";

const languages = {
  "es": "Español",
  "en": "Ingles",
  "pt-BR": "Portugues",
}

export const ModalEditNotificationCenter = ({ alias, show, onHide, refreshList, refreshListSystem }) => {

  const [retriveNotification, setRetriveNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (alias) {
      setIsLoading(true);
      getNotificationCenter(alias).then(resp=>{
        setRetriveNotification(resp.data);
        setTitle(resp.data.title);
        setMessage(resp.data.message);
        setInfo(resp.data.info);
        setIsLoading(false);
      })
    }
  }, [alias]);
  

  const handleUpdate = async() => {
    const objToSend = {
      title,
      message,
      info
    }
    try {
      const resp = await updateNotificationCenter(alias, objToSend);
      refreshList();
      refreshListSystem();
      onHide();
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async() => {
    try {
      const resp = await deleteNotificationCenter(alias);
      refreshList();
      refreshListSystem();
    } catch (error) {
      console.log(error)
    }
  }

  const handleTitleChange = (lang, value) => {
    const newTitle = _.cloneDeep(title);
    _.set(newTitle, `${lang}`, value);
    setTitle(newTitle);
  }

  const handleMessageChange = (lang, value) => {
    const newMessage = _.cloneDeep(message);
    _.set(newMessage, `${lang}`, value);
    setMessage(newMessage);
  }

  const handleInfoChange = (lang, idx, value) => {
    const newInfo = _.cloneDeep(info);
    _.set(newInfo, `${lang}.${idx}.name`, value);
    setInfo(newInfo);
  }

  const handleCreateNotificationSystem = async() => {
    const objToSend = {
      notification_center: alias, 
      send_email: true,
      send_system_notification: true
    }
    try {
      const resp = await createNotificationSystem(objToSend);
      refreshListSystem();
      onHide();
    } catch (error) {
      console.log(error)
    }
  }

  return !isLoading && retriveNotification &&
    <Modal show={show} onHide={onHide} size="xl" >
      
      {/* Title */}
      <h5>Titulo</h5>
      <section>
        {
          Object.entries(title).map(([key, value])=>
          <div key={key}>
            <label>{ languages[key] }:</label>
            <MCInput value={ value } onChange={(e)=>handleTitleChange(key, e.target.value)} />
          </div>
          )
        }
      </section>

      <hr />

      {/* Message */}
      <h5>Mensaje</h5> 
      <section>
        {
          Object.entries(message).map(([key, value])=>
          <div key={key}>
            <label>{ languages[key] }:</label>
            <MCInput value={ value } onChange={(e)=>handleMessageChange(key, e.target.value)} />
          </div>
          )
        }
      </section>

      <hr />

      {/* Info */}
      <h5>Mensaje</h5> 
      <section>
        {
          Object.entries(info).map(([key, value])=>
          <div key={key}>
            <label>{ languages[key] }:</label>
            {
              Array.isArray(value) && value.map((item,idx)=>
                <MCInput 
                  key={idx}
                  className="mb-2" 
                  value={ item?.name || "" } 
                  onChange={(e)=>handleInfoChange(key, idx, e.target.value)} 
                />
              )
            }
          </div>
          )
        }
      </section>

      <div className="d-flex justify-content-between">

        <div>
          <MCButton 
            label="Eliminar Centro de notificaciones"
            variant="danger"
            onClick={handleDelete}
            className="me-2"
          />
          <MCButton 
            label="Crear notificacion de sistema"
            variant="success"
            onClick={handleCreateNotificationSystem}
          />
        </div>
        

        <div>
          <MCButton 
            label="Cancelar"
            variant="primary"
            outline
            onClick={onHide}
            className="me-2"
          />
          <MCButton 
            label="Guardar"
            variant="primary"
            onClick={handleUpdate}
          />
        </div>

      </div>



    </Modal>
}
