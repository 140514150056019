import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './Mobilestyles.scss';
import { login, retrieveCatalogueByPath } from "../../apis";
import { searchNavbarAPI } from '../../apis/apiReports';
import {
    catalogByPart,
    listCatalogues,
} from "../../apis/apiCatalogues";
import { Modal, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { MCButton } from "../MainComponents";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import {
    SearchIcon2,
    OutlineMessages3,
    Monitor,
    Call,
    OutlineSms,
    OutlineMessages2,
    Mobile,
    Grammerly,
    CdIcon,
} from "../Icon";
import { parseDateToISOString } from "../../helpers/dateTransform";

import { utilitiesDashboard } from "../../apis/apiDashboard";
import Search from "../../assets/images/vuesax-outline-search-normal2.png";


import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import MobileSimpleSearch from "./MobileSimpleSearch"
import AdvancedSearch from "./MobileAdvancedSearch"





const MobileSearch = ({ placeholder, onChange, options, value, onKeyDown, onSubmit = () => { }, ...rest }, props) => {
    const [t] = useTranslation("report");
    const [showDetails, setShowDetails] = useState(false);
    const modalRef = useRef(null);
    const modalRefHeader = useRef(null);
    const modalRefSearchBar = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const smallSearchRef = useRef(null);
    const bigSearchRef = useRef(null);
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('general');





    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setShowDetails(false);
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const closeTap = () => {
        setShowDetails(false);
        setSearchTerm('');
    };

    useEffect(() => {
        // Cierra el modal cuando la URL cambie
        setSearchTerm('');
        setShowDetails(false);
    }, [location, setShowDetails]);

    const handleFocus = () => {
        //se tarda 2 milisegundos mas para colocar la barra de escritura del modal
        setTimeout(() => {
            setShowDetails(true);
        }, 150);
        setTimeout(() => {
            bigSearchRef.current.focus();
        }, 152);
    };

    useEffect(() => {
        // }, [searchTerm, startDate, endDate, kindOfEntrywaysValue, kindOfStatusValue, kindOfSeverityValue, kindOfSubCatalogKey, kindOfSubCatalogValues]);
    }, [searchTerm]);
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };




    return (
        <div>
            {!showDetails && (



                <img
                    src={Search}
                    className="mobile-search"
                    alt="search"
                    onClick={handleFocus}
                />



            )}
            {showDetails && (
                <>
                    <Modal
                    className="custom-modal" 
                        show={showDetails}
                        onHide={closeTap}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        fullscreen = {true}
                        dialogClassName="custom-modal"
                    >
                        <Modal.Body>
                            <div className="mobile-custom-searchBiggest" ref={modalRefHeader}>
                                <form className="mobile-searchsInputs" onSubmit={(e) => e.preventDefault()} ref={modalRefSearchBar}>
                                    <div className="mobile-searchIcon">
                                        <SearchIcon2 size={16} />
                                    </div>
                                    <input
                                        placeholder={t("searchBar.search_in_casemanager")}
                                        id="header-search"
                                        name="report"
                                        autoComplete="off"
                                        onFocus={handleFocus}
                                        onChange={handleInputChange}
                                        ref={bigSearchRef}
                                        className={"mobile-searchbar"}
                                    />
                                    <div className="mobile-close-icon" onClick={closeTap}>
                                        <FaTimes size={16} />
                                    </div>
                                </form>


                                <div className="mobile-search-details" ref={modalRef}>
                                    <div className="mobile-search-details-section">
                                        <Row>
                                            <Col xs={12} md={12} xxl={12}>
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={activeKey}
                                                    onSelect={(k) => setActiveKey(k)}
                                                    className="mobile-tabs-class mb-3"
                                                >
                                                    <Tab
                                                        eventKey="general"
                                                        title={
                                                            <span
                                                                style={{
                                                                    fontFamily: 'Segoe UI',
                                                                    fontSize: '20px',
                                                                    fontWeight: 600,
                                                                    color: activeKey === 'general' ? '#009ed7' : '#546E7A'
                                                                }}
                                                            >
                                                                {t("searchBar.basic_search")}
                                                            </span>
                                                        }
                                                        className="mobile-table-results"
                                                    >
                                                        <MobileSimpleSearch searchTermSecondary={searchTerm} />
                                                    </Tab>
                                                    {/* <Tab
                                                    eventKey="notifications"
                                                    title={
                                                        <span
                                                            style={{
                                                                fontFamily: 'Segoe UI',
                                                                fontSize: '20px',
                                                                fontWeight: 600,
                                                                color: activeKey === 'notifications' ? '#009ed7' : '#546E7A'
                                                            }}
                                                        >
                                                            {t("searchBar.advanced_search")}
                                                        </span>
                                                    }
                                                    className="table-results"
                                                >
                                                    <AdvancedSearch searchTermSecondary={searchTerm} />
                                                </Tab> */}
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </div>
    );
}


export default MobileSearch;
