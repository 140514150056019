// apCognito.js

import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

// function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

//ENDPOINT LOGIN
export const login = async (email, password) => {
  try {
    const resp = await apiCall.post("/login/", { email, password });
    return resp;
  } catch (error) {
    localStorage.removeItem("session", "Tenant");
    console.error(error.message);
  }
};

//ENDPOINT LOGIN
export const loginMFA = async (uuid, otp_code, session, email) => {
  try {
    const resp = await apiCall.post("/login/2fa", { uuid, otp_code, session, email });
    return resp;
  } catch (error) {
    localStorage.removeItem("session", "Tenant");
    throw new Error(error.message)
  }
};

//ENDPOINT CHECK VALID TOKEN
export const checkToken = async (idToken, accessToken) => {
  try {
    const resp = await apiCall.get(`/validate_credentials/`, {
      headers: {
        IdToken: idToken,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error.message);
    throw new Error("Error al iniciar sesion");
  }
};

//ENDPOINT LOGOUT
export const logout = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/cognito/logout/`,
      {},
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.failed_logout}`);
    console.error(error);
  }
};

//ENDPOINT SIGN UP
export const createUserAdmin = async (
  data = {
    email: "",
    username: "",
    password: "",
    phone_number: "",
  }
) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/admin/signup/`, data);
    return resp.data;
  } catch (error) {
    return error;
  }
};

//ENDPOINT SIGN UP
export const confirmUser = async (
  data = { email: "", verification_code: "" }
) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/confirm/user/`, data);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_trying_verify_user}`);
    console.error(error);
  }
};
