import { useDispatch, useSelector } from "react-redux";
import Signup from "./Signup";
import Signin from "./Signin";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ESP from "../../assets/image/flags/mexico.png";
import ENG from "../../assets/image/flags/usa.png";
import BR from "../../assets/image/flags/brasil.png";
import Carrousel from "./Carrousel";
import "./Auth.scss";
import { startToChangueLang } from "../../store/lang/langThunks";
import { startSettingDomain } from "../../store/auth/authThunks";
import { useEffect, useState } from "react";
import { MCButton } from "../../components/MainComponents";
import Icon from "../../components/Icon/Icon";

const flags = {
  es: ESP,
  en: ENG,
  "pt-BR": BR,
};

const DropDownLanguage = ({ lang, handleChangeLang }) => {
  return (
    <DropdownButton
      className="language__dropdown"
      align={"end"}
      drop="down-centered"
      variant="light"
      title={<img className="language__flag" src={flags[lang]} />}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        style={{ textAlign: "center" }}
        onClick={() => handleChangeLang("es")}
      >
        <img src={ESP} className="language__flag" alt="ESP" />
        &nbsp; ES
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        style={{ textAlign: "center" }}
        onClick={() => handleChangeLang("en")}
      >
        <img src={ENG} className="language__flag" alt="ENG" />
        &nbsp; EN
      </Dropdown.Item>
    </DropdownButton>
  )
}

const DomainSelection = ({ domainList, selectedDomain, setSelectedDomain }) => {

  const dispatch = useDispatch();

  const handleSelectTenant = () => {
    dispatch(startSettingDomain(selectedDomain));
  }

  useEffect(() => {
    const keyDownDomain = (e) => {
      if (e.key === "ArrowDown") {
        setSelectedDomain(prevSelDom=>{
          const selectedDomainIndex = domainList.findIndex(domain => domain == prevSelDom);
          if (selectedDomainIndex >= domainList.length-1) {
            return domainList[0];
          } else {
            return domainList[selectedDomainIndex+1];
          }
        })
      }
      if (e.key === "ArrowUp") {
        setSelectedDomain(prevSelDom=>{
          const selectedDomainIndex = domainList.findIndex(domain => domain == prevSelDom);
          if (selectedDomainIndex == 0) {
            return domainList[domainList.length-1];
          } else {
            return domainList[selectedDomainIndex-1];
          }
        })
      }
      if (e.key === "Enter") {
        handleSelectTenant();
      }
    };
    document.addEventListener("keydown", keyDownDomain);
    return () => {
      document.removeEventListener("keydown", keyDownDomain);
    };
  }, [selectedDomain]);

  return (
    <div className="domain-selection">
      <div className="mb-3">
        <h2 style={{ textAlign: "center" }}>
          Selecciona un dominio
        </h2>
        <h3 style={{ textAlign: "center", color: "grey" }}>
          Tienes acceso a {domainList.length} dominio(s)
        </h3>
      </div>
      <>
        <ul className="domain-list">
          {
            domainList.map((domain,idx)=>(
              <li 
                key={idx}
                className={`domain-list-item ${selectedDomain === domain ? "active" : ""}`}
                onClick={()=>setSelectedDomain(domain)}>
                  <div className="d-flex jusify-content-start">
                    <div className="me-3">
                      <Icon name="outline_profile_2_user" />
                    </div>
                    <div>
                      {domain}
                    </div>
                  </div>
                  <div className="arrow_in">
                    <Icon name="outline_arrow_right_4" />
                  </div>
              </li>
            ))
          }
        </ul>
        <div className="d-flex justify-content-center">
          <MCButton label="Seleccionar dominio" variant="primary" onClick={handleSelectTenant} className="mt-3" />
        </div>
      </>
    </div>
  )
}

const Auth = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.lang);
  const { isLoggedIn, tenant, status } = useSelector((state) => state.auth);

  //-----Lenguage provider-----//
  const [t, i18n] = useTranslation("login");
  
  const [domainList, setDomainList] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");

  const handleChangeLang = (newLang) => {
    i18n.changeLanguage(newLang);
    dispatch(startToChangueLang(newLang));
  };

  return (
    <div className="authContainer">
      {
        isLoggedIn && !tenant 
        ? <div className="auth">
            <DomainSelection 
                domainList={domainList}
                selectedDomain={selectedDomain}
                setSelectedDomain={setSelectedDomain}
              />
          </div> 
        : <Row className="auth">
            <Col className="auth__left">
              <div className="login">
                <div className="language">
                  <DropDownLanguage 
                    lang={lang}
                    handleChangeLang={handleChangeLang}
                  />
                </div>
                <div className="form">
                  <Signin
                    setDomainList={setDomainList}
                    setSelectedDomain={setSelectedDomain}
                    mainTitle={t("Auth.sign_in")} 
                  />
                </div>
              </div>
            </Col>
            <Col className="auth__right">
              <Carrousel />
            </Col>
          </Row>
      }
      <span className="copyright">
        Powered by EthicsGlobal®{new Date().getFullYear()}
      </span>
    </div>
  );
};

export default Auth;
