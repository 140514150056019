import { useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Icon from "../Icon/Icon";
import { MCSelect } from "../MainComponents/Select/Select";
import { EditarPerfil } from "./EditarPerfil";
import Pagos from "./Pagos";
import Seguridad from "./Seguridad";
import Soporte from "./Soporte";
import { useTranslation } from "react-i18next";
import "./Profile.scss";
import { Notifications } from "../../screens/Settings/Notifications/Notifications";

const languages = [
  {
    value: "en",
    text: "English-USA",
  },
  {
    value: "es",
    text: "Español-Mexico",
  },
];
export const ProfileScreen = (props) => {
  const [activeTab, setActiveTab] = useState("editar");
  const navigate = useNavigate();
  const [t] = useTranslation("perfil");

  return (
    <>
      <div className="page">
        <div className="dyTheme1 dyBorder1 general-header">
          <Row>
            <Col
              lg="9"
              className="p-0 d-flex flex-column justify-content-center"
            >
              <div className="d-flex align-items-center">
                <span
                  onClick={() => navigate(-1)}
                  className="pointer backButton"
                >
                  <Icon name="arrow_left_3" />
                </span>
                <h3 className="mb-0 general-header-title">
                  {t("profile.my_profile")}
                </h3>
              </div>
              <div>
                <p className="mb-0 font-14">
                </p>
              </div>
            </Col>
            <Col lg="3" className="p-0">
              {/* <MCSelect
                placeholder="Español - México"
                options={languages.text}
              /> */}
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Tabs
            activeKey={activeTab}
            onSelect={setActiveTab}
            className="mb-4 dyTheme1 dyBorder1 rounded"
          >
            <Tab eventKey="editar" title={t("profile.edit_profile")} tabClassName="ms-2 me-2">
              <EditarPerfil />
            </Tab>
            <Tab eventKey="notificaciones" title={t("profile.notifications")} tabClassName="me-2">
              <Notifications />
            </Tab>
            {/* <Tab eventKey="pagos" title={t("profile.payments")}>
              <Pagos theme={props.vTheme} />
            </Tab>
            <Tab eventKey="seguridad" title={t("profile.security")}>
              <Seguridad theme={props.vTheme} />
            </Tab>
            <Tab eventKey="soporte" title={t("profile.support")}>
              <Soporte theme={props.vTheme} />
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </>
  );
};
