import { useSelector } from "react-redux"
import Select from 'react-select';
import { useTranslation } from 'react-i18next';


export const MCSelect = ({ options, onChange = () => {}, menuWidth=null, optionLabel = "label", optionValue = "value", isValid = true, ...rest }) => {
    const { gTheme } = useSelector(state => state.theme);
    const [t] = useTranslation("components")
    // console.log(rest);
    return (
        <Select
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: !isValid ? "solid 1px #f5425a" : gTheme === "light" ? "solid 1px #e3ebf5" : "solid 1px #363742",
                    backgroundColor: gTheme === "light" ? "#fff" : "#242836",
                    filter: state.isDisabled ? "contrast(90%)" : "none"
                }),
                option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? state.isFocused ? gTheme === "light" ? "#000000" : "#bdcad0 !important" : '#00000 !important' : gTheme === "light" ? "#78909c !important" : "#bdcad0 !important",
                    background: state.isFocused ? gTheme === "light" ? "#e6ebef" : "#32384d" : '#ffff',
                }),
                input: (provided, state) => ({
                    ...provided,
                    // color: gTheme === "light" ? "#324148 !important" : "#b0bec5 !important",
                    color: !isValid ? "#f5425a" : gTheme === "light" ? "#324148 !important" : "#b0bec5 !important"
                }),
                singleValue: (styles, { data }) => ({
                    ...styles,
                    // color: gTheme === "light" ? "#324148 !importtant" : "#b0bec5 !important",
                    color: !isValid ? "#f5425a" : gTheme === "light" ? "#324148 !important" : "#b0bec5 !important"
                }),
                placeholder: (provider, state) => ({
                    ...provider,
                    // color: gTheme === "light" ? "#324148 !important" : "#b0bec5 !important",
                    color: !isValid ? "#de463d" : gTheme === "light" ? "#324148 !important" : "#b0bec5 !important"                    
                }),
                menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: gTheme === "light" ? "#fff" : "#242836",
                    minWidth: "100%",
                    ...(!isNaN(menuWidth) && { width: menuWidth }),
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                ...(!isValid && {
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#f5425a", // Cambia esto al color que desees
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: "#f5425a", // Cambia esto al color que desees
                    }),
                })
            }}
            placeholder={t("MCSelect.select_option")}
            options={options}
            onChange={(e) => onChange(e)}
            getOptionLabel ={(option)=>option[optionLabel]}
            getOptionValue ={(option)=>option[optionValue]}
            {...rest}
        />
    );
}