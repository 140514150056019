import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import Icon from "../../Icon/Icon";
import { MCButton, MCTooltip } from "../../MainComponents";
import _ from "lodash";
import ModalAddRole from "./ModalAddRole";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";
import { getListRoles } from "../../../apis";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
import "./Roles.scss";

const RolesList = ({ roles, handleCurrentRole }) => {
  const [t] = useTranslation("roles");
  return (
    <div>
      <ul>
        {roles.map((item) => (
          <li
            key={item.id}
            className="role-list-item d-flex justify-content-between align-items-center"
            onClick={() => handleCurrentRole(item.id)}
          >
            <h5>{item.name}</h5>
            <div className="d-flex">
              <div>
                <MCTooltip position="bottom" text={t("rolesUser.disable_role")}>
                  <div>
                    <MCButton
                      label=""
                      icon={item.is_active ? "check_circle" : "cancel_circle"}
                      variant={item.is_active ? "success" : "danger"}
                      size="sm"
                      className="p-0"
                      width={28}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </MCTooltip>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Pagina de Roles
export const RolesUser = () => {
  const [t] = useTranslation("roles");
  const location = useLocation();
  const navigate = useNavigate();
  const [rerenderRolesList, setRerenderRolesList] = useState(false);

  const [showModalAddRole, setShowModalAddRole] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [searchChecked, setSearchChecked] = useState(false);

  const breadcrumbItems = [
    { label: t("rolesUser.home"), route: "/" },
    { label: t("rolesUser.settings"), route: "/settings" },
    { label: t("rolesUser.configure_roles") },
  ];

  // Obtener la lista de los roles
  useEffect(() => {
    if (rerenderRolesList) {
      setRerenderRolesList(false);
    } else {
      getListRoles()
        .then((resp) => {
          setListRoles(resp.response);
        })
        .catch(console.log);
    }
  }, [rerenderRolesList]);

  // Ver si el componente se carga con un role en el search
  useEffect(() => {

    if ( Array.isArray(listRoles) &&  listRoles.length > 0 && !searchChecked) {
      const roleId = queryString.parse(location.search)?.role || null;
      if (roleId) {
        if (listRoles.find((i) => i.id === Number(roleId))) {
          setCurrentRole(roleId);
          setShowModalAddRole(true);
        } else {
          navigate("/users/roles");
        }
      }
      setSearchChecked(true);
    }
  }, [listRoles, searchChecked]);

  const handleCurrentRole = (idx) => {
    if (idx) {
      navigate(`/users/roles?role=${idx}`);
      setCurrentRole(idx);
      setShowModalAddRole(true);
    } else {
      navigate(`/users/roles`);
      setCurrentRole(null);
    }
  };

  return (
    <div id="settings" className="roles-page">
      {showModalAddRole && (
        <ModalAddRole
          onHide={() => setShowModalAddRole(false)}
          currentRole={currentRole}
          handleCurrentRole={handleCurrentRole}
          rerenderList={() => setRerenderRolesList(true)}
        />
      )}
      {/* HEADER */}
      <div className={`general-header dyTheme1 dyBorder1 rounded`}>
        <Row>
          <Col lg="8" className="p-0 d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <span className="title-icon">
                <Icon
                  float="left"
                  width="48"
                  height="48"
                  name={"outline_security_safe"}
                />
              </span>
              <div className="mb-2">
                <h3 className="mb-0 general-header-title">
                  {t("rolesUser.configure_roles")}
                </h3>
                <p className="mb-0 font-14 text-justify">
                  {t("rolesUser.administer_roles_permissions")}
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg="3"
            className="p-0 d-flex justify-content-end align-items-center"
          >
            <div>
              <MCButton
                label={t("rolesUser.new_role")}
                variant="primary"
                outline
                onClick={() => setShowModalAddRole(true)}
                className="me-2"
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="my-3">
        <Breadcrum items={breadcrumbItems} />
      </div>

      {/* CONTENT */}
      <div className="p-20 mt-20 dyTheme1 dyBorder1 rounded roles-list">
        <RolesList roles={listRoles} handleCurrentRole={handleCurrentRole} />
      </div>
    </div>
  );
};
