import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { memo } from 'react';

import Modal from "react-bootstrap/Modal";

import { statusReport, changeConfigurationSubStatus } from "../../../apis/apiReports";
import { MCButton } from "../../../components/MainComponents/Button/Button";
import { MCInput } from "../../../components/MainComponents/Input/Input";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DataTable } from "../../../components/DataTable/DataTable";
import { OutlineEdit, AddIconSquare, MinusIconSquare } from "../../../components/Icon";
import { closeSnackbar, useSnackbar } from "notistack";
import "./styles.scss";
import { login } from "../../../apis";


export const SubStatusConfig = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("settings");
  const { swalTheme } = useSelector((state) => state.theme);
  const [isLoading, setIsLoading] = useState(true);
  const [subStatus, setSubStatus] = useState([]);
  const [editing, setEditing] = useState(null);
  const [newLabel, setNewLabel] = useState('');
  const [updatedDataStructure, setUpdatedDataStructure] = useState({})
  const [enabledSave, setEnabledSave] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);




  // save and upload changes
  const handleSave = async () => {
    enqueueSnackbar(`${t("SubStatus.successful")}`, {
      variant: "success",
      anchorOrigin: { horizontal: "left", vertical: "bottom" },
      preventDuplicate: true,
      autoHideDuration: 2000,
    });
    setEnabledSave(false);
    if (updatedDataStructure == {} || updatedDataStructure == null) {
      return;
    } else {
      await changeConfigurationSubStatus(updatedDataStructure);
    }
  };

  const obtainSubStatus = async () => {
    const [status] = await Promise.all([
      statusReport(),
    ]);

    // console.log("STATUS", status);






    const subStatusArray = status.data.map((statusItem) => {
      const { status, sub_status } = statusItem;
  
      // Asegúrate de que sub_status sea un array válido con al menos un elemento
      if (!sub_status || sub_status.length === 0 || !sub_status[0]) {
          console.warn(`sub_status for status: ${status} is empty or undefined`);
          return {
              mainKey: status,
              parentChecked: false,
              subStatuses: [] // Se usa un array vacío en lugar de null
          };
      }
  
      return {
          mainKey: status,
          parentChecked: false,
          subStatuses: Object.entries(sub_status[0]).map(([subKey, subStatusDetails]) => ({
              subKey,
              ...subStatusDetails
          }))
      };
  });



    // const subStatusArray = Object.entries(status.data.sub_status).map(([mainKey, subStatusList]) => ({
      
    //   mainKey,
    //   parentChecked: false,
    //   subStatuses: Object.entries(subStatusList[0]).map(([subKey, subStatusDetails]) => ({
    //     subKey,
    //     ...subStatusDetails
    //   }))
    // }));

    setSubStatus(subStatusArray);
    setIsLoading(false);
  };

  useEffect(() => {
    obtainSubStatus();
  }, []);

  const createDataStructure = (updatedSubStatus) => {
    const structure = {};

    updatedSubStatus.forEach(item => {
      const parentKey = item.mainKey;
      const subStatusItems = item.subStatuses.reduce((acc, subItem) => {
        Object.entries(subItem).forEach(([key, value]) => {
          if (key !== 'subKey') {
            if (!acc[parentKey]) {
              acc[parentKey] = {};
            }
            acc[parentKey][key] = {
              key: value.key,
              checked: false,
              updated_at: value.updated_at,
              updated_by: value.updated_by
            };
          }
        });
        return acc;
      }, {});
      structure[parentKey] = [subStatusItems];
    });
    return structure;
  };

  const handleAddCheckbox = (mainKey) => {
    setEditing({ mainKey });
  };

  const handleLabelChange = (event) => {
    setNewLabel(event.target.value);
  };

  const handleSaveLabel = (mainKey, newLabel) => {
    const updatedSubStatus = subStatus.map(item => {
      if (item.mainKey === mainKey) {
        const updatedSubStatuses = [...item.subStatuses];
  
        // Verificar si el newLabel ya existe en subStatuses
        const labelExists = Object.values(updatedSubStatuses[0] || {}).some(
          subItem => subItem.key === newLabel
        );
  
        if (labelExists) {
          // Si existe un label igual, retorna un error
          enqueueSnackbar(`${t("SubStatus.alreadyExists")}`, {
            variant: "error",
            anchorOrigin: { horizontal: "left", vertical: "bottom" },
            preventDuplicate: true,
            autoHideDuration: 2000,
          });
          console.error('Error: El label ya existe en los subStatus.');
          return item; // O simplemente puedes retornar aquí si no deseas continuar
        }
  
        if (updatedSubStatuses.length === 0) {
          // Si subStatuses está vacío, inicializa un nuevo objeto vacío
          updatedSubStatuses.push({});
        }
  
        const subIndex = Object.keys(updatedSubStatuses[0]).length;
        const newSubKey = `${mainKey}_${subIndex}_${newLabel}`;
  
        updatedSubStatuses[0] = {
          ...updatedSubStatuses[0],
          [newSubKey]: {
            key: newLabel,
            checked: false,
            updated_at: '',
            updated_by: ''
          }
        };
  
        return { ...item, subStatuses: updatedSubStatuses };
      }
      return item;
    });
  
    setSubStatus(updatedSubStatus);
    setEditing(null);
    setUpdatedDataStructure(createDataStructure(updatedSubStatus));
    setModalContent(updatedSubStatus.find(item => item.mainKey === mainKey).subStatuses);
    setEnabledSave(true);
  };


  const handleDeleteItem = (mainKey, subKey) => {


    const updatedSubStatus = subStatus.map(item => {
      if (item.mainKey === mainKey) {

        const updatedSubStatuses = item.subStatuses.map(subItem => {
          const updatedSubStatusDetails = Object.keys(subItem)
            .filter(key => key !== subKey) // Filtra la subKey que se quiere eliminar
            .reduce((acc, key) => {
              acc[key] = subItem[key];
              return acc;
            }, {});


          return updatedSubStatusDetails; 
        });


        return { ...item, subStatuses: updatedSubStatuses };
      }
      return item;
    });

    // console.log("================================================");
    // console.log(updatedSubStatus, mainKey, subKey);
    // console.log("================================================");

    setSubStatus(updatedSubStatus);
    setUpdatedDataStructure(createDataStructure(updatedSubStatus));
    setModalContent(updatedSubStatus.find(item => item.mainKey === mainKey).subStatuses);

    setEnabledSave(true);
  };





  const closeModal = () => setModalVisible(false);

  const columns = ["label", "actions"];

  const tableInfo = subStatus?.map((item, index) => ({
    id: index,
    label: item.mainKey,
  }));


  const actions = (row) => {
    const openModal = () => {
      const item = subStatus.find((item) => item.mainKey === row.label);
      setModalContent(item?.subStatuses);
      setCurrentItem(item);
      setModalVisible(true);
    };

    return (
      <div className="mt-3 mr-20">
        <Button
          variant="link"
          onClick={openModal}
          style={{ outline: 'none', boxShadow: 'none' }}
          className="buttonSee"
        >
          <OutlineEdit />
        </Button>

        <EditStatusModal
          show={modalVisible}
          onHide={closeModal}
          content={modalContent}
          currentItem={currentItem}
          handleDeleteItem={handleDeleteItem}
          handleAddCheckbox={handleAddCheckbox}
          editing={editing}
          newLabel={newLabel}
          handleLabelChange={handleLabelChange}
          handleSaveLabel={handleSaveLabel}
        />
      </div>
    );
  };

  const EditStatusModal = memo(({
    show,
    onHide,
    content,
    currentItem,
    handleDeleteItem,
    handleAddCheckbox,
    editing,
    handleSaveLabel
  }) => {
    const [localContent, setLocalContent] = useState(content);
    const [localNewLabel, setLocalNewLabel] = useState('');

    useEffect(() => {
      // Cada vez que `content` cambie, actualiza `localContent`
      setLocalContent(content);
    }, [content]);

    const handleLocalLabelChange = (event) => {
      setLocalNewLabel(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSaveLabel(currentItem.mainKey, localNewLabel);
        setLocalNewLabel(''); 
      }
    };

    return (
      <>
        {show && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(1px)',
            WebkitBackdropFilter: 'blur(8px)',
            zIndex: 1049
          }} />
        )}

        <Modal
          show={show}
          onHide={onHide}
          centered
          backdrop={false}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`${t("SubStatus.set_SubStatus")} ${currentItem?.mainKey?.charAt(0).toUpperCase() + currentItem?.mainKey?.slice(1)}`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {localContent && (
              <div className="d-flex flex-column mt-2">
                {localContent.map((subItem, subIndex) =>
                  Object.entries(subItem).map(([key, value], innerIndex) =>
                    key !== 'subKey' ? (
                      <div key={`${subIndex}-${innerIndex}`} className="form-check mb-2">
                        <label className="form-check-label" htmlFor={`sub-status-${currentItem.mainKey}-${key}`}>
                          {value.key}
                        </label>
                        <Button
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteItem(currentItem.mainKey, key);
                          }}
                        >
                          <MinusIconSquare />
                        </Button>
                      </div>
                    ) : null
                  )
                )}
                <div className="mt-2">
                  {editing && editing.mainKey === currentItem.mainKey ? (
                    <MCInput
                      type="text"
                      value={localNewLabel}
                      onChange={handleLocalLabelChange}
                      onKeyDown={handleKeyDown}
                      placeholder={t("SubStatus.addSubStatus")}
                      autoFocus
                    />
                  ) : (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddCheckbox(currentItem.mainKey);
                      }}
                      className="buttonAdd"
                    >
                      <AddIconSquare />
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {t("SubStatus.close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  });



  const customTitle = {
    label: t("SubStatus.subStatus"),
    actions: t("SubStatus.Edit"),
  };

  return (
    !isLoading && (
      <div className="configuration-general">
        <DataTable
          columns={columns}
          data={tableInfo}
          actions={actions}
          customTitle={customTitle}
        />

        <Row>
          <div className="d-flex justify-content-end">
            <MCButton
              label={t("general.save")}
              className="mt-20 d-inline"
              variant="primary"
              outline
              onClick={handleSave}
              disabled={!enabledSave}
            />
          </div>
        </Row>
      </div>
    )
  );
};