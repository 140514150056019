import { backgroundColorData, borderColorData } from "./chartColors";

export const buildData = ({chartData, isPercentage, type, title}) => {
    /**
    * deconstruir para obtener labels y data
    * esa informacion viene de la llave data
    * de chartData
    * Esta es la structure de data:
    * {
    *     "data": [
    *        {
    *           "data": [],
    *          "label": "Expirados"
    *       },
    *      {
    *         "data": [],
    *        "label": "Por Abiertos"
    *    }
    * ], ...
    * }
    */
    const { data } = chartData;

    const dataLabels = data.map(item => `${item.label}`);
    const dataValues = data.map(item => item.data[0].length);
    var maxValue = 100;

    if (!isPercentage) {
        // Calculate max value
        const maxValuetmp = Math.max(...dataValues);
        // Calculate max value plus 0.1% of max value this for margin top
        maxValue = Math.ceil(maxValuetmp + (maxValue * 0.001));
    }
    else {
        // Sum all values
        const sumValues = dataValues.reduce((a, b) => a + b, 0);
        // Calculate percentage
        dataValues.forEach((item, index) => {
            dataValues[index] = ((item * 100) / sumValues).toFixed(2);
        });
        // Change labels to percentage
        dataLabels.forEach((item, index) => {
            const name = item.split(" (")[0]
            dataLabels[index] = `${name}`;
        });
    }

    var datasets = [];
    var labels = [];

    if (type === "bar") {
        labels = [title];
        datasets = dataLabels.map((item, index) => {
            return {
                label: item,
                data: [dataValues[index]],
                backgroundColor: [backgroundColorData[index]],
                borderColor: [borderColorData[index]],
                borderWidth: 1,
            }
        });
    }
    else {
        labels = dataLabels;
        datasets = [{
            label: dataLabels,
            data: dataValues,
            backgroundColor: backgroundColorData,
            borderColor: borderColorData,
            borderWidth: 1,
        }];
    }

    const response = {
        data: {
            labels: labels,
            datasets: datasets,
        },
        maxValue: maxValue
    }

    return response;
};