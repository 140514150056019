import React, { useRef, useState } from "react";
import { ArrowDown2, ArrowUp3, SearchIcon2 } from "../Icon";
import { useTranslation } from "react-i18next";
import "./styles.scss";
/**
 * This is a dynamic table with predefined styles, in which multiple data and/or actions can be displayed in order to facilitate the analysis and interpretation of its content. It has segmentation by pages, the choice of rows per page and the counting of the totals of the information in its content.
 * @param {Array} columns - Contains the name of the columns to render
 * @param {string} data - It is the data that is rendered in each of the rows of the table
 * @param {Module} actions - Renders necessary action tools such as delete, edit, create, etc.
 * @param {Array}  customTitle - Contains the custom name of the columns to render.
 * @returns Dynamic table
 */

export const DataTable = ({ columns, data, actions, customTitle }) => {
  const [search, setSearch] = useState(""); // Input search value
  const [sortColumn, setSortColumn] = useState(null); // Column sorter
  const [sortedABC, setSortedABC] = useState(true); // Column elements to sort
  const [currentPage, setCurrentPage] = useState(1); // Current page in paginator
  const [rowsPerPage, setRowsPerPage] = useState(10); // Select row per page
  const [pageLimit, setPageLimit] = useState(5); // Limit page per section
  const [maxPageLimit, setMaxPageLimit] = useState(5); // Dynamic limit page per section
  const [minPageLimit, setMinPageLimit] = useState(0); // Dynamic limit page per section
  const [openSelect, setOpenSelect] = useState(false); // Open/close menu selec row per page
  //custom hooks
  const [t] = useTranslation("components");

  // Alphabetical column sorted
  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortedABC(!sortedABC);
    } else {
      setSortColumn(column);
      setSortedABC(true);
    }
  };
  // Menu rows per page options
  const rowPerPageOptions = [10, 15, 20];
  // Input ref to show rows per page
  const leagueInput = useRef();
  // Selecting row per page value
  const selectRow = (e) => {
    leagueInput.current.value = e.target.outerText;
    setRowsPerPage(leagueInput.current.value);
    setOpenSelect(false);
  };
  // open/close menu rows per page
  const openOptions = () => {
    setOpenSelect((show) => !show);
  };
  // Input search & filter data
  const filterData = () => {
    let filteredData = [...data];
    filteredData = filteredData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
    //Sort filtered data
    if (sortColumn) {
      filteredData.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA < valueB) {
          return sortedABC ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortedABC ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  };
  // Based on the total data obtained, it is calculated to segment the results sections into pages.
  let fullData = filterData();
  // Count is used to calculate the total data
  const count = fullData;


  // Determination of the total number of pages to segment the information.
  const pages = [];
  const totalPages = Math.ceil(fullData.length / rowsPerPage);
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  // Render necessary pages
  const renderPages = pages.map((page) => {
    if (page < maxPageLimit && page >= minPageLimit) {
      return (
        <button
          key={page}
          id={page}
          onClick={() => setCurrentPage(Number(page))}
          className={`btnPage ${currentPage === page ? "--current" : ""}`}
          disabled={currentPage === page ? true : false}
        >
          {page}
        </button>
      );
    }
  });

  // Defines the range of data to display
  const start = (currentPage - 1) * rowsPerPage;
  const end = Number(start) + Number(rowsPerPage);
  fullData = fullData.slice(start, end);
  // Navigate to the content of the previous page
  const prevPag = () => {
    setCurrentPage(currentPage - 1);
    if (currentPage - 1 < minPageLimit) {
      setMaxPageLimit(Number(maxPageLimit) - pageLimit);
      setMinPageLimit(minPageLimit - pageLimit);
    }
  };
  // Navigate to the content of the next page
  const nextPag = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 >= maxPageLimit) {
      setMaxPageLimit(Number(maxPageLimit) + pageLimit);
      setMinPageLimit(minPageLimit + pageLimit);
    }
  };
  // Navigate to the content of the previous section pages
  const prevSection = () => {
    setMaxPageLimit(maxPageLimit - pageLimit);
    setMinPageLimit(minPageLimit - pageLimit);
    setCurrentPage(maxPageLimit - pageLimit - 1);
  };
  // Navigate to the content of the next section pages
  const nextSection = () => {
    setMaxPageLimit(maxPageLimit + pageLimit);
    setMinPageLimit(minPageLimit + pageLimit);
    setCurrentPage(minPageLimit + pageLimit);
  };
  // Navigate to the content of the last page
  const lastPage = () => {
    setMaxPageLimit(pages.length + 1);
    setMinPageLimit(pages.length - pageLimit);
    setCurrentPage(pages.length);
  };
  // Navigate to the content of the first page
  const firstPage = () => {
    setMaxPageLimit(pageLimit);
    setMinPageLimit(0);
    setCurrentPage(1);
  };

  return (
    <div className="dataTable dyBorder1 rounded p-20 mt-10">
      <div className="searcher">
        <input
          placeholder={`${t("DataTable.search")}`}
          type="search"
          value={search}
          className="searcher__input form-control"
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
        />
        <SearchIcon2 className="searcher__icon dyIcon2" />
      </div>

      <div className="dataTable__container ">
        <table className="dataTable__table">
          <thead className="dataTable__header">
            <tr>
              {columns.map((title, index) => (
                <th
                  key={index}
                  onClick={() => handleSortColumn(title)}
                  className={`title ${
                    title === sortColumn ? "--sortedColumn" : ""
                  }`}
                >
                  <h5 className="mx-auto">
                    {customTitle[title] || title}{" "}
                    {title === sortColumn &&
                      (sortedABC ? (
                        <ArrowUp3 className="dyIcon2 --scale" />
                      ) : (
                        <ArrowDown2 className="dyIcon2 --scale" />
                      ))}
                  </h5>
                </th>
              ))}
            </tr>
          </thead>
          {/* Table Body & Data */}
          <tbody className="dataTable__body">
            {fullData.map((row, index) => (
              <tr key={index}>
                {columns.map((column, columnIndx) => (
                  <td key={columnIndx}>
                    {column === "actions" ? (
                      <div>{actions(row)}</div>
                      ) : (
                        <span>{row[column]}</span>
                        )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* footer & pagination */}
        {fullData.length !== 0 ? (
          <div className="pagination">
            <div className="pagination__rowsPerPage">
              <small className="pagination__info">
                {`${t("DataTable.rows_per_page")}`}: &nbsp;
              </small>
              <div className="selectRow">
                <input
                  value={rowsPerPage}
                  onClick={openOptions}
                  onBlur={() => {
                    setOpenSelect(false);
                  }}
                  ref={leagueInput}
                  id="league"
                  type="number"
                  readOnly
                  />
                <span className={openSelect ? "icon active" : "icon"}>
                  <ArrowDown2 />
                </span>
                <div className={openSelect ? "options active" : "options"}>
                  {rowPerPageOptions.map((rows, i) => (
                    <li onClick={selectRow} key={i}>
                      {rows}
                    </li>
                  ))}
                </div>
              </div>
            </div>

            <div className="pagination__pages">
              {minPageLimit >= 1 ? (
                <div>
                  <button className="btnPage" onClick={firstPage}>
                    1
                  </button>
                  <span>...</span>
                  <button className="btnPageAction" onClick={prevSection}>
                    <p>&#8249;&#8249;</p>
                  </button>
                </div>
              ) : (
                <></>
                )}

              <button
                className="btnPageAction"
                onClick={prevPag}
                disabled={currentPage === 1}
                >
                <p>&#8249;</p>
              </button>

              {renderPages}

              <button
                className="btnPageAction"
                onClick={nextPag}
                disabled={currentPage === totalPages}
                >
                <p>&#8250;</p>
              </button>

              {pages.length > maxPageLimit ? (
                <div>
                  <button className="btnPageAction" onClick={nextSection}>
                    <p>&#8250;&#8250;</p>
                  </button>
                  <span>...</span>
                  <button className="btnPage" onClick={lastPage}>
                    {pages.length}
                  </button>
                </div>
              ) : (
                <></>
                )}
            </div>

            <div>
              <small className="pagination__info">{`${t(
                "DataTable.results"
                )}: ${Number(start + 1)} al ${start + fullData.length} de ${
                  count.length
                }`}</small>
            </div>
          </div>
        ) : (
          <div className="pagination__noData">
            <small>{`${t("DataTable.no_records_show")}`}...</small>
          </div>
        )}
      </div>
    </div>
  );
};
