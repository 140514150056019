import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import { CDBBtn, CDBModal, CDBInput } from "cdbreact";
import SimpleSelect from "../SimpleSelect";
import { BoldCloseCircle, Settings3 } from "../Icon";
import { useTranslation } from "react-i18next";

const Config = ({ theme }) => {
  const [t] = useTranslation("users");
  const DynamicTheme = {
    backgroundColor: theme === "light" ? "#fff" : "#1a1d27",
  };
  const toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    setState({
      ...state,
      [modalNumber]: !state[modalNumber],
    });
  };
  const [state, setState] = useState({
    modal1: false,
  });
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [date3, setDate3] = useState(new Date());
  const [date4, setDate4] = useState(new Date());
  const [date5, setDate5] = useState(new Date());
  const [general_option] = useState([
    {
      text: "Option 1",
      value: "1",
    },
    {
      text: "Option 2",
      value: "2",
    },
    {
      text: "Option 3",
      value: "3",
    },
  ]);
  return (
    <>
      <small id="filter-svg" onClick={toggle(2)}>
        {t("filter.filters")} <Settings3 />{" "}
      </small>
      <CDBModal
        isOpen={state.modal2}
        toggle={toggle(2)}
        size="lg"
        className="text-left p-20"
      >
        <div
          className="d-flex justify-content-between align-items-center pt-4 px-4 mb-20"
          style={DynamicTheme}
        >
          <h5 className="mb-0">{t("filter.user_list_configuration")}</h5>
          <BoldCloseCircle onClick={toggle(2)} />
        </div>
        <div className="border p-20 mx-4" style={DynamicTheme}>
          <Row>
            <Col md="6">
              <SimpleSelect
                label={`${t("filter.select_data_flow")}:`}
                options={general_option}
              />
            </Col>
            <Col md="6">
              <CDBBtn className="athics-outline-btn w-100 mt-4" outline>
                {t("filter.create_new_data_flow")}
              </CDBBtn>
            </Col>
          </Row>
        </div>
        <div className="border p-20 mt-20 mx-4" style={DynamicTheme}>
          <Row>
            <Col md="6">
              <SimpleSelect label="Email:" options={general_option} />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <SimpleSelect
                label={`${t("filter.name")}:`}
                options={general_option}
              />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <SimpleSelect
                label={`${t("filter.lastname")}:`}
                options={general_option}
              />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <SimpleSelect
                label={`${t("filter.number_income")}:`}
                options={general_option}
              />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
        </div>
        <div className="border p-20 mt-20 mx-4" style={DynamicTheme}>
          <Row>
            <Col md="6">
              <small className="mb-2">{`${t("filter.current_entry")}:`}</small>
              <DatePicker
                selected={date1}
                onChange={(date) => setDate1(date)}
              />
            </Col>
            <Col md="6">
              <DatePicker
                selected={date2}
                onChange={(date) => setDate2(date)}
                className="mt-4"
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <small className="mb-2">{`${t("filter.creation_date")}:`}</small>
              <DatePicker
                selected={date3}
                onChange={(date) => setDate3(date)}
              />
            </Col>
            <Col md="6">
              <small className="mb-2">{`${t("filter.update_date")}:`}</small>
              <DatePicker
                selected={date4}
                onChange={(date) => setDate4(date)}
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <SimpleSelect
                label={t("filter.lastname")}
                options={general_option}
              />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col md="6">
              <SimpleSelect
                label={`${t("filter.number_income")}:`}
                options={general_option}
              />
            </Col>
            <Col md="6" className="mt-4">
              <CDBInput className="w-100" size="sm" hint="placeholder" />
            </Col>
          </Row>
        </div>
        <div className="border p-20 my-20 mx-4" style={DynamicTheme}>
          <Row>
            <Col md="6">
              <small className="mb-2">{`${t("filter.active")}:`}</small>
              <DatePicker
                selected={date5}
                onChange={(date) => setDate5(date)}
              />
            </Col>
          </Row>
        </div>
        <div className="p-20 text-left" style={DynamicTheme}>
          <div className="text-right">
            <CDBBtn
              className="athics-outline-btn my-20 w-200 mr-3"
              outline
              onClick={toggle(2)}
            >
              {t("filter.remove_filter")}
            </CDBBtn>
            <CDBBtn className="athics-btn my-20 w-200">
              {t("filter.apply_filter")}
            </CDBBtn>
          </div>
        </div>
      </CDBModal>
    </>
  );
};
export default Config;
