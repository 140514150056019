import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MCButton,
  MCInput,
  MCLabel,
  MCSelect,
  MCTooltip,
} from "../../../MainComponents";
import { Col, Row } from "react-bootstrap";
import { TrashCan } from "../../../Icon";
import Icon from "../../../Icon/Icon";
import { MCSelectMulti } from "../../../MainComponents/SelectMulti/MCSelectMulti";
import { Loading } from "../../../Loading/String/Loading";

export const RemunerationsCompensations = ({
  resolution,
  setActiveTab,
  openModal,
  handleTabChange,
  reportDetail
}) => {
  const [t] = useTranslation("report");
  const {
    beneficiaries,
    editResolution,
    setEditResolution,
    addMoreBeneficiaries,
    resolutionsUpdated,
    getAvailableVictims,
    hdlSelectResolutionChange,
    victims,
    benefitOptions,
    hdlAnoterOptionChange,
    isValid,
    hdlDetailsChange,
    hdlAddResolution,
    sendResolutionData,
    isFormValid,
    loading,
  } = resolution;
  // Value to label
  const benefitsLabel = (values) => {
    // if  value = "another" shows "another_sanction" value
    return values
      .filter((value) => value !== "another")
      .map((value) => {
        const benefit = benefitOptions.find((option) => option.value === value);
        return benefit ? benefit.label : value;
      });
  };

  // Victims type translate
  const beneficiariesType = {
    witness: t("Resolution.witness"),
    victim: t("Resolution.victim"),
  };


  const [enableReadMode, setEnableReadMode] = useState(false);

  useEffect(() => {
    if (reportDetail != "finish")
      setEnableReadMode(true);

  }, [])

  return (
    enableReadMode === false ? (
      <div className="dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.remunerations_compesation")}</h4>
          {beneficiaries.length >= 1 && (
            <>
              {!editResolution ? (
                <MCButton
                  label={t("Resolution.edit")}
                  variant="light"
                  className="resolution__btnAction"
                  onClick={() => setEditResolution(true)}
                  icon="outline_edit"
                />
              ) : (
                <MCButton
                  label={t("Resolution.cancel")}
                  variant="light"
                  className="resolutions__btnAction"
                  onClick={() => {
                    handleTabChange(4);
                  }}
                  icon="collapse"
                />
              )}
            </>
          )}
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            beneficiaries.length === 0 ? (
              !addMoreBeneficiaries ? (
                <h5>
                  {t(
                    "Resolution.no_victims_or_witnesses_have_been_registered_yet"
                  )}
                </h5>
              ) : (
                <h5>
                  {t(
                    "Resolution.no_remuneration_compensation_has_yet_been_assigned"
                  )}
                </h5>
              )
            ) : (
              resolutionsUpdated?.beneficiaries?.map(
                (beneficiariesElement, i) => (
                  <Col key={i} sm={12} xxl={6} className="p-1">
                    {beneficiariesElement.length === 0 ? (
                      <h5>
                        {t(
                          "Resolution.no_remuneration_compensation_has_yet_been_assigned"
                        )}
                      </h5>
                    ) : (
                      <div className="resolutions__compensation rounded">
                        <MCLabel
                          text={`${t("Resolution.beneficiary_information")}:`}
                        />
                        <Row className="resolutions__beneficiaryInfo my-2 rounded">
                          <Col xs={12} className="benefitGeneral">
                            <h6 className="text-center py-1">
                              {beneficiariesElement.name}
                            </h6>
                          </Col>
                          <hr className="my-0" />
                          <Col xs={12} className="benefitGeneral my-1">
                            <small>
                              {
                                beneficiariesType[
                                beneficiariesElement.kind ||
                                beneficiariesElement.rol
                                ]
                              }
                            </small>
                            <small>
                              <Icon
                                name={`${beneficiariesElement.confirm
                                    ? "check_circle"
                                    : "info_circle"
                                  }`}
                                width={17}
                                className={`${beneficiariesElement.confirm
                                    ? "--confirm"
                                    : "--noConfirm"
                                  }`}
                              />
                              {`${beneficiariesElement.confirm
                                  ? t("Resolution.confirmed")
                                  : t("Resolution.unconfirmed")
                                }`}
                            </small>
                          </Col>
                          <hr className="my-1" />
                          <Col>
                            <h6>{`${t("Resolution.employee_number")}:`}</h6>
                            <small>{beneficiariesElement.additional}</small>
                          </Col>
                          <Col>
                            <h6>{`${t("Resolution.area")}:`}</h6>
                            <small>{beneficiariesElement.details}</small>
                          </Col>
                          <Col>
                            <h6>{`${t("Resolution.job_position")}:`}</h6>
                            <small>{beneficiariesElement.relationship}</small>
                          </Col>
                          <hr className="my-1" />
                          <Col>
                            <h6>
                              {`${beneficiariesElement.benefits.length > 1
                                  ? t("Resolution.benefits_granted")
                                  : t("Resolution.benefit_granted")
                                }:`}
                            </h6>
                            <ul className="mx-3">
                              {benefitsLabel(beneficiariesElement.benefits).map(
                                (label, i) => (
                                  <li key={i}>{label}</li>
                                )
                              )}
                              {beneficiariesElement.another_benefit !==
                                undefined && (
                                  <li key={"other_option"}>
                                    {beneficiariesElement.another_benefit}
                                  </li>
                                )}
                            </ul>
                          </Col>
                          <hr />
                          <Col>
                            <h6>{`${t("Resolution.details")}:`}</h6>
                            <small className="px-2 mb-3">
                              {beneficiariesElement.comment ||
                                t("Resolution.no_comments")}
                            </small>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                )
              )
            )
          ) : beneficiaries.length === 0 ? (
            <h5>
              {t("Resolution.all_remuneration_compensation_have_been_removed")}
            </h5>
          ) : (
            beneficiaries.map((_, i) => (
              <Col key={i} sm={12} xxl={6} className="p-1">
                <div className="resolutions__compensation rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "benefits")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.beneficiary")}:`}
                    className="my-1 mx-2"
                  />
                  <MCSelect
                    isValid={beneficiaries[i].name !== ""}
                    options={getAvailableVictims(i)}
                    optionLabel="name"
                    onChange={(victimSelected) =>
                      hdlSelectResolutionChange(
                        i,
                        victims.find(
                          (victim) => victim.name === victimSelected.name
                        ),
                        "beneficiaries"
                      )
                    }
                    value={victims.find(
                      (victim) => victim.name === beneficiaries[i].name
                    )}
                  />
                  {beneficiaries[i].name !== "" && (
                    <>
                      <Row className="resolutions__beneficiaryInfo my-2 rounded">
                        <Col xs={12} className="benefitGeneral my-1">
                          <small>
                            {
                              beneficiariesType[
                              beneficiaries[i].kind || beneficiaries[i].rol
                              ]
                            }
                          </small>
                          <small>
                            <Icon
                              name={`${beneficiaries[i].confirm
                                  ? "check_circle"
                                  : " info_circle"
                                }`}
                              width={17}
                              className={`${beneficiaries[i].confirm
                                  ? "--confirm"
                                  : "--noConfirm"
                                } my-1`}
                            />
                            {`${beneficiaries[i].confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="mb-2" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{beneficiaries[i].additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{beneficiaries[i].details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{beneficiaries[i].relationship}</small>
                        </Col>
                      </Row>
                      <hr />
                      <MCSelectMulti
                        label={`${t("Resolution.assignment_benefits")}:`}
                        options={benefitOptions}
                        selectedValues={beneficiaries[i].benefits}
                        setSelectedValues={(selectedBenefits) =>
                          hdlSelectResolutionChange(
                            i,
                            selectedBenefits,
                            "benefits"
                          )
                        }
                        isValid={beneficiaries[i].benefits.length !== 0}
                        helptip={t("Resolution.select_one_more_options")}
                      />
                      {beneficiaries[i].benefits.includes("another") && (
                        <MCInput
                          className="my-2"
                          type="textarea"
                          placeholder={`${t(
                            "Resolution.specify_another_benefit_type"
                          )}*`}
                          value={beneficiaries[i]?.another_benefit}
                          onChange={(e) =>
                            hdlAnoterOptionChange(i, e, "benefits")
                          }
                          isValid={isValid(i, "benefits")}
                        />
                      )}
                      {beneficiaries[i].benefits.length > 0 && (
                        <>
                          <hr />
                          <MCLabel
                            text={`${t("Resolution.details")}:`}
                            className="mx-2 my-0"
                          />
                          <MCInput
                            className="my-1"
                            type="textarea"
                            rows={3}
                            placeholder={t(
                              "Resolution.enter_details_remuneration_compensation_assigned_beneficiary"
                            )}
                            value={beneficiaries[i].comment}
                            onChange={(e) => hdlDetailsChange(i, e, "benefits")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            ))
          )}
          {/* Add/Save remunerations & compensations */}
          <Col className="resolutions__sendResolutions mt-3" sm={12}>
            {beneficiaries.length === 0 ? (
              <>
                {!addMoreBeneficiaries ? (
                  <></>
                  // <MCButton
                  //   label={t("Resolution.go_to_investigation")}
                  //   onClick={() => {
                  //     setActiveTab("Investigación");
                  //   }}
                  //   variant="light"
                  //   icon="bold_folder_add"
                  //   className="resolutions__btnAction"
                  // />
                ) : (
                  <MCButton
                    label={t("Resolution.add_new_beneficiary")}
                    onClick={() => {
                      hdlAddResolution("benefits");
                      setEditResolution(true);
                    }}
                    variant="primary"
                    icon={!addMoreBeneficiaries ? "" : "add_square"}
                    disabled={!addMoreBeneficiaries}
                    outline={!addMoreBeneficiaries}
                  />
                )}
                {editResolution && (
                  <>
                    <MCButton
                      label={t("Resolution.save")}
                      variant="success"
                      onClick={sendResolutionData}
                      icon={isFormValid("benefits") ? "" : "check_circle"}
                    />
                    <MCButton
                      label={t("Resolution.cancel")}
                      variant="light"
                      className="resolutions__btnAction"
                      onClick={() => {
                        handleTabChange(4);
                      }}
                      icon="collapse"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {addMoreBeneficiaries && (
                      <MCButton
                        label={t("Resolution.add_another_benefit")}
                        onClick={() => {
                          hdlAddResolution("benefits");
                          setEditResolution(true);
                        }}
                        variant="primary"
                        disabled={isFormValid("benefits")}
                        outline={isFormValid("benefits")}
                        icon={isFormValid("benefits") ? "" : "add_square"}
                      />
                    )}
                    {editResolution && (
                      <MCButton
                        label={t("Resolution.save")}
                        variant="success"
                        onClick={sendResolutionData}
                        disabled={isFormValid("benefits")}
                        outline={isFormValid("benefits")}
                        icon={isFormValid("benefits") ? "" : "check_circle"}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    ) : (
















      <div className="readMode dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.remunerations_compesation")}</h4>
          {beneficiaries.length >= 1 && (
            <>

            </>
          )}
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            beneficiaries.length === 0 ? (
              !addMoreBeneficiaries ? (
                <h5>
                  {t(
                    "Resolution.no_victims_or_witnesses_have_been_registered_yet"
                  )}
                </h5>
              ) : (
                <h5>
                  {t(
                    "Resolution.no_remuneration_compensation_has_yet_been_assigned"
                  )}
                </h5>
              )
            ) : (
              resolutionsUpdated?.beneficiaries?.map(
                (beneficiariesElement, i) => (
                  <Col key={i} sm={12} xxl={6} className="p-1">
                    {beneficiariesElement.length === 0 ? (
                      <h5>
                        {t(
                          "Resolution.no_remuneration_compensation_has_yet_been_assigned"
                        )}
                      </h5>
                    ) : (
                      <div className="resolutions__compensation rounded">
                        <MCLabel
                          text={`${t("Resolution.beneficiary_information")}:`}
                        />
                        <Row className="resolutions__beneficiaryInfo my-2 rounded">
                          <Col xs={12} className="benefitGeneral">
                            <h6 className="text-center py-1">
                              {beneficiariesElement.name}
                            </h6>
                          </Col>
                          <hr className="my-0" />
                          <Col xs={12} className="benefitGeneral my-1">
                            <small>
                              {
                                beneficiariesType[
                                beneficiariesElement.kind ||
                                beneficiariesElement.rol
                                ]
                              }
                            </small>
                            <small>
                              <Icon
                                name={`${beneficiariesElement.confirm
                                    ? "check_circle"
                                    : "info_circle"
                                  }`}
                                width={17}
                                className={`${beneficiariesElement.confirm
                                    ? "--confirm"
                                    : "--noConfirm"
                                  }`}
                              />
                              {`${beneficiariesElement.confirm
                                  ? t("Resolution.confirmed")
                                  : t("Resolution.unconfirmed")
                                }`}
                            </small>
                          </Col>
                          <hr className="my-1" />
                          <Col>
                            <h6>{`${t("Resolution.employee_number")}:`}</h6>
                            <small>{beneficiariesElement.additional}</small>
                          </Col>
                          <Col>
                            <h6>{`${t("Resolution.area")}:`}</h6>
                            <small>{beneficiariesElement.details}</small>
                          </Col>
                          <Col>
                            <h6>{`${t("Resolution.job_position")}:`}</h6>
                            <small>{beneficiariesElement.relationship}</small>
                          </Col>
                          <hr className="my-1" />
                          <Col>
                            <h6>
                              {`${beneficiariesElement.benefits.length > 1
                                  ? t("Resolution.benefits_granted")
                                  : t("Resolution.benefit_granted")
                                }:`}
                            </h6>
                            <ul className="mx-3">
                              {benefitsLabel(beneficiariesElement.benefits).map(
                                (label, i) => (
                                  <li key={i}>{label}</li>
                                )
                              )}
                              {beneficiariesElement.another_benefit !==
                                undefined && (
                                  <li key={"other_option"}>
                                    {beneficiariesElement.another_benefit}
                                  </li>
                                )}
                            </ul>
                          </Col>
                          <hr />
                          <Col>
                            <h6>{`${t("Resolution.details")}:`}</h6>
                            <small className="px-2 mb-3">
                              {beneficiariesElement.comment ||
                                t("Resolution.no_comments")}
                            </small>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                )
              )
            )
          ) : beneficiaries.length === 0 ? (
            <h5>
              {t("Resolution.all_remuneration_compensation_have_been_removed")}
            </h5>
          ) : (
            beneficiaries.map((_, i) => (
              <Col key={i} sm={12} xxl={6} className="p-1">
                <div className="resolutions__compensation rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "benefits")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.beneficiary")}:`}
                    className="my-1 mx-2"
                  />
                  <MCSelect
                    isValid={beneficiaries[i].name !== ""}
                    options={getAvailableVictims(i)}
                    optionLabel="name"
                    onChange={(victimSelected) =>
                      hdlSelectResolutionChange(
                        i,
                        victims.find(
                          (victim) => victim.name === victimSelected.name
                        ),
                        "beneficiaries"
                      )
                    }
                    value={victims.find(
                      (victim) => victim.name === beneficiaries[i].name
                    )}
                  />
                  {beneficiaries[i].name !== "" && (
                    <>
                      <Row className="resolutions__beneficiaryInfo my-2 rounded">
                        <Col xs={12} className="benefitGeneral my-1">
                          <small>
                            {
                              beneficiariesType[
                              beneficiaries[i].kind || beneficiaries[i].rol
                              ]
                            }
                          </small>
                          <small>
                            <Icon
                              name={`${beneficiaries[i].confirm
                                  ? "check_circle"
                                  : " info_circle"
                                }`}
                              width={17}
                              className={`${beneficiaries[i].confirm
                                  ? "--confirm"
                                  : "--noConfirm"
                                } my-1`}
                            />
                            {`${beneficiaries[i].confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="mb-2" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{beneficiaries[i].additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{beneficiaries[i].details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{beneficiaries[i].relationship}</small>
                        </Col>
                      </Row>
                      <hr />
                      <MCSelectMulti
                        label={`${t("Resolution.assignment_benefits")}:`}
                        options={benefitOptions}
                        selectedValues={beneficiaries[i].benefits}
                        setSelectedValues={(selectedBenefits) =>
                          hdlSelectResolutionChange(
                            i,
                            selectedBenefits,
                            "benefits"
                          )
                        }
                        isValid={beneficiaries[i].benefits.length !== 0}
                        helptip={t("Resolution.select_one_more_options")}
                      />
                      {beneficiaries[i].benefits.includes("another") && (
                        <MCInput
                          className="my-2"
                          type="textarea"
                          placeholder={`${t(
                            "Resolution.specify_another_benefit_type"
                          )}*`}
                          value={beneficiaries[i]?.another_benefit}
                          onChange={(e) =>
                            hdlAnoterOptionChange(i, e, "benefits")
                          }
                          isValid={isValid(i, "benefits")}
                        />
                      )}
                      {beneficiaries[i].benefits.length > 0 && (
                        <>
                          <hr />
                          <MCLabel
                            text={`${t("Resolution.details")}:`}
                            className="mx-2 my-0"
                          />
                          <MCInput
                            className="my-1"
                            type="textarea"
                            rows={3}
                            placeholder={t(
                              "Resolution.enter_details_remuneration_compensation_assigned_beneficiary"
                            )}
                            value={beneficiaries[i].comment}
                            onChange={(e) => hdlDetailsChange(i, e, "benefits")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            ))
          )}
          {/* Add/Save remunerations & compensations */}
          
        </Row>
      </div>
    )
  );
};
