import { useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { useContext } from "react";

/**
 * The usePrompt hook is designed to display a confirmation message when the user tries to navigate to another page within the React application, and there are unsaved changes. Its purpose is to prevent the user from accidentally losing unsaved data.
 * @param {*} when This is a boolean that indicates whether the warning message should be displayed. It is based on whether there are unsaved changes.
 * @param {*} message This is the message that will be displayed to the user when trying to navigate away from the current page.
 */
export const usePrompt = (when, message) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;
    // The browser push function is redefined here
    const push = navigator.push;

    navigator.push = (...args) => {
      if (window.confirm(message)) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [when, message, navigator]);
};
