import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Col, Modal, Row } from "react-bootstrap";
import { RenderInput } from "../PreviewForm/PreviewForm";
import { BuildInput } from "./BuildForm";
import Icon from "../../Icon/Icon";
import { useSelector } from "react-redux";
import { checkTypeOfConditional } from "../PreviewForm/formStarter";
import { MCButton } from "../../MainComponents";
import { useTranslation } from "react-i18next";

export const ModalConditionals = ({
  activeStep,
  inputIndex,
  entireSchema,
  entirePathSchema,
  setSchemaState,
  entireFormData,
  entirePathData,
  setFormData,
  entireIsValid,
  setIsValid,
  constraints,
  setConstraints,
  hdlAddInp,
  hdlChange,
  hdlMveInp,
  hdlDelInp,
  tryToCreate,
  nestLvl,
  show,
  onHide,
}) => {
  const [t] = useTranslation("forms");
  const { gTheme } = useSelector((state) => state.theme);

  const schema = _.get(entireSchema, entirePathSchema);
  const key = schema.type.includes("catalog") ? schema.catalogue : schema.key;

  const [activeInput, setActiveInput] = useState(0);
  const [attachments, setAttachments] = useState(null);
  const [rerender, setRerender] = useState(false);

  const entirePathDataWithKey = `${entirePathData}.${key}`;
  const value = _.get(entireFormData, entirePathDataWithKey);
  let currentConditionals = [];
  let currentConditionalsPath = ``;
  let findCase;
  let isNewConditional = false;
  if ("conditionals" in schema) {
    findCase = schema.conditionals.findIndex((item) =>
      checkTypeOfConditional(item, value)
    );
    if (findCase >= 0) {
      currentConditionalsPath = `${entirePathSchema}.conditionals.${findCase}.nestChildren`;
      currentConditionals = _.get(entireSchema, currentConditionalsPath);
    } else {
      currentConditionalsPath = `${entirePathSchema}.conditionals`;
      isNewConditional = true;
    }
  }

  const debounceRerender = useRef(
    _.debounce(() => {
      setRerender(true);
    }, 300)
  );

  useEffect(() => {
    debounceRerender.current();
  }, [entireSchema]);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const hdlActInp = (inputIndex) => {
    setActiveInput(inputIndex);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
      dialogClassName="modal-config-conditionals"
      centered
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      />
      <Modal.Body>
        <Row>
          <Col lg={12} xl={6}>
            <div>
              <div>
              <h3>{t("modalConditionals.field_value")}:</h3>
                <span>
                  {value}
                  {value === true && "true"}
                  {value === false && "false"}
                </span>
              </div>
              <div>
                <h3>{t("modalConditionals.input_display")}:</h3>
                <div className="info-container inputs">
                  {currentConditionals.map((sch, idx) => (
                    <BuildInput
                      key={idx}
                      activeInput={activeInput}
                      inputIndex={idx}
                      inputSchema={sch}
                      hdlChange={hdlChange}
                      hdlAddInp={hdlAddInp}
                      hdlActInp={hdlActInp}
                      hdlDelInp={hdlDelInp}
                      hdlMveInp={hdlMveInp}
                      tryToCreate={tryToCreate}
                      nestLvl={nestLvl}
                      entireSchema={entireSchema}
                      entirePathSchema={`${currentConditionalsPath}.${idx}`}
                      entireFormData={entireFormData}
                      entirePathData={`${entirePathData}.conditionals.${idx}`}
                      entireIsValid={entireIsValid}
                      setSchemaState={setSchemaState}
                      setFormData={setFormData}
                      setIsValid={setIsValid}
                      constraints={constraints}
                      setConstraints={setConstraints}
                      setActiveInput={setActiveInput}
                    />
                  ))}
                  <div
                    className="add-input"
                    onClick={() =>
                      hdlAddInp({
                        inputIndex: currentConditionals.length,
                        nestLvl,
                        setActiveInput,
                        setRerender,
                        entirePathSchema: `${currentConditionalsPath}`,
                        entirePathData: `${entirePathData}.conditionals`,
                        isNewConditional,
                        value,
                      })
                    }
                  >
                    <div>
                      <Icon name="add_square" />
                      <span>{t("modalConditionals.add_field")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!rerender && (
            <Col lg={12} xl={6}>
              <div>
                <h3 className="my-2">{t("modalConditionals.preview")}</h3>
              </div>
              <Row>
                <RenderInput
                  key={`form-input-${inputIndex}`}
                  entireSchema={entireSchema}
                  entireFormData={entireFormData}
                  entireIsValid={entireIsValid}
                  entirePathSchema={entirePathSchema}
                  entirePathData={entirePathData}
                  setSchemaState={setSchemaState}
                  setFormData={setFormData}
                  setIsValid={setIsValid}
                  constraints={constraints}
                  setConstraints={setConstraints}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  tryToNext={false}
                  activeStep={activeStep}
                  idx={inputIndex}
                  colsLg={12}
                />
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <div className="d-flex justify-content-end mt-4">
            <MCButton
              label={t("modalConditionals.accept")}
              variant="primary"
              onClick={onHide}
            />
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
