import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Saved from "../../../../../assets/images/saved.png";
import { MCButton } from "../../../../MainComponents";
import { patchInvestigation } from "../../../../../apis";
import { Calendar } from "../../../../Calendar/Calendar";
import {
  parseDateToLgComun,
  parseDateToISOString,
} from "../../../../../helpers/dateTransform";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useSelector } from "react-redux";

//This Modal containing the simple investigation conclusion tools.
export const ConcludeSimpInv = ({
  investigationAlias,
  objToSave,
  setRerender,
  closeModal,
}) => {
  const [t] = useTranslation("investigation");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [confirm, setConfirm] = useState(false);
  const { gTheme } = useSelector((state) => state.theme);

  const today = new Date();
  //The maximum and minimum days to schedule the conclusion are determined
  const daysAdds = 180;
  const daysMins = 1;
  const maxDate = new Date().setDate(new Date().getDate() + daysAdds);
  const minDate = new Date().setDate(new Date().getDate() - daysMins);
  //Extract the selected day from <Calendar/>
  const getSelectedDate = (date) => {
    setSelectedDate(date);
  };
  //Show info to confirm
  const handleConfirm = () => setConfirm((show) => !show);
  //Confirmed completion schedule
  const handleSubmitScheduleConfirm = async (e) => {
    e.preventDefault();
    const message = t("messages.investigation_scheduled_successfully");
    const objToSend = {
      conclusion: objToSave.conclusion,
      conclusion_at: parseDateToISOString(selectedDate),
    };
    try {
      const resp = await patchInvestigation(
        investigationAlias,
        objToSend,
        message
      );
      setRerender(true);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  //The completion of the investigation is confirmed()
  const handleSubmit = async () => {
    const message = t("messages.investigation_concluded_successfully");
    try {
      const resp = await patchInvestigation(
        investigationAlias,
        objToSave,
        message
      );
      setRerender(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal.Header
        style={{ border: "none", padding: 0 }}
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""}`}
      ></Modal.Header>
      <Modal.Body className="concludeInv__body">
        {!confirm ? (
          <>
            <div className="concludeInv__header">
              <img src={Saved} alt="logo" />
              <h4>{t("Conclusion.conclude_investigation")}</h4>
              <small>{t("Conclusion.conclude_now_or_schedule_investigation")}</small>
            </div>
            <div className="rounded my-2">
              <Calendar
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                getSelectedDate={getSelectedDate}
              />
            </div>
            <div className="concludeInv__actionBtns mb-3 mt-1">
              <MCButton
                label={t("Conclusion.schedule")}
                variant={selectedDate < today ? "secondary" : "primary"}
                className="actionBtn"
                onClick={handleConfirm}
                disabled={selectedDate < today}
              />
            </div>
            <fieldset className="dyTheme4 dyBorder1 rounded my-1 description">
              <p className="my-2 text-center descriptionText">
                {t("Conclusion.use_calendar_to_schedule_conclusion")}
              </p>
            </fieldset>
            <div className="concludeInv__actionBtns mt-3">
              <MCButton
                label={t("Conclusion.cancel")}
                variant="primary"
                className="actionBtn"
                onClick={closeModal}
                outline
              />
              <MCButton
                label={t("Conclusion.confirm")}
                variant={today < selectedDate ? "secondary" : "primary"}
                className="actionBtn"
                onClick={handleSubmit}
                disabled={today < selectedDate}
              />
            </div>
          </>
        ) : (
          <div className="concludeInv__confirm">
            <small>{t("Conclusion.investigation_scheduled_conclude")}:</small>
            <b>{parseDateToLgComun(selectedDate, t)}</b>

            <div className="concludeInv__actionBtns my-4">
              <MCButton
                label={t("Conclusion.cancel")}
                variant="primary"
                className="actionBtn"
                onClick={handleConfirm}
                outline
              />
              <MCButton
                label={t("Conclusion.confirm")}
                variant={"primary"}
                className="actionBtn"
                onClick={handleSubmitScheduleConfirm}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </div>
  );
};
