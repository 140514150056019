import { useEffect, useRef, useState } from "react";
import Icon from "../Icon/Icon";
import { MCInput } from "../MainComponents/Input/Input";
import _ from "lodash";
import { createCatalogues } from "../../apis";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MCLabel } from "../MainComponents/Label/Label";
import { MCButton } from "../MainComponents/Button/Button";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { components } from "react-select";
import { MCSelect } from "../MainComponents";
import { Loading } from "../Loading/String/Loading";
import { OutlineBoxAdd } from "../Icon";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";

const Option = ({ ...props }) => {
  const [t] = useTranslation("catalogues");
  const description = props.data.description ? props.data.description : null;
  const examples = props.data.examples ? props.data.examples : null;

  return (
    <components.Option {...props}>
      <div>
        <div>{props.data.label}</div>
        {description && typeof description === "string" && <p>{description}</p>}
        {examples && Array.isArray(examples) && (
          <>
            <small style={{ fontSize: 10, color: "#ffaf00" }}>
              {t("Catalogues.example_displayed_modal")}
            </small>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              {examples.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </components.Option>
  );
};

const RecursiveChild = ({ options = [], containerName }) => {
  const [selection, setSelection] = useState(null);
  const [parsedOptions, setParsedOptions] = useState([]);
  const [rerenderChild, setRerenderChild] = useState(false);

  useEffect(() => {
    if (Array.isArray(options)) {
      const newOptions = options.map((item, idx) => ({
        ...item,
        value: idx,
      }));
      if (options.length === 1) {
        setSelection(newOptions[0]);
      }
      setParsedOptions(newOptions);
    }
  }, []);

  useEffect(() => {
    setRerenderChild(true);
  }, [selection]);

  useEffect(() => {
    if (rerenderChild) {
      setRerenderChild(false);
    }
  }, [rerenderChild]);

  return (
    <>
      <div className="catalogue-select p-2">
        {containerName && <MCLabel text={containerName} />}
        <MCSelect
          options={parsedOptions}
          value={selection}
          onChange={setSelection}
          components={{ Option }}
        />
      </div>
      {Array.isArray(selection?.type) && !rerenderChild && (
        <RecursiveChild
          options={selection.type}
          containerName={selection.container_name || null}
          pathErr=""
        />
      )}
    </>
  );
};

const PreviewComponent = ({ catalogue, catalogueDebounced }) => {
  const [t] = useTranslation("catalogues");
  const { gTheme } = useSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [catalogue]);

  useEffect(() => {
    setIsLoading(false);
  }, [catalogueDebounced]);

  return (
    <div
      className={`modal-preview-create-catalogue ${
        gTheme !== "light" ? "dark-mode" : ""
      }`}
    >
      <h3>{t("Catalogues.catalog_preview")}</h3>
      {isLoading ? (
        <div className="mt-3">
          <Loading />
        </div>
      ) : (
        <div>
          <MCLabel text={catalogue?.label} className="mb-1" />
          <RecursiveChild options={catalogue?.type} />
        </div>
      )}
    </div>
  );
};

const ModalCatalogueProps = ({
  scope,
  toChange = "description",
  hdlCatalogueChange,
  path,
  onHide,
}) => {
  const [t] = useTranslation("catalogues");
  const { gTheme } = useSelector((state) => state.theme);
  const [state, setState] = useState("");
  const [exampleToAdd, setExampleToAdd] = useState("");

  useEffect(() => {
    const currValue = _.get(scope, `${toChange}`);
    if (!currValue) {
      if (toChange === "examples") {
        setState([]);
      } else {
        setState("");
      }
    } else {
      setState(currValue);
    }
  }, []);

  const handleAddExample = () => {
    if (exampleToAdd.trim() === "") return;
    setState([...state, exampleToAdd]);
    setExampleToAdd("");
  };

  const handleDeleteExample = (idx) => {
    const newExamples = state.filter((item, idx2) => idx !== idx2);
    setExampleToAdd("");
    setState(newExamples);
  };

  const handleSave = () => {
    hdlCatalogueChange({ toChange, path, value: state });
    onHide();
  };

  return (
    <Modal
      show={true}
      onHide={onHide}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""}`}
      >
        <h3>{t("Catalogues.edit")}</h3>
      </Modal.Header>
      <Modal.Body>
        {toChange === "description" && (
          <div>
            <MCLabel text={`${t("Catalogues.description")}:`} />
            <MCInput
              name="description"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <MCButton
              label={t("Catalogues.save")}
              variant="primary"
              outline
              className="mt-2 w-100"
              onClick={handleSave}
            />
          </div>
        )}
        {toChange === "container_name" && (
          <div>
            <MCLabel text={`${t("Catalogues.container_name")}:`} />
            <MCInput
              name="conteiner_name"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <MCButton
              label={t("Catalogues.save")}
              variant="primary"
              outline
              className="mt-2 w-100"
              onClick={handleSave}
            />
          </div>
        )}
        {toChange === "examples" && (
          <div>
            <MCLabel text={`${t("Catalogues.examples")}:`} />
            <ul className="m-0">
              {Array.isArray(state) &&
                state.map((item, idx) => (
                  <li key={idx}>
                    {item}
                    <button onClick={() => handleDeleteExample(idx)}>-</button>
                  </li>
                ))}
            </ul>
            <div className="d-flex align-items-center">
              <MCInput
                type="text"
                className="mt-1"
                value={exampleToAdd}
                onChange={(e) => setExampleToAdd(e.target.value)}
              />
              <MCTooltip position="bottom" text={t("Catalogues.add_examples")}>
                <div className="me-2">
                  <MCButton
                    label=""
                    icon="add_icon"
                    variant="primary"
                    size="sm"
                    className="add-icon ms-1 p-0"
                    width={28}
                    outline
                    onClick={handleAddExample}
                  />
                </div>
              </MCTooltip>
            </div>
            <MCButton
              label={t("Catalogues.save")}
              variant="primary"
              outline
              className="mt-2 w-100"
              onClick={handleSave}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const Child = ({
  nestNum,
  allowAddChild,
  setShowChildren,
  showChildren,
  scope,
  hdlCatalogueChange,
  hdlRemoveChild,
  path,
  hdlAddChild,
  lastChild,
  tryToSend,
  entireCatalogue,
}) => {
  const [t] = useTranslation("catalogues");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfo, setModalInfo] = useState("description");

  const handleChange = (e) => {
    if (e.target.name === "label") {
      hdlCatalogueChange({ toChange: "label", path, value: e.target.value });
    }
  };

  const handleDropdownClick = (info) => {
    setShowModalInfo(true);
    setModalInfo(info);
  };

  const returnValid = () => {
    if (!tryToSend) return true;
    let brosPath = path.split(".");
    brosPath.pop();
    brosPath = brosPath.join(".");
    const brothers = _.get(entireCatalogue, brosPath);

    const theresRepeated = [];
    let label = scope.label;
    label = label.replace(/_/g, " ");
    label = label.trim().replace(/\s+/g, "_");
    label = label.toLowerCase();

    for (let i = 0; i < brothers.length; i++) {
      let labelCheck = brothers[i].label;
      labelCheck = labelCheck.replace(/_/g, " ");
      labelCheck = labelCheck.trim().replace(/\s+/g, "_");
      labelCheck = labelCheck.toLowerCase();
      if (label === labelCheck) {
        theresRepeated.push(true);
      }
    }

    if (theresRepeated.length > 1) {
      return false;
    }
    return true;
  };

  return (
    <div className={`${nestNum > 1 ? "doted-rline" : ""}`}>
      {showModalInfo && (
        <ModalCatalogueProps
          scope={scope}
          path={path}
          toChange={modalInfo}
          hdlCatalogueChange={hdlCatalogueChange}
          onHide={() => setShowModalInfo(false)}
        />
      )}

      <div
        className={`child ${
          nestNum === 1
            ? "first"
            : nestNum === 2
            ? "second"
            : nestNum === 3
            ? "third"
            : "fourth"
        } dyBorder1 rounded`}
      >
        {nestNum > 1 && <div className="color-lbar"></div>}
        <div className="w-100 d-flex align-items-center p-2">
          {allowAddChild && !lastChild && (
            <div onClick={() => setShowChildren(!showChildren)}>
              <Icon
                name="arrow_down_2"
                className={`mr-1 arrow-colapse ${
                  !showChildren ? "collapsed" : ""
                }`}
              />
            </div>
          )}
          {/* <pre>{JSON.stringify({ errors, thisPathErrors },null,2)}</pre> */}
          <div className="w-100 d-flex justify-content-between">
            <MCInput
              className="mt-0"
              type="text"
              name="label"
              value={scope.label}
              onChange={handleChange}
              isValid={returnValid()}
              onFocus={returnValid}
              autoComplete="off"
            />
            <div className="catalogue-icons">
              <div
                className="catalogue-icon"
                onClick={() => hdlRemoveChild({ path })}
              >
                <Icon name="trash_icon" />
              </div>
              {allowAddChild && (
                <div
                  className="catalogue-icon"
                  onClick={() => hdlAddChild({ path })}
                >
                  <Icon name="add_square" />
                </div>
              )}
              <div className="catalogue-icon ps-0">
                <Dropdown>
                  <Dropdown.Toggle variant="">
                    <Icon name="more_dots" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleDropdownClick("description")}
                    >
                      <span className="ms-2">
                        {t("Catalogues.description")}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDropdownClick("container_name")}
                    >
                      <span className="ms-2">
                        {t("Catalogues.container_name")}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDropdownClick("examples")}
                    >
                      <span className="ms-2">{t("Catalogues.examples")}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecursiveCatalog = ({
  scope,
  path = "",
  hdlCatalogueChange,
  hdlAddChild,
  hdlRemoveChild,
  nestNum = 0,
  tryToSend,
  entireCatalogue,
}) => {
  const [allowAddChild, setAllowAddChild] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  // let outputLabel = scope.label?.replace(/_/g, ' ');
  // outputLabel = outputLabel.trim().replace(/\s+/g, '_');
  // outputLabel = outputLabel.toLowerCase();
  // const thisPathErrors = `${pathErrors?(pathErrors+"::"+outputLabel):outputLabel}`

  useEffect(() => {
    if (nestNum <= 3) {
      setAllowAddChild(true);
    }
  }, []);

  const returnStyle = (nestNum) => {
    return {
      ...(nestNum === 0 && { padding: 10, paddingTop: 0 }),
      ...(nestNum === 1 && {
        marginBottom: 10,
        // border: "1px solid #D8DFE3",
        borderRadius: 4,
        marginRight: 10,
      }),
      ...(nestNum > 1 && {
        padding: 10,
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: nestNum * 10,
      }),
    };
  };

  return (
    <>
      <div
        className={`${nestNum === 1 ? "pr-3" : ""}`}
        style={returnStyle(nestNum)}
      >
        {nestNum !== 0 && (
          <Child
            nestNum={nestNum}
            allowAddChild={allowAddChild}
            setShowChildren={setShowChildren}
            showChildren={showChildren}
            scope={scope}
            hdlCatalogueChange={hdlCatalogueChange}
            hdlRemoveChild={hdlRemoveChild}
            path={path}
            hdlAddChild={hdlAddChild}
            tryToSend={tryToSend}
            lastChild={scope.type ? false : true}
            entireCatalogue={entireCatalogue}
          />
        )}
      </div>
      {showChildren &&
        Array.isArray(scope.type) &&
        scope.type.map((item, idx) => (
          <RecursiveCatalog
            key={idx}
            scope={item}
            path={path ? `${path}.type.${idx}` : `type.${idx}`}
            hdlCatalogueChange={hdlCatalogueChange}
            hdlAddChild={hdlAddChild}
            hdlRemoveChild={hdlRemoveChild}
            nestNum={nestNum + 1}
            entireCatalogue={entireCatalogue}
            tryToSend={tryToSend}
          />
        ))}
    </>
  );
};

export const CreateCatalogue = () => {
  const [t] = useTranslation("catalogues");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [catalogue, setCatalogue] = useState({
    label: t("Catalogues.catalog_name"),
  });
  const [debouncedCatalogue, setDebouncedCatalogue] = useState({
    label: t("Catalogues.catalog_name"),
  });
  const [tryToSend, setTryToSend] = useState(false);

  const breadcrumbItems = [
    { label: t("Catalogues.home"), route: "/" },
    { label: t("Catalogues.settings"), route: "/settings" },
    { label: t("Catalogues.create_catalog") },
  ];

  const hdlCatalogueName = (e) => {
    const newCatalogue = _.cloneDeep(catalogue);
    _.set(newCatalogue, `label`, e.target.value);
    setCatalogue(newCatalogue);
  };

  const hdlCatalogueChange = ({ toChange, path, value }) => {
    let newCatalogue = _.cloneDeep(catalogue);
    if (toChange === "label") {
      _.set(newCatalogue, `${path ? `${path}.` : ``}label`, value);
      setCatalogue(newCatalogue);
    }
    if (toChange === "description") {
      if (value.trim() === "") {
        _.unset(newCatalogue, `${path ? `${path}.` : ``}description`);
      } else {
        _.set(newCatalogue, `${path ? `${path}.` : ``}description`, value);
      }
      setCatalogue(newCatalogue);
    }
    if (toChange === "container_name") {
      if (value.trim() === "") {
        _.unset(newCatalogue, `${path ? `${path}.` : ``}container_name`);
      } else {
        _.set(newCatalogue, `${path ? `${path}.` : ``}container_name`, value);
      }
      setCatalogue(newCatalogue);
    }
    if (toChange === "examples") {
      if (Array.isArray(value) && value.length > 0) {
        _.set(newCatalogue, `${path ? `${path}.` : ``}examples`, value);
      } else {
        _.unset(newCatalogue, `${path ? `${path}.` : ``}examples`);
      }
      setCatalogue(newCatalogue);
    }
  };

  const hdlAddChild = ({ path }) => {
    let newCatalogue = _.cloneDeep(catalogue);
    let typeArr = _.get(newCatalogue, `${path ? `${path}.` : ``}type`) || [];
    let indexArrForLabel = path.split(".");
    indexArrForLabel = indexArrForLabel.filter((item) => item !== "type");
    typeArr.push({
      label: `${t("Catalogues.new_label")} ${indexArrForLabel.join("_")}${
        indexArrForLabel.length > 0 ? "_" : ""
      }${typeArr.length}`,
    });
    _.set(newCatalogue, `${path ? `${path}.` : ``}type`, typeArr);
    setCatalogue(newCatalogue);
  };

  const hdlRemoveChild = ({ path }) => {
    let newCatalogue = _.cloneDeep(catalogue);
    let pathType = path.split(".");
    let pathIdx = pathType.pop();
    let typeArr = _.get(newCatalogue, pathType);
    typeArr.splice(Number(pathIdx), 1);
    console.log(pathType.join("."));
    if (typeArr.length === 0) {
      _.unset(newCatalogue, pathType.join("."));
    } else {
      _.set(newCatalogue, pathType, typeArr);
    }
    setCatalogue(newCatalogue);
  };

  const handleSave = async () => {
    setTryToSend(true);
    try {
      const { error, message, payload } = await createCatalogues(catalogue);
      if (message === "successfully created") {
        enqueueSnackbar(t("Catalogues.catalog_successfully_created"), {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      if (error) {
        if (payload === "main catalogue name is repeated") {
          enqueueSnackbar(t("Catalogues.catalog_name_is_repeat"), {
            autoHideDuration: 4000,
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        } else if (Array.isArray(payload)) {
          enqueueSnackbar(t("Catalogues.cannot_similar_label"), {
            autoHideDuration: 4000,
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          // setErrors(payload)
        }
      }
    } catch (error) {
      // console.log(error.message)
      // if (error.message==="Catalogue name repeated") {
      //   enqueueSnackbar("El nombre del catalogo ya esta en uso", {
      //     autoHideDuration: 4000,
      //     variant: "error",
      //     anchorOrigin: { vertical: "bottom", horizontal: "right" },
      //   });
      // } else if (error.message==="Labels repeated") {
      //   enqueueSnackbar("No puede haber etiquetas similares en el mismo nivel", {
      //     autoHideDuration: 4000,
      //     variant: "error",
      //     anchorOrigin: { vertical: "bottom", horizontal: "right" },
      //   });
      //   setErrors()
      // }
    }
  };

  const debouncedCatalogueFunc = useRef(_.debounce(setDebouncedCatalogue, 600));

  useEffect(() => {
    debouncedCatalogueFunc.current(catalogue);
  }, [catalogue]);

  return (
    <div className="single-catalogue-page page">
      <div>
        {/* HEADER */}
        <div className={`general-header dyTheme1 dyBorder1 rounded`}>
          <Row>
            <Col
              lg="7"
              className="p-0 d-flex flex-row justify-content-space-around"
            >
              <div className="d-flex align-items-center">
                <span>
                  <OutlineBoxAdd float="left" width="48" height="48" />
                </span>
                <div className="mx-3">
                  <h3 className="mb-0 general-header-title">
                    {t("Catalogues.create_catalog")}
                  </h3>
                  <p className="mb-0 my-2 font-14">
                    {t("Catalogues.create_catalogs_manage_easily")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="my-3">
          <Breadcrum items={breadcrumbItems} />
        </div>
      </div>

      <Row>
        <Col lg="8" className="dyTheme1 dyBorder1 rounded p-4">
          <div className="d-flex justify-content-between">
            <div>
              <label className="form-label">
                {t("Catalogues.catalog_name")}:
              </label>
              <MCInput
                placeholder={t("Catalogues.enter_catalog_name")}
                onChange={hdlCatalogueName}
                value={catalogue.label}
              />
            </div>
            <div className="catalogue-icons" style={{ opacity: 100 }}>
              <div
                className="catalogue-icon"
                onClick={() => hdlAddChild({ path: "" })}
              >
                <Icon name="add_square" />
              </div>
            </div>
          </div>

          <hr />

          <div className="children-container dyTheme1 dyBorder1 rounded">
            {catalogue?.type ? (
              catalogue.type.map((item, idx) => (
                <RecursiveCatalog
                  key={idx}
                  scope={item}
                  entireCatalogue={catalogue}
                  hdlCatalogueChange={hdlCatalogueChange}
                  hdlAddChild={hdlAddChild}
                  hdlRemoveChild={hdlRemoveChild}
                  path={`type.${idx}`}
                  nestNum={1}
                  tryToSend={tryToSend}
                />
              ))
            ) : (
              <span>{t("Catalogues.no_children_display")}</span>
            )}
          </div>

          <div className="mt-2 d-flex justify-content-end">
            <MCButton
              label={t("Catalogues.save")}
              variant="primary"
              onClick={handleSave}
            />
          </div>
        </Col>

        <Col className="pe-0 ps-0 ps-lg-4 mt-4 mt-lg-0">
          <div className="dyTheme1 dyBorder1 rounded p-4">
            <PreviewComponent
              catalogue={catalogue}
              catalogueDebounced={debouncedCatalogue}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
