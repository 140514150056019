import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MCInput } from "../Input/Input";
import { useTranslation } from "react-i18next";
import { BoldTickCircle, FolderCross, OutlineDocumentText } from "../../Icon";
import { MCTooltip } from "../Tooltip/Tooltip";
import { useSnackbar } from "notistack";
import Icon from "../../Icon/Icon";
import "./SupportFile.scss";
/**
 * Component type input for text files such as PDF or TXT etc.
 */
export const SupportFileUpload = forwardRef(
  ({ icon = "outline_document_upload", onChange = () => {} }, ref) => {
    // Initial state
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [supportFileName, setSupportFileName] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    // Custom Hooks
    const divRef = useRef(null);
    const inputRef = useRef(null);
    const isDraggingOver = useRef(false);
    const supportUploadRef = useRef(null);
    const [t] = useTranslation("components");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Captures the input value and sets it to the respective states
    const handleFileChange = (e) => {
      const selectedFile = e.target.files;
      if (selectedFile) {
        setFile(selectedFile);
        setFileName(selectedFile[0].name);
        onChange({ file: selectedFile[0], supportFileName });
      } else {
        setFile(null);
      }
    };
    // Replaces the input functionality with a div for customization purposes
    const handleDivClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };
    // Clean initial state of the component
    const handleEraseFile = () => {
      setFile("");
      setFileName("");
      setSupportFileName("");
      onChange(null);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    };
    // useImperativeHandle function allows you to define which methods or functions are exposed in the reference object (ref) when used from the parent component
    useImperativeHandle(ref, () => ({
      handleEraseFile: () => {
        handleEraseFile();
      },
    }));
    // Grab and drop image file
    const handleFileDrop = (e) => {
      e.preventDefault();
      const selectedFile = e.dataTransfer.files;
      if (selectedFile) {
        setFile(selectedFile);
        setFileName(selectedFile[0].name);
        onChange(selectedFile[0]);
        setShowMessage(false);
        closeSnackbar();
      } else {
        setFile(null);
      }
      divRef.current.classList.add("drag-over");
    };
    // For effects of drag file into <div/>
    const handleDragEnter = (e) => {
      e.preventDefault();
      isDraggingOver.current = true;
      divRef.current.classList.add("drag-over");
      setShowMessage(true);
    };
    // Detect when the course enters the div by dragging the selected file
    const handleDragLeave = (e) => {
      e.preventDefault();
      const target = e.target;
      const clientX = e.clientX;
      const clientY = e.clientY;
      const elementBox = target.getBoundingClientRect();

      if (
        clientX < elementBox.left ||
        clientX > elementBox.rigth ||
        clientY < elementBox.top ||
        clientY > elementBox.bottom
      ) {
        setShowMessage(false);
        closeSnackbar();
        isDraggingOver.current = false;
        divRef.current.classList.remove("drag-over");
      }
    };
    // If the drag cursor leaves the div area, clear the drag state
    const handleMouseLeave = () => {
      if (isDraggingOver.current) {
        isDraggingOver.current = false;
        setShowMessage(false);
        closeSnackbar();
        divRef.current.classList.remove("drag-over");
      }
    };
    // Prevents the default behavior of the browser when dragging on the input
    const preventDefaultBehavior = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    // Allows you to display the original name of the selected file, but adjust it to a shorter name
    const truncatedText = () => {
      if (fileName?.length > 15) {
        const firstPt = fileName.substring(0, 8);
        const lastPt = fileName.substring(fileName.length - 8);
        return `${firstPt} ... ${lastPt}`;
      }
      return fileName;
    };
    // The file must be named to identify it
    const handleSupportFileName = (e) => {
      const name = e.target.value;
      setSupportFileName(name);
      onChange({ file, name: name });
    };
    // Show message with drag and drop message instructions
    {
      showMessage &&
        enqueueSnackbar(`${t("SupportUpload.drop_add_file")}`, {
          variant: "info",
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
          autoHideDuration: 2000,
        });
    }

    return (
      <div className="supportUpload" ref={supportUploadRef}>
        {!file ? (
          <div
            className="supportUploader"
            onClick={handleDivClick}
            onDragOver={preventDefaultBehavior}
            onDrop={handleFileDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onMouseLeave={handleMouseLeave}
            ref={divRef}
          >
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              className="supportUploader__inputFile"
              name="supportFile"
              accept=".txt, .csv, .xml, .json, .html, .md, .yaml, .yml, .log, .ini, .zip, .pdf"
              hidden
            />
            <Icon name={icon} size={50} className="supportUploader__icon" />
            <code className="supportUploader__subtitle m-2">
              {t("SupportUpload.click_drag")}
            </code>
          </div>
        ) : (
          <div className="supportUploader__file">
            <MCTooltip text={t("SupportUpload.delete")} position="top">
              <button
                onClick={handleEraseFile}
                className="supportUploader__delete"
              >
                <FolderCross />
              </button>
            </MCTooltip>
            <div className="supportUploader__fileReady">
              <OutlineDocumentText
                size={50}
                className="supportUploader__fileIcon"
              />
              <BoldTickCircle className="supportUploader__fileTick" />
            </div>
            <code className="supportUploader__fileSubtitle">
              {truncatedText()}
            </code>
            <MCInput
              placeholder={t("SupportUpload.file_name")}
              value={supportFileName}
              onChange={handleSupportFileName}
              isValid={supportFileName}
            />
            {!supportFileName && (
              <code className="--error">{t("SupportUpload.field_empty")}</code>
            )}
          </div>
        )}
      </div>
    );
  }
);
