import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getUtilitiesConfig,
  getGeneralDetails,
  updateDetails,
} from "../../../apis/apiClientConfig";
import { MCSelectSome } from "../../../components/MainComponents/SelectSome/SelectSome";
import { MCButton } from "../../../components/MainComponents/Button/Button";
import { MCInput } from "../../../components/MainComponents/Input/Input";
import { MCLabel } from "../../../components/MainComponents/Label/Label";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const General = () => {
  const [t] = useTranslation("settings");
  const { swalTheme } = useSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState(true);

  const [listCountriesUtilities, setListCountriesUtilities] = useState([]);
  const [listLanguagesUtilities, setListLanguagesUtilities] = useState([]);
  const [listPhonesUtilities, setListPhonesUtilities] = useState([]);

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [phones, setPhones] = useState([]);
  const [phonesToSelect, setPhonesToSelect] = useState([]);
  const [number_of_employees, setNumber_of_employees] = useState(0);
  const [expired_report, setExpired_report] = useState(30);
  const [report_to_expire, setReport_to_expire] = useState(0);

  useEffect(() => {
    onMounted();
  }, []);

  useEffect(() => {
    // From the selection in 'add countries', the phones available to select are created.
    if (!isLoading) {
      const phones = [];
      countries.map((item1) => {
        listPhonesUtilities.map((item2) => {
          if (item1.value === item2.value) {
            item2.phones.map((item3) => {
              phones.push({ label: `${item3}-${item2.value}`, value: item3 });
            });
          }
        });
      });
      setPhonesToSelect(phones);
    }
  }, [countries]);

  const onMounted = async () => {
    const [detailsGeneralApi, utilitiesApi] = await Promise.all([
      getGeneralDetails(), //data previous selected & uploaded
      getUtilitiesConfig(), //avaiable data to select
    ]);
    // Data previous selected & uploaded
    setCountries(detailsGeneralApi.general.countries);
    setLanguages(detailsGeneralApi.general.languages);
    setNumber_of_employees(
      detailsGeneralApi.general.employees.number_of_employees
    );
    setExpired_report(detailsGeneralApi.general.expired_report); //days to alert
    setReport_to_expire(detailsGeneralApi.general.report_to_expire);
    // avaiable data to select
    setListCountriesUtilities(utilitiesApi.utilities.list_countries);
    setListLanguagesUtilities(utilitiesApi.utilities.list_languages);
    setListPhonesUtilities(utilitiesApi.utilities.list_phones);
    setPhones((befPh) => {
      const phones = [];
      detailsGeneralApi.general.phones.map((phone) => {
        phone.phones.map((arrPhone) => {
          phones.push({ label: `${arrPhone}-${phone.value}`, value: arrPhone });
        });
      });
      return phones;
    });
    setIsLoading(false);
  };
  // save and upload changes
  const handleSave = async () => {
    const newCountries = countries.map((item) => item.value);
    const newLanguages = languages.map((item) => item.value);
    const newPhones = [];
    phones.map((item1) => {
      listPhonesUtilities.map((item2) => {
        if (item2.phones.includes(item1.value)) {
          const existInNewPhones = newPhones.findIndex(
            (item3) => item3.key === item2.value
          );
          if (existInNewPhones >= 0) {
            newPhones[existInNewPhones].number_phones.push(item1.value);
          } else {
            newPhones.push({
              key: item2.value,
              number_phones: [item1.value],
            });
          }
        }
      });
    });

    const objToSave = {
      countries: newCountries,
      languages: newLanguages,
      phones: newPhones,
      number_of_employees,
      report_to_expire,
      expired_report,
    };

    try {
      const resp = await updateDetails(objToSave).then((resp) => {
        if (resp.status === 200) {
          Swal.fire({
            title: t("general.settings_success"),
            icon: "success",
            confirmButtonText: t("general.continue"),
            ...swalTheme,
          });
        }
      });
    } catch (error) {
      Swal.fire({
        title: t("general.error_update"),
        icon: "error",
        confirmButtonText: t("general.continue"),
        ...swalTheme,
      });
    }
  };

  return (
    !isLoading && (
      <div className="configuration-general">
        <div className="dyTheme1 dyBorder1 rounded p-30 border mb-20">
          <h5>{t("general.setup_plan")}</h5>

          <div className="mt-3 mr-20 ">
            <MCSelectSome
              helptip={t("general.choose_countries")}
              placeholder={t("general.select_countries")}
              label={`${t("general.add_countries")}:`}
              options={listCountriesUtilities}
              selecteds={countries}
              onChange={setCountries}
            />
          </div>
          <div className="mt-3 mr-20">
            <MCSelectSome
              helptip={t("general.choose_languages")}
              placeholder={t("general.select_languages")}
              label={`${t("general.support_lenguages")}:`}
              options={listLanguagesUtilities}
              selecteds={languages}
              onChange={setLanguages}
            />
          </div>
          <div className="mt-3 mr-20">
            <MCSelectSome
              helptip={t("general.choose_phones")}
              placeholder={t("general.select_phones")}
              label={`${t("general.add_phones")}:`}
              options={phonesToSelect}
              selecteds={phones}
              onChange={setPhones}
            />
          </div>
        </div>

        <div className="dyTheme1 dyBorder1 rounded p-30 border mb-2">
          <h5>{t("general.worker_settings")}</h5>

          <Row>
            <Col>
              <div className="mt-3 mr-20">
                <MCLabel
                  text={`${t("general.expiration_days")}:`}
                  helptip={t("general.expiration_time")}
                />
                <MCInput
                  type="number"
                  autoComplete="off"
                  value={expired_report}
                  onChange={(e) => setExpired_report(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-3 mr-20">
                <MCLabel
                  text={`${t("general.alert_days")}:`}
                  helptip={t("general.alert_time")}
                />
                <MCInput
                  type="number"
                  autoComplete="off"
                  value={report_to_expire}
                  onChange={(e) => setReport_to_expire(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-3 mr-20">
                <MCLabel
                  text={`${t("general.num_employees")}:`}
                  helptip={t("general.importance_num_employees")}
                />
                <MCInput
                  type="number"
                  autoComplete="off"
                  value={number_of_employees}
                  onChange={(e) => setNumber_of_employees(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="">
          <Row>
            <div className="d-flex justify-content-end">
              <MCButton
                label={t("general.save")}
                className="mt-20 d-inline"
                variant="primary"
                outline
                onClick={handleSave}
              />
            </div>
          </Row>
        </div>
      </div>
    )
  );
};
