import { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Modal, Row } from "react-bootstrap";
import { catalogByPart } from "../../../apis";
import { RenderInput } from "../../JSONForms/PreviewForm/PreviewForm";
import {
  MCButton,
  MCCheckbox,
  MCInput,
  MCLabel,
  MCTooltip,
} from "../../MainComponents";
import { returnStringOfSelections } from "../User";
import { SingleRowTextLoading } from "../../Loading/SingleRowText/SingleRowText";
import {
  getSingleRole,
  updateRole as updateRoleApi,
  createRole as createRoleApi,
} from "../../../apis/apiEAM";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const showSnackBar = (msg, type = "error") => {
  enqueueSnackbar(`${msg}`, {
    variant: type,
    preventDuplicate: true,
  });
};

const newRole = {
  name: "",
  action: {
    version: "barad-dur",
    statement: [
      {
        effect: "allow",
        resources: [],
      },
    ],
  },
  is_active: true,
};

const RoleValue = ({ idx, catalogue, isOwn, value, deleteStatement }) => {
  const [string, setString] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [allOnPathSetted, setAllOnPathSetted] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const splitedValue = value.split("::");
    if (splitedValue[splitedValue.length - 1] == "*") {
      splitedValue.pop();
      setAllOnPathSetted(true);
    }
    let joinedValue = splitedValue.join("::type::");
    joinedValue = `type::${joinedValue}`;
    returnStringOfSelections("", catalogue, isOwn, joinedValue).then((resp) => {
      setString(resp);
      setIsLoading(false);
    });
  }, [value]);

  return (
    <div className="role-list-item modall d-flex justify-content-between align-items-center">
      {isLoading ? (
        <SingleRowTextLoading />
      ) : (
        <>
          <label>
            {string}
            {allOnPathSetted ? " > *" : ""}
          </label>
          <MCTooltip position="bottom" text="Delete">
            <div>
              <MCButton
                label=""
                icon="trash_can"
                variant="danger"
                size="sm"
                className="p-0"
                width={28}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteStatement(idx);
                }}
              />
            </div>
          </MCTooltip>
        </>
      )}
    </div>
  );
};

const ModalAddRole = ({
  onHide,
  currentRole,
  handleCurrentRole,
  rerenderList,
}) => {
  const catalogue = "RL-001";
  const isOwn = false;

  // States para el funcionamiento del catalogo
  const [schema, setSchema] = useState(null);
  const [data, setData] = useState(null);
  const [valid, setValid] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // States para manejar el role
  const [role, setRole] = useState(newRole);
  const [idxAllow, setIdxAllow] = useState(0);
  const [allOnPath, setAllOnPath] = useState(false);

  const [t] = useTranslation("roles");
  const { gTheme } = useSelector((state) => state.theme);

  useEffect(() => {
    mount();
  }, []);

  useEffect(() => {
    if (currentRole) {
      getSingleRole(currentRole).then((resp) => {
        setRole(resp);
      });
    }
  }, []);

  // CREAR un nuevo role
  const createRole = () => {
    if (role.name.trim() === "") {
      showSnackBar(t("addRole.enter_role_name"));
      return;
    }
    if (role.action.statement[idxAllow].resources.length === 0) {
      showSnackBar(t("addRole.no_categories_selected"));
      return;
    }
    createRoleApi(role)
      .then(() => {
        showSnackBar(t("addRole.role_successfully_created"), "success");
        handleCurrentRole();
        rerenderList();
        onHide();
      })
      .catch(console.log);
  };

  // EDITAR un role existente
  const updateRole = () => {
    if (role.action.statement[idxAllow].resources.length === 0) {
      showSnackBar(t("addRole.no_categories_selected"));
      return;
    }
    updateRoleApi(currentRole, role.action)
      .then(() => {
        showSnackBar(t("addRole.updated_role"), "success");
        handleCurrentRole();
        onHide();
      })
      .catch(console.log);
  };

  const handleName = (value) => {
    const newRole = _.cloneDeep(role);
    newRole.name = value;
    setRole(newRole);
  };

  const addStatement = () => {
    if (data[0][0][catalogue].trim() === "") {
      showSnackBar(t("addRole.select_category", "error"));
      return;
    }
    const newRole = _.cloneDeep(role);
    const splitedValue = data[0][0][catalogue].split("::type::");
    let newValue = splitedValue.join("::");
    newValue = newValue.substring(6);
    newValue = `${newValue}${allOnPath ? "::*" : ""}`;
    if (newRole.action.statement[idxAllow].resources.includes(newValue)) {
      showSnackBar(t("addRole.category_already_added"), "warning");
      return;
    } else {
      newRole.action.statement[idxAllow].resources.push(newValue);
      setRole(newRole);
    }
  };

  const deleteStatement = (idx) => {
    const newRole = _.cloneDeep(role);
    newRole.action.statement[idxAllow].resources.splice(idx, 1);
    setRole(newRole);
  };

  const handleCloseModal = () => {
    handleCurrentRole();
    onHide();
  };

  // Para cargar los datos del catalogo
  const mount = async () => {
    setIsLoading(true);
    setIdxAllow(
      role.action.statement.findIndex((item) => item.effect === "allow")
    );
    const resp = await catalogByPart({ is_own: isOwn, catalogue, path: "/" });
    if (resp.error) return;
    let children = [...resp.data];
    let objCatalogue = {
      key: `C::OWN::${catalogue}`,
      type: "catalog-select",
      label: `${t("addRole.select_role")}`,
      placeholder: t("addRole.support_text"),
      required: false,
      sensitive: false,
      scope: "/",
      selected: "",
      isOwn: isOwn,
      catalogue: catalogue,
      locales: null,
      children: children,
    };
    let objCatData = {
      [catalogue]: "",
      catalogue: catalogue,
      isOwn: false,
      sensitive: false,
    };
    let objIsValid = { [catalogue]: [] };
    setSchema([[objCatalogue]]);
    setData([[objCatData]]);
    setValid([[objIsValid]]);
    setIsLoading(false);
  };

  return (
    <Modal
      show={true}
      onHide={handleCloseModal}
      size="xl"
      className="roles-page-modal-add"
      centered
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      >
        <h3>{`${
          currentRole ? t("addRole.edit_role") : t("addRole.create_new_role")
        }`}</h3>
      </Modal.Header>
      <div className="form-preview">
        <Row>
          <Col md={4}>
            <div>
              <label>{t("addRole.role_name")}:</label>
              <MCInput
                defaultValue={role.name}
                onChange={(e) => handleName(e.target.value)}
                disabled={currentRole}
              />
            </div>
            {schema && !isLoading && (
              <Row>
                <RenderInput
                  key={`form-input-0`}
                  entireSchema={schema}
                  entireFormData={data}
                  entireIsValid={valid}
                  constraints={[]}
                  setConstraints={() => {}}
                  entirePathSchema={`0.0`}
                  entirePathData={`0.0`}
                  setSchemaState={setSchema}
                  setFormData={setData}
                  setIsValid={setValid}
                  tryToNext={[]}
                  activeStep={0}
                  attachments={[]}
                  setAttachments={() => {}}
                  origin={`none`}
                />
              </Row>
            )}
            <div className="mx-3 mb-3">
              <MCCheckbox
                defaultValue={allOnPath}
                onChange={(e) => setAllOnPath(e.target.checked)}
                id="allOnPath"
                className="me-2"
              />
              <MCLabel
                text={t("addRole.all_categories_from_this")}
                htmlFor="allOnPath"
              />
            </div>
            <MCButton
              variant="primary"
              label={t("addRole.add_category")}
              className="w-100"
              onClick={addStatement}
            />
          </Col>

          <Col md={8}>
            <section>
              <ul>
                {role.action.statement[0].resources.map((item, idx) => (
                  <RoleValue
                    key={idx}
                    idx={idx}
                    catalogue={catalogue}
                    isOwn={isOwn}
                    value={item}
                    deleteStatement={deleteStatement}
                  />
                ))}
              </ul>
            </section>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-end">
            <MCButton
              variant="primary"
              outline
              label={t("addRole.cancel")}
              onClick={handleCloseModal}
              className="me-2"
            />
            {currentRole ? (
              <MCButton
                variant="primary"
                label={t("addRole.update_role")}
                onClick={updateRole}
              />
            ) : (
              <MCButton
                variant="primary"
                label={t("addRole.add_role")}
                onClick={createRole}
              />
            )}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalAddRole;
