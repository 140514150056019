import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { createInvestigationAssign, deleteInvestigationAssign, getInvestigationListByReportId } from "../../../../apis";
import { parseDateToDDMMYYYY2 } from "../../../../helpers/dateTransform";
import { BoldDocumentText, OutlineData, OutlineDocumentText3, OutlineEdit, OutlineEye, OutlineHierarchy, OutlineLock, TrashCan } from "../../../Icon";
import { MCButton, MCSelect } from "../../../MainComponents";
import { EGIconButton } from "../../../MainComponents/Button/IconButton/IconButton";
import { EGSpeedDial } from "../../../MainComponents/Button/SpeedDial/SpeedDial";

const ModalAssignInvestigationToReport = ({ 
  idReportRelated, 
  investigationList, 
  investigationsAssignedToThisRelated,
  setInvestigationsAssignedToThisRelated,
  show, 
  onHide,
}) => {
  
  //--- Language hook
  const [t] = useTranslation("information");
  const [t2] = useTranslation("investigation");
  //--- State for handle investigation to assign to another report
  const [selectedInvestigation, setSelectedInvestigation] = useState(null);

  useEffect(() => {
    if (show===true) {
      getInvestigationListByReportId(idReportRelated)
      .then(setInvestigationsAssignedToThisRelated)
      .catch(console.log);
    }
  }, [show]);

  useEffect(() => {
    if (investigationList.length === 1) {
      setSelectedInvestigation(investigationList[0]);
    }
  }, [investigationList]);

  //--- Defines the index option selected in the reports dropList (Select)---//
  const getInvType = (advance, created_at, id) => {
    if (advance) {
      return `${parseDateToDDMMYYYY2(created_at)} -${t2(
        "investigation.advanced"
      )} -${id}`;
    } else {
      return `${parseDateToDDMMYYYY2(created_at)} -${t2("investigation.basic")} -${id}`;
    }
  };
  //--- Handles an assignation to report
  const confirmDelete = async() => {
    try {
      await deleteInvestigationAssign(selectedInvestigation?.alias, idReportRelated);
      getInvestigationListByReportId(idReportRelated)
      .then(setInvestigationsAssignedToThisRelated)
      .catch(console.log);
      onHide();
    } catch (error) {
      console.log(error);
    }
  }
  //--- Handles an assignation to report
  const confirmAssing = async() => {
    try {
      await createInvestigationAssign(selectedInvestigation?.alias, idReportRelated);
      getInvestigationListByReportId(idReportRelated)
      .then(setInvestigationsAssignedToThisRelated)
      .catch(console.log);
      onHide();
    } catch (error) {
      console.log(error);
    }
  }

  const returnIsAlredyAssign = (alias) => {
    if (!alias) return "";
    const isAssigned = investigationsAssignedToThisRelated.find(item=>item.alias === alias);
    if (isAssigned) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <div className="confirmationModal__header mb-3">
            <h3 className="confirmationModal__title m-0">
              <b>{t("information.investigations")}</b>
            </h3>
            <EGIconButton
              iconName="cancel_circle"
              onClick={onHide}
              iconColor="primary"
            />
          </div>
          {
            Array.isArray(investigationList) && investigationList.length === 1 
            ? <small>
                {`${returnIsAlredyAssign(selectedInvestigation?.alias) ? t("information.unlink") : t("information.assing")}`} {t("information.the_current_investigation")}
              </small>
            : <small>
                {t("information.assign_or_unlink_any_investigation")}
              </small> 
          }
          <div className="mt-20">
            {
              Array.isArray(investigationList) && investigationList.length > 1 &&
              <MCSelect
                options={investigationList}
                value={selectedInvestigation}
                onChange={setSelectedInvestigation}
                formatOptionLabel={({ advance, created_at, id, alias }) =>
                  advance ? (
                    <small className="selectInvestigation__option" >
                      <OutlineData className="--advance" />{" "}
                      {getInvType(advance, created_at, id)} &nbsp;&nbsp;
                      <div className={`circle-assign-investigation ${returnIsAlredyAssign(alias) ? "assigned" : "unassigned"}`}></div>
                    </small>
                  ) : (
                    <small className="selectInvestigation__option">
                      <OutlineHierarchy className="--basic" />{" "}
                      {getInvType(advance, created_at, id)} &nbsp;&nbsp;
                      <div className={`circle-assign-investigation ${returnIsAlredyAssign(alias) ? "assigned" : "unassigned"}`}></div>
                    </small>
                  )
                }
              />
            }
            
            <div className="mt-20 d-flex justify-content-end">
              {
                !!selectedInvestigation && returnIsAlredyAssign(selectedInvestigation?.alias)
                ? <MCButton
                    label={t("information.unlink")}
                    variant="danger"
                    onClick={confirmDelete}
                    disabled={!selectedInvestigation}
                  />
                : <MCButton
                    label={t("information.assing")}
                    variant="primary"
                    onClick={confirmAssing}
                    disabled={!selectedInvestigation}
                  />
              }
            </div>

          </div>
        </Modal.Body>
      </Modal>
  )
}

export const RelatedReportItem = ({
  i, 
  related, 
  investigationList, 
  expandedRelatedComments,
  setEditRelated,
  setRelatedFolio,
  setRelatedComment,
  setUploadRelated,
  openModalAction,
  removeRelatedSave,
  hdlSeeMore,
}) => {

  const [t] = useTranslation("information");

  const [investigationsAssignedToThisRelated, setInvestigationsAssignedToThisRelated] = useState([]);
  const [haveLinkedInvestigations, setHaveLinkedInvestigations] = useState(true);
  const [showModalAssign, setShowModalAssign] = useState(false);

  useEffect(() => {
    getInvestigationListByReportId(related?.id_report)
    .then(setInvestigationsAssignedToThisRelated)
    .catch(console.log);
  }, []);

  useEffect(() => {
    let haveAssigned = false;
    investigationsAssignedToThisRelated.forEach(item1=>{
      investigationList.forEach(item2=>{
        if (item1.alias === item2.alias) {
          haveAssigned = true;
        }
      })
    });
    if (haveAssigned) {
      setHaveLinkedInvestigations(true);
    } else {
      setHaveLinkedInvestigations(false);
    }
  }, [investigationsAssignedToThisRelated]);

  // Button actions
  const actions = [
    {
      key: "view",
      icon:<OutlineEye />,
      name: t("information.view"),
      onClick: () =>
        window.open(
          `/reports/${related?.id_report}`,
          "_blank",
          "noopener,noreferrer"
        ),
      className: "--view",
      aviable: true,
    },
    {
      key: "investigation",
      icon: <OutlineDocumentText3 />,
      name: t("information.assign_investigation"),
      onClick: () => setShowModalAssign(true),
      className: "--investigation",
      aviable: Array.isArray(investigationList) && investigationList.length > 0,
    },
    {
      key: "edit",
      icon: <OutlineEdit />,
      name: t("information.edit"),
      onClick: () => {
        setEditRelated(true);
        setRelatedFolio(related.folio);
        setRelatedComment(related.comment);
        setUploadRelated(true);
      },
      className: "--editar",
      aviable: true,
    },
    {
      key: "delete",
      icon: haveLinkedInvestigations ? <OutlineLock /> : <TrashCan /> ,
      name: t("information.delete"),
      onClick: () => {
        if (haveLinkedInvestigations) { // action, title, desc1, desc2, question
          openModalAction(
            null,
            t("information.warning"),
            t("information.cant_unlink_report"),
          )
        } else {
          openModalAction(
            () => removeRelatedSave(related),
            t("information.unlink_report"),
            t("information.are_you_sure_you_want_perform_this_action"),
          )
        }
      },
      className: "--erase",
      aviable: true,
    },
  ];

  return (
    <>
      <ModalAssignInvestigationToReport 
        investigationList={investigationList}
        idReportRelated={related?.id_report}
        investigationsAssignedToThisRelated={investigationsAssignedToThisRelated}
        setInvestigationsAssignedToThisRelated={setInvestigationsAssignedToThisRelated}
        show={showModalAssign}
        onHide={()=>setShowModalAssign(false)}
      />
      <div
        className="properties__related-chip rounded"
        key={`related-report-${i}`}
      >
        <div className="mx-2">
          <BoldDocumentText />
        </div>
        <div className="properties__related-title">
          <h6 className="p-1 my-auto">{related.folio}</h6>
          {related.comment && (
            <small
              className="px-2 pb-2"
              onClick={() => hdlSeeMore(i)}
            >
              {expandedRelatedComments.includes(i)
                ? related.comment
                : `${related.comment.slice(0, 140)}`}
              {related.comment.length > 140 &&
                !expandedRelatedComments.includes(i) && (
                  <b className="--seeMore pointer">{`... ${t(
                    "information.see_more"
                  )}`}</b>
                )}
            </small>
          )}
        </div>

        <EGSpeedDial
          actions={actions}
          tooltipPlacement="top"
          direction="left"
          style={{ position: "absolute", right: 15 }}
          actionSize={38}
          actionMargin={5}
        />

      </div>
    </>
  )
}