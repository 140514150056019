import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ListReportForms } from "../../../apis";
import {
  utilitiesReportTemplates,
  createReportTemplate,
} from "../../../apis/apiExports";
import { Loading } from "../../Loading/String/Loading";
import { MCSelect } from "../../MainComponents/Select/Select";
import { useTranslation } from "react-i18next";
import {
  MCButton,
  MCCheckbox,
  MCInput,
  MCLabel,
  MCTooltip,
} from "../../MainComponents";
import { Col, Row } from "react-bootstrap";
import { useSnackbar } from "notistack";
import "./FormReportTemplate.scss";
import { parseDateToISOString2 } from "../../../helpers/dateTransform";

const MsgError = ({ state }) => {
  const [t] = useTranslation("exports");
  return (
    state && (
      <p className="required-field-msg">{`${t(
        "createFile.field_required"
      )} *`}</p>
    )
  );
};

const CreateReportTemplate = ({
  listReportForms = [],
  stateToIndicateNewReportTemplate = null,
}) => {
  const [t] = useTranslation("exports");
  const { enqueueSnackbar } = useSnackbar();
  // request data states
  const [reportId, setReportId] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [choisedLanguage, setChoisedLanguage] = useState(null);
  const [listSelectedFields, setListSelectedFields] = useState([]);
  const [isGlobalTemplate, setIsGlobalTemplate] = useState(false);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  // List Fields states
  const [listFields, setListFields] = useState([]);

  // Class names states
  const [classNameReportId, setClassNameReportId] = useState("");
  const [classNameTemplateName, setClassNameTemplateName] = useState("");
  const [classNameSelectedFields, setClassNameSelectedFields] = useState("");

  // Validations states
  const [isValidReportId, setIsValidReportId] = useState(false);
  const [isValidTemplateName, setIsValidTemplateName] = useState(false);
  const [isValidSelectedFields, setIsValidSelectedFields] = useState(false);

  const listLanguages = [
    {
      value: "es",
      label: t("reportTemplate.es"),
    },
    {
      value: "en",
      label: t("reportTemplate.en"),
    },
  ];

  /**
   * This function handles the select option
   * and sets the state of the report id
   * @param {Object} selectedForm
   * seldectedForm = {
   *   value: 1,
   *   label: "Report created at - 2023-10-13T17:17:13.082252"
   *   selected: false
   * }
   * value = id of the report form
   */
  const handleSelectOption = (selectedForm) => {
    setReportId(selectedForm.value);
    setClassNameReportId("");
    setIsValidReportId(true);
  };

  /**
   * This function handles the select option
   * and sets the state of the choised language
   * @param {Object} selectedLanguage
   * selectedLanguage = {
   *   value: "es",
   *   label: "Spanish",
   * }
   * value = language code
   */
  const handleSelectedLanguage = (selectedLanguage) => {
    setChoisedLanguage(selectedLanguage.value);
  };

  const getUtilitiesReportTemplates = async () => {
    resetStatesFields();

    const { data } = await utilitiesReportTemplates(reportId);
    if (data !== undefined && data.fields.length > 0) {
      setListFields(transformDataToSelect(data.fields));
      setIsLoading(false);
    }
  };

  const transformDataToSelect = (data) => {
    return data.map((item, index) => {
      return {
        value: index,
        label: item.label,
        data: item,
      };
    });
  };

  const resetStatesFields = () => {
    setListFields([]);
    setListSelectedFields([]);
    setIsValidSelectedFields(false);
  };

  const handleSelectFields = (selectedFields) => {
    if (selectedFields.length > 0) {
      setListSelectedFields(
        selectedFields.map((item) => {
          return item.data;
        })
      );
      setClassNameSelectedFields("");
      setIsValidSelectedFields(true);
    } else {
      setListSelectedFields([]);
      setClassNameSelectedFields("is-invalid");
      setIsValidSelectedFields(false);
    }
  };

  const handleIsGlobalTemplate = () => setIsGlobalTemplate(!isGlobalTemplate);

  // Validations
  const validateReportId = () => {
    if (reportId === undefined || reportId === null) {
      setClassNameReportId("is-invalid");
      setIsValidReportId(false);
      return;
    }
  };

  const handleTemplateName = (e) => {
    if (e.target.value !== "") {
      setTemplateName(e.target.value);
      setClassNameTemplateName("");
      setIsValidTemplateName(true);
    } else {
      setClassNameTemplateName("is-invalid");
      setIsValidTemplateName(false);
    }
  };

  const handleBlurTemplateName = (e) => {
    if (e.target.value === "") {
      setClassNameTemplateName("is-invalid");
      setIsValidTemplateName(false);
    } else setClassNameTemplateName("");
  };

  const validatedSelectedFields = () => {
    if (listSelectedFields.length === 0) {
      setClassNameSelectedFields("is-invalid");
      setIsValidSelectedFields(false);
    } else setClassNameSelectedFields("");
  };

  const handlecreateReportTemplate = async () => {
    enqueueSnackbar(`${t("reportTemplate.creating_report_template")}...`, {
      variant: "info",
    });
    /**
         * This function creates a report template
         * with the data of the form
         * @request data:
         * {
                "is_global": true,
                "form_id": 7,
                "template_name": "Reportes del formulario",
                "template_fields": [
                    {
                        "path": "0::form::json-schema::0",
                        "key": "C::EG::PI-103101",
                        "label": "Gender",
                        "is_sensitive": false,
                        "locales": {},
                        "has_catalog": true
                    }
                ]
            }

            @response data: { "message": "success" }
         */
    const data = {
      form_id: reportId,
      template_name: templateName,
      template_fields: listSelectedFields,
    };

    if (choisedLanguage !== null) data.default_language = choisedLanguage;
    if (isGlobalTemplate) data.is_global = isGlobalTemplate;

    const response = await createReportTemplate(data);

    if (response !== undefined || response !== null) {
      enqueueSnackbar(t("reportTemplate.report_template_created"), {
        variant: "success",
      });
      stateToIndicateNewReportTemplate(true);
      resetStatesFields();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (reportId) getUtilitiesReportTemplates();
  }, [reportId]);

  return (
    <div className="container-form-report-template">
      <div className={classNameReportId}>
        <Row>
          <MCLabel
            text={`${t("reportTemplate.select_report_form")}:`}
            isRequired
          />
          <MCSelect
            options={listReportForms}
            onChange={handleSelectOption}
            onBlur={validateReportId}
          />
          <MsgError state={classNameReportId !== ""} />
        </Row>
      </div>
      {reportId && (
        <div className="input-data">
          <hr />
          <div>
            <Row className={classNameTemplateName}>
              <MCLabel
                text={`${t("reportTemplate.template_name")}:`}
                isRequired
              />
              <MCInput
                className="mt-1"
                placeholder={t("reportTemplate.enter_template_name")}
                onChange={handleTemplateName}
                onBlur={handleBlurTemplateName}
              />
              <MsgError state={classNameTemplateName !== ""} />
            </Row>
          </div>

          <Row className="mt-2">
            <Col lg="6">
              <MCCheckbox className="me-1" onChange={handleIsGlobalTemplate} />
              <MCLabel
                className="mb-0"
                text={`${t("reportTemplate.create_global_template")} (${t(
                  "createFile.optional"
                )}):`}
                htmlFor="check-for-active-catalogue"
              />
            </Col>
            <Col lg="6">
              <MCLabel
                text={`${t("reportTemplate.select_language")} (${t(
                  "createFile.optional"
                )}):`}
              />
              <MCSelect
                options={listLanguages}
                onChange={handleSelectedLanguage}
              />
            </Col>
          </Row>

          {!isLoading ? (
            <>
              <div className={classNameSelectedFields}>
                <Row className="mt-2">
                  <MCLabel
                    text={`${t("reportTemplate.select_report_field")}:`}
                    isRequired
                  />

                  <MCSelect
                    isMulti
                    options={listFields}
                    onChange={handleSelectFields}
                    onBlur={validatedSelectedFields}
                  />
                  <MsgError state={classNameSelectedFields !== ""} />
                </Row>
              </div>

              <Row className="mt-3">
                <MCButton
                  label={t("reportTemplate.create_template_report")}
                  variant="primary"
                  outline
                  disabled={
                    isValidReportId &&
                    isValidTemplateName &&
                    isValidSelectedFields
                      ? false
                      : true
                  }
                  onClick={handlecreateReportTemplate}
                />
              </Row>
            </>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  );
};

const RedirectToCreateForm = () => {
  const [t] = useTranslation("exports");
  const navigate = useNavigate();

  const returnToCreateForm = () => {
    navigate("/createform");
  };

  return (
    <>
      <p className="textRedirect">{`${t(
        "reportTemplate.havent_created_report"
      )}...`}</p>
      <MCButton
        label={t("reportTemplate.crate_new_form")}
        variant="primary"
        outline
        onClick={returnToCreateForm}
      />
    </>
  );
};

export const FormReportTemplate = ({
  areThereReportForms = false,
  stateToReturn = null,
}) => {
  const [t] = useTranslation("exports");

  // API states
  const [hasReportForms, setHasReportForms] = useState(false);
  const [listReportForms, setListReportForms] = useState([]);
  const [isThereNewReportTemplate, setIsThereNewReportTemplate] =
    useState(false);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  const getListReportForms = async () => {
    const data = await ListReportForms();
    if (data !== undefined && data.length > 0) {
      setListReportForms(transformDataToSelect(data));
      setHasReportForms(true);
    } else setHasReportForms(false);

    setIsLoading(false);
  };

  const transformDataToSelect = (data) => {
    /**
     * This function transforms the data to the format
     * that the select component needs
     * @returns [
     *    {
     *      value: 1,
     *      label: "Report created at - 2023-10-13T17:17:13.082252"
     *      selected: false
     *    },
     *    ...
     * ]
     */

    return data.map((item) => {
      return {
        value: item.id,
        label: `${t(
          "reportTemplate.report_created_at"
        )} ${parseDateToISOString2(item.created_at)}`,
        selected: false,
      };
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getListReportForms();
  }, [isThereNewReportTemplate]);

  return !isLoading ? (
    <div className="container">
      {(areThereReportForms || isThereNewReportTemplate) && (
        <MCTooltip text={t("reportTemplate.turn_back")} position="left">
          <div className="back-btn">
            <MCButton
              label={""}
              variant="primary"
              outline
              icon="arrow_left"
              size="sm"
              onClick={() => stateToReturn(false)}
            />
          </div>
        </MCTooltip>
      )}
      {hasReportForms ? (
        <CreateReportTemplate
          listReportForms={listReportForms}
          stateToIndicateNewReportTemplate={setIsThereNewReportTemplate}
        />
      ) : (
        <RedirectToCreateForm />
      )}
    </div>
  ) : (
    <Loading />
  );
};
