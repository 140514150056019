import usersTypeES from "../../../../../translations/ES/investigation.json";
import usersTypeEN from "../../../../../translations/EN/investigation.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? usersTypeES : usersTypeEN;
export const userTypeOpts = [
  {
    value: "incharge",
    label: [language.usersType.investigator_chargue],
  },
  {
    value: "additional",
    label: [language.usersType.additional],
  },
  {
    value: "assigned",
    label: [language.usersType.assigned],
  },
  {
    value: "virtual",
    label: [language.usersType.without_access],
  },
];
