import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  createAttachment,
  getReportDetail,
  updateStatusCloseDetails,
} from "../../../../apis";
import {
  MCSelect,
  MCCheckbox,
  MCInput,
  MCLabel,
  MCRadio,
  MCButton,
  FileUpload,
} from "../../../MainComponents";
import { validInitDates } from "../../../../helpers/dateTransform";
import { useTranslation } from "react-i18next";
import { Accordion, Modal } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { createPrivateInternalMessage } from "../../../../apis";
import { useSnackbar, closeSnackbar } from "notistack";
import "./Conclusion.scss";
// Report conclusion and closure details
export const Conclusions = ({
  report_details,
  tracking_code,
  setRerenderAll,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [t] = useTranslation("report");
  // ----> Initial States <---- //
  const [loading, setLoading] = useState(false);
  // String: "Observations on the change of status"
  const [observations, setObservations] = useState("");
  // String: "General closure comments"
  const [comments, setComments] = useState("");
  // File(s): General closure files
  const [uploadFilesClosureReport, setUploadFilesClosureReport] = useState([]);
  // Select: "Select of closure type for the report"
  const [closed, setClosed] = useState("");
  // Checkbox: "The report will be followed up on in another instance"
  const [followup, setFollowup] = useState(false);
  // String: "Observations on the follow-up of the report in another instance"
  const [followupDetails, setFollowupDetails] = useState("");
  // Boolean: "Prevents the observation from going empty when follow-up is true"
  const [errorFollowup, setErrorFollowup] = useState(false);
  // Object: "Select closure cause (unfounded && inadmissible)"
  const [cause, setCause] = useState({});
  // String: "Enter new closure cause"
  const [another_cause, setAnother_cause] = useState("");
  // Prevent the "specify other cause" field from being left empty
  const [error, setError] = useState(false);
  // Schedule closure report date
  const [close_date, setClose_date] = useState("");
  // String: "Direct message to complainant"
  const [content, setContent] = useState("");
  // File(s)
  const [files, setFiles] = useState([]);
  // Get files uploaded
  const [filesConclusion, setFilesConclusion] = useState([]);
  // Mount details
  useEffect(() => {
    getReportDetails();
  }, []);

  const getReportDetails = async () => {
    getReportDetail(id).then((resp) => {
      const {
        another_cause,
        cause,
        close_date,
        closed,
        followup,
        followupDetails,
        observations,
        files,
      } = resp?.status?.details;
      setAnother_cause(another_cause);
      setCause(cause);
      setClose_date(
        validInitDates(new Date(close_date)) ? new Date(close_date) : new Date()
      );
      setClosed(closed);
      setFollowup(followup);
      setFollowupDetails(followupDetails);
      setObservations(observations);
      setFilesConclusion(files);
    });
  };
  // -----> Unfounded Causes <-----//
  const unfoundedOptions = [
    {
      label: t("Conclusion.incomplete_information"),
      value: "incomplete_information",
    },
    { label: t("Conclusion.false_information"), value: "false_information" },
    {
      label: t("Conclusion.insufficient_evidence_found"),
      value: "insufficient_evidence_found",
    },
    { label: t("Conclusion.another"), value: "another" },
  ];
  // Select unfounded cause(s)
  const unfoundedCausesChange = (e) => {
    console.log(e.target);
    const { name, checked } = e.target;
    setCause({ ...cause, [name]: checked });
    // Clean state if 'other' is unchecked
    console.log(checked);
    if (name === "another" && !checked) {
      setAnother_cause("");
      setError(false);
    } else {
      setError(true);
    }
    console.log(cause);
  };
  // Select unfounded options
  const unfoundedCheckedOptions = unfoundedOptions.map((option) => ({
    ...option,
    checked: cause[option.value] || false,
  }));
  // Enter another cause input
  const otherCauseChange = (e) => {
    console.log(e.target);
    setAnother_cause(e.target.value);
    if (cause.another && e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  // -----> Inadmisible Report <-----//
  const inadmissibleCausesOptions = [
    {
      label: t("Conclusion.not_code_ethics_issue"),
      value: "not_violaion_ethics_code",
    },
    { label: t("Conclusion.irrelevant_report"), value: "irrelevant_report" },
    {
      label: t("Conclusion.does_not_apply_organization"),
      value: "doesnt_apply_organization",
    },
    { label: t("Conclusion.another"), value: "another" },
  ];
  // Select inadmissible cause
  const inadmissibleCauseChange = (e) => {
    const { name, checked } = e.target;
    if (name !== "another") {
      setAnother_cause("");
      setError(false);
    } else {
      setError(true);
    }
    setCause({ [name]: checked });
  };
  // Event "Will it be followed up in another instance?"
  const followupChange = (e) => {
    const { checked } = e.target;
    setFollowup(checked);
    if (!checked) {
      setFollowupDetails("");
    }
  };
  // Sets value of follow details
  const followupDetailsChange = (e) => {
    const { value } = e.target;
    setFollowupDetails(value);
  };
  // Prevents the status from remaining active when the option is changed
  useEffect(() => {
    // console.log(closed)
    // console.log(followup)
    if (closed !== "unfounded" && closed !== "inadmissible") {
      setCause({});
      setAnother_cause("");
    }
    if (closed !== "founded" && closed !== "inadmissible") {
      setFollowup(false);
      setFollowupDetails("");
    }
    if (
      (followup && followupDetails.trim() === "") ||
      (followup && !followupDetails)
    ) {
      setErrorFollowup(true);
    } else {
      setErrorFollowup(false);
    }
    if (cause?.another && another_cause.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  }, [closed, followup, followupDetails, error]);
  // Status report type
  const kindOfClosedOptions = [
    {
      label: t("Conclusion.closed_founded"),
      value: "founded",
    },
    {
      label: t("Conclusion.closed_unfounded"),
      value: "unfounded",
    },
    { label: t("Conclusion.inadmissible_report"), value: "inadmissible" },
    {
      label: t("Conclusion.closed_undetermined"),
      value: "undefined",
    },
  ];
  // Send status report data
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (closed !== "unfounded" && closed !== "inadmissible") {
      setCause({});
      setAnother_cause("");
    }
    if (closed !== "founded" && closed !== "inadmissible") {
      setFollowup(false);
      setFollowupDetails("");
    }
    if (
      (followup && followupDetails.trim() === "") ||
      (followup && !followupDetails)
    ) {
      setErrorFollowup(true);
    } else {
      setErrorFollowup(false);
    }
    if (cause?.another && another_cause.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }

    console.log(error);
    if (error || errorFollowup) {
      console.log(errorFollowup);
      enqueueSnackbar(`${t("Conclusion.complete_requered_fields")} *`, {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    setLoading(true);
    let files_id;
    let filesToSend = [];
    enqueueSnackbar(`${t("Conclusion.sending")}...`, { variant: "info" });
    try {
      if (files.length > 0) {
        for (const fileObj of files) {
          const { formData } = fileObj;
          const filesRes = await createAttachment(formData);
          files_id = filesRes.attachments_id;
          filesToSend.push({
            file: files_id,
            name: fileObj.file.name,
          });
        }
      }
      // Add current files
      if (filesConclusion && filesConclusion.length > 0) {
        filesConclusion.forEach((file) => {
          filesToSend.push({
            file: file.file,
            name: file.name,
          });
        });
      }

      const details = {
        another_cause,
        followup,
        followupDetails,
        closed,
        close_date,
        cause,
        observations,
        files: filesToSend,
      };

      const resp = await updateStatusCloseDetails(id, details).then((resp) => {
        closeSnackbar();
        enqueueSnackbar(`${t("Conclusion.sending_successful")}`, {
          variant: "success",
        });
        setRerenderAll();
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="conclusion">
      <Accordion defaultActiveKey="0">
        {/* Details report closure */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="m-0 p-3">{t("Conclusion.closed_details")}</h5>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <MCLabel
                className="px-3 py-2 mt-1"
                text={`${t("Conclusion.determine_closure_date")}:`}
                htmlFor="scheduleCloseDate"
              />
              <MCInput
                className="px-3"
                id="scheduleCloseDate"
                type="date"
                selected={close_date}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setClose_date(date)}
              />
              <MCLabel
                text={`${t("Conclusion.observations_change_status")}:`}
                className="px-3 py-2 mt-1"
                htmlFor="detailsStatusChange"
              />
              <MCInput
                type="textarea"
                id="detailsStatusChange"
                placeholder={t("Conclusion.enter_details_report_closure")}
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
              <hr />
              <MCLabel
                className="px-3 py-2"
                text={`${t("Conclusion.select_type_closure_report")}:`}
                htmlFor="kindOfClosedOptions"
              />
              <MCSelect
                options={kindOfClosedOptions}
                id="kindOfClosedOptions"
                onChange={(e) => setClosed(e.value)}
                value={
                  kindOfClosedOptions.find(
                    (option) => option.value === closed
                  ) || null
                }
              />
              {closed === "inadmissible" && (
                <div className="px-3 py-0 mt-3">
                  <small>{`${t(
                    "Conclusion.select_inadmissible_cause"
                  )}:`}</small>
                  <ul className="mt-1">
                    {inadmissibleCausesOptions.map(
                      (inadmissibleCauseOption, i) => (
                        <li key={i} className="list-unstyled">
                          <MCRadio
                            id={inadmissibleCauseOption.value}
                            name={inadmissibleCauseOption.value}
                            checked={
                              cause[inadmissibleCauseOption.value] || false
                            }
                            className="mx-2"
                            onChange={inadmissibleCauseChange}
                          />
                          <MCLabel
                            htmlFor={inadmissibleCauseOption.value}
                            text={inadmissibleCauseOption.label}
                          />
                        </li>
                      )
                    )}
                  </ul>
                  {cause.another && (
                    <div className="px-3 py-0">
                      <MCLabel
                        text={`${t("Conclusion.specify")}:`}
                        htmlFor="specifyOtherClosedCause"
                        className="mb-2 mx-3"
                      />
                      <MCInput
                        id="specifyOtherClosedCause"
                        value={another_cause}
                        onChange={otherCauseChange}
                        className="mx-2"
                        isValid={!error}
                        placeholder={`${t(
                          "Conclusion.enter_new_inadmissible_cause"
                        )} *`}
                      />
                    </div>
                  )}
                </div>
              )}
              {/* Seguimiento en otra instancia? */}
              {(closed === "founded" || closed === "inadmissible") && (
                <div className="px-3 py-1 mt-3">
                  <MCCheckbox
                    id="followUpAnotherInstance"
                    checked={followup}
                    onChange={followupChange}
                    className="mx-2"
                  />
                  <MCLabel
                    text={`${t(
                      "Conclusion.this_report_will_followedup_another_instance"
                    )}`}
                    htmlFor="followUpAnotherInstance"
                  />
                </div>
              )}
              {/* Selección en caso de ser no fundamentado (unfounded) */}
              {closed === "unfounded" && (
                <div className="px-3 py-0 mt-3">
                  <MCLabel
                    text={`${t("Conclusion.select_cause_unfounded_closure")}:`}
                  />
                  <ul className="mt-1">
                    {unfoundedCheckedOptions.map((unfoundedOption, i) => (
                      <li key={i} className="list-unstyled">
                        <MCCheckbox
                          id={unfoundedOption.value}
                          name={unfoundedOption.value}
                          checked={cause[unfoundedOption.value] || false}
                          className="mx-2"
                          onChange={unfoundedCausesChange}
                        />
                        <MCLabel
                          text={unfoundedOption.label}
                          htmlFor={unfoundedOption.value}
                        />
                      </li>
                    ))}
                  </ul>
                  {/* Another closed cause unfounded? */}
                  {cause.another && (
                    <div className="px-3 py-0">
                      <MCLabel
                        text={`${t("Conclusion.specify")}:`}
                        htmlFor="specifyOtherClosedCause"
                        className="mb-2 mx-3"
                      />
                      <MCInput
                        id="specifyOtherClosedCause"
                        value={another_cause}
                        onChange={otherCauseChange}
                        className="mx-2"
                        isValid={!error}
                        placeholder={`${t(
                          "Conclusion.enter_new_cause_unfounded_closure"
                        )} *`}
                      />
                    </div>
                  )}
                </div>
              )}
              {followup && (
                <div className="px-4 mt-1">
                  <MCLabel
                    text={`${t("Conclusion.observations")}:`}
                    className="px-3 py-1"
                    htmlFor="detailsInFollowship"
                  />
                  <MCInput
                    id="detailsInFollowship"
                    className="mx-auto"
                    type="textarea"
                    placeholder={`${t(
                      "Conclusion.details_followup_report_another_instance"
                    )} *`}
                    value={followupDetails}
                    onChange={followupDetailsChange}
                    row={2}
                    isValid={!errorFollowup}
                  />
                </div>
              )}
              <hr />
              <FileUpload
                files={files}
                setFiles={setFiles}
                filesToRender={filesConclusion}
                setFilesToRender={setFilesConclusion}
              />
              <hr />
              <div className="d-flex align-items-center justify-content-end m-4">
                <MCButton
                  variant="success"
                  label={`${t("Conclusion.save")}`}
                  onClick={handleSubmit}
                  outline={loading}
                  disabled={loading}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
