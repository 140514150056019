import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import FlagsSelect from "react-flags-select";
import { configClient, updatedLocales } from "../../apis";
import { MCButton, MCInput } from "../MainComponents";
import _, { forEach } from "lodash";
import { Loading } from "../../components/Loading/String/Loading";
import { useTranslation } from "react-i18next";

const customCountryCodesInit = {
  en: "us",
  es: "mx",
  pt: "br",
};
const customCountryCodesBack = {
  US: "EN",
  MX: "ES",
  BR: "PT",
};

export const ModalLocales = ({
  objSelection,
  onHide,
  catalogue,
  path: initiPath,
  is_own,
  nestNum,
  rerenderParent,
}) => {
  const pathTemp = initiPath ? initiPath : "";
  const splitedArr = pathTemp.split("::");
  const slicedArr = splitedArr.slice(0, 2 * (nestNum + 1));
  const path = slicedArr.join("::");

  const [t] = useTranslation("catalogues");
  const { gTheme } = useSelector((state) => state.theme);
  const [lang, setLang] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentTags = [];
    const localesEntries = Object.entries(objSelection.locales);
    localesEntries.forEach((item) => {
      if (customCountryCodesInit[item[0]]) {
        currentTags.push({
          code: customCountryCodesInit[item[0]].toUpperCase(),
          value: item[1],
        });
      } else {
        currentTags.push({ code: item[0].toUpperCase(), value: item[1] });
      }
    });
    setTags(currentTags);
  }, []);

  useEffect(() => {
    configClient()
      .then((resp) => {
        const newLanguages = resp?.utilities?.list_languages.map((item) => {
          return {
            code: customCountryCodesInit[item.code] || item.code,
            name: item.name,
            value: item.value,
          };
        });
        setLang(newLanguages);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (toChange, value, index) => {
    const newLangs = _.cloneDeep(tags);
    if (toChange === "lang") {
      _.set(newLangs, `${index}.code`, value);
    } else if (toChange === "tag") {
      _.set(newLangs, `${index}.value`, value);
    }
    setTags(newLangs);
  };

  const handleAddTag = () => {
    setTags([...tags, { code: "", value: "" }]);
  };

  const handleDeleteTag = (index) => {
    const newTags = _.cloneDeep(tags);
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const locales = {};
    tags.forEach((item) => {
      if (item.code && item.value) {
        const finalKeyCode = customCountryCodesBack[item.code] || item.code;
        locales[finalKeyCode.toLowerCase()] = item.value;
      }
    });
    const data = {
      is_own,
      catalogue,
      path,
      locales,
    };
    // console.log(data)
    try {
      const result = await updatedLocales(data);
      if (result.message === "successfully updated") {
        onHide();
        rerenderParent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const returnAviableLanguages = (selected) => {
    const actualLangs = [];
    tags.forEach((item) => {
      actualLangs.push(item.code);
    });
    const newLangs = lang.map((item) => item.code.toUpperCase());
    const newLangsFiltred = newLangs.filter(
      (item) => !actualLangs.includes(item)
    );
    if (selected) {
      newLangsFiltred.push(selected);
    }
    return newLangsFiltred;
  };

  return (
    <div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`modalContainer ${gTheme !== "light" ? "dark-mode" : ""}`}
        animation={true}
        show={true}
        onHide={onHide}
      >
        <Modal.Header
          className={`${
            gTheme !== "light" ? "btn-close-white" : ""
          } d-flex flex-column align-items-start`}
          id="contained-modal-title-vcenter"
          closeButton
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Catalogues.configure_label")}
          </Modal.Title>
          <small className="fs-6 mt-10">
            {t("Catalogues.customize_label")}
          </small>
        </Modal.Header>
        {isLoading ? (
          <div className="p-3">
            <Loading />
          </div>
        ) : (
          <Modal.Body>
            {tags.map((item, index) => (
              <div key={index}>
                <div>
                  <label>{t("Catalogues.label")}* ?</label>
                  <span
                    className="ms-2"
                    style={{ color: "#de463d", cursor: "pointer" }}
                    onClick={() => handleDeleteTag(index)}
                  >
                    x
                  </span>
                </div>
                <div className="tag d-flex align-items-center mt-2 mb-3">
                  <FlagsSelect
                    customLabels={{
                      US: "English",
                      RU: "Русский",
                      FR: "Français",
                      DE: "Deutsch",
                      IT: "Italiano",
                      MX: "Español",
                      BR: "Português",
                    }}
                    countries={returnAviableLanguages(tags[index]?.code)} //Cambia el valor a uperCase
                    selected={tags[index]?.code} //EL valor del seleccionado
                    onSelect={(code) => handleChange("lang", code, index)}
                    fullWidth={false}
                    optionsSize={12}
                    selectedSize={12}
                    className="pb-0 me-2"
                    placeholder={t("Catalogues.select_lenguage")}
                  />
                  <MCInput
                    autoComplete="off"
                    className="form-select"
                    placeholder={t("Catalogues.enter_label_name")}
                    type="text"
                    name="text"
                    value={item?.value}
                    onChange={(e) => handleChange("tag", e.target.value, index)}
                  />
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-between mt-4">
              {tags.length < lang.length && (
                <div>
                  <MCButton
                    label={t("Catalogues.add_label")}
                    variant="primary"
                    outline
                    onClick={handleAddTag}
                  />
                </div>
              )}

              <div>
                <MCButton
                  label={t("Catalogues.cancel")}
                  variant="danger"
                  outline
                  className="me-1"
                  onClick={onHide}
                />
                <MCButton
                  label={t("Catalogues.save")}
                  variant="primary"
                  onClick={handleSave}
                />
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};
