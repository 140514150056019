import {useState} from 'react'
import { SearchIcon2 }  from "../Icon";
import './styles.scss';
import { getReportDetail } from '../../apis/apiReports';
const SearchBar = ({placeholder, onChange, onSubmit=()=>{}, ...rest}) => {
    const [search, setSearch] = useState({})
    const handleChange = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit();
    }
    // const filteredReports = reports.filter ((report) => {
    //     if(report.title.toUpperCase().inlcudes(search.toUpperCase())){
    //         return true;
    //     }
    //     return false;
    // })
    return (
        <div className="custom-search">
            <form className="searchsInputs w-100" onSubmit={handleSubmit}>
                <div className="searchIcon">
                    <SearchIcon2 
                    size={16}
                    />
                </div>
                <input 
                    type="search" 
                    placeholder={placeholder} 
                    id="header-search"
                    name='report'
                    autoComplete='off'
                    onChange={onChange || handleChange}
                    {...rest}
                />
            </form>
        </div>
    );
}

export default SearchBar;