import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { deleteElement } from "../../../apis/apiDashboard";
import "./indicators.scss";
import { useTranslation } from "react-i18next";


export const Indicators = ({ indicatorsData, stateLoading = null }) => {
  const [t] = useTranslation("dashboard");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme === "dark" ? "dark" : "light";

  // State de la lista de indicadores
  const [indicators, setIndicators] = useState([]);
  const [deleting, setDeleting] = useState("");

  useEffect(() => {
    setIndicators(Object.values(indicatorsData));
  }, []);

  // Eliminar un indicador
  const deleteIndicator = async (path) => {
    setDeleting(path);

    const response = await deleteElement({ path });
    if (stateLoading !== null && response !== undefined) stateLoading(true);
  };

  return (
    indicators.length > 0 && (
      <div className="dyTheme1 rounded indicators-content">
        <h3 className={`indicators-subtitle ${theme}`}>
          {t("indicators.title")}
        </h3>
        {indicators.map((indicatorItem, index) => (
          <div key={index} className={`indicator-body ${theme}`}>
            <div className={`indicator-header ${theme}`}>
              <div className="indicator-title">
                <h6>
                  {indicatorItem.path === deleting
                    ? `${t("indicators.deleting")}...`
                    : indicatorItem.title}
                </h6>
                <div className={`indicator-options ${theme}`}>
                  <DropdownButton
                    key="end"
                    id="dropdown-button-drop-end"
                    drop="end"
                    title="ooo"
                  >
                    {/* <Dropdown.Item eventKey="1">{t("indicators.change_range")}</Dropdown.Item>
                        <Dropdown.Divider /> */}
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => deleteIndicator(indicatorItem.path)}
                    >
                      {t("indicators.delete")}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <hr />
              <div className={`indicator-count ${theme}`}>
                <span>{indicatorItem.data[0].length}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  );
};
