import _ from "lodash";
import { Row, Col, Accordion } from "react-bootstrap";
import { patchInvestigation } from "../../../../../apis";

import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents";


import { MCEditTool } from "../../../../MainComponents/EditTool/EditTool";
import { useTranslation } from "react-i18next";
import NotFound from "../../../../GlobalComponents/NotFound";


import { useState } from "react";


export const Involucrados = ({
  investigationAlias,
  involved,
  general,
  toggleModal,
}) => {
  const [t] = useTranslation("investigation");

  const involvedTypeOpts = [
    {
      value: "accomplice",
      label: t("involved.accomplice"),
    },
    {
      value: "offender",
      label: t("involved.offender"),
    },
    {
      value: "victim",
      label: t("involved.victim"),
    },
    {
      value: "witness",
      label: t("involved.witness"),
    },
  ];

  const [involvedType, setInvolvedType] = useState(involvedTypeOpts[0]);

  const kind = (kind) => {
    if (kind === "offender") return t("involved.offender");
    if (kind === "witness") return t("involved.witness");
    if (kind === "victim") return t("involved.victim");
    if (kind === "accomplice") return t("involved.accomplice");
  };
  const entryway = (entry) => {
    if (entry === "phone") return "Phone";
    return "";
  };

  const [inputValues, setInputValues] = useState(involved);
  
  const [loading, setLoading] = useState(false);


  const handleEditClick = (e, isEditing) => {
    // Lógica para manejar el clic en el botón de editar
  };

  const saveChanges = async (updatedValues) => {
    setLoading(true);
    try {
        const objToSave = {
            folder: {
                involved: updatedValues,
            },
        };
        const resp = await patchInvestigation(investigationAlias, objToSave);
        console.log('Save successful:', resp);
    } catch (error) {
        console.error('Save failed:', error);
    } finally {
        setLoading(false);
    }
};

const handleSaveClick = async (key, index) => {
    await saveChanges(inputValues);
};


  const handleInputChange = (category, index, field) => (e) => {
    const newValues = { ...inputValues };
    newValues[category][index][field] = e.target.value;
    setInputValues(newValues);
  };

  const handleTypeChange = (category, index) => async (e) => {
    const newType = e.target.value;
    const newValues = { ...inputValues };
    const [movedItem] = newValues[category].splice(index, 1);
    movedItem.kind = newType;
    if (!newValues[newType]) {
        newValues[newType] = [];
    }
    newValues[newType].push(movedItem);
    setInputValues(newValues);
    await saveChanges(newValues);
};

  // const accordion = [
  //   {
  //     key: "offender",
  //     header: t("involved.offender_plural"),
  //     involved: involved?.offender,
  //   },
  //   {
  //     key: "witness",
  //     header: t("involved.witness_plural"),
  //     involved: involved?.witness,
  //   },
  //   {
  //     key: "victim",
  //     header: t("involved.victim_plural"),
  //     involved: involved?.victim,
  //   },
  //   {
  //     key: "accomplice",
  //     header: t("involved.accomplice_plural"),
  //     involved: involved?.accomplice,
  //   },
  // ];

  const accordion = [
    { key: "offender", header: t("involved.offender_plural"), involved: inputValues?.offender },
    { key: "witness", header: t("involved.witness_plural"), involved: inputValues?.witness },
    { key: "victim", header: t("involved.victim_plural"), involved: inputValues?.victim },
    { key: "accomplice", header: t("involved.accomplice_plural"), involved: inputValues?.accomplice },
  ];

  var have_one = [
    involved?.offender ? involved?.offender.length : 0,
    involved?.witness ? involved?.witness.length : 0,
    involved?.victim ? involved?.victim.length : 0,
    involved?.accomplice ? involved?.accomplice.length : 0,
  ];

  const total = have_one.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0); // 0 is the initial value of the accumulator

  const changeConfirmInvolved = async ({ value, key, index, prev }) => {
    if (value === prev) return;
    const newInvolved = _.cloneDeep(involved);
    _.set(newInvolved, `${key}.${index}.confirm`, value);
    const objToSave = {
      folder: {
        involved: newInvolved,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  //const [isEdit, setIsEdit] = useState(false);

  


  return (
    <div className="dyBackground research-contents">


      <div className="dyTheme1 dyBorder1 rounded p-20">
        <div className="pb-3 border-bottom">
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <div>
              <h5 className="m-0">{t("involved.involved")}</h5>
              <small>{t("involved.people_involved_investigation")}</small>
            </div>
            <MCButton
              label={t("involved.add_involved")}
              variant="primary"
              outline
              onClick={() => toggleModal("add-involved")}
            />
          </div>
        </div>
        <div>
          <h6 className="my-3">{t("involved.reporter_name")}</h6>
          <Row>
            <Col lg="4" md="12" className="p-0">
              <small>{t("involved.name")}:</small>
              <h6>{general?.reporter?.name || t("involved.anonymous")}</h6>
            </Col>
            <Col lg="4" md="12" className="p-0">
              <small>{t("involved.business_relationship")}:</small>
              <h6>
                {general?.reporter?.relationship || t("involved.undefined")}
              </h6>
            </Col>
            <Col lg="4" md="12" className="p-0">
              <small>{t("involved.entryway")}:</small>
              <h6>
                {entryway(general?.reporter?.entryway) ||
                  t("involved.undefined")}
              </h6>
            </Col>
          </Row>
        </div>
      </div>

      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">

        {total >= 1 ? (
          <Accordion defaultActiveKey={accordion.map((item, idx) => `${idx + 1}`)} alwaysOpen>
            {accordion.map((item, idx) => (
              <Accordion.Item eventKey={`${idx + 1}`} key={idx}>
                <Accordion.Header>{item.header}</Accordion.Header>
                {item?.involved && item.involved.map((item2, idx2) => (
                  <MCEditTool key={idx2} onClick={handleEditClick} onSave={() => handleSaveClick(item.key, idx2)} loading={loading}>
                    {(isEdit) => (
                      <Accordion.Body className="infractors" key={idx2}>
                        <div className="d-block d-lg-flex align-items-center justify-content-between">
                          <div className="my-2">
                            {!isEdit ?
                              <h6>{item2.name}</h6> :
                              <MCInput
                                type="text"
                                value={inputValues[item.key][idx2].name}
                                onChange={handleInputChange(item.key, idx2, "name")}
                              />
                            }
                          </div>
                          {!isEdit ?
                            <MCButton
                              label={kind(item2.kind)}
                              className="small-warning-btn"
                            /> :
                            <select
                              value={item2.kind}
                              onChange={handleTypeChange(item.key, idx2)}
                            >
                              <option value="offender">{ t("involved.offender_plural")}</option>
                              <option value="witness">{t("involved.witness_plural")}</option>
                              <option value="victim">{t("involved.victim_plural")}</option>
                              <option value="accomplice">{t("involved.accomplice_plural")}</option>
                            </select>
                          }
                        </div>
                        <div className="mt-20">
                          <span className="mt-20 mb-2">
                            <strong>{t("involved.area")}:</strong>&nbsp;
                            {!isEdit ?
                              item2.details :
                              <MCInput
                                type="text"
                                value={inputValues[item.key][idx2].details}
                                onChange={handleInputChange(item.key, idx2, "details")}
                              />
                            }
                          </span><br />
                          <span className="mt-20 mb-2">
                            <strong>{t("involved.position")}:</strong>&nbsp;
                            {!isEdit ?
                              item2.relationship :
                              <MCInput
                                type="text"
                                value={inputValues[item.key][idx2].relationship}
                                onChange={handleInputChange(item.key, idx2, "relationship")}
                              />
                            }
                          </span><br />
                          <span className="mt-20 mb-2">
                            <strong>{t("involved.employee_number")}:</strong>&nbsp;
                            {!isEdit ?
                              item2.additional :
                              <MCInput
                                type="text"
                                value={inputValues[item.key][idx2].additional}
                                onChange={handleInputChange(item.key, idx2, "additional")}
                              />
                            }
                          </span><br />

                          {!isEdit &&
                            <>
                              <span className="mt-20">
                                {t("involved.confirm_question")}
                              </span><br />

                              <div className="mt-20">
                                <MCButton
                                  label={t("involved.confirmed")}
                                  variant="primary"
                                  outline={!item2.confirm}
                                  size="lg"
                                  width={200}
                                  onClick={() => {

                                    changeConfirmInvolved({
                                      value: true,
                                      key: item.key,
                                      index: idx2,
                                      prev: item2.confirm,
                                    });

                                    const newValues = { ...inputValues };
                                    newValues[item.key][idx2].confirm = true;
                                    setInputValues(newValues);



                                  }}
                                  className="me-3"
                                >
                                  {t("involved.confirmed")}
                                </MCButton>
                                <MCButton
                                  label={t("involved.not_confirmed")}
                                  variant="primary"
                                  outline={item2.confirm}
                                  size="lg"
                                  width={200}
                                  onClick={() => {
                                    changeConfirmInvolved({
                                      value: false,
                                      key: item.key,
                                      index: idx2,
                                      prev: item2.confirm,
                                    })

                                    const newValues = { ...inputValues };
                                    newValues[item.key][idx2].confirm = false;
                                    setInputValues(newValues);

                                  }}
                                >
                                  {t("involved.not_confirmed")}
                                </MCButton>
                              </div>


                            </>
                          }
                        </div>
                      </Accordion.Body>
                    )}
                  </MCEditTool>
                ))}
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
