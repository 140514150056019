import React, { useState, useEffect } from "react";
import Clientes from "../../assets/images/login/Login_CaseManager/Login_CaseManager_Clientes.png";
import Indicadores from "../../assets/images/login/Login_CaseManager/Login_CaseManager_Indicadores.png";
import Modelo360 from "../../assets/images/login/Login_CaseManager/Login_CaseManager_Modelo_360.png";
import Seguridad from "../../assets/images/login/Login_CaseManager/Login_CaseManager_Seguridad.png";
import Icon from "../../components/Icon/Icon";
import "./Auth.scss";

const Carrousel = () => {
  //-----Array of Img-----//
  const images = [ Indicadores, Modelo360, Seguridad];
  const [selectedIndex, setSelectedIndex] = useState(0);

  //-----Previous and next selection of images-----//
  const selectNewImg = (index, next = true) => {
    const newIndex = next
      ? index < images.length - 1
        ? index + 1
        : 0
      : index > 0
      ? index - 1
      : images.length - 1;

    setSelectedIndex(newIndex);
  };

  //-----Time interval of transition-----//
  useEffect(() => {
    const interval = setInterval(() => {
      selectNewImg(selectedIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedIndex]);

  //-----Select previous index -----//
  const previous = () => {
    selectNewImg(selectedIndex, false);
  };
  //-----Select next index-----//
  const next = () => {
    selectNewImg(selectedIndex);
  };

  return (
    <div className="containerCarousel">
      <div className="carousel">
        <div className="carousel__control">
          <button onClick={previous} className="carousel__btn">
            <Icon name="bold_arrow_circle_left" />
          </button>
        </div>
        <div className="carousel__img">
          <img src={images[selectedIndex]} alt="MainImg" className="loaded" />
        </div>
        <div className="carousel__control">
          <button onClick={next} className="carousel__btn">
            <Icon name="bold_arrow_circle_right" />
          </button>
        </div>
      </div>
      <div className="carousel__footer">
        {images.map((image, i) => (
          <button
            key={i}
            onClick={() => {
              setSelectedIndex(i);
            }}
            className={selectedIndex === i ? "--active" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default Carrousel;
