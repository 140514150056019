import { setTheme, setSwalTheme, setThemeLS, setSnackBarTheme, setSize } from "./themeSlice";

export const startSetTheme = () => (dispatch, getState) => {
  const { gTheme } = getState().theme;
  const newTheme = gTheme === 'light' ? 'dark' : 'light';
  localStorage.setItem('theme', newTheme);
  startSetSwalTheme(newTheme, dispatch);
  startSetSnackBarTheme(newTheme, dispatch);
  dispatch( setTheme(newTheme) );
}

export const startSetThemeLS = () => (dispatch, getState) => {
  const themeLS = localStorage.getItem('theme') || null;
  const newTheme = (!themeLS) ? 'light'  : themeLS;
  localStorage.setItem('theme', newTheme);
  startSetSwalTheme(newTheme, dispatch);
  startSetSnackBarTheme(newTheme, dispatch);
  dispatch( setTheme(newTheme) );
}

export const startSetSize = (size) => (dispatch, getState) => {
  dispatch( setSize(size) );
}

const startSetSwalTheme = (newTheme, dispatch) => {
  let newSwalTheme;
  if (newTheme==="dark") {
    newSwalTheme = { 
      background: "#1a1d27", 
      color: "#b0bec5",
      confirmButtonColor: "#009ED7",
    }
    
  } else {
    newSwalTheme = { 
      background: "#ffffff", 
      color: "#000000",
      confirmButtonColor: "#009ED7",
    }
  }
  dispatch( setSwalTheme(newSwalTheme) );
}

const startSetSnackBarTheme = (newTheme, dispatch) => {
  let newSwalTheme;
  if (newTheme==="dark") {
    newSwalTheme = { 
      background: "#1a1d27", 
      color: "#b0bec5",
    }
    
  } else {
    newSwalTheme = { 
      background: "#ffffff", 
      color: "#000000",
    }
  }
  dispatch( setSnackBarTheme(newSwalTheme) );
}