import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { CDBCard, CDBCardBody, CDBBtn } from "cdbreact";
import { useTranslation } from "react-i18next";
import SimpleSelect from "../../components/SimpleSelect";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import PlanCard from "../Plans/PlanCard";
import Step from "../Auth/Step";
import { GeneralHeader } from "../../components/Title/GeneralHeader";
import "./styles.scss";

const SignPlan = () => {
  const [currency, setCurrency] = useState("MX");
  const [t] = useTranslation("settings");
  const plan = {
    name: t("SignPlan.basic_plan"),
    description:
      "<ul><li>Este plan incluye hasta 3 mil empleados</li><li>8$ Costo adicional por empleado</li></ul>",
    duration: `1 ${t("SignPlan.year")}`,
    price: "$0 pesos",
    features:
      "<ul><li>Sin posibilidad de requerir telefonia en un pais sin disponibilidad</li><li>3 paises</li><li>3 idiomas (solo disponibles)</li><li>1 subdominio</li><li>Soporte tecnico. Mesa de ayuda</li><li>Centros de trabajo ilimitados</li><li>Hasta 10 centros de trabajo</li></ul>",
    color: "#3181FF",
  };
  const plans = [
    { value: "1", text: `1 - ${t("plan.type")}` },
    { value: "2", text: "2 - Plan Basico - 1 Año" },
    { value: "3", text: "3 - Plan Standard - 1 Año" },
  ];
  const countries = [
    {
      value: "mx",
      label: "Mexico",
    },
    {
      value: "usa",
      label: "USA",
    },
  ];
  const languages = [
    {
      value: "en",
      label: "Ingles",
    },
    {
      value: "es",
      label: "Español",
    },
  ];
  const domains = [
    {
      value: "eg.com",
      text: "eg.com",
    },
    {
      value: "eg.mx",
      text: "eg.mx",
    },
  ];
  const handlePlanSelected = (p) => {};
  return (
    <>
      {/* <SingleNav /> */}
      <div className="plan-container">
        <CDBCard className="pb-3">
          <Step />
          <GeneralHeader
            title={t("SignPlan.select_your_plan")}
            // text="Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis bibendum felis sit amet fringilla."
          />
        </CDBCard>
        <div className="d-lg-flex d-block flex-row mt-4">
          <div id="plan-left-card">
            <div className="d-flex flex-column">
              <CDBCard>
                <CDBCardBody className="p-30">
                  <SimpleSelect
                    label=""
                    options={plans}
                    onChange={handlePlanSelected}
                  />
                </CDBCardBody>
              </CDBCard>
              <CDBCard className="mt-4 py-3">
                <CDBCardBody className="p-30">
                  <div className="d-flex flex-column">
                    <h5 className="mb-4">
                      <b>{t("SignPlan.configure_plan")}</b>
                    </h5>
                    {/* <Select label={t("plan.addCountry")} options={countries} placeholder="Seleccione un pais"/> */}
                    <br />
                    {/* <Select label={t("plan.lang")} options={languages} placeholder="Seleccione un idioma"/> */}
                    <br />
                    <small>{`${t("SignPlan.phones")}:`}</small>
                    <CustomPhoneInput label={t("SignPlan.phones")} />
                    <br />
                    <div className="d-block d-sm-flex flex-row align-items-end">
                      <div className="w-100 pr-sm-3">
                        {/* <InputText
                          label={t("SignPlan.account_name")}
                          type="text"
                          placeholder=""
                          required={true}
                        /> */}
                      </div>
                      <div className="w-100 mt-20 pl-sm-3">
                        <SimpleSelect
                          label=""
                          options={domains}
                          onChange={(e) => console.log(e)}
                        />
                      </div>
                    </div>
                    <div className="d-block d-sm-flex flex-row align-items-end">
                      <div className="w-100 pr-sm-3 mt-20">
                        {/* <InputText
                          label={t("SignPlan.domains")}
                          type="text"
                          placeholder=""
                          required={true}
                        /> */}
                      </div>
                      <div className="w-100 mt-20 pl-sm-3">
                        <SimpleSelect
                          label=""
                          options={domains}
                          onChange={(e) => console.log(e)}
                        />
                      </div>
                    </div>
                    <p>
                      <label>
                        {t("SignPlan.domains_available_during")} 24 hrs.
                      </label>
                    </p>
                    <CDBBtn
                      block={false}
                      disabled={false}
                      className="athics-btn mt-20"
                    >
                      {t("SignPlan.select_plan")}
                    </CDBBtn>
                  </div>
                </CDBCardBody>
              </CDBCard>
            </div>
          </div>
          <div id="plan-right-card">
            <div className="d-flex flex-column">
              <CDBCard>
                <CDBCardBody className="p-30">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row-reverse">
                      <ReactFlagsSelect
                        selected={currency}
                        onSelect={(code) => {
                          setCurrency(code);
                        }}
                        placeholder={t("SignPlan.currency")}
                        selectButtonClassName="dropdown-flags"
                        countries={["US", "MX"]}
                        customLabels={{
                          US: `${t("SignPlan.us_dollar")} (USD)`,
                          MX: `${t("SignPlan.mexican_peso")} (MXN)`,
                        }}
                        className="mb-20"
                      />
                    </div>
                    <PlanCard plan={plan} headerColor={"#7C4DFF"} />
                  </div>
                </CDBCardBody>
              </CDBCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignPlan;
