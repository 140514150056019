import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CalendarSearch } from "../../Icon";
import "./EGDatePicker.scss";

const EGDatePicker = ({
  label = "Select Date",
  value,
  maxDate,
  minDate,
  onChange,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        formatDensity="dense"
        className="input-date-picker"
        slotProps={{
          textField: {
            fullWidth: true,
            InputProps: {
              sx: {
                "& .MuiIconButton-root": {
                  margin: "0 auto",
                },
              },
            },
          },
        }}
        format="DD/MM/YYYY"
        slots={{ openPickerIcon: CalendarSearch }}
        maxDate={maxDate}
        minDate={minDate}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default EGDatePicker;
