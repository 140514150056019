import { Modal } from "react-bootstrap";
import Icon from "../../Icon/Icon";
import { PreviewForm } from "../PreviewForm/PreviewForm";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ModalPreview = ({
  formIdentifier,
  steps,
  schemaState,
  setSchemaState,
  formData,
  setFormData,
  isValid,
  setIsValid,
  constraints,
  setConstraints,
  isLoading,
  show,
  onHide,
  gTheme,
}) => {
  const [t] = useTranslation("forms");
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    setRerender(true);
  }, [show]);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  return (
    <>
      {!rerender && (
        <Modal
          show={show}
          onHide={onHide}
          size="xl"
          className={`${gTheme !== "light" ? "dark-mode" : ""}`}
          centered
        >
          <Modal.Header
            closeButton
            className={`${
              gTheme !== "light" ? "btn-close-white" : ""
            } border-0`}
          />
          <div className="modal-preview-container">
            <div className="modal-preview-header">
              <div className="header-info">
                <div className="header-title">
                  {/* <div className="header-arrow-left pointer" onClick={onHide}>
                    <Icon name="arrow_left_3" />
                  </div> */}
                  <h4>{t("modalPreview.form_preview")}</h4>
                </div>
                <div className="header-description">
                  {t("modalPreview.form_preview_and_steps")}
                </div>
              </div>
              <div className="header-route">
                {t("modalPreview.forms")} {">"}{" "}
                <span className="font-weight-bold">
                  {t("modalPreview.form_preview")}
                </span>
              </div>
            </div>
            {!isLoading && (
              <PreviewForm
                formIdentifier={formIdentifier}
                steps={steps}
                schemaState={schemaState}
                setSchemaState={setSchemaState}
                formData={formData}
                setFormData={setFormData}
                isValid={isValid}
                constraints={constraints}
                setConstraints={setConstraints}
                setIsValid={setIsValid}
                showButtons={false}
                stepClick={true}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
