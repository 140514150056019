import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { Row, Col, Tab, Nav, Modal } from "react-bootstrap";
import {
  createInvestigation,
  deleteInvestigationAssign,
  getInvestigation,
} from "../../../../../apis";
import { BoldFolder, OutlineData, OutlineHierarchy } from "../../../../Icon";
import { Loading } from "../../../../Loading/String/Loading";
import NotFound from "../../../../GlobalComponents/NotFound";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { Datos } from "../Datos/Datos";
import { Usuarios } from "../Usuarios/Usuarios";
import { Recursos } from "../Recursos/Recursos";
import { Involucrados } from "../Involucrados/Involucrados";
import { Plan } from "../Plan/Plan";
import { Entrevistas } from "../Entrevista/Entrevistas";
import { Evidencias } from "../Evidencia/Evidencias";
import {
  EditGeneral,
  EditScenario,
  EditDates,
  EditCauses,
  AddScenario,
  AddResource,
  AddInvolved,
  AddUser,
  AddSimpleInvModal,
  AddCause,
} from "../EditForms";
import { Conclusion } from "../Conclusion/Conclusion";
import { AddPlan } from "../EditForms/AddPlan";
import { useTranslation } from "react-i18next";
import { parseDateToDDMMYYYY2 } from "../../../../../helpers/dateTransform";
import { useSnackbar } from "notistack";
import { getSafeParsedItem, initialCurrrentReprotLS } from "../../ReportViewer";
import { Scenarios } from "../Scenarios/Scenarios";
import { Deadline } from "../Deadline/Deadline";
import { Causes } from "../Causes/Causes";
import "./InvestigationPage.scss";

const checkInvestigationExist = (invAlias = "", invList = []) => {
  if (Array.isArray(invList) && invList?.length === 0) return null;
  if (invList.length === 1) return invList[0];
  if (invList.length > 1) {
    const invFinded = invList.find(item=>item.alias===invAlias);
    if (invFinded) {
      return invFinded;
    } else {
      return invList[0];
    }
  }
}

//--- Section with the detail of reports raised ---//
export const InvestigationPage = ({
  id,
  investigationList = [],
  setRerenderAll,
}) => {
  // Get report information on LS
  const currentReportLS = getSafeParsedItem("current-report") ?? initialCurrrentReprotLS(id);
  // Custom Hooks
  const [t] = useTranslation("investigation");
  const { gTheme, swalTheme } = useSelector((state) => state.theme);
  const { enqueueSnackbar } = useSnackbar();
  // Initial State
  const [investigationAliasLS, setinvestigationAliasLS] = useState(
    investigationList[0]
  );
  const [selectedInvestigation, setSelectedInvestigation] = useState(checkInvestigationExist(currentReportLS["inv-alias"], investigationList));
  const [activeFolder, setActiveFolder] = useState(currentReportLS["inv-folder"] ?? "Datos");
  const [investigationAlias, setInvestigationAlias] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [modalParams, setModalParams] = useState({});
  const [investigation, setInvestigation] = useState({});
  const [show, setShow] = useState(false);
  // Advance Investigation Menu
  const advInvFolders = [
    {
      title: t("folder.general"),
      key: "Datos",
    },
    { title: t("folder.locations_scenarios"), key: "Escenarios" },
    {
      title: t("folder.deadline"),
      key: "Deadline",
    },
    { title: t("folder.causes_of_grievance"), key: "Causas" },
    {
      title: t("folder.resources"),
      key: "Recursos",
    },
    // {
    //   title: t("folder.assigned_users"),
    //   key: "Usuarios",
    // },
    {
      title: t("folder.involved"),
      key: "Involucrados",
    },
    {
      title: t("folder.activities_plan"),
      key: "Plan",
    },
    {
      title: t("folder.interviews"),
      key: "Entrevistas",
    },
    {
      title: t("folder.evidence"),
      key: "Evidencias",
    },
    {
      title: t("folder.conclusion"),
      key: "Conclusion",
    },
  ];
  // Simple Investigation Menu
  const simpInvFolders = [
    { title: t("folder.general"), key: "Datos" },
    { title: t("folder.locations_scenarios"), key: "Escenarios" },
    {
      title: t("folder.deadline"),
      key: "Deadline",
    },
    { title: t("folder.causes_of_grievance"), key: "Causas" },
    { title: t("folder.involved"), key: "Involucrados" },
    { title: t("folder.conclusion"), key: "Conclusion" },
  ];
  //--- Defines the index option selected in the reports dropList (Select)---//
  const getInvType = (advance, created_at) => {
    if (advance) {
      return `${parseDateToDDMMYYYY2(created_at)} -${t(
        "investigation.advanced"
      )}`;
    } else {
      return `${parseDateToDDMMYYYY2(created_at)} -${t("investigation.basic")}`;
    }
  };
  // Initial active tabs
  const investigationFolderLS = "Datos"; // localStorage.getItem("investigation-folder");

  useEffect(() => {
    if (rerender) {
      setRerender(false);
      setIsLoading(true);
    }
  }, [rerender]);

  useEffect(() => {
    // if (selectedInvestigation) {
    //   setinvestigationAliasLS();
    // }
    const onMounted = async () => {
      if (investigationList.length > 0) {
        setInvestigationAlias(selectedInvestigation.alias);
        const newCurrentReportLS = { ...currentReportLS, "inv-alias": selectedInvestigation.alias };
        localStorage.setItem("current-report", JSON.stringify(newCurrentReportLS));
        getInvestigation(selectedInvestigation.alias)
          .then((investigation) => {
            if (!investigation) return;
            setInvestigation({
              ...investigation?.response,
            });
            setIsLoading(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            console.log(err);
          });
      } else {
        setIsLoading(false);
      }
    };
    if (!rerender) {
      setIsLoading(true);
      onMounted();
    }
  }, [selectedInvestigation, rerender]);

  // => Cambiar el modal de agregar o editar y hacer rerender si se actualizo algo
  const toggleModal = (modal, params) => {
    if (modal === false) {
      setShowModals(false);
      setRerender(true);
    } else if (modal === "just-close") {
      setShowModals(false);
    } else {
      setSelectedModal(modal);
      setModalParams(params);
      setShowModals(true);
    }
  };

  // => Setear el modal con el que se esta trabajando
  const setEditModal = (modal) => {
    if (modal === "general")
      return (
        <EditGeneral
          investigationAlias={investigationAlias}
          formValues={investigation.general}
          toggleModal={toggleModal}
        />
      );
    if (modal === "scenarios")
      return (
        <EditScenario
          investigationAlias={investigationAlias}
          formValues={investigation.scenarios}
          toggleModal={toggleModal}
          modalParams={modalParams}
        />
      );
    if (modal === "add-scenario")
      return (
        <AddScenario
          investigationAlias={investigationAlias}
          formValues={investigation.scenarios}
          toggleModal={toggleModal}
        />
      );
    if (modal === "dates")
      return (
        <EditDates
          investigationAlias={investigationAlias}
          formValues={investigation.dates}
          toggleModal={toggleModal}
        />
      );
    if (modal === "causes")
      return (
        <EditCauses
          investigationAlias={investigationAlias}
          formValues={investigation.causes}
          toggleModal={toggleModal}
        />
      );
    if (modal === "add-resource")
      return (
        <AddResource
          investigationAlias={investigationAlias}
          formValues={investigation.resources}
          toggleModal={toggleModal}
        />
      );
    if (modal === "add-involved")
      return (
        <AddInvolved
          investigationAlias={investigationAlias}
          formValues={investigation.involved}
          toggleModal={toggleModal}
          setRerenderAll={setRerenderAll}
        />
      );
    if (modal === "add-plan")
      return (
        <AddPlan
          investigationAlias={investigationAlias}
          formValues={investigation.plans}
          toggleModal={toggleModal}
        />
      );
    if (modal === "add-user")
      return (
        <AddUser
          investigationAlias={investigationAlias}
          formValues={investigation.users}
          toggleModal={toggleModal}
        />
      );
    if (modal === "add-cause")
      return (
        <AddCause
          investigationAlias={investigationAlias}
          formValues={investigation.causes}
          toggleModal={toggleModal}
        />
      );
    if (modal === "add-newInvestigation")
      return (
        <AddSimpleInvModal
          investigationAlias={investigationAlias}
          additionalInv={additionalInv}
          toggleModal={toggleModal}
        />
      );
    return <></>;
  };

  // => Crear investigación avanzada y/o simple
  const additionalInv = async (type) => {
    try {
      const resp = await createInvestigation(id, type).then(() =>
        setRerenderAll()
      );
    } catch (error) {
      console.error(error);
    }
  };

  // => Borra (desvincula del reporte) una investigacion
  const handleUnlinkInvestigation = async () => {
    Swal.fire({
      title: t("investigation.are_re_want_unlink_investigation"),
      text: t("investigation.wont_able_see_investigation_info"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: t("investigation.unlink"),
      cancelButtonText: t("investigation.cancel"),
      ...swalTheme,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await deleteInvestigationAssign(
            investigationAlias,
            id
          );
          if (result.error) throw new Error();
          setRerenderAll();
          Swal.fire({
            title: t("investigation.investigation_unlinked_success"),
            icon: "success",
            ...swalTheme,
          });
        } catch (error) {
          Swal.fire({
            title: t("investigation.error_unlinking"),
            text: t("investigation.error_unlinking_investigation_from_report"),
            icon: "error",
            ...swalTheme,
          });
        }
      }
    });
  };

  const getSubtitle = (key, t) => {
    const getCountText = (count, singular, plural) => {
      if (count === 0) {
        return t(plural);
      } else if (count === 1) {
        return t(singular);
      } else {
        return t(plural);
      }
    };
    if (key === "Datos") {
      const causes = investigation.causes;
      if (!causes) return t("folder.Datos.no_causes");
      const cant = causes.length;
      return `${cant} ${getCountText(
        cant,
        "folder.Datos.cause_registered",
        "folder.Datos.causes_registered"
      )}`;
    }
    if (key === "Recursos") {
      const resources = investigation.resources;
      if (!resources) return t("folder.Recursos.no_resources");
      const cant = Object.values(resources).flat().length;
      return `${cant} ${getCountText(
        cant,
        "folder.Recursos.resource_available",
        "folder.Recursos.resources_available"
      )}`;
    }
    if (key === "Usuarios") {
      const users = investigation.users;
      if (!users) return t("folder.Usuarios.no_users");
      const cant = Object.values(users).flat().length;
      return `${cant} ${getCountText(
        cant,
        "folder.Usuarios.user_assigned",
        "folder.Usuarios.users_assigned"
      )}`;
    }
    if (key === "Involucrados") {
      const involved = investigation.involved;
      if (!involved) return t("folder.Involucrados.no_involved");
      const cant = Object.values(involved).flat().length;
      return `${cant} ${getCountText(
        cant,
        "folder.Involucrados.involved",
        "folder.Involucrados.involveds"
      )}`;
    }
    if (key === "Plan") {
      const plans = investigation.plans;
      if (!plans) return t("folder.Plan.no_plans");
      const cant = plans.length;
      return `${cant} ${getCountText(
        cant,
        "folder.Plan.activity_plan",
        "folder.Plan.activity_plans"
      )}`;
    }
    if (key === "Entrevistas") {
      const interviews = investigation.interviews;
      if (!interviews) return t("folder.Entrevistas.no_interviews");
      const cant = interviews.length;
      return `${cant} ${getCountText(
        cant,
        "folder.Entrevistas.interview",
        "folder.Entrevistas.interviews"
      )}`;
    }
    if (key === "Evidencias") {
      const evidences = investigation.evidences;
      if (!evidences) return t("folder.Evidencias.no_evidences");
      const cant = evidences.length;
      return `${cant} ${getCountText(
        cant,
        "folder.Evidencias.evidence",
        "folder.Evidencias.evidences"
      )}`;
    }
    if (key === "Conclusion") {
      const conclusion = investigation.conclusion;
      if (!conclusion) return t("folder.Conclusion.no_conclusions");
    }
    return "";
  };
  // </> Se muestra este componente cuando no hay ninguna investigacion
  if (investigationList.length === 0) {
    return (
      <Row>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="xl"
          className={`${gTheme !== "light" ? "dark-mode" : ""}`}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            style={{ border: "none", padding: 0 }}
            closeButton
            className={`${gTheme !== "light" ? "btn-close-white" : ""}`}
          />
          <AddSimpleInvModal
            toggleModal={() => setShow(false)}
            additionalInv={additionalInv}
          />
        </Modal>
        <Col className="dyBackground p-0">
          <div className="dyTheme1 rounded dyBorder1 d-flex flex-column justify-content-center align-items-center p-20 my-20">
            <h4>{t("investigation.report_dont_have_investigation")}</h4>
            <NotFound />
            <MCButton
              label={t("investigation.create_investigation")}
              variant="primary"
              outline
              className="me-1 my-2"
              onClick={() => setShow(true)}
            />
          </div>
        </Col>
      </Row>
    );
  }

  // </> Investigacion
  return (
    <div className="dyBackground research-folder border-0 pl-0">
      <Modal
        className={`${gTheme !== "light" ? "dark-mode" : ""}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModals}
        onHide={() => setShowModals(false)}
        size="xl"
      >
        <Modal.Header
          className={`modalHeader ${
            gTheme !== "light" ? "btn-close-white" : ""
          }`}
          closeButton
        />
        {setEditModal(selectedModal)}
      </Modal>

      <Tab.Container
        defaultActiveKey={activeFolder}
        className="investigation-folder"
      >
        <Row>
          <Col lg={4} className="p-0 selectInvestigation">
            {investigationList.length > 1 && (
              <div className="dyTheme1 dyBorder1 me-lg-4 p-20 mt-20 rounded">
                <h6 className="mb-3">
                  {t("folder.select_another_investigation")}:
                </h6>
                <MCSelect
                  options={investigationList}
                  value={selectedInvestigation}
                  onChange={setSelectedInvestigation}
                  formatOptionLabel={({ advance, created_at }) =>
                    advance ? (
                      <small className="selectInvestigation__option">
                        <OutlineData className="--advance" />{" "}
                        {getInvType(advance, created_at)}
                      </small>
                    ) : (
                      <small className="selectInvestigation__option">
                        <OutlineHierarchy className="--basic" />{" "}
                        {getInvType(advance, created_at)}
                      </small>
                    )
                  }
                />
              </div>
            )}
            {!investigation.advance ? (
              <div className="dyTheme1 dyBorder1 me-lg-4 p-20 mt-20 rounded">
                <div className="d-flex align-items-center">
                  <OutlineHierarchy className="--basic" />{" "}
                  <h6 className="mx-2 my-auto">
                    {t("folder.basic_investigation_folder")}:
                  </h6>
                </div>
                <Nav className="flex-lg-column flex-row flex-nowrap">
                  {simpInvFolders.map((item, i) => (
                    <Nav.Item key={i}>
                      <Nav.Link eventKey={item.key}>
                        <div className="folder-green">
                          <BoldFolder />
                          <div className="ms-2">
                            <h6 className="my-auto">{item.title}</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            ) : (
              <div className="dyTheme1 dyBorder1 me-lg-4 p-20 mt-20 rounded">
                <div className="d-flex align-items-center">
                  <OutlineData className="--advance" />{" "}
                  <h6 className="mx-2 my-auto">
                    {t("folder.investigation_folder")}:
                  </h6>
                </div>
                <Nav className="flex-lg-column flex-row flex-nowrap">
                  {advInvFolders.map((item, i) => {
                    return (
                      <Nav.Item key={i}>
                        <Nav.Link eventKey={item.key}>
                          <div
                            className="folder-block"
                            onClick={() => {
                              setActiveFolder(item.key);
                              const currentReportLS = getSafeParsedItem("current-report") ?? initialCurrrentReprotLS(id);
                              localStorage.setItem("current-report", JSON.stringify({ ...currentReportLS, "inv-folder": item.key }));
                            }}
                          >
                            <BoldFolder />
                            <div className="ms-2">
                              <h6 className="mb-0">{item.title}</h6>
                              <small>{getSubtitle(item.key, t)}</small>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </div>
            )}
          </Col>
          <Col lg={8} className="p-0">
            <div className="ml-lg-4 mt-20">
              {isLoading ? (
                <Loading />
              ) : (
                <Tab.Content>
                  <Tab.Pane eventKey="Datos">
                    <Datos
                      general={investigation?.general}
                      scenarios={investigation?.scenarios || []}
                      dates={investigation?.dates}
                      causes={investigation?.causes || []}
                      toggleModal={toggleModal}
                      investigationAlias={investigationAlias}
                      handleUnlinkInvestigation={handleUnlinkInvestigation}
                      id={id}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Escenarios">
                    <Scenarios
                      scenarios={investigation?.scenarios}
                      toggleModal={toggleModal}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Deadline">
                    <Deadline
                      toggleModal={toggleModal}
                      dates={investigation?.dates}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Causas">
                    <Causes
                      toggleModal={toggleModal}
                      investigation={investigation}
                      investigationAlias={investigationAlias}
                      id={id}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Recursos">
                    <Recursos
                      resources={investigation?.resources}
                      toggleModal={toggleModal}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Usuarios">
                    <Usuarios
                      investigationAlias={investigationAlias}
                      users={investigation?.users}
                      general={investigation?.general}
                      toggleModal={toggleModal}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Involucrados">
                    <Involucrados
                      investigationAlias={investigationAlias}
                      involved={investigation?.involved}
                      general={investigation?.general}
                      toggleModal={toggleModal}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Plan">
                    <Plan
                      investigationAlias={investigationAlias}
                      plans={investigation?.plans || []}
                      toggleModal={toggleModal}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Entrevistas">
                    <Entrevistas
                      investigationAlias={investigationAlias}
                      interviews={investigation?.interviews || []}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Evidencias">
                    <Evidencias
                      investigationAlias={investigationAlias}
                      evidences={investigation?.evidences || []}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Conclusion">
                    <Conclusion
                      investigationAlias={investigationAlias}
                      investigation={investigation}
                      toggleModal={toggleModal}
                      id={id}
                      setRerender={setRerender}
                      handleUnlinkInvestigation={handleUnlinkInvestigation}
                    />
                  </Tab.Pane>
                </Tab.Content>
              )}
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};
