import { useNavigate } from "react-router";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { MyCatalogues } from "./MyCatalogues";
import { useTranslation } from "react-i18next";
import { MCButton } from "../MainComponents";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";
import "./Catalogues.scss";
import { Outline3dSquare } from "../Icon";

export const Catalogues = () => {
  const [t] = useTranslation("catalogues");
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: t("Catalogues.home"), route: "/" },
    { label: t("Catalogues.settings"), route: "/settings" },
    { label: t("Catalogues.configure_catalogs") },
  ];

  return (
    <div className={`catalogues-page page`}>
      {/* Custom catalogue header */}
      <div>
        <div className={`general-header dyTheme1 dyBorder1 rounded`}>
          <Row>
            <Col
              lg="8"
              className="p-0 d-flex flex-row justify-content-space-around"
            >
              <div className="d-flex align-items-center">
                <span>
                  <Outline3dSquare float="left" width="48" height="48" />
                </span>
              </div>
              <div className="mx-3">
                <h3 className="mb-0 general-header-title">
                  {t("Catalogues.configure_catalogs")}
                </h3>
                <p className="mb-0 font-14">
                  {t("Catalogues.create_edit_catalogs")}
                </p>
              </div>
            </Col>
            <Col
              lg="4"
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              <MCButton
                onClick={() => navigate("/catalogues/new")}
                label={t("Catalogues.create_catalog")}
                variant="primary"
                outline
                width={150}
              />
            </Col>
          </Row>
        </div>
        <div className="my-3">
          <Breadcrum items={breadcrumbItems} />
        </div>
      </div>
      {/* Component for show catalogues things, like a list of them or preview and edit single one  */}
      <MyCatalogues />
    </div>
  );
};
