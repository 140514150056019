import { useTranslation } from "react-i18next";

const PaymentCard = (props) => {
  const [t] = useTranslation("");
  return (
    <div
      style={{
        height: "auto",
        width: props.containerWidth ? props.containerWidth : "100%",
        borderRadius: 6,
        border: "1px solid #e3ebf5",
        marginTop: 20,
      }}
    >
      <div className="d-flex flex-column">
        <div
          style={{
            borderLeft: `5px solid ${props.headerColor}`,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          className="p-30"
        >
          <div className="d-block d-sm-flex flex-row text-left">
            <div className="w-100">
              <small className="font-14">{t("PaymentCard.plan_type")}:</small>
              <span style={{ fontSize: 22, fontWeight: "bold" }}>
                {props.plan.name}
              </span>
            </div>
            <div className="w-100 text-sm-right">
              <span style={{ fontSize: 12 }}>Subtotal:</span>
              <br />
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                $ {props.plan.subtotal} {props.plan.currency}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid #e3ebf5",
            flexDirection: "column",
          }}
          className="p-30"
        >
          <div className="text-sm-right" style={{ marginRight: 10 }}>
            <span style={{ fontSize: 12 }}>{t("PaymentCard.taxes_fees")}</span>
            <br />
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              <b>
                $ {props.plan.taxes} {props.plan.currency}
              </b>
            </span>
          </div>

          <div
            className="boxTotal d-block text-left d-sm-flex flex-row"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <div className="w-100">
              <h3 className="m-0" style={{ textTransform: "uppercase" }}>
                Total
              </h3>
            </div>
            <div className="w-100 text-sm-right">
              <span style={{ fontSize: 24, fontWeight: "bold" }}>
                $ {props.plan.total} {props.plan.currency}
              </span>
            </div>
          </div>

          <div className="d-block d-sm-flex text-left align-items-center flex-row">
            <div className="w-100">
              <span style={{ fontSize: 12, padding: 10 }}>
                * {t("PaymentCard.can_review_confirm_order")}
              </span>
            </div>
            <div className="w-100 text-sm-right">
              <span style={{ fontSize: 12, padding: 10 }}>
                {t("PaymentCard.secure_payment")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
