import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { buildData } from "../Utils/chartTransformData";
import "./pieChart.scss";

export const PieChart = ({ chartData }) => {
  const [data, setData] = useState(null);
  const [isPercentage, setIsPercentage] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    const isPercentageData = chartData.get_data !== "count";
    setIsPercentage(isPercentageData);
    const dataTransformed = buildData({
      chartData,
      isPercentage: isPercentageData,
      type: "pie",
      title: chartData.title,
    });
    
    // Filtrar los datos para excluir valores 0
    const filteredData = filterZeroValues(dataTransformed.data);
    setData(filteredData);
  }, [chartData]);

  const filterZeroValues = (originalData) => {
    const nonZeroIndices = originalData.datasets[0].data.reduce((acc, value, index) => {
      if (parseValue(value) !== 0) {
        acc.push(index);
      }
      return acc;
    }, []);

    return {
      labels: nonZeroIndices.map(index => originalData.labels[index]),
      datasets: [{
        data: nonZeroIndices.map(index => originalData.datasets[0].data[index]),
        backgroundColor: nonZeroIndices.map(index => originalData.datasets[0].backgroundColor[index])
      }]
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 60,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    hover: {
      mode: null
    },
    layout: {
      padding: 40
    }
  };

  const parseValue = (value) => {
    if (typeof value === 'string') {
      return parseFloat(value.replace(/[^\d.-]/g, ''));
    }
    return value;
  };

  const formatValue = (value) => {
    const numValue = parseValue(value);
    if (isNaN(numValue)) {
      return 'N/A';
    }
    return isPercentage ? `${numValue.toFixed(1)}%` : numValue.toFixed(0);
  };

  const drawAllTooltips = (chart) => {
    const ctx = chart.ctx;
    const total = chart.data.datasets[0].data.reduce((sum, value) => sum + parseValue(value), 0);
    const dataLength = chart.data.datasets[0].data.length;
    
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((element, index) => {
        const value = parseValue(dataset.data[index]);
        const percentage = (value / total) * 100;
        
        // Mostrar tooltip si hay 3 o menos elementos, o si el porcentaje es 15% o más
        if (dataLength <= 3 || percentage >= 15) {
          const model = element._model;
          let label = chart.data.labels[index];
          let displayValue;

          if (isPercentage) {
            displayValue = `${formatValue(value)}`;
          } else {
            displayValue = `${formatValue(value)} (${percentage.toFixed(1)}%)`;
          }
          
          const midAngle = (model.startAngle + model.endAngle) / 2;
          const radius = model.outerRadius + 30;
          const x = model.x + Math.cos(midAngle) * radius;
          const y = model.y + Math.sin(midAngle) * radius;

          ctx.fillStyle = 'black';
          ctx.font = '12px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(`${label}: ${displayValue}`, x, y);
        }
      });
    });
  };

  if (!data) return null;

  return (
    <div className="pie-chart-container">
      <div className="chart-wrapper">
        <Doughnut 
          ref={chartRef} 
          data={data} 
          options={options}
          plugins={[{
            beforeDraw: (chart) => {
              const ctx = chart.ctx;
              ctx.save();
              ctx.fillStyle = 'white';
              ctx.fillRect(0, 0, chart.width, chart.height);
              ctx.restore();
            },
            afterDraw: drawAllTooltips
          }]}
        />
      </div>
      <div className="legend-wrapper">
        {data.labels.map((label, index) => (
          <div key={index} className="legend-item">
            <span 
              className="color-box" 
              style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
            ></span>
            <span className="label">{label}</span>
            <span className="value">
              {formatValue(data.datasets[0].data[index])}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};