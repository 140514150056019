import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const BasicSelect = ({
  label,
  value,
  options,
  onChange,
  errorText,
  required = false,
  disabled = false,
  ...props
}) => {
  const theme = useTheme();
  const [t] = useTranslation("components");
  const defaultLabel = t("EGSelects.select_option");
  const defaultErrorLabel = t("EGSelects.this_field_is_required");

  return (
    <FormControl fullWidth {...props} disabled={disabled}>
      <InputLabel
        sx={{
          color: required
            ? value
              ? theme.palette.text.primary
              : theme.palette.red.main
            : theme.palette.oxfordBlue.main,

          "&.Mui-focused": {
            color: required
              ? value
                ? `${theme.palette.green.main} !important`
                : theme.palette.red.main
              : theme.palette.oxfordBlue.main,
          },
          "&.MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
            transition: "all 0.25s",
            top: required ? 0 : "50%",
            transform: required
              ? "translate(10%, 48%)"
              : "translate(10%, -50%)",
          },
          "&.MuiInputLabel-shrink": {
            transition: "all 0.25s",
            backgroundColor: theme.palette.common.white,
            padding: "0 5px",
            borderRadius: "4px",
            color: required
              ? value
                ? theme.palette.oxfordBlue.main
                : theme.palette.red.main
              : theme.palette.ethicsBlue.main,
          },
        }}
      >
        <Typography
          sx={{
            "&::after": {
              content: required ? '"*"' : '""',
              color: theme.palette.red.main,
              marginLeft: "0.2em",
            },
          }}
        >
          {label || defaultLabel}
        </Typography>
      </InputLabel>
      <Select
        value={value}
        label={label || defaultLabel}
        onChange={onChange}
        sx={{
          height: "40px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: required
              ? value
                ? theme.palette.green.primary
                : theme.palette.redIV.main
              : theme.palette.blueGray200.main,
            borderWidth: required ? (value ? "2px" : "3px") : "1px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: required
              ? value
                ? theme.palette.green.main
                : theme.palette.red.main
              : theme.palette.text.primary,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            boxShadow: required
              ? value
                ? `${theme.palette.greenIV.main} 0 0 4px 1px`
                : `${theme.palette.redIV.main} 0 0 4px 1px`
              : `${theme.palette.blueIV.main} 0 0 4px 1px`,
            borderColor: required
              ? value
                ? theme.palette.green.main
                : theme.palette.red.main
              : theme.palette.ethicsBlue.main,
          },
        }}
      >
        {options && options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            <Typography marginX="auto">
              {t("EGSelects.no_options_available")}
            </Typography>
          </MenuItem>
        )}
      </Select>
      {required && !value && (
        <FormHelperText
          sx={{ color: theme.palette.error.main, mt: 0.5, fontSize: "0.7rem" }}
        >
          {`${errorText || defaultErrorLabel} *`}
        </FormHelperText>
      )}
    </FormControl>
  );
};
