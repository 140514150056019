import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import { CDBModal } from "cdbreact";
import { Settings3 } from "../../Icon";
import { MCButton, MCCheckbox, MCInput, MCLabel } from "../../MainComponents";
import { MCSelect } from "../../MainComponents/Select/Select";
import { useTranslation } from "react-i18next";
import { retrieveCatalogueByPath } from "../../../apis";
import { useLocation } from "react-router-dom";


export const Filter = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [monthSelected, setMonthSelected] = useState("");
  const [yearSelected, setYearSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [reportClassification, setReportClassification] = useState([]);
  const [kindOfGrievance, setKindOfGrievance] = useState([]);
  const [kindOfSeverity, setKindOfSeverity] = useState([]);
  const [t] = useTranslation("report");

  const {
    handleReCallReports,
    checksFilter,
    setChecksFilter,
    folio,
    setFolio,
    tracking_code,
    setTracking_code,
    setMonth_created,
    setYear_created,
    before_created,
    setBefore_created,
    after_created,
    setAfter_created,
    r_start_created,
    setR_start_created,
    r_end_created,
    setR_end_created,
    created,
    setCreated,
    open_days_less_than,
    setOpen_days_less_than,
    open_days_greater_than,
    setOpen_days_greater_than,
    r_start_open_days,
    setR_start_open_days,
    r_end_open_days,
    setR_end_open_days,
    setStatus,
    reportClassificationSelected,
    setReportClassificationSelected,
    kind_of_grievance,
    setKind_of_grievance,
    kind_of_severity,
    setKind_of_severity,
    kind_of_entryways,
    setKind_of_entryways,
    status,
    selectURL
  } = props;


  const kindOfEntryways = [
    {
      value: "WEBSITE",
      label: t("entryways.website"),
    },
    {
      value: "PHONE",
      label: t("entryways.phone"),
    },
    {
      value: "EMAIL",
      label: t("entryways.email"),
    },
    {
      value: "CHAT",
      label: t("entryways.chat"),
    },
    {
      value: "WHATSAPP",
      label: t("entryways.whatsapp"),
    },
    {
      value: "APP",
      label: t("entryways.app"),
    },
    {
      value: "OUTDOOR",
      label: t("entryways.outdoor"),
    },
    {
      value: "OTHER",
      label: t("entryways.other"),
    },
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      applyFilter();
    }
  };

  useEffect(() => {
    //Verify if all checkboxes are checked
    const allCheckboxesChecked = Object.values(checksFilter).every(
      (checkbox) => checkbox
    );
    //Update the initial state of the checkbox 'selectAll'
    if (allCheckboxesChecked) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checksFilter]);

  useEffect(() => {
    // getCatalogue("/", "reportClassification");
    // getCatalogue("type::grievance", "kindOfGrievance");
    getCatalogue("/", "kindOfSeverity", "RD-102106");
  }, []);

  // Transform data from API to the format required by the Select component
  const transformData = (data, key = "path") => {
    return data.map((item) => {
      const path = item[key];
      return {
        value: path,
        label: item.label,
      };
    });
  };

  // Get data from the API
  const getCatalogue = async (
    path = "/",
    origin = "reportClassification",
    catalogue = "RC-100",
    is_own = false
  ) => {
    const response = await retrieveCatalogueByPath({ is_own, catalogue, path });
    if (response.status === 200) {
      //
      if (origin === "reportClassification") {
        setReportClassification(transformData(response.data.data));
      }
      if (origin === "kindOfGrievance") {
        setKindOfGrievance(transformData(response.data.data));
      }
      if (origin === "kindOfSeverity") {
        setKindOfSeverity(transformData(response.data.data, "path_locales"));
      }
    }
    return [];
  };

  //OnChange of individual Checkbox
  const handleFilterChecks = (e) => {
    const { name } = e.target;
    //It takes the initial state of the checkboxes (false) as a prop and updates them as long as their value changes.
    setChecksFilter((checksFilter) => ({
      ...checksFilter,
      [name]: !checksFilter[name],
    }));
  };

  //onChange of the 'selectAll' checkbox which updates the selection of all elements or none, respectively
  const handleSelectAll = () => {
    const updatedSelectAll = !selectAll;
    setSelectAll(updatedSelectAll);
    //If the value of the checkbox 'selectAll' changes, the other checkboxes are updated
    const updatedCheckboxes = {};
    for (const name in checksFilter) {
      updatedCheckboxes[name] = updatedSelectAll;
    }
    setChecksFilter(updatedCheckboxes);
  };

  const applyFilter = () => {
    handleReCallReports();
    setShowModal(false);
  };

  //--- Values of MCSelect ---//
  //Select Month of Reporting
  const selectMonth = (monthSelected) => {
    setMonthSelected(monthSelected);
    setMonth_created(monthSelected.value);
  };
  const optionsMonth = [
    { value: "01", label: t("months.january") },
    { value: "02", label: t("months.february") },
    { value: "03", label: t("months.march") },
    { value: "04", label: t("months.april") },
    { value: "05", label: t("months.may") },
    { value: "06", label: t("months.june") },
    { value: "07", label: t("months.july") },
    { value: "08", label: t("months.august") },
    { value: "09", label: t("months.september") },
    { value: "10", label: t("months.october") },
    { value: "11", label: t("months.november") },
    { value: "12", label: t("months.december") },
  ];
  //Select Year of Reporting
  const selectYear = (yearSelected) => {
    setYearSelected(yearSelected);
    setYear_created(yearSelected.value);
  };
  const optionsYear_created = [{ value: "2023", label: "2023" }];

  const optionsStatus = [
    { value: "new", label: t("status.new") },
    { value: "process", label: t("status.process") },
    { value: "finish", label: t("status.finish") },
    { value: "pending", label: t("status.pending") },
    { value: "closed", label: t("status.closed") },
    { value: "draft", label: t("status.draft") },
  ];
  const location = useLocation();

  //Select Status of Reporting
  // console.log("status en el segundo archivo: ", status);
  // console.log("SELECT URL", selectURL);
  
  useEffect(() => {
    let filtro=location.pathname.split('/')
    // let filtroParaPuebas =  


    const selectedOption = optionsStatus.find(option => option.value === filtro[filtro.length-1].replace(":",""));
    // console.log("ESTE ES EL SELECT OPTION", selectedOption);
    if (selectedOption != null && selectedOption != undefined) {





      setStatusSelected(selectedOption);
      setStatus(selectedOption.value);
    }
    // Dependencias: este efecto se ejecutará cuando `currentStatus` cambie
  }, []);


  const selectStatus = (statusSelected) => {
    // console.log("STATUS", statusSelected);
    setStatusSelected(statusSelected);
    setStatus(statusSelected.value);
  
  };

const selectReportClassification = (reportClassificationSelected) => {
  setReportClassificationSelected(reportClassificationSelected);
};

return (
  <>
    {/* Filters button to show modal */}
    <small
      id="filter-svg"
      className="d-flex btnFilters"
      onClick={() => setShowModal(!showModal)}
    >
      {t("Filter.filter")} <Settings3 className="my-auto" />
    </small>

    <CDBModal
      isOpen={showModal}
      toggle={() => setShowModal(!showModal)}
      size="lg"
      centered
    >
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 dyTheme1 rounded">
        {/* Modal header */}
        <div>
          <h5>{t("Filter.filter")}</h5>
          <small>{t("Filter.set_filter")}</small>
        </div>
        <button
          onClick={() => setShowModal(!showModal)}
          className="closeModal --scale09"
        >
          <small>x</small>
        </button>
      </div>

      <div className="p-20 text-left">
        <div className="p-20 dyBorder1 rounded">
          {/* Select All */}
          <Row>
            <Col sm="12" className="mb-3">
              <MCCheckbox
                className="dyTheme4 me-2"
                name="selectAll"
                onChange={handleSelectAll}
                checked={selectAll}
              />
              <MCLabel
                text={
                  selectAll
                    ? t("Filter.deselect_all")
                    : t("Filter.select_all")
                }
              />
            </Col>
            <hr width="1" size="1" className="mb-2" />
          </Row>
          {/* Filter */}
          <Row>
            <Col lg="6" md="6" sm="12">
              {/* Folio*/}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="me-1 mt-1 dyTheme4"
                      id="folio"
                      name="folio"
                      value={checksFilter.folio}
                      onChange={handleFilterChecks}
                      checked={checksFilter.folio}
                    />
                    <MCLabel text="Folio" htmlFor="folio" />
                  </div>
                </Col>
              </Row>
              {checksFilter.folio && (
                <Row>
                  <Col>
                    <MCInput
                      type="text"
                      className="mt-0 w-100 form-control"
                      value={folio}
                      onChange={(e) => setFolio(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Tracking Code */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="tracking_code"
                      name="tracking_code"
                      value={checksFilter.tracking_code}
                      onChange={handleFilterChecks}
                      checked={checksFilter.tracking_code}
                    />
                    <MCLabel
                      text={t("Filter.tracking_code")}
                      htmlFor="tracking-code"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.tracking_code && (
                <Row>
                  <Col>
                    <MCInput
                      type="text"
                      className="mt-0 w-100 form-control"
                      value={tracking_code}
                      onChange={(e) => setTracking_code(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Mes de creacion */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className=" dyTheme4"
                      id="month_created"
                      name="month_created"
                      value={checksFilter.month_created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.month_created}
                    />
                    <MCLabel
                      text={t("Filter.month_created")}
                      htmlFor="month_created"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.month_created && (
                <Row>
                  <Col>
                    <MCSelect
                      className="mt-0 w-100"
                      value={monthSelected}
                      onChange={selectMonth}
                      options={optionsMonth}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Año de creacion */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="year_created"
                      name="year_created"
                      value={checksFilter.year_created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.year_created}
                    />
                    <MCLabel
                      text={t("Filter.year_created")}
                      htmlFor="year_created"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.year_created && (
                <Row>
                  <Col>
                    <MCSelect
                      className="mt-0 w-100"
                      value={yearSelected}
                      onChange={selectYear}
                      options={optionsYear_created}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Creado a partir de... */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="before_created"
                      name="before_created"
                      value={checksFilter.before_created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.before_created}
                    />
                    <MCLabel
                      text={t("Filter.before_created")}
                      htmlFor="before_created"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.before_created && (
                <Row>
                  <Col>
                    <DatePicker
                      selected={before_created}
                      onChange={(date) => setBefore_created(date)}
                      dateFormat="d/MM/yyyy"
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Creado despues de... */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="after_created"
                      name="after_created"
                      value={checksFilter.after_created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.after_created}
                    />
                    <MCLabel
                      text={t("Filter.after_created")}
                      htmlFor="after_created"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.after_created && (
                <Row>
                  <Col>
                    <DatePicker
                      selected={after_created}
                      onChange={(date) => setAfter_created(date)}
                      dateFormat="d/MM/yyyy"
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}

              {/* Severity */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="kind_of_severity"
                      name="kind_of_severity"
                      value={checksFilter.kind_of_severity}
                      onChange={handleFilterChecks}
                      checked={checksFilter.kind_of_severity}
                    />
                    <MCLabel
                      text={t("Filter.kind_of_severity")}
                      htmlFor="kind_of_severity"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.kind_of_severity && (
                <Row>
                  <Col>
                    <MCSelect
                      className="mt-0 w-100"
                      options={kindOfSeverity}
                      onChange={(e) => setKind_of_severity(e)}
                      value={kind_of_severity}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
            </Col>

            <Col lg="6" md="6" sm="12">
              {/* Rango de creación */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="range_of_created"
                      name="range_of_created"
                      value={checksFilter.range_of_created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.range_of_created}
                    />
                    <MCLabel
                      text={t("Filter.range_of_created")}
                      htmlFor="range_of_created"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.range_of_created && (
                <Row>
                  <Col>
                    <MCLabel
                      className="form-label"
                      text={`${t("Filter.from")}:`}
                    />
                    <DatePicker
                      selected={r_start_created}
                      onChange={(date) => setR_start_created(date)}
                      dateFormat="d/MM/yyyy"
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                  <Col>
                    <MCLabel
                      className="form-label"
                      text={`${t("Filter.to")}:`}
                    />
                    <DatePicker
                      selected={r_end_created}
                      onChange={(date) => setR_end_created(date)}
                      dateFormat="d/MM/yyyy"
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Fecha de creacion */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="created"
                      name="created"
                      value={checksFilter.created}
                      onChange={handleFilterChecks}
                      checked={checksFilter.created}
                    />
                    <MCLabel
                      htmlFor="created"
                      text={t("Filter.created_date")}
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.created && (
                <Row>
                  <Col>
                    <DatePicker
                      selected={created}
                      onChange={(date) => setCreated(date)}
                      dateFormat="d/MM/yyyy"
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Dias abiertos menores que */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="open_days_less_than"
                      name="open_days_less_than"
                      value={checksFilter.open_days_less_than}
                      onChange={handleFilterChecks}
                      checked={checksFilter.open_days_less_than}
                    />
                    <MCLabel
                      htmlFor="open_days_less_than"
                      text={t("Filter.open_days_less_than")}
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.open_days_less_than && (
                <Row>
                  <Col>
                    <MCInput
                      type="number"
                      className="mt-0 w-100 form-control"
                      value={open_days_less_than}
                      onChange={(e) => setOpen_days_less_than(e.target.value)}
                      onKeyDown={handleKeyDown}
                      min={1}
                    />
                  </Col>
                </Row>
              )}
              {/* Dias abiertos mayores que */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="open_days_greater_than"
                      name="open_days_greater_than"
                      value={checksFilter.open_days_greater_than}
                      onChange={handleFilterChecks}
                      checked={checksFilter.open_days_greater_than}
                    />
                    <MCLabel
                      htmlFor="open_days_greater_than"
                      text={t("Filter.open_days_greater_than")}
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.open_days_greater_than && (
                <Row>
                  <Col>
                    <MCInput
                      type="number"
                      className="mt-0 w-100 form-control"
                      value={open_days_greater_than}
                      onChange={(e) =>
                        setOpen_days_greater_than(e.target.value)
                      }
                      onKeyDown={handleKeyDown}
                      min={1}
                    />
                  </Col>
                </Row>
              )}
              {/* Rango de días abierto */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="open_days_range"
                      name="open_days_range"
                      value={checksFilter.open_days_range}
                      onChange={handleFilterChecks}
                      checked={checksFilter.open_days_range}
                    />
                    <MCLabel
                      htmlFor="open_days_range"
                      text={t("Filter.open_days_range")}
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.open_days_range && (
                <Row>
                  <Col>
                    <label className="form-label">
                      {`${t("Filter.from")}:`}
                    </label>
                    <MCInput
                      type="number"
                      className="mt-0 w-100 form-control"
                      value={r_start_open_days}
                      onChange={(e) => setR_start_open_days(e.target.value)}
                      min={1}
                    />
                  </Col>
                  <Col>
                    <label className="form-label">
                      {`${t("Filter.to")}:`}
                    </label>
                    <MCInput
                      type="number"
                      className="mt-0 w-100 form-control"
                      value={r_end_open_days}
                      onChange={(e) => setR_end_open_days(e.target.value)}
                      min={1}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
              {/* Estatus */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="status"
                      name="status"
                      value={checksFilter.status}
                      onChange={handleFilterChecks}
                      checked={checksFilter.status}
                    />
                    <MCLabel text={t("Filter.status")} htmlFor="status" />
                  </div>
                </Col>
              </Row>
              {checksFilter.status && (
                <Row>
                  <Col>
                    <MCSelect
                      className="mt-0 w-100"
                      options={optionsStatus}
                      onChange={selectStatus}
                      value={statusSelected}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}

              {/* Tipo de entrada */}
              <Row>
                <Col className="my-2">
                  <div className="form-check">
                    <MCCheckbox
                      className="dyTheme4"
                      id="kind_of_entryways"
                      name="kind_of_entryways"
                      value={checksFilter.kind_of_entryways}
                      onChange={handleFilterChecks}
                      checked={checksFilter.kind_of_entryways}
                    />
                    <MCLabel
                      text={t("Filter.kind_of_entryways")}
                      htmlFor="kind_of_entryways"
                    />
                  </div>
                </Col>
              </Row>
              {checksFilter.kind_of_entryways && (
                <Row>
                  <Col>
                    <MCSelect
                      className="mt-0 w-100"
                      options={kindOfEntryways}
                      onChange={(e) => setKind_of_entryways(e)}
                      value={kind_of_entryways}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        {/* Actions modal footer*/}
        <div className="filter-footer">
          <MCButton
            label={t("Filter.cancel")}
            variant="primary"
            outline
            width={180}
            onClick={() => setShowModal(false)}
          />
          <MCButton
            label={t("Filter.apply_filter")}
            variant="primary"
            width={180}
            onClick={applyFilter}
          />
        </div>
      </div>
    </CDBModal>
  </>
);
};
