import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { buildData } from "../Utils/chartTransformData";
import "./BarChart.scss";

const BarChart = ({ chartData }) => {
  const [data, setData] = useState(chartData);
  const [maxValue, setMaxValue] = useState(100);

  useEffect(() => {
    const isPercentage = chartData.get_data !== "count";
    const dataTransformed = buildData({
      chartData,
      isPercentage,
      type: "bar",
      title: chartData.title,
    });
    setData(dataTransformed.data);
    setMaxValue(dataTransformed.maxValue);
  }, []);

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: maxValue,
          },
        },
      ],
    },
  };

  return (
    <div className="chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
