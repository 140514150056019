import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

const showSuccessMessage = (msg) => {
  enqueueSnackbar(`${msg}`, {
    variant: "success",
    preventDuplicate: true,
  });
};

export const getUserList = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/list/user/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error.message);
  }
};

export const createUser = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/create/user/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp;
  } catch (resp) {
    console.error(resp.message);
    return {
      error: true,
      message: resp.response.data.errors || resp.response.data.message,
    };
  }
};

export const confirmUser = async (email = "", verification_code = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/confirm/user/`, {
      email,
      verification_code,
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error.error_confirm_user);
    console.error(error.message);
  }
};

export const userInsertInToGroup = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/user/insert/groups/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_updating_group}`);
    console.error(error.message);
  }
};

// Send code for reset password
export const sendCodeResetPassword = async (email) => {
  try {
    const resp = await apiCall.post("/reset_password/", { email });
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_group}`);
    console.error(error.message);
  }
};

// Reset password confirmation
// export const resetPasswordConfirm = async (
//   email,
//   confirmation_code,
//   new_password
// ) => {
//   try {
//     const resp = await apiCall.post("/confirm_reset_password/", {
//       email,
//       confirmation_code,
//       new_password,
//     });
//     return resp;
//   } catch (error) {
//     showErrorMessage(`${language.error_processing_request}`);
//     console.error(error.message);
//   }
// };

// Reset password confirmation
export const resetPasswordConfirm = async (
  email,
  password,
  cpassword
) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/user/change_password/`, {
      email,
      password,
      cpassword,
    }, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      }
    });
    return resp;
  } catch (error) {
    console.log(error);
    showErrorMessage(`${language.error_processing_request}`);
    throw new Error(error.message)
  }
};

export const resetMyPasswordConfirm = async (
  email,
  password,
  cpassword
) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/user/change_my_password/`, {
      email,
      password,
      cpassword,
    }, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      }
    });
    return resp;
  } catch (error) {
    console.log(error);
    showErrorMessage(`${language.error_processing_request}`);
    throw new Error(error.message)
  }
};

export const enableMFA = async (data) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/cognito/setup_mfa_totp/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_report_mfa_enable}`);
    console.error(error);
  }
};

export const verifyMFA = async (otp_code) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/cognito/verify_mfa_totp/`, {
      otp_code
    }, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_report_mfa}`);
    console.error(error);
  }
};