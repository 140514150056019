import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import SimpleSelect from "../../components/SimpleSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createClient } from "../../apis/apiClientConfig";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import { CDBBtn } from "cdbreact";
import { GeneralHeader } from "../../components/Title/GeneralHeader";
import "./Auth.scss";

export const ClientSignup = (props) => {
  const { gTheme } = useSelector((state) => state.theme);
  const DynamicTheme = {
    backgroundColor: gTheme === "light" ? "#fff" : "#141319",
  };
  const [t] = useTranslation("login");
  const { signup } = useSelector((state) => state.lang.dictionary);
  const { MX, US } = signup.country_options;
  const jobPosition = [
    { value: "p1", text: "Puesto 1" },
    { value: "p2", text: "Puesto 2" },
    { value: "p3", text: "Puesto 3" },
  ];
  const countries = [
    { value: "MX", text: MX },
    { value: "US", text: US },
  ];

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [webSite, setWebsite] = useState("");
  const [surname, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [success, setSuccess] = useState(null);
  const [business, setBusiness] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleJobPosition = (val) => {
    console.log(val);
  };

  const handleCountry = (val) => {
    console.log(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await createClient(
        email,
        username,
        password,
        surname,
        phoneNumber
      );
      setSuccess(data);
      setError(null);
    } catch (error) {
      setError(error);
      setSuccess(null);
    }
  };
  const handlePass = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-white" style={DynamicTheme}>
      <GeneralHeader
        title={t("Signup.create_client")}
        text={t("Signup.create_client_filling_out_required_fields")}
      />
      <div className="px-30" style={DynamicTheme}>
        <Row>
          <Col md="6" className="p-0 mt-30">
            <div className="pr-md-30">
              <label htmlFor="name">{t("Signup.name")}:</label>
              <input
                className="form-control"
                id="name"
                type="text"
                required
                name="name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </Col>
          <Col md="6" className="p-0 mt-30">
            <div className="pl-md-30">
              <label htmlFor="lastname">{t("Signup.lastname")}:</label>
              <input
                className="form-control"
                id="surname"
                type="text"
                required
                name="lastname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="p-0 mt-30">
            <div className="pr-md-30">
              <SimpleSelect
                label={`${t("Signup.job_position")}:`}
                options={jobPosition}
                onChange={handleJobPosition}
              />
            </div>
          </Col>
          <Col md="6" className="p-0 mt-30">
            <div className="pl-md-30">
              <SimpleSelect
                label={`${t("Signup.country")}:`}
                options={countries}
                onChange={handleCountry}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="py-20 px-20" style={DynamicTheme}>
        <div className=" py-20 border  change" style={DynamicTheme}>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <div className="d-block d-sm-flex flex-row">
                <div className="d-flex flex-column w-100 px-20">
                  <label htmlFor="mail"> {t("signup.businessEmail")}</label>
                  <input
                    className="form-control"
                    label={t("Signup.business_email")}
                    type="email"
                    name="email"
                    required
                    id="mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="mt-20">
                    <label htmlFor="pass"> {t("signup.password")}</label>
                    <input
                      id="pass"
                      className="form-control"
                      type="password"
                      name="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-100 px-20">
                  <div className="form-group has-feedback">
                    <label htmlFor="phone">{t("Signup.phone")}</label>
                    <CustomPhoneInput
                      id="phone"
                      onChange={(e) => {
                        props.setPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-20">
                    <label htmlFor="verify">
                      {`${t("Signup.verify_password")}:`}
                    </label>
                    <input
                      id="verify"
                      className="form-control"
                      type="password"
                      name="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column" style={DynamicTheme}>
        <div className="d-block d-sm-flex flex-row mx-2">
          <div className="d-flex flex-column w-100 p-20">
            <div className="pr-md-20">
              <label htmlFor="business"> {t("Signup.company_name")}: </label>
              <input
                id="business"
                type="text"
                className="form-control"
                required
                value={business}
                onChange={(e) => setBusiness(e.target.value)}
              />
              <div className="mt-20">
                <CDBBtn
                  className="athics-btn mt-20"
                  type="submit"
                  onClick={handleSubmit}
                  block={false}
                  disabled={false}
                >
                  {t("Signup.create")}
                </CDBBtn>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100 p-20">
            <div className="pl-md-20">
              <label htmlFor="web">{t("Signup.webSite")}</label>
              <input
                id="web"
                type="text"
                required
                value={webSite}
                onChange={(e) => setWebsite(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
