import React, { useRef } from "react";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import { BoldCloseCircle, Paperclip } from "../../Icon";
import { MCTooltip } from "../Tooltip/Tooltip";
import "./FileUpload.scss";
import { Col, Row } from "react-bootstrap";
import { MCLabel } from "../Label/Label";
import { MCSelect } from "../Select/Select";
/**
 * A custom input for uploading multiple files
 * @param files: preview state files to upload.
 * @param setFiles: a function to set files in state.
 * @param onChange: event listening for changes when adding or deleting files
 */
export const FileUpload = ({
  files = [],
  setFiles,
  filesToRender = [],
  setFilesToRender,
  formDataKey = "attachments",
}) => {
  const fileInputRef = useRef(null);
  const fileDivRef = useRef(null);
  const isDraggingOver = useRef(false);
  const [t] = useTranslation("components");
  // To generate formData
  const processFile = (file) => {
    const formData = new FormData();
    formData.append(formDataKey, file);
    return { file, formDataKey, formData };
  };
  // Input to load file
  const hdlFileInput = (e) => {
    const file = e.target.files;
    const newFileArray = Array.from(file).map(processFile);
    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFileArray.filter(
        (newFile) =>
          !prevFiles.some(
            (prevFile) => prevFile.file.name === newFile.file.name
          )
      ),
    ]);
    // fileDivRef.current.classList.remove("drag-over");
  };
  // Click into input to load file
  const hdlDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // Erase a file uploaded
  const removeFile = (fileName) => {
    const updatedFiles = files.filter((file) => file.file.name !== fileName);
    setFiles(updatedFiles);
  };
  // Sets the file when dropped
  const hdlFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    const newFileArray = Array.from(file).map(processFile);
    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFileArray.filter(
        (newFile) =>
          !prevFiles.some(
            (prevFile) => prevFile.file.name === newFile.file.name
          )
      ),
    ]);
    fileDivRef.current.classList.remove("drag-over");
  };
  // Drag files into input and add styles
  const hdlDragEnter = (e) => {
    e.preventDefault();
    isDraggingOver.current = true;
    fileDivRef.current.classList.add("drag-over");
  };
  // Space calculation when the mouse leave input
  const hdlDragLeave = (e) => {
    e.preventDefault();
    const target = e.target;
    const clientX = e.clientX;
    const clientY = e.clientY;
    const elementBox = target.getBoundingClientRect();

    if (
      clientX < elementBox.left - 1 ||
      clientX > elementBox.right - 1 ||
      clientY < elementBox.top - 1 ||
      clientY > elementBox.bottom - 1
    ) {
      isDraggingOver.current = false;
      fileDivRef.current.classList.remove("drag-over");
    }
  };
  // Remove styles drag and drop file
  const hdlMouseLeave = () => {
    if (isDraggingOver.current) {
      isDraggingOver.current = false;
      fileDivRef.current.classList.remove("drag-over");
    }
  };
  // Drag file with mouse and prevent default browser behavior
  const preventDefaultBehavior = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDelete = (idx) => {
    const newFiles = filesToRender.filter((_, index) => index !== idx);
    setFilesToRender(newFiles);
  };

  return (
    <Row className="fileUpload">
      {/* Custom input */}
      <Col className="px-1">
        <div
          xl={6}
          className="fileUpload__attachments rounded"
          onClick={hdlDivClick}
          onDragOver={preventDefaultBehavior}
          onDrop={hdlFileDrop}
          onDragEnter={hdlDragEnter}
          onDragLeave={hdlDragLeave}
          onMouseLeave={hdlMouseLeave}
          ref={fileDivRef}
        >
          <div className="fileUpload__attachments-icon-container">
            <Icon name="gallery" />
            <Icon name="outline_clipboard_text" />
            <Icon name="volume_high" />
            <Icon name="outline_video" />
            <Icon name="outline_document" />
          </div>

          <div className="fileUpload__attachments-instructions">
            <p>{t("fileUpload.drag_drop_files")}</p>
            <button className="btn attachments-btn">
              {t("fileUpload.or_click_here")}
            </button>
          </div>
          <input
            type="file"
            accept="*"
            multiple
            ref={fileInputRef}
            className="form-control w-100"
            onChange={hdlFileInput}
            hidden
          />
        </div>
      </Col>
      {/* Preview of loaded files before to send endpoint */}
      <Col xl={6} className="px-1">
        <div className={`fileUpload__preview-container rounded`}>
          {files.length > 0 ? (
            <>
              <MCLabel
                text={`${t("fileUpload.selected_files")}:`}
                className="text-center my-2"
              />
              <ul>
                {files.map((fileObj, idx) => (
                  <li key={idx} className="fileUpload__file">
                    <Paperclip className="--paperClip" />
                    <small className="fileUpload__name">
                      {fileObj.file?.name}
                    </small>

                    <MCTooltip text={t("fileUpload.delete")} position="top">
                      <button
                        onClick={() => removeFile(fileObj.file?.name, idx)}
                      >
                        <BoldCloseCircle />
                      </button>
                    </MCTooltip>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <span className="m-auto">{`${t(
              "fileUpload.no_files_selected"
            )}...`}</span>
          )}
        </div>
      </Col>
      {filesToRender.length > 0 && (
        <Col xl={12} className="px-1 mt-20">
          <div className={`fileUpload__loaded-container rounded`}>
            <>
              <ul>
                <li className="list-unstyled text-center">
                  <h6>{`${t("fileUpload.uploaded_files")}:`}</h6>
                </li>
                {filesToRender.map((fileRender, idx) => (
                  <li className="fileUpload__file" key={idx}>
                    <Paperclip className="--paperClip" />
                    <a href={fileRender.url} target="_blank" key={idx}>
                      <small className="fileUpload__name">
                        {fileRender.name}
                      </small>
                    </a>
                    <MCTooltip text={t("fileUpload.delete")} position="top">
                      <button onClick={() => handleDelete(idx)}>
                        <BoldCloseCircle />
                      </button>
                    </MCTooltip>
                  </li>
                ))}
              </ul>
            </>
          </div>
        </Col>
      )}
    </Row>
  );
};
