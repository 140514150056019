import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getFormsList,
  getDetailCurrentReport,
  getFormDetails,
} from "../../../apis";
import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { EGForms } from "./EGForms";
import { OwnForms } from "./OwnForms";
import { PreviewForm } from "../PreviewForm/PreviewForm";
import { formStarter } from "../PreviewForm/formStarter";
import { useTranslation } from "react-i18next";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";
import "./FormsList.scss";

// const DeleteModal = ({ show, onHide, gTheme, setOwnFormToDelete }) => {
//   const DynamicTheme = {
//     backgroundColor: gTheme === "light" ? '#fff' : '#1a1d27',
//     border: gTheme === "light" ? '1px solid #DEE2E6' : '1px solid #fff',
//   };

//   const cancelDelete = () => {
//     setOwnFormToDelete(null);
//     onHide();
//   }

//   return (
//     <Modal show={show} onHide={cancelDelete} centered >
//       <div className="p-4 modal-shadow" style={DynamicTheme}>
//         <div className="d-flex flex-column">
//           <h4>¿Estás seguro que quieres eliminar tu fomrulario?</h4>
//           <p>Eliminando tu formulario se borraran todas las configuraciones de etiquetas y pasos.</p>
//         </div>
//         <hr className="mb-5" />
//         <div className="d-flex justify-content-between">
//           <button className="btn athics-outline-btn mx-4 w-100" onClick={cancelDelete}>Cancelar</button>
//           <button className="btn athics-outline-btn mx-4 w-100" onClick={alert}>Eliminar</button>
//         </div>
//       </div>
//     </Modal>
//   )
// }

const PreviewModal = ({ formLoaded, show, onHide, gTheme }) => {
  const [t] = useTranslation("forms");
  const [isLoading, setIsLoading] = useState(true);
  const [formIdentifier, setFormIdentifier] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [steps, setSteps] = useState([
    {
      name: "step_title",
      title: t("FormsList.step_title"),
      description: t("FormsList.write_description"),
    },
  ]);
  const [schemaState, setSchemaState] = useState([
    [
      {
        key: "input_label",
        type: "string",
        label: t("FormsList.input_label"),
        placeholder: t("FormsList.support_text"),
        required: false,
        sensitive: false,
      },
    ],
  ]);
  const [formData, setFormData] = useState([[{ input_label: "" }]]);
  const [isValid, setIsValid] = useState([[[]]]);
  const [constraints, setConstraints] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (formLoaded) {
      formStarter(formLoaded)
        .then(
          ({
            initFormIdentifier,
            initSteps,
            initSchemaState,
            initFormData,
            initIsValid,
            initConstraints,
          }) => {
            setFormIdentifier(initFormIdentifier);
            setSteps(initSteps);
            setSchemaState(initSchemaState);
            setFormData(initFormData);
            setIsValid(initIsValid);
            setConstraints(initConstraints);
            setIsLoading(false);
          }
        )
        .catch(console.log);
    }
  }, [formLoaded]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        className={`${gTheme !== "light" ? "dark-mode" : ""}`}
        centered
      >
        <Modal.Header
          closeButton
          className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
        />
        <div className="modal-preview-container">
          <div className="modal-preview-header">
            <div className="header-info">
              <div className="header-title">
                <h4>{t("FormsList.form_preview")}</h4>
              </div>
              <div className="header-description">
                {t("FormsList.form_preview_and_steps")}
              </div>
            </div>
            <div className="header-route">
              {t("FormsList.forms")} {">"}{" "}
              <span className="font-weight-bold">{t("FormsList.preview")}</span>
            </div>
          </div>
          {!isLoading && formLoaded && (
            <div className="modal-preview">
              <PreviewForm
                formIdentifier={formIdentifier}
                formDescription={formDescription}
                steps={steps}
                schemaState={schemaState}
                setSchemaState={setSchemaState}
                formData={formData}
                setFormData={setFormData}
                isValid={isValid}
                setIsValid={setIsValid}
                constraints={constraints}
                setConstraints={setConstraints}
                stepClick={true}
                showButtons={false}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export const FormsList = () => {
  const [t] = useTranslation("forms");
  const { gTheme } = useSelector((state) => state.theme);
  // For form list
  const [formList, setFormList] = useState([]);
  const [currentReport, setCurrentReport] = useState({});
  // For form preview
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [formLoaded, setFormLoaded] = useState(null);

  useEffect(() => {
    Promise.all([getFormsList(), getDetailCurrentReport()])
      .then(([forms, curr]) => {
        setFormList(forms);
        setCurrentReport(curr);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (selectedPreview) {
      getFormDetails(selectedPreview).then((form) => {
        setFormLoaded(form);
      });
    }
  }, [selectedPreview]);

  const breadcrumbItems = [
    { label: t("FormsList.home"), route: "/" },
    {
      label: t("FormsList.settings"),
      route: "/settings",
    },
    { label: t("FormsList.forms_list") },
  ];

  return (
    <div className={`form-list page`}>
      <div className="mb-4">
        <GeneralIconHeader
          title={t("FormsList.forms_list")}
          icon="bold_task"
          text={t("FormsList.this_section_can_create_new_form")}
        />
      </div>
      <PreviewModal
        formLoaded={formLoaded}
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        gTheme={gTheme}
      />
      <Breadcrum items={breadcrumbItems} />
      <div className="dyTheme1 dyBorder1 main-container">
        <div className="header text-center">
          <h4>{t("FormsList.can_select_form_or_create")}</h4>
        </div>

        <div className="dyTheme1 dyBorder1 forms-own-eg">
          <div className="own-forms">
            <OwnForms
              gTheme={gTheme}
              formList={formList}
              currentReport={currentReport}
              setSelectedPreview={setSelectedPreview}
              setShowPreviewModal={setShowPreviewModal}
            />
          </div>

          <div className="eg-forms my-3">
            <EGForms
              gTheme={gTheme}
              formList={formList}
              setSelectedPreview={setSelectedPreview}
              setShowPreviewModal={setShowPreviewModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
