
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
  MCButton,
  MCInput,
  MCLabel,
  MCSelect,
  MCTooltip,
} from "../../../MainComponents";
import { Col, Row } from "react-bootstrap";
import { TrashCan } from "../../../Icon";
import { MCSelectMulti } from "../../../MainComponents/SelectMulti/MCSelectMulti";
import { Loading } from "../../../Loading/String/Loading";
import Icon from "../../../Icon/Icon";

export const Sanctions = ({ resolution, setActiveTab, openModal, handleTabChange, reportDetail }) => {
  const [t] = useTranslation("report");
  const {
    sanctions,
    editResolution,
    setEditResolution,
    addMoreSanction,
    resolutionsUpdated,
    getAvailableOffenders,
    hdlSelectResolutionChange,
    offenders,
    sanctionsOptions,
    hdlAnoterOptionChange,
    isValid,
    hdlDetailsChange,
    hdlAddResolution,
    sendResolutionData,
    isFormValid,
    loading,
  } = resolution;
  // Value to label
  const sanctionLabel = (values) => {
    // if  value = "another" shows "another_sanction" value
    return values
      .filter((value) => value !== "another")
      .map((value) => {
        const sanction = sanctionsOptions?.find(
          (option) => option?.value === value
        );
        return sanction ? sanction.label : value;
      });
  };

  // Offender type translate
  const offenderType = {
    offender: t("Resolution.infractor"),
    accused: t("Resolution.accused"),
    accomplice: t("Resolution.accomplice"),
  };

  const [enableReadMode, setEnableReadMode] = useState(false);

  useEffect(() => {
    if (reportDetail != "finish")
      setEnableReadMode(true);

  }, [])

  return (
    enableReadMode === false ? (
      <div className="dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.sanctions")}</h4>
          {sanctions?.length >= 1 && (
            <>
              {!editResolution ? (
                <MCButton
                  label={t("Resolution.edit")}
                  variant="light"
                  className="resolutions__btnAction"
                  onClick={() => setEditResolution(true)}
                  icon="outline_edit"
                />
              ) : (
                <MCButton
                  label={t("Resolution.cancel")}
                  variant="light"
                  className="resolutions__btnAction"
                  onClick={() => {
                    handleTabChange(3);
                  }}
                  icon="collapse"
                />
              )}
            </>
          )}
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            sanctions?.length === 0 ? (
              !addMoreSanction ? (
                <h5>{t("Resolution.no_offenders_have_been_registered_yet")}</h5>
              ) : (
                <h5>{t("Resolution.this_report_no_sanctions_assigned_yet")}</h5>
              )
            ) : (
              resolutionsUpdated?.sanctions?.map((sanctionElement, i) => (
                <Col key={i} sm={12} xxl={6} className="p-1">
                  {sanctionElement.length === 0 ? (
                    <h5>
                      {t("Resolution.this_report_no_sanctions_assigned_yet")}
                    </h5>
                  ) : (
                    <div className="resolutions__sanction rounded">
                      <MCLabel
                        text={`${t("Resolution.information_involved_party")}:`}
                      />
                      <Row className="resolutions__involvedInfo my-2 rounded">
                        <Col xs={12} className="involvedGeneral">
                          <h6 className="text-center py-1">
                            {sanctionElement.name}
                          </h6>
                        </Col>
                        <hr className="my-0" />
                        <Col xs={12} className="involvedGeneral">
                          <small>
                            {
                              offenderType[
                              sanctionElement.kind || sanctionElement.rol
                              ]
                            }
                          </small>
                          <small>
                            <Icon
                              name={`${sanctionElement.confirm
                                  ? "check_circle"
                                  : "info_circle"
                                }`}
                              width={17}
                              className={`${sanctionElement.confirm
                                  ? "--confirm"
                                  : "--noConfirm"
                                } my-1`}
                            />
                            {`${sanctionElement.confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="my-1" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{sanctionElement.additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{sanctionElement.details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{sanctionElement.relationship}</small>
                        </Col>
                        <hr className="my-1" />
                        <Col className="pt-2 px-3">
                          <h6>{`${sanctionElement?.sanctions?.length > 1
                              ? t("Resolution.assigned_sanctions")
                              : t("Resolution.assigned_sanction")
                            }:`}</h6>
                          <ul className="mx-3">
                            {sanctionLabel(sanctionElement?.sanctions).map(
                              (label, i) => (
                                <li key={i}>{label}</li>
                              )
                            )}
                            {sanctionElement.another_sanction !== undefined && (
                              <li key={"other_option"}>
                                {sanctionElement.another_sanction}
                              </li>
                            )}
                          </ul>
                        </Col>
                        <hr />
                        <Col className="px-3 mb-3">
                          <h6>{`${t("Resolution.details")}:`}</h6>
                          <small className="px-2">
                            {sanctionElement.comment ||
                              t("Resolution.no_comments")}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ))
            )
          ) : sanctions?.length === 0 ? (
            <h5>{t("Resolution.all_sanctions_have_been_removed")}</h5>
          ) : (
            sanctions.map((_, i) => (
              <Col key={i} sm={12} xxl={6} className="p-1">
                <div className="resolutions__sanction rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "sanctions")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.involved")}:`}
                    className="my-1 mx-2"
                  />
                  <MCSelect
                    isValid={sanctions[i].name !== ""}
                    options={getAvailableOffenders(i)}
                    optionLabel="name"
                    onChange={(involvedSelected) =>
                      hdlSelectResolutionChange(
                        i,
                        offenders.find(
                          (offender) => offender.name === involvedSelected.name
                        ),
                        "involved"
                      )
                    }
                    value={offenders.find(
                      (offender) => offender.name === sanctions[i]?.name
                    )}
                  />
                  {sanctions[i].name !== "" && (
                    <>
                      <Row className="resolutions__involvedInfo my-2 rounded">
                        <Col xs={12} className="involvedGeneral">
                          <small>
                            {offenderType[sanctions[i].kind || sanctions[i].rol]}
                          </small>
                          <small>
                            <Icon
                              name={`${sanctions[i].confirm
                                  ? "check_circle"
                                  : "info_circle"
                                }`}
                              width={17}
                              className={`${sanctions[i].confirm ? "--confirm" : "--noConfirm"
                                } my-1`}
                            />
                            {`${sanctions[i].confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="mb-2" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{sanctions[i].additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{sanctions[i].details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{sanctions[i].relationship}</small>
                        </Col>
                      </Row>
                      <hr />
                      <MCSelectMulti
                        label={`${t("Resolution.sanctions")}:`}
                        options={sanctionsOptions}
                        selectedValues={sanctions[i].sanctions}
                        setSelectedValues={(selectedSanctions) =>
                          hdlSelectResolutionChange(
                            i,
                            selectedSanctions,
                            "sanctions"
                          )
                        }
                        isValid={sanctions[i]?.sanctions?.length !== 0}
                        helptip={t("Resolution.select_one_more_options")}
                      />
                      {sanctions[i].sanctions?.includes("another") && (
                        <MCInput
                          className="my-2"
                          type="textarea"
                          placeholder={`${t(
                            "Resolution.specify_another_sanction_type"
                          )} *`}
                          value={sanctions[i]?.another_sanction}
                          onChange={(e) =>
                            hdlAnoterOptionChange(i, e, "sanctions")
                          }
                          isValid={isValid(i, "sanctions")}
                        />
                      )}
                      {sanctions[i].sanctions?.length > 0 && (
                        <>
                          <hr />
                          <MCLabel
                            text={`${t("Resolution.details")}:`}
                            className="mx-2 my-0"
                          />
                          <MCInput
                            className="my-1"
                            type="textarea"
                            rows={3}
                            placeholder={t(
                              "Resolution.enter_details_sanction_assigned_person_involved"
                            )}
                            value={sanctions[i].comment}
                            onChange={(e) => hdlDetailsChange(i, e, "sanctions")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            ))
          )}
          {/* Add/Save sanctions */}
          <Col className="resolutions__sendResolutions mt-3" sm={12}>
            {sanctions?.length === 0 ? (
              <>
                {!addMoreSanction ? (
                  <></>
                  // <MCButton
                  //   label={t("Resolution.go_to_investigation")}
                  //   onClick={() => {
                  //     setActiveTab("Investigación");
                  //   }}
                  //   variant="light"
                  //   icon="bold_folder_add"
                  //   className="resolutions__btnAction"
                  // />
                ) : (
                  <MCButton
                    label={t("Resolution.add_new_sanction")}
                    onClick={() => {
                      hdlAddResolution("sanctions");
                      setEditResolution(true);
                    }}
                    variant="primary"
                    icon={!addMoreSanction ? "" : "add_square"}
                    disabled={!addMoreSanction}
                    outline={!addMoreSanction}
                  />
                )}
                {editResolution && (
                  <>
                    <MCButton
                      label={t("Resolution.save")}
                      variant="success"
                      onClick={sendResolutionData}
                      icon={isFormValid("sanctions") ? "" : "check_circle"}
                    />
                    <MCButton
                      label={t("Resolution.cancel")}
                      variant="light"
                      className="resolutions__btnAction"
                      onClick={() => {
                        handleTabChange(3);
                      }}
                      icon="collapse"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {addMoreSanction && (
                      <MCButton
                        label={t("Resolution.add_another_sanction")}
                        onClick={() => {
                          hdlAddResolution("sanctions");
                          setEditResolution(true);
                        }}
                        variant="primary"
                        disabled={isFormValid("sanctions")}
                        outline={isFormValid("sanctions")}
                        icon={isFormValid("sanctions") ? "" : "add_square"}
                      />
                    )}
                    {editResolution && (
                      <MCButton
                        label={t("Resolution.save")}
                        variant="success"
                        onClick={sendResolutionData}
                        disabled={isFormValid("sanctions")}
                        outline={isFormValid("sanctions")}
                        icon={isFormValid("sanctions") ? "" : "check_circle"}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    ) : (























      <div className="readMode dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.sanctions")}</h4>
        </div>
        <Row className="resolutions__container">
          {!editResolution ? (
            sanctions?.length === 0 ? (
              !addMoreSanction ? (
                <h5>{t("Resolution.no_offenders_have_been_registered_yet")}</h5>
              ) : (
                <h5>{t("Resolution.this_report_no_sanctions_assigned_yet")}</h5>
              )
            ) : (
              resolutionsUpdated?.sanctions?.map((sanctionElement, i) => (
                <Col key={i} sm={12} xxl={6} className="p-1">
                  {sanctionElement.length === 0 ? (
                    <h5>
                      {t("Resolution.this_report_no_sanctions_assigned_yet")}
                    </h5>
                  ) : (
                    <div className="resolutions__sanction rounded">
                      <MCLabel
                        text={`${t("Resolution.information_involved_party")}:`}
                      />
                      <Row className="resolutions__involvedInfo my-2 rounded">
                        <Col xs={12} className="involvedGeneral">
                          <h6 className="text-center py-1">
                            {sanctionElement.name}
                          </h6>
                        </Col>
                        <hr className="my-0" />
                        <Col xs={12} className="involvedGeneral">
                          <small>
                            {
                              offenderType[
                              sanctionElement.kind || sanctionElement.rol
                              ]
                            }
                          </small>
                          <small>
                            <Icon
                              name={`${sanctionElement.confirm
                                  ? "check_circle"
                                  : "info_circle"
                                }`}
                              width={17}
                              className={`${sanctionElement.confirm
                                  ? "--confirm"
                                  : "--noConfirm"
                                } my-1`}
                            />
                            {`${sanctionElement.confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="my-1" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{sanctionElement.additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{sanctionElement.details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{sanctionElement.relationship}</small>
                        </Col>
                        <hr className="my-1" />
                        <Col className="pt-2 px-3">
                          <h6>{`${sanctionElement?.sanctions?.length > 1
                              ? t("Resolution.assigned_sanctions")
                              : t("Resolution.assigned_sanction")
                            }:`}</h6>
                          <ul className="mx-3">
                            {sanctionLabel(sanctionElement?.sanctions).map(
                              (label, i) => (
                                <li key={i}>{label}</li>
                              )
                            )}
                            {sanctionElement.another_sanction !== undefined && (
                              <li key={"other_option"}>
                                {sanctionElement.another_sanction}
                              </li>
                            )}
                          </ul>
                        </Col>
                        <hr />
                        <Col className="px-3 mb-3">
                          <h6>{`${t("Resolution.details")}:`}</h6>
                          <small className="px-2">
                            {sanctionElement.comment ||
                              t("Resolution.no_comments")}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ))
            )
          ) : sanctions?.length === 0 ? (
            <h5>{t("Resolution.all_sanctions_have_been_removed")}</h5>
          ) : (
            sanctions.map((_, i) => (
              <Col key={i} sm={12} xxl={6} className="p-1">
                <div className="resolutions__sanction rounded">
                  <div className="resolutions__deleteResolution">
                    {editResolution && (
                      <MCTooltip text={t("Resolution.delete")} position="top">
                        <button
                          onClick={() => openModal(i, "sanctions")}
                          className="--delete"
                        >
                          <TrashCan />
                        </button>
                      </MCTooltip>
                    )}
                  </div>
                  <MCLabel
                    text={`${t("Resolution.involved")}:`}
                    className="my-1 mx-2"
                  />
                  <MCSelect
                    isValid={sanctions[i].name !== ""}
                    options={getAvailableOffenders(i)}
                    optionLabel="name"
                    onChange={(involvedSelected) =>
                      hdlSelectResolutionChange(
                        i,
                        offenders.find(
                          (offender) => offender.name === involvedSelected.name
                        ),
                        "involved"
                      )
                    }
                    value={offenders.find(
                      (offender) => offender.name === sanctions[i]?.name
                    )}
                  />
                  {sanctions[i].name !== "" && (
                    <>
                      <Row className="resolutions__involvedInfo my-2 rounded">
                        <Col xs={12} className="involvedGeneral">
                          <small>
                            {offenderType[sanctions[i].kind || sanctions[i].rol]}
                          </small>
                          <small>
                            <Icon
                              name={`${sanctions[i].confirm
                                  ? "check_circle"
                                  : "info_circle"
                                }`}
                              width={17}
                              className={`${sanctions[i].confirm ? "--confirm" : "--noConfirm"
                                } my-1`}
                            />
                            {`${sanctions[i].confirm
                                ? t("Resolution.confirmed")
                                : t("Resolution.unconfirmed")
                              }`}
                          </small>
                        </Col>
                        <hr className="mb-2" />
                        <Col>
                          <h6>{`${t("Resolution.employee_number")}:`}</h6>
                          <small>{sanctions[i].additional}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.area")}:`}</h6>
                          <small>{sanctions[i].details}</small>
                        </Col>
                        <Col>
                          <h6>{`${t("Resolution.job_position")}:`}</h6>
                          <small>{sanctions[i].relationship}</small>
                        </Col>
                      </Row>
                      <hr />
                      <MCSelectMulti
                        label={`${t("Resolution.sanctions")}:`}
                        options={sanctionsOptions}
                        selectedValues={sanctions[i].sanctions}
                        setSelectedValues={(selectedSanctions) =>
                          hdlSelectResolutionChange(
                            i,
                            selectedSanctions,
                            "sanctions"
                          )
                        }
                        isValid={sanctions[i]?.sanctions?.length !== 0}
                        helptip={t("Resolution.select_one_more_options")}
                      />
                      {sanctions[i].sanctions?.includes("another") && (
                        <MCInput
                          className="my-2"
                          type="textarea"
                          placeholder={`${t(
                            "Resolution.specify_another_sanction_type"
                          )} *`}
                          value={sanctions[i]?.another_sanction}
                          onChange={(e) =>
                            hdlAnoterOptionChange(i, e, "sanctions")
                          }
                          isValid={isValid(i, "sanctions")}
                        />
                      )}
                      {sanctions[i].sanctions?.length > 0 && (
                        <>
                          <hr />
                          <MCLabel
                            text={`${t("Resolution.details")}:`}
                            className="mx-2 my-0"
                          />
                          <MCInput
                            className="my-1"
                            type="textarea"
                            rows={3}
                            placeholder={t(
                              "Resolution.enter_details_sanction_assigned_person_involved"
                            )}
                            value={sanctions[i].comment}
                            onChange={(e) => hdlDetailsChange(i, e, "sanctions")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            ))
          )}
          {/* Add/Save sanctions */}
         
        </Row>
      </div>
    )
  );
};
