import { createSlice } from '@reduxjs/toolkit';

export const jaguarSlice = createSlice({
  name: 'jaguar',
  initialState: {
    denuncia: {
      id: "1234dasd"
    },
    reports: [],
    filtredReports: [],
    filterApplied: false
  },
  reducers: {
    setDenuncia: (state, { payload }) => {
      state.denuncia = payload;
    },
    setReports: (state, { payload }) => {
      state.reports = payload;
    },
    setFiltredReports: (state, { payload }) => {
      state.filtredReports = payload;
    },
    setFilterApplied: (state, { payload }) => {
      state.filterApplied = payload;
    }
  }
});

export const { setDenuncia, setReports, setFiltredReports, setFilterApplied } = jaguarSlice.actions;