import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CallBold } from "../../Icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { parseDateToISOString2 } from "../../../helpers/dateTransform";
import "./RecentReports.scss";

const RecentReports = ({ reportsData }, theme) => {
  const [t] = useTranslation("dashboard");

  const navigate = useNavigate();

  const [lastreports, setLastReports] = useState([]);
  const [expireDays, setExpireDays] = useState();

  useEffect(() => {
    setLastReports(Object.values(reportsData.results.reports));
    setExpireDays(reportsData.results.expired_report);
  }, []);

  // List of Status Reports
  const statusReports = {
    draft: t("RecentReports.draft"),
    new: t("RecentReports.new"),
    pending: t("RecentReports.pending"),
    process: t("RecentReports.process"),
    finish: t("RecentReports.finish"),
    closed: t("RecentReports.closed"),
  };

  return (
    <div className="mt-20 dashboard-card p-4 dyBorder1" id="recent-reports">
      <div className="text-center border-bottom pb-3">
        <h6 className="m-0">{`${t("RecentReports.last_reports")}:`}</h6>
      </div>

      {lastreports.length > 0 && (
        <div className="last-reports">
          {lastreports.map((reportItem) => (
            <div
              onClick={() => navigate("/reports/" + reportItem.id)}
              key={reportItem.id}
              className={`report ${reportItem.status_} dashboard-card mt-20`}
            >
              <div className="title">
                <CallBold className="title__icon rounded" />
                <h6 className="title__folio">{reportItem.folio}</h6>
              </div>
              <p>
                {reportItem.subject || `${t("RecentReports.no_subject")}...`}
              </p>
              <small>{parseDateToISOString2(reportItem.created_at)}</small>
              <small className="py-2 border-bottom text-center">
                {reportItem.open_days} {t("RecentReports.open_days")}
              </small>
              <small>{statusReports[reportItem.status_]}</small>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecentReports;
