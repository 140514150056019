import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./styles.scss";

const CustomPhoneInput = ({
  value,
  onChange = () => {},
  isValid = true,
  disabled = false,
}) => {
  const { customPhoneInput } = useSelector((state) => state.lang.dictionary);
  const [phone, setPhone] = useState(value);

  useEffect(() => {
    onChange(phone);
  }, [phone]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <PhoneInput
          className={`${!isValid ? "is-invalid" : ""} ${
            disabled ? "is-disabled" : ""
          }`}
          disabled={disabled}
          defaultCountry="MX"
          placeholder={`${customPhoneInput}`}
          onChange={setPhone}
          autoComplete="off"
          value={phone}
        />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
