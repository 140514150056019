import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Row, Col, Card, Form, FormCheck } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { GeneralIconHeader } from "../../../components/Title/GeneralHeader";
import {
  MCButton,
  MCInput,
  MCLabel,
  MCTooltip,
} from "../../../components/MainComponents";
import { useLandingPageSettings } from "./useLandingPage";
import { Breadcrum } from "../../../components/MainComponents/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ImageUpload } from "../../../components/MainComponents/ImageUpload/ImageUpload";
import { updateLandingLogos, updateSupportMaterial } from "../../../apis";
import { Loading } from "../../../components/Loading/String/Loading";
import { useSnackbar } from "notistack";
import megaphone from "../../../assets/images/concept.png";
import monitor from "../../../assets/images/monitor.png";
import complaint from "../../../assets/images/complaint.png";
import asesor from "./../../../assets/images/person.png";
import {
  BoldBrush4,
  BoldCloseCircle,
  BoldEdit2,
  BoldMessages2,
  BoldMessages3,
  BoldTickCircle,
  CallBold,
  Mobile,
  Monitor,
  MonitorMobile,
  OutlineSmsTracking,
} from "../../../components/Icon";
import { SupportFileUpload } from "../../../components/MainComponents/SupportFiles/SupportFileUpload";
import "./LandingPage.scss";
import { SupportFilesReader } from "../../../components/MainComponents/SupportFiles/SupportFilesReader";

export const LandingPageSettings = () => {
  // Custom hooks
  const { businessUnit } = useParams();
  const [t] = useTranslation("settings");
  const supportUploadRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    //building the stage
    mount,
    //campaing_name
    campaing_name,
    errorCampaingName,
    onChangeCampaingName,
    //content
    content,
    handleContentChange,
    renderErrorContent,
    //colors
    colors,
    mainColorText,
    secondaryColorText,
    handleColorChange,
    //questions
    questionToAdd,
    editQuestion,
    errorQuestions,
    handleAddQuestion,
    handleDeleteQuestion,
    handleEditQuestion,
    setQuestionToAdd,
    setEditQuestion,
    updateQuestion,
    //channels
    channels,
    errorChannels,
    phoneNumberStrings,
    switchBoolean,
    handleSwitchChange,
    handleChannelsValue,
    //button example styles
    ethicsCodeButton,
    grievanceButton,
    trackingButton,
    setTrackingHovered,
    setEthicsCodeHovered,
    //submit
    handleSaveLanding,
    //files
    files,
  } = useLandingPageSettings();
  // ---- Initial States ---- //
  // ---- Images ---- //
  const [images, setImages] = useState({});
  // ---- Support Files ----//
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  // Length of images updating
  const objectContent = Object.keys(images).length;
  // Previous Path
  const breadcrumbItems = [
    { label: t("landing_page.home"), route: "/" },
    { label: t("landing_page.settings"), route: "/settings" },
    {
      label: t("landing_page.business_units"),
      route: "/settings/business/list",
    },
    {
      label: t("landing_page.configuration"),
      route: `/settings/business/${businessUnit}`,
    },
    { label: t("landing_page.landing_custom") },
  ];
  // Get and set the uploaded images from the component <ImageUpload/>
  const selectingImages = (file, fieldKey) => {
    setImages((prevImages) => {
      const updateImage = { ...prevImages };
      if (file !== null) {
        //Sets state with image-file
        updateImage[fieldKey] = file;
      } else {
        //removes it from the state to prevent errors
        delete updateImage[fieldKey];
      }
      return updateImage;
    });
  };
  // Clicking to send previous selected images
  const sendImages = () => {
    const imageEntries = Object.entries(images);
    const imageToSend = imageEntries.filter(([key, value]) => value !== null);
    let objectToSend = {};
    objectToSend["business_unit_key"] = businessUnit;
    for (const [keyName, value] of imageToSend) {
      objectToSend[keyName] = value;
    }
    try {
      const resp = updateLandingLogos(objectToSend);
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };
  // Get and set selected support File from <SupportUpload>
  const selectingSupportFile = (file) => {
    setFile(file?.file);
    setFileName(file?.name);
  };
  // Clean actually state
  const handleCleanFile = () => {
    setFile("");
    setFileName("");
  };
  // Send to endpoint support files selected
  const sendSupportFile = () => {
    let objectToSend = new FormData();
    objectToSend.append("business_unit_key", businessUnit);
    objectToSend.append("file", file[0]);
    objectToSend.append("name", fileName);
    try {
      const resp = updateSupportMaterial(objectToSend).then((res) => {
        if (res.errors) {
          return;
        } else {
          handleCleanFile();
          if (supportUploadRef.current) {
            supportUploadRef.current.handleEraseFile();
          }
          mount();
        }
      });
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  };
  return (
    <div id="settings" className="landingPage">
      {/* --- Header Title --- */}
      <GeneralIconHeader
        title={t("landing_page.landing_custom")}
        text={t("landing_page.landing_custom_description")}
        icon="outline_brush"
      />
      <Breadcrum items={breadcrumbItems} />
      {/* --- Form --- */}
      <Row>
        <Col lg={7} className="mt-1 p-0">
          <div className="pr-lg-30">
            {/* --- Branding --- */}
            <div className="p-30 dyTheme1 dyBorder1 rounded">
              <Row>
                <h4>Branding</h4>
                <Col lg="12" className="p-0">
                  <div className="pr-lg-30">
                    <small className="mb-1">
                      {`${t("landing_page.campaing_name")}:`}
                    </small>
                    <MCInput
                      type="text"
                      name="campaing_name"
                      value={campaing_name}
                      onChange={onChangeCampaingName}
                      placeholder={t("landing_page.listens")}
                      isValid={!errorCampaingName}
                    />
                  </div>
                  {errorCampaingName && (
                    <p className="error">{t("landing_page.field_required")}</p>
                  )}
                </Col>
              </Row>
              <hr />
              {/* --- Content--- */}
              <Row>
                <h4>{t("landing_page.content")}</h4>
                <Col lg={6} className="p-0">
                  <div className="pr-lg-30">
                    <small className="mb-1">{`${t(
                      "landing_page.report_btn_text"
                    )}:`}</small>
                    <MCInput
                      type="text"
                      name="grievance_btn"
                      value={content.grievance_btn}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.report_now")}
                      isValid={content.grievance_btn}
                    />
                    {renderErrorContent("grievance_btn")}
                  </div>
                </Col>

                <Col lg={6} className="p-0">
                  <div className="pr-lg-30 mb-1">
                    <small className="mb-1">
                      {`${t("landing_page.tracking_btn_text")}:`}
                    </small>
                    <MCInput
                      type="text"
                      name="track_btn"
                      value={content.track_btn}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.complaint_tracking")}
                      isValid={content.track_btn}
                    />
                    {renderErrorContent("track_btn")}
                  </div>
                </Col>

                <Col lg={12} className="p-0">
                  <div className="pr-lg-30">
                    <small className="mb-1">{`${t(
                      "landing_page.main_text"
                    )}:`}</small>
                    <MCInput
                      type="textarea"
                      name="welcome_msg"
                      value={content.welcome_msg}
                      onChange={handleContentChange}
                      placeholder={t(
                        "landing_page.if_you_witness_any_violation"
                      )}
                      isValid={content.welcome_msg}
                    />
                    {renderErrorContent("welcome_msg")}
                  </div>
                </Col>
              </Row>
              <hr />
              {/* --- Complaint Form --- */}
              <Row>
                <h4>{t("landing_page.complaint_form")}</h4>
                <Col lg={6} className="p-0">
                  <div className="pr-lg-30">
                    <small className="mb-1">{`${t(
                      "landing_page.form_title"
                    )}:`}</small>
                    <MCInput
                      type="textarea"
                      name="form_title"
                      value={content.form_title}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.agents_of_change")}
                      isValid={content.form_title}
                    />
                    {renderErrorContent("form_title")}
                  </div>
                </Col>

                <Col lg={6} className="p-0">
                  <div className="pr-lg-30">
                    <small className="mb-1">{`${t(
                      "landing_page.form_message"
                    )}:`}</small>
                    <MCInput
                      type="textarea"
                      name="form_msg"
                      value={content.form_msg}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.through_this_site")}
                      isValid={content.form_msg}
                    />
                    {renderErrorContent("form_msg")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="p-0">
                  <div className="pr-lg-30">
                    <small className="my-1">{`${t(
                      "landing_page.tracking_title"
                    )}:`}</small>
                    <MCInput
                      type="textarea"
                      name="tracking_grievance_title"
                      value={content.tracking_grievance_title}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.tracking_your_complaint")}
                      isValid={content.tracking_grievance_title}
                    />
                    {renderErrorContent("tracking_grievance_title")}
                  </div>
                </Col>
                <Col lg="6" className="p-0">
                  <div className="pr-lg-30">
                    <small className="my-1">{`${t(
                      "landing_page.tracking_message"
                    )}:`}</small>
                    <MCInput
                      type="textarea"
                      name="tracking_grievance_msg"
                      value={content.tracking_grievance_msg}
                      onChange={handleContentChange}
                      placeholder={t(
                        "landing_page.in_ethics_line_its_possible"
                      )}
                      isValid={content.tracking_grievance_msg}
                    />
                    {renderErrorContent("tracking_grievance_msg")}
                  </div>
                </Col>
              </Row>
            </div>
            {/* --- Images --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <Row className="images">
                <h4>{t("landing_page.images")}</h4>
                <div className="images__image">
                  <ImageUpload
                    title={t("landing_page.business_logo")}
                    fieldKey="company_logo"
                    onChange={selectingImages}
                  />
                  <ImageUpload
                    title={t("landing_page.campaing_logo")}
                    icon="bold_radar_3"
                    fieldKey="campaign_logo"
                    onChange={selectingImages}
                  />
                  <ImageUpload
                    title={t("landing_page.QR")}
                    icon="outline_scan_barcode"
                    fieldKey="qr"
                    onChange={selectingImages}
                  />
                  <ImageUpload
                    title={t("landing_page.background_img")}
                    icon="image"
                    fieldKey="backgroud"
                    onChange={selectingImages}
                  />
                </div>
                <div className="text-center">
                  <MCButton
                    label={
                      objectContent > 1
                        ? t("landing_page.save_imgs")
                        : t("landing_page.save_img")
                    }
                    variant={objectContent === 0 ? "secondary" : "primary"}
                    className="w-50 mt-20"
                    onClick={sendImages}
                    disabled={objectContent === 0}
                  />
                </div>
              </Row>
            </div>

            {/* --- How does it work? --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <Row className="howWork">
                <h4>{`${t("landing_page.how_work")}`}</h4>
                <div className="howWork__question pr-lg-30 mt-1 mb-3 ">
                  <img
                    src={megaphone}
                    alt="option1"
                    width={50}
                    height={50}
                    className="howWork__img"
                  />
                  <div className="inputField">
                    <small>{t("landing_page.question")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.what_it_is.question"
                      value={content.how_function.what_it_is.question}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.what_is")}
                      isValid={content.how_function.what_it_is.question}
                    />
                    {renderErrorContent("what_it_is", "question")}
                  </div>
                  <div className="inputField">
                    <small>{t("landing_page.text")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.what_it_is.text"
                      value={content.how_function.what_it_is.text}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.means_comunicate")}
                      isValid={content.how_function.what_it_is.text}
                    />
                    {renderErrorContent("what_it_is", "text")}
                  </div>
                </div>
                <hr />
                <div className="howWork__question pr-lg-30 mb-3">
                  <img
                    src={monitor}
                    alt="option2"
                    className="howWork__img"
                    width={50}
                    height={50}
                  />
                  <div className="inputField">
                    <small>{t("landing_page.question")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.procedure_report.question"
                      value={content.how_function.procedure_report.question}
                      placeholder={t("landing_page.reporting_procedure")}
                      onChange={handleContentChange}
                      isValid={content.how_function.procedure_report.question}
                    />
                    {renderErrorContent("procedure_report", "question")}
                  </div>
                  <div className="inputField">
                    <small>{t("landing_page.text")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.procedure_report.text"
                      value={content.how_function.procedure_report.text}
                      placeholder={t("landing_page.use_the_medium")}
                      onChange={handleContentChange}
                      isValid={content.how_function.procedure_report.text}
                    />
                    {renderErrorContent("procedure_report", "text")}
                  </div>
                </div>
                <hr />
                <div className="howWork__question pr-lg-30 mb-3">
                  <img
                    src={complaint}
                    alt="option3"
                    className="howWork__img"
                    width={50}
                    height={50}
                  />
                  <div className="inputField">
                    <small>{t("landing_page.question")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.generate_report.question"
                      value={content.how_function.generate_report.question}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.generate_report")}
                      isValid={content.how_function.generate_report.question}
                    />
                    {renderErrorContent("generate_report", "question")}
                  </div>
                  <div className="inputField">
                    <small>{t("landing_page.text")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.generate_report.text"
                      value={content.how_function.generate_report.text}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.have_follow_info")}
                      isValid={content.how_function.generate_report.text}
                    />
                    {renderErrorContent("generate_report", "text")}
                  </div>
                </div>
                <hr />
                <div className="howWork__question pr-lg-30 mb-1">
                  <img
                    src={asesor}
                    alt="option4"
                    className="howWork__img"
                    width={50}
                    height={50}
                  />
                  <div className="inputField">
                    <small>{t("landing_page.text")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.follow_up.question"
                      value={content.how_function.follow_up.question}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.consult_follow")}
                      isValid={content.how_function.follow_up.question}
                    />
                    {renderErrorContent("follow_up", "question")}
                  </div>
                  <div className="inputField">
                    <small>{t("landing_page.question")}:</small>
                    <MCInput
                      type="textarea"
                      name="how_function.follow_up.text"
                      value={content.how_function.follow_up.text}
                      onChange={handleContentChange}
                      placeholder={t("landing_page.involved_in_process")}
                      isValid={content.how_function.follow_up.text}
                    />
                    {renderErrorContent("follow_up", "text")}
                  </div>
                </div>
              </Row>
            </div>

            {/* --- Questions --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <Row>
                {/* Add new question */}
                <h4>{t("landing_page.questions")}</h4>
                <small className="my-2">{`${t(
                  "landing_page.add_question"
                )}:`}</small>

                <Col lg="6" className="p-0">
                  <div className="pr-lg-30">
                    <MCInput
                      type="textarea"
                      placeholder={t("landing_page.new_question")}
                      name="questions.question"
                      value={questionToAdd.question}
                      onChange={(e) =>
                        setQuestionToAdd((prev) => ({
                          ...prev,
                          question: e.target.value,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col lg="6" className="p-0">
                  <div className="pr-lg-30">
                    <MCInput
                      type="textarea"
                      placeholder={t("landing_page.new_answer")}
                      name="questions.text"
                      value={questionToAdd.text}
                      onChange={(e) =>
                        setQuestionToAdd((prev) => ({
                          ...prev,
                          text: e.target.value,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col className="p-0 my-3">
                  <div className="pr-lg-30 text-center">
                    <MCButton
                      label={t("landing_page.add")}
                      variant="primary"
                      outline
                      className="w-50"
                      onClick={handleAddQuestion}
                      disabled={!questionToAdd.question || !questionToAdd.text}
                    />
                  </div>
                </Col>
                <hr />
              </Row>
              {/* --- Current Questions --- */}
              <div>
                <h5>
                  {t("landing_page.current_questions")} (
                  {content.questions.length}):
                </h5>
                {content.questions.map((question, index) => (
                  <div key={index}>
                    {editQuestion === index ? (
                      <div className="landingPage__editQuestions border-bottom">
                        <div className="confirm">
                          <MCTooltip text={"save"} position="bottom">
                            <button
                              className={`${
                                !errorQuestions.question && !errorQuestions.text
                                  ? "--save"
                                  : "--noSave"
                              }`}
                              onClick={() => {
                                if (
                                  !errorQuestions.question &&
                                  !errorQuestions.text
                                )
                                  setEditQuestion(null);
                              }}
                            >
                              <BoldTickCircle size={16} />
                            </button>
                          </MCTooltip>
                        </div>

                        <div className="editingInputs">
                          <div className="my-1">
                            <small>{t("landing_page.question")}:</small>
                            <MCInput
                              type="textarea"
                              placeholder={t("landing_page.question")}
                              className="questionInput"
                              value={question?.question}
                              rows={2}
                              onChange={(e) => {
                                updateQuestion(
                                  index,
                                  "question",
                                  e.target.value
                                );
                              }}
                              isValid={!errorQuestions.question}
                            />
                            {errorQuestions.question && (
                              <p className="error">
                                {t("landing_page.field_empty")}
                              </p>
                            )}
                          </div>
                          <div className="mb-2">
                            <small>{t("landing_page.answer")}:</small>
                            <MCInput
                              type="textarea"
                              value={question?.text}
                              rows={3}
                              className="answerInput"
                              placeholder={t("landing_page.answer")}
                              onChange={(e) => {
                                updateQuestion(index, "text", e.target.value);
                              }}
                              isValid={!errorQuestions.text}
                            />
                            {errorQuestions.text && (
                              <p className="error">
                                {t("landing_page.field_empty")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="landingPage__questions border-bottom">
                        <div className="modifying">
                          <MCTooltip
                            text={t("business_units.edit")}
                            position="bottom"
                          >
                            <button
                              className="--edit"
                              onClick={() => handleEditQuestion(index)}
                            >
                              <BoldEdit2 size={16} />
                            </button>
                          </MCTooltip>
                          <MCTooltip
                            text={t("landing_page.delete")}
                            position="bottom"
                          >
                            <button
                              className="--close"
                              onClick={() => handleDeleteQuestion(index)}
                            >
                              <BoldCloseCircle size={16} />
                            </button>
                          </MCTooltip>
                        </div>
                        <div className="mb-3">
                          <h6>{question.question}</h6>
                          <div className="answerContainer">
                            <small>{question.text}</small>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* --- Colors & Typography --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <Row>
                <h4 className="mb-2">{t("landing_page.colors")}</h4>
                {/* --- Buttons --- */}
                <div className="colors">
                  {/* Grievance Button */}
                  <div className="mt-2">
                    <h6 className="mx-2">
                      {t("landing_page.grievance_btn_features")}
                    </h6>
                    <div className="colors__section">
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.grievance_btn_color"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="grievance_btn_color"
                          name="grievance_btn_color"
                          value={colors.grievance_btn_color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </div>
                    {/* Button Desing Preview (Examples) */}
                    <div className="colors_section">
                      <div className="colors__picker">
                        <MCLabel
                          text={`${t("landing_page.preview_btn")}:`}
                          helptip={t(
                            "landing_page.helper_preview_btn_grievance"
                          )}
                          className="my-1"
                        />
                        <fieldset>
                          <button
                            className="--exampleBtn m-2"
                            style={ethicsCodeButton}
                            onMouseEnter={() => setEthicsCodeHovered(true)}
                            onMouseLeave={() => setEthicsCodeHovered(false)}
                          >
                            {t("landing_page.ethics_code")}
                          </button>{" "}
                          <button
                            className="--exampleBtn --pulseBlue m-2"
                            style={grievanceButton}
                          >
                            {content?.grievance_btn ||
                              t("landing_page.report_now")}
                          </button>
                        </fieldset>
                      </div>
                    </div>
                    <hr />
                  </div>
                  {/* Tracking Button */}
                  <div>
                    <h6 className="mx-2">
                      {t("landing_page.features_tracking_btn")}
                    </h6>
                    <BoldBrush4 className="--iconColor" />
                    <MCLabel
                      text={t("landing_page.primary_colors")}
                      helptip={t("landing_page.customize_primary_colors")}
                    />
                    {/* Primary colors (base) */}
                    <div className="colors__section">
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.background_color"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="track_btn_hover_color"
                          name="track_btn_hover_color"
                          value={colors.track_btn_hover_color}
                          onChange={handleColorChange}
                        />
                      </div>
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.text"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="primary_text_color"
                          name="primary_text_color"
                          value={colors.primary_text_color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </div>
                    {/* Secondary Colors (hover) */}
                    <BoldBrush4 className="--iconColor" />
                    <MCLabel
                      text={t("landing_page.secondary_colors")}
                      helptip={t("landing_page.customize_secondary_colors")}
                    />
                    <div className="colors__section">
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.background_color"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="track_btn_color"
                          name="track_btn_color"
                          value={colors.track_btn_color}
                          onChange={handleColorChange}
                        />
                      </div>
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.text"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          name="secondary_text_color"
                          id="secondary_text_color"
                          value={colors.secondary_text_color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </div>

                    {/* Button Desing Preview (Example) */}
                    <div className="colors_section">
                      <div className="colors__picker">
                        <MCLabel
                          text={`${t("landing_page.preview_btn")}:`}
                          helptip={t(
                            "landing_page.helper_preview_btn_tracking"
                          )}
                          className="my-1"
                        />
                        <fieldset>
                          <button
                            className="--exampleBtn m-1"
                            style={trackingButton}
                            onMouseEnter={() => setTrackingHovered(true)}
                            onMouseLeave={() => setTrackingHovered(false)}
                          >
                            {content?.track_btn ||
                              t("landing_page.complaint_tracking")}
                          </button>
                        </fieldset>
                      </div>
                    </div>
                    <hr />
                  </div>
                  {/* main and secondary colors */}
                  <div>
                    <MCLabel
                      text={`${t("landing_page.text")}s`}
                      className="mx-2"
                    />
                    <div className="colors__section">
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.main_color_text"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="primary_color"
                          name="primary_color"
                          value={colors.primary_color}
                          onChange={handleColorChange}
                        />
                      </div>
                      <div className="colors__picker">
                        <small className="mb-1">{`${t(
                          "landing_page.secondary_color_text"
                        )}:`}</small>
                        <Form.Control
                          type="color"
                          id="secondary_color"
                          name="secondary_color"
                          value={colors.secondary_color}
                          onChange={handleColorChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            {/* --- Grievance Channels --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <h4>{t("landing_page.channels")}</h4>
              <small>{t("landing_page.select_channels")}</small>
              <Row className="channels">
                {/* Whatsapp */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pr-lg-30">
                    <div className="channels__title">
                      <div className="--whatsApp">
                        <CallBold />
                      </div>
                      <label className="mb-1">WhatsApp</label>
                      <FormCheck
                        type="switch"
                        checked={channels.whatsapp.is_active}
                        onChange={handleSwitchChange("whatsapp")}
                      />
                    </div>
                    {!channels?.whatsapp?.is_active ? (
                      <MCInput
                        disabled
                        placeholder={t("landing_page.turn_it_on")}
                      />
                    ) : (
                      <CustomPhoneInput
                        id="whatsapp"
                        name="whatsapp"
                        value={channels.whatsapp.phone_number}
                        onChange={(value) =>
                          handleChannelsValue({
                            target: { name: "whatsapp", value },
                          })
                        }
                        isValid={!errorChannels.whatsapp}
                      />
                    )}
                    {errorChannels.whatsapp && (
                      <p className="error">{t("landing_page.field_empty")}</p>
                    )}
                  </div>
                </Col>

                {/* Teléfonos */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pl-lg-30">
                    <div className="channels__title">
                      <CallBold className="--phone" />
                      <label className="mb-1">
                        {t("landing_page.phones")}:
                      </label>
                      <FormCheck
                        type="switch"
                        checked={channels.phones.is_active}
                        onChange={handleSwitchChange("phones")}
                      />
                    </div>
                    {!channels?.phones?.is_active ? (
                      <MCInput
                        disabled
                        placeholder={t("landing_page.turn_it_on")}
                      />
                    ) : (
                      <CustomPhoneInput
                        id="phones"
                        name="phones"
                        value={phoneNumberStrings || ""}
                        onChange={(value) =>
                          handleChannelsValue({
                            target: { name: "phones", value },
                          })
                        }
                        isValid={!errorChannels.phones}
                      />
                    )}
                    {errorChannels.phones && (
                      <p className="error">{t("landing_page.field_empty")}</p>
                    )}
                  </div>
                </Col>

                {/* App móvil */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pr-lg-30">
                    <div className="channels__title">
                      <MonitorMobile className="--iconLanding" />
                      <label className="mb-1">
                        {t("landing_page.mobile_app")}
                      </label>
                      <FormCheck
                        type="switch"
                        checked={channels.app_mobile.is_active}
                        onChange={handleSwitchChange("app_mobile")}
                      />
                    </div>
                    <MCInput
                      id="app_mobile"
                      name="app_mobile"
                      disabled={!channels.app_mobile.is_active}
                      value={channels.app_mobile.text}
                      onChange={handleChannelsValue}
                      placeholder={
                        !channels.app_mobile.is_active
                          ? `${t("landing_page.turn_on_customize")} ${t(
                              "landing_page.mobile_app"
                            )}`
                          : `${t("landing_page.download_app")}`
                      }
                      isValid={!errorChannels.app_mobile}
                    />
                    {errorChannels.app_mobile && (
                      <p className="error">{t("landing_page.field_empty")}</p>
                    )}
                  </div>
                </Col>

                {/* Chat */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pl-lg-30">
                    <div className="channels__title">
                      <BoldMessages2 className="--iconLanding" />
                      <label className="mb-1">Chat</label>
                      <FormCheck
                        type="switch"
                        checked={channels.chat.is_active}
                        onChange={handleSwitchChange("chat")}
                      />
                    </div>
                    <MCInput
                      id="chat"
                      name="chat"
                      disabled={!channels.chat.is_active}
                      value={channels.chat.text}
                      onChange={handleChannelsValue}
                      placeholder={
                        !channels.chat.is_active
                          ? `${t("landing_page.turn_on_customize")} Chat`
                          : `${t("landing_page.welcome_complaint_line")}`
                      }
                      isValid={!errorChannels.chat}
                    />
                    {errorChannels.chat && (
                      <p className="error">{t("landing_page.field_empty")}</p>
                    )}
                  </div>
                </Col>

                {/* Correo electrónico */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pr-lg-30">
                    <div className="channels__title">
                      <OutlineSmsTracking className="--iconLanding" />
                      <label className="mb-1">{t("landing_page.email")}</label>
                      <FormCheck
                        type="switch"
                        checked={channels.email.is_active}
                        onChange={handleSwitchChange("email")}
                      />
                    </div>
                    <MCInput
                      id="email"
                      name="email"
                      type="email"
                      disabled={!channels.email.is_active}
                      value={channels.email.email}
                      onChange={handleChannelsValue}
                      placeholder={
                        !channels.email.is_active
                          ? `${t("landing_page.turn_on_customize")} ${t(
                              "landing_page.email"
                            )}`
                          : "report@example.com"
                      }
                      isValid={!errorChannels.email}
                    />
                    {errorChannels.email && (
                      <p className="error">{t("landing_page.valid_email")}</p>
                    )}
                  </div>
                </Col>

                {/* App Chat */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pl-lg-30">
                    <div className="channels__title">
                      <BoldMessages3 className="--iconLanding" />
                      <label className="mb-1">
                        {t("landing_page.chat_app")}
                      </label>
                      <FormCheck
                        type="switch"
                        checked={channels.chat_app.is_active}
                        onChange={handleSwitchChange("chat_app")}
                      />
                    </div>
                    <MCInput
                      id="chat_app"
                      name="chat_app"
                      disabled={!channels.chat_app.is_active}
                      value={channels.chat_app.text}
                      onChange={handleChannelsValue}
                      placeholder={
                        !channels.chat.is_active
                          ? `${t("landing_page.turn_on_customize")} App Chat`
                          : `${t("landing_page.welcome_complaint_line")}`
                      }
                      isValid={!errorChannels.chat_app}
                    />
                    {errorChannels.chat_app && (
                      <p className="error">{t("landing_page.field_empty")}</p>
                    )}
                  </div>
                </Col>

                {/* Website */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pr-lg-30 channels__title">
                    <Monitor className="--iconLanding" />
                    <label className="mb-1">Website</label>
                    <FormCheck
                      id="website"
                      name="website"
                      type="switch"
                      checked={channels.website}
                      onChange={switchBoolean("website")}
                    />
                  </div>
                </Col>

                {/* App teléfono */}
                <Col lg="6" className="p-0 mt-2">
                  <div className="pl-lg-30 channels__title">
                    <Mobile className="--iconLanding" />
                    <label className="mb-1">
                      {t("landing_page.phone_app")}
                    </label>
                    <FormCheck
                      id="phone_app"
                      name="phone_app"
                      type="switch"
                      checked={channels.phone_app}
                      onChange={switchBoolean("phone_app")}
                    />
                  </div>
                </Col>
                {/* <pre>{JSON.stringify(channels,null,2)}</pre> */}
              </Row>
            </div>

            {/* --- Support Material --- */}
            <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
              <h4>{t("landing_page.support_material")}</h4>
              <small>{t("landing_page.upload_support_files")}</small>
              <div className="mt-3 supportMaterial">
                <div className="supportMaterial__uploader mb-2">
                  <SupportFileUpload
                    onChange={selectingSupportFile}
                    ref={supportUploadRef}
                  />
                  {file && (
                    <MCButton
                      label={t("landing_page.upload_support_files_btn")}
                      variant={`${
                        fileName === "" || fileName === undefined
                          ? "secondary"
                          : "primary"
                      }`}
                      disabled={fileName === "" || fileName === undefined}
                      onClick={sendSupportFile}
                      className="mt-2"
                    />
                  )}
                </div>
                <div className="mb-2">
                  <SupportFilesReader files={files} />
                </div>
              </div>

              {/* Dominios */}
              <div>
                {/* <div className="p-30 mt-20 dyTheme1 dyBorder1 rounded">
                <h5 className="mb-30">Dominios</h5>
                <Row>
                  <Col lg="6" className="p-0">
                    <div className="pr-lg-30">
                      <MCLabel className="mb-2" text="Nombre de tu dominio" />
                      <MCInput placeholder="bimboteescucha.com" />
                    </div>
                  </Col>

                  <Col lg="6" className="p-0">
                    <div className="pl-lg-30">
                      <SimpleSelect
                        label="Actualizar dominio"
                        options={options}
                      />
                    </div>
                  </Col>
                </Row>
                <small>¿Necesitas ayuda para configurar tu dominio?</small>
              </div> */}
              </div>

              <div>
                {/* <Row>
                  <Col xl="6" className="mt-30 p-0">
                    <small>Material de apoyo (Eje. Medidas preventivas)</small>
                    <div className="settings-landing-upload-doc-btn">
                      <div className="d-flex justify-content-center align-items-center">
                        <span id="file-name2">CartaConsentimiento.pdf</span>
                        <Icon name="outline_document_upload" size={16} />
                      </div>
                      <input
                        type="file"
                        className="d-none"
                      />
                    </div>
                  </Col>
                </Row> */}
              </div>

              <div>
                {/* <Row>
                  <Col xl="6" className="mt-30 p-0">
                    <small>Material de apoyo (Eje. Toma de decisiones)</small>
                    <div className="settings-landing-upload-doc-btn">
                      <div className="d-flex justify-content-center align-items-center">
                        <span id="file-name2">CartaConsentimiento.pdf</span>
                        <Icon name="outline_document_upload" size={16} />
                      </div>
                      <input
                        type="file"
                        className="d-none"
                      />
                    </div>
                  </Col>
                </Row> */}
              </div>
            </div>

            {/* Politica de privacidad */}
            <div>
              {/* <div className="p-30 mt-30 dyTheme1 dyBorder1 rounded">
                <h5 className="mb-30">Políticas de privacidad</h5>
                <small>Adjunta tu política de privacidad.</small>

                <Row>
                  <Col xl="6" className="mt-30 p-0">
                    <small>Política de privacidad (obligatorio)</small>
                    <div className="settings-landing-upload-doc-btn">
                      <div className="d-flex justify-content-center align-items-center">
                        <span id="file-name2">CartaConsentimiento.pdf</span>
                        <Icon name="outline_document_upload" size={16} />
                      </div>
                      <input
                        type="file"
                        className="d-none"
                      />
                    </div>
                  </Col>
                </Row>
              </div> */}
            </div>
          </div>
        </Col>

        {/* Template Hero */}
        <Col lg={5} className="mt-1 p-0 sticky-col">
          <div className="p-20 dyTheme1 dyBorder1 rounded pr-lg-30">
            <h6 className="mt-3">Template HERO</h6>
            {/* <small>Guardado por última vez el: 23 de Agosto de 2021</small> */}
            <div className="template-hero mt-20">
              <h3 className="mb-0">TEMPLATE HERO</h3>
            </div>
            <div className="mt-4 text-center">
              <MCButton
                label={t("landing_page.post_landing_page")}
                variant="primary"
                className="w-100 mt-20"
                onClick={handleSaveLanding}
              />
              <MCButton
                label={t("landing_page.preview_website")}
                variant="primary"
                outline
                className="w-100 mt-20"
              />
              <small className="mt-20">
                {t("landing_page.by_publishing_you_agree")}
                <br />
                <Link to="/">{t("landing_page.terms_and_conditions")}</Link>
              </small>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
