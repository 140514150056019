import { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Dropdown } from "react-bootstrap";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";

const BuildStep = ({
  tryToCreate,
  step,
  index,
  activeStep,
  hdlActStp,
  hdlStpNme,
  hdlMveStp,
  hdlDelStep,
  isValid,
  setValidCreateSteps,
}) => {
  const [stepName, setStepName] = useState(step.title);
  const [t] = useTranslation("forms");

  useEffect(() => {
    setStepName(step.title);
    const errors = hdlVldStp();
    setValidCreateSteps((pre) =>
      pre.map((item, idx) => (index === idx ? errors : item))
    );
  }, [step]);

  const hdlVldStp = () => {
    let errors = [];
    if (typeof step.title === "string" && step.title.trim() === "") {
      errors.push(t("buildSteps.field_no_empty"));
    }
    return errors;
  };

  return (
    <div
      className={`step-item dyTheme1 dyBorder1 ${
        isValid.length > 0 && tryToCreate ? "error" : ""
      } ${index === activeStep ? "active" : ""}`}
    >
      <div className="step-item-lbar"></div>
      <div className="step-item-info">
        <div className="w-100" onClick={() => hdlActStp(index)}>
          <input
            type="text"
            className="step-item-input"
            value={step.title}
            onChange={(e) => hdlStpNme(e.target.value, index)}
          />
        </div>

        <div className="d-flex">
          <div className="step-item-arrows">
            <div onClick={() => hdlMveStp("up", index)}>
              <Icon name="arrow_up_4" className="dyIcon2" />
            </div>
            <div onClick={() => hdlMveStp("down", index)}>
              <Icon name="arrow_down" className="dyIcon2" />
            </div>
          </div>
          <div className="step-item-more">
            <Dropdown>
              <Dropdown.Toggle variant="">
                <div className="form-list-item-more">
                  <Icon name="more_dots" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => hdlDelStep(index)}>
                  <Icon name="trash_icon" />
                  <span className="ms-2">{t("buildSteps.delete")}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BuildSteps = ({
  steps,
  activeStep,
  hdlActStp,
  hdlStpNme,
  hdlAddStep,
  hdlDelStep,
  hdlMveStp,
  validCreateSteps,
  setValidCreateSteps,
  tryToCreate,
  gTheme,
}) => {
  const [t] = useTranslation("forms");
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    setRerender(true);
  }, [steps.length]);

  return (
    <div className="steps-list">
      <div>
        <h5>{t("buildSteps.steps")}</h5>
        <small>{t("buildSteps.define_steps_form")}</small>
      </div>
      <div>
        {!rerender &&
          steps.map((item, index) => (
            <BuildStep
              key={index}
              step={item}
              index={index}
              activeStep={activeStep}
              hdlActStp={hdlActStp}
              hdlStpNme={hdlStpNme}
              hdlMveStp={hdlMveStp}
              hdlDelStep={hdlDelStep}
              isValid={validCreateSteps[index]}
              setValidCreateSteps={setValidCreateSteps}
              tryToCreate={tryToCreate}
              gTheme={gTheme}
            />
          ))}

        {Array.isArray(steps) && steps.length < 4 && (
          <div
            className="step-item add"
            onClick={() =>
              hdlAddStep({ name: "any", title: "any", description: "any" })
            }
          >
            <div className="step-item-lbar"></div>
            <div className="step-item-info">
              <input
                type="text"
                className="step-item-input"
                defaultValue={t("buildSteps.add_step")}
                onFocus={(e) => e.target.blur()}
              />
              <div className="step-item-more">
                <Button variant="">
                  <Icon name="add_square" />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
