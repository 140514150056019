import { useSelector } from "react-redux";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import "./Label.scss";

export const MCLabel = ({
  text = "",
  className = "",
  isRequired = false,
  helptip = false,
  ...rest
}) => {
  const { gTheme } = useSelector((state) => state.theme);

  const buildClassName = () => {
    let classNameString = `${className}`;
    if (gTheme === "dark") classNameString += ` ${gTheme}`;
    if (isRequired) classNameString += " required";
    return classNameString;
  };

  return (
    <label className={buildClassName()} {...rest}>
      {`${text} ${isRequired ? "*" : ""}`}
      {helptip && (
        <>
          &nbsp;
          <MCTooltip text={helptip} position="top">
            <span className="helptip">?</span>
          </MCTooltip>
        </>
      )}
    </label>
  );
};
