import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;
//Function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

export const createAttachment = async (files) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/attachment/`, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error);
    showErrorMessage(`${language.error_ocurred_attach_file}`);
    throw new Error(`${language.error_ocurred_attach_file}`)
  }
};

export const createAttachmentForReport = async (id, files) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/create/attachment/report/${id}/`, files,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return resp.data;
  } catch (error) {
    console.error(error);
    showErrorMessage(`${language.error_creating_attachment_report}`);
    throw new Error(`${language.error_creating_attachment_report}`)
  }
};

export const getListAttachmentForReport = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/list/attachment/report/${id}/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_get_list_report_attachments}`);
    console.error(error);
  }
};

export const deleteAttachmentForReport = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(
      `${tenant}/api/delete/attachment/report/${id}/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_deleting_report_attachment}`);
    console.error(error);
  }
};
