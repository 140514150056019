import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";


const showErrorMessage = (error) => { 
  enqueueSnackbar(`${error.message}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 15000,
  });
};

export const getDefaultNotifications = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notification-system/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error getting default notifications");
  }
};

export const getNotificationsList = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notifications/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error getting list of notifications");
  }
};


// NOTIFICATION CENTER ========================================================================================================================================================================

export const createNotificationCenter = async (payload) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/notifications/notification-center/`, payload,  {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const getListNotificationCenter = async (url, limit = 15, offset = 0) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notifications/notification-center/list/`, {
      params: {
        limit,
        offset
      },
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const getNotificationCenter = async (alias) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notifications/notification-center/retrieve/${alias}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const updateNotificationCenter = async (alias, payload) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/notifications/notification-center/${alias}/`, payload, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const deleteNotificationCenter = async (alias) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(`${tenant}/api/notifications/notification-center/delete/${alias}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};



// NOTIFICATION SETUP USER ====================================================================================================================================================================


export const createNotificationSystem = async (payload = {"notification_center": "NCls01K843228y3u216F2k1yW3l40g0j95", "send_email": true,"send_system_notification": true}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/notifications/notificarion-setup/`, payload,  {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error al crear notificacion del sistema")
  }
};

export const getListNotificationSystem = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notifications/notificarion-setup/list/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const updateStatusNotificationSystem = async (alias, payload) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/notifications/notificarion-setup/status/${alias}/`, payload, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};

export const updateSendersNotificationSystem = async (alias, payload = { "send_email": false, "send_system_notification": true }) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/notifications/notificarion-setup/senders/${alias}/`, payload, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
  }
};


// DUMMYS ====================================================================================================================================================================



export const fakeGetListNotificationCenter = async() => {
  const objTemp = {
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
      {
        "alias": "NC080A3Q091l2mk6205u1v2WP417T70kk1",
        "title": "Titulo de notificación",
        "origin": "REPORT"
      }
    ]
  }
  const fakeP = new Promise((res, rej)=>{
    setTimeout(() => {
      res(objTemp);
    }, 1500);
  })
  return fakeP;
}

export const fakeGetNotificationCenter = async() => {
  const objTemp = {
    "message": "ok",
    "data": {
      "alias": "NC080A3Q091l2mk6205u1v2WP417T70kk1",
      "title": {
        "es": "Titulo de notificación",
        "en": "Titulo de notificación"
      },
      "message": {
        "es": "Este es el mensaje que llevara la notificación",
        "en": "Este es el mensaje que llevara la notificación"
      },
      "info": {
        "es": [
          {
            "name": "Este es el mensaje que llevara la info",
            "alias": "Este es el mensaje que llevara la info"
          },
          {
            "name": "Este es el mensaje que llevara la info2",
            "alias": "Este es el mensaje que llevara la info2"
          },
        ],
        "en": [
          {
            "name": "Este es el mensaje que llevara la info3",
            "alias": "Este es el mensaje que llevara la info3"
          },
          {
            "name": "Este es el mensaje que llevara la info4",
            "alias": "Este es el mensaje que llevara la info4"
          },
        ]

      },
      "created_at": "2023-11-27T10:40:50.681755",
      "updated_at": "2023-11-27T10:40:50.681786",
      "origin": "REPORT"
    }
  }
  const fakeP = new Promise((res, rej)=>{
    setTimeout(() => {
      res(objTemp);
    }, 1500);
  })
  return fakeP;
}

export const fakeGetListNotificationSystem = async() => {
  const objTemp = {
  "count": 1,
  "next": null,
  "previous": null,
  "results": [
    {
      "alias": "NSBU19dLQ57H421o17P3W33200fC9ew1242W",
      "title": "Titulo de notificación",
      "send_email": true,
      "send_system_notification": true,
      "is_active": true,
      "created_at": "2023-11-30T14:43:57.930073"
    },
    {
      "alias": "NSBU19dLQ57H421o17P3W33200fC9ew1242X",
      "title": "Titulo de notificación2",
      "send_email": true,
      "send_system_notification": true,
      "is_active": true,
      "created_at": "2023-11-30T14:43:57.930073"
    }
  ]
}
  const fakeP = new Promise((res, rej)=>{
    setTimeout(() => {
      res(objTemp);
    }, 1500);
  })
  return fakeP;
}



export const NotificationByUserView = async (registros) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/notification-by-user/`, {
      params: { limit: registros },  // Corrección: registros debe estar dentro de params
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      }
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error getting list of notifications");
  }
};



export const NotificationByUserPost = async (id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/notification-by-user-put/`, 
      { id },
      {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
        },
        });
        
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error getting list of notifications");
  }
};




export const MarkAllAsRead = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/mark-all-as-read/`, {}, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(error);
    throw new Error("Error marking all as read");
  }
};