import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;
//functions to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
const showWarningMessage = (msg) => {
  enqueueSnackbar(`${msg}`, {
    variant: "warning",
    preventDuplicate: true,
  });
};

// Utilidades de Revision del asesor
export const utilitiesAdvisorReview = async (
  data = { is_own: false, catalogue: "", path: "" }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/advisor/review/utilities/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_advisor_review_data}`);
    console.error(error);
  }
};

// DETALLES DE LA REVISION DEL ASESOR
export const detailAdvisorReview = async (reportId) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/advisor/review/detail/${reportId}/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      // showWarningMessage(`${language.report_has_not_yet_been_review_advisor}`);
      console.log(`${language.report_has_not_yet_been_review_advisor}`);
    } else {
      showErrorMessage(`${language.error_obtaining_consultant_review_details} (${error.response.status}z)`);
      console.error(error);
    }
  }
};

// CREAR REVISION DEL ASESOR
export const createAdvisiorReview = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/advisor/review/create/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_when_creating_advisor_review}`);
    console.error(error);
  }
};

// ACTUALIZAR REVISION DEL ASESOR
export const updateAdvisiorReview = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/advisor/review/update/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_updating_advisor_review}`);
    console.error(error);
  }
};
