import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  assignUserToReport,
  deleteUserFromReport,
  listAssignedUsers,
  listUsersWithoutSpecificReport,
} from "../../../../apis";
import _ from "lodash";
import SearchBar from "../../../SearchBar/Search";
import { useTranslation } from "react-i18next";
import { ModalAssignUser } from "./ModalAssignUser";
import { MCButton, MCTooltip } from "../../../MainComponents";
import { enqueueSnackbar } from "notistack";

const sessionEmail = JSON.parse(localStorage.getItem("Session"))?.email || "";

const UserItem = ({ user, handleChange, target }) => {
  const [isLoading, setIsLoading] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleClickInactive = () => {
    if (user.email === sessionEmail) return;
    handleChange(user, setIsLoading, target);
  };

  const handleClickActive = () => {
    setShowModal(true);
  };

  return (
    <>
      {
        target === "inactive" &&
        <ModalAssignUser 
          user={user} 
          handleChange={handleChange}
          setIsLoading={setIsLoading}
          show={showModal} 
          onHide={()=>setShowModal(false)} 
        />
      }
      <li
        key={user.email}
        disabled={isLoading}
        onClick={target === "inactive" ? handleClickActive : handleClickInactive}
        className={`${user.email === sessionEmail ? "myself" : ""}`}
      >
        {user.name} / {user.email}
      </li>
    </>
  );
};

export const Users = ({ idReport }) => {
  const [t] = useTranslation("users");

  //-- Entire user list gotten from api
  const [activeUsers, setActiveUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  //-- Filtred users by search
  const [filtredInactiveUsers, setFiltredInactiveUsers] = useState([]);
  //-- Search state
  const [search, setSearch] = useState("");
  const [showModalAddExternalUser, setShowModalAddExternalUser] = useState(false);

  //-- Get users assigned and without assigns
  useEffect(() => {
    Promise.all([
      listAssignedUsers(idReport),
      listUsersWithoutSpecificReport(idReport),
    ])
    .then(([assign, without]) => {
      setActiveUsers(assign.users);
      setInactiveUsers(without.users);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  //=> Handle assign status active and inactive
  const handleChange = async (user, setIsLoading, target, rolesInvestigation, rolesResolution, details) => {
    
    const isSearchedUser = (names) => {
      const cleanedSearch = search.trim().replace(/\s+/g, " ");
      const splitedSearch = cleanedSearch.split(" ");
      const newNames = names.split(" ");
      const lowerNames = newNames.map((name) => name.toLowerCase());
      for (const wordSearch of splitedSearch) {
        if (!lowerNames.includes(wordSearch.toLowerCase())) {
          return false;
        }
      }
      return true;
    };

    setIsLoading(true);
    
    try {


      if (target === "active") { // Esta actualmente activo y se va a desactivar
        await deleteUserFromReport(user.id, idReport);
        const kickedUser = activeUsers.find((item) => item.id === user.id);
        setActiveUsers(activeUsers.filter((item) => item.id !== user.id));
        setInactiveUsers([...inactiveUsers, kickedUser]);
        if (isSearchedUser(user.name)) {
          setFiltredInactiveUsers([...filtredInactiveUsers, kickedUser]);
        }
        setIsLoading(false);
      }


      if (target === "inactive") { // Esta actualmente inactivo y se va a activar
        await assignUserToReport(user.id, idReport, rolesInvestigation, rolesResolution, details);
        const kickedUser = inactiveUsers.find((item) => item.id === user.id);
        setInactiveUsers(inactiveUsers.filter((item) => item.id !== user.id));
        setActiveUsers([...activeUsers, kickedUser]);
        setFiltredInactiveUsers(
          filtredInactiveUsers.filter((item) => item.id !== user.id)
        );
        setIsLoading(false);
        enqueueSnackbar("Usuario asignado", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 3000,
        });
      }


      if (target === "external") { // Al crear un usuario y agregarlo
        await assignUserToReport(user.id, idReport, rolesInvestigation, rolesResolution, details);
        const [assign, without] = await Promise.all([
          listAssignedUsers(idReport),
          listUsersWithoutSpecificReport(idReport),
        ])
        setActiveUsers(assign.users);
        setInactiveUsers(without.users);
        setIsLoading(false);
        enqueueSnackbar("Usuario asignado", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 3000,
        });
      }


    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  //=> Handle search users for find user who match with the search
  const handleSearch = (value) => {
    setSearch(value);
  
    if (value.trim() === "") {
      setFiltredInactiveUsers([]); // Si la búsqueda está vacía, mostrar todos los usuarios inactivos
      return;
    }
  
    const cleanedSearch = value.trim().toLowerCase();
    const filtredUsersTemp = inactiveUsers.filter((user) => {
      const userLowerCase = user.name.toLowerCase();
      return userLowerCase.includes(cleanedSearch);
    });
  
    setFiltredInactiveUsers(filtredUsersTemp);
  };

  return (
    <>
      <ModalAssignUser 
        handleChange={handleChange} 
        setIsLoading={()=>{}}
        registred={false}
        show={showModalAddExternalUser} 
        onHide={()=>setShowModalAddExternalUser(false)}
      />
      <div className="my-3 p-4 dyTheme1 dyBorder1 rounded d-flex flex-column flex-md-row justify-content-between align-items-center">
        <h4 className="text-center mb-0">{t("Users.manage_users")}</h4>
      </div>

      <section className="my-3 container-report-users">
        <Row>
          <Col lg="6" className="p-0 pe-lg-2">
            <div className="dyTheme1 dyBorder1 rounded p-4 inactive-users">
              <div className="d-flex align-items-center">
                <SearchBar
                  placeholder={t("Users.enter_user")}
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <MCButton variant="primary" icon="outline_profile_add" iconSize={24} className="py-0 px-2" size="sm" onClick={()=>setShowModalAddExternalUser(true)} />
              </div>

              <hr />

              <ul>
                {filtredInactiveUsers.length === 0 && (
                  <h5>{t("Users.no_results")}</h5>
                )}
                {filtredInactiveUsers.map((item) => (
                  <UserItem
                    user={item}
                    handleChange={handleChange}
                    target="inactive"
                  />
                ))}
              </ul>
            </div>
          </Col>

          <Col lg="6" className="p-0 ps-lg-2 mt-3 mt-lg-0">
            <div className="dyTheme1 dyBorder1 rounded p-4 active-users">
              <h5>{`${t("Users.assigned_user")}${
                activeUsers.length === 0 ? ":" : ` (${activeUsers.length})`
              }`}</h5>
              <hr />
              <ul>
                {activeUsers.map((item, i) => (
                  <UserItem
                    key={i}
                    user={item}
                    handleChange={handleChange}
                    target="active"
                  />
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};
