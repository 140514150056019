import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../Loading/String/Loading";
//Modal to choose & create a new type of investigation
export const AddSimpleInvModal = ({ toggleModal, additionalInv }) => {
  const [t] = useTranslation("investigation");
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (type) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
  
    additionalInv(type).finally(() => {
      setIsLoading(false);
      window.location.reload();
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <h5 className="text-center">
            {t("investigation.procesing_request_wait_a_moment")}
          </h5>
          <Modal.Body>
            <Loading />
          </Modal.Body>
        </>
      ) : (
        <>
          <h5 className="text-center">
            {t("investigation.what_kind_investigation")}
          </h5>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <MCButton
              label={t("investigation.advanced_inv")}
              variant="primary"
              className="me-1 my-2"
              onClick={() => handleClick("advance")}
              disabled={isLoading}
            />
            <MCButton
              label={t("investigation.basic_inv")}
              variant="primary"
              outline
              className="me-1 my-2"
              onClick={() => handleClick("simple")}
              disabled={isLoading}
            />
          </Modal.Body>
        </>
      )}
    </>
  );
};
