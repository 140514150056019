import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from "react-bootstrap";
import { CDBStep, CDBStepper } from "cdbreact";
import {useTranslation} from "react-i18next";
const Step = () => {
    const [t] = useTranslation("");
    const { pathname } = useLocation();
    const navigate = useNavigate();

    let page_number = 1;

    if (pathname === "/") {
        page_number = 1;
    } else if( pathname === "/sign-plan" ){
        page_number = 2;
    } else if( pathname === "/sign-payment-info" ){
        page_number = 3;
    } 

    return (
        <>
            <Row className="justify-content-end">
                <Col md="4" sm="12" id="register-step" className='p-0'>
                    <CDBStepper direction="horizontal" md="4" currentStepNumber={1} steps={[1,2,3]} stepColor="" >
                        <CDBStep
                            id={1}
                            icon="stack-1x"
                            name="Registro"
                            onClick={()=>navigate("/")}
                            active={page_number}
                            />
                        <CDBStep
                            id={2}
                            icon="stack-1x"
                            name="Planes"
                            onClick={()=>navigate("/sign-plan")}
                            active={page_number}
                            />
                        <CDBStep
                            id={3}
                            icon="dragon"
                            name="Pago"
                            onClick={()=>navigate("/sign-payment-info")}
                            active={page_number}
                        />
                    </CDBStepper>
                </Col>
            </Row>
        </>
    )
}
export default Step;