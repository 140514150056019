import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { startLoggedOut } from "../../store/auth/authThunks";
import { useTemporizer } from "../../hooks/useTemporizer";
import { useSelector } from "react-redux";
import ImageSessionExpired from "../../assets/image/Session/Imagen Tiempo sencilla.png"
import ImageSessionAboutToExpire from "../../assets/image/Session/Imagen aviso sencilla.png"
import DigitBackGround from "../../assets/image/Session/cuadrito.png"
import { Book } from "./Book";

const DigitCrono = ({ position, timeString, className = "", ...rest }) => {

  const [digit, setDigit] = useState(position);

  useEffect(() => {
    const timeDigit = timeString.charAt(position);
    setDigit(timeDigit)
  }, [timeString]);

  return (
    <div className={`digit-container ${className}`}>
      <img src={DigitBackGround} className="background-digit"/>
      <div className="digit">
        {digit}
      </div>
    </div>
  )
}

const SessionIsAboutToExpire = ({ timeString, handleYes, handleNo }) => {
  return (
    <Modal.Body>
      <div className="image-container">
        <img src={ImageSessionAboutToExpire} className="main-img" alt="main-image" style={{ marginTop: "-60px" }} />
        <div className="crono-container">
          <div className="crono">
            <div className="area d-flex me-1">
              <DigitCrono position={3} timeString={timeString} className="me-1" />
              <DigitCrono position={4} timeString={timeString} />
            </div>
            <div className="separator d-flex align-items-center">
              <span>
                :
              </span>
            </div>
            <div className="area d-flex ms-1">
              <DigitCrono position={6} timeString={timeString} className="me-1" />
              <DigitCrono position={7} timeString={timeString} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <span>
          Tu sesión está a punto de caducar
        </span>
        <br />
        <span>
          ¿Deseas continuar?
        </span>

        <div className=" mt-2 d-flex flex-column flex-md-row">
          <button className="btn-session no me-md-1" onClick={handleNo}>
            No
          </button>
          <button className="btn-session yes mt-2 mt-md-0 ms-md-1" onClick={handleYes}>
            Si
          </button>
        </div>
      </div>
    </Modal.Body>
  )
}

const SessionExpired = ({ setShowModal, stopTimer, resetTimer }) => {

  const handleLoginAgain = () => {
    stopTimer();
    resetTimer();
    setShowModal(false);
  }

  return (
    <Modal.Body>
      <div className="image-container">
        <img src={ImageSessionExpired} alt="" className="main-img" />
      </div>
      <div>La sesión ha caducado</div>
      <button className="btn-session again" onClick={handleLoginAgain}>
        Vuelve a iniciar sesión
      </button>
    </Modal.Body>
  )
}

export const ModalKeepSessionAlive = () => {

  const timeForModal = 1800000; // 30min
  const temporizer = 120000; // 2min
  
  // const timeForModal = 130000; // 2min 10seg
  // const temporizer = 120000; // 2min

  // const timeForModal = 20000; // 2min 10seg
  // const temporizer = 10000; // 2min

  const { status } = useSelector(state=>state.auth);
  
  const lastLoginTime = localStorage.getItem("LoginTime");
  const currentTime = new Date().getTime();
  const timeElapsed = currentTime - parseInt(lastLoginTime, 10);
  const timeRemaining = timeForModal - timeElapsed;
  
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const { startTimer, stopTimer, resetTimer, timeString } = useTemporizer({ millisecons: temporizer, onComplete });

  useEffect(() => { 
    if (status === "checking" || status === "not-authenticated") return;
    if (!lastLoginTime) return;
    if (timeRemaining <= 0) {
      localStorage.setItem("LoginTime", new Date().getTime().toString());
      setSessionExpired(true);
      setShowModal(true);
      onComplete()
      return;
    }
    const sessionTimeout = setTimeout(() => {
      setShowModal(true);
      setSessionExpired(false);
      startTimer();
    }, timeRemaining - temporizer);
    return () => {
      clearTimeout(sessionTimeout);
    };
  }, [status]);

  function handleYes() {
    setShowModal(false);
    stopTimer();
    resetTimer();
    localStorage.setItem("LoginTime", new Date().getTime().toString());
    setTimeout(() => {
      setShowModal(true);
      startTimer();
    }, timeForModal - temporizer);
  }

  function handleNo() {
    setShowModal(false);
    stopTimer();
    resetTimer();
    dispatch(startLoggedOut());
  }

  function onComplete() {
    stopTimer();
    resetTimer();
    setSessionExpired(true);
    dispatch(startLoggedOut());
  }

  return (
    <Modal 
      show={showModal} 
      onHide={()=>setShowModal(false)} 
      backdrop="static" keyboard={false} 
      className="modal-keep-session" 
      centered
      contentClassName={ sessionExpired ? "content-session-expired" : "content-session-about-to-expire" }
    >
      {
        sessionExpired 
        ? <SessionExpired 
            setShowModal={setShowModal}
            stopTimer={stopTimer}
            resetTimer={resetTimer}
          />
        : <SessionIsAboutToExpire
            timeString={timeString}
            handleYes={handleYes}
            handleNo={handleNo}
          />
      }
    </Modal>
  )
}