import * as React from "react";
import { styled } from "@mui/material/styles";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import { MoreIconDots } from "../../../Icon";
import Icon from "../../../Icon/Icon";

/**
 * @param action Array of action buttons
 * @param direction ["down", "left", "right", "up"]
 * @param iconName String, dynamic icon name
 * @param className Additional class to handle styles from parent
 * @param style Inline styling if necessary
 * @param rotationAngle Icon rotation (deg) on click | hover
 * @param iconColor Customizable Icon Color
 * @param hoverColor Customizable button background color on click or hover
 * @param actionMargin Margin relative to position from main button
 * @param tooltipPlacement Tooltip positioning ["up", "down", "bottom", "left"]
 * @example [] actions
 * const actions = [{icon: <Icon>, name: "tootip title", onClick: () => {}, style: {}, className: "class-name", sx: {} }]
 */

export const EGSpeedDial = ({
  actions = [],
  direction = "left",
  iconName = "more_dots",
  className,
  style,
  width = 35,
  height = 35,
  rotationAngle = 90,
  iconColor = "white",
  bgColor = "primary",
  hoverColor = "primary",
  actionMargin = 10,
  actionSize = 33,
  tooltipPlacement = "up",
  ...rest
}) => {
  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    // Specific styles for main button
    ".MuiFab-primary": {
      // Ajusta el tamaño del botón principal
      transition: "none !important",
    },
    "& .MuiSpeedDial-fab": {
      width: width,
      height: height,
      padding: 0,
      marginLeft: direction === "left" ? "-15px" : 0,
      marginRight: direction === "right" ? "-15px" : 0,
      backgroundColor: theme?.palette[bgColor]?.main || bgColor,
      "&:hover": {
        // Darker shade in hover if it is a color from the palette.
        backgroundColor: theme?.palette[bgColor]?.main
          ? theme.palette[bgColor].dark
          : theme.palette[hoverColor].main || hoverColor,
        // If it's a custom color, it stays the same
      },
    },
    "& .MuiSpeedDialIcon-icon": {
      display: "flex",
      alignItems: "center",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover .MuiSpeedDialIcon-icon": {
      // Hover rotation
      transform: `rotate(${rotationAngle}deg)`,
    },
    "& .MuiSpeedDial-actions": {
      height: "auto",
    },
  }));

  const StyledSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
    maxHeight: actionSize,
    width: actionSize + 2,
    padding: 6,
    marginTop: direction === "up" ? actionMargin : 0,
    marginBottom: direction === "down" ? actionMargin : 0,
    marginRight: direction === "left" ? actionMargin : 0,
    marginLeft: direction === "right" ? actionMargin : 0,
  }));

  return (
    <StyledSpeedDial
      ariaLabel="SpeedDial example"
      icon={<SpeedDialIcon icon={<Icon name={iconName} color={iconColor} />} />}
      direction={direction}
      className={className}
      style={style}
      {...rest}
    >
      {actions.map((action) => (
        <StyledSpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          style={action.style}
          tooltipPlacement={tooltipPlacement}
          className={action.className}
          onClick={action.onClick}
        />
      ))}
    </StyledSpeedDial>
  );
};
