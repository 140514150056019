import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

/**
 * Input of custom switch type based on Material UI library
 * @param disabled true|false
 * @param onChange event to change state
 * @param checked swtich state
 */

export const EGSwitch = ({ disabled = false, onChange, checked, ...props }) => {
  // Style customization
  const MainSwitch = styled(Switch)(({ theme }) => ({
    // Switch base
    "& .MuiSwitch-switchBase": {
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      // Switch disabled
      "&.Mui-disabled": {
        // Custom border and background color
        "& .MuiSwitch-thumb": {
          border: `1px solid ${theme.palette.blueGray100.main}`,
          backgroundColor: theme.palette.common.white,
        },
        // Slide track color
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.blueGray700.main,
        },
      },
      // Switch checked
      "&.Mui-checked": {
        // Custom border and background color
        "& .MuiSwitch-thumb": {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.common.white,
          boxShadow: `0 0 2px 3px ${alpha(theme.palette.primary.light, 0.3)}`,
        },
        // Slide track color
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.success.main,
          opacity: 1,
        },
      },
      // Combination of checked and disabled styles
      "&.Mui-checked.Mui-disabled": {
        // Custom border and background color
        "& .MuiSwitch-thumb": {
          border: `2px solid ${theme.palette.blueGray100.main}`,
          backgroundColor: theme.palette.common.white,
          boxShadow: `0px 0 2px 3px ${alpha(
            theme.palette.blueGray100.main,
            0.4
          )}`,
        },
        // Slide track color
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.blueGray100.main,
        },
      },
    },
    // Swtich circle (default)
    "& .MuiSwitch-thumb": {
      border: `1px solid ${theme.palette.ethicsBlue.main}`,
    },
    //Switch sliding track (default)
    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.blueGray500.main,
    },
  }));

  const label = { inputProps: { "aria-label": "Custom switch" } };

  // Handles the onChange event of the switch
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <MainSwitch
      disabled={disabled}
      checked={checked}
      onChange={handleChange}
      {...label}
      {...props}
    />
  );
};
