import { Navigate, Route, Routes } from "react-router-dom";
// import Complaint from "./../components/ComplaintForm/ComplaintForm";
// import ComplaintForm from "./../components/ComplaintForm/Component/Form";
// import Tenant from "./../components/Tenant/Tenant";
import Auth from "./../screens/Auth/Auth";
import SignPlan from "./../screens/SignPlan";
import Plans from "./../screens/Plans";
import PaymentInfo from "./../screens/SignPayment/PaymentInfo";
import Signup from "../screens/Auth/Signup";

export const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Auth />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/sign-plan" element={<SignPlan />} />
      <Route path="/sign-payment-info" element={<PaymentInfo />} />
      <Route path="/signup" element={<Signup />} />
      {/* <Route path="/tenant" element={ <Tenant /> } /> */}
      {/* <Route path="/complaint" element={ <Complaint /> } /> */}
      {/* <Route path="/complaint-form" element={ <ComplaintForm /> } /> */}
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
