import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// import { CallBold } from "../../Icon";
import { useTranslation } from "react-i18next";
import { parseDateToISOString2 } from "../../helpers/dateTransform";
import "./GlobalComponents.scss";
import { NotificationByUserPost } from "../../apis";

const NotificationsCards = ({ reportsData }) => {
  const [t] = useTranslation("dashboard");
  const navigate = useNavigate();

  const [lastReports, setLastReports] = useState([]);
  const [expireDays, setExpireDays] = useState();
  const [cardsList, setCardsList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const calculateOpenDays = (created_at) => {
    const createdDate = new Date(created_at);
    const today = new Date();
    const diffTime = Math.abs(today - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const updateReportsData = useCallback(() => {
    // console.log("REPORTS DATA UPDATEADO", reportsData);
    try {
      if (reportsData) {
        setLastReports(reportsData);
        setExpireDays(reportsData.expired_report);

        let tempCardsList = [];
        if (reportsData.length > 0) {
          reportsData.forEach((reportItem) => {
            const card = {
              id: reportItem.id,
              titulo: reportItem.data.title || "SIN TITULO",
              mensaje: reportItem.data.message || "SIN MENSAJE",
              report_id: reportItem.metadata.report_id || "SIN ID",
              created_at: reportItem.created_at || "SIN FECHA DE CREACION",
              created_by: reportItem.metadata.user_name || "ANONIMO",
              open_days: calculateOpenDays(reportItem.created_at || new Date()),
              deleted_at: reportItem.deleted_at || "AUN NO REVISADO",
              nombre0: reportItem.data.info[0]["name"] || "SIN NOMBRE 0",
              value0: reportItem.data.info[0]["value"] || "SIN VALUE 0",
              nombre1: reportItem.data.info[1]["name"] || "SIN NOMBRE 1",
              value1: reportItem.data.info[1]["value"] || "SIN VALUE 1",
              colorTarjeta: reportItem.data.info[1]["value"] || "SIN VALUE 1"
            };
            if (card["colorTarjeta"] !== "new" && card["colorTarjeta"] !== "pending" && card["colorTarjeta"] !== "process"
              && card["colorTarjeta"] !== "finish" && card["colorTarjeta"] !== "closed") {
              card["colorTarjeta"] = "draft";
            }
            tempCardsList.push(card);
          });
        }
        setCardsList(tempCardsList);
      }
    } catch (error) {
      console.error("Error processing reports data:", error);
    }
  }, [reportsData]);

  useEffect(() => {
    updateReportsData();
  }, [reportsData, refresh, updateReportsData]);  // Añadir `updateReportsData` a las dependencias

  const handleReportClick = useCallback((reportId, id) => {
    // Navegar a la página del reporte
    if (Number.isInteger(reportId)) {
      navigate("/reports/" + reportId);
    }
    // console.log("ID seleccionado", id);
    NotificationByUserPost(id);
    setRefresh(prevRefresh => !prevRefresh);
    updateReportsData();
  }, [navigate]);


  return (
    <div className="mt-20 dashboard-card p-4 dyBorder1" id="recent-reports">

      <div className="text-center border-bottom pb-3">
        <h6 className="m-0">{`${t("NotificationCards.last_notifications")}:`}</h6>
      </div>

      {lastReports.length > 0 ? (
        <div className="last-reports">
          {cardsList.map((reportItem, index) => (
            <div
              onClick={() => handleReportClick(reportItem.report_id, reportItem.id)}
              key={index}
              className={`report ${reportItem.colorTarjeta} dashboard-card mt-20`}
            // className={`report ${reportItem.titulo} dashboard-card mt-20`}
            >
              <div className="title">

                <h4 className="title__folio">{reportItem.titulo}</h4>

              </div>
              <p>
                {reportItem.mensaje + " " || `${t("NotificationCards.no_notifications")}...`}
              </p>
              {/* <p>
                {'Id: '+  reportItem.report_id  || `${t("NotificationCards.no_notifications")}...`}
              </p> */}
              <p>
                {reportItem.nombre0 + ": " + reportItem.value0 || "sin informacion"}
              </p>
              <p>
                {reportItem.nombre1 + ": " + reportItem.value1 || "sin informacion"}
              </p>
              <small>{parseDateToISOString2(reportItem.created_at)}</small>
              <small>{reportItem.created_by}</small>

              <small className="py-2 border-bottom text-center">
                {/* {reportItem.open_days} {t("NotificationCards.open_days")} */}
              </small>
              <div className="report-details">
                <small className="left">
                  {parseDateToISOString2(reportItem.deleted_at) === "NaN-NaN-NaN NaN:NaN:NaN"
                    ? reportItem.deleted_at
                    : parseDateToISOString2(reportItem.deleted_at)}
                </small>
                <small className="right">
                  {reportItem.open_days === 1 ? `${t("NotificationCards.open_day")}` : `${reportItem.open_days} ${t("NotificationCards.open_days")}`}
                </small>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>{t("NotificationCards.no_notifications")}</p>
      )}
    </div>

  );
};

export default NotificationsCards;
