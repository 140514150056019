import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getTenant } from "../handlers/getTenant";
import { getSession } from "./../handlers/getSession";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

export const renderFormToReport = async () => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/render-form-to-report/`);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

// CURRENT REPORT
export const getDetailCurrentReport = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/form/current/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const getFormsList = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/form/list/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const editForms = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/forms/edit/${id}`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_update_data}`);
    console.error(error);
  }
};

export const getFormDetails = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/form/detail/${id}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

export const createForm = async (form = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/form/create/`, form, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_create_form}`);
  }
};

export const deleteForm = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(`${tenant}/api/forms/delete/${id}/`, id, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_delete_form}`);
  }
};

// Get list Report Forms
export const ListReportForms = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/form/list/report/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
  }
};
