import { useNavigate } from "react-router-dom";
import { Row, Col, Accordion } from "react-bootstrap";
import { MCButton } from "../../../../MainComponents/Button/Button";
import NotFound from "../../../../GlobalComponents/NotFound";
import { useTranslation } from "react-i18next";
import {
  parseDateToDDMMYYYY2,
  parseDateToISOString2,
} from "../../../../../helpers/dateTransform";

export const Evidencias = ({ investigationAlias, evidences }) => {
  const [t] = useTranslation("investigation");
  const navigate = useNavigate();

  const compromised = (comp) => {
    if (comp) return t("Evidences.compromised");
    return t("Evidences.intact");
  };

  const returnKind = (kin) => {
    if (kin === "fisic") return t("Evidences.physical");
    if (kin === "digital") return t("Evidences.digital");
    return t("Evidences.unspecified");
  };

  const returnFacilitator = (fac) => {
    if (fac === "human-resources") return t("Evidences.human_resources");
    if (fac === "other") return t("Evidences.other");
    return t("Evidences.unspecified");
  };

  const accordion = [
    {
      key: "pending",
      header: t("Evidences.list_evidences"),
      data: evidences || [],
    },
  ];

  var have_one = [evidences ? evidences.length : 0];

  const total = have_one.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0); // 0 is the initial value of the accumulator

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded p-20">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div>
            <h4 className="m-0">{t("Evidences.evidences")}</h4>
          </div>
          <MCButton
            label={t("Evidences.new_evidence")}
            variant="primary"
            outline
            onClick={() =>
              navigate("/evidence/new", {
                state: { investigationAlias, evidences },
              })
            }
          />
        </div>
      </div>
      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
        {total >= 1 ? (
          <Accordion
            defaultActiveKey={accordion.map((item, idx) => `${idx + 1}`)}
            alwaysOpen
          >
            {accordion.map((item, idx) => (
              <Accordion.Item eventKey={`${idx + 1}`} key={idx}>
                <Accordion.Header>{item.header}</Accordion.Header>
                {item?.data.length !== 0 &&
                  item.data.map((item2, idx2) => (
                    <Accordion.Body key={idx2}>
                      <div className="d-block d-lg-flex align-items-center justify-content-between py-3 border-bottom">
                        <div className="my-2">
                          <h6>{item2.name}</h6>
                          <small>
                            {`${t(
                              "Evidences.uploaded_at"
                            )}: ${parseDateToISOString2(
                              item2.information.created_at
                            )}`}
                          </small>
                        </div>
                        {/* <MCButton label={item2.status} className="small-primary-btn" /> */}
                      </div>
                      <div className="py-3 border-bottom">
                        <Row>
                          <Col lg="4" md="12" className="my-2 p-0">
                            <small>{t("Evidences.upload_by")}:</small>
                            <h6>{item2.upload_by}</h6>
                          </Col>
                          <Col lg="4" md="12" className="my-2 p-0">
                            <small>
                              {t("Evidences.company_relationship")}:
                            </small>
                            <h6>{item2.relationship}</h6>
                          </Col>
                          <Col lg="4" md="12" className="my-2 p-0">
                            <small>{t("Evidences.evidence_type")}:</small>
                            <h6>{returnKind(item2.kind)}</h6>
                          </Col>
                        </Row>
                      </div>
                      <h6 className="my-3">
                        {t("Evidences.evidence_objectives")}:
                      </h6>
                      <small>{item2.description}</small>
                      <Accordion>
                        <Accordion.Item eventKey="extra" className="mt-20">
                          <Accordion.Header className="header-extra-data-interview">
                            {t("Evidences.extra_information")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.managed_by")}:</small>
                                <h6>{item2?.information?.created_by}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.job_position")}:</small>
                                <h6>{item2?.information?.position}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.description")}:</small>
                                <h6>{item2?.information?.description}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.budget")}:</small>
                                <h6>{item2?.information?.budget}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.created_at")}:</small>
                                <h6>
                                  {parseDateToDDMMYYYY2(
                                    item2?.information?.created_at
                                  )}
                                </h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.deadline")}:</small>
                                <h6>{item2?.information?.deadline}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.facilitator")}:</small>
                                <h6>
                                  {returnFacilitator(
                                    item2?.information?.facilitator
                                  )}
                                </h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.measures")}:</small>
                                <h6>{item2?.information?.measures}</h6>
                              </Col>
                              <Col lg="4" md="6" className="my-2 p-0">
                                <small>{t("Evidences.is_compromised")}</small>
                                <h6>
                                  {compromised(item2?.information?.compromised)}
                                </h6>
                              </Col>
                            </Row>
                            <div className="py-3 border-bottom">
                              <h6 className="mt-20">
                                {t("Evidences.description_findings")}:
                              </h6>
                              <small>{item2?.information?.details}</small>
                            </div>
                            <div className="py-3 border-bottom">
                              <h6 className="mt-20">
                                {t("Evidences.evidence_conclusions")}:
                              </h6>
                              <small>{item2?.information?.comment}</small>
                            </div>
                            {item2?.files.length > 0 && (
                              <div className="py-3 border-bottom">
                                <h6>{t("Evidences.files")}:</h6>
                                {item2?.files.map((item3, idx3) => (
                                  <div
                                    key={idx3}
                                    className="mb-2 d-flex align-items-center"
                                  >
                                    <small className="me-1">
                                      {item3.name ||
                                        t("Evidences.unnamed_file")}
                                    </small>
                                    <MCButton
                                      label={t("Evidences.chain_custody")}
                                      variant="primary"
                                      size="sm"
                                      outline
                                      onClick={() =>
                                        navigate("/evidence/custody", {
                                          state: {
                                            investigationAlias,
                                            evidences,
                                            currentEvidence: item2,
                                            indexCurrentEvidence: idx2,
                                            currentFile: item3,
                                            indexCurrentFile: idx3,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="text-center text-md-right">
                        <MCButton
                          label={t("Evidences.review_evidence")}
                          variant="primary"
                          outline
                          onClick={() =>
                            navigate("/evidence/details", {
                              state: {
                                investigationAlias,
                                evidences,
                                currentEvidence: item2,
                                index: idx2,
                              },
                            })
                          }
                        />
                      </div>
                    </Accordion.Body>
                  ))}
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
