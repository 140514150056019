import { useState } from "react";
import { useSelector } from "react-redux";
import { CDBBtn, CDBInput } from "cdbreact";
import SimpleSelect from "../../components/SimpleSelect";
import { Col, Row } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import Step from "./Step";
import { useTranslation } from "react-i18next";
import { createUserAdmin } from "../../apis/apiCognito";
import { confirmUser } from "../../apis/apiCognito";
import { Modal } from "react-bootstrap";
import "./Auth.scss";
const Signup = (props) => {
  const [t] = useTranslation("login");
  const { signup } = useSelector((state) => state.lang.dictionary);
  const { MX, US } = signup.country_options;
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [modal, setModal] = useState(null);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredPassword, setRequiredPassword] = useState(false);
  const [requiredUsername, setRequiredUsername] = useState(false);
  const [codeVerification, setCodeVerification] = useState("");
  const [isLoading, setIsLoading] = useState(false); //generar pantalla de recarga cuando la verificacion sea exitosa y nos mande al Dashboard
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const { formValues, handleChange } = useForm({
    name: "",
    lastname: "",
  });

  const jobPosition = [
    { value: "p1", text: "Puesto 1" },
    { value: "p2", text: "Puesto 2" },
    { value: "p3", text: "Puesto 3" },
  ];

  const countries = [
    { value: "MX", text: MX },
    { value: "US", text: US },
  ];

  const handleJobPosition = (val) => {
    console.log(val);
  };
  const handleCountry = (val) => {
    console.log(val);
  };
  //code
  const handleCodeChange = (e) => {
    setCodeVerification(e.target.value);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    if (e.target.value.trim() !== "") {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber("");
    }
  };

  const handleSubmit = async () => {
    const data = {
      email: email,
      username: username,
      password: password,
      phone_number: phoneNumber,
    };
    const verification = {
      email: email,
      verfication_code: codeVerification,
    };
    try {
      const res = await createUserAdmin(data);
      setError(null);
      if (res.status === 200) {
        openModal(true);
        const confirmData = await confirmUser(verification);
        if (confirmData.status === 200) {
          closeModal();
          setSuccess(t("Signup.user_created_successfully"));
          openModal(false);
        }
      } else if (
        res?.response?.data?.errors?.email[0] ===
          "Este campo no puede estar en blanco." ||
        res?.response?.data?.errors?.password[0] ===
          "Este campo no puede estar en blanco." ||
        res?.response?.data?.errors?.username[0] ===
          "Este campo no puede estar en blanco."
      ) {
        setRequiredEmail(true);
        setRequiredPassword(true);
        setRequiredUsername(true);
      }
    } catch (error) {
      setError(error);
      setSuccess(null);
    }
  };

  const handleCodeSubmit = async (e) => {
    try {
      const confirmData = await confirmUser({
        email,
        verification_code: codeVerification,
      });
      if (confirmData.message === "account verified") {
        closeModal();
        setSuccess(t("Signup.user_created_successfully"));
        openModal(false);
        setIsLoading(true);
      }
    } catch (error) {
      setError(t("Signup.invalid_code"));
    }
  };

  return (
    <div className="bg-white">
      {modal && (
        <div>
          <Modal show={modal} className="d-flex justify-content-center px-2">
            <div className="modal-body">
              <h2>{t("Signup.verification")}</h2>
              <label htmlFor="Code">{t("Signup.code")}</label>
              <input
                id="Code"
                type="text"
                className="form-control"
                value={codeVerification}
                onChange={handleCodeChange}
              />
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                id="email"
                className="form-control"
                value={email}
                onChange={handleCodeChange}
              />
              <button className=" btnVerifyCLose" onClick={closeModal}>
                {t("Signup.close")}
              </button>
              <button className="btnVerifySubmit" onClick={handleCodeSubmit}>
                {t("Signup.submit")}
              </button>
            </div>
          </Modal>
        </div>
      )}
      <Step />
      <div className="px-30">
        <Row>
          <Col md="6" className="p-0 mt-30">
            <div className="pr-md-30">
              <label htmlFor="name">{t("Signup.name")}:</label>
              <input
                className="form-control"
                id="name"
                type="text"
                name="name"
                required={requiredUsername}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </Col>
          <Col md="6" className="p-0 mt-30">
            <div className="pl-md-30">
              <label htmlFor="surname">{t("Signup.lastname")}:</label>
              <input
                className="form-control"
                id="surname"
                type="text"
                // required={true}
                name="lastname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md="6" className="p-0 mt-30">
            <div className="pr-md-30">
              <SimpleSelect
                label={t("signup.jobPosition")}
                options={jobPosition}
                onChange={handleJobPosition}
              />
            </div>
          </Col>
          <Col md="6" className="p-0 mt-30">
            <div className="pl-md-30">
              <SimpleSelect
                label={t("signup.country")}
                options={countries}
                onChange={handleCountry}
              />
            </div>
          </Col>
        </Row> */}
      </div>
      <div className="mx-30 py-20 border mt-30">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="d-block d-sm-flex flex-row">
              <div className="d-flex flex-column w-100 px-20">
                <label htmlFor="businessEmail">
                  {t("Signup.business_email")}:
                </label>
                <input
                  id="businessEmail"
                  className="form-control"
                  label={t("Signup.business_email")}
                  type="text"
                  name="email"
                  required={requiredEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-20">
                  <label htmlFor="pass"> {t("Signup.password")}:</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    required={requiredPassword}
                    onChange={(e) => setPassword(e.target.value)}
                    id="pass"
                  />
                </div>
              </div>
              <div className="d-flex flex-column w-100 px-20">
                <div className="form-group has-feedback">
                  <label htmlFor="telf">{t("Signup.phone")}:</label>
                  {/*2 <CustomPhoneInput /> */}
                  {/* <PhoneInput onChange={handlePhoneNumberChange} vallue={phoneNumber}/> */}
                  {/*3 <input className="form-control"  type="number" onChange={(e) => {props.setPhone(e.target.value)}} /> */}
                  <div className="container">
                    <select
                      id="pais"
                      className="w-100 wrapper-country"
                      onChange={handleCountryChange}
                    >
                      <option value="">{t("Signup.select_option")}</option>
                      <option value="+54">ARG</option>
                      <option value="+56">CHL</option>
                      <option value="+52">MEX</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      id="telf"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      disabled={!selectedCountry}
                    />
                  </div>
                </div>
                <div className="mt-20">
                  <label htmlFor="verify">{t("Signup.verify_password")}:</label>
                  <input
                    id="verify"
                    className="form-control"
                    type="password"
                    name="password"
                    // required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="d-block d-sm-flex flex-row mx-2">
          <div className="d-flex flex-column w-100 p-20">
            <div className="pr-md-20">
              <label htmlFor="companyName"> {t("Signup.company_name")}: </label>
              <input
                id="companyName"
                type="text"
                className="form-control"
                // required={true}
                onChange={handleChange}
              />
              <div className="mt-20">
                <CDBInput
                  type="checkbox"
                  label={t("Signup.agree_terms_conditions")}
                  id="check1"
                />
                <CDBInput
                  type="checkbox"
                  label={t("Signup.subscribe_newsletter")}
                  id="check2"
                />
                <CDBBtn
                  className="athics-btn mt-20"
                  type="submit"
                  onClick={handleSubmit}
                  block={false}
                  disabled={false}
                >
                  {t("Signup.register")}
                </CDBBtn>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100 p-20">
            <div className="pl-md-20">
              <label htmlFor="web">{t("Signup.website")}</label>
              <input
                id="web"
                type="text"
                // required={true}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
