import Filter from "./Filter";
import { MenuDotBold, OutlineProfile2User } from "../Icon";
import Config from "./Config-dropdown";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { forwardRef, useEffect, useState } from "react";
import { getUserList } from "../../apis/apiUsers";
import Icon from "../Icon/Icon";
import { MCButton } from "../MainComponents/Button/Button";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";
import SearchBar from "../../components/SearchBar/Search";

export const Users = () => {
  const [t] = useTranslation("users");
  const navigate = useNavigate();
  // Columns in Users Table
  const initialColumns = [
    {
      id: "nombre",
      name: t("Users.name"),
      checked: true,
      selector: (row) =>
        row.first_name ? row.first_name : t("Users.unregistered"),
    },
    // {
    //   id: "número_de_ingresos",
    //   name: "Número de ingresos",
    //   checked: true,
    //   selector: (row) =>
    //     row.date_joined ? row.date_joined : "No hay fecha de ingreso",
    // },
    // { id: "bloqueado", name: "Bloqueado", checked: false },
    {
      id: "email",
      name: "Email",
      checked: true,
      selector: (row) => (row.email ? row.email : t("Users.no_data")),
    },
    // {
    //   id: "usuario_ethicsglobal",
    //   name: t("User.user_ethicsglobal"),
    //   checked: true,
    //   selector: (row) =>
    //     row.username ? row.username : t("Users.no_username"),
    // },
    // {
    //   id: "relación_con_la_empresa",
    //   name: t("Users.company_relationship"),
    //   checked: false,
    // },
    // {
    //   id: "apellidos",
    //   name: t("Users.lastname"),
    //   checked: true,
    //   selector: (row) => (row.last_name ? row.last_name : t("Users.unregistered")),
    // },
    // {
    //   id: "líder",
    //   name: t("Users.leader"),
    //   checked: true,
    //   selector: (row) => (row.is_staff ? row.is_staff : t("Users.no_assigned_leader")),
    // },
    // { id: "tipo_de_reporte", name: t("Users.report_type"), checked: false },
    {
      id: "rol",
      name: t("Users.role"),
      checked: true,
      selector: (row) => (row.role ? row.role : t("Users.no_role_assigned")),
    },
    // {id: "temporal", name: t("Users.temporary"), checked: false},
    // {id: "entrada actual", name: t("Users.current_entry"), checked: false},
    {
      id: "activo",
      name: t("Users.active"),
      checked: true,
      selector: (row) =>
        row.is_active === true
          ? t("Users.yes")
          : row.is_active === false
          ? "No"
          : t("Users.no_data"),
    },
  ];

  // const BootyCheckbox = forwardRef(({ onClick, ...rest }, ref) => (
  //   <div className="form-check">
  //     <input
  //       htmlFor="booty-check"
  //       type="checkbox"
  //       className="form-check-input"
  //       ref={ref}
  //       onClick={onClick}
  //       {...rest}
  //     />
  //     <label className="form-check-label" id="booty-check" />
  //   </div>
  // ));

  // States
  const [listUser, setListUser] = useState([]);
  const [checks, setChecks] = useState(
    initialColumns.map((item) => ({
      id: item.id,
      name: item.name,
      checked: item.checked,
    }))
  );
  const [columns, setColumns] = useState(initialColumns);

  const breadcrumbItems = [
    { label: t("Users.home"), route: "/" },
    { label: t("Users.all_users") },
  ];

  const applyConfig = () => {
    const columnsFinal = [];
    checks.map((item1) => {
      if (item1.checked) {
        const column = initialColumns.find((item2) => item1.id === item2.id);
        columnsFinal.push(column);
      }
    });
    columnsFinal.push({
      name: t("Users.actions"),
      button: true,
      cell: (user) => (
        <div className="w-100">
          <div className="text-center">
            <div className="d-flex justify-content-around table-icons">
              <MCTooltip position="top" text={t("Users.edit_user")}>
                <div className="m4-1 pointer icon">
                  <Icon
                    name="outline_user_edit"
                    onClick={() =>
                      navigate(`/users/edit/`, { state: { user } })
                    }
                  />
                </div>
              </MCTooltip>
              <MCTooltip position="top" text={t("Users.build_user_segment")}>
                <div className="pointer icon">
                  <Icon
                    name="outline_shield"
                    onClick={() =>
                      navigate(`/segments/new/`, { state: { user } })
                    }
                  />
                </div>
              </MCTooltip>
            </div>
          </div>
        </div>
      ),
    });
    setColumns(columnsFinal);
  };


  const [filtredActiveUsers, setFiltredActiveUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  //-- Search state
  useEffect(() => {
    getUserList()
      .then((userList) => {
        setAllUsers(userList.users);
        setFiltredActiveUsers(userList.users);
        setListUser(userList.users);
      })
      .catch((error) => {
        console.error("Error fetching user list:", error);
      });
  }, []);

  const handleSearch = (value) => {
    setSearch(value);

    if (value.trim() === "") {
      setFiltredActiveUsers(allUsers); // Si la búsqueda está vacía, mostrar todos los usuarios
      setListUser(allUsers);
      return;
    }

    const cleanedSearch = value.trim().toLowerCase();
    const filtredUsersTemp = allUsers.filter((user) => {
      const firstNameLowerCase = user.first_name.toLowerCase();
      const emailLowerCase = user.email.toLowerCase();
      return firstNameLowerCase.includes(cleanedSearch) || emailLowerCase.includes(cleanedSearch);
    });

    setFiltredActiveUsers(filtredUsersTemp);
    setListUser(filtredUsersTemp);
  };

  useEffect(() => {
    applyConfig();
    getUserList()
      .then((resp) => {
        setListUser(resp.users);
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: "error" }));
  }, []);



  return (
    <div id="settings" className={`users-page page`}>
      <div className="dyTheme1 dyBorder1 general-header">
        <Row>
          <Col lg="8" className="p-0 d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <span className="title-icon">
                <OutlineProfile2User float="left" width="48" height="48" />
              </span>
              <div>
                <h3 className="mb-0 general-header-title">
                  {t("Users.all_users")}
                </h3>
                <p className="mb-0 font-14">
                  {t("Users.customize_user_roles_permissions")}
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg="4"
            className="p-0 d-flex align-items-center justify-content-center"
          >
            <Config
              applyConfig={applyConfig}
              checks={checks}
              setChecks={setChecks}
            />
          </Col>
        </Row>
      </div>

      <Breadcrum items={breadcrumbItems} />

      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20 userlist">
        <div className="sub-header mt-0 mb-3">
          <div className="d-flex">
            <h5> {t("Users.registered_users_list")}</h5>
          </div>

          <div>
                <SearchBar
                  placeholder={t("Users.enter_user")}
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>

          <div className="review-icon user-header">
            <MCButton
              icon="outline_user_add"
              label={t("Users.add_user")}
              variant="primary"
              size="sm"
              className="mx-1"
              onClick={() => navigate("/users/new")}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={listUser}
          defaultSortFieldID={1}
          pagination
          //selectableRows
          //selectableRowsComponent={BootyCheckbox}
          //selectableRowsSingle
        />
      </div>
    </div>
  );
};
