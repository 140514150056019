import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;
//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
// FUNCION MAIN DE CATALOGOS
export const catalogByPart = async (
  data = { is_own: false, catalogue: "", path: "" }
) => {
  //show_all: true
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/detail/catalogue/`, data);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_obtaining_consultant_review_details}`);
    console.error(error);
    return { error: true };
  }
};

// LIST CATALOGUE ENDPOINT
export const listCatalogues = async () => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/list/catalogues/`);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_updating_catalog}`);
    console.error(error);
  }
};

//LIST OWN CATALOGUES ENDPOINT
export const ownCatalogues = async () => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/list/own-catalogues/`);
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_obtaining_catalog_details}`);
    console.error(error);
  }
};

//NEW CATALOGUE ENDPOINT
export const createCatalogues = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/new/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    if (
      error.response.data?.errors?.repeated_name ===
      "main catalogue name is repeated"
    ) {
      // throw new Error("Catalogue name repeated")
      return { error: true, payload: "main catalogue name is repeated" };
    } else if ("repeated_name" in error.response.data?.errors) {
      // throw new Error("Labels repeated")
      return {
        error: true,
        payload: error.response.data?.errors?.repeated_name,
      };
    } else {
      throw new Error(`${language.error_creating_catalog}`);
    }
  }
};

//INSERT ITEM CATALOGUE ENDPOINT

export const insertCatalogueChildren = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/catalogues/insert/new-element/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    if (error.response.data.errors === "Element already exists") {
      return { error: true, payload: "element already exists" };
    }
  }
};

//Update o Create Locales

export const updatedLocales = async (
  data = { is_own: false, catalogue: "", path: "", locales: {} }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/locales/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_updating_configuration}`);
    console.error(error);
  }
};

//Update Status
export const updateStatusCatalogue = async (
  data = { is_own: false, catalogue: "", path: "", is_active: false }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/status/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_configuration}`);
    console.error(error);
  }
};

//UPDATE LABEL CATALOGUES
export const updateCatalogueLabel = async (
  data = { is_own: false, catalogue: "", path: "", label: false }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/label/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_catalog_label}`);
    console.error(error);
  }
};

//UPDATE DESCRIPTION CATALOGUES
export const updateCatalogueDescription = async (
  data = { is_own: false, catalogue: "", path: "", label: false }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/description/catalogue/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_catalog_description}`);
    console.error(error);
  }
};

//UPDATE EXAMPLES CATALOGUES
export const updateCatalogueExamples = async (
  data = { is_own: false, catalogue: "", path: "", label: false }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/examples/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_configuration}`);
    console.error(error);
  }
};

//UPDATE CONTAINER NAME CATALOGUES
export const updateCatalogueContainerName = async (
  data = { is_own: false, catalogue: "", path: "", label: false }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/container-name/catalogue/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating_container_name}`);
    console.error(error);
  }
};

// RETRIEVE CATALOGUE BY PATH AND LANGUAGE
// BACKEND ENDPOINT {{host}}/{{tenant}}/api/catalogues/retrieve/by-path/?lg=es
export const retrieveCatalogueByPath = async (
  data = { is_own: false, catalogue: "", path: "" }
) => {
  const tenant = getTenant();
  const params = {
    params: {
      lg: localStorage.getItem("lang") || "es",
    },
  };
  try {
    const resp = await apiCall.post(
      `${tenant}/api/catalogues/retrieve/by-path/`,
      data,
      params
    );
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_catalog}`);
    console.error(error);
  }
};
