import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { patchInvestigation } from "../../../../../apis";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

export const EditScenario = ({
  investigationAlias,
  formValues,
  toggleModal,
  modalParams,
}) => {
  const [t] = useTranslation("investigation");
  const scenario = _.get(formValues, `${modalParams.id}`);
  const [kind, setKind] = useState(scenario.kind);
  const [name, setName] = useState(scenario.name);
  const [site, setSite] = useState(scenario.site);
  const [location, setLocation] = useState(scenario.location);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const newForm = _.cloneDeep(formValues);
    _.set(newForm, `${modalParams.id}`, { kind, name, site, location });
    const objToSave = {
      folder: {
        scenarios: newForm,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  if (modalParams.id === null || modalParams.id === undefined) {
    return <div>{t("General.unidentified")}</div>;
  }

  return (
    <div>
      <Row>
        <h5>{t("General.general_investigation_data")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.type_scen")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_scen_type")}
            value={kind}
            onChange={(e) => setKind(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.scenario")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_scenario_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.site")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_site_name")}
            value={site}
            onChange={(e) => setSite(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.address")}:</small>
          <MCInput
            type="text"
            placeholder={t("General.enter_address")}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("General.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("General.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
