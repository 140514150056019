import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Information.scss";
import { Box, Typography, useTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const Comments = ({
  text,
  maxLength = 140,
  rounded = false,
  border = "",
  background = "",
  padding = "0.5rem 0.75rem",
}) => {
  const theme = useTheme();
  const [t] = useTranslation("information");
  const [expanded, setExpanded] = useState(false);

  if (text.length <= maxLength) {
    return (
      <Box
        className={`comments ${rounded ? "rounded" : "semi-rounded"}`}
        sx={{
          border: `2px solid ${theme?.palette[border]?.main || "transparent"}`,
          background:
            theme?.palette[background]?.main || theme.palette.blueGray50.main,
          padding: padding,
        }}
      >
        <Typography variant="body1" className="p-0 m-0">
          {text}
        </Typography>
      </Box>
    );
  }
  const visibleText = expanded ? text : text.slice(0, maxLength);

  return (
    <Box
      className={`comments ${rounded ? "rounded" : "semi-rounded"}`}
      sx={{
        border: `2px solid ${theme?.palette[border]?.main || "transparent"}`,
        background:
          theme?.palette[background]?.main || theme.palette.blueGray50.main,
        padding: padding,
      }}
    >
      <Typography variant="body1" className="p-0 m-0" padding={padding}>
        {visibleText}
        {!expanded && "..."}
        <b
          onClick={() => setExpanded(() => !expanded)}
          className={`${expanded ? "d-flex justify-content-end" : "mx-2"}`}
        >
          {expanded ? t("information.see_less") : t("information.see_more")}
        </b>
      </Typography>
    </Box>
  );
};

export default Comments;
