import './graphic.scss';

export const LoadingGraphic = () => {
    return (
        <div className="center-body">
            <div className="loader-line-10">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}