import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import { enqueueSnackbar } from "notistack";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

export const createReport = async (report = {}) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/report/create/`, report);
    // console.log(resp);
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_sending_form} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const updateReport = async (id, answers) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/report/update/${id}/`,
      answers,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_update_data}`);
  }
};

export const getStatusReport = async (tracking_code) => {
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/report/status/get/`, {
      tracking_code,
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_sending_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const getReports = async (params, orderParam) => {
  const session = getSession();
  const tenant = getTenant();

  var order = "?ordering=-created_at";

  if (orderParam) {
    order = "?ordering=" + orderParam;
  }
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/report/list/${order}`, {
      params,
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const getReportsWithPagination = async (url) => {
  const session = getSession();
  if (!session) return;
  try {
    const resp = await apiCall.get(url, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error.message);
  }
};

export const getReportDetail = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/report/detail/${id}`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_report_details} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const getDetailReport = async (id = "") => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/form-to-report/${id}/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
  }
};

// Status
export const statusReport = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/report/utilities/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const changeConfigurationSubStatus = async (updatedDataStructure) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/substatus/config/update/`,
      { updatedDataStructure },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_severity_level} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const updateStatusReport = async (
  report_id,
  status,
  comment,
  message,
  checkboxes
) => {
  // console.log(comment);

  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/report/status/update/`,
      { report_id, status, comment, message, checkboxes },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
  }
};

export const updateStatusCheckboxes = async (report_id, checkboxes) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/report/status/update/`,

      { report_id, checkboxes },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_data} (${error.response.status})`
    );
  }
};

export const updateStatusCloseDetails = async (report_id, details = {}) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/report/status_close/update/`,
      { report_id, details },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_resolution_status} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const updateSanctions = async (report_id, resolution) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/report/resolution/`,
      { report_id, resolution },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_sanctions} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const changeSeverity = async ({ report_id, path, comment = "" }) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/report/severity/`,
      { report_id, path, comment },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_update_severity_level} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const changeReportSecure = async ({
  report_id,
  status_type,
  comment,
  comment_path,
  value,
}) => {
  const data = {
    status_type,
    comment_path,
    message: comment ?? "",
    value,
    report_id,
  };

  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/report/secure/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_report_security} (${error.response.status})`
    );
    console.error(error);
  }
};

export const updateRangeDates = async ({
  report_identifier,
  comment,
  end_day,
}) => {
  const data = {
    report_identifier,
    comment: comment ?? "",
    end_day,
  };

  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/reports/custom_dates/`,
      { ...data },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    console.error(error);
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
  }
};

export const getRelated = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/reports/related/`, {
      params: data,
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_report_security} (${error.response.status})`
    );
    console.error(error);
  }
};

export const createRelated = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(`${tenant}/api/reports/related/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response.data.error === "The report already exists in the list") {
      showErrorMessage(`${language.this_report_is_already_linked}`);
    } else {
      showErrorMessage(
        `${language.error_occurred_trying_link_report} (${error.response.status})`
      );
    }
    console.error(error);
  }
};

export const changeRelated = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(`${tenant}/api/reports/related/`, {
      data,
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.linked_report_not_be_updated_due_to_an_error} (${error.response.status})`
    );
    console.error(error);
  }
};

export const deleteRelated = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(
      `${tenant}/api/reports/related/?report_identifier=${data.report_identifier}&report_to_relate_identifier=${data.report_to_relate_identifier}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_deleting_linked_report} (${error.response.status})`
    );
    console.error(error);
  }
};

export const changeCompromisedAnonymity = async (props) => {
  const data = {
    report_identifier: props.report_identifier,
    value_to_assign: props.value_to_assign,
    comment: props.comment ?? "",
  };

  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/reports/compromised_anonymity/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_report_security} (${error.response.status})`
    );
    console.error(error);
  }
};

export const changeHostileEnvironment = async (props) => {
  const data = {
    comment: props.comment ?? "",
    report_identifier: props.report_identifier,
    value_to_assign: props.value_to_assign,
  };

  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/reports/is_hostile_place/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_report_security} (${error.response.status})`
    );
    console.error(error);
  }
};

// utilities for get users that arent assigned to a specific report
export const listAssignedUsers = async (id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report/assigned-users-from-report/?report_id=${id}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_getting_users_utilities} (${error.response.status})`
    );
    console.error(error.message);
  }
};

// utilities for get users that arent assigned to a specific report
export const listUsersWithoutSpecificReport = async (id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/report/list-users-without-this-report/?report_id=${id}`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_getting_users_utilities} (${error.response.status})`
    );
    console.error(error.message);
  }
};

export const searchNavbarAPI = async (data) => {
  const session = getSession();
  const tenant = getTenant();

  if (!session) return;

  try {
    const resp = await apiCall.get(`${tenant}/api/report/search_list/`, {
      params: data, // Envía los datos como parámetros de la URL
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });

    // console.log(resp.data); // Muestra la respuesta del backend en la consola

    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error.message);
  }
};
