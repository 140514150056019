import axios from "axios";
export * from "./apiAttachment";
export * from "./apiCognito";
export * from "./apiReports";
export * from "./apiInvestigation";
export * from "./apiCatalogues";
export * from "./apiForm";
export * from "./apiMessages";
export * from "./apiLanding";
export * from "./apiClientConfig";
export * from "./apiAssigment";
export * from "./apiEAM";
export * from "./apiNotification";

// IF YOU CHANGE THIS VARIABLE DON'T COMMIT
export const host = "https://api.ethicsglobal.com"; //
//export const host = "https://djaguar.herokuapp.com";
//export const host = "http://localhost:8000";
// export const host = process.env.REACT_APP_HOST;

export const baseURL = `${host}`;
export const apiCall = axios.create({ baseURL });
