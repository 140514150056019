import React, { useEffect, useState } from "react";
import { Arrowleft3, Calendar2, OutlineArrowRight4 } from "../Icon";
import { getNumDaysInMonth, dayNames, monthNames, range } from "./data";
import { MCTooltip } from "../MainComponents";
import { useTranslation } from 'react-i18next';
import "./Calendar.scss";

export const Calendar = ({ minDate, maxDate, getSelectedDate }) => {
  const [t] = useTranslation("components");
  const [currentMonth, setCurrenMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(new Date());
  //useEffect to send selected day using props
  useEffect(() => {
    getSelectedDate(selectedDay);
  }, [selectedDay]);

  //Today's date
  const today = new Date();
  //start day of selected month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  //end day of selected month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDay();
  //Day number that starts the week of the selected month
  const startingDayOfWeek = firstDayOfMonth.getDay();
  //Day number that ends the week of the selected month
  const endingDaysOfWeek = 6 - lastDayOfMonth;
  //Reset to current date
  const handleSetToday = () => {
    setSelectedDay(new Date());
    setCurrenMonth(new Date().getMonth());
    setCurrentYear(new Date().getFullYear());
  };
  //Set next month
  const nextMonth = () => {
    if (currentMonth < 11) {
      setCurrenMonth((prev) => prev + 1);
    } else {
      setCurrenMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
  };
  //Set previus month
  const prevMonth = () => {
    if (currentMonth > 0) {
      setCurrenMonth((prev) => prev - 1);
    } else {
      setCurrenMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
  };
  //Set select day
  const handleSelection = (evt) => {
    if (evt.target.id === "day") {
      setSelectedDay(
        new Date(currentYear, currentMonth, evt.target.getAttribute("data-day"))
      );
    }
  };
  //Get time in ms of all days of the selected month
  const getTimeFromState = (_day) => {
    return new Date(currentYear, currentMonth, _day).getTime();
  };

  return (
    <div className="calendar">
      {/* Calendar Header */}
      <div className="calendar__header">
        <h4>{selectedDay.getDate()}</h4>
        <MCTooltip position="bottom" text={t("Calendar.today")}>
          <button className="mx-2 px-1 btnsCalendar" onClick={handleSetToday}>
            <Calendar2 className="dyIcon3" />
          </button>
        </MCTooltip>

        <div className="calendar__nxtPrev">
          <button
            className="btnsCalendar"
            onClick={prevMonth}
            disabled={minDate?.getTime() > getTimeFromState(1)}
          >
            <Arrowleft3 className="dyIcon3" />
          </button>

          <small>
            {t(`Calendar.months.${monthNames[currentMonth]}`)} - {currentYear}
          </small>

          <button
            className="btnsCalendar"
            onClick={nextMonth}
            disabled={
              maxDate?.getTime() <
              getTimeFromState(getNumDaysInMonth(currentYear, currentMonth))
            }
          >
            <OutlineArrowRight4 className="dyIcon3" />
          </button>
        </div>
      </div>
      {/* Calendar Body */}
      <div className="calendar__body">
        {/* Day names */}
        <div className="calendar__sevenColGrid">
          {dayNames.map((day) => (
            <p className="calendar__day" key={day}>
              {t(`Calendar.days.${day}`)}
            </p>
          ))}
        </div>
        {/* Calendar days */}
        <div onClick={handleSelection} className="calendar__sevenColGrid pb-3">
          {/* empty spaces */}
          {Array.from({ length: startingDayOfWeek }).map((_, day) => (
            <button key={day} disabled className="btnsCalendar" />
          ))}
          {/* days */}
          {range(1, getNumDaysInMonth(currentYear, currentMonth) + 1).map(
            (day) => (
              <button
                key={day}
                id="day"
                data-day={day}
                className={`btnsCalendar my-auto
                  ${
                    selectedDay?.getTime() ===
                    new Date(currentYear, currentMonth, day).getTime()
                      ? "--active"
                      : ""
                  } ${
                  today.getFullYear() === currentYear &&
                  today.getMonth() === currentMonth &&
                  today.getDate() === day
                    ? "--today"
                    : ""
                }`}
                disabled={
                  minDate?.getTime() > getTimeFromState(day) ||
                  maxDate?.getTime() < getTimeFromState(day)
                }
              >
                {day}
              </button>
            )
          )}
          {/* empty spaces */}
          {Array.from({ length: endingDaysOfWeek }).map((_, day) => (
            <button key={day} disabled className="btnsCalendar" />
          ))}
        </div>
      </div>
    </div>
  );
};
