import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import exports_esp from "./ES/exports.json";
import exports_eng from "./EN/exports.json";
import investigation_esp from "./ES/investigation.json";
import investigation_eng from "./EN/investigation.json";
import messages_esp from "./ES/messages.json";
import messages_eng from "./EN/messages.json";
import settings_esp from "./ES/settings.json";
import settings_eng from "./EN/settings.json";
import components_esp from "./ES/components.json";
import components_eng from "./EN/components.json";
import report_esp from "./ES/report.json";
import report_eng from "./EN/report.json";
import information_esp from "./ES/information.json";
import information_eng from "./EN/information.json";
import login_esp from "./ES/login.json";
import login_eng from "./EN/login.json";
import dashboard_esp from "./ES/dashboard.json";
import dashboard_eng from "./EN/dashboard.json";
import users_esp from "./ES/users.json";
import user_eng from "./EN/users.json";
import catalogues_esp from "./ES/catalogues.json";
import catalogues_eng from "./EN/catalogues.json";
import forms_esp from "./ES/forms.json";
import forms_eng from "./EN/forms.json";
import perfil_esp from "./ES/perfil.json";
import perfil_eng from "./EN/perfil.json";
import roles_esp from "./ES/roles.json";
import roles_eng from "./EN/roles.json";
import dates_esp from "./ES/dates.json";
import dates_eng from "./EN/dates.json";
import statuscode_esp from "./ES/statuscode.json";
import statuscode_eng from "./EN/statuscode.json";
// const fallbackLng = ['es']
// const availableLanguages = ['es', 'en']
i18next
  // .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    lng: "es",
    // fallbackLng,
    // whitelist: availableLanguages,
    resources: {
      es: {
        catalogues: catalogues_esp,
        components: components_esp,
        dashboard: dashboard_esp,
        dates: dates_esp,
        exports: exports_esp,
        forms: forms_esp,
        information: information_esp,
        investigation: investigation_esp,
        login: login_esp,
        messages: messages_esp,
        perfil: perfil_esp,
        report: report_esp,
        roles: roles_esp,
        settings: settings_esp,
        statuscode: statuscode_esp,
        users: users_esp,
      },
      en: {
        catalogues: catalogues_eng,
        components: components_eng,
        dashboard: dashboard_eng,
        dates: dates_eng,
        forms: forms_eng,
        exports: exports_eng,
        information: information_eng,
        investigation: investigation_eng,
        login: login_eng,
        messages: messages_eng,
        perfil: perfil_eng,
        report: report_eng,
        roles: roles_eng,
        settings: settings_eng,
        statuscode: statuscode_eng,
        users: user_eng,
      },
    },
  });
export default i18next;
