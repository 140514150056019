import React, { forwardRef, useState } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import "./styles.scss";
import {
  BoldCloseCircle,
  BoldTickCircle,
  BoldWarning2,
  QuestionBubbleBold,
} from "../Icon";
import { useSelector } from "react-redux";
//--- Notistack customatized by Notistack V3 (MUI) ---//
//-- Success flag--//
export const ethicsSuccess = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const { gTheme } = useSelector((state) => state.theme);
  //function to nest information
  const handleExpandClick = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  return (
    <div className={`${gTheme === "dark" ? "dark-mode" : ""}`}>
      <SnackbarContent ref={ref} className={`snackbar --snackSuccess `}>
        <div>
          <BoldTickCircle className="snackbar__icon --success" />
        </div>
        <div className="snackbar__message">{props.message}</div>
        <button onClick={handleDismiss} className="snackbar__close">
          <BoldCloseCircle />
        </button>
      </SnackbarContent>
    </div>
  );
});
ethicsSuccess.displayName = "ethicsSuccess";
//-- Error Flag--//
export const ethicsError = forwardRef(({ id, ...props }, ref) => {
  const { gTheme } = useSelector((state) => state.theme);
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = () => {
    closeSnackbar(id);
  };
  return (
    <div className={`${gTheme === "dark" ? "dark-mode" : ""}`}>
      <SnackbarContent ref={ref} className="snackbar --snackError">
        <div>
          <BoldCloseCircle className="snackbar__icon --error" />
        </div>
        <div className="snackbar__message">{props.message}</div>
        <button onClick={handleDismiss} className="snackbar__close">
          <BoldCloseCircle />
        </button>
      </SnackbarContent>
    </div>
  );
});
ethicsError.displayName = "ethicsError";
//-- Info Flag --//
export const ethicsInfo = forwardRef(({ id, ...props }, ref) => {
  const { gTheme } = useSelector((state) => state.theme);
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = () => {
    closeSnackbar(id);
  };
  return (
    <div className={`${gTheme === "dark" ? "dark-mode" : ""}`}>
      <SnackbarContent ref={ref} className="snackbar --snackInfo">
        <div>
          <QuestionBubbleBold className="snackbar__icon --info" />
        </div>
        <div className="snackbar__message">{props.message}</div>
        <div>
          <button onClick={handleDismiss} className="snackbar__close">
            <BoldCloseCircle />
          </button>
        </div>
      </SnackbarContent>
    </div>
  );
});
ethicsInfo.displayName = "ethicsInfo";
//-- Warning Flag --//
export const ethicsWarning = forwardRef(({ id, ...props }, ref) => {
  const { gTheme } = useSelector((state) => state.theme);
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = () => {
    closeSnackbar(id);
  };
  return (
    <div className={`${gTheme === "dark" ? "dark-mode" : ""}`}>
      <SnackbarContent ref={ref} className="snackbar --snackWarning">
        <div>
          <BoldWarning2 className="snackbar__icon --warning" />
        </div>
        <div className="snackbar__message">{props.message}</div>
        <div>
          <button onClick={handleDismiss} className="snackbar__close">
            <BoldCloseCircle />
          </button>
        </div>
      </SnackbarContent>
    </div>
  );
});
ethicsWarning.displayName = "ethicsWarning";

//---Examples to use---//

// const key = enqueueSnackbar({ message, ...options })

//---Directly in a button event---//
/*
  import { SnackbarProvider, useSnackbar } from 'notistack';
  ////////////////////////////////////////////////////////// 
  <button onClick={() => enqueueSnackbar('Message')}>Show message in a snackbar</button> */

//---Call in a function---//

/* const functionExample = () => {
// enqueueSnackbar({ message, ...options })
*/

// ...options  //

/* variant: success | error | info | warning ;
// persist: true | defaultTime (5s) ;
// allowDownload: true, // <-- pass any additional options ;
// autoHideDuration: DefaultTime 5000ms | Choose timer in ms ; 
// preventDuplicate: false | true ;

// action: add action to an individual snackbar --example--
// const action = snackbarId => (
//   <>
//     <button onClick={() => { alert(`I belong to snackbar with id ${snackbarId}`); }}>
//       An Action
//     </button>
//     <button onClick={() => { closeSnackbar(snackbarId) }}>
//       Dismiss (Other Action)
//     </button>
//   </>
// );

//Documentation: https://notistack.com/ */
