import { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import {
  BoldMessageText2,
  OutlineNote2,
  OutlineLayer,
  Outline3dSquare,
  OutlineBoxAdd,
  Buildings,
  OutlineUserOctagon,
  BoldTask,
  OutlineSecuritySafe,
} from "../../../components/Icon";
import { GeneralIconHeader } from "../../../components/Title/GeneralHeader";
import { General } from "../General/General";
import { Breadcrum } from "../../../components/MainComponents/Breadcrumb/Breadcrumb";
import "./Settings.scss";
import { Notifications } from "../Notifications/Notifications";
import { SubStatusConfig } from "../SubStatus/SubStatusConfig";
// import { useSelector } from "react-redux";
// import { CDBProgress } from "cdbreact";
// import Departments from "../Departaments/Departments";
// import People from "../People/People";
// import Reports from "../Reports/Reports";
// import Workplace from "../Workplace/Workplace";

export const Settings = () => {
  const [activeTab, setActiveTab] = useState("general");
  // const gTheme = useSelector((state) => state.theme);

  const navigate = useNavigate();
  const [t] = useTranslation("settings");

  const breadcrumbItems = [
    { label: t("settings.home"), route: "/" },
    { label: t("settings.settings") },
  ];

  return (
    <div id="settings" className={`page`}>
      <GeneralIconHeader
        title={t("settings.settings")}
        text={t("settings.subtitle")}
        icon="settings"
      />

      <Breadcrum items={breadcrumbItems} />
      <Row>
        {/* --- Tab Top Menu --- */}
        <Col lg={7} className="mt-1 p-0">
          <Tabs
            activeKey={activeTab}
            onSelect={setActiveTab}
            className="mb-3 dyTheme1 dyBorder1 justify-content-start"
          >
            <Tab eventKey="general" title={t("settings.general")}>
              <General />
            </Tab>
            <Tab eventKey="notifications" title={t("settings.notifications")}>
              <Notifications vision="settings" />
            </Tab>
            <Tab eventKey="substatus" title={t("settings.sub_status")}>
              <SubStatusConfig vision="settings" />
            </Tab>
            {/* <Tab eventKey="people" title="Personas">
              <People theme={gTheme} />
            </Tab>
            <Tab eventKey="workplace" title="Centro de trabajo">
              <Workplace theme={gTheme} />
            </Tab>
            <Tab eventKey="departments" title="Departamentos">
              <Departments theme={gTheme} />
            </Tab>
            <Tab eventKey="reports" title="Reportes">
              <Reports theme={gTheme} />
            </Tab> */}
          </Tabs>
        </Col>
        {/* --- Settings Menu Aside --- */}
        <Col lg={5} className="access_links mt-1 p-0">
          <div className="pl-lg-30">
            <div className="dyTheme1 dyBorder1 rounded pt-30 px-20 pb-20">
              <Row className="pb-3 border-bottom">
                <Col xl="12" className="p-0">
                  <h5 className="mb-0">{t("settings.setup_account")}</h5>
                </Col>
              </Row>
              <div className="mt-20">
                {/* ---Form--- */}
                <div
                  onClick={() => navigate("/formslist")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <BoldTask size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">{t("settings.forms_list")}</h6>
                </div>
                <div
                  onClick={() => navigate("/createform")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <OutlineNote2 size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">{t("settings.create_form")}</h6>
                </div>
                <hr />
                {/* ---Catalogues--- */}
                <div
                  onClick={() => navigate("/catalogues")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <Outline3dSquare size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">
                    {t("settings.setup_catalogues")}
                  </h6>
                </div>
                <div
                  onClick={() => navigate("/catalogues/new")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <OutlineBoxAdd size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">
                    {t("settings.create_catalogue")}
                  </h6>
                </div>
                <hr />
                {/* ---Business Units--- */}
                <div
                  onClick={() => navigate("/settings/business/list")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <Buildings size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">
                    {t("settings.setup_business_unit")}
                  </h6>
                </div>
                <div
                  onClick={() => navigate("/users")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <OutlineUserOctagon size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">{t("settings.setup_access")}</h6>
                </div>
                <div
                  onClick={() => navigate("/groups")}
                  className="access_link d-flex align-items-center mt-20"
                  role="button"
                >
                  <OutlineSecuritySafe size={"18px"} className="dyIcon2 me-1" />
                  <h6 className="ml-2 mb-0">{t("settings.configure_roles")}</h6>
                </div>
              </div>
            </div>
            {/* --- Settings Suggestions --- */}
            <div
              className="dyTheme3 dyBorder2 rounded d-flex align-items-center mt-4 p-3"
              role="button"
            >
              <BoldMessageText2 size={"35px"} className="bold-message" />
              &nbsp;
              <div className="ml-2">
                <small>
                  <strong>{t("settings.submit_suggestions")}</strong>
                </small>
                <small className="mt-2">
                  {t("settings.extra_suggestions")}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
