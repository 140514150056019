import _ from "lodash";
import { Row, Col, Accordion, FormCheck } from "react-bootstrap";
import { ProfileIcon, CheckIconCircle } from "../../../../Icon";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { patchInvestigation } from "../../../../../apis";
import { useTranslation } from "react-i18next";
import { userTypeOpts } from "./userTypeOpts";

export const Usuarios = ({
  toggleModal,
  users,
  setUsers,
  general,
  investigationAlias,
}) => {
  // const [user, setUser] = useState(users);
  const [t] = useTranslation("investigation");
  // => Cambiar si el usuario esta tiene acceso
  const handleChangeAccess = async (value, rol, idx) => {
    const newUsers = _.cloneDeep(users);
    const arrToChange = newUsers[rol];
    arrToChange[idx].access = value;
    const objToSave = {
      folder: {
        users: newUsers,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
      // console.log(newUsers)
      // setUsers(newUsers);
    } catch (error) {
      console.log(error);
    }
  };

  // => Borrar usuarios con el boton eliminar
  const handleChangeRol = async (newRol, oldRol, oldIdx, user) => {
    const newUsers = _.cloneDeep(users);
    const newUser = _.cloneDeep(user);
    const arrToPop = newUsers[oldRol];
    newUser.rol = newRol;
    const arrToPush = Array.isArray(newUsers[newRol]) ? newUsers[newRol] : [];
    arrToPop.splice(oldIdx, 1);
    arrToPush.push(newUser);
    const objToSave = {
      folder: {
        users: {
          ...newUsers,
          [newRol]: arrToPush,
        },
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
      // setUsers(newUsers);
      // console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  // => Borrar usuarios con el boton eliminar
  const handleDelete = async (rol, idx) => {
    const newUsers = _.cloneDeep(users);
    const arrToPop = newUsers[rol];
    _.unset(newUsers);

    arrToPop.splice(idx, 1);
    const objToSave = {
      folder: {
        users: newUsers,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
  };

  // => Acordeon con los usuarios cada uno en su categoria
  const accordion = [
    {
      header: t("usersType.investigators_chargue"),
      data: users?.incharge,
      value: "incharge",
    },
    {
      header: t("usersType.additional_users"),
      data: users?.additional,
      value: "additional",
    },
    {
      header: t("usersType.assigned_users"),
      data: users?.assigned,
      value: "assigned",
    },
    {
      header: t("usersType.without_access_users"),
      data: users?.virtual,
      value: "virtual",
    },
  ];

  const entryway = (entry) => {
    if (entry === "phone") return t("usersType.phone_call");
    return "";
  };

  return (
    <div className="dyBackground research-contents">
      <div className="dyTheme1 dyBorder1 rounded p-20">
        <div className="pb-3 border-bottom">
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <div>
              <h5 className="m-0">
                {t("usersType.users_assigned_investigation")}
              </h5>
            </div>
            <MCButton
              label={t("usersType.add_user")}
              variant="primary"
              outline
              onClick={() => toggleModal("add-user")}
            />
          </div>
        </div>
        <div>
          <h6 className="my-3">{t("usersType.complainant_data")}</h6>
          <Row>
            <Col lg="4" md="12" className="p-0">
              <small>{t("usersType.complainant")}:</small>
              <h6>{general?.reporter?.name || t("investigation.undefined")}</h6>
            </Col>
            <Col lg="4" md="12" className="p-0">
              <small>{t("usersType.company_relationship")}:</small>
              <h6>
                {general?.reporter?.relationship || t("usersType.unspecified")}
              </h6>
            </Col>
            <Col lg="4" md="12" className="p-0">
              <small>{t("usersType.entryway")}</small>
              <h6>
                {entryway(general?.reporter?.entryway) ||
                  t("investigation.undefined")}
              </h6>
            </Col>
          </Row>
        </div>
      </div>

      <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
        <Accordion
          defaultActiveKey={accordion.map((item, idx) => `${idx + 1}`)}
          alwaysOpen
        >
          {accordion.map((item, idx) => (
            <Accordion.Item eventKey={`${idx + 1}`} key={idx}>
              <Accordion.Header>{item.header}</Accordion.Header>

              {item?.data &&
                item.data.map((item2, idx2, array) => (
                  <Accordion.Body key={idx2}>
                    <div className="p-20 dyTheme3 dyBorder2 rounded">
                      <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-start justify-content-start profile-icon">
                          <ProfileIcon />
                          <div className="ms-2">
                            <h5 className="m-0">{item2.email} </h5>
                          </div>
                        </div>
                        <div className="d-block text-center d-md-flex align-items-center justify-content-lg-center">
                          <FormCheck
                            type="switch"
                            checked={item2.access}
                            onChange={(e) =>
                              handleChangeAccess(
                                e.target.checked,
                                item2.rol,
                                idx2
                              )
                            }
                          />
                          <MCSelect
                            className="me-1"
                            options={userTypeOpts}
                            value={userTypeOpts.find(
                              (opt) => opt.value === item2.rol
                            )}
                            onChange={(e) =>
                              e.value !== item2.rol
                                ? handleChangeRol(
                                    e.value,
                                    item2.rol,
                                    idx2,
                                    item2
                                  )
                                : null
                            }
                          />
                          <MCButton
                            label={t("usersType.delete")}
                            variant="danger"
                            outline
                            onClick={() => handleDelete(item2.rol, idx2)}
                          />
                        </div>
                      </div>
                      {/* <div className="dyTheme1 dyBorder1 rounded research-text mt-20 p-20">
                        <h6 className="d-block mb-4">
                          Actividades del usuario
                        </h6>
                        {item.access && item.access.length > 0 ? (
                          <div>
                            {item.access.map((item, idxdx) => {
                              return (
                                <div
                                  className="d-flex d-row align-items-center mb-2"
                                  key={idxdx}
                                >
                                  <CheckIconCircle size={"16px"} />
                                  <small className="ms-2 mb-0">{item}</small>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                        <MCButton
                          label="Nueva actividad"
                          variant="primary"
                          outline
                          icon="outline_edit_2"
                        />
                      </div> */}
                    </div>
                  </Accordion.Body>
                ))}
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
