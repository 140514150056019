import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MCButton, MCInput, MCSelect } from "../../../../MainComponents";
import { patchInvestigation } from "../../../../../apis";

export const Causes = ({ toggleModal, investigation, investigationAlias }) => {
  const [t] = useTranslation("investigation");
  const { causes } = investigation;
  const [options, setOptions] = useState([]);
  const [selectedCause, setSelectedCause] = useState(null);
  const [details, setDetails] = useState("");
  const [hostile, setHostile] = useState("");
  const [amend, setAmend] = useState("");
  const [avoid, setAvoid] = useState("");
  const prevDetailsRef = useRef();
  const prevHostileRef = useRef();
  const prevAmendRef = useRef();
  const prevAvoidRef = useRef();

  const handleSaveCauseDetails = async (data) => {
    const {
      reason = "",
      details = false,
      hostile = false,
      amend = false,
      avoid = false,
      index,
    } = data;
    const newCauses = _.cloneDeep(causes);
    _.set(newCauses, `${index}`, {
      reason,
      details,
      enviroments: {
        hostile,
        amend,
        avoid,
      },
    });
    const objToSave = {
      folder: {
        causes: newCauses,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.error(error);
    }
  };
  const debouncedSaveCauseDerailsRef = useRef(
    _.debounce(handleSaveCauseDetails, 1100)
  );

  useEffect(() => {
    if (Array.isArray(causes)) {
      const newCauses = causes.map((item, index) => ({
        label: item.reason,
        value: item,
        index,
      }));
      setOptions(newCauses);
    }
  }, [causes]);

  useEffect(() => {
    if (selectedCause) {
      setDetails(selectedCause.value.details);
      setHostile(selectedCause.value.enviroments.hostile);
      setAmend(selectedCause.value.enviroments.amend);
      setAvoid(selectedCause.value.enviroments.avoid);
      prevDetailsRef.current = selectedCause.value.details;
      prevHostileRef.current = selectedCause.value.enviroments.hostile;
      prevAmendRef.current = selectedCause.value.enviroments.amend;
      prevAvoidRef.current = selectedCause.value.enviroments.avoid;
    }
  }, [selectedCause]);

  useEffect(() => {
    if (
      selectedCause &&
      (prevDetailsRef.current !== details ||
        prevHostileRef.current !== hostile ||
        prevAmendRef.current !== amend ||
        prevAvoidRef.current !== avoid)
    ) {
      prevDetailsRef.current = details;
      prevHostileRef.current = hostile;
      prevAmendRef.current = amend;
      prevAvoidRef.current = avoid;
      debouncedSaveCauseDerailsRef.current({
        reason: selectedCause.value.reason,
        details,
        hostile,
        amend,
        avoid,
        index: selectedCause.index,
      });
    }
  }, [details, hostile, amend, avoid]);

  return (
    <div className="dyTheme1 dyBorder1 rounded p-20">
      <div>
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div className="my-1">
            <h5>{t("General.causes_of_grievance")}</h5>
          </div>
          {/* <div>
                <MCButton
                  label={t("General.edit")}
                  variant="primary"
                  outline
                  onClick={() => toggleModal("causes")}
                />
              </div> */}
        </div>
        <Row>
          <Col lg="12" className="p-0">
            <small>{t("General.investigation_description")}</small>
            <small className="my-2 d-block">
              {t("General.grievance_causes")}:
            </small>
            <MCSelect
              options={options}
              value={selectedCause}
              onChange={setSelectedCause}
            />
          </Col>
        </Row>
        <div className="py-3">
          <h6 className="my-3">{t("General.add_investigation_cause")}</h6>
          <MCButton
            label={t("General.add_cause")}
            variant="primary"
            outline
            className="me-1"
            onClick={() => toggleModal("add-cause")}
          />
        </div>
        {selectedCause && (
          <>
            <div lg="12" className="p-0 mt-20">
              <small className="my-2 d-block">
                {t("General.cause_details")}:
              </small>
              <MCInput
                type="textarea"
                value={details}
                rows="5"
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <div>
              <div>
                <small className="mt-20 d-block">{t("General.hostile")}:</small>
                <div className="my-2">
                  <MCButton
                    label={t("General.yes")}
                    className="me-3"
                    variant="primary"
                    outline={!(typeof hostile === "string")}
                    width={160}
                    onClick={() => setHostile("")}
                  />
                  <MCButton
                    label="No"
                    variant="primary"
                    outline={!(hostile === null)}
                    width={160}
                    onClick={() => setHostile(null)}
                  />
                </div>
                {hostile !== null && (
                  <div>
                    <small className="my-2 d-block">
                      {t("General.details")}:
                    </small>
                    <MCInput
                      type="textarea"
                      value={hostile}
                      rows="5"
                      onChange={(e) => setHostile(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div>
                <small className="mt-20 d-block">{t("General.avoid")}</small>
                <div className="my-2">
                  <MCButton
                    label={t("General.yes")}
                    className="me-3"
                    variant="primary"
                    outline={!(typeof avoid === "string")}
                    width={160}
                    onClick={() => setAvoid("")}
                  />
                  <MCButton
                    label="No"
                    variant="primary"
                    outline={!(avoid === null)}
                    width={160}
                    onClick={() => setAvoid(null)}
                  />
                </div>
                {avoid !== null && (
                  <div>
                    <small className="my-2 d-block">
                      {t("General.details")}:
                    </small>
                    <MCInput
                      type="textarea"
                      value={avoid}
                      rows="5"
                      onChange={(e) => setAvoid(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div>
                <small className="mt-20 d-block">{t("General.amend")}:</small>
                <div className="my-2">
                  <MCButton
                    label={t("General.yes")}
                    className="me-3"
                    variant="primary"
                    outline={!(typeof amend === "string")}
                    width={160}
                    onClick={() => setAmend("")}
                  />
                  <MCButton
                    label="No"
                    variant="primary"
                    outline={!(amend === null)}
                    width={160}
                    onClick={() => setAmend(null)}
                  />
                </div>
                {amend !== null && (
                  <div>
                    <small className="my-2 d-block">
                      {t("General.details")}:
                    </small>
                    <MCInput
                      type="textarea"
                      value={amend}
                      rows="5"
                      onChange={(e) => setAmend(e.target.value)}
                    />
                  </div>
                )}
              </div>
              {/* <div>
                    <small className="mt-20 d-block">
                      ¿Hay algún reporte previo relevante al caso?
                    </small>
                    <div className="my-2">
                      <MCButton
                        label="Si"
                        className="me-3"
                        variant="primary"
                        outline
                        width={160}
                      />
                      <MCButton
                        label="No"
                        variant="primary"
                        outline
                        width={160}
                      />
                    </div>
                    <div>
                      <small className="my-2 d-block">
                        Número de folio del reporte
                      </small>
                      <CDBInput
                        hint="Número de folio del reporte"
                        size="medium"
                      />
                    </div>
                  </div>
                  <div>
                    <small className="mt-20 d-block">
                      ¿Hay violaciones adicionales derivado de la denuncia?
                    </small>
                    <div className="my-2">
                      <MCButton
                        label="Si"
                        className="me-3"
                        variant="primary"
                        outline
                        width={160}
                      />
                      <MCButton
                        label="No"
                        variant="primary"
                        outline
                        width={160}
                      />
                    </div>
                  </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
