import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { MCButton } from "../MainComponents/Button/Button";
import { Row, Col } from "react-bootstrap";

import { MCLabel } from "../MainComponents/Label/Label";
import { MCCheckbox, MCInput } from "../MainComponents/Input/Input";
import { MCSelect } from "../MainComponents/Select/Select";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";

import { utilitiesDashboard, createDashboard } from "../../apis/apiDashboard";
import { Loading } from "../Loading/String/Loading";
import { catalogByPart } from "../../apis";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { ArrowLeft, TrashCan } from "../Icon";
import "./Modal.scss";

const SelectChild = ({ options, selected = null, ...rest }) => {
  return (
    <>
      <MCSelect
        className="m-1"
        options={options}
        {...rest}
        value={options.find((item) => item.selected === true)}
      />
      {selected !== null && (
        <SelectChild
          options={options[selected].childs[0].options}
          selected={options[selected].childs[0].selected}
          {...rest}
        />
      )}
    </>
  );
};

const NewFilterComponent = ({
  getOptionsFromUtilities,
  handleShowNewFilter,
  handleNewFilter,
}) => {
  const [t] = useTranslation("components");
  // Para el titulo del filtro
  const [filterTitle, setFilterTitle] = useState("");
  // Los tipos de filtros que se pueden usar
  const [filterType, setFilterType] = useState([
    { label: t("ModalGraph.filter_by_status"), value: 1, selected: false },
    { label: t("ModalGraph.filter_by_field_form"), value: 2, selected: false },
  ]);
  // State para el typo filtro seleccionado
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  // State para las opciones de los filtros
  const [filterOptions, setFilterOptions] = useState([]);
  // State para el filtro seleccionado
  const [filterSelected, setFilterSelected] = useState(null);

  // State para los catalogos origen
  const [catalogue, setCatalogue] = useState("");
  const [is_own, setIsOwn] = useState(false);

  // State para los catalogos anidados
  const [selects, setSelects] = useState([]);

  // State para el valor del filtro aniadido seleccionado
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  // State para activar o desactivar el boton de agregar filtro
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // State para agregar un filtro para cada hijo en la ultima seleccion
  const [addEveryChild, setAddEveryChild] = useState(false);

  useEffect(() => {
    // Asignar opciones de filtro
    setFilterOptions([]);
  }, []);

  /**
   * Funcion que permite guardar el titulo
   * del filtro
   */
  const handleFilterTitle = (e) => {
    setFilterTitle(e.target.value);
    if (e.target.value === "") setIsButtonDisabled(true);
    else {
      if (selectedFilterType === 1 && filterSelected !== null)
        setIsButtonDisabled(false);
      else if (
        selectedFilterType === 2 &&
        filterSelected !== null &&
        selectedFilterValue !== null
      )
        setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite mostar el filtro
   * seleccionado
   */
  const changeFilterType = (e) => {
    // Asignar un valor vacio a los selects
    setSelects([]);
    // Desactivar el boton de agregar filtro
    setIsButtonDisabled(true);

    var filterTypeTmp = _.cloneDeep(filterType);
    setFilterType(
      filterTypeTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar un valor null al filtro seleccionado
    setFilterSelected(null);

    // Asignar opciones de filtro
    if (e.value === 1)
      setFilterOptions(getOptionsFromUtilities("filter_by_status"));
    if (e.value === 2)
      setFilterOptions(getOptionsFromUtilities("filter_fields_report"));

    // Asignar filtro seleccionado al estado
    setSelectedFilterType(e.value);
  };

  /**
   * Funcion que permite cambiar el filtro seleccionado
   */
  const changeFilterSelected = (e) => {
    resetCatalogueChilds(e);

    var filterOptionsTmp = _.cloneDeep(filterOptions);
    setFilterOptions(
      filterOptionsTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar filtro seleccionado al estado
    setFilterSelected(e.value);
    // Aqui es donde hay que ver que tipo de filtro es para
    // activar o mantener desactivado el boton de agregar filtro
    if (selectedFilterType === 1) {
      if (filterTitle !== "") setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite agregar un nuevo
   * select al filtro
   */
  const handleNestedSelect = async (select_father) => {
    // Valor del filtro seleccionado
    setSelectedFilterValue(select_father.value);

    const origin = select_father.path;
    const itemIndex = origin.split(".").pop();
    const selectedPathArray = origin.split(".");
    var newElements = null;

    selectedPathArray.pop();
    selectedPathArray.pop();

    var selectedPath = "";

    for (let i = 0; i < selectedPathArray.length; i++) {
      if (i === 0) selectedPath = selectedPathArray[i];
      else selectedPath += `.${selectedPathArray[i]}`;
    }

    const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);

    if (select_father.continue !== "") {
      await catalogByPart({
        catalogue: catalogue,
        is_own: is_own,
        path: select_father.value,
      }).then((response) => {
        newElements = response.data;
      });
    }

    _.set(newSelects, `${origin}.selected`, true);

    if (newElements !== null) {
      _.set(newSelects, `${selectedPath}.selected`, itemIndex);
      _.set(newSelects, `${origin}.childs`, [
        createOptionsSelects(newElements, origin),
      ]);
    }

    setSelects(newSelects);
    // Aqui es donde se debe activar el boton de agregar filtro
    if (filterTitle !== "") setIsButtonDisabled(false);
  };

  /**
   * Funcion que permite remover los selects
   * anidados y regresar el valor de selected
   * a false de cada uno de los elementos
   */
  const removeNestedSelects = (data, path) => {
    const selectsTmp = _.get(data, `${path}.options`);

    _.set(
      data,
      `${path}.options`,
      selectsTmp.map((opt) => {
        opt.childs = [];
        opt.selected = false;
        return opt;
      })
    );

    _.set(data, `${path}.selected`, null);

    return data;
  };

  /**
   * Funcion que permite resetear los datos del
   * catalogo seleccionado de la lista de filtros
   * campos del reporte
   */
  const resetCatalogueChilds = (data) => {
    setCatalogue("");
    setIsOwn(false);
    setSelectedFilterValue(null);

    if (selectedFilterType === 1) return;

    const originCatalogue = data.value.split(":::").pop().split("::");
    const catalogueLocal = originCatalogue.pop();
    const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
    const pathLocal = "/";
    setCatalogue(catalogueLocal);
    setIsOwn(isOwnLocal);

    catalogByPart({
      catalogue: catalogueLocal,
      is_own: isOwnLocal,
      path: pathLocal,
    }).then((response) => {
      setSelects([createOptionsSelects(response.data)]);
    });
  };

  /**
   * Funcion que permite crear las opciones
   * de los selects anidados
   */
  const createOptionsSelects = (data, origin = null, selected = null) => {
    return {
      selected: selected,
      options: data.map((item, idx) => {
        return {
          label: item.label,
          value: item.path_locales,
          continue: item.path,
          path: origin
            ? `${origin}.childs.0.options.${idx}`
            : `0.options.${idx}`,
          childs: [],
          selected: false,
        };
      }),
    };
  };

  /**
   * Funcion que recolecta los datos del filtro
   * y los agrega a la lista de filtros
   */
  const handleAddFilter = () => {
    handleNewFilter({
      label: filterTitle,
      function:
        selectedFilterType === 1
          ? filterSelected
          : `${filterSelected}?${selectedFilterValue}`,
    });
    // Aqui es donde se regresa a la pantanlla del
    // cuerpo del filtro
    handleShowNewFilter(false);
  };
  /**
   * Funcion para buscar en el schema el ultimo
   * scope de hijos que hay en las selecciones
   */
  const recursiveReturnFilters = (childs, lastOptions) => {
    if (Array.isArray(childs) && childs.length > 0) {
      const options = childs[0].options || [];
      const idxOpt = options.findIndex((opt) => opt.selected === true);
      if (idxOpt >= 0) {
        return recursiveReturnFilters(options[idxOpt].childs, options);
      } else {
        return options;
      }
    } else {
      return lastOptions;
    }
  };
  /**
   * Funcion para agregar un filtro
   * por cada hijo que halla en el primer scope
   */
  const handleAddFilterForEveryChild = () => {
    const currentFilters = recursiveReturnFilters(selects);

    let allFilters;
    if (addEveryChild && Array.isArray(currentFilters)) {
      allFilters = currentFilters.map((item) => {
        return {
          // label: `${filterTitle} - ${item.label}`,
          label: `${item.label}`,
          function:
            selectedFilterType === 1
              ? filterSelected
              : `${filterSelected}?${item.value}`,
        };
      });
    }
    if (allFilters) {
      handleNewFilter(allFilters, true);
      handleShowNewFilter(false);
    }
  };
  /**
   * Retorna un valor para habilitar o inhabilitar el
   * boton para agregar un filtro por cada hijo
   */
  const isButtonAddFilterForEveryChildDisabled = () => {
    if (filterTitle.trim() === "") {
      return true;
    }
    if (filterSelected === null) {
      return true;
    }
    if (!Array.isArray(selects[0]?.options)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="filter-modal">
        <MCLabel text={`${t("ModalGraph.data_series_name")}:`} />
        <MCInput
          type="text"
          name="title"
          className="m-1"
          placeholder={t("ModalGraph.enter_category_name")}
          autoComplete="off"
          value={filterTitle}
          onChange={handleFilterTitle}
        />
      </div>

      <div>
        <MCLabel text={`${t("ModalGraph.select_filter")}:`} />
        <MCSelect
          className="m-1"
          options={filterType}
          onChange={(e) => changeFilterType(e)}
          value={filterType.find((item) => item.selected === true)}
        />
      </div>

      {selectedFilterType === 1 && (
        <div>
          <MCLabel text={`${t("ModalGraph.filter_by_status")}:`} />
          <MCSelect
            className="m-1"
            options={filterOptions}
            onChange={(e) => changeFilterSelected(e)}
            value={filterOptions.find((item) => item.selected === true)}
          />
        </div>
      )}

      {selectedFilterType === 2 && (
        <div>
          <div className="d-flex justify-content-between">
            <MCLabel text={`${t("ModalGraph.filter_by_field_form")}:`} />
            <div className="d-flex align-items-center">
              <MCCheckbox
                id="agregar_un_filtro_por_cada_opcion_del_catalogo"
                className="me-2"
                checked={addEveryChild}
                onChange={(e) => setAddEveryChild(e.target.checked)}
              />
              <MCLabel
                htmlFor="agregar_un_filtro_por_cada_opcion_del_catalogo"
                text={t("ModalGraph.include_all")}
              />
            </div>
          </div>
          <MCSelect
            className="m-1"
            options={filterOptions}
            onChange={(e) => changeFilterSelected(e)}
            value={filterOptions.find((item) => item.selected === true)}
          />
        </div>
      )}

      {selects.length > 0 && <hr className="my-4" />}

      {selects.map((item) => {
        return (
          <SelectChild
            options={item.options}
            selected={item.selected}
            onChange={(e) => handleNestedSelect(e)}
          />
        );
      })}

      {/* <br /> */}
      {/* <pre>
        <p> Titulo del filtro: <strong>{filterTitle}</strong> </p>
        <p> Tipo de filtro: <strong>{selectedFilterType === 1 ? "Estatus" : "Catálogos"}</strong> </p>
        <p> Filtro seleccionado: <strong>{selectedFilterType === 1 ? filterSelected : `${filterSelected}?${selectedFilterValue}`}</strong> </p>
        <p> Estatus del boton? <strong>{isButtonDisabled === true ? "Desactivado" : "Activado"}</strong> </p>
      </pre> */}

      <br />
      <div className="add-indicator">
        {!addEveryChild ? (
          <MCButton
            label={t("ModalGraph.create")}
            variant={isButtonDisabled ? "secondary" : "success"}
            onClick={handleAddFilter}
            disabled={isButtonDisabled}
          />
        ) : (
          <MCButton
            label={t("ModalGraph.add")}
            variant={
              isButtonAddFilterForEveryChildDisabled() ? "secondary" : "success"
            }
            onClick={handleAddFilterForEveryChild}
            disabled={isButtonAddFilterForEveryChildDisabled()}
          />
        )}
      </div>

      {/* <pre>{JSON.stringify({
        selects
      },null,2)}</pre> */}
    </>
  );
};

/**
 * componente que permite construir los indicadores
 */
const IndicatorComponent = ({
  getOptionsFromUtilities,
  showIndicator = null,
  listIndicators = null,
  fnLoadingIndicator = null,
  fnCloseModal = null,
  setShowMainOptions,
}) => {
  const [t] = useTranslation("components");
  // Para el titulo del filtro
  const [filterTitle, setFilterTitle] = useState("");
  // Los tipos de filtros que se pueden usar
  const [filterType, setFilterType] = useState([
    {
      label: `${t("ModalGraph.filter_by_status")}`,
      value: 1,
      selected: false,
    },
    {
      label: `${t("ModalGraph.filter_by_field_form")}`,
      value: 2,
      selected: false,
    },
  ]);
  // State para el typo filtro seleccionado
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  // State para las opciones de los filtros
  const [filterOptions, setFilterOptions] = useState([]);
  // State para el filtro seleccionado
  const [filterSelected, setFilterSelected] = useState(null);

  // State para los catalogos origen
  const [catalogue, setCatalogue] = useState("");
  const [is_own, setIsOwn] = useState(false);

  // State para los catalogos anidados
  const [selects, setSelects] = useState([]);

  // State para el valor del filtro aniadido seleccionado
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  // State para mostrar el cuerpo del filtro
  const [showFilterBody, setShowFilterBody] = useState(false);

  // State para activar o desactivar el boton de agregar filtro
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // State para activar o desactivar el boton de crear indicador
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

  // State donde se almacenan los filtros de los indicadores
  const [stackFilters, setStackFilters] = useState([]);

  useEffect(() => {
    // Asignar opciones de filtro
    setFilterOptions([]);
  }, []);

  /**
   * Funcion que permite guardar el titulo
   * del filtro
   */
  const handleFilterTitle = (e) => {
    setFilterTitle(e.target.value);
    if (e.target.value === "") {
      setIsButtonDisabled(true);
      setIsCreateButtonDisabled(true);
    } else {
      if (stackFilters.length > 0) setIsCreateButtonDisabled(false);
      if (selectedFilterType === 1 && filterSelected !== null)
        setIsButtonDisabled(false);
      else if (
        selectedFilterType === 2 &&
        filterSelected !== null &&
        selectedFilterValue !== null
      )
        setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite mostar el filtro
   * seleccionado
   */
  const changeFilterType = (e) => {
    // Asignar un valor vacio a los selects
    setSelects([]);
    // Desactivar el boton de agregar filtro
    setIsButtonDisabled(true);

    var filterTypeTmp = _.cloneDeep(filterType);
    setFilterType(
      filterTypeTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar un valor null al filtro seleccionado
    setFilterSelected(null);

    // Asignar opciones de filtro
    if (e.value === 1)
      setFilterOptions(getOptionsFromUtilities("filter_by_status"));
    if (e.value === 2)
      setFilterOptions(getOptionsFromUtilities("filter_fields_report"));

    // Asignar filtro seleccionado al estado
    setSelectedFilterType(e.value);
  };

  /**
   * Funcion que permite cambiar el filtro seleccionado
   */
  const changeFilterSelected = (e) => {
    resetCatalogueChilds(e);

    var filterOptionsTmp = _.cloneDeep(filterOptions);
    setFilterOptions(
      filterOptionsTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar filtro seleccionado al estado
    setFilterSelected(e);
    // Aqui es donde hay que ver que tipo de filtro es para
    // activar o mantener desactivado el boton de agregar filtro
    if (selectedFilterType === 1) {
      if (filterTitle !== "") setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite agregar un nuevo
   * select al filtro
   */
  const handleNestedSelect = async (select_father) => {
    // Valor del filtro seleccionado
    setSelectedFilterValue(select_father);

    const origin = select_father.path;
    const itemIndex = origin.split(".").pop();
    const selectedPathArray = origin.split(".");
    var newElements = null;

    selectedPathArray.pop();
    selectedPathArray.pop();

    var selectedPath = "";

    for (let i = 0; i < selectedPathArray.length; i++) {
      if (i === 0) selectedPath = selectedPathArray[i];
      else selectedPath += `.${selectedPathArray[i]}`;
    }

    const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);

    if (select_father.continue !== "") {
      await catalogByPart({
        catalogue: catalogue,
        is_own: is_own,
        path: select_father.value,
      }).then((response) => {
        newElements = response.data;
      });
    }

    _.set(newSelects, `${origin}.selected`, true);

    if (newElements !== null) {
      _.set(newSelects, `${selectedPath}.selected`, itemIndex);
      _.set(newSelects, `${origin}.childs`, [
        createOptionsSelects(newElements, origin),
      ]);
    }

    setSelects(newSelects);
    // Aqui es donde se debe activar el boton de agregar filtro
    if (filterTitle !== "") setIsButtonDisabled(false);
  };

  /**
   * Funcion que permite remover los selects
   * anidados y regresar el valor de selected
   * a false de cada uno de los elementos
   */
  const removeNestedSelects = (data, path) => {
    const selectsTmp = _.get(data, `${path}.options`);

    _.set(
      data,
      `${path}.options`,
      selectsTmp.map((opt) => {
        opt.childs = [];
        opt.selected = false;
        return opt;
      })
    );

    _.set(data, `${path}.selected`, null);

    return data;
  };

  /**
   * Funcion que permite resetear los datos del
   * catalogo seleccionado de la lista de filtros
   * campos del reporte
   */
  const resetCatalogueChilds = (data) => {
    setCatalogue("");
    setIsOwn(false);
    setSelectedFilterValue(null);

    if (selectedFilterType === 1) return;

    const originCatalogue = data.value.split(":::").pop().split("::");
    const catalogueLocal = originCatalogue.pop();
    const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
    const pathLocal = "/";
    setCatalogue(catalogueLocal);
    setIsOwn(isOwnLocal);

    catalogByPart({
      catalogue: catalogueLocal,
      is_own: isOwnLocal,
      path: pathLocal,
    }).then((response) => {
      setSelects([createOptionsSelects(response.data)]);
    });
  };

  /**
   * Funcion que permite crear las opciones
   * de los selects anidados
   */
  const createOptionsSelects = (data, origin = null, selected = null) => {
    return {
      selected: selected,
      options: data.map((item, idx) => {
        return {
          label: item.label,
          value: item.path_locales,
          continue: item.path,
          path: origin
            ? `${origin}.childs.0.options.${idx}`
            : `0.options.${idx}`,
          childs: [],
          selected: false,
        };
      }),
    };
  };

  /**
   * Funcion que permite resetear los valores
   */
  const resetValues = () => {
    setFilterType(
      filterType.map((item) => {
        item.selected = false;
        return item;
      })
    );
    setSelectedFilterType(null);
    setFilterSelected(null);
    setSelectedFilterValue(null);
    setSelects([]);
    setShowFilterBody(false);
    setIsButtonDisabled(true);
  };

  /**
   * Funcion que recolecta los datos del filtro
   * y los agrega a la lista de filtros
   */
  const handleAddFilter = () => {
    if (selectedFilterType === 1 && filterSelected !== null) {
      setStackFilters([
        ...stackFilters,
        {
          label: filterSelected.label,
          value: filterSelected.value,
        },
      ]);
    } else if (
      selectedFilterType === 2 &&
      filterSelected !== null &&
      selectedFilterValue !== null
    ) {
      setStackFilters([
        ...stackFilters,
        {
          label: `${filterSelected.label} -> ${selectedFilterValue.label}`,
          value: `${filterSelected.value}?${selectedFilterValue.value}`,
        },
      ]);
    }

    // Resetear los valores de los filtros
    resetValues();
    // Activar el boton de crear
    setIsCreateButtonDisabled(false);
  };

  /**
   * Funcion que permite eliminar un filtro
   * de la lista de filtros
   */
  const handleDeleteFilter = (filter) => {
    setStackFilters(stackFilters.filter((item) => item.value !== filter.value));
    if (stackFilters.length === 1) {
      setIsCreateButtonDisabled(true);
    }
  };

  /**
   * Funcion que permite crear el indicador
   */
  const handleCreateIndicator = async () => {
    const title = filterTitle;
    const filter_param = stackFilters.map((item) => item.value);
    const show_indicator = "show_indicators#type#num";
    const metadata = {};

    const indicatorItem = {
      title,
      filter_param,
      show_indicator,
      metadata,
    };

    if (showIndicator !== null && listIndicators !== null) {
      listIndicators(indicatorItem);
      showIndicator(false);
    } else {
      const data = {
        graphics: [],
        indicators: [indicatorItem],
      };

      const response = await createDashboard(data);
      if (response !== undefined && fnLoadingIndicator !== null) {
        if (fnCloseModal !== null) fnCloseModal(true);
        fnLoadingIndicator(true);
      }
    }
  };

  /**
   * Funcion que permite cancelar la creacion
   */
  const handleCancel = () => {
    if (showIndicator !== null) showIndicator(false);
  };
  return (
    <>
      <Row className="px-1 my-2">
        <MCLabel text={`${t("ModalGraph.indicator_name")}:`} />
        <MCInput
          type="text"
          name="title"
          placeholder={t("ModalGraph.enter_indicator_name")}
          autoComplete="off"
          value={filterTitle}
          onChange={handleFilterTitle}
          className="m-1"
        />
      </Row>

      {!showFilterBody && (
        <Row className="px-2">
          <div className="container-btn-new-filter">
            <MCLabel
              text={`${t("ModalGraph.convine_filters")}:`}
              helptip={t("ModalGraph.you_can_add_filters")}
            />
            <MCButton
              label={t("ModalGraph.add_filter")}
              variant="primary"
              outline
              onClick={() => setShowFilterBody(true)}
              className="px-4"
            />
          </div>
        </Row>
      )}

      {showFilterBody && (
        <>
          <Row className="px-1 my-2">
            <MCLabel text={`${t("ModalGraph.select_filter")}:`} />
            <MCSelect
              options={filterType}
              className="m-1"
              onChange={(e) => changeFilterType(e)}
              value={filterType.find((item) => item.selected === true)}
            />
          </Row>

          {selectedFilterType === 1 && (
            <Row className="px-1 my-2">
              <MCLabel text={`${t("ModalGraph.filter_by_status")}:`} />
              <MCSelect
                className="m-1"
                options={filterOptions}
                onChange={(e) => changeFilterSelected(e)}
                value={filterOptions.find((item) => item.selected === true)}
              />
            </Row>
          )}

          {selectedFilterType === 2 && (
            <Row className="px-1 my-2">
              <MCLabel text={`${t("ModalGraph.filter_by_field_form")}:`} />
              <MCSelect
                className="m-1"
                options={filterOptions}
                onChange={(e) => changeFilterSelected(e)}
                value={filterOptions.find((item) => item.selected === true)}
              />
            </Row>
          )}

          {selects.map((item) => {
            return (
              <Row>
                <SelectChild
                  options={item.options}
                  selected={item.selected}
                  onChange={(e) => handleNestedSelect(e)}
                />
              </Row>
            );
          })}

          <br />
          <hr />
          <div className="add-indicator">
            <MCButton
              label={t("ModalGraph.add_filter")}
              variant="primary"
              outline
              onClick={handleAddFilter}
              disabled={isButtonDisabled}
            />
            <MCButton
              label={t("ModalGraph.cancel")}
              variant="danger"
              className="mx-2"
              outline
              onClick={resetValues}
            />
          </div>
        </>
      )}

      {stackFilters.length > 0 && !showFilterBody && (
        <Row>
          <div className="stack-filters-container">
            {stackFilters.map((item, idx) => {
              return (
                <div className="item-filter">
                  <MCLabel text={item.label} />
                  <MCTooltip text={t("ModalGraph.delete")} position="bottom">
                    <div>
                      <TrashCan
                        className="trash"
                        onClick={() => handleDeleteFilter(item)}
                      />
                    </div>
                  </MCTooltip>
                </div>
              );
            })}
          </div>
        </Row>
      )}

      {!showFilterBody && (
        <>
          <hr />
          <div className="footer-modal">
            {!showIndicator ? (
              <div>
                <MCTooltip text={t("ModalGraph.back")} position="top">
                  <button
                    id="return"
                    label={t("ModalGraph.back")}
                    onClick={() => setShowMainOptions(true)}
                    className="turnBack"
                  >
                    <ArrowLeft width={25} heigth={30} />
                  </button>
                </MCTooltip>
              </div>
            ) : (
              <div>{""}</div>
            )}
            <div>
              <MCButton
                label={t("ModalGraph.create")}
                variant={isCreateButtonDisabled ? "secondary" : "success"}
                disabled={isCreateButtonDisabled}
                onClick={handleCreateIndicator}
              />
              {showIndicator !== null && (
                <MCButton
                  label={t("ModalGraph.cancel")}
                  variant="danger"
                  className="mx-2"
                  outline
                  onClick={handleCancel}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ModalGraph = (props) => {
  const [t] = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme !== "light" ? "dark" : "";

  const [showMainOptions, setShowMainOptions] = useState(true);
  const [elementOptions, setElementOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [stateData, setStateData] = useState(null); // Aqui es donde se almacena las utilidades del dashboard

  /**
   * Aqui es donde iran los datos que se van a mostrar en el grafico
   */
  const [titleGrafic, setTitleGrafic] = useState(""); // Titulo del grafico
  const [typeGrafic, setTypeGrafic] = useState(null); // Como se va a mostrar el grafico
  const [typeDataGrafic, setTypeDataGrafic] = useState(null); // Que tipo de datos se van a mostrar en el grafico

  const [showNewFilter, setShowNewFilter] = useState(false); // Para mostrar el modal de nuevo filtro
  const [showNewIndicator, setShowNewIndicator] = useState(false); // Para mostrar el modal de nuevo indicador

  const [stackFilters, setStackFilters] = useState([]); // Aqui se almacenan los filtros que se van a aplicar al grafico
  const [stackIndicators, setStackIndicators] = useState([]); // Aqui se almacenan los indicadores que se van a aplicar al grafico

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Para desactivar el boton de agregar filtro

  /**
   * Funciones
   */
  useEffect(() => {
    utilitiesDashboard().then((response) => {
      setStateData(response);
      setIsLoading(false);
    });

    setElementOptions([
      { label: t("ModalGraph.graph"), value: 1, selected: false },
      { label: t("ModalGraph.indicator"), value: 2, selected: false },
    ]);
  }, []);

  const handleSelectOption = (option) => {
    setElementOptions(
      elementOptions.map((item) => {
        if (item.value === option.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    setShowMainOptions(false);
  };

  const getOptionsFromElementUtilities = (path) => {
    let options = getElementFromUtilities(path).childs.map((item) => {
      return {
        label: item.locales[t("lang")] ? item.locales[t("lang")] : item.label,
        value: item.path,
        key: path,
        selected: false,
      };
    });

    return options;
  };

  const getElementFromUtilities = (path) => {
    return stateData["utilities"].filter((item) => item.path === path).pop();
  };

  const handleShowNewFilter = (is_active) => {
    setShowNewFilter(is_active);
  };

  const handleNewFilter = (filter, addEachChild = false) => {
    if (addEachChild) {
      setStackFilters([...stackFilters, ...filter]);
    } else {
      setStackFilters([...stackFilters, filter]);
    }
    if (titleGrafic !== "" && typeGrafic !== null && typeDataGrafic !== null)
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleNewIndicator = (indicator) => {
    setStackIndicators([...stackIndicators, indicator]);
  };

  const handleDeleteFilter = (filter) => {
    setStackFilters(stackFilters.filter((item) => item.label !== filter.label));
    if (
      titleGrafic !== "" &&
      typeGrafic !== null &&
      typeDataGrafic !== null &&
      stackFilters.length > 1
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleDeleteIndicator = (indicator) => {
    setStackIndicators(
      stackIndicators.filter((item) => item.title !== indicator.title)
    );
  };

  const handleTitleGrafic = (e) => {
    setTitleGrafic(e.target.value);
    if (
      e.target.value !== "" &&
      typeGrafic !== null &&
      typeDataGrafic !== null &&
      stackFilters.length > 0
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleTypeGrafic = (e) => {
    setTypeGrafic(e);
    if (
      titleGrafic !== "" &&
      typeDataGrafic !== null &&
      stackFilters.length > 0
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleTypeDataGrafic = (e) => {
    setTypeDataGrafic(e);
    if (titleGrafic !== "" && typeGrafic !== null && stackFilters.length > 0)
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleCreateGrafic = async () => {
    let data = {
      graphics: [
        {
          title: titleGrafic,
          filter_params: stackFilters,
          show_graphic: typeGrafic.value,
          get_data: typeDataGrafic.value,
          indicators: stackIndicators,
          metadata: {},
          is_active: true,
        },
      ],
      indicators: [],
    };

    const response = await createDashboard(data);

    if (response !== undefined) {
      props.onHide();
      props.stateLoadingGraphics(true);
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalContainer"
        contentClassName={`modal-example-dialog ${theme}`}
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className={theme === "dark" ? "btn-close-white" : ""}
        >
          <Modal.Title bsPrefix={`cus-modal-title ${theme}`}>
            <h3>{t("ModalGraph.add_chart_or_indicator")}</h3>
            <h6 className={`cus-modal-title ${theme}`}>
              {t("ModalGraph.use_filters_to_create")}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-body">
          {isLoading ? (
            <Loading />
          ) : showMainOptions ? (
            <div className="main-component">
              <div className="w-50">
                <MCLabel text={t("ModalGraph.what_want_create")} />
                <MCSelect
                  className="m-1"
                  options={elementOptions}
                  onChange={(e) => handleSelectOption(e)}
                />
              </div>
            </div>
          ) : !showMainOptions && elementOptions[0].selected ? (
            <>
              {showNewFilter ? (
                <NewFilterComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  handleShowNewFilter={handleShowNewFilter}
                  handleNewFilter={handleNewFilter}
                />
              ) : showNewIndicator ? (
                <IndicatorComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  showIndicator={setShowNewIndicator}
                  listIndicators={handleNewIndicator}
                />
              ) : (
                <>
                  <Row>
                    <Col>
                      <MCLabel text={`${t("ModalGraph.graph_name")}:`} />
                      <MCInput
                        type="text"
                        name="title"
                        className="m-1"
                        placeholder={t("ModalGraph.name_your_chart")}
                        value={titleGrafic}
                        onChange={(e) => handleTitleGrafic(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <MCLabel text={`${t("ModalGraph.graph_type")}:`} />
                      <MCSelect
                        className="m-1"
                        options={getOptionsFromElementUtilities("show_graphic")}
                        onChange={(e) => handleTypeGrafic(e)}
                        value={typeGrafic}
                      />
                    </Col>
                    <Col>
                      <MCLabel
                        text={`${t("ModalGraph.graph_data_representation")}:`}
                      />
                      <MCSelect
                        className="m-1"
                        options={getOptionsFromElementUtilities("get_data")}
                        onChange={(e) => handleTypeDataGrafic(e)}
                        value={typeDataGrafic}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="head-container-filter">
                        <MCButton
                          label={t("ModalGraph.add_data_series")}
                          variant="primary"
                          outline
                          onClick={() => handleShowNewFilter(true)}
                        />
                      </div>
                    </Col>
                    {stackFilters.length !== 0 && (
                      <Col>
                        <div className="head-container-filter">
                          <MCLabel
                            className="px-2"
                            text={`${t("ModalGraph.configure_indicators")}...`}
                          />
                          <MCButton
                            label="+"
                            variant="primary"
                            outline
                            onClick={() => setShowNewIndicator(true)}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  {stackFilters.length !== 0 && (
                    <Row>
                      <Col>
                        <div className="filter-container">
                          {stackFilters.map((item, i) => {
                            return (
                              <div className="item-filter" key={i}>
                                <MCLabel text={item.label} />
                                <MCTooltip
                                  text={t("ModalGraph.delete")}
                                  position="bottom"
                                >
                                  <div>
                                    <TrashCan
                                      className="trash"
                                      onClick={() => handleDeleteFilter(item)}
                                    />
                                  </div>
                                </MCTooltip>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                      {stackIndicators.length !== 0 && (
                        <Col>
                          <div className="filter-container">
                            {stackIndicators.map((item) => {
                              return (
                                <div className="item-filter">
                                  <MCLabel text={item.title} />
                                  <MCTooltip
                                    text={t(
                                      "dashboard.modals.addGraphOrInd.buttons.delete"
                                    )}
                                    position="bottom"
                                  >
                                    <div>
                                      <TrashCan
                                        className="trash"
                                        onClick={() =>
                                          handleDeleteIndicator(item)
                                        }
                                      />
                                    </div>
                                  </MCTooltip>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                  <hr />
                  <div className="footer-modal">
                    <div>
                      <MCTooltip text={t("ModalGraph.back")} position="top">
                        <button
                          id="return"
                          label={t("ModalGraph.back")}
                          onClick={() => setShowMainOptions(true)}
                          className="turnBack"
                        >
                          <ArrowLeft width={25} heigth={30} />
                        </button>
                      </MCTooltip>
                    </div>
                    <div>
                      <MCButton
                        id="cancel"
                        label={t("ModalGraph.cancel")}
                        variant="danger"
                        outline
                        onClick={props.onHide}
                      />
                      <MCButton
                        id="saved"
                        label={t("ModalGraph.create")}
                        variant={isButtonDisabled ? "secondary" : "success"}
                        disabled={isButtonDisabled}
                        onClick={() => handleCreateGrafic()}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            !showMainOptions &&
            elementOptions[1].selected && (
              <>
                <IndicatorComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  fnLoadingIndicator={props.stateLoadingIndicators}
                  fnCloseModal={props.onHide}
                  setShowMainOptions={setShowMainOptions}
                />
              </>
            )
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalGraph;
