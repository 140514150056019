import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Person1 from "../../../../assets/images/person1.png";
import Person2 from "../../../../assets/images/person2.png";
import { OutlineLogout, OutlineSmsTracking } from "../../../Icon";
import { parseDateToISOString2 } from "../../../../helpers/dateTransform";
import { MCTooltip } from "../../../MainComponents";
import { AvatarLetter } from "../../../MainComponents/Avatar/AvatarLetter";
import { AvatarGroup } from "../../../MainComponents/Avatar/AvatarGroup";

export const TextBubble = ({ msg, onReply }) => {
  const [t] = useTranslation("messages");
  const [dragging, setDragging] = useState(false);
  const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (msg.recipients) {
      const contactList = Object.entries(msg.recipients).map(([email, name]) => ({
        name,
        email
      }));
      setContacts(contactList);
    }
  }, [msg.recipients]);

  // Limit move
  const maxMove = 25;
  // To reply a message
  const handleReply = () => {
    onReply(msg);
  };
  // Start drag event on website
  const startDrag = (e) => {
    setDragging(true);
    setOriginalPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };
  // Start drag event on touch device
  const startDragTouch = (e) => {
    setDragging(true);
    setOriginalPosition({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    });
  };
  // Move TextBubble to reply on website
  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaX = e.clientX - originalPosition.x;
      setPosition({
        x: Math.min(maxMove, Math.max(0, deltaX)),
        y: 0,
      });
    }
  };
  // Move TextBubble to reply on touch device
  const handleTouchMove = (e) => {
    if (dragging) {
      const deltaX = e.touches[0].clientX - originalPosition.x;
      setPosition({
        x: Math.min(maxMove, Math.max(0, deltaX)),
        y: 0,
      });
    }
  };
  // End the drag event to reply to a message and return to its original position
  const endDrag = () => {
    if (dragging) {
      setDragging(false);
      if (position.x > maxMove - 1) {
        handleReply();
      }
      setPosition({ x: 0, y: 0 });
    }
  };



  return (
    <div
      className="textBubble"
      style={{
        justifyContent: msg.is_sender ? "flex-end" : "flex-start",
        transform: msg.is_sender
          ? null
          : `translate(${position.x}px, ${position.y}px)`,
        cursor: msg.is_sender ? null : dragging ? `grabbing` : "",
      }}
      //on web site
      onMouseDown={msg.is_sender ? null : startDrag}
      onMouseMove={msg.is_sender ? null : handleMouseMove}
      onMouseUp={msg.is_sender ? null : endDrag}
      onMouseLeave={msg.is_sender ? null : endDrag}
      //on a touch device
      onTouchStart={msg.is_sender ? null : startDragTouch}
      onTouchMove={msg.is_sender ? null : handleTouchMove}
      onTouchEnd={msg.is_sender ? null : endDrag}
    >
      <div className={`chatBox ${msg.is_sender ? "--send" : "--receive"}`}>
        <div className="border-bottom">
          <div className="mb-1 chatBox__titular">
            <img
              src={msg.is_sender ? Person1 : Person2}
              alt={msg.sender_email}
            />
            <h6 className="ms-1">
              {`${msg.is_anonymous
                  ? t("messages.anonymous")
                  : msg?.sender_email || t("messages.anonymous")
                }`}
            </h6>
          </div>
          <div className="chatBox__message">
            {msg.recipient_count > 0 && (
              <div className="directMsg my-2">
                <div className="contact-list pb-2">
                  <h6>{`${t("avatarGroup.message_sent_to")}:`}</h6>
                  {msg.recipient_count === 1 ? (
                    <span>{contacts[0]?.name }</span>
                  ) : (
                    <AvatarGroup
                      contacts={contacts}
                      max={4}
                      title={`${t("avatarGroup.message_sent_to")}:`}
                    />
                  )}
                </div>
              </div>
            )}
            <small className="mx-2">{msg.content}</small>
          </div>
        </div>
        <div
          className="chatBox__dataSend"
          style={{ justifyContent: msg.is_sender ? "flex-end" : "flex-start" }}
        >
          <OutlineSmsTracking className="chatBox__icon" />
          <p className="chatBox__dateSend">
            {parseDateToISOString2(msg.created_at)}
          </p>
        </div>
      </div>
      {!msg.is_sender && (
        <MCTooltip text={t("messages.reply")} position="top">
          <button onClick={handleReply} className="chatBox__reply">
            <OutlineLogout />
          </button>
        </MCTooltip>
      )}
    </div>
  );
};
