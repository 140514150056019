import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Row, Col, FormCheck } from "react-bootstrap";
import Mexico from "../../../../../assets/images/mexico.png";
import { MoreIcon, Arrow3 } from "../../../../Icon";
import {
  parseDatoToLgAndHZ,
  validInitDates,
} from "../../../../../helpers/dateTransform";
import { patchInvestigation } from "../../../../../apis";
import Icon from "../../../../Icon/Icon";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCCheckbox, MCInput } from "../../../../MainComponents/Input/Input";
import { MCLabel } from "../../../../MainComponents/Label/Label";
import "./Entrevista.scss";
import { GeneralHeader } from "../../../../Title/GeneralHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const StartInterview = () => {
  const [t] = useTranslation("investigation");

  const returnRequired = (req) => {
    if (req) return `${t("Interview.required_question")} *`;
    return t("Interview.optional_question");
  };

  const kind = (kn) => {
    if (kn === "in-person") return t("Interview.in_person");
    if (kn === "video-conference") return t("Interview.videocall");
    return t("Interview.unspecified");
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { gTheme } = useSelector((state) => state.theme);
  const investigationAlias = location.state.investigationAlias;
  const interviews = location?.state?.interviews || null;
  const currentInterview = location?.state?.currentInterview || null;
  const currentInterviewIndex = location?.state?.index;

  const [interview, setInterview] = useState(
    Array.isArray(currentInterview?.interview)
      ? currentInterview?.interview
      : []
  );

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [required, setRequired] = useState(false);

  const handleChange = ({ value, path }) => {
    const newInterview = _.cloneDeep(interview);
    _.set(newInterview, path, value);
    setInterview(newInterview);
  };

  const handleAddQuestion = () => {
    setInterview((antQuestion) => [
      ...antQuestion,
      { question, answer, required },
    ]);
    setQuestion("");
    setAnswer("");
  };

  const handleSubmit = async (isFinish) => {
    let newForm;
    try {
      const newInterview = {
        ...currentInterview,
        interview,
        status: isFinish ? "finish" : "process",
      };
      newForm = _.cloneDeep(interviews);
      _.set(newForm, `${currentInterviewIndex}`, newInterview);

      const objToSave = {
        folder: {
          interviews: newForm,
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      //   console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`page ${gTheme !== "light" ? "dark-mode" : ""} p-4`}>
      <GeneralHeader
        title={t("Interview.conduct_interview")}
        text={t("Interview.conduct_interview_register_data")}
      />

      <Row>
        <Col lg="10" md="12" className="mx-auto">
          <div className="dyTheme1 dyBorder1 px-3 py-4 mt-30">
            <div className="d-block d-sm-flex align-items-end justify-content-between">
              <div>
                <h3>
                  {`${t("Interview.interview")} - ${
                    currentInterview?.interviewed
                  }`}
                </h3>
                {/* <h6>Testigo señalado por el denunciante</h6> */}
              </div>
            </div>
            <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
              <div className="d-block d-md-flex align-items-end justify-content-end">
                <MCButton
                  label={kind(currentInterview.kind)}
                  className="small-info-btn my-2 start_interview_btn"
                />
                {/* <CDBBtn className="small-info-btn my-2 start_interview_btn">
                  {kind(currentInterview.kind)}
                  <CDBIcon className="ml-2" icon="link" />
                </CDBBtn>
                <div className="d-flex my-2 justify-content-between">
                  <CDBBtn className="download-btn mr-2">
                    <CDBIcon className="mr-md-2" icon="upload" />
                    <span className="d-none d-md-inline-block">{t("Interview.upload")}</span>
                  </CDBBtn>
                  <CDBBtn className="download-btn mr-2">
                    <CDBIcon className="mr-md-2" icon="download" />
                    <span className="d-none d-md-inline-block">{t("Inteview")}</span>
                  </CDBBtn>
                  <CDBBtn className="download-btn mr-2">
                    <CDBIcon className="mr-md-2" icon="print" />
                    <span className="d-none d-md-inline-block">imprimir</span>
                  </CDBBtn>
                  <CDBBtn className="download-btn mr-2">
                    <CDBIcon icon="question-circle" />
                    <span className="d-none d-md-inline-block">ayuda</span>
                  </CDBBtn>
                </div> */}
              </div>

              <h6>
                {`${t("Interview.interviewer")}: ${
                  currentInterview?.interviewer?.name
                }`}
              </h6>
              <h6 className="my-3">{t("Interview.objectives_topics")}</h6>
              <small>
                {currentInterview?.details || t("Interview.unspecified")}
              </small>
            </div>
          </div>
          <div className="dyTheme1 dyBorder1 p-20  mt-20 pb-0">
            <div className="dyTheme1 dyBorder1 p-20">
              <div className="d-block d-sm-flex align-items-end justify-content-between">
                <div className="my-auto w-50">
                  <h5>{t("Interview.interviewee_answers")}</h5>
                </div>
                <div className="w-50">
                  <MCCheckbox
                    id="check-denied-be-interviewed"
                    className="me-2"
                  />
                  <MCLabel
                    text={t("Interview.refused_to_answer")}
                    htmlFor="check-denied-be-interviewed"
                    className="w-50"
                  />
                </div>
              </div>
            </div>
            {interview.map((item, idx) => {
              return (
                <div className="dyTheme1 dyBorder1 p-20 p-md-4 my-20" key={idx}>
                  <div className="pb-3 border-bottom">
                    <h6 className="m-0">
                      {t("Interview.question")} {idx + 1}
                    </h6>
                    {/* <small className="mt-2">El objetivo de la pregunta es auris ultrices, est sit amet imperdiet convallis, diam nunc consequat eros</small> */}
                  </div>
                  <h6 className="mt-20">{item?.question}</h6>
                  <MCInput
                    type="textarea"
                    rows="3"
                    placeholder={`${t("Interview.answer")} #${idx + 1}`}
                    value={item.answer}
                    onChange={(e) =>
                      handleChange({
                        value: e.target.value,
                        path: `${idx}.answer`,
                      })
                    }
                  />
                  {/* <textarea className="form-control" rows="3" placeholder={`Respuesta No. ${idx+1}`} value={item.answer} onChange={(e)=>handleChange({value: e.target.value, path: `${idx}.answer`})}></textarea> */}
                  <small className="mt-20">
                    {t("Interview.interviewer_comments")}:
                  </small>
                  <MCInput
                    type="textarea"
                    rows="3"
                    placeholder={`${t("Interview.comment")} #${idx + 1}`}
                    value={item.observations}
                    onChange={(e) =>
                      handleChange({
                        value: e.target.value,
                        path: `${idx}.observations`,
                      })
                    }
                  />
                  {/* <textarea
                    className="form-control"
                    rows="3"
                    placeholder={`Observación No. ${idx + 1}`}
                    value={item.observations}
                    onChange={(e) =>
                      handleChange({
                        value: e.target.value,
                        path: `${idx}.observations`,
                      })
                    }
                  /> */}
                  <div className="d-block d-sm-flex align-items-center text-center mt-20">
                    {/* <CDBBtn className="download-btn w-160 my-2" size="sm">
                      <Paperclip /> 5 archivos adjuntos
                    </CDBBtn> */}
                    <small className="ms-3">
                      {returnRequired(item?.required)}
                    </small>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="dyTheme1 dyBorder1 mt-20 p-20">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0">{t("Interview.new_question")}</h6>
              {/* <div className="d-flex">
                <MoreIcon className="me-3" />
                <Arrow3 />
              </div> */}
            </div>
            <small className="mt-20 mb-2">{t("Interview.question")}:</small>
            <MCInput
              type="textarea"
              rows="5"
              placeholder=""
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <small className="mt-20 mb-2">{t("Interview.answer")}:</small>
            <MCInput
              type="textarea"
              rows="5"
              placeholder=""
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />

            <div className="d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex">
                {/* <CDBSwitch isChecked={required} onChange={e=>setRequired(e.target.checked)} /> */}
                <FormCheck
                  type="switch"
                  checked={required}
                  onChange={(e) => setRequired(e.target.checked)}
                />
                <small className="ms-2">{t("Interview.required")}</small>
              </div>
              <MCButton
                label={t("Interview.save_question")}
                variant="primary"
                outline
                onClick={handleAddQuestion}
              />
            </div>
            {/* </div> */}
          </div>
          {/* <pre>{JSON.stringify(interview,null,2)}</pre> */}
          <div className="text-center text-md-right mt-30">
            <MCButton
              label={t("Interview.turn_back")}
              variant="primary"
              className="me-3"
              outline
              onClick={() => navigate(-1)}
            />
            <MCButton
              label={t("Interview.save_continue_later")}
              variant="primary"
              className="me-3"
              onClick={() => handleSubmit(false)}
            />
            <MCButton
              label={t("Interview.save_end_interview")}
              variant="primary"
              className="me-3"
              onClick={() => handleSubmit(true)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
