import React, { useEffect, useState, forwardRef } from "react";
import {
  SendIcon2,
  SendIcon,
  OutlineUserSquare,
  OutlineSms,
  BoldCloseCircle,
} from "../../../Icon";
import {
  createAnonymousMessage,
  createPrivateInternalMessage,
  createPublicInternalMessage,
  listAssignedUsers,
  listUsersWithoutSpecificReport,
} from "../../../../apis";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { MCTooltip } from "../../../MainComponents";
import { Button, Dropdown, FormControl } from "react-bootstrap";
import { AvatarLetter } from "../../../MainComponents/Avatar/AvatarLetter";
import { TextBubble } from "./TextBubble";
import "./Chat.scss";
// Message container
export const Chat = ({
  messages,
  typeMsgs,
  tracking_code,
  onReportDetailChange,
  id,
}) => {
  const [t] = useTranslation("messages");
  const { enqueueSnackbar } = useSnackbar();
  const [content, setContent] = useState("");
  const [inputHeight, setInputHeight] = useState("auto");
  const [contacts, setContacts] = useState([]);
  const [originalContacts, setOriginalContacts] = useState([]);
  // Data to send at backend
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [replyTo, setReplyTo] = useState(null);
  //Get contacts
  useEffect(() => {
    Promise.all([
      listAssignedUsers(id),
      listUsersWithoutSpecificReport(id),
    ]).then(([resp1, resp2]) => {
      const newListUsers = [...resp1.users, ...resp2.users];
      // Sort contacts alphabetically
      const contactSorted = newListUsers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setContacts(contactSorted);
      setOriginalContacts(contactSorted);
    });
  }, []);
  // Sets message reply
  const handleReply = (message) => {
    setReplyTo(message);
  };
  // When replying to a message has been selected
  const handleSendReply = (replyText) => {
    setReplyTo(null);
  };
  // Cancel reply
  const cancelMention = () => {
    setReplyTo(null);
  };
  // Selecting contacts
  const handleSelectContacts = (selectedContact) => {
    const updatedContacts = contacts.filter(
      (contact) => contact.id !== selectedContact.id
    );
    setSelectedContacts([...selectedContacts, selectedContact]);
    setContacts(updatedContacts);
  };
  // Unselect contacts
  const handleRemoveContact = (removedContact) => {
    const updatedSelectedContact = selectedContacts.filter(
      (contact) => contact.id !== removedContact.id
    );
    setContacts([...contacts, removedContact]);
    setSelectedContacts(updatedSelectedContact);
  };
  // Writing message
  const handleMessage = (e) => {
    const newMessage = e.target.value;
    setContent(newMessage);
    setInputHeight("auto");
    if (e.target.scrollHeight > e.target.clientHeight) {
      setInputHeight(`${e.target.scrollHeight}px`);
    }
  };
  // Dropdown CustomToggle by Bootstrap
  const ContactsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <MCTooltip position="top" text={t("messages.contacts")}>
      <Button
        variant="none"
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </Button>
    </MCTooltip>
  ));
  // Dropdown CustomMenu by Bootstrap
  const EthicContacts = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      // Initial state
      const [search, setSearch] = useState("");
      // Contacts are filtered through the search
      const childArray = React.Children.toArray(children);
      const filteredChildren = childArray
        .filter((child) => child.props && child.props.contact)
        .filter((child) => {
          const searchValue = search.toLowerCase();
          const contact = child.props.contact;
          const contactName = contact.name.toLowerCase();
          const contactEmail = contact.email.toLowerCase();
          // name and email are filtered
          return (
            !search ||
            contactName.includes(searchValue) ||
            contactEmail.includes(searchValue)
          );
        });
      //Menu content
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <h6 className="ethicsContact__header">{t("messages.contacts")}</h6>
          <ul className="list-unstyled ethicsContact__list-contact">
            {/* If there are no contacts */}
            {filteredChildren.length === 0 && search.trim() === "" && (
              <center className="ethicsContact__noContacts">
                <small>{t("messages.no_contacts")}</small>
              </center>
            )}
            {/* The search does not match the registered contacts */}
            {filteredChildren.length === 0 && search.trim() !== "" ? (
              <center className="ethicsContact__noContacts">
                <small>{t("messages.no_matches_search")}</small>
              </center>
            ) : (
              <div>{filteredChildren}</div>
            )}
          </ul>
          {/* Search input */}
          <div className="ethicsContact__footer">
            <FormControl
              autoFocus
              type="search"
              className="searchInput"
              placeholder={t("messages.search_contact")}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
      );
    }
  );
  // Sending message
  const handleSubmit = async () => {
    if (typeMsgs === "public") {
      try {
        await createPublicInternalMessage({ tracking_code, content }).then(
          (resp) => {
            if (resp.status === "OK") {
              onReportDetailChange();
              enqueueSnackbar(`${t("messages.send_success")}`, {
                variant: "success",
              });
              setContent("");
              setInputHeight("auto");
            } else {
              return;
            }
          }
        );
      } catch (error) {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    }
    if (typeMsgs === "private") {
      try {
        await createPrivateInternalMessage({
          tracking_code,
          content,
          selectedContacts,
        }).then((resp) => {
          if (resp.status === "OK") {
            enqueueSnackbar(`${t("messages.send_success")}`, {
              variant: "success",
            });
            setContent("");
            setInputHeight("auto");
            setSelectedContacts([]);
            onReportDetailChange();
            setContacts(originalContacts);
          } else {
            return;
          }
        });
      } catch (error) {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    }
  };
  return (
    <div className="rounded chat">
      <div className="chat__title p-20 border-bottom">
        <h5 className="m-0">
          {`${t("messages.history")} ${
            typeMsgs === "public" ? t("messages.reporter") : t("messages.staff")
          }`}
        </h5>
      </div>
      <div className="chat__messages">
        {messages?.length === 0 ? (
          <h5 className="--noMessages">{t("messages.no_messages")}</h5>
        ) : (
          Array.isArray(messages) &&
          messages.map((msg, idx) => (
            <TextBubble msg={msg} key={idx} onReply={handleReply} />
          ))
        )}
      </div>
      {replyTo && (
        <div className="replyTo">
          <div className="replyTo__contacts">
            <strong>{`${t("messages.answer_to")} ${
              !replyTo.is_anonymous
                ? replyTo?.sender_email || t("messages.whistleblower")
                : t("messages.whistleblower")
            }:`}</strong>
            <small> {`${replyTo.content}`}</small>
          </div>
          <div className="replyTo__cancelMention">
            <button onClick={cancelMention} className="--button">
              <BoldCloseCircle className="--cancel" />
            </button>
          </div>
        </div>
      )}
      {selectedContacts?.length >= 1 && (
        <div className="sendTo">
          <div className="sendTo__title">
            <OutlineSms style={{ scale: "0.65" }} />
            <small>{`${t("messages.message_to")}:`}</small>
          </div>
          {selectedContacts.map((selectedContact) => (
            <div key={selectedContact.id} className="sendTo__contacts">
              <small className="sendTo__at">@</small>
              <small className="sendTo__mentionName">
                {selectedContact.name}
              </small>
              <button
                onClick={() => handleRemoveContact(selectedContact)}
                className="--button"
              >
                <BoldCloseCircle
                  className="--cancel"
                  style={{ scale: "0.60" }}
                />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="chat__sendMessage">
        {typeMsgs != "public" && (
          <Dropdown drop="up" className="menuContacts">
            <Dropdown.Toggle as={ContactsToggle}>
              <OutlineUserSquare height={30} width={30} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={EthicContacts}
              className="menuContacts__container"
            >
              {contacts.length >= 1 &&
                contacts.map((contact, i) => (
                  <Dropdown.Item
                    key={i}
                    eventKey={contact.id}
                    onClick={() => handleSelectContacts(contact)}
                    className="menuContacts__option"
                    contact={contact}
                  >
                    <AvatarLetter contact={contact} />
                    <>
                      <h6>{contact.name}</h6>
                      <small>{contact.email}</small>
                    </>
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <textarea
          style={{ height: inputHeight }}
          rows={1}
          placeholder={t("messages.write_your_message")}
          value={content}
          onChange={handleMessage}
        />
        <button
          className={`chat__paperPlane ${
            content.trim() === "" && "--disabled"
          }`}
          onClick={handleSubmit}
          disabled={content.trim() === ""}
        >
          {content.trim() === "" ? <SendIcon2 /> : <SendIcon />}
        </button>
      </div>
    </div>
  );
};
