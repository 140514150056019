import { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Modal, Row, Tab } from "react-bootstrap";
import { RenderInput } from "../PreviewForm/PreviewForm";
import { useSelector } from "react-redux";
import { MCButton } from "../../MainComponents";
import { catalogByPart, listCatalogues, ownCatalogues } from "../../../apis";
import { returnStringOfSelections } from "../../Users/User";
import { SingleRowTextLoading } from "../../Loading/SingleRowText/SingleRowText";
import { useTranslation } from "react-i18next";

// Componente para la paginacion
const ReturnPagination = ({
  catalogues,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const isAnotherPage = catalogues.length % itemsPerPage > 0 ? true : false; // Si sobran catalogos agrega otra pagina.
  const totalPages =
    parseInt(catalogues.length / itemsPerPage) + (isAnotherPage ? 1 : 0); // Total de paginas.

  // Para generar la primera parte de la paginacion (primeros 2 btns)
  const returnFirstStepsOnPagination = () => {
    if (totalPages == 1) {
      return [0];
    }
    if (totalPages > 1) {
      return [0, 1];
    }
    return [];
  };
  // Para generar la primera parte de la paginacion (3 o menos btns de en medio)
  const returnMiddleStepsOnPagination = () => {
    if (totalPages < 4) {
      return [];
    }
    if (totalPages == 5 && currentPage == 3) {
      return [currentPage];
    }
    if (totalPages == 6) {
      if (currentPage == 3) {
        return [currentPage, currentPage + 1];
      }
      if (currentPage == 4) {
        return [currentPage - 1, currentPage];
      }
    }
    if (totalPages >= 7) {
      if (currentPage === 0 || currentPage === 1 || currentPage === 2) {
        return [2, 3, 4];
      }
      if (
        currentPage === totalPages - 1 ||
        currentPage === totalPages - 2 ||
        currentPage === totalPages - 3
      ) {
        return [totalPages - 5, totalPages - 4, totalPages - 3];
      }
      return [currentPage - 1, currentPage, currentPage + 1];
    }
    return [];
  };
  // Para generar la primera parte de la paginacion (ultimos 2 btns)
  const returnLastStepsOnPagination = () => {
    if (totalPages == 3) {
      return [2];
    }
    if (totalPages >= 4) {
      return [totalPages - 2, totalPages - 1];
    }
    return [];
  };

  const disableNext = () => currentPage >= totalPages - 1; // Deshabilita el boton de pagina siguiente
  const disableBefore = () => currentPage <= 0; // Deshabilita el boton de pagina anterior

  return (
    <div className="pagination">
      <div className="pagination__pages">
        <button
          style={{
            fontSize: 20,
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
          }}
          className="me-1"
          disabled={disableBefore()}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          &#8249;
        </button>
        <div className="d-flex">
          {returnFirstStepsOnPagination().map((item, i) => (
            <button
              key={i}
              className={`btnPage ${item == currentPage ? "--current" : ""}`}
              onClick={() => setCurrentPage(item)}
            >
              {item + 1}
            </button>
          ))}
        </div>
        <div className="d-flex">
          {returnMiddleStepsOnPagination().map((item, i) => (
            <button
              key={i}
              className={`btnPage ${item == currentPage ? "--current" : ""}`}
              onClick={() => setCurrentPage(item)}
            >
              {item + 1}
            </button>
          ))}
        </div>
        <div className="d-flex">
          {returnLastStepsOnPagination().map((item, i) => (
            <button
              key={i}
              className={`btnPage ${item == currentPage ? "--current" : ""}`}
              onClick={() => setCurrentPage(item)}
            >
              {item + 1}
            </button>
          ))}
        </div>
        <button
          style={{
            fontSize: 20,
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
          }}
          className="ms-1"
          disabled={disableNext()}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          &#8250;
        </button>{" "}
        {/* > */}
      </div>
    </div>
  );
};

const CataloguesList = ({ selectedCatalogue, setSelectedCatalogue }) => {
  const [egCataloguesState, setEgCataloguesState] = useState([]); // Lista de catalogos de EG
  const [ownCataloguesState, setOwnCataloguesState] = useState([]); // Lista de catalogos Propios
  const [currentList, setCurrentList] = useState("eg"); // Lista de EG seleccionada por defecto
  const [currentPageEg, setCurrentPageEg] = useState(0); // Control de paginacion en la lista de EG
  const [currentPageOwn, setCurrentPageOwn] = useState(0); // Control de paginacion en la lista de OWN
  const [itemsPerPage, setItemsPerPage] = useState(10); // Catalogos que se van a mostrar por pagina en la lista
  const [t] = useTranslation("forms");
  // Obtiene los catalogos al crear el componente
  useEffect(() => {
    Promise.all([listCatalogues(), ownCatalogues()])
      .then(([eg, own]) => {
        setEgCataloguesState(eg.catalogues);
        setOwnCataloguesState(own.catalogues);
      })
      .catch(console.log);
  }, []);

  // Retorna los catalogos que se van a mostrar dependiendo de la pagina que se encuentre
  const returnItemsPage = (catalogues, type) => {
    if (Array.isArray(catalogues)) {
      let newCatalogues = _.cloneDeep(catalogues);
      if (type === "own") {
        newCatalogues = newCatalogues.slice(
          currentPageOwn * itemsPerPage,
          currentPageOwn * itemsPerPage + itemsPerPage
        );
      } else {
        newCatalogues = newCatalogues.slice(
          currentPageEg * itemsPerPage,
          currentPageEg * itemsPerPage + itemsPerPage
        );
      }
      return newCatalogues;
    } else {
      return [];
    }
  };

  return (
    <div className="list-of-catalogues">
      <header className="d-flex justify-content-between">
        <div className="d-flex justify-content-center">
          <div className="eg-own-selector">
            <div className="shadow-selection">
              <button
                className={`${currentList === "eg" ? "active" : ""}`}
                onClick={() => setCurrentList("eg")}
              >
                EthicsGLobal
              </button>
              <button
                className={`${currentList === "own" ? "active" : ""}`}
                onClick={() => setCurrentList("own")}
              >
                {t("modalConstraints.my_catalogs")}
              </button>
            </div>
          </div>
        </div>
        {currentList === "own" ? (
          <ReturnPagination
            catalogues={ownCataloguesState}
            itemsPerPage={itemsPerPage}
            currentPage={currentPageOwn}
            setCurrentPage={setCurrentPageOwn}
          />
        ) : (
          <ReturnPagination
            catalogues={egCataloguesState}
            itemsPerPage={itemsPerPage}
            currentPage={currentPageEg}
            setCurrentPage={setCurrentPageEg}
          />
        )}
      </header>

      <section className="catalogues-list-container rounded dyTheme1 border">
        <Tab.Container
          activeKey={currentList}
          className="constraints-list-catalogue"
        >
          <Tab.Content>
            <Tab.Pane eventKey="eg">
              <ul className="catalogues-list">
                {returnItemsPage(egCataloguesState, "eg").map((item, idx) => (
                  <li
                    key={idx}
                    className={`${
                      selectedCatalogue?.code === item.code ? "selected" : ""
                    }`}
                    onClick={() => setSelectedCatalogue(item)}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </Tab.Pane>
            <Tab.Pane eventKey="own">
              <ul className="catalogues-list">
                {returnItemsPage(ownCataloguesState, "own").map((item, idx) => (
                  <li
                    key={idx}
                    className={`${
                      selectedCatalogue?.alias === item.alias ? "selected" : ""
                    }`}
                    onClick={() => setSelectedCatalogue(item)}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </section>
    </div>
  );
};

// Cada valor que sera restringido del catalogo secundario
const ConstraintValue = ({
  idx,
  value,
  alias,
  isOwn,
  deleteConstraintValue,
}) => {
  const [stringOfValues, setStringOfValues] = useState(""); // Para obtener la cadena de selecciones que se restringio en el catalogo secundario
  const [isLoading, setIsLoading] = useState(true); // Variable de control

  // Cada que cambie el valor vuelve a generar la cadena de selecciones (en caso de que un catalogo se elimine o se cree uno nuevo)
  useEffect(() => {
    setIsLoading(true);
    returnStringOfSelections("", alias, isOwn, value).then((resp) => {
      setIsLoading(false);
      setStringOfValues(resp);
    });
  }, [value]);

  // En lo que carga la cadena de seleccion se muestra un loading
  if (isLoading) {
    return <SingleRowTextLoading />;
  }

  return stringOfValues !== "" ? (
    <li>
      <span
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => deleteConstraintValue(idx)}
      >
        x
      </span>{" "}
      {stringOfValues}
    </li> // Valor seleccionado
  ) : (
    <li>
      <span
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => deleteConstraintValue(idx)}
      >
        x
      </span>{" "}
      Valor no disponible
    </li>
  ); // Solo para evitar cualquier error de request
};

// Cada que se agregue una restriccion al valor de un catalogo principal
const ConstraintPiece = ({
  data,
  idx,
  catalogue,
  isOwn: isOwnParent,
  constraints,
  setConstraints,
  attachments,
  setAttachments,
  parentEntireSchema,
  parentEntirePathSchema,
  parentSetSchemaState,
}) => {
  // Crea otro formulario aparte para manejar los valores de que se van a agregar para restringir los catalogos secundarios
  const [schemaState, setSchemaState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  // Para mostrar el valor del catalog principal con el que se va a restringir el catalogo secundario
  const [stringOfValues, setStringOfValues] = useState("");
  // variables de control
  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(false);
  // El valor del catalogo principal
  const value = data.value;
  // El catalogo secundario que se eligio
  let splitedKey = data.catKey.split("::");
  let isOwn = splitedKey[1] == "OWN" ? true : false;
  let alias = splitedKey[2];

  const [t] = useTranslation("forms");

  // Cuando cambie el catalogo el formulario vuevle a tomar valores nuevos (en caso de que un catalogo se elimine o se cree uno nuevo)
  useEffect(() => {
    catalogByPart({ is_own: isOwn, catalogue: alias, path: "/" }).then(
      (resp) => {
        if (resp.error) {
          console.log(data.catKey);
        }
        let children = [...resp.data];
        setSchemaState([
          [
            {
              key: `C::EG::${alias}`,
              type: "catalog-select",
              label: data.label,
              placeholder: t("modalConstraints.support_text"),
              required: true,
              sensitive: false,
              scope: "/",
              selected: "",
              isOwn: isOwn,
              catalogue: alias,
              locales: {},
              children: children,
            },
          ],
        ]);
        setFormData([
          [
            {
              [alias]: "",
              catalogue: alias,
              isOwn: isOwn,
              sensitive: false,
            },
          ],
        ]);
        setIsValid([
          [
            {
              [alias]: [],
            },
          ],
        ]);
        setIsLoading(false);
        setRerender(true);
      }
    );
  }, [data.catKey]);

  // Cuando cambie el catalogo la cadena de seleccion del catalogo principal se vuelve a generar (en caso de que un catalogo se elimine o se cree uno nuevo)
  useEffect(() => {
    returnStringOfSelections("", catalogue, isOwnParent, value).then((resp) => {
      setStringOfValues(resp);
    });
  }, [data.catKey]);

  // Forzar a rerenderizar
  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  // Para borrar una restriccion del catalogo principal
  const deleteConstraint = () => {
    const pathConstraints = `${parentEntirePathSchema}.otherCatConstraints`; // Hace un path para obtener las restricciones del catalogo principal
    const newSchema = _.cloneDeep(parentEntireSchema); // Se hace una copia del schema actual
    const currentConstraints = _.get(newSchema, pathConstraints); // Se obtienen las restricciones actuales
    currentConstraints.splice(idx, 1); // Se borra la restriccion con el index actual que tiene recibe componente
    parentSetSchemaState(newSchema); // Setea el estado del nuevo schema
  };

  // Para agregar una restriccion de valor del catalogo secundario
  const addConstraintValue = () => {
    const pathConstraintsValues = `${parentEntirePathSchema}.otherCatConstraints.${idx}.constraints`; // hace un path para obtener las restriccciones de valor actuales
    const value = _.get(formData, `0.0.${alias}`); // obtiene el valor del catalogo secundario
    if (value.trim() == "") return; // Si el valor es igual a "" termina la funcion
    const newSchema = _.cloneDeep(parentEntireSchema); // hace una copia del schema del formulario
    const currentConstraints = _.get(newSchema, pathConstraintsValues); // Obtiene las restricciones de valor acuales del catalogo secundario
    if (currentConstraints.includes(value)) return; // Si la restriccion de valor ya existe, entonces temina la funcion
    currentConstraints.push(value); // Agrega la nueva restriccion de valor a las ya existentes
    parentSetSchemaState(newSchema); // Setea el estado
  };

  // Para borrar una restriccion de valor del catalogo secundario
  const deleteConstraintValue = (idxVal) => {
    const pathConstraintsValues = `${parentEntirePathSchema}.otherCatConstraints.${idx}.constraints`; // Hace un path para obtener las restricciones de valor del catalogo secundario
    const newSchema = _.cloneDeep(parentEntireSchema); // Se hace una copia del schema actual
    const currentConstraints = _.get(newSchema, pathConstraintsValues); // Se obtienen las restricciones de valor actuales
    currentConstraints.splice(idxVal, 1); // Se borra la restriccion con el index que recibe la funcion
    parentSetSchemaState(newSchema); // Setea el estado con el nuevo schema
  };

  return (
    <div className="form-preview p-0">
      <Row className="p-2">
        <div className="d-flex justify-content-between">
          <span>{t("modalConstraints.dependent_value")}</span>
          <span
            onClick={deleteConstraint}
            style={{ color: "red", cursor: "pointer" }}
          >
            x
          </span>
        </div>
        {stringOfValues !== "" ? (
          <p className="mb-1">{stringOfValues}</p>
        ) : (
          <SingleRowTextLoading />
        )}
      </Row>

      {!rerender && !isLoading && (
        <Row className="form-grid p-0">
          <RenderInput
            key={`form-input--constraints${idx}`}
            entireSchema={schemaState}
            entireFormData={formData}
            entireIsValid={isValid}
            entirePathSchema={`${0}.${0}`}
            entirePathData={`${0}.${0}`}
            setSchemaState={setSchemaState}
            setFormData={setFormData}
            setIsValid={setIsValid}
            constraints={constraints}
            setConstraints={setConstraints}
            attachments={attachments}
            setAttachments={setAttachments}
            tryToNext={false}
            activeStep={0}
            idx={0}
          />
          <div className="p-2">
            <MCButton
              label={t("modalConstraints.restrict_selection")}
              variant="primary"
              outline
              className="w-100"
              onClick={addConstraintValue}
            />
          </div>
        </Row>
      )}
      <Row className="p-2">
        <small className="p-0">Restricciones actuales</small>
        <ul className="p-0" style={{ listStyle: "none" }}>
          {Array.isArray(data?.constraints) &&
            data.constraints.map((item, idx) => (
              <ConstraintValue
                key={idx}
                idx={idx}
                value={item}
                alias={alias}
                isOwn={isOwn}
                deleteConstraintValue={deleteConstraintValue}
              />
            ))}
        </ul>
      </Row>
    </div>
  );
};

// Modal para agregar restricciones
export const ModalConstraints = ({
  entireSchema,
  entirePathSchema,
  setSchemaState,
  entireFormData,
  entirePathData,
  setFormData,
  entireIsValid,
  setIsValid,
  constraints,
  setConstraints,
  activeStep,
  inputIndex,
  show,
  onHide,
}) => {
  // El "catalogo principal" es el que va a dictar con el valor que opciones se van a restringir del "catalogo secundario"

  const { gTheme } = useSelector((state) => state.theme);

  const newSchema = _.cloneDeep(entireSchema, entirePathSchema);
  const schema = _.get(newSchema, entirePathSchema); // Schema del catalogo principal
  const currentConstraints = Array.isArray(schema.otherCatConstraints)
    ? schema.otherCatConstraints
    : []; // restricciones que tiene actualmente el catalogo principal
  _.set(schema, `type`, `catalog-select`); // Cambiar el tipo de catalogo a select para que se muestre de manera mas amigable en el modal
  const key = schema.type.includes("catalog") ? schema.catalogue : schema.key; // asegurar que sea un catalogo con el que se esta trabajando

  const [attachments, setAttachments] = useState(null); // Este estado es necesario para que funcione el input (pero no afecta a este modulo)
  const [rerender, setRerender] = useState(false); // variable para forzar al rerenderizado

  const entirePathDataWithKey = `${entirePathData}.${key}`; // path entero de donde se encuentra el catalogo principal en el formulario
  const value = _.get(entireFormData, entirePathDataWithKey); // Valor que tiene el catalogo principal en el
  const [selectedCatalogue, setSelectedCatalogue] = useState(null); // Catalogo secundario seleccionado

  const [t] = useTranslation("forms");
  // Para forzar a que se renderize el catalogo principal
  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  // Funcion para agregar un valor de restriccion en el catalogo principal
  const addConstraint = () => {
    if (value == "" || selectedCatalogue == null) return; // Checar que no vaya un valor en vacio ni que no se halla seleccionado un catalogo
    const catKey = `C::${"code" in selectedCatalogue ? "EG" : "OWN"}::${
      selectedCatalogue?.code ?? selectedCatalogue?.alias
    }`; // Crear la variable correspondiente a la key del catalogo que se va a restringir
    let currentOtherCatConstraints = _.get(
      entireSchema,
      `${entirePathSchema}.otherCatConstraints`
    ); // Obtener las restricciones actuales de este campo
    currentOtherCatConstraints = Array.isArray(currentOtherCatConstraints)
      ? currentOtherCatConstraints
      : []; // Ver si ya existen restricciones o hay que crear un array
    if (constraintExist(currentOtherCatConstraints, catKey, value)) return; // Ver si ya existe una restriccion para este caso en especifico
    const newConstraint = {
      catKey,
      value,
      constraints: [],
      label: selectedCatalogue?.label || t("modalConstraints.no_name"),
    }; // Crear la restriccion
    currentOtherCatConstraints.push(newConstraint); // Insertar la nueva restriccion
    const newSchema = _.cloneDeep(entireSchema); // Copiar el schema actual
    _.set(
      newSchema,
      `${entirePathSchema}.otherCatConstraints`,
      currentOtherCatConstraints
    ); // Insertar las nuevas restricciones con el path
    setSchemaState(newSchema); // setear el estado
  };

  // Funcion para verificar si una restriccion con el valor del catalogo principal y el catalogo secundario ya existe y que no se repitan.
  // Esta funcion solo se utiliza en addConstraint().
  const constraintExist = (currentOtherCatConstraints, catKey, value) => {
    for (const element of currentOtherCatConstraints) {
      if (catKey == element.catKey && value == element.value) {
        // Si el currentOtherCatConstraints incluye el catalogo secundario y el valor del catalogo principal este retorna que si existe (true)
        return true;
      }
    }
    return false; // En caso de que no halla coincidencias la funcion retorna que no existe (false)
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
      dialogClassName="constraints-modal"
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      />
      <Modal.Body>
        <Row>
          <Col lg={12} xl={4}>
            <small className="ps-2">
              {t("modalConstraints.select_catalog_value_restrictions")}
            </small>
            {!rerender && (
              <div className="form-preview p-0">
                <Row className="form-grid p-0 ">
                  <RenderInput
                    key={`form-input-${inputIndex}`}
                    entireSchema={entireSchema}
                    entireFormData={entireFormData}
                    entireIsValid={entireIsValid}
                    entirePathSchema={entirePathSchema}
                    entirePathData={entirePathData}
                    setSchemaState={setSchemaState}
                    setFormData={setFormData}
                    setIsValid={setIsValid}
                    constraints={constraints}
                    setConstraints={setConstraints}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    tryToNext={false}
                    activeStep={activeStep}
                    idx={0}
                  />
                </Row>
              </div>
            )}
            <div className="mt-2">
              <small className="ps-2">
                {t("modalConstraints.select_catalog_restrict")}:
              </small>

              <CataloguesList
                selectedCatalogue={selectedCatalogue}
                setSelectedCatalogue={setSelectedCatalogue}
              />
            </div>
            <MCButton
              label={t("modalConstraints.add_restriction_value")}
              variant="primary"
              outline
              onClick={addConstraint}
              className="w-100 mt-2 mb-2 mb-xl-0"
            />
          </Col>

          <Col lg={12} xl={8}>
            <div className="grid-constraints">
              {currentConstraints.map((item, idx) => (
                <ConstraintPiece
                  key={idx}
                  idx={idx}
                  data={item}
                  catalogue={schema.catalogue}
                  isOwn={schema.isOwn}
                  constraints={constraints}
                  setConstraints={setConstraints}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  parentEntireSchema={entireSchema}
                  parentEntirePathSchema={entirePathSchema}
                  parentSetSchemaState={setSchemaState}
                />
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <div className="d-flex justify-content-end">
            <MCButton
              label={t("modalConstraints.accept")}
              variant="primary"
              onClick={onHide}
            />
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
