import React from "react";
import { useTranslation } from "react-i18next";
import { parseDateToISOString2 } from "../../../../helpers/dateTransform";
import "./Information.scss";
import { OutlineDocumentText } from "../../../Icon";
import { MCTooltip } from "../../../MainComponents";

function getFileName(url) {
  // Utilizamos el constructor URL de JavaScript para manejar y parsear la URL
  const urlObject = new URL(url);
  
  // Obtenemos la parte del pathname de la URL
  const pathname = urlObject.pathname;
  
  // Extraemos el nombre del archivo, que es el último segmento después de la última '/'
  const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
  
  return fileName;
}

export const Complements = ({ complements }) => {
  const [t] = useTranslation("information");

  return (
    <div className="complements">
      <div className="complements__content rounded">
        {complements.map((complement, i) => (
          <div key={i} className="mx-3 complements__container border-bottom">
            <div className="complements__text">
              <label>{t("complements.commentary")}: </label>
              <div className="rounded dyBorder1 dyTheme4 complements__commentary">
                <small>{complement.commentary}</small>
                <p>{parseDateToISOString2(complement.created_at)}</p>
              </div>
            </div>

            <div className="complements__files">
              <label>{t("complements.attached_file")}: </label>
              <div className="--file dyTheme4 dyBorder1 rounded p-2">
                {complement.attachments.length > 0 ? (
                  complement.attachments.map((attachment, i) => (
                    <MCTooltip
                      key={i}
                      text={`${
                        getFileName(attachment.file) || t("complements.unnamed_file")
                      }`}
                      position="top"
                    >
                      <button onClick={() => window.open(attachment.file)}>
                        <OutlineDocumentText />
                        {attachment.file_type}
                      </button>
                    </MCTooltip>
                  ))
                ) : (
                  <p>{t("complements.no_files_available")}...</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
