import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Icon from "../../../Icon/Icon";
import { alpha, useTheme } from "@mui/material/styles";
import { Tooltip, Zoom } from "@mui/material";
import "./IconButton.scss";

/**
 * Customizable button, it is required to add the name of the Icon to be rendered for its correct operation.
 ** --> Icon <--
 * @param (iconName) String of icon name eg: "trash_can", "close_icon", "check_icon", etc.
 * @param (iconColor) Custom color of the selected icon
 * @param (iconSize) Setting the selected icon size
 ** --> Button <---
 * @param (size) Button size ["small", "medium", "large"]
 * @param (disable) Button is disabled?
 * @param (outline) boolean true = "transparent background" : "solid background"
 * @param (color) background|border color
 * @param (ariaLabel) provides an accessible description of the button's purpose for screen reader users
 * @param (sx) Additional MUI online styles
 ** --> Tootltip <--
 * @param (placement) Tooltip position  ['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start','top']
 * @param (title) Text displayed in tooltip
 ** --> Event <--
 * @param (onClick) Assigned event execution
 */

export const EGIconButton = ({
  iconName = "",
  iconColor = "",
  iconSize = 23,
  size = "small",
  disabled = false,
  color,
  colorHover = "grey[300]",
  outline = false,
  ariaLabel,
  sx = {},
  placement = "top",
  title,
  onClick,
  id,
  ...rest
}) => {
  const theme = useTheme();

  // Status for icon color
  const [currentIconColor, setCurrentIconColor] = React.useState(
    theme.palette[iconColor]?.main || theme.palette.grey[500]
  );

  // Determine background and hover color
  const backgroundColor = outline
    ? "transparent"
    : theme.palette[color]?.main || "transparent";

  const hoverBackgroundColor = outline
    ? theme.palette[color]?.main || theme.palette.grey[300]
    : theme.palette[color]?.dark ||
      theme.palette[colorHover]?.main ||
      theme.palette[colorHover];

  // Determine icon color for hover state
  const iconHoverColor = outline
    ? theme.palette.common.white
    : theme.palette[iconColor]?.main || theme.palette.grey[500];

  React.useEffect(() => {
    // Update icon color based on props
    setCurrentIconColor(
      theme.palette[iconColor]?.main ||
        theme.palette[color]?.main ||
        theme.palette.grey[500]
    );
  }, [iconColor, color, theme]);

  // Hover effect for icon
  const handleMouseEnter = () => {
    if (outline) {
      setCurrentIconColor(iconHoverColor);
    }
  };

  const handleMouseLeave = () => {
    if (outline) {
      setCurrentIconColor(
        theme.palette[iconColor]?.main ||
          theme.palette[color]?.main ||
          theme.palette.grey[500]
      );
    }
  };

  const disabledStyles = disabled
    ? {
        opacity: 0.5,
      }
    : {};

  // Customized styles for MUI
  const variantStyles = outline
    ? {
        border: `1px solid ${theme.palette[color]?.main || "transparent"}`,
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor:
            theme.palette[color]?.main || theme.palette.grey[300],
        },
        ...disabledStyles,
      }
    : {
        backgroundColor: `${backgroundColor} !important`,
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
        },
        ...disabledStyles,
      };

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 150 }}
    >
      <IconButton
        onClick={onClick}
        size={size}
        disabled={disabled}
        aria-label={ariaLabel || title}
        sx={{ ...variantStyles, ...sx }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id={id}
        {...rest}
      >
        <Icon
          name={iconName}
          size={iconSize}
          color={alpha(currentIconColor, disabled ? 0.5 : 1)}
        />
      </IconButton>
    </Tooltip>
  );
};
