import { CDBBtn, CDBInput } from "cdbreact";
import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../../../../Icon/Icon";
import { patchInvestigation } from "../../../../../apis";
import { validInitDates } from "../../../../../helpers/dateTransform";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";

export const EditDates = ({ investigationAlias, formValues, toggleModal }) => {
  const [t] = useTranslation("investigation");
  const [will_close, setWill_close] = useState(
    validInitDates(formValues?.will_close)
      ? new Date(formValues?.will_close)
      : new Date()
  );
  const [will_finish_resolution, setWill_finish_resolution] = useState(
    validInitDates(formValues?.will_finish_resolution)
      ? new Date(formValues?.will_finish_resolution)
      : new Date()
  );
  const [will_finish_investigation, setWill_finish_investigation] = useState(
    validInitDates(formValues?.will_finish_investigation)
      ? new Date(formValues?.will_finish_investigation)
      : new Date()
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const objToSave = {
        folder: {
          dates: {
            will_close,
            will_finish_resolution,
            will_finish_investigation,
          },
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  return (
    <div>
      <Row>
        <h5>{t("General.promise_date")}</h5>
      </Row>
      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">
            {t("General.resolution_init_date")}:
          </small>
          <DatePicker
            selected={will_close}
            onChange={(date) => setWill_close(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, hh:mm a"
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">
            {t("General.resolution_end_date")}:
          </small>
          <DatePicker
            selected={will_finish_resolution}
            onChange={(date) => setWill_finish_resolution(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, hh:mm a"
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.final_date")}:</small>
          <DatePicker
            selected={will_finish_investigation}
            onChange={(date) => setWill_finish_investigation(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, hh:mm a"
          />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("General.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("General.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
