import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  createBusinessUnit,
  detailBusinessUnit,
  updatedBusinessUnit,
  utilitiesList,
} from "../../../apis/apiClientConfig";
import Plantillas from "../../../assets/images/plantillas.png";
import { MCSelectSome } from "../../../components/MainComponents/SelectSome/SelectSome";
import { MCSelect } from "../../../components/MainComponents/Select/Select";
import { MCButton } from "../../../components/MainComponents/Button/Button";
import { GeneralIconHeader } from "../../../components/Title/GeneralHeader";
import { MCInput, MCLabel } from "../../../components/MainComponents";
import { Loading } from "../../../components/Loading/String/Loading";
import { useSnackbar } from "notistack";
import { Breadcrum } from "../../../components/MainComponents/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

const setPhonesFromApi = (phones) => {
  if (!Array.isArray(phones)) return [];
  const newPhones = [];
  phones.forEach((item1) => {
    item1.phones.forEach((item2) => {
      newPhones.push({ label: `${item2}-${item1.value}`, value: item2 });
    });
  });
  return newPhones;
};

export const BusinessUnit = () => {
  // Custom hooks
  const { enqueueSnackbar } = useSnackbar();
  const { alias } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation("settings");
  // Initial state
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [end_point, setEnd_point] = useState(""); // aqui se guarda la informacion escrita del Dominio del cliente
  const [industry, setIndustry] = useState(null); // aqui se guarda la informacion seleccionada del Select industry
  const [errorMessage, setErrorMessage] = useState({});

  const [listTypeIndustryUtilities, setListTypeIndustryUtilities] = useState(
    []
  );
  const [listCountriesUtilities, setListCountriesUtilities] = useState([]);
  const [listLanguagesUtilities, setListLanguagesUtilities] = useState([]);
  const [listPhonesUtilities, setListPhonesUtilities] = useState([]);

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [phones, setPhones] = useState([]);
  const [phonesToSelect, setPhonesToSelect] = useState([]);
  const [number_of_employees, setNumber_of_employees] = useState(0);
  //list that is used to set up company
  useEffect(() => {
    utilitiesList().then((resp) => {
      setListTypeIndustryUtilities(resp.data.industry_classification);
      setListCountriesUtilities(resp.data.list_countries);
      setListLanguagesUtilities(resp.data.list_languages);
      setListPhonesUtilities(resp.data.list_phones);
      if (!alias) {
        setIsLoading(false);
      }
    });
  }, []);
  //Details Business Unit
  useEffect(() => {
    if (alias) {
      detailBusinessUnit(alias).then((resp) => {
        const {
          name,
          phones,
          industry,
          template,
          countries,
          employees,
          end_point,
          is_active,
          languages,
        } = resp.data;
        setName(name);
        setIndustry(industry.path);
        setNumber_of_employees(employees.number_of_employees);
        setEnd_point(end_point);
        setCountries(countries);
        setLanguages(languages);
        setPhones(setPhonesFromApi(phones));
        setIsLoading(false);
      });
    }
  }, []);
  //Get number phone pre-registered
  useEffect(() => {
    if (!isLoading) {
      const phones = [];
      countries.map((item1) => {
        listPhonesUtilities.map((item2) => {
          if (item1.value === item2.value) {
            item2.phones.map((item3) => {
              phones.push({ label: `${item3}-${item2.value}`, value: item3 });
            });
          }
        });
      });
      setPhonesToSelect(phones);
    }
  }, [countries]);

  const breadcrumbItems = [
    { label: t("business_units.home"), route: "/" },
    { label: t("business_units.settings"), route: "/settings" },
    {
      label: t("business_units.business_units"),
      route: "/settings/business/list",
    },
    { label: t("business_units.configuration") },
  ];

  // Create Business Unit
  const handleCreateBusinessUnit = async () => {
    const newCountries = countries.map((item) => item.value);
    const newLanguages = languages.map((item) => item.value);
    const newPhones = [];
    phones.map((item1) => {
      listPhonesUtilities.map((item2) => {
        if (item2.phones.includes(item1.value)) {
          const existInNewPhones = newPhones.findIndex(
            (item3) => item3.key === item2.value
          );
          if (existInNewPhones >= 0) {
            newPhones[existInNewPhones].number_phones.push(item1.value);
          } else {
            newPhones.push({
              key: item2.value,
              number_phones: [item1.value],
            });
          }
        }
      });
    });

    const objToSave = {
      countries: newCountries,
      languages: newLanguages,
      phones: newPhones,
      number_of_employees,
      end_point,
      name,
      industry,
    };

    try {
      const resp = await createBusinessUnit(objToSave).then((resp) =>
        enqueueSnackbar(
          `${t("business_units.business_unit")} '${name}' ${t(
            "business_units.created_successfully"
          )}`,
          { variant: "success" }
        )
      );
    } catch (errors) {
      enqueueSnackbar(t("business_units.error_while_creating_business_unit"), {
        variant: "error",
      });
    }
  };
  // Edit & Update Business Unit
  const handleUpdate = async () => {
    const newCountries = countries.map((item) => item.value);
    const newLanguages = languages.map((item) => item.value);
    const newPhones = [];
    phones.map((item1) => {
      listPhonesUtilities.map((item2) => {
        if (item2.phones.includes(item1.value)) {
          const existInNewPhones = newPhones.findIndex(
            (item3) => item3.key === item2.value
          );
          if (existInNewPhones >= 0) {
            newPhones[existInNewPhones].number_phones.push(item1.value);
          } else {
            newPhones.push({
              key: item2.value,
              number_phones: [item1.value],
            });
          }
        }
      });
    });

    const objToSave = {
      alias,
      countries: newCountries,
      languages: newLanguages,
      phones: newPhones,
      number_of_employees,
      name,
    };
    try {
      const resp = await updatedBusinessUnit(objToSave).then((resp) => {
        if (resp) {
          enqueueSnackbar(t("business_units.update_successfully"), {
            variant: "success",
          });
        }
      });
    } catch (error) {
      enqueueSnackbar(t("business_units.error_while_update_business_unit"), {
        variant: "error",
      });
      // console.log(error);
    }
  };

  return (
    <div id="settings" className="page">
      <GeneralIconHeader
        title={`${
          alias ? t("business_units.configure") : t("business_units.new")
        } ${t("business_units.business_unit")}`}
        text={`${
          alias
            ? t("business_units.setup_business_unit")
            : t("business_units.setup_new_business_unit")
        }`}
        icon="settings2"
      />
      <Breadcrum items={breadcrumbItems} />
      {isLoading ? (
        <Row>
          <Col lg={12} className="mt-1">
            <Loading />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg={7} className="mt-1 p-0">
            <div className="pr-lg-10">
              <div className="p-30 dyTheme1 dyBorder1 rounded">
                <h5 className="mb-0">
                  {t("business_units.business_unit_configuration")}
                </h5>
                <Row>
                  <Col lg="6" className="mt-3">
                    <MCLabel
                      text={`${t("business_units.business_unit_name")}: *`}
                    />
                    <MCInput
                      type="text"
                      placeholder={t("business_units.enter_business_unit_name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>

                  <Col lg="6" className="mt-3">
                    <MCLabel text={`${t("business_units.business_line")}: *`} />
                    <div disabled>
                      <MCSelect
                        isDisabled={alias}
                        options={listTypeIndustryUtilities}
                        onChange={(e) => setIndustry(e.value)}
                        value={listTypeIndustryUtilities.find(
                          (item) => item.value === industry
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" className="mt-3">
                    <MCLabel text={`${t("business_units.no_employees")}: *`} />
                    <MCInput
                      type="number"
                      placeholder={t("business_units.enter_no_employees")}
                      name="number_of_employees"
                      value={number_of_employees}
                      onChange={(e) => setNumber_of_employees(e.target.value)}
                      // isValid={false}
                    />
                    {errorMessage.number_of_employees && (
                      <div className="errorMessage">
                        {errorMessage.number_of_employees}
                      </div>
                    )}
                  </Col>

                  <Col lg="6" className="mt-3">
                    <MCLabel text={`${t("business_units.domain")}: *`} />
                    <MCInput
                      type="text"
                      placeholder={t("business_units.domain_example")}
                      value={end_point}
                      onChange={(e) => setEnd_point(e.target.value)}
                      disabled={alias}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <div className="mt-3">
                      <MCSelectSome
                        label={`${t("general.add_countries")}: *`}
                        options={listCountriesUtilities}
                        selecteds={countries}
                        onChange={setCountries}
                      />
                    </div>
                  </Col>

                  <Col sm="12">
                    <div className="mt-3">
                      <MCSelectSome
                        label={`${t("general.support_lenguages")}: *`}
                        options={listLanguagesUtilities}
                        selecteds={languages}
                        onChange={setLanguages}
                      />
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="mt-3">
                      <MCSelectSome
                        label={`${t("general.add_phones")}: *`}
                        options={phonesToSelect}
                        selecteds={phones}
                        onChange={setPhones}
                      />
                    </div>
                  </Col>
                </Row>

                <MCButton
                  variant="primary"
                  outline
                  label={
                    alias
                      ? `${t("business_units.edit")} ${t(
                          "business_units.business_unit"
                        )}`
                      : `${t("business_units.create_business_unit")}`
                  }
                  className="mt-20 w-100"
                  onClick={alias ? handleUpdate : handleCreateBusinessUnit}
                />
              </div>
            </div>
          </Col>
          <Col lg={5} className="mt-1 p-0">
            <div className="pl-lg-30">
              <div className="py-30 px-20 dyTheme1 dyBorder1 rounded">
                <div className="pb-3 border-bottom">
                  <h6>{t("business_units.setup_website")}</h6>
                  <small>
                    {t("business_units.costumatize_website_description")}
                  </small>
                </div>
                <h6 className="mt-30">Template HERO</h6>
                {/* <small>Guardado por última vez el: 23 de Agosto de 2021</small> */}
                <div className="template-hero mt-20">
                  <h3 className="mb-0">
                    <Link className="text-white" to="/settings/landing">
                      TEMPLATE HERO
                    </Link>
                  </h3>
                </div>
                <div className="mt-30 text-center">
                  <MCButton
                    label={t("business_units.costumatize_website")}
                    variant="primary"
                    outline
                    className="w-100"
                    onClick={() => navigate(`/settings/landing/${alias}`)}
                  />

                  <Link to={"#"}>
                    <small className="mt-20">
                      {`${t("business_units.custom_configuration")}`}
                    </small>
                    <small> {`${t("business_units.contact_advisor")}`}</small>
                  </Link>
                </div>
              </div>
              <div className="text-center mt-20 dyTheme1 dyBorder1 rounded">
                <img src={Plantillas} className="w-100" alt="Plantillas" />
                <div className="p-20">
                  <small className="font-14">
                    {t("general.download_material_campaign")}
                  </small>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
