// useFormLogin.js


import _ from "lodash";

import { useState } from "react";
import { startLoggedIn, startLoggedInWithMFA, startSettingDomain } from "../store/auth/authThunks";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { enqueueSnackbar } from "notistack";

const showErrorMessage = (error) => {
  enqueueSnackbar(error.message || error, { variant: "error" });
};

const initialForm = {
  email: "",
  password: "",
  otp_code: new Array(6).fill("")
};

//-----To validate Login form-----//
export const useFormLogin = ({ setDomainList, setSelectedDomain }) => {

  let regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [t] = useTranslation("login");
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [wasBlurred, setWasBlurred] = useState([]);

  const [loading, setLoading] = useState(false);
  const [sectionShowed, setSectionShowed] = useState("form");
  const [mfa, setMfa] = useState(false);

  //-----Show/hide password-----//
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => e.preventDefault();

  //-----Validation form & throw errors-----//
  const validate = (target = "form", e) => {
    const newErrors = _.cloneDeep(errors);
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (target === "change") {
      if (name === "email" && wasBlurred.includes("email") ) {
        if (value.trim() === "") {
          newErrors.email = `*${t("Signin.field_required")}`;
        } else if (!regexEmail.test(value.trim())) {
          newErrors.email = `*${t("Signin.enter_valid_email")}`;
        } else {
          _.unset(newErrors, `email`);
        }
      }
      if (name === "password" && wasBlurred.includes("password") ) {
        if (value.trim() === "") {
          newErrors.password = `*${t("Signin.password_required")}`;
        } else {
          _.unset(newErrors, `password`);
        }
      }
    }

    if (target === "blur") {
      if (name === "email") {
        if (value.trim() === "") {
          newErrors.email = `*${t("Signin.field_required")}`;
        } else if (!regexEmail.test(value.trim())) {
          newErrors.email = `*${t("Signin.enter_valid_email")}`;
        } else {
          _.unset(newErrors, `email`)
        }
      }
      if (name === "password") {
        if (value.trim() === "") {
          newErrors.password = `*${t("Signin.password_required")}`;
        } else {
          _.unset(newErrors, `password`);
        }
      }
    }

    if (target === "form") {
      if (form.email.trim() === "") {
        newErrors.email = `*${t("Signin.field_required")}`;
      } else if (!regexEmail.test(form.email.trim())) {
        newErrors.email = `*${t("Signin.enter_valid_email")}`;
      } else {
        _.unset(newErrors, `email`);
      }
      if (form.password.trim() === "") {
        newErrors.password = `*${t("Signin.password_required")}`;
      } else {
        _.unset(newErrors, `password`);
      }
    }

    return newErrors;
  };

  //-----Change values-----//
  const handleChange = (e) => {
    const { name, value } = e.target;
    //setForm({ ...form, [name]: value });
    
    const newErrors = validate("change", e);
    setErrors(newErrors);
    setForm({ ...form, [name]: value });
    
  };

  //----On Focus Input----//
  const handleBlur = (e) => {
    const { name } = e.target;
    setWasBlurred((pWasBlurred)=> pWasBlurred.includes(name) ? pWasBlurred : [...pWasBlurred, name] );
    const newErrors = validate("blur", e);
    setErrors(newErrors);
  };

  //-----Validate & Send form-----//
  const handleSubmit = async (e) => {
    e?.preventDefault();
    //setErrors(validateForm(form));

    if (
      Object.keys(errors).length === 0 &&
      form.email.trim() !== "" &&
      form.password.trim() !== ""
    ) {
      // console.log(form);
      //-----send information-----//
      setLoading(true);
      dispatch(startLoggedIn(form, setMfa, setSectionShowed, setDomainList, setSelectedDomain));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      return;
    }
  };

  const mfaSubmit = async (e) => {
    e.preventDefault();

    // showErrorMessage( t("Signin.otp_required") );
    const joinedOtp = form.otp_code.join("")
    // console.log(joinedOtp);
  
    setLoading(true);

    dispatch( startLoggedInWithMFA(mfa, joinedOtp, form.email, setSectionShowed, setDomainList, setSelectedDomain) );

    setTimeout(() => {
      setLoading(false);
    }, 1000);

  };



  //-----Variable of initial state form----///
  return {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    showPassword, 
    handleClickShowPassword,
    handleMouseDownPassword,
    mfa,
    mfaSubmit,
    sectionShowed,
  };
};
