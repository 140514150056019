import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Icon from "../../../Icon/Icon";
import "./EGAccordion.scss";

// Accordion container
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "1rem",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));
// Accordion Header
const AccordionSummary = styled(
  ({ expanded, titleBackgrundColor, titleColor, ...props }) => (
    <MuiAccordionSummary
      expandIcon={
        <Icon name="arrow_down_2" color={titleColor || "#546E7A"} size={20} />
      }
      {...props}
    />
  )
)(({ theme, expanded, titleBackgrundColor, titleColor }) => ({
  backgroundColor: titleBackgrundColor || theme.palette.blueGray50.main,
  color: titleColor || theme.palette.secondary.main,
  borderRadius: expanded ? "6px 6px 0 0" : "6px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transition: "transform 0.5s",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
// Accordion Contents
const AccordionDetails = styled(({ contentBorderColor, ...props }) => (
  <MuiAccordionDetails {...props} />
))(({ theme, contentBorderColor }) => ({
  padding: theme.spacing(1),
  borderRadius: "0 0 6px 6px",
  borderRight: `2px solid ${contentBorderColor || "transparent"}`,
  borderBottom: `2px solid ${contentBorderColor || "transparent"}`,
  borderLeft: `2px solid ${contentBorderColor || "transparent"}`,
}));

/**
 * Custom MaterialUI Accordion
 * @param {*} panels  Array of objects in which each element contains the configurations to be rendered in each accordion.
 * @param {*} defaultExpanded Refers to the Id of the panel that will be open by default.
 * @param {*} onPanelChange Optional callback function that is executed whenever the Accordion expansion status changes.
 * Common uses of onPanelChange:
 ** External notification: Letting a parent component know when a panel has changed its state.
 ** State tracking: Use the event to record or manage state changes in a log, statistics, or to modify other user interface elements.
 ** Custom logic: Execute any additional action or logic each time a panel expands or collapses.
 #### Panels - Color customization is optional
 * @example 
  const panels = [
    {
      id: "string" || number,
      title: "String",
      titleBackgrundColor: "theme.palette || #color",
      titleColor: "theme.palette || #color",
      contentBorderColor: "theme.palette || #color",
      content: <JSXComponent> || "String"
    }
  ];
 */

const EGAccordion = ({ panels, defaultExpanded = null, onPanelChange }) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  // const theme = useTheme();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (onPanelChange) {
      onPanelChange(panel, newExpanded);
    }
  };

  return (
    <div>
      {panels.map((panel, index) => (
        <Accordion
          key={index}
          expanded={expanded === panel.id}
          onChange={handleChange(panel.id)}
        >
          <AccordionSummary
            aria-controls={`${panel.id}-content`}
            expanded={expanded === panel.id}
            id={`${panel.id}-header`}
            titleBackgrundColor={panel.titleBackgrundColor}
            titleColor={panel.titleColor}
          >
            <Typography variant="h5">{panel.title}</Typography>
          </AccordionSummary>
          <AccordionDetails contentBorderColor={panel.contentBorderColor}>
            <Typography component={"div"}>{panel.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default EGAccordion;
