/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./router/AppRouter";
import { setThemeLS } from "./store/theme/themeSlice";
import {
  startLoggedInWithTokenLS,
  startLoggedOut,
} from "./store/auth/authThunks";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./theme/styles.scss";
import { startSetSize, startSetThemeLS } from "./store/theme/themeThunks";
import { startGettingLanguageLS } from "./store/lang/langThunks";
import { useTranslation } from "react-i18next";
import { setOnline } from "./store/online/onlineSlice";
import { closeSnackbar, useSnackbar } from "notistack";
import statuscode_es from "./translations/ES/statuscode.json";
import statuscode_en from "./translations/EN/statuscode.json";

export const App = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t, i18n] = useTranslation();
  const lang = localStorage.getItem("lang") || "es";
  const language = lang === "es" ? statuscode_es : statuscode_en;

  useEffect(() => {
    const lostConection = () => {
      enqueueSnackbar(`${language.no_internet_conection}`, {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
        preventDuplicate: true,
        persist: true,
      });
    };

    const onlineStatusHandler = () => {
      dispatch(setOnline(navigator.onLine));

      if (!navigator.onLine) {
        lostConection();
      } else {
        closeSnackbar(lostConection());
        enqueueSnackbar(`${language.internet_connection_reestablished}`, {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }
    };

    window.addEventListener("online", onlineStatusHandler);
    window.addEventListener("offline", onlineStatusHandler);
    return () => {
      window.removeEventListener("online", onlineStatusHandler);
      window.removeEventListener("offline", onlineStatusHandler);
    };
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    dispatch(startGettingLanguageLS(i18n));
  }, [dispatch, i18n]);

  useEffect(() => {
    dispatch(startSetThemeLS());
  }, [dispatch]);

  useEffect(() => {
    dispatch(startLoggedInWithTokenLS());
  }, [dispatch]);

  useEffect(() => {
    dispatch(startSetSize(window.innerWidth));
    const setSizeEvLis = () => {
      dispatch(startSetSize(window.innerWidth));
    };
    window.addEventListener("resize", setSizeEvLis);
    return () => {
      window.removeEventListener("resize", setSizeEvLis);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </>
  );
};
