import { useEffect, useState } from "react"

export const useTemporizer = ({ minutes = 0, seconds = 0, millisecons, onComplete }) => {
  
  const initialTimer = millisecons ? millisecons : (minutes * 60 * 1000) + (seconds * 1000);
  const [timer, setTimer] = useState(initialTimer);
  const [isRunning, setIsRunning] = useState(false);

  // Función para iniciar el temporizador
  const startTimer = () => {
    setIsRunning(true);
  };

  // Función para detener el temporizador
  const stopTimer = () => {
    setIsRunning(false);
  };

  // Función para reiniciar el temporizador
  const resetTimer = () => {
    setTimer(initialTimer);
    setIsRunning(false);
  };

  // Lógica para actualizar el temporizador cada segundo
  useEffect(() => {
    let timerId;
    if (isRunning) {
      timerId = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 0) {
            clearInterval(timerId);
            setIsRunning(false);
            if (onComplete && typeof onComplete === 'function') {
              onComplete();
            }
            return 0;
          }
          return prevTimer - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [isRunning]);

  // Convertir el tiempo restante en horas, minutos y segundos
  const hoursParsed = Math.floor((timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesParsed = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
  const secondsParsed = Math.floor((timer % (1000 * 60)) / 1000);

  // Devolver el tiempo restante en milisegundos y formato de cadena
  const timeString = `${hoursParsed < 10 ? '0' : ''}${hoursParsed}:${minutesParsed < 10 ? '0' : ''}${minutesParsed}:${secondsParsed < 10 ? '0' : ''}${secondsParsed}`;

  return {
    timer,
    timeString,
    hoursString: hoursParsed,
    minutesString: minutesParsed,
    secondsString: secondsParsed,
    startTimer,
    stopTimer,
    resetTimer,
  };
};
