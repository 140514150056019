import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import { enqueueSnackbar } from "notistack";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;
//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};

// utilities for get users that arent assigned to a specific report
export const assignUserToReport = async (user_id, report_id, rolesInvestigation, rolesResolution, details, externalUserName = null) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/assignment/manual/`,
      { user_id, report_id, rolesInvestigation, rolesResolution, details, externalUserName },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_assigning_user_report}`);
    console.error(error);
  }
};

// utilities for get users that arent assigned to a specific report
export const deleteUserFromReport = async (user_id, report_id) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.delete(`${tenant}/api/assignment/manual/`, {
      data: { user_id, report_id },
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_deleting_user_report}`);
    console.error(error);
  }
};
