import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MCLabel } from "../Label/Label";
import { MCSelect } from "../Select/Select";
import { BoldCloseCircle } from "../../Icon";
import { useTranslation } from "react-i18next";
import "./SelectSome.scss";
// -----> Select Some Option Available Component <----- //
export const MCSelectSome = ({
  label,
  helptip = false,
  placeholder = false,
  options = [],
  selecteds = [],
  onChange = () => {},
}) => {
  // --- Initial State --- //
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [avaiableOptions, setAvaiableOptions] = useState([]);
  // ---  Custom Hooks --- //
  const [t] = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);
  // Setting preselected information, filtering and updating options
  useEffect(() => {
    // Options are filtered to show only those that have not been selected
    const filteredOptions = options.filter(
      (option) => !selecteds.find((selected) => selected.value === option.value)
    );
    setAvaiableOptions(filteredOptions);
    setSelectedOptions(selecteds);
  }, [options]);
  // Sets selected options value
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);
  // Selecting available data option
  const selectingOptions = (selection) => {
    setSelectedOptions([...selectedOptions, selection]);
    setAvaiableOptions(
      avaiableOptions.filter((option) => option.value !== selection.value)
    );
  };
  // Remove data selected
  const removeItem = (selection) => {
    setSelectedOptions(
      selectedOptions.filter((option) => option !== selection)
    );
    // prevents duplicate avaiable options
    if (!avaiableOptions.some((option) => option.value === selection.value)) {
      setAvaiableOptions([...avaiableOptions, selection]);
    }
  };
  // Setting options to shows in input <Select/>
  const getOptions = (children) => {
    let option = [];
    for (var i = 0; i < children.length; i++) {
      option.push({
        value: children[i].value || children[i].code,
        // key: i,
        label: children[i].label || children[i].name,
      });
    }
    return option;
  };
  // Set Dark-mode
  const styleDarkMode = gTheme === "light" ? "" : "dark-mode";
  // Tooltip erase data
  const tooltip = <Tooltip id="tooltip">{t("SelectSome.delete")}</Tooltip>;

  return (
    <div className={`selectSome ${styleDarkMode}`}>
      <div className="title px-2">
        <MCLabel helptip={helptip} text={label} className="title__label" />
        <MCLabel
          text={`${selectedOptions.length} / ${options.length}`}
          className="title__label"
        />
      </div>
      <div>
        <MCSelect
          placeholder={
            placeholder ? (
              <div>{placeholder}</div>
            ) : (
              <div>{t("SelectSome.select_option")}</div>
            )
          }
          options={getOptions(avaiableOptions)}
          onChange={selectingOptions}
        />
      </div>
      {/* preview preselected options */}
      {selectedOptions.length >= 1 && (
        <div className="chipBox">
          {selectedOptions.map((item, idx) => (
            <div key={idx} className="chipBox__chip">
              <span>{item.label || item.name || item.country}</span>
              <OverlayTrigger placement="bottom" overlay={tooltip}>
                <div className="chipBox__close">
                  <BoldCloseCircle
                    onClick={() => removeItem(item)}
                    className="chipBox__icon"
                  />
                </div>
              </OverlayTrigger>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};