import { useState, useRef } from 'react';

const SimpleSelect = ({ options, key, label, fieldName, onChange, width, required, value }) => {
    const [invalid] = useState(false);

    return (
        <div className="form-group has-feedback" style={width ? {width:width} : {}}>
            <small className='mb-2'>{label}</small>
            <select
                key={key}
                name={fieldName ? fieldName : null}
                className={invalid ? "form-control is-invalid" : "form-control"}
                required={required}
                value={value}
                onChange={onChange}
            >
                {
                    options.map((option, idx) => {
                        return (
                            <option value={option.value} key={idx} >{option.text}</option>
                        )
                    })
                }
            </select>
        </div>
    );
}

export default SimpleSelect;