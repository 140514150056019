import { Row, Col } from "react-bootstrap";
import {
  CDBDropDown,
  CDBDropDownItem,
  CDBDropDownMenu,
  CDBDropDownToggle,
  CDBIcon,
  CDBBtn,
  CDBInput,
  CDBSwitch,
} from "cdbreact";
import Menu from "../../../assets/images/menu.png";
import {
  ProfileIcon,
  OutlineExport2,
  SearchIcon2,
  Setting,
} from "../../../components/Icon";
import { GeneralHeader } from "../../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

export const NewMenuUser = ({ vTheme }) => {
  const [t] = useTranslation("users");
  const BackTheme = {
    backgroundColor: vTheme === "light" ? "#f3f7fa" : "#141319",
  };
  const DynamicTheme = {
    backgroundColor: vTheme === "light" ? "#fff" : "#1a1d27",
  };
  return (
    <>
      <div
        id="settings"
        className={vTheme === "light" ? "" : "dark-mode"}
        style={BackTheme}
      >
        <GeneralHeader title={t("newMenu.new_menu")} />
        <div className="d-block d-lg-flex mt-30">
          <div className="profile-first-block mb-3">
            <div className="p-20 border" style={DynamicTheme}>
              <div className="d-flex flex-column roundBorder cardNoBorder">
                <h5 className="mb-20 text-left">
                  {t("newMenu.personal_information")}
                </h5>
                <div className="d-block d-sm-flex flex-row text-center">
                  <a href="/">
                    <ProfileIcon size="80px" />
                  </a>
                  <div className="d-flex flex-column text-left">
                    <span className="Selecciona-un-avatar mb-3 mt-2">
                      {t("newMenu.select_avatar_photo")}
                    </span>
                    <div>
                      <button className="btn btnUpload align-items-center text-center d-inline-block">
                        <span className="Sube-tu-foto">
                          {t("newMenu.upload_photo")} |{" "}
                        </span>{" "}
                        <OutlineExport2 size="14px" />
                      </button>
                      <button className="btn btnUpload align-items-center text-center d-inline-block">
                        <span className="Escoge-tu-avatar">
                          {t("newMenu.choose_avatar")} |{" "}
                        </span>{" "}
                        <ProfileIcon size="14px" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-flex flex-row">
                  <div className="form-group mb-30 w-100 mr-5">
                    <label className="mb-2">{`${t(
                      "newMenu.controller"
                    )}: *`}</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Dashboard"
                    ></input>
                  </div>
                  <div className="form-group mb-30 w-100">
                    <label className="mb-2">{`${t(
                      "newMenu.controller_action"
                    )}: *`}</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("newMenu.index")}
                    ></input>
                  </div>
                </div>
                <div className="d-block d-md-flex flex-row">
                  <div className="form-group mb-30 w-100 mr-5">
                    <label className="mb-2">{`${t(
                      "newMenu.additional_parameter"
                    )}: *`}</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("newMenu.new")}
                    ></input>
                  </div>
                  <div className="form-group mb-30 w-100">
                    <label className="mb-2">{`${t("newMenu.label")}: *`}</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("newMenu.board")}
                    ></input>
                  </div>
                </div>
                <div className="form-group mb-30 w-100">
                  <label className="mb-2">{t("newMenu.description")}:</label>
                  <CDBInput
                    className="form-control"
                    type="textarea"
                    rows="3"
                    size="sm"
                    hint="Praesent consectetur, nibh a porttitor vehicula, ligula lorem luctus justo, nec sagittis ante justo at nulla. Lacinia imperdiet est tellus vel arcu."
                  />
                </div>
              </div>
            </div>
            <div className="p-20 mt-20 border" style={DynamicTheme}>
              <h5 className="mt-10">{t("newMenu.assign_roles")}</h5>
              <small className="mt-20">
                {t("newMenu.select_role_assign")}:
              </small>
              <Row>
                <Col lg="6" className="p-0">
                  <div className="pr-lg-30">
                    <div className="p-2 border mt-20">
                      <div className="search w-auto">
                        <div className="searchsInputs w-100">
                          <div className="searchIcon">
                            <SearchIcon2 />
                          </div>
                          <input
                            type="text"
                            placeholder={`${t("newMenu.search_role")}...`}
                            id="header-search"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="pb-3 border-bottom">
                        <div className="mt-20 ml-3">
                          <CDBInput
                            type="checkbox"
                            label={t("newMenu.select_all")}
                            id="check0"
                          />
                        </div>
                      </div>
                      <div className="mt-20 ml-3">
                        <CDBInput
                          type="checkbox"
                          label={t("newMenu.ethics_committee")}
                          id="check1"
                        />
                      </div>
                      <div className="mt-20 ml-3">
                        <CDBInput
                          type="checkbox"
                          label={t("newMenu.investigator")}
                          id="check2"
                        />
                      </div>
                      <div className="mt-20 ml-3">
                        <CDBInput
                          type="checkbox"
                          label={t("newMenu.ethical_officer")}
                          id="check3"
                        />
                      </div>
                      <div className="mt-20 ml-3">
                        <CDBInput
                          type="checkbox"
                          label={t("newMenu.compliance")}
                          id="check4"
                        />
                      </div>
                      <div className="mt-20 ml-3">
                        <CDBInput
                          type="checkbox"
                          label={t("newMenu.advidor")}
                          id="check5"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="p-0">
                  <div className="pl-lg-30">
                    <div className="p-2 border mt-20">
                      <small className="pb-3 border-bottom">
                        5 {t("newMenu.aggregate_resources")}
                      </small>
                      <CDBDropDown dropright className="config-dropdown">
                        <CDBDropDownToggle>
                          <small className="btn athics-outline-btn w-100 d-flex align-items-center justify-content-between mt-20">
                            {t("newMenu.ethics_committee")}
                            <CDBIcon fas icon="ellipsis-h" className="ml-3" />
                          </small>
                        </CDBDropDownToggle>
                        <CDBDropDownMenu>
                          <CDBDropDownItem>
                            <Setting className="mr-2" />
                            {t("newMenu.configure")}
                          </CDBDropDownItem>
                          <div className="mt-2 text-center">
                            <CDBSwitch checked={true} />
                          </div>
                        </CDBDropDownMenu>
                      </CDBDropDown>
                      <CDBDropDown dropright className="config-dropdown">
                        <CDBDropDownToggle>
                          <small className="btn athics-outline-btn w-100 d-flex align-items-center justify-content-between mt-20">
                            {t("newMenu.investigator")}
                            <CDBIcon fas icon="ellipsis-h" className="ml-3" />
                          </small>
                        </CDBDropDownToggle>
                        <CDBDropDownMenu>
                          <CDBDropDownItem>
                            <Setting className="mr-2" />
                            {t("newMenu.configure")}
                          </CDBDropDownItem>
                          <div className="mt-2 text-center">
                            <CDBSwitch checked={true} />
                          </div>
                        </CDBDropDownMenu>
                      </CDBDropDown>
                      <CDBDropDown dropright className="config-dropdown">
                        <CDBDropDownToggle>
                          <small
                            disabled={true}
                            className="btn athics-outline-btn w-100 d-flex align-items-center justify-content-between mt-20"
                          >
                            {t("newMenu.ethical_officer")}
                            <CDBIcon fas icon="ellipsis-h" className="ml-3" />
                          </small>
                        </CDBDropDownToggle>
                        <CDBDropDownMenu>
                          <CDBDropDownItem>
                            <Setting className="mr-2" />
                            {t("newMenu.configure")}
                          </CDBDropDownItem>
                          <div className="mt-2 text-center">
                            <CDBSwitch checked={true} />
                          </div>
                        </CDBDropDownMenu>
                      </CDBDropDown>
                      <CDBDropDown dropright className="config-dropdown">
                        <CDBDropDownToggle>
                          <small className="btn athics-outline-btn w-100 d-flex align-items-center justify-content-between mt-20">
                            {t("newMenu.compliance")}
                            <CDBIcon fas icon="ellipsis-h" className="ml-3" />
                          </small>
                        </CDBDropDownToggle>
                        <CDBDropDownMenu>
                          <CDBDropDownItem>
                            <Setting className="mr-2" />
                            {t("newMenu.configure")}
                          </CDBDropDownItem>
                          <div className="mt-2 text-center">
                            <CDBSwitch checked={true} />
                          </div>
                        </CDBDropDownMenu>
                      </CDBDropDown>
                      <CDBDropDown dropright className="config-dropdown">
                        <CDBDropDownToggle>
                          <small className="btn athics-outline-btn w-100 d-flex align-items-center justify-content-between mt-20">
                            {t("newMenu.advisor")}
                            <CDBIcon fas icon="ellipsis-h" className="ml-3" />
                          </small>
                        </CDBDropDownToggle>
                        <CDBDropDownMenu>
                          <CDBDropDownItem>
                            <Setting className="mr-2" />
                            {t("newMenu.configure")}
                          </CDBDropDownItem>
                          <div className="mt-2 text-center">
                            <CDBSwitch checked={true} />
                          </div>
                        </CDBDropDownMenu>
                      </CDBDropDown>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="profile-second-block p-20 user-second-bar border"
            style={DynamicTheme}
          >
            <h5 className="mb-20 text-left">{t("newMenu.report_filters")}</h5>
            <small className="pb-3 border-bottom">
              {`${t("newMenu.there_are")} 5 ${t(
                "newMenu.active_permissions_user"
              )}`}
            </small>
            <div className="text-center p-20">
              <img
                src={Menu}
                alt="menu"
                id="menu"
                className="d-block mx-auto"
              />
              <CDBBtn className="athics-outline-btn mt-20" outline>
                {t("newMenu.edit_all_permissions")}
              </CDBBtn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
