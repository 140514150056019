import { CDBBtn, CDBInput, CDBSelect } from "cdbreact";
import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validInitDates } from "../../../../../helpers/dateTransform";
import { patchInvestigation } from "../../../../../apis";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { useTranslation } from "react-i18next";

export const EditGeneral = ({
  investigationAlias,
  formValues,
  toggleModal,
}) => {
  const [t] = useTranslation("investigation");

  const severityOpts = [
    {
      value: 1,
      label: t("severity.low"),
    },
    {
      value: 2,
      label: t("severity.medium"),
    },
    {
      value: 3,
      label: t("severity.high"),
    },
  ];

  const entrywayOpts = [
    {
      value: "phone",
      label: t("entryways.phone"),
    },
    {
      value: "email",
      label: t("entryways.email"),
    },
    {
      value: "web",
      label: t("entryways.web"),
    },
    {
      value: "whatsapp",
      label: t("entryways.whatsapp"),
    },
    {
      value: "chat",
      label: t("entryways.chat"),
    },
    {
      value: "app",
      label: t("entryways.app"),
    },
    {
      value: "other",
      label: t("entryways.other"),
    },
  ];

  const [when, setWhen] = useState(
    validInitDates(formValues?.about?.when)
      ? new Date(formValues?.about?.when)
      : new Date()
  );
  const [created_at, setCreated_at] = useState(
    validInitDates(formValues?.about?.created_at)
      ? new Date(formValues?.about?.created_at)
      : new Date()
  );
  const [severity, setSeverity] = useState(
    formValues?.about?.severity
      ? severityOpts.find((item) => item.value === formValues?.about?.severity)
      : null
  );
  const [to, setTo] = useState(
    validInitDates(formValues?.about?.event_happen?.to)
      ? new Date(formValues?.about?.event_happen?.to)
      : new Date()
  );
  const [from, setFrom] = useState(
    validInitDates(formValues?.about?.event_happen?.from)
      ? new Date(formValues?.about?.event_happen?.from)
      : new Date()
  );

  const [name, setName] = useState(formValues?.reporter?.name || "");
  const [entryway, setEntryway] = useState(
    formValues?.reporter?.entryway
      ? entrywayOpts.find(
          (item) => item.value === formValues?.reporter?.entryway
        )
      : null
  );
  const [relationship, setRelationship] = useState(
    formValues?.reporter?.relationship || ""
  );

  const [ethics_adviser, setEthics_adviser] = useState(
    formValues?.comentaries?.ethics_adviser || ""
  );
  const [ethics_officer, setEthics_officer] = useState(
    formValues?.comentaries?.ethics_officer || ""
  );

  const [code, setCode] = useState(formValues?.clasification?.code || "");
  const [category, setCategory] = useState(
    formValues?.clasification?.category || ""
  );
  const [subcategory, setSubcategory] = useState(
    formValues?.clasification?.subcategory || ""
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const objToSave = {
      folder: {
        general: {
          about: {
            when,
            created_at,
            severity: severity?.value || null,
            event_happen: {
              to,
              from,
            },
          },
          reporter: {
            name,
            entryway: entryway?.value || null,
            relationship,
          },
          comentaries: {
            ethics_adviser,
            ethics_officer,
          },
          clasification: {
            code,
            category,
            subcategory,
          },
        },
      },
    };
    try {
      await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  return (
    <div>
      <Row>
        <h5>{t("General.general_investigation_data")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("severity.severity")}:</small>
          <MCSelect
            options={severityOpts}
            value={severity}
            onChange={setSeverity}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.reporter_info")}:</small>
          <MCInput
            type="text"
            placeholder=""
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">
            {t("General.business_relationship")}:
          </small>
          <MCInput
            type="text"
            placeholder=""
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.entryway")}:</small>
          <MCSelect
            options={entrywayOpts}
            value={entryway}
            onChange={setEntryway}
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <h5>{t("General.range_date")}</h5>
      </Row>
      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.initial_date")}:</small>
          <DatePicker
            selected={from}
            onChange={(d) => setFrom(d)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, hh:mm a"
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("General.final_date")}:</small>
          <DatePicker
            selected={to}
            onChange={(d) => setTo(d)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, hh:mm a"
          />
        </Col>
      </Row>

      {/* <hr />
      
      <Row>
          <h5>Clasificación de la denuncia</h5>
      </Row> */}
      {/* <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">Categoría</small>
          <MCInput type="text" placeholder="Ingresa la categoria" value={ category } onChange={(e)=>setCategory(e.target.value)} />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">Subcategoría</small>
          <MCInput type="text" placeholder="Ingresa la subcategoria" value={ subcategory } onChange={(e)=>setSubcategory(e.target.value)} />
        </Col>
      </Row> */}

      <hr />

      <Row>
        <h5>{t("General.approvers_comments")}</h5>
      </Row>
      <Row>
        <Col md={12} lg={12} className="input-container">
          <small className="my-2 d-block">
            {t("General.approvers_comments")}:
          </small>
          <MCInput
            type="textarea"
            cols="30"
            rows="5"
            placeholder={t("General.comments_example")}
            value={ethics_adviser}
            onChange={(e) => setEthics_adviser(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("General.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("General.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
