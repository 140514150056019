import { useState } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import { CDBInput, CDBBtn } from 'cdbreact';
import SimpleSelect from '../../../components/SimpleSelect';
import { GeneralHeader } from '../../../components/Title/GeneralHeader';

export const ReportSettings = ({ vTheme }) => {
    const BackTheme = {
        backgroundColor: vTheme === "light" ? '#f3f7fa' : '#141319'
    }
    const DynamicTheme = {
        backgroundColor: vTheme === "light" ? '#fff' : '#1a1d27'
    }
    const [general_option] = useState([
        {
          text: "Option 1",
          value: "1",
        },
        {
          text: "Option 2",
          value: "2",
        },
        {
          text: "Option 3",
          value: "3",
        },
    ]);
    const assgin = [
        {
            header: "Asignar personas",
            title: "Asignar todas las personas",
            content: [
                { text: "Empleados / Employees", status: true },
                { text: "Proveedor / Providers", status: true },
                { text: "Clientes / Clients", status: false },
                { text: "Socios / Partners", status: true },
                { text: "Alumno / Student", status: false },
                { text: "Empleados / Employees", status: true },
                { text: "Proveedor / Providers", status: true },
                { text: "Clientes / Clients", status: false }
            ]
        },
        {
            header: "Asignar centros de trabajo",
            title: "Asignar todos los centros del trabajo",
            content: [
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Colombia", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: true },
                { text: "Almacén Centro - Dakota s/n, Nápoles, Benito Juárez, 03810 CDMX, Mexico", status: false }
            ]
        },
        {
            header: "Asignar áreas o departamentos",
            title: "Asignar todos los departamentos",
            content: [
                { text: "Recursos Humanos / Human Resources", status: true },
                { text: "Capital Humano / Human Capital", status: false },
                { text: "Recursos y Materiales / Resources and Materials", status: false },
                { text: "Compras y Adquisiciones / Purchases and Acquisitions", status: true },
                { text: "Recursos Humanos / Human Resources", status: false }
            ]
        },
        {
            header: "Asignar denuncias",
            title: "Asignar todo el catálogo de denuncias",
            content: [
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: true },
                { text: "Hostigamiento Sexual / Sexual Harassment , Recursos Humanos - Acoso", status: false }
            ]
        },
        {
            header: "Asignar preguntas",
            title: "Asignar todo el catálogo de preguntas",
            content: [
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Relación con la empresa", status: true },
                { text: "¿Los hechos sucedieron dentro del lugar de trabajo? Anónimo", status: false },
            ]
        },
        {
            header: "Asignar sugerencias",
            title: "Asignar todo el catálogo de sugerencias",
            content: [
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
                { text: "Optimizar tiempos y esfuerzos, Relación con la empresa", status: true },
                { text: "Optimizar tiempos y esfuerzos, Anónimo", status: false },
            ]
        }
    ]
    return (
        <div id="settings" className={vTheme === "light" ? "" : "dark-mode"} style={ BackTheme } >
            <GeneralHeader
                title="Configurar catálogo de denuncias"
                text="Nulla a nisi ac magna tristique lacinia. Vestibulum facilisis bibendum felis sit amet fringilla."
                showLang
                showPath
                path="Configuración > Reportes > Catálogo de denuncias"
            />
            <div className='p-20 mt-20 border' style={ DynamicTheme } >
                <h5 className='mt-20'>Administrar catálogo de denuncias</h5>
                <small>Clasificación por categorías y subcategorías para tus denuncias</small>
                <Row className='mt-20'>
                    <Col lg="6" className='p-0'>
                        <div className='pr-lg-30'>
                            <SimpleSelect label="Categoría*" options={general_option} />
                        </div>                        
                    </Col>
                    <Col lg="6" className='p-0'>
                        <div className='pl-lg-30'>
                            <SimpleSelect label="Subcategoría*" options={general_option} />
                        </div>                        
                    </Col>
                </Row>
            </div>
            <div className='p-20 mt-20 border' style={ DynamicTheme } >
                <Accordion defaultActiveKey={['1']} alwaysOpen>
                    {assgin.map((item, id) => {
                        return (
                            <Accordion.Item className="assign-accordion" key={"assgin"+ id} eventKey={id}>
                                <Accordion.Header>{item.header}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='d-block d-md-flex align-items-center justify-content-between pb-3 px-3 border-bottom'>
                                        <CDBInput type="checkbox" label={item.title} id={"assign" + id} />
                                        <CDBBtn className="small-primary-btn text-uppercase mt-2">{item.header}</CDBBtn>
                                    </div>
                                    <Row>
                                        {item.content.map((list, idx) => {
                                            return (
                                                (
                                                    id === 0 ? 
                                                    <Col lg="6" key={id + "_" + idx}>
                                                        <div className="mt-20 assign-check">
                                                            <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                        </div>
                                                    </Col>
                                                    : 
                                                    <Col lg="12" key={id + "_" + idx}>
                                                        <div className="mt-20 assign-check">
                                                            <CDBInput type="checkbox" label={list.text} id={"checkbox" + id + "_" + idx} />
                                                        </div>
                                                    </Col>
                                                )
                                            )
                                        })}
                                    </Row>
                                </Accordion.Body>                            
                            </Accordion.Item>                            
                        )
                    })}
                </Accordion>
            </div>
        </div>
    );
};

