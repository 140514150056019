import { useState } from "react";

export const useForm = (initialState) => {
  const [formValues, setFormValues] = useState(initialState);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return {
    formValues,
    handleChange,
  };
};
