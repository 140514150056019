import React from 'react';
import notFoundImage from '../../assets/images/not_found.png'; // Adjust the import path
import "./GlobalComponents.scss";

const NotFound = () => {
  return (
    <div className="notfound">
      <img src={notFoundImage} alt="Not Found"  />
    </div>
  );
};

export default NotFound;
