import { CDBBtn, CDBInput } from "cdbreact";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import Icon from "../../../../Icon/Icon";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { useTranslation } from "react-i18next";

export const EditCauses = ({
  theme,
  formValues,
  handleChange,
  toggleModal,
  setkeysChanged,
  saveForm,
}) => {
  const [t] = useTranslation("investigation");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    toggleModal("just-close");
  };

  return (
    <div>
      <Row>
        <h5>{t("causes.general_info")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("causes.cause_complaint")}:</small>
          <MCInput type="text" placeholder={t("causes.enter_cause_compaint")} />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("causes.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("causes.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
