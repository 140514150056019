//CUSTOM
import { ReactComponent as RemoveFromPlaylist } from "./remove-from-playlist.svg";
import { ReactComponent as OutlineDocumentText3 } from "./vuesax-outline-document-text-3.svg";

//MONEY (outline):
import { ReactComponent as WalletAdd } from "./vuesax-outline-wallet-add.svg";
import { ReactComponent as ReceiptItem } from "./vuesax-outline-receipt-item.svg";
import { ReactComponent as EmptyWallet } from "./vuesax-outline-empty-wallet.svg";
import { ReactComponent as ReceiptDiscount } from "./vuesax-outline-receipt-discount.svg";
import { ReactComponent as EmptyWalletRemove } from "./vuesax-outline-empty-wallet-remove.svg";
import { ReactComponent as EmptyWalletAdd } from "./vuesax-outline-empty-wallet-add.svg";
import { ReactComponent as ReceiptMinus } from "./vuesax-outline-receipt-minus.svg";
import { ReactComponent as WalletSearch } from "./vuesax-outline-wallet-search.svg";
import { ReactComponent as Strongbox } from "./vuesax-outline-strongbox-2.svg";
import { ReactComponent as WalletTick } from "./vuesax-outline-empty-wallet-tick.svg";
import { ReactComponent as ReceiptAdd } from "./vuesax-outline-receipt-add.svg";
import { ReactComponent as OutlineMoney } from "./vuesax-outline-money.svg";
import { ReactComponent as EmptyWalletTime } from "./vuesax-outline-empty-wallet-time.svg";

import { ReactComponent as TransactionMinus } from "./vuesax-outline-transaction-minus.svg";
import { ReactComponent as OutlineMoney2 } from "./vuesax-outline-money-2.svg";
import { ReactComponent as EmptyWalletChange } from "./vuesax-outline-empty-wallet-change.svg";
import { ReactComponent as OutlineMoney3 } from "./vuesax-outline-money-3.svg";
import { ReactComponent as PercentageSquare } from "./vuesax-outline-percentage-square.svg";
import { ReactComponent as OutlineMoney4 } from "./vuesax-outline-money-4.svg";
import { ReactComponent as PercentageCircle } from "./vuesax-outline-percentage-circle.svg";
import { ReactComponent as ChartSquareMoney } from "./vuesax-outline-chart-square.svg";

import { ReactComponent as OutlineMoneys } from "./vuesax-outline-moneys.svg";
import { ReactComponent as OutlineCoin } from "./vuesax-outline-coin.svg";
import { ReactComponent as ReceiptEdit } from "./vuesax-outline-receipt-edit.svg";
import { ReactComponent as MoneyTick } from "./vuesax-outline-money-tick.svg";
import { ReactComponent as DiscountShape } from "./vuesax-outline-discount-shape.svg";
import { ReactComponent as ReceiptSearch } from "./vuesax-outline-receipt-search.svg";
import { ReactComponent as MoneyAdd } from "./vuesax-outline-money-add.svg";
import { ReactComponent as ConvertCard } from "./vuesax-outline-convert-card.svg";
import { ReactComponent as DiscountCircle } from "./vuesax-outline-discount-circle.svg";
import { ReactComponent as OutlineWallet } from "./vuesax-outline-wallet-2.svg";
import { ReactComponent as MoneyRemove } from "./vuesax-outline-money-remove.svg";

import { ReactComponent as WalletMoney } from "./vuesax-outline-wallet-money.svg";
import { ReactComponent as CardRemove } from "./vuesax-outline-card-remove.svg";
import { ReactComponent as OutlineCards } from "./vuesax-outline-cards.svg";
import { ReactComponent as MoneyForbiden } from "./vuesax-outline-money-forbidden.svg";
import { ReactComponent as OutlineWallet3 } from "./vuesax-outline-wallet-3.svg";
import { ReactComponent as CardTick } from "./vuesax-outline-card-tick.svg";
import { ReactComponent as OutlineReceipt2 } from "./vuesax-outline-receipt-2.svg";
import { ReactComponent as OutlineReceipt } from "./vuesax-outline-receipt.svg";
import { ReactComponent as MoneyChange } from "./vuesax-outline-money-change.svg";
import { ReactComponent as WalletRemove } from "./vuesax-outline-wallet-remove.svg";
import { ReactComponent as MoneyTime } from "./vuesax-outline-money-time.svg";

import { ReactComponent as ReceiptText } from "./vuesax-outline-receipt-text.svg";
import { ReactComponent as OutlineStrongbox } from "./vuesax-outline-strongbox.svg";
import { ReactComponent as OutlineWallet2 } from "./vuesax-outline-wallet.svg";
import { ReactComponent as SecurityCard } from "./vuesax-outline-security-card.svg";
import { ReactComponent as MoneyRecive } from "./vuesax-outline-money-recive.svg";
import { ReactComponent as MoneySend } from "./vuesax-outline-money-send.svg";
import { ReactComponent as OutlineArchive } from "./vuesax-outline-archive.svg";
import { ReactComponent as OutlineTag } from "./vuesax-outline-tag.svg";
import { ReactComponent as OutlineTag2 } from "./vuesax-outline-tag-2.svg";
import { ReactComponent as OutlineCoin2 } from "./vuesax-outline-coin.svg";
import { ReactComponent as OutlineDocument } from "./vuesax-outline-document.svg";
import { ReactComponent as Wallet4 } from "./vuesax-outline-wallet-4.svg";
import { ReactComponent as CardAdd } from "./vuesax-outline-card-add.svg";
import { ReactComponent as CardRemove2 } from "./vuesax-outline-card-remove-2.svg";
import { ReactComponent as CardTick2 } from "./vuesax-outline-card-tick-2.svg";
import { ReactComponent as CardSlash } from "./vuesax-outline-card-slash.svg";
import { ReactComponent as CardPos } from "./vuesax-outline-card-pos.svg";
import { ReactComponent as Card } from "./vuesax-outline-card.svg";
import { ReactComponent as CardReceive } from "./vuesax-outline-card-receive.svg";
import { ReactComponent as CardSend } from "./vuesax-outline-card-send.svg";
import { ReactComponent as CardEdit } from "./vuesax-outline-card-edit.svg";
import { ReactComponent as WalletMinus } from "./vuesax-outline-wallet-minus.svg";
import { ReactComponent as WalletAdd2 } from "./vuesax-outline-wallet-add-2.svg";
import { ReactComponent as DollarSquare } from "./vuesax-outline-dollar-square.svg";
import { ReactComponent as DollarCircle } from "./vuesax-outline-dollar-circle.svg";
import { ReactComponent as Receipt1 } from "./vuesax-outline-receipt-1.svg";
import { ReactComponent as Receipt3 } from "./vuesax-outline-receipt-3.svg";
import { ReactComponent as ReceiptDisscount } from "./vuesax-outline-receipt-disscount.svg";
import { ReactComponent as Ticket } from "./vuesax-outline-ticket.svg";
import { ReactComponent as TicketStar } from "./vuesax-outline-ticket-star.svg";
import { ReactComponent as TicketExpired } from "./vuesax-outline-ticket-expired.svg";
import { ReactComponent as Ticket2 } from "./vuesax-outline-ticket-2.svg";
import { ReactComponent as TicketDiscount } from "./vuesax-outline-ticket-discount.svg";
import { ReactComponent as Math } from "./vuesax-outline-math.svg";

//TYPE-PARAGRAPH-CHARACTER (outline):
import { ReactComponent as TextalingLeft } from "./vuesax-outline-textalign-left.svg";
import { ReactComponent as TextalingCenter } from "./vuesax-outline-textalign-center.svg";
import { ReactComponent as TextalingRight } from "./vuesax-outline-textalign-right.svg";
import { ReactComponent as TextalingJustifyRight } from "./vuesax-outline-textalign-justifyright.svg";
import { ReactComponent as TextalingJustifyCenter } from "./vuesax-outline-textalign-justifycenter.svg";
import { ReactComponent as TextalingJustifyLeft } from "./vuesax-outline-textalign-justifyleft.svg";
import { ReactComponent as FirstLine } from "./vuesax-outline-firstline.svg";
import { ReactComponent as Paperclip2 } from "./vuesax-outline-paperclip-2.svg";
import { ReactComponent as Paperclip } from "./vuesax-outline-paperclip.svg";
import { ReactComponent as Link } from "./vuesax-outline-link.svg";
import { ReactComponent as Link1 } from "./vuesax-outline-link-1.svg";
import { ReactComponent as Link2 } from "./vuesax-outline-link-2.svg";
import { ReactComponent as TextBlock } from "./vuesax-outline-text-block.svg";
import { ReactComponent as TextItalic } from "./vuesax-outline-text-italic.svg";
import { ReactComponent as Text } from "./vuesax-outline-text.svg";
import { ReactComponent as Smallcaps } from "./vuesax-outline-smallcaps.svg";
import { ReactComponent as TextBold } from "./vuesax-outline-text-bold.svg";
import { ReactComponent as Underline } from "./vuesax-outline-text-underline.svg";
import { ReactComponent as Pharagraphspacing } from "./vuesax-outline-pharagraphspacing.svg";
import { ReactComponent as Maximize } from "./vuesax-outline-maximize.svg";
import { ReactComponent as Eraser } from "./vuesax-outline-eraser.svg";
import { ReactComponent as Square } from "./vuesax-outline-attach-square.svg";
import { ReactComponent as AttachCircle } from "./vuesax-outline-attach-circle.svg";
import { ReactComponent as QuoteUp } from "./vuesax-outline-quote-up.svg";
import { ReactComponent as QuoteDown } from "./vuesax-outline-quote-down.svg";
import { ReactComponent as QuoteUpSquare } from "./vuesax-outline-quote-up-square.svg";
import { ReactComponent as QuoteDownSquare } from "./vuesax-outline-quote-down-square.svg";
import { ReactComponent as QuoteDownCircle } from "./vuesax-outline-quote-down-circle.svg";
import { ReactComponent as QuoteUpCircle } from "./vuesax-outline-quote-up-circle.svg";
import { ReactComponent as Link3 } from "./vuesax-outline-link-3.svg";
import { ReactComponent as LinkSquare } from "./vuesax-outline-link-square.svg";
import { ReactComponent as LinkCircle } from "./vuesax-outline-link-circle.svg";
import { ReactComponent as Translate } from "./vuesax-outline-translate.svg";
import { ReactComponent as LenguageSquare } from "./vuesax-outline-language-square.svg";
import { ReactComponent as LenguageCircle } from "./vuesax-outline-language-circle.svg";

//Weather
import { ReactComponent as Moon } from "./vuesax-outline-moon.svg";
import { ReactComponent as Sun } from "./vuesax-outline-sun_2.svg";
import { ReactComponent as Cloud } from "./vuesax-outline-cloud.svg";
import { ReactComponent as CloudPlus } from "./vuesax-outline-cloud-plus.svg";
import { ReactComponent as CloudMinus } from "./vuesax-outline-cloud-minus.svg";
import { ReactComponent as CloudCross } from "./vuesax-outline-cloud-cross.svg";
import { ReactComponent as CloudSunny } from "./vuesax-outline-cloud-sunny.svg";
import { ReactComponent as CloudNotif } from "./vuesax-outline-cloud-notif.svg";
import { ReactComponent as CloudDrizzle } from "./vuesax-outline-cloud-drizzle.svg";
import { ReactComponent as CloudSnow } from "./vuesax-outline-cloud-snow.svg";
import { ReactComponent as CloudLightning } from "./vuesax-outline-cloud-lightning.svg";
import { ReactComponent as OutlineFlash2 } from "./vuesax-outline-flash.svg";
import { ReactComponent as OutlineIcon } from "./vuesax-outline-icon.svg";
import { ReactComponent as OutlineWind } from "./vuesax-outline-wind.svg";
import { ReactComponent as Wind2 } from "./vuesax-outline-wind-2.svg";
import { ReactComponent as CloudFog } from "./vuesax-outline-cloud-fog.svg";
import { ReactComponent as SunFog } from "./vuesax-outline-sun-fog.svg";
import { ReactComponent as OutlineSun } from "./vuesax-outline-sun.svg";

//Files
import { ReactComponent as Folder } from "./vuesax-outline-folder.svg";
import { ReactComponent as FolderCross } from "./vuesax-outline-folder-cross.svg";
import { ReactComponent as FolderAdd } from "./vuesax-outline-folder-add.svg";
import { ReactComponent as FolderMinus } from "./vuesax-outline-folder-minus.svg";
import { ReactComponent as FolderFavorite } from "./vuesax-outline-folder-favorite.svg";
import { ReactComponent as Folder_2 } from "./vuesax-outline-folder-2.svg";
import { ReactComponent as FolderOpen } from "./vuesax-outline-folder-open.svg";
import { ReactComponent as FolderCloud } from "./vuesax-outline-folder-cloud.svg";

//Video, Audio, Image
import { ReactComponent as Microphone } from "./vuesax-outline-microphone.svg";
import { ReactComponent as MicrophoneSlash } from "./vuesax-outline-microphone-slash.svg";
import { ReactComponent as Microphone2 } from "./vuesax-outline-microphone-2.svg";
import { ReactComponent as MicrophoneSlash2 } from "./vuesax-outline-microphone-slash_2.svg";
import { ReactComponent as VolumeCross } from "./vuesax-outline-volume-cross.svg";
import { ReactComponent as VolumeSlash } from "./vuesax-outline-volume-slash.svg";
import { ReactComponent as VolumeUp } from "./vuesax-outline-volume-up.svg";
import { ReactComponent as VolumeLow2 } from "./vuesax-outline-volume-low_2.svg";
import { ReactComponent as VolumeLow } from "./vuesax-outline-volume-low.svg";
import { ReactComponent as VolumeHigh } from "./vuesax-outline-volume-high.svg";
import { ReactComponent as VolumeMute } from "./vuesax-outline-volume-mute.svg";
import { ReactComponent as OutlineVideo } from "./vuesax-outline-video.svg";
import { ReactComponent as VideoSlash } from "./vuesax-outline-video-slash.svg";
import { ReactComponent as VideoSquare } from "./vuesax-outline-video-square.svg";
import { ReactComponent as VideoCircle } from "./vuesax-outline-video-circle.svg";
import { ReactComponent as Gallery } from "./vuesax-outline-gallery.svg";
import { ReactComponent as GalleryFavorite } from "./vuesax-outline-gallery-favorite.svg";
import { ReactComponent as GallerySlash } from "./vuesax-outline-gallery-slash.svg";
import { ReactComponent as GalleryTick } from "./vuesax-outline-gallery-tick.svg";
import { ReactComponent as GalleryImport } from "./vuesax-outline-gallery-import.svg";
import { ReactComponent as GalleryExport } from "./vuesax-outline-gallery-export.svg";
import { ReactComponent as GalleryAdd } from "./vuesax-outline-gallery-add.svg";
import { ReactComponent as GalleryRemove } from "./vuesax-outline-gallery-remove.svg";
import { ReactComponent as GalleryEdit } from "./vuesax-outline-gallery-edit.svg";
import { ReactComponent as Image } from "./vuesax-outline-image.svg";
import { ReactComponent as Sreenmirroring } from "./vuesax-outline-screenmirroring.svg";
import { ReactComponent as Camera } from "./vuesax-outline-camera.svg";
import { ReactComponent as CameraSlash } from "./vuesax-outline-camera-slash.svg";
import { ReactComponent as Play } from "./vuesax-outline-play.svg";
import { ReactComponent as Record } from "./vuesax-outline-record.svg";
import { ReactComponent as Stop } from "./vuesax-outline-stop.svg";
import { ReactComponent as MusicNote } from "./vuesax-outline-musicnote.svg";
import { ReactComponent as Music } from "./vuesax-outline-music.svg";
import { ReactComponent as VideoVertical } from "./vuesax-outline-video-vertical.svg";
import { ReactComponent as VideoHorizontal } from "./vuesax-outline-video-horizontal.svg";
import { ReactComponent as VideoPlay } from "./vuesax-outline-video-play.svg";
import { ReactComponent as Subtitle } from "./vuesax-outline-subtitle.svg";
import { ReactComponent as PlayCircle } from "./vuesax-outline-play-circle.svg";
import { ReactComponent as RecordCircle } from "./vuesax-outline-record-circle.svg";
import { ReactComponent as StopCircle } from "./vuesax-outline-stop-circle.svg";
import { ReactComponent as PauseCircle } from "./vuesax-outline-pause-circle.svg";
import { ReactComponent as Pause } from "./vuesax-outline-pause.svg";
import { ReactComponent as Scissor } from "./vuesax-outline-scissor.svg";
import { ReactComponent as Backward } from "./vuesax-outline-backward.svg";
import { ReactComponent as Forward } from "./vuesax-outline-forward.svg";
import { ReactComponent as Previous } from "./vuesax-outline-previous.svg";
import { ReactComponent as Netx } from "./vuesax-outline-next.svg";
import { ReactComponent as Radio } from "./vuesax-outline-radio.svg";
import { ReactComponent as MusicCircle } from "./vuesax-outline-music-circle.svg";
import { ReactComponent as PlayCricle } from "./vuesax-outline-play-cricle.svg";
import { ReactComponent as PlayRemove } from "./vuesax-outline-play-remove.svg";
import { ReactComponent as PlayAdd } from "./vuesax-outline-play-add.svg";
import { ReactComponent as Backward5 } from "./vuesax-outline-backward-5-seconds.svg";
import { ReactComponent as Backward10 } from "./vuesax-outline-backward-10-seconds.svg";
import { ReactComponent as Backward15 } from "./vuesax-outline-backward-15-seconds.svg";
import { ReactComponent as Forward5 } from "./vuesax-outline-forward-5-seconds.svg";
import { ReactComponent as Forward10 } from "./vuesax-outline-forward-10-seconds.svg";
import { ReactComponent as Forward15 } from "./vuesax-outline-forward-15-seconds.svg";
import { ReactComponent as MusicFilter2 } from "./vuesax-outline-music-filter-2.svg";
import { ReactComponent as MusicDasboard } from "./vuesax-outline-music-dashboard.svg";
import { ReactComponent as SquareRemove } from "./vuesax-outline-music-square-remove.svg";
import { ReactComponent as SaquareAdd } from "./vuesax-outline-music-square-add.svg";
import { ReactComponent as SquareSearh } from "./vuesax-outline-music-square-search.svg";
import { ReactComponent as RepeatMusic } from "./vuesax-outline-repeate-music.svg";
import { ReactComponent as RepeatOne } from "./vuesax-outline-repeate-one.svg";
import { ReactComponent as Shuffle } from "./vuesax-outline-shuffle.svg";
import { ReactComponent as Devices } from "./vuesax-outline-devices.svg";
import { ReactComponent as Playlist } from "./vuesax-outline-music-playlist.svg";
import { ReactComponent as Library2 } from "./vuesax-outline-music-library-2.svg";
import { ReactComponent as MusicSquare } from "./vuesax-outline-music-square.svg";
import { ReactComponent as AudioSquare } from "./vuesax-outline-audio-square.svg";
import { ReactComponent as NoteSquare } from "./vuesax-outline-note-square.svg";
import { ReactComponent as MiniNoteSquare } from "./vuesax-outline-mini-music-sqaure.svg";
import { ReactComponent as VideoAdd } from "./vuesax-outline-video-add.svg";
import { ReactComponent as VideoRemove } from "./vuesax-outline-video-remove.svg";
import { ReactComponent as VideoTick } from "./vuesax-outline-video-tick.svg";
import { ReactComponent as VideoTime } from "./vuesax-outline-video-time.svg";
import { ReactComponent as VideoOctagon } from "./vuesax-outline-video-octagon.svg";
import { ReactComponent as VoiceSquare } from "./vuesax-outline-voice-square.svg";
import { ReactComponent as VoiceCircle } from "./vuesax-outline-voice-cricle.svg";
import { ReactComponent as MaximizeCircle } from "./vuesax-outline-maximize-circle.svg";

//Arrow
import { ReactComponent as Received } from "./vuesax-outline-received.svg";
import { ReactComponent as Send } from "./vuesax-outline-send.svg";
import { ReactComponent as Arrow2 } from "./vuesax-outline-arrow-2.svg";
import { ReactComponent as Arrow3 } from "./vuesax-outline-arrow-3.svg";
import { ReactComponent as ArrowSquareUp } from "./vuesax-outline-arrow-square-up.svg";
import { ReactComponent as ArrowSquareDown } from "./vuesax-outline-arrow-square-down.svg";
import { ReactComponent as ArrowSquareLeft } from "./vuesax-outline-arrow-square-left.svg";
import { ReactComponent as ArrowSquareRight } from "./vuesax-outline-arrow-right.svg";
import { ReactComponent as ArrowRight } from "./vuesax-outline-arrow-right_2.svg";
import { ReactComponent as ArrowLeft } from "./vuesax-outline-arrow-left.svg";
import { ReactComponent as ArrowUp4 } from "./vuesax-outline-arrow-up_4.svg";
import { ReactComponent as ArrowDown } from "./vuesax-outline-arrow-down.svg";
import { ReactComponent as ArrowUp3 } from "./vuesax-outline-arrow-up_3.svg";
import { ReactComponent as ArrowDown2 } from "./vuesax-outline-arrow-down_2.svg";
import { ReactComponent as OutlineArrowRight4 } from "./vuesax-outline-arrow-right_4.svg";
import { ReactComponent as Arrowleft3 } from "./vuesax-outline-arrow-left_3.svg";
import { ReactComponent as ArrowCircleLeft } from "./vuesax-outline-arrow-circle-left.svg";
import { ReactComponent as ArrowCircleRight } from "./vuesax-outline-arrow-circle-right.svg";
import { ReactComponent as ArrowCircleDown } from "./vuesax-outline-arrow-circle-down.svg";
import { ReactComponent as ArrowCircleUp } from "./vuesax-outline-arrow-circle-up.svg";
import { ReactComponent as ArrowSwap } from "./vuesax-outline-arrow-swap.svg";
import { ReactComponent as ArrowSwapHorizontal } from "./vuesax-outline-arrow-swap-horizontal.svg";
import { ReactComponent as OutlineUndo } from "./vuesax-outline-undo.svg";
import { ReactComponent as OutlineRedo } from "./vuesax-outline-redo.svg";
import { ReactComponent as OutlineRotateLeft } from "./vuesax-outline-rotate-left.svg";
import { ReactComponent as OutlineRotateRight } from "./vuesax-outline-rotate-right.svg";
import { ReactComponent as OutlineRefresh2 } from "./vuesax-outline-refresh-2.svg";
import { ReactComponent as OutlineArrowLeft2 } from "./vuesax-outline-arrow-left_2.svg";
import { ReactComponent as OutlineArrowRight5 } from "./vuesax-outline-arrow-right_5.svg";
import { ReactComponent as OutlineArrowUp } from "./vuesax-outline-arrow-up.svg";
import { ReactComponent as OutlineArrowBottom } from "./vuesax-outline-arrow-bottom.svg";
import { ReactComponent as OutlineArrowUp2 } from "./vuesax-outline-arrow-up_2.svg";
import { ReactComponent as OutlineArrowRight3 } from "./vuesax-outline-arrow-right_3.svg";
import { ReactComponent as OutlineArrowLeft4 } from "./vuesax-outline-arrow-left_4.svg";
import { ReactComponent as OutlineArrowDown } from "./vuesax-outline-arrow-down_3.svg";
import { ReactComponent as OutlineConvert } from "./vuesax-outline-convert.svg";
import { ReactComponent as OutlineArrangeSquare } from "./vuesax-outline-arrange-square.svg";
import { ReactComponent as OutlineArrageSquare2 } from "./vuesax-outline-arrange-square-2.svg";
import { ReactComponent as OutlineBackSquare } from "./vuesax-outline-back-square.svg";
import { ReactComponent as OutlineForwardSquare } from "./vuesax-outline-forward-square.svg";
import { ReactComponent as OutlineRefreshSquare2 } from "./vuesax-outline-refresh-square-2.svg";
import { ReactComponent as OutlineRefreshRightSquare } from "./vuesax-outline-refresh-right-square.svg";
import { ReactComponent as OutlineRefreshLeftSquare } from "./vuesax-outline-refresh-left-square.svg";
import { ReactComponent as OutlineReceiveSquare } from "./vuesax-outline-receive-square.svg";
import { ReactComponent as OutlineSendSquare } from "./vuesax-outline-send-square.svg";
import { ReactComponent as OutlineSendSquare2 } from "./vuesax-outline-send-sqaure-2.svg";
import { ReactComponent as OutlineReceiveSquare2 } from "./vuesax-outline-receive-square-2.svg";
import { ReactComponent as OutlineLogin2 } from "./vuesax-outline-login_2.svg";
import { ReactComponent as OutlineLogout2 } from "./vuesax-outline-logout_2.svg";
import { ReactComponent as OutlineImport } from "./vuesax-outline-import.svg";
import { ReactComponent as OutlineExport } from "./vuesax-outline-export.svg";
import { ReactComponent as OutlineExport2 } from "./vuesax-outline-export_2.svg";
import { ReactComponent as OutlineImport2 } from "./vuesax-outline-import_2.svg";
import { ReactComponent as OutlineLogin } from "./vuesax-outline-login.svg";
import { ReactComponent as OutlineLogout } from "./vuesax-outline-logout.svg";
import { ReactComponent as OutlineExport3 } from "./vuesax-outline-export_3.svg";
import { ReactComponent as OutlineFrame } from "./vuesax-outline-frame.svg";
import { ReactComponent as OutlineImport3 } from "./vuesax-outline-import_3.svg";
import { ReactComponent as OutlineExport4 } from "./vuesax-outline-export_4.svg";
import { ReactComponent as OutlineRefresh } from "./vuesax-outline-refresh.svg";
import { ReactComponent as OutlineArrangeCircle } from "./vuesax-outline-arrange-circle.svg";
import { ReactComponent as OutlineArrangeCircle2 } from "./vuesax-outline-arrange-circle-2.svg";
import { ReactComponent as OutlineRepeat } from "./vuesax-outline-repeat.svg";
import { ReactComponent as OutlineRepeatCircle } from "./vuesax-outline-repeat-circle.svg";
import { ReactComponent as OutlineRefreshCircle } from "./vuesax-outline-refresh-circle.svg";

//Users
import { ReactComponent as OutlineUser } from "./vuesax-outline-user.svg";
import { ReactComponent as OutlineUserAdd } from "./vuesax-outline-user-add.svg";
import { ReactComponent as OutlineUserRemove } from "./vuesax-outline-user-remove.svg";
import { ReactComponent as OutlineUserTick } from "./vuesax-outline-user-tick.svg";
import { ReactComponent as OutlineUserMinus } from "./vuesax-outline-user-minus.svg";
import { ReactComponent as OutlineUserSearch } from "./vuesax-outline-user-search.svg";
import { ReactComponent as OutlineUserEdit } from "./vuesax-outline-user-edit.svg";
import { ReactComponent as OutlineUserTag } from "./vuesax-outline-user-tag.svg";
import { ReactComponent as OutlineUserOctagon } from "./vuesax-outline-user-octagon.svg";
import { ReactComponent as OutlineUserCircleAdd } from "./vuesax-outline-user-cirlce-add.svg";

import { ReactComponent as OutlineUserSquare } from "./vuesax-outline-user-square.svg";
import { ReactComponent as OutlineFrame2 } from "./vuesax-outline-frame.svg";
import { ReactComponent as OutlineProfile2User } from "./vuesax-outline-profile-2-user.svg";
import { ReactComponent as OutlineProfileRemove } from "./vuesax-outline-profile-remove.svg";
import { ReactComponent as OutlineProfileTick } from "./vuesax-outline-profile-tick.svg";
import { ReactComponent as OutlineProfileAdd } from "./vuesax-outline-profile-add.svg";
import { ReactComponent as OutlineProfileDelete } from "./vuesax-outline-profile-delete.svg";
import { ReactComponent as OutlineProfileCircle } from "./vuesax-outline-profile-circle.svg";
import { ReactComponent as OutlineTagUser } from "./vuesax-outline-tag-user.svg";
import { ReactComponent as OutlinePeople } from "./vuesax-outline-people.svg";

//Car
import { ReactComponent as OutlineAirplane } from "./vuesax-outline-airplane.svg";
import { ReactComponent as OutlineCar } from "./vuesax-outline-car.svg";
import { ReactComponent as OutlineBus } from "./vuesax-outline-bus.svg";
import { ReactComponent as OutlineSmartCar } from "./vuesax-outline-smart-car.svg";
import { ReactComponent as OutlineDriving } from "./vuesax-outline-driving.svg";
import { ReactComponent as OutlineShip } from "./vuesax-outline-ship.svg";
import { ReactComponent as OutlineAirplaneSquare } from "./vuesax-outline-airplane-square.svg";
import { ReactComponent as OutlineGasStation } from "./vuesax-outline-gas-station.svg";

//Essetional
import { ReactComponent as TrashCan } from "./vuesax-outline-trash.svg";
import { ReactComponent as EmptyBattery } from "./vuesax-outline-battery-empty.svg";
import { ReactComponent as OneThirdBattery } from "./vuesax-outline-battery-1-3-full.svg";
import { ReactComponent as TwoThirdBattery } from "./vuesax-outline-battery-2-3-full.svg";
import { ReactComponent as FullBattery } from "./vuesax-outline-battery-full.svg";
import { ReactComponent as ChargeBattery } from "./vuesax-outline-battery-charging.svg";
import { ReactComponent as DisableBattery } from "./vuesax-outline-battery-disable.svg";
import { ReactComponent as HomeIcon } from "./vuesax-outline-home.svg";
import { ReactComponent as HomeIconTwo } from "./vuesax-outline-home-2.svg";
import { ReactComponent as MoreIcon } from "./vuesax-outline-more-square.svg";
import { ReactComponent as CheckIcon } from "./vuesax-outline-tick.svg";
import { ReactComponent as CloseIcon } from "./vuesax-close-icon.svg";

import { ReactComponent as CheckIconCircle } from "./vuesax-outline-tick-circle.svg";
import { ReactComponent as AddIconCircle } from "./vuesax-outline-add-circle.svg";
import { ReactComponent as MinusIconCircle } from "./vuesax-outline-minus-cirlce.svg";
import { ReactComponent as CancelIconCircle } from "./vuesax-outline-close-circle.svg";
import { ReactComponent as MoreIconCircle } from "./vuesax-outline-more-circle.svg";
import { ReactComponent as InfoIconCircle } from "./vuesax-outline-info-circle.svg";
import { ReactComponent as WarningIcon } from "./vuesax-outline-danger.svg";
import { ReactComponent as AddIcon } from "./vuesax-outline-add.svg";
import { ReactComponent as AddIcon2 } from "./vuesax-outline-add-icon.svg";
import { ReactComponent as MinusIcon } from "./vuesax-outline-minus.svg";
import { ReactComponent as AddIconSquare } from "./vuesax-outline-add-square.svg";
import { ReactComponent as MinusIconSquare } from "./vuesax-outline-minus-square.svg";
import { ReactComponent as CheckIconSquare } from "./vuesax-outline-tick-square.svg";
import { ReactComponent as CancelIconSquare } from "./vuesax-outline-close-square.svg";
import { ReactComponent as SortIcon } from "./vuesax-outline-sort.svg";
import { ReactComponent as SendIcon } from "./vuesax-outline-send.svg";
import { ReactComponent as SendIcon2 } from "./vuesax-outline-send-2.svg";
import { ReactComponent as ShareCircle } from "./vuesax-outline-share.svg";
import { ReactComponent as FlashIcon } from "./vuesax-outline-flash-icon.svg";
import { ReactComponent as CancelFlashIcon } from "./vuesax-outline-flash-slash.svg";
import { ReactComponent as FlashCircle } from "./vuesax-outline-flash-circle.svg";
import { ReactComponent as CancelIcon } from "./vuesax-outline-slash.svg";
import { ReactComponent as CakeIcon } from "./vuesax-outline-cake.svg";
import { ReactComponent as Grammerly } from "./vuesax-outline-grammerly.svg";
import { ReactComponent as CrownKingIcon } from "./vuesax-outline-crown.svg";
import { ReactComponent as CrownQueenIcon } from "./vuesax-outline-crown.svg";
import { ReactComponent as FlagIcon } from "./vuesax-outline-flag.svg";
import { ReactComponent as FlagIcon2 } from "./vuesax-outline-flag-2.svg";
import { ReactComponent as TrophyIcon } from "./vuesax-outline-cup.svg";
import { ReactComponent as HappySquare } from "./vuesax-outline-emoji-happy.svg";
import { ReactComponent as HappySquare2 } from "./vuesax-outline-emoji-normal.svg";
import { ReactComponent as SadSquare } from "./vuesax-outline-emoji-sad.svg";
import { ReactComponent as TagCancelIcon } from "./vuesax-outline-tag-cross.svg";
import { ReactComponent as HamburguerMenu } from "./vuesax-outline-menu.svg";
import { ReactComponent as CdIcon } from "./vuesax-outline-cd.svg";
import { ReactComponent as CircleGraph } from "./vuesax-outline-chart.svg";
import { ReactComponent as ArchiveIcon } from "./vuesax-outline-archive.svg";
import { ReactComponent as ChromeIcon } from "./vuesax-outline-chrome.svg";
import { ReactComponent as InstagramIcon } from "./vuesax-outline-instagram.svg";
import { ReactComponent as CoffeeCup } from "./vuesax-outline-coffee.svg";
import { ReactComponent as PetPaw } from "./vuesax-outline-pet.svg";
import { ReactComponent as HappyCircle } from "./vuesax-outline-happyemoji.svg";
import { ReactComponent as ReservationBell } from "./vuesax-outline-reserve.svg";
import { ReactComponent as PostItCicle } from "./vuesax-outline-sticker.svg";
import { ReactComponent as ComputingSquare } from "./vuesax-outline-computing.svg";
import { ReactComponent as VerifyCircle } from "./vuesax-outline-verify.svg";
import { ReactComponent as InformationCircle } from "./vuesax-outline-information.svg";
import { ReactComponent as AutoBright } from "./vuesax-outline-autobrightness.svg";
import { ReactComponent as TriangleChrome } from "./vuesax-outline-triangle.svg";
import { ReactComponent as Cube3D } from "./vuesax-outline-3-dcube.svg";
import { ReactComponent as Glasses } from "./vuesax-outline-glass.svg";
import { ReactComponent as Discover } from "./vuesax-outline-discover.svg";
import { ReactComponent as Weight } from "./vuesax-outline-weight.svg";
import { ReactComponent as Filter } from "./vuesax-outline-filter.svg";
import { ReactComponent as FilterSquare } from "./vuesax-outline-filter-square.svg";
import { ReactComponent as SoundWave } from "./vuesax-outline-sound.svg";
import { ReactComponent as StoryIcon } from "./vuesax-outline-story.svg";
import { ReactComponent as SliderIcon } from "./vuesax-outline-slider.svg";
import { ReactComponent as StatusIcon } from "./vuesax-outline-status.svg";
import { ReactComponent as WifiSquare } from "./vuesax-outline-wifi-square.svg";
import { ReactComponent as OutlineSquareBox } from "./vuesax-outline-box-2.svg";
import { ReactComponent as TrashSquareIcon } from "./vuesax-outline-trush-square.svg";
import { ReactComponent as WifiIcon } from "./vuesax-outline-wifi.svg";
import { ReactComponent as Speedometer } from "./vuesax-outline-speedometer.svg";
import { ReactComponent as Diamonds } from "./vuesax-outline-diamonds.svg";
import { ReactComponent as LevelCircle } from "./vuesax-outline-level.svg";
import { ReactComponent as JudgeHammer } from "./vuesax-outline-judge.svg";
import { ReactComponent as LampIcon } from "./vuesax-outline-lamp.svg";
import { ReactComponent as TreeIcon } from "./vuesax-outline-tree.svg";
import { ReactComponent as Bubbles } from "./vuesax-outline-bubble.svg";
import { ReactComponent as Mirror } from "./vuesax-outline-mirror.svg";
import { ReactComponent as TagRight } from "./vuesax-outline-tag-right.svg";
import { ReactComponent as MilkBottleIcon } from "./vuesax-outline-milk.svg";
import { ReactComponent as GhostIcon } from "./vuesax-outline-ghost.svg";
import { ReactComponent as PodiumIcon } from "./vuesax-outline-ranking.svg";
import { ReactComponent as MouseSquare } from "./vuesax-outline-mouse-square.svg";
import { ReactComponent as MouseCircle } from "./vuesax-outline-mouse-circle.svg";
import { ReactComponent as MouseInsideCircle } from "./vuesax-outline-mouse.svg";
import { ReactComponent as ForbiddenIcon } from "./vuesax-outline-forbidden.svg";
import { ReactComponent as ForbiddenIcon2 } from "./vuesax-outline-forbidden-2.svg";
import { ReactComponent as WarningIcon2 } from "./vuesax-outline-warning-2.svg";
import { ReactComponent as BroomIcon } from "./vuesax-outline-broom.svg";
import { ReactComponent as SignPost } from "./vuesax-outline-signpost.svg";
import { ReactComponent as FilterRemove } from "./vuesax-outline-filter-remove.svg";
import { ReactComponent as FilterAdd } from "./vuesax-outline-filter-add.svg";
import { ReactComponent as FilterSearch } from "./vuesax-outline-filter-search.svg";
import { ReactComponent as FilterEdit } from "./vuesax-outline-filter-edit.svg";
import { ReactComponent as FilterCheck } from "./vuesax-outline-filter-tick.svg";
import { ReactComponent as HomeIconThree } from "./vuesax-outline-home.svg";
import { ReactComponent as SmartHome } from "./vuesax-outline-smart-home.svg";
import { ReactComponent as HomeWifi } from "./vuesax-outline-home-wifi.svg";
import { ReactComponent as SafeHome } from "./vuesax-outline-safe-home.svg";

//Support, Like, question
import { ReactComponent as StarIcon } from "./vuesax-outline-star.svg";
import { ReactComponent as StarCancelIcon } from "./vuesax-outline-star-slash.svg";
import { ReactComponent as LoveIcon } from "./vuesax-outline-heart.svg";
import { ReactComponent as LikeIcon } from "./vuesax-outline-like.svg";
import { ReactComponent as DisLikeIcon } from "./vuesax-outline-dislike.svg";
import { ReactComponent as DashStarIcon } from "./vuesax-outline-star.svg";
import { ReactComponent as DashDownStarIcon } from "./vuesax-outline-ranking.svg";
import { ReactComponent as MagicStar } from "./vuesax-outline-magic-star.svg";
import { ReactComponent as LoveEdit } from "./vuesax-outline-heart-edit.svg";
import { ReactComponent as LoveCheck } from "./vuesax-outline-heart-tick.svg";
import { ReactComponent as LoveSearch } from "./vuesax-outline-heart-search.svg";
import { ReactComponent as LoveCancel } from "./vuesax-outline-heart-slash.svg";
import { ReactComponent as LoveRemove } from "./vuesax-outline-heart-remove.svg";
import { ReactComponent as LoveAdd } from "./vuesax-outline-heart-add.svg";
import { ReactComponent as Smileys } from "./vuesax-outline-smileys.svg";
import { ReactComponent as LikeDislike } from "./vuesax-outline-like-dislike.svg";
import { ReactComponent as LikeTag } from "./vuesax-outline-like-tag.svg";
import { ReactComponent as TwentyFourSupport } from "./vuesax-outline-24-support.svg";
import { ReactComponent as LikeShape } from "./vuesax-outline-like-shapes.svg";
import { ReactComponent as QuestionBubble } from "./vuesax-outline-message-question.svg";
import { ReactComponent as PrizeIcon } from "./vuesax-outline-medal-star.svg";
import { ReactComponent as LovelyIcon } from "./vuesax-outline-lovely.svg";
import { ReactComponent as Medal } from "./vuesax-outline-medal.svg";
import { ReactComponent as Unlimited } from "./vuesax-outline-unlimited.svg";
import { ReactComponent as OutlineLike } from "./vuesax-outline-like.svg";
import { ReactComponent as LoveCircle } from "./vuesax-outline-heart-circle.svg";

//Notifications
import { ReactComponent as NotificationFill } from "./vuesax-outline-notification-bing.svg";
import { ReactComponent as NotificationIcon } from "./vuesax-outline-notification.svg";
import { ReactComponent as PendingNotificationSquare } from "./vuesax-outline-notification-square.svg";
import { ReactComponent as PendingNotificationFavourite } from "./vuesax-outline-notification-favorite.svg";
import { ReactComponent as PendingNotificationStatus } from "./vuesax-outline-notification-status.svg";
import { ReactComponent as PendingNotificationCircle } from "./vuesax-outline-notification-circle.svg";
import { ReactComponent as LightBulb } from "./vuesax-outline-lamp-on.svg";
import { ReactComponent as LightBulbCharge } from "./vuesax-outline-lamp-charge.svg";
import { ReactComponent as LightBulbSlash } from "./vuesax-outline-lamp-slash.svg";

//Settings
import { ReactComponent as Category } from "./vuesax-outline-category.svg";
import { ReactComponent as Category2 } from "./vuesax-outline-category-2.svg";
import { ReactComponent as Setting } from "./vuesax-outline-setting.svg";
import { ReactComponent as Settings } from "./vuesax-outline-setting-2.svg";
import { ReactComponent as Settings2 } from "./vuesax-outline-setting-3.svg";
import { ReactComponent as Settings3 } from "./vuesax-outline-setting-4.svg";
import { ReactComponent as Settings4 } from "./vuesax-outline-setting-5.svg";
import { ReactComponent as MoreIconDots } from "./vuesax-outline-more.svg";
import { ReactComponent as MoreIconDots2 } from "./vuesax-outline-more-2.svg";
import { ReactComponent as ToggleOff } from "./vuesax-outline-toggle-off.svg";
import { ReactComponent as ToggleOn } from "./vuesax-outline-toggle-on.svg";
import { ReactComponent as ToggleOffCircle } from "./vuesax-outline-toggle-off-circle.svg";
import { ReactComponent as ToggleOnCircle } from "./vuesax-outline-toggle-on-circle.svg";
import { ReactComponent as MenuDot } from "./vuesax-outline-menu.svg";
import { ReactComponent as OptionsIcon } from "./vuesax-outline-candle.svg";
import { ReactComponent as Option } from "./vuesax-outline-settings.svg";
import { ReactComponent as OptionsIcon2 } from "./vuesax-outline-candle-2.svg";

//Archive
import { ReactComponent as MarkCheck } from "./vuesax-outline-archive-tick.svg";
import { ReactComponent as ArchiveTag } from "./vuesax-outline-archive.svg";
import { ReactComponent as ArchiveTagCancel } from "./vuesax-outline-archive-slash.svg";
import { ReactComponent as ArchiveTagAdd } from "./vuesax-outline-archive-add.svg";
import { ReactComponent as ArchiveTagMinus } from "./vuesax-outline-archive-minus.svg";
import { ReactComponent as ReceiptSquare } from "./vuesax-outline-receipt-square.svg";
import { ReactComponent as BookSquare } from "./vuesax-outline-book-square.svg";
import { ReactComponent as SaveIcon } from "./vuesax-outline-save-2.svg";
import { ReactComponent as SaveAdd } from "./vuesax-outline-save-add.svg";
import { ReactComponent as SaveMinus } from "./vuesax-outline-save-minus.svg";
import { ReactComponent as SaveRemove } from "./vuesax-outline-save-remove.svg";
import { ReactComponent as BookSave } from "./vuesax-outline-book-saved.svg";

//Time
import { ReactComponent as Timer } from "./vuesax-outline-timer-2.svg";
import { ReactComponent as Clock } from "./vuesax-outline-clock.svg";
import { ReactComponent as Timer2 } from "./vuesax-outline-timer.svg";
import { ReactComponent as TimerPause } from "./vuesax-outline-timer-pause.svg";
import { ReactComponent as TimerPlay } from "./vuesax-outline-timer-start.svg";
import { ReactComponent as TimeSecurity } from "./vuesax-outline-security-time.svg";
import { ReactComponent as Calendar } from "./vuesax-outline-calendar.svg";
import { ReactComponent as CalendarCheck } from "./vuesax-outline-calendar-tick.svg";
import { ReactComponent as CalendarSearch } from "./vuesax-outline-calendar-search.svg";
import { ReactComponent as CalendarRemove } from "./vuesax-outline-calendar-remove.svg";
import { ReactComponent as CalendarAdd } from "./vuesax-outline-calendar-add.svg";
import { ReactComponent as CalendarEdit } from "./vuesax-outline-calendar-edit.svg";
import { ReactComponent as Calendar2 } from "./vuesax-outline-calendar-2.svg";
import { ReactComponent as CalendarCircle } from "./vuesax-outline-calendar-circle.svg";
import { ReactComponent as Calendar3 } from "./vuesax-outline-calendar-date.svg";

//Computers, Devices, Electronics
import { ReactComponent as Printer } from "./vuesax-outline-printer.svg";
import { ReactComponent as Chip } from "./vuesax-outline-cpu.svg";
import { ReactComponent as PrinterCancel } from "./vuesax-outline-printer-slash.svg";
import { ReactComponent as ChipCharge } from "./vuesax-outline-cpu-charge.svg";
import { ReactComponent as ChipSettings } from "./vuesax-outline-cpu-setting.svg";
import { ReactComponent as Monitor } from "./vuesax-outline-monitor.svg";
import { ReactComponent as MonitorRecorder } from "./vuesax-outline-monitor-recorder.svg";
import { ReactComponent as MonitorMobile } from "./vuesax-outline-monitor-mobbile.svg";
import { ReactComponent as Headphones } from "./vuesax-outline-headphone.svg";
import { ReactComponent as Airpod } from "./vuesax-outline-airpod.svg";
import { ReactComponent as Speaker } from "./vuesax-outline-speaker.svg";
import { ReactComponent as Driver } from "./vuesax-outline-driver.svg";
import { ReactComponent as Driver2 } from "./vuesax-outline-driver-2.svg";
import { ReactComponent as DriverRefresh } from "./vuesax-outline-driver-refresh.svg";
import { ReactComponent as CloudAdd } from "./vuesax-outline-cloud-add.svg";
import { ReactComponent as CloudRemove } from "./vuesax-outline-cloud-remove.svg";
import { ReactComponent as ExternalDrive } from "./vuesax-outline-external-drive.svg";
import { ReactComponent as CloudConnection } from "./vuesax-outline-cloud-connection.svg";
import { ReactComponent as HeadphonesPlay } from "./vuesax-outline-music-play.svg";
import { ReactComponent as Devices2 } from "./vuesax-outline-devices.svg";
import { ReactComponent as Airpods } from "./vuesax-outline-airpods.svg";
import { ReactComponent as CableHeadphones } from "./vuesax-outline-headphones.svg";
import { ReactComponent as FolderConnection } from "./vuesax-outline-folder-connection.svg";
import { ReactComponent as GameControl } from "./vuesax-outline-game.svg";
import { ReactComponent as WeightControl } from "./vuesax-outline-weight-2.svg";
import { ReactComponent as Keyboard } from "./vuesax-outline-keyboard.svg";
import { ReactComponent as Mouse } from "./vuesax-outline-mouse.svg";
import { ReactComponent as Watch } from "./vuesax-outline-watch.svg";
import { ReactComponent as Ram } from "./vuesax-outline-ram.svg";
import { ReactComponent as Ram2 } from "./vuesax-outline-ram-2.svg";
import { ReactComponent as SimCard } from "./vuesax-outline-simcard.svg";
import { ReactComponent as SimCard2 } from "./vuesax-outline-simcard-2.svg";
import { ReactComponent as Desk } from "./vuesax-outline-desk.svg";
import { ReactComponent as Mobile } from "./vuesax-outline-mobile.svg";
import { ReactComponent as Plug } from "./vuesax-outline-electricity.svg";
import { ReactComponent as ScreenMirroring } from "./vuesax-outline-mirroring-screen.svg";
import { ReactComponent as Telescope } from "./vuesax-outline-telescope.svg";
import { ReactComponent as SimCard3 } from "./vuesax-outline-simcard.svg";
import { ReactComponent as OpenKeyboard } from "./vuesax-outline-keyboard-open.svg";
import { ReactComponent as AirDrop } from "./vuesax-outline-airdrop.svg";
import { ReactComponent as GameBoy } from "./vuesax-outline-gameboy.svg";
import { ReactComponent as Watch2 } from "./vuesax-outline-watch-square.svg";
import { ReactComponent as WatchStatus } from "./vuesax-outline-watch-status.svg";
import { ReactComponent as Bluetooth } from "./vuesax-outline-bluetooth.svg";
import { ReactComponent as Bluetooth2 } from "./vuesax-outline-bluetooth-2.svg";
import { ReactComponent as BluetoothCircle } from "./vuesax-outline-bluetooth-circle.svg";
import { ReactComponent as BluetoothSquare } from "./vuesax-outline-bluetooth-rectangle.svg";
import { ReactComponent as CloudChange } from "./vuesax-outline-cloud-change.svg";

//Call
import { ReactComponent as Call } from "./vuesax-outline-call.svg";
import { ReactComponent as Calling } from "./vuesax-outline-call-calling.svg";
import { ReactComponent as IncomingCall } from "./vuesax-outline-call-incoming.svg";
import { ReactComponent as OutgoingCall } from "./vuesax-outline-call-outgoing.svg";
import { ReactComponent as CallAdd } from "./vuesax-outline-call-add.svg";
import { ReactComponent as CallMinus } from "./vuesax-outline-call-minus.svg";
import { ReactComponent as CallRemove } from "./vuesax-outline-call-remove.svg";
import { ReactComponent as CallReceived } from "./vuesax-outline-call-received.svg";
import { ReactComponent as CallCancel } from "./vuesax-outline-call-slash.svg";

//Business
import { ReactComponent as ChartSuccess } from "./vuesax-outline-chart-success.svg";
import { ReactComponent as ChartFail } from "./vuesax-outline-chart-fail.svg";
import { ReactComponent as ChartSquare } from "./vuesax-outline-chart-2.svg";
import { ReactComponent as PieChart } from "./vuesax-outline-graph.svg";
import { ReactComponent as Activity } from "./vuesax-outline-activity.svg";
import { ReactComponent as HomeTrending } from "./vuesax-outline-home-trend-up.svg";
import { ReactComponent as HomeTrendingDown } from "./vuesax-outline-home-trend-down.svg";
import { ReactComponent as HomeHash } from "./vuesax-outline-home-hashtag.svg";
import { ReactComponent as TrendUp } from "./vuesax-outline-trend-up.svg";
import { ReactComponent as TrendDown } from "./vuesax-outline-trend-down.svg";
import { ReactComponent as HashSquare } from "./vuesax-outline-hashtag.svg";
import { ReactComponent as OutlineChart } from "./vuesax-outline-chart.svg";
import { ReactComponent as PersonalId } from "./vuesax-outline-personalcard.svg";
import { ReactComponent as StatusUp } from "./vuesax-outline-status-up.svg";
import { ReactComponent as LineChart } from "./vuesax-outline-diagram.svg";
import { ReactComponent as Presentation } from "./vuesax-outline-presention-chart.svg";
import { ReactComponent as BarChart } from "./vuesax-outline-chart-3.svg";
import { ReactComponent as Health } from "./vuesax-outline-health.svg";
import { ReactComponent as BarChart2 } from "./vuesax-outline-chart-4.svg";
import { ReactComponent as FavouriteChart } from "./vuesax-outline-favorite-chart.svg";

//Building
import { ReactComponent as Building } from "./vuesax-outline-building.svg";
import { ReactComponent as CourtHouse } from "./vuesax-outline-courthouse.svg";
import { ReactComponent as House } from "./vuesax-outline-house.svg";
import { ReactComponent as House2 } from "./vuesax-outline-house-2.svg";
import { ReactComponent as Buildings } from "./vuesax-outline-buildings.svg";
import { ReactComponent as Bank } from "./vuesax-outline-bank.svg";
import { ReactComponent as Buildings2 } from "./vuesax-outline-buildings-2.svg";
import { ReactComponent as Building2 } from "./vuesax-outline-building-3.svg";
import { ReactComponent as Building3 } from "./vuesax-outline-building-4.svg";
import { ReactComponent as Building4 } from "./vuesax-outline-buliding-2.svg";
import { ReactComponent as Hospital } from "./vuesax-outline-hospital.svg";

//Astrology
import { ReactComponent as Aquarius } from "./vuesax-outline-aquarius.svg";
import { ReactComponent as Gemini } from "./vuesax-outline-gemini.svg";
import { ReactComponent as Gemini2 } from "./vuesax-outline-gemini-2.svg";
import { ReactComponent as Sagittarius } from "./vuesax-outline-sagittarius.svg";
import { ReactComponent as Male } from "./vuesax-outline-man.svg";
import { ReactComponent as Female } from "./vuesax-outline-woman.svg";

//Search
import { ReactComponent as SearchIcon } from "./vuesax-outline-search-normal.svg";
import { ReactComponent as ZoomIn } from "./vuesax-outline-search-zoom-in.svg";
import { ReactComponent as ZoomOut } from "./vuesax-outline-search-zoom-out.svg";
import { ReactComponent as SearchFavourite } from "./vuesax-outline-search-favorite.svg";
import { ReactComponent as SearchStatus } from "./vuesax-outline-search-status.svg";
import { ReactComponent as SearchIcon2 } from "./vuesax-outline-search-normal-2.svg";
import { ReactComponent as ZoomIn2 } from "./vuesax-outline-search-zoom-in-2.svg";
import { ReactComponent as ZoomOut2 } from "./vuesax-outline-search-zoom-out-2.svg";
import { ReactComponent as SearchFavourite2 } from "./vuesax-outline-search-favorite-2.svg";
import { ReactComponent as SearchStatus2 } from "./vuesax-outline-search-status-2.svg";

/********************************
 ********************************
 ********************************
 ********************************
            BOLD ICONS
 ********************************
 ********************************
 ********************************
 ********************************/

//Support, Like, question
import { ReactComponent as StarIconBold } from "./vuesax-bold-star.svg";
import { ReactComponent as StarCancelIconBold } from "./vuesax-bold-star-slash.svg";
import { ReactComponent as LoveIconBold } from "./vuesax-bold-heart.svg";
import { ReactComponent as LikeIconBold } from "./vuesax-bold-like.svg";
import { ReactComponent as DisLikeIconBold } from "./vuesax-bold-dislike.svg";
import { ReactComponent as DashStarIconBold } from "./vuesax-bold-star.svg";
import { ReactComponent as DashDownStarIconBold } from "./vuesax-bold-ranking.svg";
import { ReactComponent as MagicStarBold } from "./vuesax-bold-magic-star.svg";
import { ReactComponent as LoveEditBold } from "./vuesax-bold-heart-edit.svg";
import { ReactComponent as LoveCheckBold } from "./vuesax-bold-heart-tick.svg";
import { ReactComponent as LoveSearchBold } from "./vuesax-bold-heart-search.svg";
import { ReactComponent as LoveCancelBold } from "./vuesax-bold-heart-slash.svg";
import { ReactComponent as LoveRemoveBold } from "./vuesax-bold-heart-remove.svg";
import { ReactComponent as LoveAddBold } from "./vuesax-bold-heart-add.svg";
import { ReactComponent as SmileysBold } from "./vuesax-bold-smileys.svg";
import { ReactComponent as LikeDislikeBold } from "./vuesax-bold-like-dislike.svg";
import { ReactComponent as LikeTagBold } from "./vuesax-bold-like-tag.svg";
import { ReactComponent as TwentyFourSupportBold } from "./vuesax-bold-24-support.svg";
import { ReactComponent as LikeShapeBold } from "./vuesax-bold-like-shapes.svg";
import { ReactComponent as QuestionBubbleBold } from "./vuesax-bold-message-question.svg";
import { ReactComponent as PrizeIconBold } from "./vuesax-bold-medal-star.svg";
import { ReactComponent as LovelyIconBold } from "./vuesax-bold-lovely.svg";
import { ReactComponent as MedalBold } from "./vuesax-bold-medal.svg";
import { ReactComponent as UnlimitedBold } from "./vuesax-bold-unlimited.svg";
import { ReactComponent as BoldLike } from "./vuesax-bold-like.svg";
import { ReactComponent as LoveCircleBold } from "./vuesax-bold-heart-circle.svg";

//Notifications
import { ReactComponent as NotificationFillBold } from "./vuesax-bold-notification-bing.svg";
import { ReactComponent as NotificationIconBold } from "./vuesax-bold-notification.svg";
import { ReactComponent as PendingNotificationSquareBold } from "./vuesax-bold-notification-square.svg";
import { ReactComponent as PendingNotificationFavouriteBold } from "./vuesax-bold-notification-favorite.svg";
import { ReactComponent as PendingNotificationStatusBold } from "./vuesax-bold-notification-status.svg";
import { ReactComponent as PendingNotificationCircleBold } from "./vuesax-bold-notification-circle.svg";
import { ReactComponent as LightBulbBold } from "./vuesax-bold-lamp-on.svg";
import { ReactComponent as LightBulbChargeBold } from "./vuesax-bold-lamp-charge.svg";
import { ReactComponent as LightBulbSlashBold } from "./vuesax-bold-lamp-slash.svg";

//Settings
import { ReactComponent as CategoryBold } from "./vuesax-bold-category.svg";
import { ReactComponent as Category2Bold } from "./vuesax-bold-category-2.svg";
import { ReactComponent as SettingBold } from "./vuesax-bold-setting.svg";
import { ReactComponent as SettingsBold } from "./vuesax-bold-setting-2.svg";
import { ReactComponent as Settings2Bold } from "./vuesax-bold-setting-3.svg";
import { ReactComponent as Settings3Bold } from "./vuesax-bold-setting-4.svg";
import { ReactComponent as Settings4Bold } from "./vuesax-bold-setting-5.svg";
import { ReactComponent as MoreIconDotsBold } from "./vuesax-bold-more.svg";
import { ReactComponent as MoreIconDots2Bold } from "./vuesax-bold-more-2.svg";
import { ReactComponent as ToggleOffBold } from "./vuesax-bold-toggle-off.svg";
import { ReactComponent as ToggleOnBold } from "./vuesax-bold-toggle-on.svg";
import { ReactComponent as ToggleOffCircleBold } from "./vuesax-bold-toggle-off-circle.svg";
import { ReactComponent as ToggleOnCircleBold } from "./vuesax-bold-toggle-on-circle.svg";
import { ReactComponent as MenuDotBold } from "./vuesax-bold-menu.svg";
import { ReactComponent as OptionsIconBold } from "./vuesax-bold-candle.svg";
import { ReactComponent as OptionBold } from "./vuesax-bold-settings.svg";
import { ReactComponent as OptionsIcon2Bold } from "./vuesax-bold-candle-2.svg";

//Archive
import { ReactComponent as MarkCheckBold } from "./vuesax-bold-archive-tick.svg";
import { ReactComponent as ArchiveTagBold } from "./vuesax-bold-archive.svg";
import { ReactComponent as ArchiveTagCancelBold } from "./vuesax-bold-archive-slash.svg";
import { ReactComponent as ArchiveTagAddBold } from "./vuesax-bold-archive-add.svg";
import { ReactComponent as ArchiveTagMinusBold } from "./vuesax-bold-archive-minus.svg";
import { ReactComponent as ReceiptSquareBold } from "./vuesax-bold-receipt-square.svg";
import { ReactComponent as BookSquareBold } from "./vuesax-bold-book-square.svg";
import { ReactComponent as SaveIconBold } from "./vuesax-bold-save-2.svg";
import { ReactComponent as SaveAddBold } from "./vuesax-bold-save-add.svg";
import { ReactComponent as SaveMinusBold } from "./vuesax-bold-save-minus.svg";
import { ReactComponent as SaveRemoveBold } from "./vuesax-bold-save-remove.svg";
import { ReactComponent as BookSaveBold } from "./vuesax-bold-book-saved.svg";

//Time
import { ReactComponent as TimerBold } from "./vuesax-bold-timer-2.svg";
import { ReactComponent as ClockBold } from "./vuesax-bold-clock.svg";
import { ReactComponent as Timer2Bold } from "./vuesax-bold-timer.svg";
import { ReactComponent as TimerPauseBold } from "./vuesax-bold-timer-pause.svg";
import { ReactComponent as TimerPlayBold } from "./vuesax-bold-timer-start.svg";
import { ReactComponent as TimeSecurityBold } from "./vuesax-bold-security-time.svg";
import { ReactComponent as CalendarBold } from "./vuesax-bold-calendar.svg";
import { ReactComponent as CalendarCheckBold } from "./vuesax-bold-calendar-tick.svg";
import { ReactComponent as CalendarSearchBold } from "./vuesax-bold-calendar-search.svg";
import { ReactComponent as CalendarRemoveBold } from "./vuesax-bold-calendar-remove.svg";
import { ReactComponent as CalendarAddBold } from "./vuesax-bold-calendar-add.svg";
import { ReactComponent as CalendarEditBold } from "./vuesax-bold-calendar-edit.svg";
import { ReactComponent as Calendar2Bold } from "./vuesax-bold-calendar-2.svg";
import { ReactComponent as CalendarCircleBold } from "./vuesax-bold-calendar-circle.svg";
import { ReactComponent as Calendar3Bold } from "./vuesax-bold-calendar-date.svg";

//Computers, Devices, Electronics
import { ReactComponent as PrinterBold } from "./vuesax-bold-printer.svg";
import { ReactComponent as ChipBold } from "./vuesax-bold-cpu.svg";
import { ReactComponent as PrinterCancelBold } from "./vuesax-bold-printer-slash.svg";
import { ReactComponent as ChipChargeBold } from "./vuesax-bold-cpu-charge.svg";
import { ReactComponent as ChipSettingsBold } from "./vuesax-bold-cpu-setting.svg";
import { ReactComponent as MonitorBold } from "./vuesax-bold-monitor.svg";
import { ReactComponent as MonitorRecorderBold } from "./vuesax-bold-monitor-recorder.svg";
import { ReactComponent as MonitorMobileBold } from "./vuesax-bold-monitor-mobbile.svg";
import { ReactComponent as HeadphonesBold } from "./vuesax-bold-headphone.svg";
import { ReactComponent as AirpodBold } from "./vuesax-bold-airpod.svg";
import { ReactComponent as SpeakerBold } from "./vuesax-bold-speaker.svg";
import { ReactComponent as DriverBold } from "./vuesax-bold-driver.svg";
import { ReactComponent as Driver2Bold } from "./vuesax-bold-driver-2.svg";
import { ReactComponent as DriverRefreshBold } from "./vuesax-bold-driver-refresh.svg";
import { ReactComponent as CloudAddBold } from "./vuesax-bold-cloud-add.svg";
import { ReactComponent as CloudRemoveBold } from "./vuesax-bold-cloud-remove.svg";
import { ReactComponent as ExternalDriveBold } from "./vuesax-bold-external-drive.svg";
import { ReactComponent as CloudConnectionBold } from "./vuesax-bold-cloud-connection.svg";
import { ReactComponent as HeadphonesPlayBold } from "./vuesax-bold-music-play.svg";
import { ReactComponent as Devices2Bold } from "./vuesax-bold-devices.svg";
import { ReactComponent as AirpodsBold } from "./vuesax-bold-airpods.svg";
import { ReactComponent as CableHeadphonesBold } from "./vuesax-bold-headphones.svg";
import { ReactComponent as FolderConnectionBold } from "./vuesax-bold-folder-connection.svg";
import { ReactComponent as GameControlBold } from "./vuesax-bold-game.svg";
import { ReactComponent as WeightControlBold } from "./vuesax-bold-weight-2.svg";
import { ReactComponent as KeyboardBold } from "./vuesax-bold-keyboard.svg";
import { ReactComponent as MouseBold } from "./vuesax-bold-mouse.svg";
import { ReactComponent as WatchBold } from "./vuesax-bold-watch.svg";
import { ReactComponent as RamBold } from "./vuesax-bold-ram.svg";
import { ReactComponent as Ram2Bold } from "./vuesax-bold-ram-2.svg";
import { ReactComponent as SimCardBold } from "./vuesax-bold-simcard.svg";
import { ReactComponent as SimCard2Bold } from "./vuesax-bold-simcard-2.svg";
import { ReactComponent as DeskBold } from "./vuesax-bold-desk.svg";
import { ReactComponent as MobileBold } from "./vuesax-bold-mobile.svg";
import { ReactComponent as PlugBold } from "./vuesax-bold-electricity.svg";
import { ReactComponent as ScreenMirroringBold } from "./vuesax-bold-mirroring-screen.svg";
import { ReactComponent as TelescopeBold } from "./vuesax-bold-telescope.svg";
import { ReactComponent as SimCard3Bold } from "./vuesax-bold-simcard.svg";
import { ReactComponent as OpenKeyboardBold } from "./vuesax-bold-keyboard-open.svg";
import { ReactComponent as AirDropBold } from "./vuesax-bold-airdrop.svg";
import { ReactComponent as GameBoyBold } from "./vuesax-bold-gameboy.svg";
import { ReactComponent as Watch2Bold } from "./vuesax-bold-watch-square.svg";
import { ReactComponent as WatchStatusBold } from "./vuesax-bold-watch-status.svg";
import { ReactComponent as BluetoothBold } from "./vuesax-bold-bluetooth.svg";
import { ReactComponent as Bluetooth2Bold } from "./vuesax-bold-bluetooth-2.svg";
import { ReactComponent as BluetoothCircleBold } from "./vuesax-bold-bluetooth-circle.svg";
import { ReactComponent as BluetoothSquareBold } from "./vuesax-bold-bluetooth-rectangle.svg";
import { ReactComponent as CloudChangeBold } from "./vuesax-bold-cloud-change.svg";

//Call
import { ReactComponent as CallBold } from "./vuesax-bold-call.svg";
import { ReactComponent as CallingBold } from "./vuesax-bold-call-calling.svg";
import { ReactComponent as IncomingCallBold } from "./vuesax-bold-call-incoming.svg";
import { ReactComponent as OutgoingCallBold } from "./vuesax-bold-call-outgoing.svg";
import { ReactComponent as CallAddBold } from "./vuesax-bold-call-add.svg";
import { ReactComponent as CallMinusBold } from "./vuesax-bold-call-minus.svg";
import { ReactComponent as CallRemoveBold } from "./vuesax-bold-call-remove.svg";
import { ReactComponent as CallReceivedBold } from "./vuesax-bold-call-received.svg";
import { ReactComponent as CallCancelBold } from "./vuesax-bold-call-slash.svg";

//Business
import { ReactComponent as ChartSuccessBold } from "./vuesax-bold-chart-success.svg";
import { ReactComponent as ChartFailBold } from "./vuesax-bold-chart-fail.svg";
import { ReactComponent as ChartSquareBold } from "./vuesax-bold-chart-2.svg";
import { ReactComponent as PieChartBold } from "./vuesax-bold-graph.svg";
import { ReactComponent as ActivityBold } from "./vuesax-bold-activity.svg";
import { ReactComponent as HomeTrendingBold } from "./vuesax-bold-home-trend-up.svg";
import { ReactComponent as HomeTrendingDownBold } from "./vuesax-bold-home-trend-down.svg";
import { ReactComponent as HomeHashBold } from "./vuesax-bold-home-hashtag.svg";
import { ReactComponent as TrendUpBold } from "./vuesax-bold-trend-up.svg";
import { ReactComponent as TrendDownBold } from "./vuesax-bold-trend-down.svg";
import { ReactComponent as HashSquareBold } from "./vuesax-bold-hashtag.svg";
import { ReactComponent as BoldChartBusiness } from "./vuesax-bold-chart.svg";
import { ReactComponent as PersonalIdBold } from "./vuesax-bold-personalcard.svg";
import { ReactComponent as StatusUpBold } from "./vuesax-bold-status-up.svg";
import { ReactComponent as LineChartBold } from "./vuesax-bold-diagram.svg";
import { ReactComponent as PresentationBold } from "./vuesax-bold-presention-chart.svg";
import { ReactComponent as BarChartBold } from "./vuesax-bold-chart-3.svg";
import { ReactComponent as HealthBold } from "./vuesax-bold-health.svg";
import { ReactComponent as BarChart2Bold } from "./vuesax-bold-chart-4.svg";
import { ReactComponent as FavouriteChartBold } from "./vuesax-bold-favorite-chart.svg";

//Building
import { ReactComponent as BuildingBold } from "./vuesax-bold-building.svg";
import { ReactComponent as CourtHouseBold } from "./vuesax-bold-courthouse.svg";
import { ReactComponent as HouseBold } from "./vuesax-bold-house.svg";
import { ReactComponent as House2Bold } from "./vuesax-bold-house-2.svg";
import { ReactComponent as BuildingsBold } from "./vuesax-bold-buildings.svg";
import { ReactComponent as BankBold } from "./vuesax-bold-bank.svg";
import { ReactComponent as Buildings2Bold } from "./vuesax-bold-buildings-2.svg";
import { ReactComponent as Building2Bold } from "./vuesax-bold-building-3.svg";
import { ReactComponent as Building3Bold } from "./vuesax-bold-building-4.svg";
import { ReactComponent as Building4Bold } from "./vuesax-bold-buliding-2.svg";
import { ReactComponent as HospitalBold } from "./vuesax-bold-hospital.svg";

//Astrology
import { ReactComponent as AquariusBold } from "./vuesax-bold-aquarius.svg";
import { ReactComponent as GeminiBold } from "./vuesax-bold-gemini.svg";
import { ReactComponent as Gemini2Bold } from "./vuesax-bold-gemini-2.svg";
import { ReactComponent as SagittariusBold } from "./vuesax-bold-sagittarius.svg";
import { ReactComponent as MaleBold } from "./vuesax-bold-man.svg";
import { ReactComponent as FemaleBold } from "./vuesax-bold-woman.svg";

//Search
import { ReactComponent as SearchIconBold } from "./vuesax-bold-search-normal.svg";
import { ReactComponent as ZoomInBold } from "./vuesax-bold-search-zoom-in.svg";
import { ReactComponent as ZoomOutBold } from "./vuesax-bold-search-zoom-out.svg";
import { ReactComponent as SearchFavouriteBold } from "./vuesax-bold-search-favorite.svg";
import { ReactComponent as SearchStatusBold } from "./vuesax-bold-search-status.svg";
import { ReactComponent as SearchIcon2Bold } from "./vuesax-bold-search-normal-2.svg";
import { ReactComponent as ZoomIn2Bold } from "./vuesax-bold-search-zoom-in-2.svg";
import { ReactComponent as ZoomOut2Bold } from "./vuesax-bold-search-zoom-out-2.svg";
import { ReactComponent as SearchFavourite2Bold } from "./vuesax-bold-search-favorite-2.svg";
import { ReactComponent as SearchStatus2Bold } from "./vuesax-bold-search-status-2.svg";

//Emails, Messages
import { ReactComponent as OutlineDirectboxDeafault } from "./vuesax-outline-directbox-default.svg";
import { ReactComponent as OutlineDirectboxNotif } from "./vuesax-outline-directbox-notif.svg";
import { ReactComponent as OutlineDirectboxReceive } from "./vuesax-outline-directbox-receive.svg";
import { ReactComponent as OutlineDirectboxSend } from "./vuesax-outline-directbox-send.svg";
import { ReactComponent as OutlineDirectNormal } from "./vuesax-outline-direct-normal.svg";
import { ReactComponent as OutlineDirectDirect } from "./vuesax-outline-direct.svg";
import { ReactComponent as OutlineDirectNotification } from "./vuesax-outline-direct-notification.svg";
import { ReactComponent as OutlineDirectInbox } from "./vuesax-outline-direct-inbox.svg";
import { ReactComponent as OutlineDirectSend } from "./vuesax-outline-direct-send.svg";
import { ReactComponent as OutlineMessage } from "./vuesax-outline-message.svg";
import { ReactComponent as OutlineMessageFavorite } from "./vuesax-outline-message-favorite.svg";
import { ReactComponent as OutlineMessageNotif } from "./vuesax-outline-message-notif.svg";
import { ReactComponent as OutlineMessageSearch } from "./vuesax-outline-message-search.svg";
import { ReactComponent as OutlineMessageText } from "./vuesax-outline-message-text.svg";
import { ReactComponent as OutlineDeviceMessage } from "./vuesax-outline-device-message.svg";
import { ReactComponent as OutlineMessages } from "./vuesax-outline-messages.svg";
import { ReactComponent as OutlineMessages2 } from "./vuesax-outline-messages-2.svg";
import { ReactComponent as OutlineMessages3 } from "./vuesax-outline-messages-3.svg";
import { ReactComponent as OutlineSms } from "./vuesax-outline-sms.svg";
import { ReactComponent as OutlineSmsTracking } from "./vuesax-outline-sms-tracking.svg";
import { ReactComponent as OutlineSmsNotification } from "./vuesax-outline-sms-notification.svg";
import { ReactComponent as OutlineSmsEdit } from "./vuesax-outline-sms-edit.svg";
import { ReactComponent as OutlineSmsStar } from "./vuesax-outline-sms-star.svg";
import { ReactComponent as OutlineSmsSearch } from "./vuesax-outline-sms-search.svg";
import { ReactComponent as OutlineMessageRemove } from "./vuesax-outline-message-remove.svg";
import { ReactComponent as OutlineMessageAdd } from "./vuesax-outline-message-add.svg";
import { ReactComponent as OutlineMessageTick } from "./vuesax-outline-message-tick.svg";
import { ReactComponent as OutlineMessageTime } from "./vuesax-outline-message-time.svg";
import { ReactComponent as OutlineMessage4 } from "./vuesax-outline-message-2.svg";
import { ReactComponent as OutlineMessages4 } from "./vuesax-outline-messages.svg";
import { ReactComponent as OutlineMessageCircle } from "./vuesax-outline-message-circle.svg";
import { ReactComponent as OutlineMessageSquare } from "./vuesax-outline-message-square.svg";
import { ReactComponent as OutlineMessageText2 } from "./vuesax-outline-message-text.svg";
import { ReactComponent as OutlineMessageMinus } from "./vuesax-outline-message-minus.svg";
import { ReactComponent as OutlineMessageAdd2 } from "./vuesax-outline-message-add.svg";
import { ReactComponent as OutlineMessageEdit } from "./vuesax-outline-message-edit.svg";

//Location
import { ReactComponent as OutlineLocationMinus } from "./vuesax-outline-location-minus.svg";
import { ReactComponent as OutlineLocationCross } from "./vuesax-outline-location-cross.svg";
import { ReactComponent as OutlineLocationTick } from "./vuesax-outline-location-tick.svg";
import { ReactComponent as OutlineLocationSlash } from "./vuesax-outline-location-slash.svg";
import { ReactComponent as OutlineDiscover } from "./vuesax-outline-discover.svg";
import { ReactComponent as OutlineLocation } from "./vuesax-outline-location.svg";
import { ReactComponent as OutlineLocationAdd } from "./vuesax-outline-location-add.svg";
import { ReactComponent as OutlineRadar } from "./vuesax-outline-radar.svg";
import { ReactComponent as OutlineRadar2 } from "./vuesax-outline-radar-2.svg";
import { ReactComponent as OutlineGlobalSearch } from "./vuesax-outline-global-search.svg";
import { ReactComponent as OutlineGlobalEdit } from "./vuesax-outline-global-edit.svg";
import { ReactComponent as OutlineGlobalRefresh } from "./vuesax-outline-global-refresh.svg";
import { ReactComponent as OutlineGlobal } from "./vuesax-outline-global.svg";
import { ReactComponent as OutlineDirectUp } from "./vuesax-outline-direct-up.svg";
import { ReactComponent as OutlineDirectDown } from "./vuesax-outline-direct-down.svg";
import { ReactComponent as OutlineDirectLeft } from "./vuesax-outline-direct-left.svg";
import { ReactComponent as OutlineDirectRight } from "./vuesax-outline-direct-right.svg";
import { ReactComponent as OutlineMap } from "./vuesax-outline-map.svg";
import { ReactComponent as OutlineGps } from "./vuesax-outline-gps.svg";
import { ReactComponent as OutlineGpsSlash } from "./vuesax-outline-gps-slash.svg";
import { ReactComponent as OutlineMap2 } from "./vuesax-outline-map.svg";
import { ReactComponent as OutlineRouting } from "./vuesax-outline-routing.svg";
import { ReactComponent as OutlineRouting2 } from "./vuesax-outline-routing-2.svg";
import { ReactComponent as OutlineArrowSquare } from "./vuesax-outline-arrow-square.svg";
import { ReactComponent as OutlinePictureFrame } from "./vuesax-outline-picture-frame.svg";
import { ReactComponent as OutlineArrow } from "./vuesax-outline-arrow.svg";
import { ReactComponent as OutlineRouteSquare } from "./vuesax-outline-route-square.svg";

//School, Learning
import { ReactComponent as OutlineBriefcase } from "./vuesax-outline-briefcase.svg";
import { ReactComponent as OutlineBrifecaseTimer } from "./vuesax-outline-brifecase-timer.svg";
import { ReactComponent as OutlineBriefcaseTick } from "./vuesax-outline-brifecase-tick.svg";
import { ReactComponent as OutlineBriefcaseCross } from "./vuesax-outline-brifecase-cross.svg";
import { ReactComponent as OutlineBriefcaseBookmark } from "./vuesax-outline-bookmark.svg";
import { ReactComponent as OutlineAward } from "./vuesax-outline-award.svg";
import { ReactComponent as OutlineBook } from "./vuesax-outline-book.svg";
import { ReactComponent as OutlineBook2 } from "./vuesax-outline-book.svg";
import { ReactComponent as OutlineBookmark2 } from "./vuesax-outline-bookmark-2.svg";
import { ReactComponent as OutlineClipboard } from "./vuesax-outline-clipboard.svg";
import { ReactComponent as OutlineNote } from "./vuesax-outline-note.svg";
import { ReactComponent as OutlineNote2 } from "./vuesax-outline-note-2.svg";
import { ReactComponent as OutlineTeacher } from "./vuesax-outline-teacher.svg";
import { ReactComponent as OutlineGift } from "./vuesax-outline-gift.svg";
import { ReactComponent as OutlineCalculator } from "./vuesax-outline-calculator.svg";

//Delivery
import { ReactComponent as OutlineBox } from "./vuesax-outline-box.svg";
import { ReactComponent as Outline3dCubeScan } from "./vuesax-outline-3-d-cube-scan.svg";
import { ReactComponent as Outline3dCube } from "./vuesax-outline-convert-3-d-cube.svg";
import { ReactComponent as OutlineBoxAdd } from "./vuesax-outline-box-add.svg";
import { ReactComponent as OutlineBoxRemove } from "./vuesax-outline-box-remove.svg";
import { ReactComponent as OutlineBoxTick } from "./vuesax-outline-box-tick.svg";
import { ReactComponent as OutlineBoxTime } from "./vuesax-outline-box-time.svg";
import { ReactComponent as OutlineBox2 } from "./vuesax-outline-box.svg";
import { ReactComponent as OutlineBoxSearch } from "./vuesax-outline-box-search.svg";
import { ReactComponent as OutlineTruck } from "./vuesax-outline-truck.svg";
import { ReactComponent as OutlineTruckFast } from "./vuesax-outline-truck-fast.svg";
import { ReactComponent as OutlineTruckTick } from "./vuesax-outline-truck-tick.svg";
import { ReactComponent as OutlineTruckTime } from "./vuesax-outline-truck-time.svg";
import { ReactComponent as OutlineTruckRemove } from "./vuesax-outline-truck-remove.svg";
import { ReactComponent as Outline3dSquare } from "./vuesax-outline-3-d-square.svg";
import { ReactComponent as Outline3dRotate } from "./vuesax-outline-3-d-rotate.svg";

//Security
import { ReactComponent as OutlineEye } from "./vuesax-outline-eye.svg";
import { ReactComponent as OutlineEyeSlash } from "./vuesax-outline-eye-slash.svg";
import { ReactComponent as OutlineShield } from "./vuesax-outline-shield.svg";
import { ReactComponent as OutlineShieldTick } from "./vuesax-outline-shield-tick.svg";
import { ReactComponent as OutlineShieldCross } from "./vuesax-outline-shield-cross.svg";
import { ReactComponent as OutlineShieldSlash } from "./vuesax-outline-shield-slash.svg";
import { ReactComponent as OutlineFrame3 } from "./vuesax-outline-frame.svg";
import { ReactComponent as OutlinePasswordCheck } from "./vuesax-outline-password-check.svg";
import { ReactComponent as OutlineShieldSearch } from "./vuesax-outline-shield-search.svg";
import { ReactComponent as OutlineKey } from "./vuesax-outline-key.svg";
import { ReactComponent as OutlineKeySquare } from "./vuesax-outline-key-square.svg";
import { ReactComponent as OutlineCheck } from "./vuesax-outline-check.svg";
import { ReactComponent as OutlineLock } from "./vuesax-outline-lock.svg";
import { ReactComponent as OutlineUnlock } from "./vuesax-outline-unlock.svg";
import { ReactComponent as OutlineLockSlash } from "./vuesax-outline-lock-slash.svg";
import { ReactComponent as OutlineScan } from "./vuesax-outline-scan.svg";
import { ReactComponent as OutlineScanBarcode } from "./vuesax-outline-scan-barcode.svg";
import { ReactComponent as OutlineScanning } from "./vuesax-outline-scanning.svg";
import { ReactComponent as OutlineFingerScan } from "./vuesax-outline-finger-scan.svg";
import { ReactComponent as OutlineFingerCircle } from "./vuesax-outline-finger-cricle.svg";
import { ReactComponent as OutlineScanner } from "./vuesax-outline-scanner.svg";
import { ReactComponent as OutlineSecurityUser } from "./vuesax-outline-security-user.svg";
import { ReactComponent as OutlineLock2 } from "./vuesax-outline-lock.svg";
import { ReactComponent as OutlineLockCircle } from "./vuesax-outline-lock-circle.svg";
import { ReactComponent as OutlineAlarm } from "./vuesax-outline-alarm.svg";
import { ReactComponent as OutlineRadar3 } from "./vuesax-outline-radar.svg";
import { ReactComponent as OutlineSecuritySafe } from "./vuesax-outline-security-safe.svg";
import { ReactComponent as OutlineSecurity } from "./vuesax-outline-security.svg";

//Desing, tools
import { ReactComponent as OutlineBezier } from "./vuesax-outline-bezier.svg";
import { ReactComponent as OutlineBrush } from "./vuesax-outline-brush.svg";
import { ReactComponent as OutlineBrush2 } from "./vuesax-outline-brush.svg";
import { ReactComponent as OutlineMagicpen } from "./vuesax-outline-magicpen.svg";
import { ReactComponent as BrokenPenTool } from "./vuesax-broken-pen-tool.svg";
import { ReactComponent as BrokenBucket } from "./vuesax-broken-bucket.svg";
import { ReactComponent as OutlinePaintbucket } from "./vuesax-outline-paintbucket.svg";
import { ReactComponent as OutlineAdditem } from "./vuesax-outline-additem.svg";
import { ReactComponent as OutlineRuler } from "./vuesax-outline-ruler.svg";
import { ReactComponent as OutlineDesingtools } from "./vuesax-outline-designtools.svg";
import { ReactComponent as OutlineShapes } from "./vuesax-outline-shapes.svg";
import { ReactComponent as OutlineLifebuoy } from "./vuesax-outline-lifebuoy.svg";
import { ReactComponent as OutlinePenTool2 } from "./vuesax-outline-pen-tool-2.svg";
import { ReactComponent as OutlineBrush3 } from "./vuesax-outline-brush.svg";
import { ReactComponent as OutlinePenClose } from "./vuesax-outline-pen-close.svg";
import { ReactComponent as OutlinePenAdd } from "./vuesax-outline-pen-add.svg";
import { ReactComponent as OutlinePenRemove } from "./vuesax-outline-pen-remove.svg";
import { ReactComponent as OutlinePath } from "./vuesax-outline-path.svg";
import { ReactComponent as OutlinePath2 } from "./vuesax-outline-path-2.svg";
import { ReactComponent as OutlineBucketCircle } from "./vuesax-outline-bucket-circle.svg";
import { ReactComponent as OutlineBucketSquare } from "./vuesax-outline-bucket-square.svg";
import { ReactComponent as OutlineBrush4 } from "./vuesax-outline-brush.svg";
import { ReactComponent as OutlineBrokenScissor } from "./vuesax-broken-scissor.svg";
import { ReactComponent as OutlineGlass } from "./vuesax-outline-glass.svg";
import { ReactComponent as OutlineRulerpen } from "./vuesax-outline-ruler-pen.svg";
import { ReactComponent as BrokenColorSwatch } from "./vuesax-broken-color-swatch.svg";
import { ReactComponent as OutlineBlend2 } from "./vuesax-outline-blend-2.svg";
import { ReactComponent as OutlinePathSquare } from "./vuesax-outline-path-square.svg";
import { ReactComponent as OutlineOmegaCircle } from "./vuesax-outline-omega-circle.svg";
import { ReactComponent as OutlineOmegaSquare } from "./vuesax-outline-omega-square.svg";
import { ReactComponent as OutlineShapes2 } from "./vuesax-outline-shapes.svg";
import { ReactComponent as OutlineSize } from "./vuesax-outline-size.svg";
import { ReactComponent as OutlineFlashCircle } from "./vuesax-outline-flash-circle.svg";
import { ReactComponent as OutlineEraser } from "./vuesax-outline-eraser.svg";
import { ReactComponent as OutlineMask } from "./vuesax-outline-mask.svg";
import { ReactComponent as OutlineBlur } from "./vuesax-outline-blur.svg";
import { ReactComponent as OutlineBlend } from "./vuesax-outline-blend.svg";
import { ReactComponent as OutlineColorfilter } from "./vuesax-outline-colorfilter.svg";
import { ReactComponent as OutlineColorsSquare } from "./vuesax-outline-colors-square.svg";
import { ReactComponent as OutlineMask2 } from "./vuesax-outline-mask.svg";
import { ReactComponent as OutlineBrush5 } from "./vuesax-outline-brush.svg";
import { ReactComponent as OutlineLayer } from "./vuesax-outline-layer.svg";
import { ReactComponent as OutlineCopy } from "./vuesax-outline-copy.svg";
import { ReactComponent as OutlineCopySucces } from "./vuesax-outline-copy-success.svg";
import { ReactComponent as OutlineRecoveryConvert } from "./vuesax-outline-recovery-convert.svg";
import { ReactComponent as OutlineMainComponet } from "./vuesax-outline-main-component.svg";
import { ReactComponent as OutlineComponent } from "./vuesax-outline-component.svg";
import { ReactComponent as OutlineMask3 } from "./vuesax-outline-mask.svg";
import { ReactComponent as OutlineForwardItem } from "./vuesax-outline-forward-item.svg";
import { ReactComponent as OutlineBackwardItem } from "./vuesax-outline-backward-item.svg";

//Grid
import { ReactComponent as OutlineKanban } from "./vuesax-outline-kanban.svg";
import { ReactComponent as OutlineFatrows } from "./vuesax-outline-fatrows.svg";
import { ReactComponent as OutlineRowVertical } from "./vuesax-outline-row-vertical.svg";
import { ReactComponent as OutlineRowHorizontal } from "./vuesax-outline-row-horizontal.svg";
import { ReactComponent as OutlineSliderVertical } from "./vuesax-outline-slider-vertical.svg";
import { ReactComponent as OutlineSliderHorizontal } from "./vuesax-outline-slider-horizontal.svg";
import { ReactComponent as OutlineSliderVertical2 } from "./vuesax-outline-slider-vertical.svg";
import { ReactComponent as OutlineSliderHorizontal2 } from "./vuesax-outline-slider-horizontal.svg";
import { ReactComponent as OutlineGrid4 } from "./vuesax-outline-grid-4.svg";
import { ReactComponent as OutlineGrid9 } from "./vuesax-outline-grid-9.svg";
import { ReactComponent as OutlineGrid2 } from "./vuesax-outline-grid-2.svg";
import { ReactComponent as OutlineGrid3 } from "./vuesax-outline-grid-3.svg";
import { ReactComponent as OutlineGrid5 } from "./vuesax-outline-grid-5.svg";
import { ReactComponent as OutlineGrid6 } from "./vuesax-outline-grid-6.svg";
import { ReactComponent as OutlineGrid7 } from "./vuesax-outline-grid-7.svg";
import { ReactComponent as OutlineGrid8 } from "./vuesax-outline-grid-8.svg";
import { ReactComponent as OutlineGrid1 } from "./vuesax-outline-grid-1.svg";
import { ReactComponent as OutlineElement2 } from "./vuesax-outline-element-2.svg";
import { ReactComponent as OutlineFrame4 } from "./vuesax-outline-frame.svg";
import { ReactComponent as OutlineElement3 } from "./vuesax-outline-element-3.svg";
import { ReactComponent as OutlineElement4 } from "./vuesax-outline-element-4.svg";
import { ReactComponent as OutlineElementPlus } from "./vuesax-outline-element-plus.svg";
import { ReactComponent as OutlineElementEqual } from "./vuesax-outline-element-equal.svg";
import { ReactComponent as Outline3Square } from "./vuesax-outline-3-square.svg";
import { ReactComponent as OutlineAlingVertically } from "./vuesax-outline-align-vertically.svg";
import { ReactComponent as OutlineAlingHorizontally } from "./vuesax-outline-align-horizontally.svg";
import { ReactComponent as OutlineAlingLeft } from "./vuesax-outline-align-left.svg";
import { ReactComponent as OutlineAlingRight } from "./vuesax-outline-align-right.svg";
import { ReactComponent as OutlineAlingBottom } from "./vuesax-outline-align-bottom.svg";
import { ReactComponent as OutlineFrame5 } from "./vuesax-outline-frame.svg";
import { ReactComponent as OutlineMaximize } from "./vuesax-outline-maximize.svg";
import { ReactComponent as OutlineMaximize2 } from "./vuesax-outline-maximize-2.svg";
import { ReactComponent as OutlineMaximize3 } from "./vuesax-outline-maximize-3.svg";
import { ReactComponent as OutlineMaximize4 } from "./vuesax-outline-maximize-4.svg";
import { ReactComponent as OutlineFormatCircle } from "./vuesax-outline-format-circle.svg";
import { ReactComponent as OutlineFormatSquare } from "./vuesax-outline-format-square.svg";
import { ReactComponent as OutlineRotateRight2 } from "./vuesax-outline-rotate-right.svg";
import { ReactComponent as OutlineRotateLeft2 } from "./vuesax-outline-rotate-left.svg";
import { ReactComponent as OutlineCrop } from "./vuesax-outline-crop.svg";
import { ReactComponent as OutlineMaximize5 } from "./vuesax-outline-maximize.svg";
import { ReactComponent as OutlineConvertshape } from "./vuesax-outline-convertshape.svg";
import { ReactComponent as OutlineConvertshape2 } from "./vuesax-outline-convertshape-2.svg";
import { ReactComponent as OutlineGridEraser } from "./vuesax-outline-grid-eraser.svg";
import { ReactComponent as OutlineGridLock } from "./vuesax-outline-grid-lock.svg";
import { ReactComponent as OutlineGridEdit } from "./vuesax-outline-grid-edit.svg";

//Shop
import { ReactComponent as OutlineBag } from "./vuesax-outline-bag.svg";
import { ReactComponent as OutlineBagHappy } from "./vuesax-outline-bag-happy.svg";
import { ReactComponent as OutlineBagTick } from "./vuesax-outline-bag-tick.svg";
import { ReactComponent as OutlineBagCross } from "./vuesax-outline-bag-cross.svg";
import { ReactComponent as OutlineBagTimer } from "./vuesax-outline-bag-timer.svg";
import { ReactComponent as OutlineBagTick2 } from "./vuesax-outline-bag-tick-2.svg";
import { ReactComponent as OutlineBagCross2 } from "./vuesax-outline-bag-cross.svg";
import { ReactComponent as OutlineBag2 } from "./vuesax-outline-bag-2.svg";
import { ReactComponent as OutlineShoppingCart } from "./vuesax-outline-shopping-cart.svg";
import { ReactComponent as OutlineShppingBag } from "./vuesax-outline-shopping-bag.svg";
import { ReactComponent as OutlineShopAdd } from "./vuesax-outline-shop-add.svg";
import { ReactComponent as OutlineShopRemove } from "./vuesax-outline-shop-remove.svg";
import { ReactComponent as OutlineShop } from "./vuesax-outline-shop.svg";
import { ReactComponent as OutlineBarcode } from "./vuesax-outline-barcode.svg";

//Crypto Currency
import { ReactComponent as OutlineCardCoin } from "./vuesax-outline-card-coin.svg";
import { ReactComponent as OutlineTrade } from "./vuesax-outline-trade.svg";
import { ReactComponent as OutlineBuyCrypto } from "./vuesax-outline-buy-crypto.svg";
import { ReactComponent as OutlineBitcoinCard } from "./vuesax-outline-bitcoin-card.svg";
import { ReactComponent as OutlineBitcoinConvert } from "./vuesax-outline-bitcoin-convert.svg";
import { ReactComponent as OutlineBitcoinRefresh } from "./vuesax-outline-bitcoin-refresh.svg";
import { ReactComponent as OutlineWhatsapp } from "./vuesax-outline-whatsapp.svg";

//Content, edit
import { ReactComponent as OutlineDocument2 } from "./vuesax-outline-document.svg";
import { ReactComponent as OutlineDocumentText } from "./vuesax-outline-document-text.svg";
import { ReactComponent as OutlineDocumentFavorite } from "./vuesax-outline-document-favorite.svg";
import { ReactComponent as OutlineDocumentDownload } from "./vuesax-outline-document-download.svg";
import { ReactComponent as OutlineDocumentUpload } from "./vuesax-outline-document-upload.svg";
import { ReactComponent as OutlineDocumentForward } from "./vuesax-outline-document-forward.svg";
import { ReactComponent as OutlineDocumentPrevious } from "./vuesax-outline-document-previous.svg";
import { ReactComponent as OutlineDocumentSketch } from "./vuesax-outline-document-sketch.svg";
import { ReactComponent as OutlineDocumentCloud } from "./vuesax-outline-document-cloud.svg";
import { ReactComponent as OutlineDocumentCopy } from "./vuesax-outline-document-copy.svg";
import { ReactComponent as OutlineDocumentNormal } from "./vuesax-outline-document-normal.svg";
import { ReactComponent as OutlineClipboardText } from "./vuesax-outline-clipboard-text.svg";
import { ReactComponent as OutlineClipboardTick } from "./vuesax-outline-clipboard-tick.svg";
import { ReactComponent as OutlineClipboardClose } from "./vuesax-outline-clipboard-close.svg";
import { ReactComponent as OutlineClipboardExport } from "./vuesax-outline-clipboard-export.svg";
import { ReactComponent as OutlineCliprboardImport } from "./vuesax-outline-clipboard-import.svg";
import { ReactComponent as OutlineNote3 } from "./vuesax-outline-note.svg";
import { ReactComponent as OutlineDocumentText2 } from "./vuesax-outline-document-text.svg";
import { ReactComponent as OutlineDocumentLike } from "./vuesax-outline-document-like.svg";
import { ReactComponent as OutlineEdit } from "./vuesax-outline-edit.svg";
import { ReactComponent as OutlineEdit2 } from "./vuesax-outline-edit-2.svg";
import { ReactComponent as OutlineCopyright } from "./vuesax-outline-copyright.svg";
import { ReactComponent as OutlineCreativeCommos } from "./vuesax-outline-creative-commons.svg";
import { ReactComponent as OutlineNote4 } from "./vuesax-outline-note.svg";
import { ReactComponent as OutlineNoteRemove } from "./vuesax-outline-note-remove.svg";
import { ReactComponent as OutlineStickynote } from "./vuesax-outline-stickynote.svg";
import { ReactComponent as OutlineAdd } from "./vuesax-outline-note-add.svg";
import { ReactComponent as OutlineTask } from "./vuesax-outline-task.svg";
import { ReactComponent as OutlineTaskSquare } from "./vuesax-outline-task-square.svg";
import { ReactComponent as OutlineBill } from "./vuesax-outline-bill.svg";
import { ReactComponent as OutlineDocumentFilter } from "./vuesax-outline-document-filter.svg";
import { ReactComponent as OutlineArchiveBook } from "./vuesax-outline-archive-book.svg";
import { ReactComponent as OutlineNoteText } from "./vuesax-outline-note-text.svg";
import { ReactComponent as OutlineNoteFavorite } from "./vuesax-outline-note-favorite.svg";
import { ReactComponent as OutlineMenuBoard } from "./vuesax-outline-menu-board.svg";

//Programing
import { ReactComponent as OutlineCommand } from "./vuesax-outline-command.svg";
import { ReactComponent as OutlineProgramingArrow } from "./vuesax-outline-programming-arrow.svg";
import { ReactComponent as OutlineHierarchy } from "./vuesax-outline-hierarchy.svg";
import { ReactComponent as OutlineHierarchy2 } from "./vuesax-outline-hierarchy-2.svg";
import { ReactComponent as OutlineCommandSquare } from "./vuesax-outline-command-square.svg";
import { ReactComponent as OutlineHierarchySquare } from "./vuesax-outline-hierarchy-square.svg";
import { ReactComponent as OutlineHierarchySquare2 } from "./vuesax-outline-hierarchy-square-2.svg";
import { ReactComponent as OutlineHierarchySquare3 } from "./vuesax-outline-hierarchy-square-3.svg";
import { ReactComponent as OutlineData } from "./vuesax-outline-data.svg";
import { ReactComponent as OutlineData2 } from "./vuesax-outline-data-2.svg";
import { ReactComponent as OutlineDocumentCode } from "./vuesax-outline-document-code.svg";
import { ReactComponent as OutlineCodeCircle } from "./vuesax-outline-code-circle.svg";
import { ReactComponent as OutlineHierarchy3 } from "./vuesax-outline-hierarchy-3.svg";
import { ReactComponent as OutlineProgramingArrows } from "./vuesax-outline-programming-arrows.svg";
import { ReactComponent as OutlineDocumentCode2 } from "./vuesax-outline-document-code-2.svg";
import { ReactComponent as OutlineMessageProgramming } from "./vuesax-outline-message-programming.svg";
import { ReactComponent as OutlineMobilePrograming } from "./vuesax-outline-mobile-programming.svg";
import { ReactComponent as OutlineCode } from "./vuesax-outline-code.svg";
import { ReactComponent as OutlineCode2 } from "./vuesax-outline-code_2.svg";
import { ReactComponent as OutlineHastagUp } from "./vuesax-outline-hashtag-up.svg";
import { ReactComponent as OutlineHastag } from "./vuesax-outline-hashtag.svg";
import { ReactComponent as OutlineHashtagDown } from "./vuesax-outline-hashtag-down.svg";
import { ReactComponent as OutlineScroll } from "./vuesax-outline-scroll.svg";
import { ReactComponent as OutlineSidebarLeft } from "./vuesax-outline-sidebar-left.svg";
import { ReactComponent as OutlineSidebarRight } from "./vuesax-outline-sidebar-right.svg";
import { ReactComponent as OutlineSidebarTop } from "./vuesax-outline-sidebar-top.svg";
import { ReactComponent as OutlineSidebarBottom } from "./vuesax-outline-sidebar-bottom.svg";

/******************************************************************************************************************************************************************************** */
/*************************************************************       ICONOS BOLD       *******************************************************************************************/
/****************************************************************************************************************************************************************************** */

//Money
import { ReactComponent as BoldWalletAdd } from "./vuesax-bold-wallet-add.svg";
import { ReactComponent as BoldReceiptItem } from "./vuesax-bold-receipt-item.svg";
import { ReactComponent as BoldEmptyWallet } from "./vuesax-bold-empty-wallet.svg";
import { ReactComponent as BoldWalletCheck } from "./vuesax-bold-wallet-check.svg";
import { ReactComponent as BoldReceiptDiscount } from "./vuesax-bold-receipt-discount.svg";
import { ReactComponent as BoldEmptyWalletRemove } from "./vuesax-bold-empty-wallet-remove.svg";
import { ReactComponent as BoldEmptyWalletAdd } from "./vuesax-bold-empty-wallet-add.svg";
import { ReactComponent as BoldReceiptMinus } from "./vuesax-bold-receipt-minus.svg";
import { ReactComponent as BoldWalletSearch } from "./vuesax-bold-wallet-search.svg";
import { ReactComponent as BoldStrongBox2 } from "./vuesax-bold-strongbox-2.svg";
import { ReactComponent as BoldEmptyWalletTick } from "./vuesax-bold-empty-wallet-tick.svg";
import { ReactComponent as BoldReceiptAdd } from "./vuesax-bold-receipt-add.svg";
import { ReactComponent as BoldMoney } from "./vuesax-bold-money.svg";
import { ReactComponent as BoldEmptyWalletTime } from "./vuesax-bold-empty-wallet-time.svg";
import { ReactComponent as BoldTransactionMinus } from "./vuesax-bold-transaction-minus.svg";
import { ReactComponent as BoldMoney2 } from "./vuesax-bold-money-2.svg";
import { ReactComponent as BoldEmptyWalletChange } from "./vuesax-bold-empty-wallet-change.svg";
import { ReactComponent as BoldMoney3 } from "./vuesax-bold-money-3.svg";
import { ReactComponent as BoldPercentageSquare } from "./vuesax-bold-percentage-square.svg";
import { ReactComponent as BoldMoney4 } from "./vuesax-bold-money-4.svg";
import { ReactComponent as BoldPercentageCircle } from "./vuesax-bold-percentage-circle.svg";
import { ReactComponent as BoldChartSquare } from "./vuesax-bold-chart-square.svg";
import { ReactComponent as BoldMoney5 } from "./vuesax-bold-moneys.svg";
import { ReactComponent as BoldCoin } from "./vuesax-bold-coin.svg";
import { ReactComponent as BoldReceiptEdit } from "./vuesax-bold-receipt-edit.svg";
import { ReactComponent as BoldMoneyTick } from "./vuesax-bold-money-tick.svg";
import { ReactComponent as BoldDiscountShape } from "./vuesax-bold-discount-shape.svg";
import { ReactComponent as BoldReceiptSearch } from "./vuesax-bold-receipt-search.svg";
import { ReactComponent as BoldMoneyAdd } from "./vuesax-bold-money-add.svg";
import { ReactComponent as BoldConvertCard } from "./vuesax-bold-convert-card.svg";
import { ReactComponent as BoldDiscountCircle } from "./vuesax-bold-discount-circle.svg";
import { ReactComponent as BoldWallet2 } from "./vuesax-bold-wallet-2.svg";
import { ReactComponent as BoldMoneyRemove } from "./vuesax-bold-money-remove.svg";
import { ReactComponent as BoldWalletMoney } from "./vuesax-bold-wallet-money.svg";
import { ReactComponent as BoldCardRemove } from "./vuesax-bold-card-remove.svg";
import { ReactComponent as BoldCards } from "./vuesax-bold-cards.svg";
import { ReactComponent as BoldMoneyForbidden } from "./vuesax-bold-money-forbidden.svg";
import { ReactComponent as BoldWallet3 } from "./vuesax-bold-wallet-3.svg";
import { ReactComponent as BoldCardTick } from "./vuesax-bold-card-tick.svg";
import { ReactComponent as BoldReceipt2 } from "./vuesax-bold-receipt-2.svg";
import { ReactComponent as BoldReceipt } from "./vuesax-bold-receipt.svg";
import { ReactComponent as BoldMoneyChange } from "./vuesax-bold-money-change.svg";
import { ReactComponent as BoldWalletRemove } from "./vuesax-bold-wallet-remove.svg";
import { ReactComponent as BoldMoneyTime } from "./vuesax-bold-money-time.svg";
import { ReactComponent as BoldReceiptText } from "./vuesax-bold-receipt-text.svg";
import { ReactComponent as BoldStrongbox } from "./vuesax-bold-strongbox.svg";
import { ReactComponent as BoldWallet4 } from "./vuesax-bold-wallet.svg";
import { ReactComponent as BoldSecurityCard } from "./vuesax-bold-security-card.svg";
import { ReactComponent as BoldMoneyReceive } from "./vuesax-bold-money-recive.svg";
import { ReactComponent as BoldMoneySend } from "./vuesax-bold-money-send.svg";
import { ReactComponent as BoldArchive } from "./vuesax-bold-archive.svg";
import { ReactComponent as BoldTag } from "./vuesax-bold-tag.svg";
import { ReactComponent as BoldTag2 } from "./vuesax-bold-tag-2.svg";
import { ReactComponent as BoldCoin2 } from "./vuesax-bold-coin.svg";
import { ReactComponent as BoldDocument } from "./vuesax-bold-document.svg";
import { ReactComponent as BoldWallet5 } from "./vuesax-bold-wallet.svg";
import { ReactComponent as BoldCardAdd } from "./vuesax-bold-card-add.svg";
import { ReactComponent as BoldCardRemove2 } from "./vuesax-bold-card-remove.svg";
import { ReactComponent as BoldCardTick2 } from "./vuesax-bold-card-tick.svg";
import { ReactComponent as BoldCardSlash } from "./vuesax-bold-card-slash.svg";
import { ReactComponent as BoldCardPos } from "./vuesax-bold-card-pos.svg";
import { ReactComponent as BoldCard } from "./vuesax-bold-card.svg";
import { ReactComponent as BoldCardReceive } from "./vuesax-bold-card-receive.svg";
import { ReactComponent as BoldCardSend } from "./vuesax-bold-card-send.svg";
import { ReactComponent as BoldCardEdit } from "./vuesax-bold-card-edit.svg";
import { ReactComponent as BoldWalletMinus } from "./vuesax-bold-wallet-minus.svg";
import { ReactComponent as BoldWalletAdd2 } from "./vuesax-bold-wallet-add.svg";
import { ReactComponent as BoldDollarSquare } from "./vuesax-bold-dollar-square.svg";
import { ReactComponent as BoldDollarCircle } from "./vuesax-bold-dollar-circle.svg";
import { ReactComponent as BoldReceipt3 } from "./vuesax-bold-receipt.svg";
import { ReactComponent as BoldReceipt4 } from "./vuesax-bold-receipt-2.svg";
import { ReactComponent as BoldReceiptDisscount } from "./vuesax-bold-receipt-disscount.svg";
import { ReactComponent as BoldTicket } from "./vuesax-bold-ticket.svg";
import { ReactComponent as BoldTicketStar } from "./vuesax-bold-ticket-star.svg";
import { ReactComponent as BoldTicketExpired } from "./vuesax-bold-ticket-expired.svg";
import { ReactComponent as BoldTicket2 } from "./vuesax-bold-ticket-2.svg";
import { ReactComponent as BoldTicketDiscount } from "./vuesax-bold-ticket-discount.svg";
import { ReactComponent as BoldMath } from "./vuesax-bold-math.svg";

//Type, paragraph, character
import { ReactComponent as BoldTextalingLeft } from "./vuesax-bold-textalign-left.svg";
import { ReactComponent as BoldTextalingCenter } from "./vuesax-bold-textalign-center.svg";
import { ReactComponent as BoldTextalingRiht } from "./vuesax-bold-textalign-right.svg";
import { ReactComponent as BoldTextalingJustifyRight } from "./vuesax-bold-textalign-justifyright.svg";
import { ReactComponent as BoldTextalingJustifyCenter } from "./vuesax-bold-textalign-justifycenter.svg";
import { ReactComponent as BoldTextalingJustifyLeft } from "./vuesax-bold-textalign-justifyleft.svg";
import { ReactComponent as BoldFristline } from "./vuesax-bold-firstline.svg";
import { ReactComponent as BoldPaperclip2 } from "./vuesax-bold-paperclip-2.svg";
import { ReactComponent as BoldPaperclip } from "./vuesax-bold-paperclip.svg";
import { ReactComponent as BoldLink } from "./vuesax-bold-link.svg";
import { ReactComponent as BoldLink3 } from "./vuesax-bold-link.svg";
import { ReactComponent as BoldLink2 } from "./vuesax-bold-link-2.svg";
import { ReactComponent as BoldTextBlock } from "./vuesax-bold-text-block.svg";
import { ReactComponent as BoldTextAling } from "./vuesax-bold-text-italic.svg";
import { ReactComponent as BoldText } from "./vuesax-bold-text.svg";
import { ReactComponent as BoldSmallcaps } from "./vuesax-bold-smallcaps.svg";
import { ReactComponent as BoldTextBold } from "./vuesax-bold-text-bold.svg";
import { ReactComponent as BoldTextUnderline } from "./vuesax-bold-text-underline.svg";
import { ReactComponent as BoldPharagraphspacing } from "./vuesax-bold-pharagraphspacing.svg";
import { ReactComponent as BoldMaximize } from "./vuesax-bold-maximize.svg";
import { ReactComponent as BoldEraser } from "./vuesax-bold-eraser.svg";
import { ReactComponent as BoldAtthachSquare } from "./vuesax-bold-attach-square.svg";
import { ReactComponent as BoldAttachCircle } from "./vuesax-bold-attach-circle.svg";
import { ReactComponent as BoldQuoteUp } from "./vuesax-bold-quote-up.svg";
import { ReactComponent as BoldQuoteDown } from "./vuesax-bold-quote-down.svg";
import { ReactComponent as BoldQuoteUpSquare } from "./vuesax-bold-quote-up-square.svg";
import { ReactComponent as BoldQuoteDownSquare } from "./vuesax-bold-quote-down-square.svg";
import { ReactComponent as BoldQuoteDownCircle } from "./vuesax-bold-quote-down-circle.svg";
import { ReactComponent as BoldQuoteUpCircle } from "./vuesax-bold-quote-up-circle.svg";
import { ReactComponent as BoldLink4 } from "./vuesax-bold-link.svg";
import { ReactComponent as BoldLinkSquare } from "./vuesax-bold-link-square.svg";
import { ReactComponent as BoldLinkCircle } from "./vuesax-bold-link-circle.svg";
import { ReactComponent as BoldTranslate } from "./vuesax-bold-translate.svg";
import { ReactComponent as BoldLangugeSquare } from "./vuesax-bold-language-square.svg";
import { ReactComponent as BoldLanguageCircle } from "./vuesax-bold-language-circle.svg";

//Weather
import { ReactComponent as BoldMoon } from "./vuesax-bold-moon.svg";
import { ReactComponent as BoldSun } from "./vuesax-bold-sun.svg";
import { ReactComponent as BoldCloud } from "./vuesax-bold-cloud.svg";
import { ReactComponent as BoldCloudPlus } from "./vuesax-bold-cloud-plus.svg";
import { ReactComponent as BoldCloudMinus } from "./vuesax-bold-cloud-minus.svg";
import { ReactComponent as BoldCloudCross } from "./vuesax-bold-cloud-cross.svg";
import { ReactComponent as BoldCloudSunny } from "./vuesax-bold-cloud-sunny.svg";
import { ReactComponent as BoldCloudNotif } from "./vuesax-bold-cloud-notif.svg";
import { ReactComponent as BoldCloudDrizzle } from "./vuesax-bold-cloud-drizzle.svg";
import { ReactComponent as BoldCloudSnow } from "./vuesax-bold-cloud-snow.svg";
import { ReactComponent as BoldCloudLightning } from "./vuesax-bold-cloud-lightning.svg";
import { ReactComponent as BoldFlash } from "./vuesax-bold-flash.svg";
import { ReactComponent as BoldDrop } from "./vuesax-bold-drop.svg";
import { ReactComponent as BoldWind } from "./vuesax-bold-wind.svg";
import { ReactComponent as BoldWind2 } from "./vuesax-bold-wind-2.svg";
import { ReactComponent as BoldCloudFog } from "./vuesax-bold-cloud-fog.svg";
import { ReactComponent as BoldSunFog } from "./vuesax-bold-sun-fog.svg";
import { ReactComponent as BoldSun2 } from "./vuesax-bold-sun.svg";

//Files
import { ReactComponent as BoldFolder } from "./vuesax-bold-folder.svg";
import { ReactComponent as BoldFolderCross } from "./vuesax-bold-folder-cross.svg";
import { ReactComponent as BoldFolderAdd } from "./vuesax-bold-folder-add.svg";
import { ReactComponent as BoldFolderMinus } from "./vuesax-bold-folder-minus.svg";
import { ReactComponent as BoldFolderFavorite } from "./vuesax-bold-folder-favorite.svg";
import { ReactComponent as BoldFolder2 } from "./vuesax-bold-folder-2.svg";
import { ReactComponent as BoldFolderOpen } from "./vuesax-bold-folder-open.svg";
import { ReactComponent as BoldFolderCloud } from "./vuesax-bold-folder-cloud.svg";

//Video. Audio, Image
import { ReactComponent as BoldMicrophone } from "./vuesax-bold-microphone.svg";
import { ReactComponent as BoldMicrphoneSlash } from "./vuesax-bold-microphone-slash.svg";
import { ReactComponent as BoldMicrophone2 } from "./vuesax-bold-microphone-2.svg";
import { ReactComponent as BoldMicrophoneSlash } from "./vuesax-bold-microphone-slash.svg";
import { ReactComponent as BoldVolumeCross } from "./vuesax-bold-volume-cross.svg";
import { ReactComponent as BoldVolumeSlash } from "./vuesax-bold-volume-slash.svg";
import { ReactComponent as BoldVolumeUp } from "./vuesax-bold-volume-up.svg";
import { ReactComponent as BoldVolumeLow } from "./vuesax-bold-volume-low.svg";
import { ReactComponent as BoldVolumeLow2 } from "./vuesax-bold-volume-low.svg";
import { ReactComponent as BoldVolumeHigh } from "./vuesax-bold-volume-high.svg";
import { ReactComponent as BoldVolumeMute } from "./vuesax-bold-volume-mute.svg";
import { ReactComponent as BoldVideo } from "./vuesax-bold-video.svg";
import { ReactComponent as BoldVideoSlash } from "./vuesax-bold-video-slash.svg";
import { ReactComponent as BoldVideoSquare } from "./vuesax-bold-video-square.svg";
import { ReactComponent as BoldVideoCircle } from "./vuesax-bold-video-circle.svg";
import { ReactComponent as BoldGallery } from "./vuesax-bold-gallery.svg";
import { ReactComponent as BoldGalleryFavorite } from "./vuesax-bold-gallery-favorite.svg";
import { ReactComponent as BoldGallerySlash } from "./vuesax-bold-gallery-slash.svg";
import { ReactComponent as BoldGalleryTick } from "./vuesax-bold-gallery-tick.svg";
import { ReactComponent as BoldGalleryImport } from "./vuesax-bold-gallery-import.svg";
import { ReactComponent as BoldGalleryExport } from "./vuesax-bold-gallery-export.svg";
import { ReactComponent as BoldGalleryAdd } from "./vuesax-bold-gallery-add.svg";
import { ReactComponent as BoldGalleryRemove } from "./vuesax-bold-gallery-remove.svg";
import { ReactComponent as BoldGalleryEdit } from "./vuesax-bold-gallery-edit.svg";
import { ReactComponent as BoldImage } from "./vuesax-bold-image.svg";
import { ReactComponent as BoldScrenmirroring } from "./vuesax-bold-screenmirroring.svg";
import { ReactComponent as BoldCamera } from "./vuesax-bold-camera.svg";
import { ReactComponent as BoldCameraSlash } from "./vuesax-bold-camera-slash.svg";
import { ReactComponent as BoldPlay } from "./vuesax-bold-play.svg";
import { ReactComponent as BoldRecord } from "./vuesax-bold-record.svg";
import { ReactComponent as BoldStop } from "./vuesax-bold-stop.svg";
import { ReactComponent as BoldMusicnote } from "./vuesax-bold-musicnote.svg";
import { ReactComponent as BoldMusic } from "./vuesax-bold-music.svg";
import { ReactComponent as BoldVideoVertical } from "./vuesax-bold-video-vertical.svg";
import { ReactComponent as BoldVideoHorizontal } from "./vuesax-bold-video-horizontal.svg";
import { ReactComponent as BoldVideoPlay } from "./vuesax-bold-video-play.svg";
import { ReactComponent as BoldSubtitle } from "./vuesax-bold-subtitle.svg";
import { ReactComponent as BoldPlayCircle } from "./vuesax-bold-play-circle.svg";
import { ReactComponent as BoldRecordCircle } from "./vuesax-bold-record-circle.svg";
import { ReactComponent as BoldStopCircle } from "./vuesax-bold-stop-circle.svg";
import { ReactComponent as BoldPauseCircle } from "./vuesax-bold-pause-circle.svg";
import { ReactComponent as BoldPause } from "./vuesax-bold-pause.svg";
import { ReactComponent as BoldScissor } from "./vuesax-bold-scissor.svg";
import { ReactComponent as BoldBackward } from "./vuesax-bold-backward.svg";
import { ReactComponent as BoldForward } from "./vuesax-bold-forward.svg";
import { ReactComponent as BoldPrevious } from "./vuesax-bold-previous.svg";
import { ReactComponent as BoldNext } from "./vuesax-bold-next.svg";
import { ReactComponent as BoldRadio } from "./vuesax-bold-radio.svg";
import { ReactComponent as BoldMusicCircle } from "./vuesax-bold-music-circle.svg";
import { ReactComponent as BoldPlayCircle2 } from "./vuesax-bold-play-cricle.svg";
import { ReactComponent as BoldPlayRemove } from "./vuesax-bold-play-remove.svg";
import { ReactComponent as BoldPlayAdd } from "./vuesax-bold-play-add.svg";
import { ReactComponent as BoldBackward5Seconds } from "./vuesax-bold-backward-5-seconds.svg";
import { ReactComponent as BoldBackward10Seconds } from "./vuesax-bold-backward-10-seconds.svg";
import { ReactComponent as BoldBackward15Seconds } from "./vuesax-bold-backward-15-seconds.svg";
import { ReactComponent as BoldForward5Seconds } from "./vuesax-bold-forward-5-seconds.svg";
import { ReactComponent as BoldForward10Seconds } from "./vuesax-bold-forward-10-seconds.svg";
import { ReactComponent as BoldForward15Seconds } from "./vuesax-bold-forward-15-seconds.svg";
import { ReactComponent as BoldMusicPlaylist } from "./vuesax-bold-music-playlist.svg";
import { ReactComponent as BoldMusicFilter } from "./vuesax-bold-music-filter.svg";
import { ReactComponent as BoldMusicDashboard } from "./vuesax-bold-music-dashboard.svg";
import { ReactComponent as BoldMusicSquareRemove } from "./vuesax-bold-music-square-remove.svg";
import { ReactComponent as BoldMusicSquareAdd } from "./vuesax-bold-music-square-add.svg";
import { ReactComponent as BoldMusicSquareSearch } from "./vuesax-bold-music-square-search.svg";
import { ReactComponent as BoldRepeatMusic } from "./vuesax-bold-repeate-music.svg";
import { ReactComponent as BoldRepeatOne } from "./vuesax-bold-repeate-one.svg";
import { ReactComponent as BoldShuffle } from "./vuesax-bold-shuffle.svg";
import { ReactComponent as BoldDevices } from "./vuesax-bold-devices.svg";
import { ReactComponent as BoldMusicLibrary2 } from "./vuesax-bold-music-library-2.svg";
import { ReactComponent as BoldMusicSquare } from "./vuesax-bold-music-square.svg";
import { ReactComponent as BoldAudioSquare } from "./vuesax-bold-audio-square.svg";
import { ReactComponent as BoldNoteSquare } from "./vuesax-bold-note-square.svg";
import { ReactComponent as BoldMiniMusicSquare } from "./vuesax-bold-mini-music-sqaure.svg";
import { ReactComponent as BoldVideoAdd } from "./vuesax-bold-video-add.svg";
import { ReactComponent as BoldVideoRemove } from "./vuesax-bold-video-remove.svg";
import { ReactComponent as BoldVideoTick } from "./vuesax-bold-video-tick.svg";
import { ReactComponent as BoldVideoTime } from "./vuesax-bold-video-time.svg";
import { ReactComponent as BoldVideoOctagon } from "./vuesax-bold-video-octagon.svg";
import { ReactComponent as BoldVoiceSquare } from "./vuesax-bold-voice-square.svg";
import { ReactComponent as BoldVoiceCircle } from "./vuesax-bold-voice-cricle.svg";
import { ReactComponent as BoldMaximizeCircle } from "./vuesax-bold-maximize-circle.svg";

//Arrow
import { ReactComponent as BoldReceived } from "./vuesax-bold-received.svg";
import { ReactComponent as BoldSend } from "./vuesax-bold-send.svg";
import { ReactComponent as BoldArrow2 } from "./vuesax-bold-arrow-2.svg";
import { ReactComponent as BoldArrow3 } from "./vuesax-bold-arrow-3.svg";
import { ReactComponent as BoldArrowSquareUp } from "./vuesax-bold-arrow-square-up.svg";
import { ReactComponent as BoldArrowSquareDown } from "./vuesax-bold-arrow-square-down.svg";
import { ReactComponent as BoldArrowSquareLeft } from "./vuesax-bold-arrow-square-left.svg";
import { ReactComponent as BoldArrowSquareRight } from "./vuesax-bold-arrow-square-right.svg";
import { ReactComponent as BoldArrowRight } from "./vuesax-bold-arrow-right.svg";
import { ReactComponent as BoldArrowLeft } from "./vuesax-bold-arrow-left.svg";
import { ReactComponent as BoldArrowUp } from "./vuesax-bold-arrow-up.svg";
import { ReactComponent as BoldArrowDown } from "./vuesax-bold-arrow-down.svg";
import { ReactComponent as BoldArrowUp2 } from "./vuesax-bold-arrow-up.svg";
import { ReactComponent as BoldArrowDown2 } from "./vuesax-bold-arrow-down.svg";
import { ReactComponent as BoldArrowRight2 } from "./vuesax-bold-arrow-right.svg";
import { ReactComponent as BoldArrowLeft2 } from "./vuesax-bold-arrow-left.svg";
import { ReactComponent as BoldArrowCircleLeft } from "./vuesax-bold-arrow-circle-left.svg";
import { ReactComponent as BoldArrowCircleRight } from "./vuesax-bold-arrow-circle-right.svg";
import { ReactComponent as BoldArrowCircleDown } from "./vuesax-bold-arrow-circle-down.svg";
import { ReactComponent as BoldArrowCircleUp } from "./vuesax-bold-arrow-circle-up.svg";
import { ReactComponent as BoldArrowSwap } from "./vuesax-bold-arrow-swap.svg";
import { ReactComponent as BoldArrowSwapHorizontal } from "./vuesax-bold-arrow-swap-horizontal.svg";
import { ReactComponent as BoldUndo } from "./vuesax-bold-undo.svg";
import { ReactComponent as BoldRedo } from "./vuesax-bold-redo.svg";
import { ReactComponent as BoldRoteateLeft } from "./vuesax-bold-rotate-left.svg";
import { ReactComponent as BoldRotateRight } from "./vuesax-bold-rotate-right.svg";
import { ReactComponent as BoldRefresh2 } from "./vuesax-bold-refresh-2.svg";
import { ReactComponent as BoldArrowLeft3 } from "./vuesax-bold-arrow-left.svg";
import { ReactComponent as BoldArrowRight3 } from "./vuesax-bold-arrow-right_3.svg";
import { ReactComponent as BoldArrowUp3 } from "./vuesax-bold-arrow-up.svg";
import { ReactComponent as BoldArrowBottom } from "./vuesax-bold-arrow-bottom.svg";
import { ReactComponent as BoldArrowUp4 } from "./vuesax-bold-arrow-up.svg";
import { ReactComponent as BoldArrowRight4 } from "./vuesax-bold-arrow-right.svg";
import { ReactComponent as BoldArrowLeft4 } from "./vuesax-bold-arrow-left.svg";
import { ReactComponent as BoldArrowDown3 } from "./vuesax-bold-arrow-down.svg";
import { ReactComponent as BoldConvert } from "./vuesax-bold-convert.svg";
import { ReactComponent as BoldArrangeSquare } from "./vuesax-bold-arrange-square.svg";
import { ReactComponent as BoldArrangeSquare2 } from "./vuesax-bold-arrange-square-2.svg";
import { ReactComponent as BoldBackSquare } from "./vuesax-bold-back-square.svg";
import { ReactComponent as BoldForwardSquare } from "./vuesax-bold-forward-square.svg";
import { ReactComponent as BoldRefreshSquare2 } from "./vuesax-bold-refresh-square-2.svg";
import { ReactComponent as BoldRefreshLeftSquare } from "./vuesax-bold-refresh-left-square.svg";
import { ReactComponent as BoldRefreshRightSquare } from "./vuesax-bold-refresh-right-square.svg";
import { ReactComponent as BoldReceiveSquare } from "./vuesax-bold-receive-square.svg";
import { ReactComponent as BoldSendSquare } from "./vuesax-bold-send-square.svg";
import { ReactComponent as BoldSendSquare2 } from "./vuesax-bold-send-sqaure-2.svg";
import { ReactComponent as BoldReceiveSquare2 } from "./vuesax-bold-receive-square-2.svg";
import { ReactComponent as BoldLogin } from "./vuesax-bold-login.svg";
import { ReactComponent as BoldLogout } from "./vuesax-bold-logout.svg";
import { ReactComponent as BoldImport } from "./vuesax-bold-import.svg";
import { ReactComponent as BoldExport } from "./vuesax-bold-export.svg";
import { ReactComponent as BoldExport2 } from "./vuesax-bold-export.svg";
import { ReactComponent as BoldImport2 } from "./vuesax-bold-import.svg";
import { ReactComponent as BoldLogin2 } from "./vuesax-bold-login.svg";
import { ReactComponent as BoldLogout2 } from "./vuesax-bold-logout.svg";
import { ReactComponent as BoldExport3 } from "./vuesax-bold-export.svg";
import { ReactComponent as BoldImport3 } from "./vuesax-bold-import.svg";
import { ReactComponent as BoldImport4 } from "./vuesax-bold-import.svg";
import { ReactComponent as BoldExport4 } from "./vuesax-bold-export.svg";
import { ReactComponent as BoldRefresh3 } from "./vuesax-bold-refresh.svg";
import { ReactComponent as BoldArrangeCircle } from "./vuesax-bold-arrange-circle.svg";
import { ReactComponent as BoldArrangeCircle2 } from "./vuesax-bold-arrange-circle-2.svg";
import { ReactComponent as BoldRepeat } from "./vuesax-bold-repeat.svg";
import { ReactComponent as BoldRepeatCircle } from "./vuesax-bold-repeat-circle.svg";
import { ReactComponent as BoldRefreshCircle } from "./vuesax-bold-refresh-circle.svg";

//Users
import { ReactComponent as BoldUser } from "./vuesax-bold-user.svg";
import { ReactComponent as BoldUserAdd } from "./vuesax-bold-user-add.svg";
import { ReactComponent as BoldUserRemove } from "./vuesax-bold-user-remove.svg";
import { ReactComponent as BoldUserTick } from "./vuesax-bold-user-tick.svg";
import { ReactComponent as BoldUserMinus } from "./vuesax-bold-user-minus.svg";
import { ReactComponent as BoldUserSearch } from "./vuesax-bold-user-search.svg";
import { ReactComponent as BoldUserEdit } from "./vuesax-bold-user-edit.svg";
import { ReactComponent as BoldUserTag } from "./vuesax-bold-user-tag.svg";
import { ReactComponent as BoldUserOctagon } from "./vuesax-bold-user-octagon.svg";
import { ReactComponent as BoldUserCircleAdd } from "./vuesax-bold-user-cirlce-add.svg";
import { ReactComponent as BoldUserSquare } from "./vuesax-bold-user-square.svg";
import { ReactComponent as BoldFrame } from "./vuesax-bold-frame.svg";
import { ReactComponent as BoldProfile2User } from "./vuesax-bold-profile-2-user.svg";
import { ReactComponent as BoldProfileRemove } from "./vuesax-bold-profile-remove.svg";
import { ReactComponent as BoldProfileTick } from "./vuesax-bold-profile-tick.svg";
import { ReactComponent as BoldProfileAdd } from "./vuesax-bold-profile-add.svg";
import { ReactComponent as BoldProfileDelete } from "./vuesax-bold-profile-delete.svg";
import { ReactComponent as BoldProfileCircle } from "./vuesax-bold-profile-circle.svg";
import { ReactComponent as BoldTagUser } from "./vuesax-bold-tag-user.svg";
import { ReactComponent as BoldPeople } from "./vuesax-bold-people.svg";

//Car
import { ReactComponent as BoldAirplane } from "./vuesax-bold-airplane.svg";
import { ReactComponent as BoldCar } from "./vuesax-bold-car.svg";
import { ReactComponent as BoldBus } from "./vuesax-bold-bus.svg";
import { ReactComponent as BoldSmartCar } from "./vuesax-bold-smart-car.svg";
import { ReactComponent as BoldDriving } from "./vuesax-bold-driving.svg";
import { ReactComponent as BoldShip } from "./vuesax-bold-ship.svg";
import { ReactComponent as BoldAirplaneSquare } from "./vuesax-bold-airplane-square.svg";
import { ReactComponent as BoldGasStation } from "./vuesax-bold-gas-station.svg";

//Emails,Messages
import { ReactComponent as BoldDirectboxDefault } from "./vuesax-bold-directbox-default.svg";
import { ReactComponent as BoldDirectboxNotif } from "./vuesax-bold-directbox-notif.svg";
import { ReactComponent as BoldDirectboxReceive } from "./vuesax-bold-directbox-receive.svg";
import { ReactComponent as BoldDirectboxSend } from "./vuesax-bold-directbox-send.svg";
import { ReactComponent as BoldDirectNormal } from "./vuesax-bold-direct-normal.svg";
import { ReactComponent as BoldDirect } from "./vuesax-bold-direct.svg";
import { ReactComponent as BoldDirectNotification } from "./vuesax-bold-direct-notification.svg";
import { ReactComponent as BoldDirectInbox } from "./vuesax-bold-direct-inbox.svg";
import { ReactComponent as BoldDirectSend } from "./vuesax-bold-direct-send.svg";
import { ReactComponent as BoldMessage } from "./vuesax-bold-message.svg";
import { ReactComponent as BoldMessageFavorite } from "./vuesax-bold-message-favorite.svg";
import { ReactComponent as BoldMessageNotif } from "./vuesax-bold-message-notif.svg";
import { ReactComponent as BoldMessageSearch } from "./vuesax-bold-message-search.svg";
import { ReactComponent as BoldMessageText } from "./vuesax-bold-message-text.svg";
import { ReactComponent as BoldDeviceMessage } from "./vuesax-bold-device-message.svg";
import { ReactComponent as BoldMessages } from "./vuesax-bold-messages.svg";
import { ReactComponent as BoldMessages2 } from "./vuesax-bold-messages-2.svg";
import { ReactComponent as BoldMessages3 } from "./vuesax-bold-messages-3.svg";
import { ReactComponent as BoldSms } from "./vuesax-bold-sms.svg";
import { ReactComponent as BoldSmsTracking } from "./vuesax-bold-sms-tracking.svg";
import { ReactComponent as BoldSmsNotification } from "./vuesax-bold-sms-notification.svg";
import { ReactComponent as BoldSmsEdit } from "./vuesax-bold-sms-edit.svg";
import { ReactComponent as BoldSmsStar } from "./vuesax-bold-sms-star.svg";
import { ReactComponent as BoldSmsSearch } from "./vuesax-bold-sms-search.svg";
import { ReactComponent as BoldMessageRemove } from "./vuesax-bold-message-remove.svg";
import { ReactComponent as BoldMessageAdd } from "./vuesax-bold-message-add.svg";
import { ReactComponent as BoldMessageTick } from "./vuesax-bold-message-tick.svg";
import { ReactComponent as BoldMessageTime } from "./vuesax-bold-message-time.svg";
import { ReactComponent as BoldMessage2 } from "./vuesax-bold-message-2.svg";
import { ReactComponent as BoldMessages4 } from "./vuesax-bold-messages.svg";
import { ReactComponent as BoldMessageCircle } from "./vuesax-bold-message-circle.svg";
import { ReactComponent as BoldMessageSquare } from "./vuesax-bold-message-square.svg";
import { ReactComponent as BoldMessageText2 } from "./vuesax-bold-message-text.svg";
import { ReactComponent as BoldMessageMinus } from "./vuesax-bold-message-minus.svg";
import { ReactComponent as BoldMessageAdd2 } from "./vuesax-bold-message-add.svg";
import { ReactComponent as BoldMessageEdit } from "./vuesax-bold-message-edit.svg";

//Location
import { ReactComponent as BoldLocationMinus } from "./vuesax-bold-location-minus.svg";
import { ReactComponent as BoldLocationCross } from "./vuesax-bold-location-cross.svg";
import { ReactComponent as BoldLocationTick } from "./vuesax-bold-location-tick.svg";
import { ReactComponent as BoldLocationSlash } from "./vuesax-bold-location-slash.svg";
import { ReactComponent as BoldDiscover } from "./vuesax-bold-discover.svg";
import { ReactComponent as BoldLocation } from "./vuesax-bold-location.svg";
import { ReactComponent as BoldLocationAdd } from "./vuesax-bold-location-add.svg";
import { ReactComponent as BoldRadar } from "./vuesax-bold-radar.svg";
import { ReactComponent as BoldRadar2 } from "./vuesax-bold-radar-2.svg";
import { ReactComponent as BoldGlobalSearch } from "./vuesax-bold-global-search.svg";
import { ReactComponent as BoldGlobalEdit } from "./vuesax-bold-global-edit.svg";
import { ReactComponent as BoldGlobalRefresh } from "./vuesax-bold-global-refresh.svg";
import { ReactComponent as BoldGlobal } from "./vuesax-bold-global.svg";
import { ReactComponent as BoldDirectUp } from "./vuesax-bold-direct-up.svg";
import { ReactComponent as BoldDirectDown } from "./vuesax-bold-direct-down.svg";
import { ReactComponent as BoldDirectLeft } from "./vuesax-bold-direct-left.svg";
import { ReactComponent as BoldDirectRight } from "./vuesax-bold-direct-right.svg";
import { ReactComponent as BoldMap } from "./vuesax-bold-map.svg";
import { ReactComponent as BoldGps } from "./vuesax-bold-gps.svg";
import { ReactComponent as BoldGpsSlash } from "./vuesax-bold-gps-slash.svg";
import { ReactComponent as BoldMap2 } from "./vuesax-bold-map.svg";
import { ReactComponent as BoldRouting } from "./vuesax-bold-routing.svg";
import { ReactComponent as BoldRouting2 } from "./vuesax-bold-routing-2.svg";
import { ReactComponent as BoldArrowSquare } from "./vuesax-bold-arrow-square.svg";
import { ReactComponent as BoldPictureFrame } from "./vuesax-bold-picture-frame.svg";
import { ReactComponent as BoldArrow } from "./vuesax-bold-arrow.svg";
import { ReactComponent as BoldRouteSquare } from "./vuesax-bold-route-square.svg";

//School, learning
import { ReactComponent as BoldBriefcase } from "./vuesax-bold-briefcase.svg";
import { ReactComponent as BoldBriefcaseTimer } from "./vuesax-bold-brifecase-timer.svg";
import { ReactComponent as BoldBriefcaseTick } from "./vuesax-bold-brifecase-tick.svg";
import { ReactComponent as BoldBriefcaseCross } from "./vuesax-bold-brifecase-cross.svg";
import { ReactComponent as BoldBookmark } from "./vuesax-bold-bookmark.svg";
import { ReactComponent as BoldAward } from "./vuesax-bold-award.svg";
import { ReactComponent as BoldBook } from "./vuesax-bold-book.svg";
import { ReactComponent as BoldBook3 } from "./vuesax-bold-book.svg";
import { ReactComponent as BoldBookmark2 } from "./vuesax-bold-bookmark-2.svg";
import { ReactComponent as BoldClipboard } from "./vuesax-bold-clipboard.svg";
import { ReactComponent as BoldNote } from "./vuesax-bold-note.svg";
import { ReactComponent as BoldNote2 } from "./vuesax-bold-note-2.svg";
import { ReactComponent as BoldTeacher } from "./vuesax-bold-teacher.svg";
import { ReactComponent as BoldGift } from "./vuesax-bold-gift.svg";
import { ReactComponent as BoldCalculator } from "./vuesax-bold-calculator.svg";

//Delivery
import { ReactComponent as BoldBox } from "./vuesax-bold-box.svg";
import { ReactComponent as Bold3dCubeScan } from "./vuesax-bold-3-d-cube-scan.svg";
import { ReactComponent as BoldConvert3dCube } from "./vuesax-bold-convert-3-d-cube.svg";
import { ReactComponent as BoldBoxAdd } from "./vuesax-bold-box-add.svg";
import { ReactComponent as BoldBoxRemove } from "./vuesax-bold-box-remove.svg";
import { ReactComponent as BoldBoxTick } from "./vuesax-bold-box-tick.svg";
import { ReactComponent as BoldBoxTime } from "./vuesax-bold-box-time.svg";
import { ReactComponent as BoldBox2 } from "./vuesax-bold-box.svg";
import { ReactComponent as BoldBoxSearch } from "./vuesax-bold-box-search.svg";
import { ReactComponent as BoldTruck } from "./vuesax-bold-truck.svg";
import { ReactComponent as BoldTruckFast } from "./vuesax-bold-truck-fast.svg";
import { ReactComponent as BoldTruckTick } from "./vuesax-bold-truck-tick.svg";
import { ReactComponent as BoldTruckTime } from "./vuesax-bold-truck-time.svg";
import { ReactComponent as BoldTruckRemove } from "./vuesax-bold-truck-remove.svg";
import { ReactComponent as Bold3dSquare } from "./vuesax-bold-3-d-square.svg";
import { ReactComponent as Bold3dRotate } from "./vuesax-bold-3-d-rotate.svg";

//Security
import { ReactComponent as BoldEye } from "./vuesax-bold-eye.svg";
import { ReactComponent as BoldEyeSlash } from "./vuesax-bold-eye-slash.svg";
import { ReactComponent as BoldFrame2 } from "./vuesax-bold-frame.svg";
import { ReactComponent as BoldShieldTick } from "./vuesax-bold-shield-tick.svg";
import { ReactComponent as BoldShieldCross } from "./vuesax-bold-shield-cross.svg";
import { ReactComponent as BoldSheildSlash } from "./vuesax-bold-shield-slash.svg";
import { ReactComponent as BoldSheildSearch } from "./vuesax-bold-shield-search.svg";
import { ReactComponent as BoldShieldSecurity } from "./vuesax-bold-shield-security.svg";
import { ReactComponent as BoldPasswordCheck } from "./vuesax-bold-password-check.svg";
import { ReactComponent as BoldKey } from "./vuesax-bold-key.svg";
import { ReactComponent as BoldKeySquare } from "./vuesax-bold-key-square.svg";
import { ReactComponent as BoldCheck } from "./vuesax-bold-check.svg";
import { ReactComponent as BoldLock } from "./vuesax-bold-lock.svg";
import { ReactComponent as BoldUnlock } from "./vuesax-bold-unlock.svg";
import { ReactComponent as BoldLockSlash } from "./vuesax-bold-lock-slash.svg";
import { ReactComponent as BoldScan } from "./vuesax-bold-scan.svg";
import { ReactComponent as BoldScanBarcode } from "./vuesax-bold-scan-barcode.svg";
import { ReactComponent as BoldScanning } from "./vuesax-bold-scanning.svg";
import { ReactComponent as BoldFingerScan } from "./vuesax-bold-finger-scan.svg";
import { ReactComponent as BoldFingerCircle } from "./vuesax-bold-finger-cricle.svg";
import { ReactComponent as BoldScanner } from "./vuesax-bold-scanner.svg";
import { ReactComponent as BoldSecurityUser } from "./vuesax-bold-security-user.svg";
import { ReactComponent as BoldLock2 } from "./vuesax-bold-lock.svg";
import { ReactComponent as BoldLockCircle } from "./vuesax-bold-lock-circle.svg";
import { ReactComponent as BoldAlarm } from "./vuesax-bold-alarm.svg";
import { ReactComponent as BoldRadar3 } from "./vuesax-bold-radar.svg";
import { ReactComponent as BoldSecuritySafe } from "./vuesax-bold-security-safe.svg";
import { ReactComponent as BoldSecurity } from "./vuesax-bold-security.svg";

//Design, Tools
import { ReactComponent as BoldBezier } from "./vuesax-bold-bezier.svg";
import { ReactComponent as BoldBrush } from "./vuesax-bold-brush_2.svg";
import { ReactComponent as BoldBrush2 } from "./vuesax-bold-brush_3.svg";
import { ReactComponent as BoldMagicpen } from "./vuesax-bold-magicpen.svg";
import { ReactComponent as BoldPenTool } from "./vuesax-bold-pen-tool.svg";
import { ReactComponent as BoldBucket } from "./vuesax-bold-bucket.svg";
import { ReactComponent as BoldPaintbucket } from "./vuesax-bold-paintbucket.svg";
import { ReactComponent as BoldAdditem } from "./vuesax-bold-additem.svg";
import { ReactComponent as BoldRuler } from "./vuesax-bold-ruler.svg";
import { ReactComponent as BoldDesigntools } from "./vuesax-bold-designtools.svg";
import { ReactComponent as BoldShapes } from "./vuesax-bold-shapes.svg";
import { ReactComponent as BoldLifebuoy } from "./vuesax-bold-lifebuoy.svg";
import { ReactComponent as BoldPenTool2 } from "./vuesax-bold-pen-tool-2.svg";
import { ReactComponent as BoldBrush3 } from "./vuesax-bold-brush_4.svg";
import { ReactComponent as BoldPenClose } from "./vuesax-bold-pen-close.svg";
import { ReactComponent as BoldPenAdd } from "./vuesax-bold-pen-add.svg";
import { ReactComponent as BoldPenRemove } from "./vuesax-bold-pen-remove.svg";
import { ReactComponent as BoldPath } from "./vuesax-bold-path.svg";
import { ReactComponent as BoldPath2 } from "./vuesax-bold-path-2.svg";
import { ReactComponent as BoldBucketCircle } from "./vuesax-bold-bucket-circle.svg";
import { ReactComponent as BoldBucketSquare } from "./vuesax-bold-bucket-square.svg";
import { ReactComponent as BoldBrush4 } from "./vuesax-bold-brush_5.svg";
import { ReactComponent as BoldScissor2 } from "./vuesax-bold-scissor.svg";
import { ReactComponent as BoldGlass } from "./vuesax-bold-glass.svg";
import { ReactComponent as BoldRulerPen } from "./vuesax-bold-ruler-pen.svg";
import { ReactComponent as BoldColorSwatch } from "./vuesax-bold-color-swatch.svg";
import { ReactComponent as BoldBlend2 } from "./vuesax-bold-blend-2.svg";
import { ReactComponent as BoldPathSquare } from "./vuesax-bold-path-square.svg";
import { ReactComponent as BoldOmegaCircle } from "./vuesax-bold-omega-circle.svg";
import { ReactComponent as BoldOmegaSquare } from "./vuesax-bold-omega-square.svg";
import { ReactComponent as BoldSize } from "./vuesax-bold-size.svg";
import { ReactComponent as BoldShapes2 } from "./vuesax-bold-shapes_2.svg";
import { ReactComponent as BoldFlashCircle } from "./vuesax-bold-flash-circle.svg";
import { ReactComponent as BoldEraser2 } from "./vuesax-bold-eraser.svg";
import { ReactComponent as BoldMask } from "./vuesax-bold-mask.svg";
import { ReactComponent as BoldBlur } from "./vuesax-bold-blur.svg";
import { ReactComponent as BoldBlend3 } from "./vuesax-bold-blend.svg";
import { ReactComponent as BoldColorfilter } from "./vuesax-bold-colorfilter.svg";
import { ReactComponent as BoldColorsSquare } from "./vuesax-bold-colors-square.svg";
import { ReactComponent as BoldMask2 } from "./vuesax-bold-mask_3.svg";
import { ReactComponent as BoldBrush5 } from "./vuesax-bold-brush.svg";
import { ReactComponent as BoldLayer } from "./vuesax-bold-layer.svg";
import { ReactComponent as BoldCopy } from "./vuesax-bold-copy.svg";
import { ReactComponent as BoldCopySucces } from "./vuesax-bold-copy-success.svg";
import { ReactComponent as BoldRecoveryConvert } from "./vuesax-bold-recovery-convert.svg";
import { ReactComponent as BoldMainComponent } from "./vuesax-bold-main-component.svg";
import { ReactComponent as BoldComponent } from "./vuesax-bold-component.svg";
import { ReactComponent as BoldMask3 } from "./vuesax-bold-mask_2.svg";
import { ReactComponent as BoldForwardItem } from "./vuesax-bold-forward-item.svg";
import { ReactComponent as BoldBackwardItem } from "./vuesax-bold-backward-item.svg";

//Grid
import { ReactComponent as BoldKanban } from "./vuesax-bold-kanban.svg";
import { ReactComponent as BoldFatrows } from "./vuesax-bold-fatrows.svg";
import { ReactComponent as BoldRowVertical } from "./vuesax-bold-row-vertical.svg";
import { ReactComponent as BoldRowHorizontal } from "./vuesax-bold-row-horizontal.svg";
import { ReactComponent as BoldSliderHorizontal } from "./vuesax-bold-slider-horizontal.svg";
import { ReactComponent as BoldSliderVertical } from "./vuesax-bold-slider-vertical.svg";
import { ReactComponent as BoldSliderVertical2 } from "./vuesax-bold-slider-vertical_2.svg";
import { ReactComponent as BoldSliderHorizontal2 } from "./vuesax-bold-slider-horizontal_2.svg";
import { ReactComponent as BoldGrid4 } from "./vuesax-bold-grid-4.svg";
import { ReactComponent as BoldGrid9 } from "./vuesax-bold-grid-9.svg";
import { ReactComponent as BoldGrid2 } from "./vuesax-bold-grid-2.svg";
import { ReactComponent as BoldGrid3 } from "./vuesax-bold-grid-3.svg";
import { ReactComponent as BoldGrid5 } from "./vuesax-bold-grid-5.svg";
import { ReactComponent as BoldGrid6 } from "./vuesax-bold-grid-6.svg";
import { ReactComponent as BoldGrid7 } from "./vuesax-bold-grid-7.svg";
import { ReactComponent as BoldGrid8 } from "./vuesax-bold-grid-8.svg";
import { ReactComponent as BoldGrid1 } from "./vuesax-bold-grid-1.svg";
import { ReactComponent as BoldElement2 } from "./vuesax-bold-element-2.svg";
import { ReactComponent as BoldFrame3 } from "./vuesax-bold-frame.svg";
import { ReactComponent as BoldElement3 } from "./vuesax-bold-element-3.svg";
import { ReactComponent as BoldElement4 } from "./vuesax-bold-element-4.svg";
import { ReactComponent as BoldElelementPlus } from "./vuesax-bold-element-plus.svg";
import { ReactComponent as BoldElementEqual } from "./vuesax-bold-element-equal.svg";
import { ReactComponent as Bold3Square } from "./vuesax-bold-3-square.svg";
import { ReactComponent as BoldAlingVertically } from "./vuesax-bold-align-vertically.svg";
import { ReactComponent as BoldAlignHorizontal } from "./vuesax-bold-align-horizontally.svg";
import { ReactComponent as BoldAlignLeft } from "./vuesax-bold-align-left.svg";
import { ReactComponent as BoldAlignRight } from "./vuesax-bold-align-right.svg";
import { ReactComponent as BoldAlignBottom } from "./vuesax-bold-align-bottom.svg";
import { ReactComponent as BoldFrame4 } from "./vuesax-bold-frame_2.svg";
import { ReactComponent as BoldMaximize1 } from "./vuesax-bold-maximize.svg";
import { ReactComponent as BoldMaximize2 } from "./vuesax-bold-maximize-2.svg";
import { ReactComponent as BoldMaximize3 } from "./vuesax-bold-maximize-3.svg";
import { ReactComponent as BoldMaximize4 } from "./vuesax-bold-maximize-4.svg";
import { ReactComponent as BoldFormatCircle } from "./vuesax-bold-format-circle.svg";
import { ReactComponent as BoldFormatSquare } from "./vuesax-bold-format-square.svg";
import { ReactComponent as BoldRotateLeft } from "./vuesax-bold-rotate-left.svg";
import { ReactComponent as BoldRotateRight2 } from "./vuesax-bold-rotate-right.svg";
import { ReactComponent as BoldCrop } from "./vuesax-bold-crop.svg";
import { ReactComponent as BoldMaximize5 } from "./vuesax-bold-maximize_2.svg";
import { ReactComponent as BoldConvertshape } from "./vuesax-bold-convertshape.svg";
import { ReactComponent as BoldConvertshape2 } from "./vuesax-bold-convertshape-2.svg";
import { ReactComponent as BoldGridEraser } from "./vuesax-bold-grid-eraser.svg";
import { ReactComponent as BoldGridLock } from "./vuesax-bold-grid-lock.svg";
import { ReactComponent as BoldGridEdit } from "./vuesax-bold-grid-edit.svg";

//Shop
import { ReactComponent as BoldBag } from "./vuesax-bold-bag.svg";
import { ReactComponent as BoldBagHappy } from "./vuesax-bold-bag-happy.svg";
import { ReactComponent as BoldBagTick } from "./vuesax-bold-bag-tick.svg";
import { ReactComponent as BoldBagCross } from "./vuesax-bold-bag-cross.svg";
import { ReactComponent as BoldBagTimer } from "./vuesax-bold-bag-timer.svg";
import { ReactComponent as BoldBagTick2 } from "./vuesax-bold-bag-tick-2.svg";
import { ReactComponent as BoldBagCross2 } from "./vuesax-bold-bag-cross.svg";
import { ReactComponent as BoldBag2 } from "./vuesax-bold-bag-2.svg";
import { ReactComponent as BoldShoppingCart } from "./vuesax-bold-shopping-cart.svg";
import { ReactComponent as BoldShoppingBag } from "./vuesax-bold-shopping-bag.svg";
import { ReactComponent as BoldShopAdd } from "./vuesax-bold-shop-add.svg";
import { ReactComponent as BoldShopRemove } from "./vuesax-bold-shop-remove.svg";
import { ReactComponent as BoldShop } from "./vuesax-bold-shop.svg";
import { ReactComponent as BoldBarcode } from "./vuesax-bold-barcode.svg";

//Crypto, Currency
import { ReactComponent as BoldCardCoin } from "./vuesax-bold-card-coin.svg";
import { ReactComponent as BoldTrade } from "./vuesax-bold-trade.svg";
import { ReactComponent as BoldBuyCrypto } from "./vuesax-bold-buy-crypto.svg";
import { ReactComponent as BoldBitcoinCard } from "./bitcoin-svgrepo-com.svg";
import { ReactComponent as BoldBitcoinConvert } from "./vuesax-bold-bitcoin-convert.svg";
import { ReactComponent as BoldBitcoinRefresh } from "./vuesax-bold-bitcoin-refresh.svg";
import { ReactComponent as Mercadolibre } from "./mercadolibre-svgrepo-com.svg";
import { ReactComponent as BoldWhatsapp } from "./vuesax-bold-whatsapp.svg";

//Content, Edit
import { ReactComponent as BoldDocument2 } from "./vuesax-bold-document.svg";
import { ReactComponent as BoldDocumentText } from "./vuesax-bold-document-text.svg";
import { ReactComponent as BoldDocumentFavorite } from "./vuesax-bold-document-favorite.svg";
import { ReactComponent as BoldDocumentDownload } from "./vuesax-bold-document-download.svg";
import { ReactComponent as BoldDocumentUpload } from "./vuesax-bold-document-upload.svg";
import { ReactComponent as BoldDocumentForward } from "./vuesax-bold-document-forward.svg";
import { ReactComponent as BoldDocumentPrevious } from "./vuesax-bold-document-previous.svg";
import { ReactComponent as BoldDocumentSketch } from "./vuesax-bold-document-sketch.svg";
import { ReactComponent as BoldDocumentCloud } from "./vuesax-bold-document-cloud.svg";
import { ReactComponent as BoldDocumentCopy } from "./vuesax-bold-document-copy.svg";
import { ReactComponent as BoldDocumentNormal } from "./vuesax-bold-document-normal.svg";
import { ReactComponent as BoldClipboardText } from "./vuesax-bold-clipboard-text.svg";
import { ReactComponent as BoldClipboardTick } from "./vuesax-bold-clipboard-tick.svg";
import { ReactComponent as BoldClipboardClose } from "./vuesax-bold-clipboard-close.svg";
import { ReactComponent as BoldClipboardExport } from "./vuesax-bold-clipboard-export.svg";
import { ReactComponent as BoldClipboardImport } from "./vuesax-bold-clipboard-import.svg";
import { ReactComponent as BoldNote3 } from "./vuesax-bold-note.svg";
import { ReactComponent as BoldDocumentText2 } from "./vuesax-bold-document-text.svg";
import { ReactComponent as BoldDocumentLike } from "./vuesax-bold-document-like.svg";
import { ReactComponent as BoldEdit } from "./vuesax-bold-edit.svg";
import { ReactComponent as BoldEdit2 } from "./vuesax-bold-edit-2.svg";
import { ReactComponent as BoldCopyright } from "./vuesax-bold-copyright.svg";
import { ReactComponent as BoldCreativeCommons } from "./vuesax-bold-creative-commons.svg";
import { ReactComponent as BoldNote4 } from "./vuesax-bold-note.svg";
import { ReactComponent as BoldNoteRemove } from "./vuesax-bold-note-remove.svg";
import { ReactComponent as BoldStrickynote } from "./vuesax-bold-stickynote.svg";
import { ReactComponent as BoldNoteAdd } from "./vuesax-bold-note-add.svg";
import { ReactComponent as BoldTask } from "./vuesax-bold-task.svg";
import { ReactComponent as BoldTaskSquare } from "./vuesax-bold-task-square.svg";
import { ReactComponent as BoldBill } from "./vuesax-bold-bill.svg";
import { ReactComponent as BoldDocumentFilter } from "./vuesax-bold-document-filter.svg";
import { ReactComponent as BoldArchiveBook } from "./vuesax-bold-archive-book.svg";
import { ReactComponent as BoldNoteText } from "./vuesax-bold-note-text.svg";
import { ReactComponent as BoldNoteFavorite } from "./vuesax-bold-note-favorite.svg";
import { ReactComponent as BoldMenuBoard } from "./vuesax-bold-menu-board.svg";

//Programming
import { ReactComponent as BoldCommand } from "./vuesax-bold-command.svg";
import { ReactComponent as BoldProgrammingArrow } from "./vuesax-bold-programming-arrow.svg";
import { ReactComponent as BoldHierarchy } from "./vuesax-bold-hierarchy.svg";
import { ReactComponent as BoldHierarchy2 } from "./vuesax-bold-hierarchy-2.svg";
import { ReactComponent as BoldCommandSquare } from "./vuesax-bold-command-square.svg";
import { ReactComponent as BoldHierarchySquare } from "./vuesax-bold-hierarchy-square.svg";
import { ReactComponent as BoldHierarchySquare2 } from "./vuesax-bold-hierarchy-square-2.svg";
import { ReactComponent as BoldHierarchySquare3 } from "./vuesax-bold-hierarchy-square-3.svg";
import { ReactComponent as BoldData } from "./vuesax-bold-data.svg";
import { ReactComponent as BoldData2 } from "./vuesax-bold-data-2.svg";
import { ReactComponent as BoldDocumentCode } from "./vuesax-bold-document-code.svg";
import { ReactComponent as BoldCodeCircle } from "./vuesax-bold-code-circle.svg";
import { ReactComponent as BoldHierarchy3 } from ".//vuesax-bold-hierarchy-3.svg";
import { ReactComponent as BoldProgrammingArrows } from "./vuesax-bold-programming-arrows.svg";
import { ReactComponent as BoldDocumentCode2 } from "./vuesax-bold-document-code-2.svg";
import { ReactComponent as BoldMessageProgramming } from "./vuesax-bold-message-programming.svg";
import { ReactComponent as BoldMobileProgramming } from "./vuesax-bold-mobile-programming.svg";
import { ReactComponent as BoldCode } from "./vuesax-bold-code.svg";
import { ReactComponent as Boldcode2 } from "./vuesax-bold-code_2.svg";
import { ReactComponent as BoldHashtagUp } from "./vuesax-bold-hashtag-up.svg";
import { ReactComponent as BoldHashtag } from "./vuesax-bold-hashtag.svg";
import { ReactComponent as BoldHashtagDown } from "./vuesax-bold-hashtag-down.svg";
import { ReactComponent as BoldScroll } from "./vuesax-bold-scroll.svg";
import { ReactComponent as BoldSidebarLeft } from "./vuesax-bold-sidebar-left.svg";
import { ReactComponent as BoldSidebarRight } from "./vuesax-bold-sidebar-right.svg";
import { ReactComponent as BoldSidebarTop } from "./vuesax-bold-sidebar-top.svg";
import { ReactComponent as BoldSidebarBottom } from "./vuesax-bold-sidebar-bottom.svg";

//Essetional
import { ReactComponent as BoldTrash } from "./vuesax-bold-trash.svg";
import { ReactComponent as BoldBatteryEmpty } from "./vuesax-bold-battery-empty_2.svg";
import { ReactComponent as BoldBatteryEmpty2 } from "./vuesax-bold-battery-empty.svg";
import { ReactComponent as BoldBattery23 } from "./vuesax-bold-battery-2-3-full.svg";
import { ReactComponent as BoldBatteryFull } from "./vuesax-bold-battery-full.svg";
import { ReactComponent as BoldBatteryCharging } from "./vuesax-bold-battery-charging.svg";
import { ReactComponent as BoldBatteryDisable } from "./vuesax-bold-battery-disable.svg";
import { ReactComponent as BoldHome } from "./vuesax-bold-home.svg";
import { ReactComponent as BoldHome2 } from "./vuesax-bold-home-2.svg";
import { ReactComponent as BoldMoreSquare } from "./vuesax-bold-more-square.svg";
import { ReactComponent as BoldTickCircle } from "./vuesax-bold-tick-circle.svg";
import { ReactComponent as BoldAddCircle } from "./vuesax-bold-add-circle.svg";
import { ReactComponent as BoldMinusCircle } from "./vuesax-bold-minus-cirlce.svg";
import { ReactComponent as BoldCloseCircle } from "./vuesax-bold-close-circle.svg";
import { ReactComponent as BoldMoreCircle } from "./vuesax-bold-more-circle.svg";
import { ReactComponent as BoldInfoCircle } from "./vuesax-bold-info-circle.svg";
import { ReactComponent as BoldDanger } from "./vuesax-bold-danger.svg";
import { ReactComponent as BoldAdd } from "./vuesax-bold-add.svg";
import { ReactComponent as BoldMinus } from "./vuesax-bold-minus.svg";
import { ReactComponent as BoldAddSquare } from "./vuesax-bold-add-square.svg";
import { ReactComponent as BoldMinusSquare } from "./vuesax-bold-minus-square.svg";
import { ReactComponent as BoldTickSquare } from "./vuesax-bold-tick-square.svg";
import { ReactComponent as BoldClosedSquare } from "./vuesax-bold-close-square.svg";
import { ReactComponent as BoldSort } from "./vuesax-bold-sort.svg";
import { ReactComponent as BoldSend3 } from "./vuesax-bold-send.svg";
import { ReactComponent as BoldSend2 } from "./vuesax-bold-send-2.svg";
import { ReactComponent as BoldShare } from "./vuesax-bold-share.svg";
import { ReactComponent as BoldFlash2 } from "./vuesax-bold-flash2.svg";
import { ReactComponent as BoldFlash3 } from "./vuesax-bold-flash-slash.svg";
import { ReactComponent as BoldFlashCircle2 } from "./vuesax-bold-flash-circle.svg";
import { ReactComponent as BoldSlash } from "./vuesax-bold-slash.svg";
import { ReactComponent as BoldCake } from "./vuesax-bold-cake.svg";
import { ReactComponent as BoldGrammerly } from "./vuesax-bold-grammerly.svg";
import { ReactComponent as BoldCrown } from "./vuesax-bold-crown_2.svg";
import { ReactComponent as BoldCrown2 } from "./vuesax-bold-crown.svg";
import { ReactComponent as BoldFlag } from "./vuesax-bold-flag.svg";
import { ReactComponent as BoldFlag2 } from "./vuesax-bold-flag-2.svg";
import { ReactComponent as BoldCup } from "./vuesax-bold-cup.svg";
import { ReactComponent as BoldEmojiHappy } from "./vuesax-bold-emoji-happy.svg";
import { ReactComponent as BoldEmojiNormal } from "./vuesax-bold-emoji-normal.svg";
import { ReactComponent as BoldEmojiSad } from "./vuesax-bold-emoji-sad.svg";
import { ReactComponent as BoldTagCross } from "./vuesax-bold-tag-cross.svg";
import { ReactComponent as BoldMenu } from "./vuesax-bold-menu.svg";
import { ReactComponent as BoldCd } from "./vuesax-bold-cd.svg";

import { ReactComponent as BoldChart } from "./vuesax-bold-chart.svg";
import { ReactComponent as BoldArchive2 } from "./vuesax-bold-archive.svg";
import { ReactComponent as BoldChrome } from "./vuesax-bold-chrome.svg";
import { ReactComponent as BoldInstagram } from "./vuesax-bold-instagram.svg";
import { ReactComponent as BoldCoffe } from "./vuesax-bold-coffee.svg";
import { ReactComponent as BoldPet } from "./vuesax-bold-pet.svg";
import { ReactComponent as BoldHappyemoji } from "./vuesax-bold-happyemoji.svg";
import { ReactComponent as BoldReserve } from "./vuesax-bold-reserve.svg";
import { ReactComponent as BoldSticker } from "./vuesax-bold-sticker.svg";
import { ReactComponent as BoldComputing } from "./vuesax-bold-computing.svg";
import { ReactComponent as BoldVerify } from "./vuesax-bold-verify.svg";
import { ReactComponent as BoldInformation } from "./vuesax-bold-information.svg";
import { ReactComponent as BoldAutobrightness } from "./vuesax-bold-autobrightness.svg";
import { ReactComponent as BoldTriangle } from "./vuesax-bold-triangle.svg";
import { ReactComponent as Bold3dCube } from "./vuesax-bold-3-dcube.svg";
import { ReactComponent as BoldGlass2 } from "./vuesax-bold-glass.svg";
import { ReactComponent as BoldWeight } from "./vuesax-bold-weight.svg";
import { ReactComponent as BoldDiscover2 } from "./vuesax-bold-discover.svg";
import { ReactComponent as BoldFilter } from "./vuesax-bold-filter.svg";
import { ReactComponent as BoldFilterSquare } from "./vuesax-bold-filter-square.svg";
import { ReactComponent as BoldSound } from "./vuesax-bold-sound.svg";
import { ReactComponent as BoldStory } from "./vuesax-bold-story.svg";

import { ReactComponent as BoldSlider } from "./vuesax-bold-slider.svg";
import { ReactComponent as BoldStatus } from "./vuesax-bold-status.svg";
import { ReactComponent as BoldWifiSquare } from "./vuesax-bold-wifi-square.svg";
import { ReactComponent as BoldBox3 } from "./vuesax-bold-box-2.svg";
import { ReactComponent as BoldTrushSquare } from "./vuesax-bold-trush-square.svg";
import { ReactComponent as BoldWifi } from "./vuesax-bold-wifi.svg";
import { ReactComponent as BoldSpeedometer } from "./vuesax-bold-speedometer.svg";
import { ReactComponent as BoldDiamonds } from "./vuesax-bold-diamonds.svg";
import { ReactComponent as BoldLevel } from "./vuesax-bold-level.svg";
import { ReactComponent as BoldJudge } from "./vuesax-bold-judge.svg";
import { ReactComponent as BoldLamp } from "./vuesax-bold-lamp.svg";
import { ReactComponent as BoldTree } from "./vuesax-bold-tree.svg";
import { ReactComponent as BoldBubble } from "./vuesax-bold-bubble.svg";
import { ReactComponent as BoldMirror } from "./vuesax-bold-mirror.svg";
import { ReactComponent as BoldTagRight } from "./vuesax-bold-tag-right.svg";
import { ReactComponent as BoldMilk } from "./vuesax-bold-milk.svg";
import { ReactComponent as BoldGhost } from "./vuesax-bold-ghost.svg";
import { ReactComponent as BoldRanking } from "./vuesax-bold-ranking.svg";
import { ReactComponent as BoldMouseSquare } from "./vuesax-bold-mouse-square.svg";
import { ReactComponent as BoldMouseCircle } from "./vuesax-bold-mouse-circle.svg";
import { ReactComponent as BoldMouse } from "./vuesax-bold-mouse.svg";
import { ReactComponent as BoldForbidden } from "./vuesax-bold-forbidden.svg";

import { ReactComponent as BoldForbidden2 } from "./vuesax-bold-forbidden-2.svg";
import { ReactComponent as BoldWarning2 } from "./vuesax-bold-warning-2.svg";
import { ReactComponent as BoldBroom } from "./vuesax-bold-broom.svg";
import { ReactComponent as BoldSignpost } from "./vuesax-bold-signpost.svg";
import { ReactComponent as BoldFilterRemove } from "./vuesax-bold-filter-remove.svg";
import { ReactComponent as BoldFilterAdd } from "./vuesax-bold-filter-add.svg";
import { ReactComponent as BoldFilterSearch } from "./vuesax-bold-filter-search.svg";
import { ReactComponent as BoldFilterEdit } from "./vuesax-bold-filter-edit.svg";
import { ReactComponent as BoldFilterTick } from "./vuesax-bold-filter-tick.svg";
import { ReactComponent as BoldHome3 } from "./vuesax-bold-home.svg";
import { ReactComponent as BoldSmartHome } from "./vuesax-bold-smart-home.svg";
import { ReactComponent as BoldHomeWifi } from "./vuesax-bold-home-wifi.svg";
import { ReactComponent as BoldSafeHome } from "./vuesax-bold-safe-home.svg";

//Marca
import { ReactComponent as LogoEthicsCopy } from "./logotipo-ethics-copy.svg";
import { ReactComponent as LogoEthicsWhite } from "./logotipo-ethics-copy-white.svg";
import { ReactComponent as ImagotipoEthics } from "./imagotipo-ethics.svg";
import { ReactComponent as LogoEthicsCopyWhite } from "./logotipo-ethics-copy_2.svg";
import { ReactComponent as Collapse } from "./collapse.svg";
import { ReactComponent as BoldElement } from "./vuesax-bold-element-2.svg";
//import { ReactComponent as BoldElement } from './vuesax-bold-element-equal.svg';
import { ReactComponent as BoldDocumentText1 } from "./vuesax-bold-document-text.svg";
import { ReactComponent as BoldChart1 } from "./vuesax-bold-chart.svg";
import { ReactComponent as BoldNotification } from "./vuesax-bold-notification.svg";
import { ReactComponent as BoldDirect1 } from "./vuesax-bold-direct.svg";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as BoldMessageQuestion } from "./vuesax-bold-message-question.svg";
import { ReactComponent as LogoEthicsCopy3 } from "./logotipo-ethics-copy_3.svg";
import { ReactComponent as Collapse2 } from "./collapse_2.svg";
import { ReactComponent as BoldMessageQuestion2 } from "./vuesax-bold-message-question_2.svg";
import { ReactComponent as ImagotipoEthics2 } from "./imagotipo-ethics_2.svg";
import { ReactComponent as Group39 } from "./group-39.svg";
import { ReactComponent as ImagotipoEthics4 } from "./imagotipo-ethics_4.svg";
import { ReactComponent as Group392 } from "./group-39_2.svg";
import { ReactComponent as ImagotipoEthics5 } from "./imagotipo-ethics_5.svg";
import { ReactComponent as ProfileIcon } from "./profile.svg";

import { ReactComponent as Profile } from "./profile.svg";
import { ReactComponent as CircleYellow } from "./vuesax-bold-info-circle-yellow.svg";
import { ReactComponent as Shape } from "./shape.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //CUSTOM
  remove_from_playlist: RemoveFromPlaylist,
  outline_document_text_3: OutlineDocumentText3,
  //MONEY
  wallet_add: WalletAdd,
  receipt_item: ReceiptItem,
  empty_wallet: EmptyWallet,
  receipt_discount: ReceiptDiscount,
  empty_wallet_remove: EmptyWalletRemove,
  empty_wallet_add: EmptyWalletAdd,
  receipt_minus: ReceiptMinus,
  wallet_search: WalletSearch,
  strongbox: Strongbox,
  wallet_tick: WalletTick,
  receipt_add: ReceiptAdd,
  outline_money: OutlineMoney,
  empty_wallet_time: EmptyWalletTime,
  transaction_minus: TransactionMinus,
  outline_money_2: OutlineMoney2,
  empty_wallet_change: EmptyWalletChange,
  outline_money_3: OutlineMoney3,
  percentage_square: PercentageSquare,
  outline_money_4: OutlineMoney4,
  percentage_circle: PercentageCircle,
  chart_square_money: ChartSquareMoney,
  outline_moneys: OutlineMoneys,
  outline_coin: OutlineCoin,
  receipt_edit: ReceiptEdit,
  money_tick: MoneyTick,
  discount_shape: DiscountShape,
  receipt_search: ReceiptSearch,
  money_add: MoneyAdd,
  convert_card: ConvertCard,
  discount_circle: DiscountCircle,
  outline_wallet: OutlineWallet,
  money_remove: MoneyRemove,
  wallet_money: WalletMoney,
  card_remove: CardRemove,
  outline_cards: OutlineCards,
  money_forbiden: MoneyForbiden,
  outline_wallet_3: OutlineWallet3,
  card_tick: CardTick,
  outline_receipt_2: OutlineReceipt2,
  outline_receipt: OutlineReceipt,
  money_change: MoneyChange,
  wallet_remove: WalletRemove,
  money_time: MoneyTime,
  receipt_text: ReceiptText,
  outline_strongbox: OutlineStrongbox,
  outline_wallet_2: OutlineWallet2,
  security_card: SecurityCard,
  money_recive: MoneyRecive,
  money_send: MoneySend,
  outline_archive: OutlineArchive,
  outline_tag: OutlineTag,
  outline_tag_2: OutlineTag2,
  outline_coin_2: OutlineCoin2,
  outline_document: OutlineDocument,
  wallet_4: Wallet4,
  card_add: CardAdd,
  card_remove_2: CardRemove2,
  card_tick_2: CardTick2,
  card_slash: CardSlash,
  card_pos: CardPos,
  card: Card,
  card_receive: CardReceive,
  card_send: CardSend,
  card_edit: CardEdit,
  wallet_minus: WalletMinus,
  wallet_add_2: WalletAdd2,
  dollar_square: DollarSquare,
  dollar_circle: DollarCircle,
  receipt_1: Receipt1,
  receipt_3: Receipt3,
  receipt_disscount: ReceiptDisscount,
  ticket: Ticket,
  ticket_star: TicketStar,
  ticket_expired: TicketExpired,
  ticket_2: Ticket2,
  ticket_discount: TicketDiscount,
  math: Math,

  //TYPE-PARAGRAPH-CHARACTER
  textaling_left: TextalingLeft,
  textaling_center: TextalingCenter,
  textaling_right: TextalingRight,
  textaling_justify_right: TextalingJustifyRight,
  textaling_justify_center: TextalingJustifyCenter,
  textaling_justify_left: TextalingJustifyLeft,
  first_line: FirstLine,
  paperclip_2: Paperclip2,
  paperclip: Paperclip,
  link: Link,
  link_1: Link1,
  link_2: Link2,
  textblock: TextBlock,
  textitalic: TextItalic,
  text: Text,
  smallcaps: Smallcaps,
  textbold: TextBold,
  underline: Underline,
  pharagraphspacing: Pharagraphspacing,
  maximize: Maximize,
  eraser: Eraser,
  square: Square,
  attach_circle: AttachCircle,
  quote_up: QuoteUp,
  quote_down: QuoteDown,
  quoteup_square: QuoteUpSquare,
  quotedown_square: QuoteDownSquare,
  quotedown_circle: QuoteDownCircle,
  quoteup_circle: QuoteUpCircle,
  link_3: Link3,
  link_square: LinkSquare,
  link_circle: LinkCircle,
  translate: Translate,
  lenguage_square: LenguageSquare,
  lenguage_circle: LenguageCircle,

  //Weather
  moon: Moon,
  sun: Sun,
  cloud: Cloud,
  cloud_plus: CloudPlus,
  cloud_minus: CloudMinus,
  cloud_cross: CloudCross,
  cloud_sunny: CloudSunny,
  cloud_notif: CloudNotif,
  cloud_drizzle: CloudDrizzle,
  cloud_snow: CloudSnow,
  cloud_lightning: CloudLightning,
  outline_flash2: OutlineFlash2,
  outline_icon: OutlineIcon,
  outline_wind: OutlineWind,
  wind_2: Wind2,
  cloud_fog: CloudFog,
  sun_fog: SunFog,
  outline_sun: OutlineSun,

  //files
  folder: Folder,
  folder_cross: FolderCross,
  folder_add: FolderAdd,
  folder_minus: FolderMinus,
  folder_favorite: FolderFavorite,
  folder_2: Folder_2,
  folder_open: FolderOpen,
  folder_cloud: FolderCloud,

  //Video, Audio, Image
  microphone: Microphone,
  microphone_slash: MicrophoneSlash,
  microphone_2: Microphone2,
  microphone_slash_2: MicrophoneSlash2,
  volume_cross: VolumeCross,
  volume_slash: VolumeSlash,
  volume_up: VolumeUp,
  volume_low_2: VolumeLow2,
  volume_low: VolumeLow,
  volume_high: VolumeHigh,
  volume_mute: VolumeMute,
  outline_video: OutlineVideo,
  video_slash: VideoSlash,
  video_square: VideoSquare,
  video_circle: VideoCircle,
  gallery: Gallery,
  gallery_favorite: GalleryFavorite,
  gallery_slash: GallerySlash,
  gallery_tick: GalleryTick,
  gallery_import: GalleryImport,
  gallery_export: GalleryExport,
  gallery_add: GalleryAdd,
  gallery_remove: GalleryRemove,
  gallery_edit: GalleryEdit,
  image: Image,
  sreenmirroring: Sreenmirroring,
  camera: Camera,
  camera_slash: CameraSlash,
  play: Play,
  record: Record,
  stop: Stop,
  music_note: MusicNote,
  music: Music,
  video_vertical: VideoVertical,
  video_horizontal: VideoHorizontal,
  video_play: VideoPlay,
  subtitle: Subtitle,
  play_circle: PlayCircle,
  record_circle: RecordCircle,
  stop_circle: StopCircle,
  pause_circle: PauseCircle,
  pause: Pause,
  scissor: Scissor,
  backward: Backward,
  forward: Forward,
  previous: Previous,
  netx: Netx,
  radio: Radio,
  music_circle: MusicCircle,
  play_cricle: PlayCricle,
  play_remove: PlayRemove,
  play_add: PlayAdd,
  backward_5: Backward5,
  backward_10: Backward10,
  backward_15: Backward15,
  forward_5: Forward5,
  forward_10: Forward10,
  forward_15: Forward15,
  music_filter_2: MusicFilter2,
  music_dasboard: MusicDasboard,
  square_remove: SquareRemove,
  saquare_add: SaquareAdd,
  square_searh: SquareSearh,
  repeat_music: RepeatMusic,
  repeat_one: RepeatOne,
  shuffle: Shuffle,
  devices: Devices,
  playlist: Playlist,
  library_2: Library2,
  music_square: MusicSquare,
  audio_square: AudioSquare,
  note_square: NoteSquare,
  mini_note_square: MiniNoteSquare,
  video_add: VideoAdd,
  video_remove: VideoRemove,
  video_tick: VideoTick,
  video_time: VideoTime,
  video_octagon: VideoOctagon,
  voice_square: VoiceSquare,
  voice_circle: VoiceCircle,
  maximize_circle: MaximizeCircle,

  //Arrow
  received: Received,
  send: Send,
  arrow_2: Arrow2,
  arrow_3: Arrow3,
  arrow_square_up: ArrowSquareUp,
  arrow_square_down: ArrowSquareDown,
  arrow_square_left: ArrowSquareLeft,
  arrow_square_right: ArrowSquareRight,
  arrow_right: ArrowRight,
  arrow_left: ArrowLeft,
  arrow_up_4: ArrowUp4,
  arrow_down: ArrowDown,
  arrow_up_3: ArrowUp3,
  arrow_down_2: ArrowDown2,
  outline_arrow_right_4: OutlineArrowRight4,
  arrow_left_3: Arrowleft3,
  arrow_circle_left: ArrowCircleLeft,
  arrow_circle_right: ArrowCircleRight,
  arrow_circle_down: ArrowCircleDown,
  arrow_circle_up: ArrowCircleUp,
  arrow_swap: ArrowSwap,
  arrow_swap_horizontal: ArrowSwapHorizontal,
  outline_undo: OutlineUndo,
  outline_redo: OutlineRedo,
  outline_rotate_left: OutlineRotateLeft,
  outline_rotate_right: OutlineRotateRight,
  outline_refresh_2: OutlineRefresh2,
  outline_arrow_left_2: OutlineArrowLeft2,
  outline_arrow_right_5: OutlineArrowRight5,
  outline_arrow_up: OutlineArrowUp,
  outline_arrow_bottom: OutlineArrowBottom,
  outline_arrow_up_2: OutlineArrowUp2,
  outline_arrow_right_3: OutlineArrowRight3,
  outline_arrow_left_4: OutlineArrowLeft4,
  outline_arrow_down: OutlineArrowDown,
  outline_convert: OutlineConvert,
  outline_arrange_square: OutlineArrangeSquare,
  outline_arrage_square_2: OutlineArrageSquare2,
  outline_back_square: OutlineBackSquare,
  outline_forward_square: OutlineForwardSquare,
  outline_refresh_square_2: OutlineRefreshSquare2,
  outline_refresh_right_square: OutlineRefreshRightSquare,
  outline_refresh_left_square: OutlineRefreshLeftSquare,
  outline_receive_square: OutlineReceiveSquare,
  outline_send_square: OutlineSendSquare,
  outline_send_square_2: OutlineSendSquare2,
  outline_receive_square_2: OutlineReceiveSquare2,
  outline_login_2: OutlineLogin2,
  outline_logout_2: OutlineLogout2,
  outline_import: OutlineImport,
  outline_export: OutlineExport,
  outline_export_2: OutlineExport2,
  outline_import_2: OutlineImport2,
  outline_login: OutlineLogin,
  outline_logout: OutlineLogout,
  outline_export_3: OutlineExport3,
  outline_frame: OutlineFrame,
  outline_import_3: OutlineImport3,
  outline_export_4: OutlineExport4,
  outline_refresh: OutlineRefresh,
  outline_arrange_circle: OutlineArrangeCircle,
  outline_arrange_circle_2: OutlineArrangeCircle2,
  outline_repeat: OutlineRepeat,
  outline_repeat_circle: OutlineRepeatCircle,
  outline_refresh_circle: OutlineRefreshCircle,

  //Users
  outline_user: OutlineUser,
  outline_user_add: OutlineUserAdd,
  outline_user_remove: OutlineUserRemove,
  outline_user_tick: OutlineUserTick,
  outline_user_minus: OutlineUserMinus,
  outline_user_search: OutlineUserSearch,
  outline_user_edit: OutlineUserEdit,
  outline_user_tag: OutlineUserTag,
  outline_user_octagon: OutlineUserOctagon,
  outline_user_circle_add: OutlineUserCircleAdd,
  outline_user_square: OutlineUserSquare,
  outline_frame_2: OutlineFrame2,
  outline_profile_2_user: OutlineProfile2User,
  outline_profile_remove: OutlineProfileRemove,
  outline_profile_tick: OutlineProfileTick,
  outline_profile_add: OutlineProfileAdd,
  outline_profile_delete: OutlineProfileDelete,
  outline_profile_circle: OutlineProfileCircle,
  outline_tag_user: OutlineTagUser,
  outline_people: OutlinePeople,

  //Essetional
  trash_can: TrashCan,
  empty_battery: EmptyBattery,
  "1_3_battery": OneThirdBattery,
  "2_3_battery": TwoThirdBattery,
  full_battery: FullBattery,
  charge_battery: ChargeBattery,
  disable_battery: DisableBattery,
  home: HomeIcon,
  home_two: HomeIconTwo,
  more: MoreIcon,
  check_icon: CheckIcon,
  close_icon: CloseIcon,
  check_circle: CheckIconCircle,
  add_circle: AddIconCircle,
  minus_circle: MinusIconCircle,
  cancel_circle: CancelIconCircle,
  more_circle: MoreIconCircle,
  info_circle: InfoIconCircle,
  warning: WarningIcon,
  add: AddIcon,
  add_icon: AddIcon2,
  minus: MinusIcon,
  add_square: AddIconSquare,
  minus_square: MinusIconSquare,
  check_square: CheckIconSquare,
  cancel_square: CancelIconSquare,
  sort: SortIcon,
  send_essational: SendIcon,
  send2: SendIcon2,
  share_circle: ShareCircle,
  outline_flash: FlashIcon,
  flash_cancel: CancelFlashIcon,
  flash_circle: FlashCircle,
  cancel_icon_slash: CancelIcon,
  cake_icon: CakeIcon,
  grammerly: Grammerly,
  crown_king: CrownKingIcon,
  crown_queen: CrownQueenIcon,
  flag_square: FlagIcon,
  flag_triangle: FlagIcon2,
  trophy: TrophyIcon,
  happy_square: HappySquare,
  happy_square2: HappySquare2,
  sad_square: SadSquare,
  cancel_tag: TagCancelIcon,
  hamburguer_menu: HamburguerMenu,
  cd_icon: CdIcon,
  circle_graph: CircleGraph,
  archive: ArchiveIcon,
  chrome: ChromeIcon,
  instagram: InstagramIcon,
  coffee_cup: CoffeeCup,
  pet_paw: PetPaw,
  happy_circle: HappyCircle,
  reservation_bell: ReservationBell,
  postIt_circle: PostItCicle,
  computing_square: ComputingSquare,
  verify_circle: VerifyCircle,
  information_circle: InformationCircle,
  autoBright: AutoBright,
  triangleChrome: TriangleChrome,
  cube3D: Cube3D,
  glasses: Glasses,
  discover: Discover,
  weight: Weight,
  filter: Filter,
  filter_square: FilterSquare,
  sound_wave: SoundWave,
  story: StoryIcon,
  slider: SliderIcon,
  status: StatusIcon,
  wifi_square: WifiSquare,
  outline_box_square: OutlineSquareBox,
  trash_icon: TrashSquareIcon,
  wifi_icon: WifiIcon,
  speedometer: Speedometer,
  diamonds: Diamonds,
  level_circle: LevelCircle,
  judge_hammer: JudgeHammer,
  lamp: LampIcon,
  tree: TreeIcon,
  bubbles: Bubbles,
  mirror: Mirror,
  tag_right: TagRight,
  milk_bottle: MilkBottleIcon,
  ghost: GhostIcon,
  podium: PodiumIcon,
  mouse_square: MouseSquare,
  mouse_circle: MouseCircle,
  mouse_inside_circle: MouseInsideCircle,
  forbidden: ForbiddenIcon,
  forbidden2: ForbiddenIcon2,
  warning_hexagon: WarningIcon2,
  broom: BroomIcon,
  signpost: SignPost,
  filter_remove: FilterRemove,
  filter_add: FilterAdd,
  filter_search: FilterSearch,
  filter_edit: FilterEdit,
  filter_check: FilterCheck,
  home_pentagon: HomeIconThree,
  smart_home: SmartHome,
  home_wifi: HomeWifi,
  home_safe: SafeHome,
  //Support, Like, question
  star: StarIcon,
  star_cancel: StarCancelIcon,
  love: LoveIcon,
  like: LikeIcon,
  dislike: DisLikeIcon,
  star_dash: DashStarIcon,
  star_down_dash: DashDownStarIcon,
  star_magic: MagicStar,
  love_edit: LoveEdit,
  love_check: LoveCheck,
  love_search: LoveSearch,
  love_cancel: LoveCancel,
  love_remove: LoveRemove,
  love_add: LoveAdd,
  smileys: Smileys,
  like_dislike: LikeDislike,
  like_tag: LikeTag,
  "24_support": TwentyFourSupport,
  like_shape: LikeShape,
  question_bubble: QuestionBubble,
  prize: PrizeIcon,
  lovely: LovelyIcon,
  medal: Medal,
  infinity: Unlimited,
  outline_like: OutlineLike,
  love_circle: LoveCircle,

  //Notifications
  notification_fill: NotificationFill,
  notification: NotificationIcon,
  pending_notification_square: PendingNotificationSquare,
  pending_notification_favourite: PendingNotificationFavourite,
  pending_notification_status: PendingNotificationStatus,
  pending_notification_circle: PendingNotificationCircle,
  lightBulb: LightBulb,
  lightBulb_charge: LightBulbCharge,
  lightBulb_slash: LightBulbSlash,

  //Settings
  category: Category,
  category2: Category2,
  setting: Setting,
  settings: Settings,
  settings2: Settings2,
  settings3: Settings3,
  settings4: Settings4,
  more_dots: MoreIconDots,
  more_dots2: MoreIconDots2,
  toggleOff: ToggleOff,
  toggleOff_circle: ToggleOffCircle,
  toggleOn: ToggleOn,
  toggleOn_circle: ToggleOnCircle,
  menu_dot: MenuDot,
  options: OptionsIcon,
  options2: OptionsIcon2,
  option: Option,

  //Archive
  mark_check: MarkCheck,
  archive_tag: ArchiveTag,
  archive_tag_cancel: ArchiveTagCancel,
  archive_tag_add: ArchiveTagAdd,
  archive_tag_minus: ArchiveTagMinus,
  receipt_square: ReceiptSquare,
  book_square: BookSquare,
  save: SaveIcon,
  save_add: SaveAdd,
  save_minus: SaveMinus,
  save_remove: SaveRemove,
  book_save: BookSave,

  //Time
  timer: Timer,
  clock: Clock,
  timer2: Timer2,
  timer_pause: TimerPause,
  timer_play: TimerPlay,
  timer_security: TimeSecurity,
  calendar: Calendar,
  calendar_check: CalendarCheck,
  calendar_search: CalendarSearch,
  calendar_add: CalendarAdd,
  calendar_remove: CalendarRemove,
  calendar_edit: CalendarEdit,
  calendar2: Calendar2,
  calendar_circle: CalendarCircle,
  calendar3: Calendar3,

  //Computers, Devices, Electronics
  printer: Printer,
  printer_cancel: PrinterCancel,
  chip: Chip,
  chip_charge: ChipCharge,
  chip_settings: ChipSettings,
  monitor: Monitor,
  monitor_recorder: MonitorRecorder,
  monitor_mobile: MonitorMobile,
  headphones: Headphones,
  cable_headphones: CableHeadphones,
  headphones_play: HeadphonesPlay,
  airpod: Airpod,
  speaker: Speaker,
  driver: Driver,
  driver2: Driver2,
  driver_refresh: DriverRefresh,
  cloud_add: CloudAdd,
  cloud_remove: CloudRemove,
  cloud_connection: CloudConnection,
  external_drive: ExternalDrive,
  devices2: Devices2,
  Airpods: Airpods,
  folder_connection: FolderConnection,
  game_control: GameControl,
  weight_control: WeightControl,
  keyboard: Keyboard,
  mouse: Mouse,
  watch: Watch,
  watch2: Watch2,
  watch_status: WatchStatus,
  ram: Ram,
  ram2: Ram2,
  sim_card: SimCard,
  sim_card2: SimCard2,
  sim_card3: SimCard3,
  desk: Desk,
  mobile: Mobile,
  plug: Plug,
  screen_mirroring: ScreenMirroring,
  telescope: Telescope,
  open_keyboard: OpenKeyboard,
  air_drop: AirDrop,
  gameBoy: GameBoy,
  bluetooth: Bluetooth,
  bluetooth2: Bluetooth2,
  bluetooth_circle: BluetoothCircle,
  bluetooth_square: BluetoothSquare,
  cloud_change: CloudChange,

  //Call
  call: Call,
  incoming_call: IncomingCall,
  calling: Calling,
  outgoing_call: OutgoingCall,
  add_call: CallAdd,
  minus_call: CallMinus,
  remove_call: CallRemove,
  received_call: CallReceived,
  cancel_call: CallCancel,

  //Business
  chart_success: ChartSuccess,
  chart_fail: ChartFail,
  chart_square: ChartSquare,
  pie_chart: PieChart,
  activity: Activity,
  home_trending: HomeTrending,
  home_trending_down: HomeTrendingDown,
  home_hash: HomeHash,
  trend_up: TrendUp,
  trend_down: TrendDown,
  hash_square: HashSquare,
  outline_chart: OutlineChart,
  personal_id: PersonalId,
  status_up: StatusUp,
  line_chart: LineChart,
  presentation: Presentation,
  bar_chart: BarChart,
  bar_chart2: BarChart2,
  health: Health,
  favourite_chart: FavouriteChart,

  //Building
  building: Building,
  building2: Building2,
  building3: Building3,
  building4: Building4,
  buildings: Buildings,
  buildings2: Buildings2,
  courtHouse: CourtHouse,
  house: House,
  house2: House2,
  bank: Bank,
  hospital: Hospital,

  //Astrology
  aquarius: Aquarius,
  gemini: Gemini,
  gemini2: Gemini2,
  sagittarius: Sagittarius,
  male: Male,
  female: Female,

  //Search
  search: SearchIcon,
  search2: SearchIcon2,
  zoom_in: ZoomIn,
  zoom_in2: ZoomIn2,
  zoom_out: ZoomOut,
  zoom_out2: ZoomOut2,
  search_favourite: SearchFavourite,
  search_favourite2: SearchFavourite2,
  search_status: SearchStatus,
  search_status2: SearchStatus2,

  /**********************
     **********************
     **********************
     **********************
     **********************
     **********************
     **********************
        BOLD ICONS
     **+*******************
     **********************
     **********************
     **********************
     **********************
     **********************/

  //Support, Like, question
  star_bold: StarIconBold,
  star_cancel_bold: StarCancelIconBold,
  love_bold: LoveIconBold,
  like_bold: LikeIconBold,
  dislike_bold: DisLikeIconBold,
  star_dash_bold: DashStarIconBold,
  star_down_dash_bold: DashDownStarIconBold,
  star_magic_bold: MagicStarBold,
  love_edit_bold: LoveEditBold,
  love_check_bold: LoveCheckBold,
  love_search_bold: LoveSearchBold,
  love_cancel_bold: LoveCancelBold,
  love_remove_bold: LoveRemoveBold,
  love_add_bold: LoveAddBold,
  smileys_bold: SmileysBold,
  like_dislike_bold: LikeDislikeBold,
  like_tag_bold: LikeTagBold,
  "24_support_bold": TwentyFourSupportBold,
  like_shape_bold: LikeShapeBold,
  question_bubble_bold: QuestionBubbleBold,
  prize_bold: PrizeIconBold,
  lovely_bold: LovelyIconBold,
  medal_bold: MedalBold,
  infinity_bold: UnlimitedBold,
  outline_like_bold: BoldLike,
  love_circle_bold: LoveCircleBold,

  //Notifications
  notification_fill_bold: NotificationFillBold,
  notification_bold: NotificationIconBold,
  pending_notification_square_bold: PendingNotificationSquareBold,
  pending_notification_favourite_bold: PendingNotificationFavouriteBold,
  pending_notification_status_bold: PendingNotificationStatusBold,
  pending_notification_circle_bold: PendingNotificationCircleBold,
  lightBulb_bold: LightBulbBold,
  lightBulb_charge_bold: LightBulbChargeBold,
  lightBulb_slash_bold: LightBulbSlashBold,

  //Settings
  category_bold: CategoryBold,
  category2_bold: Category2Bold,
  setting_bold: SettingBold,
  settings_bold: SettingsBold,
  settings2_bold: Settings2Bold,
  settings3_bold: Settings3Bold,
  settings4_bold: Settings4Bold,
  more_dots_bold: MoreIconDotsBold,
  more_dots2_bold: MoreIconDots2Bold,
  toggleOff_bold: ToggleOffBold,
  toggleOff_circle_bold: ToggleOffCircleBold,
  toggleOn_bold: ToggleOnBold,
  toggleOn_circle_bold: ToggleOnCircleBold,
  menu_dot_bold: MenuDotBold,
  options_bold: OptionsIconBold,
  options2_bold: OptionsIcon2Bold,
  option_bold: OptionBold,

  //Archive
  mark_check_bold: MarkCheckBold,
  archive_tag_bold: ArchiveTagBold,
  archive_tag_cancel_bold: ArchiveTagCancelBold,
  archive_tag_add_bold: ArchiveTagAddBold,
  archive_tag_minus_bold: ArchiveTagMinusBold,
  receipt_square_bold: ReceiptSquareBold,
  book_square_bold: BookSquareBold,
  save_bold: SaveIconBold,
  save_add_bold: SaveAddBold,
  save_minus_bold: SaveMinusBold,
  save_remove_bold: SaveRemoveBold,
  book_save_bold: BookSaveBold,

  //Time
  timer_bold: TimerBold,
  clock_bold: ClockBold,
  timer2_bold: Timer2Bold,
  timer_pause_bold: TimerPauseBold,
  timer_play_bold: TimerPlayBold,
  timer_security_bold: TimeSecurityBold,
  calendar_bold: CalendarBold,
  calendar_check_bold: CalendarCheckBold,
  calendar_search_bold: CalendarSearchBold,
  calendar_add_bold: CalendarAddBold,
  calendar_remove_bold: CalendarRemoveBold,
  calendar_edit_bold: CalendarEditBold,
  calendar2_bold: Calendar2Bold,
  calendar_circle_bold: CalendarCircleBold,
  calendar3_bold: Calendar3Bold,

  //Computers, Devices, Electronics
  printer_bold: PrinterBold,
  printer_cancel_bold: PrinterCancelBold,
  chip_bold: ChipBold,
  chip_charge_bold: ChipChargeBold,
  chip_settings_bold: ChipSettingsBold,
  monitor_bold: MonitorBold,
  monitor_recorder_bold: MonitorRecorderBold,
  monitor_mobile_bold: MonitorMobileBold,
  headphones_bold: HeadphonesBold,
  cable_headphones_bold: CableHeadphonesBold,
  headphones_play_bold: HeadphonesPlayBold,
  airpod_bold: AirpodBold,
  speaker_bold: SpeakerBold,
  driver_bold: DriverBold,
  driver2_bold: Driver2Bold,
  driver_refresh_bold: DriverRefreshBold,
  cloud_add_bold: CloudAddBold,
  cloud_remove_bold: CloudRemoveBold,
  cloud_connection_bold: CloudConnectionBold,
  external_drive_bold: ExternalDriveBold,
  devices2_bold: Devices2Bold,
  Airpods_bold: AirpodsBold,
  folder_connection_bold: FolderConnectionBold,
  game_control_bold: GameControlBold,
  weight_control_bold: WeightControlBold,
  keyboard_bold: KeyboardBold,
  mouse_bold: MouseBold,
  watch_bold: WatchBold,
  watch2_bold: Watch2Bold,
  watch_status_bold: WatchStatusBold,
  ram_bold: RamBold,
  ram2_bold: Ram2Bold,
  sim_card_bold: SimCardBold,
  sim_card2_bold: SimCard2Bold,
  sim_card3_bold: SimCard3Bold,
  desk_bold: DeskBold,
  mobile_bold: MobileBold,
  plug_bold: PlugBold,
  screen_mirroring_bold: ScreenMirroringBold,
  telescope_bold: TelescopeBold,
  open_keyboard_bold: OpenKeyboardBold,
  air_drop_bold: AirDropBold,
  gameBoy_bold: GameBoyBold,
  bluetooth_bold: BluetoothBold,
  bluetooth2_bold: Bluetooth2Bold,
  bluetooth_circle_bold: BluetoothCircleBold,
  bluetooth_square_bold: BluetoothSquareBold,
  cloud_change_bold: CloudChangeBold,

  //Call
  call_bold: CallBold,
  incoming_call_bold: IncomingCallBold,
  calling_bold: CallingBold,
  outgoing_call_bold: OutgoingCallBold,
  add_call_bold: CallAddBold,
  minus_call_bold: CallMinusBold,
  remove_call_bold: CallRemoveBold,
  received_call_bold: CallReceivedBold,
  cancel_call_bold: CallCancelBold,

  //Business
  chart_success_bold: ChartSuccessBold,
  chart_fail_bold: ChartFailBold,
  chart_square_bold: ChartSquareBold,
  pie_chart_bold: PieChartBold,
  activity_bold: ActivityBold,
  home_trending_bold: HomeTrendingBold,
  home_trending_down_bold: HomeTrendingDownBold,
  home_hash_bold: HomeHashBold,
  trend_up_bold: TrendUpBold,
  trend_down_bold: TrendDownBold,
  hash_square_bold: HashSquareBold,
  outline_chart_bold: BoldChartBusiness,
  personal_id_bold: PersonalIdBold,
  status_up_bold: StatusUpBold,
  line_chart_bold: LineChartBold,
  presentation_bold: PresentationBold,
  bar_chart_bold: BarChartBold,
  bar_chart2_bold: BarChart2Bold,
  health_bold: HealthBold,
  favourite_chart_bold: FavouriteChartBold,

  //Building
  building_bold: BuildingBold,
  building2_bold: Building2Bold,
  building3_bold: Building3Bold,
  building4_bold: Building4Bold,
  buildings_bold: BuildingsBold,
  buildings2_bold: Buildings2Bold,
  courtHouse_bold: CourtHouseBold,
  house_bold: HouseBold,
  house2_bold: House2Bold,
  bank_bold: BankBold,
  hospital_bold: HospitalBold,

  //Astrology
  aquarius_bold: AquariusBold,
  gemini_bold: GeminiBold,
  gemini2_bold: Gemini2Bold,
  sagittarius_bold: SagittariusBold,
  male_bold: MaleBold,
  female_bold: FemaleBold,

  //Search
  search_bold: SearchIconBold,
  search2_bold: SearchIcon2Bold,
  zoom_in_bold: ZoomInBold,
  zoom_in2_bold: ZoomIn2Bold,
  zoom_out_bold: ZoomOutBold,
  zoom_out2_bold: ZoomOut2Bold,
  search_favourite_bold: SearchFavouriteBold,
  search_favourite2_bold: SearchFavourite2Bold,
  search_status_bold: SearchStatusBold,
  search_status2_bold: SearchStatus2Bold,
  //Car
  outline_airplane: OutlineAirplane,
  outline_car: OutlineCar,
  outline_bus: OutlineBus,
  outline_smart_car: OutlineSmartCar,
  outline_driving: OutlineDriving,
  outline_ship: OutlineShip,
  outline_airplane_square: OutlineAirplaneSquare,
  outline_gas_station: OutlineGasStation,

  //Emails, Messages
  outline_directbox_deafault: OutlineDirectboxDeafault,
  outline_directbox_notif: OutlineDirectboxNotif,
  outline_directbox_receive: OutlineDirectboxReceive,
  outline_directbox_send: OutlineDirectboxSend,
  outline_direct_normal: OutlineDirectNormal,
  outline_direct_direct: OutlineDirectDirect,
  outline_direct_notification: OutlineDirectNotification,
  outline_direct_inbox: OutlineDirectInbox,
  outline_direct_send: OutlineDirectSend,
  outline_message: OutlineMessage,
  outline_message_favorite: OutlineMessageFavorite,
  outline_message_notif: OutlineMessageNotif,
  outline_message_search: OutlineMessageSearch,
  outline_message_text: OutlineMessageText,
  outline_device_message: OutlineDeviceMessage,
  outline_messages: OutlineMessages,
  outline_messages_2: OutlineMessages2,
  outline_messages_3: OutlineMessages3,
  outline_sms: OutlineSms,
  outline_sms_tracking: OutlineSmsTracking,
  outline_sms_notification: OutlineSmsNotification,
  outline_sms_edit: OutlineSmsEdit,
  "outline-sms_star": OutlineSmsStar,
  "outline_sms-search": OutlineSmsSearch,
  outline_message_remove: OutlineMessageRemove,
  outline_message_add: OutlineMessageAdd,
  outline_message_tick: OutlineMessageTick,
  outline_message_time: OutlineMessageTime,
  outline_message_4: OutlineMessage4,
  outline_messages_4: OutlineMessages4,
  outline_message_circle: OutlineMessageCircle,
  outline_message_square: OutlineMessageSquare,
  outline_message_text_2: OutlineMessageText2,
  outline_message_minus: OutlineMessageMinus,
  outline_message_add_2: OutlineMessageAdd2,
  outline_message_edit: OutlineMessageEdit,

  //Location
  outline_location_minus: OutlineLocationMinus,
  outline_location_cross: OutlineLocationCross,
  outline_location_tick: OutlineLocationTick,
  outline_location_slash: OutlineLocationSlash,
  outline_discover: OutlineDiscover,
  outline_location: OutlineLocation,
  outline_location_add: OutlineLocationAdd,
  outline_radar: OutlineRadar,
  outline_radar_2: OutlineRadar2,
  outline_global_search: OutlineGlobalSearch,
  outline_global_edit: OutlineGlobalEdit,
  outline_global_refresh: OutlineGlobalRefresh,
  outline_global: OutlineGlobal,
  outline_direct_up: OutlineDirectUp,
  outline_direct_down: OutlineDirectDown,
  outline_direct_left: OutlineDirectLeft,
  outline_direct_right: OutlineDirectRight,
  outline_map: OutlineMap,
  outline_gps: OutlineGps,
  outline_gps_slash: OutlineGpsSlash,
  outline_map_2: OutlineMap2,
  outline_routing: OutlineRouting,
  outline_routing_2: OutlineRouting2,
  outline_arrow_square: OutlineArrowSquare,
  outline_picture_frame: OutlinePictureFrame,
  outline_arrow: OutlineArrow,
  outline_route_quare: OutlineRouteSquare,

  //School, Learning
  outline_briefcase: OutlineBriefcase,
  outline_brifecase_timer: OutlineBrifecaseTimer,
  outline_briefcase_tick: OutlineBriefcaseTick,
  outline_briefcase_cross: OutlineBriefcaseCross,
  outline_briefcase_bookmark: OutlineBriefcaseBookmark,
  outline_award: OutlineAward,
  outline_book: OutlineBook,
  outline_book_2: OutlineBook2,
  outline_bookmark_2: OutlineBookmark2,
  outline_clipboard: OutlineClipboard,
  outline_note: OutlineNote,
  outline_note_2: OutlineNote2,
  outline_teacher: OutlineTeacher,
  outline_gift: OutlineGift,
  outline_calculator: OutlineCalculator,

  //Delivery
  outline_box: OutlineBox,
  outline_3d_cube_scan: Outline3dCubeScan,
  outline_3d_cube: Outline3dCube,
  outline_box_add: OutlineBoxAdd,
  outline_box_remove: OutlineBoxRemove,
  outline_box_tick: OutlineBoxTick,
  outline_box_time: OutlineBoxTime,
  outline_box_2: OutlineBox2,
  outline_box_search: OutlineBoxSearch,
  outline_truck: OutlineTruck,
  outline_truck_fast: OutlineTruckFast,
  outline_truck_tick: OutlineTruckTick,
  outline_truck_time: OutlineTruckTime,
  outline_truck_remove: OutlineTruckRemove,
  outline_3d_square: Outline3dSquare,
  outline_3d_rotate: Outline3dRotate,

  //Security
  outline_eye: OutlineEye,
  outline_eye_slash: OutlineEyeSlash,
  outline_shield: OutlineShield,
  outline_shield_tick: OutlineShieldTick,
  outline_shield_cross: OutlineShieldCross,
  outline_shield_slash: OutlineShieldSlash,
  outline_frame_3: OutlineFrame3,
  outline_password_check: OutlinePasswordCheck,
  outline_shield_search: OutlineShieldSearch,
  outline_key: OutlineKey,
  outline_key_square: OutlineKeySquare,
  outline_check: OutlineCheck,
  outline_lock: OutlineLock,
  outline_unlock: OutlineUnlock,
  outline_lock_slash: OutlineLockSlash,
  outline_scan: OutlineScan,
  outline_scan_barcode: OutlineScanBarcode,
  outline_scanning: OutlineScanning,
  outline_finger_scan: OutlineFingerScan,
  outline_finger_circle: OutlineFingerCircle,
  outline_scanner: OutlineScanner,
  "outline-security_user": OutlineSecurityUser,
  outline_lock_2: OutlineLock2,
  outline_lock_circle: OutlineLockCircle,
  outline_alarm: OutlineAlarm,
  outline_radar_3: OutlineRadar3,
  outline_security_safe: OutlineSecuritySafe,
  outline_security: OutlineSecurity,

  //Desing, tools
  outline_bezier: OutlineBezier,
  outline_brush: OutlineBrush,
  outline_brush_2: OutlineBrush2,
  outline_magicpen: OutlineMagicpen,
  broken_pen_tool: BrokenPenTool,
  broken_bucket: BrokenBucket,
  outline_paintbucket: OutlinePaintbucket,
  outline_additem: OutlineAdditem,
  outline_ruler: OutlineRuler,
  outline_desingtools: OutlineDesingtools,
  outline_shapes: OutlineShapes,
  outline_lifebuoy: OutlineLifebuoy,
  outline_pen_tool_2: OutlinePenTool2,
  outline_brush_3: OutlineBrush3,
  outline_pen_close: OutlinePenClose,
  outline_pen_add: OutlinePenAdd,
  outline_pen_remove: OutlinePenRemove,
  outline_path: OutlinePath,
  outline_path_2: OutlinePath2,
  outline_bucket_circle: OutlineBucketCircle,
  outline_bucket_square: OutlineBucketSquare,
  outline_brush_4: OutlineBrush4,
  outline_broken_scissor: OutlineBrokenScissor,
  outline_glass: OutlineGlass,
  outline_rulerpen: OutlineRulerpen,
  broken_color_swatch: BrokenColorSwatch,
  outline_blend_2: OutlineBlend2,
  outline_path_square: OutlinePathSquare,
  outline_omega_circle: OutlineOmegaCircle,
  outline_omega_square: OutlineOmegaSquare,
  outline_shapes_2: OutlineShapes2,
  outline_size: OutlineSize,
  outline_flash_circle: OutlineFlashCircle,
  outline_eraser: OutlineEraser,
  outline_mask: OutlineMask,
  outline_blur: OutlineBlur,
  outline_blend: OutlineBlend,
  outline_colorfilter: OutlineColorfilter,
  outline_colors_square: OutlineColorsSquare,
  outline_mask_2: OutlineMask2,
  outline_brush_5: OutlineBrush5,
  outline_layer: OutlineLayer,
  outline_copy: OutlineCopy,
  outline_copy_succes: OutlineCopySucces,
  outline_recovery_convert: OutlineRecoveryConvert,
  outline_main_componet: OutlineMainComponet,
  outline_component: OutlineComponent,
  outline_mask_3: OutlineMask3,
  outline_forward_item: OutlineForwardItem,
  outline_backward_item: OutlineBackwardItem,

  //Grid
  outline_kanban: OutlineKanban,
  outline_fatrows: OutlineFatrows,
  outline_row_vertical: OutlineRowVertical,
  outline_row_horizontal: OutlineRowHorizontal,
  outline_slider_vertical: OutlineSliderVertical,
  outline_slider_horizontal: OutlineSliderHorizontal,
  outline_slider_vertical_2: OutlineSliderVertical2,
  outline_slider_horizontal_2: OutlineSliderHorizontal2,
  outline_grid_4: OutlineGrid4,
  outline_grid_9: OutlineGrid9,
  outline_grid_2: OutlineGrid2,
  outline_grid_3: OutlineGrid3,
  outline_grid_5: OutlineGrid5,
  outline_grid_6: OutlineGrid6,
  outline_grid_7: OutlineGrid7,
  outline_grid_8: OutlineGrid8,
  outline_grid_1: OutlineGrid1,
  outline_element_2: OutlineElement2,
  outline_frame_4: OutlineFrame4,
  outline_element_3: OutlineElement3,
  outline_element_4: OutlineElement4,
  outline_element_plus: OutlineElementPlus,
  outline_element_equal: OutlineElementEqual,
  outline_3_square: Outline3Square,
  outline_aling_vertically: OutlineAlingVertically,
  outline_aling_horizontally: OutlineAlingHorizontally,
  outline_aling_left: OutlineAlingLeft,
  outline_aling_right: OutlineAlingRight,
  outline_aling_bottom: OutlineAlingBottom,
  outline_frame_5: OutlineFrame5,
  outline_maximize: OutlineMaximize,
  outline_maximize_2: OutlineMaximize2,
  outline_maximize_3: OutlineMaximize3,
  outline_maximize_4: OutlineMaximize4,
  outline_format_circle: OutlineFormatCircle,
  outline_format_square: OutlineFormatSquare,
  outline_rotate_right_2: OutlineRotateRight2,
  outline_rotate_left_2: OutlineRotateLeft2,
  outline_crop: OutlineCrop,
  outline_maximize_5: OutlineMaximize5,
  outline_convertshape: OutlineConvertshape,
  outline_convertshape_2: OutlineConvertshape2,
  outline_grid_eraser: OutlineGridEraser,
  outline_grid_lock: OutlineGridLock,
  outline_grid_edit: OutlineGridEdit,

  //Shop
  outline_bag: OutlineBag,
  outline_bag_happy: OutlineBagHappy,
  outline_bag_tick: OutlineBagTick,
  outline_bag_cross: OutlineBagCross,
  outline_bag_timer: OutlineBagTimer,
  outline_bag_tick_2: OutlineBagTick2,
  outline_bag_cross_2: OutlineBagCross2,
  outline_bag_2: OutlineBag2,
  outline_shopping_cart: OutlineShoppingCart,
  outline_shpping_bag: OutlineShppingBag,
  outline_shop_add: OutlineShopAdd,
  outline_shop_remove: OutlineShopRemove,
  outline_shop: OutlineShop,
  outline_barcode: OutlineBarcode,

  //Crypto Currency
  outline_card_coin: OutlineCardCoin,
  outline_trade: OutlineTrade,
  outline_buy_crypto: OutlineBuyCrypto,
  outline_bitcoin_card: OutlineBitcoinCard,
  outline_bitcoin_convert: OutlineBitcoinConvert,
  outline_bitcoin_refresh: OutlineBitcoinRefresh,
  outline_whatsapp: OutlineWhatsapp,
  //Content, Edit
  outline_document_2: OutlineDocument2,
  outline_document_text: OutlineDocumentText,
  outline_document_favorite: OutlineDocumentFavorite,
  outline_document_download: OutlineDocumentDownload,
  outline_document_upload: OutlineDocumentUpload,
  outline_document_forward: OutlineDocumentForward,
  outline_document_previous: OutlineDocumentPrevious,
  outline_document_sketch: OutlineDocumentSketch,
  outline_document_cloud: OutlineDocumentCloud,
  outline_document_copy: OutlineDocumentCopy,
  outline_document_normal: OutlineDocumentNormal,
  outline_clipboard_text: OutlineClipboardText,
  outline_clipboard_tick: OutlineClipboardTick,
  outline_clipboard_close: OutlineClipboardClose,
  outline_clipboard_export: OutlineClipboardExport,
  outline_cliprboard_import: OutlineCliprboardImport,
  outline_note_3: OutlineNote3,
  outline_document_text_2: OutlineDocumentText2,
  outline_document_like: OutlineDocumentLike,
  outline_edit: OutlineEdit,
  outline_edit_2: OutlineEdit2,
  outline_copyright: OutlineCopyright,
  outline_creative_commos: OutlineCreativeCommos,
  outline_note_4: OutlineNote4,
  outline_note_remove: OutlineNoteRemove,
  outline_stickynote: OutlineStickynote,
  outline_add: OutlineAdd,
  outline_task: OutlineTask,
  outline_task_square: OutlineTaskSquare,
  outline_bill: OutlineBill,
  outline_document_filter: OutlineDocumentFilter,
  outline_archive_book: OutlineArchiveBook,
  outline_note_text: OutlineNoteText,
  outline_note_favorite: OutlineNoteFavorite,
  outline_menu_board: OutlineMenuBoard,

  //Programing
  outline_command: OutlineCommand,
  outline_programing_arrow: OutlineProgramingArrow,
  outline_hierarchy: OutlineHierarchy,
  outline_hierarchy_2: OutlineHierarchy2,
  outline_command_square: OutlineCommandSquare,
  outline_hierarchy_square: OutlineHierarchySquare,
  outline_hierarchy_square_2: OutlineHierarchySquare2,
  outline_hierarchy_square_3: OutlineHierarchySquare3,
  outline_data: OutlineData,
  outline_data_2: OutlineData2,
  outline_document_code: OutlineDocumentCode,
  outline_code_circle: OutlineCodeCircle,
  outline_hierarchy_3: OutlineHierarchy3,
  outline_programing_arrows: OutlineProgramingArrows,
  outline_document_code_2: OutlineDocumentCode2,
  outline_message_programming: OutlineMessageProgramming,
  outline_mobile_programing: OutlineMobilePrograming,
  outline_code: OutlineCode,
  outline_code_2: OutlineCode2,
  outline_hastag_up: OutlineHastagUp,
  outline_hastag: OutlineHastag,
  outline_hashtag_down: OutlineHashtagDown,
  outline_scroll: OutlineScroll,
  outline_sidebar_left: OutlineSidebarLeft,
  outline_sidebar_right: OutlineSidebarRight,
  outline_sidebar_top: OutlineSidebarTop,
  outline_sidebar_bottom: OutlineSidebarBottom,

  /************************************************************************************** */
  /*******************************    ICONOS BOLD    *********************************** */
  /************************************************************************************** */

  //Money
  bold_wallet_add: BoldWalletAdd,
  bold_receipt_item: BoldReceiptItem,
  bold_empty_wallet: BoldEmptyWallet,
  bold_wallet_check: BoldWalletCheck,
  bold_receip_discount: BoldReceiptDiscount,
  bold_empty_wallet_remove: BoldEmptyWalletRemove,
  bold_empty_wallet_add: BoldEmptyWalletAdd,
  bold_receipt_minus: BoldReceiptMinus,
  bold_wallet_search: BoldWalletSearch,
  bold_strong_box_2: BoldStrongBox2,
  bold_empty_wallet_tick: BoldEmptyWalletTick,
  bold_receipt_add: BoldReceiptAdd,
  bold_money: BoldMoney,
  bold_empty_wallet_time: BoldEmptyWalletTime,
  bold_transaction_minus: BoldTransactionMinus,
  bold_money_2: BoldMoney2,
  bold_empty_wallet_change: BoldEmptyWalletChange,
  bold_money_3: BoldMoney3,
  bold_percentage_square: BoldPercentageSquare,
  bold_money_4: BoldMoney4,
  bold_percentage_circle: BoldPercentageCircle,
  bold_chart_square: BoldChartSquare,
  bold_money_5: BoldMoney5,
  bold_coin: BoldCoin,
  bold_receipt_edit: BoldReceiptEdit,
  bold_money_tick: BoldMoneyTick,
  bold_discount_shape: BoldDiscountShape,
  bold_receipt_search: BoldReceiptSearch,
  bold_money_add: BoldMoneyAdd,
  bold_convert_card: BoldConvertCard,
  bold_discount_circle: BoldDiscountCircle,
  bold_wallet_2: BoldWallet2,
  bold_money_remove: BoldMoneyRemove,
  bold_wallet_money: BoldWalletMoney,
  bold_card_remove: BoldCardRemove,
  bold_cards: BoldCards,
  bold_money_forbidden: BoldMoneyForbidden,
  bold_wallet_3: BoldWallet3,
  bold_card_tick: BoldCardTick,
  bold_receipt_2: BoldReceipt2,
  bold_receipt: BoldReceipt,
  bold_money_change: BoldMoneyChange,
  bold_wallet_remove: BoldWalletRemove,
  bold_money_time: BoldMoneyTime,
  bold_receipt_text: BoldReceiptText,
  bold_strongbox: BoldStrongbox,
  bold_wallet_4: BoldWallet4,
  bold_security_card: BoldSecurityCard,
  bold_money_receive: BoldMoneyReceive,
  bold_money_send: BoldMoneySend,
  bold_archive: BoldArchive,
  bold_tag: BoldTag,
  bold_tag_2: BoldTag2,
  bold_coin_2: BoldCoin2,
  bold_document: BoldDocument,
  bold_wallet_5: BoldWallet5,
  bold_card_add: BoldCardAdd,
  bold_card_remove_2: BoldCardRemove2,
  bold_card_tick_2: BoldCardTick2,
  bold_card_slash: BoldCardSlash,
  bold_card_pos: BoldCardPos,
  bold_card: BoldCard,
  bold_card_receive: BoldCardReceive,
  bold_card_send: BoldCardSend,
  bold_card_edit: BoldCardEdit,
  bold_wallet_minus: BoldWalletMinus,
  bold_wallet_add_2: BoldWalletAdd2,
  bold_dollar_square: BoldDollarSquare,
  bold_dollar_circle: BoldDollarCircle,
  bold_receipt_3: BoldReceipt3,
  bold_receipt_4: BoldReceipt4,
  bold_receipt_disscount: BoldReceiptDisscount,
  bold_ticket: BoldTicket,
  bold_ticket_star: BoldTicketStar,
  bold_ticket_expired: BoldTicketExpired,
  bold_ticket_2: BoldTicket2,
  bold_ticket_discount: BoldTicketDiscount,
  bold_math: BoldMath,

  //Type, paragraph, character
  bold_textaling_left: BoldTextalingLeft,
  bold_textaling_center: BoldTextalingCenter,
  bold_textaling_riht: BoldTextalingRiht,
  bold_textaling_justify_right: BoldTextalingJustifyRight,
  bold_textaling_justify_center: BoldTextalingJustifyCenter,
  bold_textaling_justify_left: BoldTextalingJustifyLeft,
  bold_fristline: BoldFristline,
  bold_paperclip_2: BoldPaperclip2,
  bold_paperclip: BoldPaperclip,
  bold_link: BoldLink,
  bold_link_3: BoldLink3,
  bold_link_2: BoldLink2,
  bold_text_block: BoldTextBlock,
  bold_textaling: BoldTextAling,
  bold_text: BoldText,
  bold_smallcaps: BoldSmallcaps,
  bold_textBold: BoldTextBold,
  bold_text_underline: BoldTextUnderline,
  bold_pharagraphspacing: BoldPharagraphspacing,
  bold_maximize: BoldMaximize,
  bold_eraser: BoldEraser,
  bold_atthach_square: BoldAtthachSquare,
  bold_attach_circle: BoldAttachCircle,
  bold_quote_up: BoldQuoteUp,
  bold_quote_down: BoldQuoteDown,
  bold_quote_up_square: BoldQuoteUpSquare,
  bold_quote_down_square: BoldQuoteDownSquare,
  bold_quote_down_circle: BoldQuoteDownCircle,
  bold_quote_up_circle: BoldQuoteUpCircle,
  bold_link_4: BoldLink4,
  bold_link_square: BoldLinkSquare,
  bold_link_circle: BoldLinkCircle,
  bold_translate: BoldTranslate,
  bold_languge_square: BoldLangugeSquare,
  bold_language_circle: BoldLanguageCircle,

  //Weather
  bold_moon: BoldMoon,
  bold_sun: BoldSun,
  bold_cloud: BoldCloud,
  bold_cloud_plus: BoldCloudPlus,
  bold_cloud_minus: BoldCloudMinus,
  bold_cloud_cross: BoldCloudCross,
  bold_cloud_sunny: BoldCloudSunny,
  bold_cloud_notif: BoldCloudNotif,
  bold_cloud_drizzle: BoldCloudDrizzle,
  bold_cloud_snow: BoldCloudSnow,
  bold_cloud_lightning: BoldCloudLightning,
  bold_flash: BoldFlash,
  bold_drop: BoldDrop,
  bold_wind: BoldWind,
  bold_wind_2: BoldWind2,
  bold_cloud_fog: BoldCloudFog,
  bold_sun_fog: BoldSunFog,
  bold_sun_2: BoldSun2,

  //Files
  bold_folder: BoldFolder,
  bold_folder_cross: BoldFolderCross,
  bold_folder_add: BoldFolderAdd,
  bold_folder_minus: BoldFolderMinus,
  bold_folder_favorite: BoldFolderFavorite,
  bold_folder_2: BoldFolder2,
  bold_folder_open: BoldFolderOpen,
  bold_folder_cloud: BoldFolderCloud,

  //Video. Audio, Image
  bold_microphone: BoldMicrophone,
  bold_micrphone_slash: BoldMicrphoneSlash,
  bold_microphone_2: BoldMicrophone2,
  bold_microphone_slash: BoldMicrophoneSlash,
  bold_volume_cross: BoldVolumeCross,
  bold_volume_slash: BoldVolumeSlash,
  bold_volume_up: BoldVolumeUp,
  bold_volume_low: BoldVolumeLow,
  bold_volume_low_2: BoldVolumeLow2,
  bold_volume_high: BoldVolumeHigh,
  bold_volume_mute: BoldVolumeMute,
  bold_video: BoldVideo,
  bold_video_slash: BoldVideoSlash,
  bold_video_square: BoldVideoSquare,
  bold_video_circle: BoldVideoCircle,
  bold_gallery: BoldGallery,
  bold_gallery_favorite: BoldGalleryFavorite,
  bold_gallery_slash: BoldGallerySlash,
  bold_gallery_tick: BoldGalleryTick,
  bold_gallery_import: BoldGalleryImport,
  bold_gallery_export: BoldGalleryExport,
  bold_gallery_add: BoldGalleryAdd,
  bold_gallery_remove: BoldGalleryRemove,
  bold_gallery_edit: BoldGalleryEdit,
  bold_image: BoldImage,
  bold_screnmirroring: BoldScrenmirroring,
  bold_camera: BoldCamera,
  bold_camera_slash: BoldCameraSlash,
  bold_play: BoldPlay,
  bold_record: BoldRecord,
  bold_stop: BoldStop,
  bold_musicnote: BoldMusicnote,
  bold_music: BoldMusic,
  bold_video_vertical: BoldVideoVertical,
  bold_video_horizontal: BoldVideoHorizontal,
  bold_video_play: BoldVideoPlay,
  bold_subtitle: BoldSubtitle,
  bold_play_circle: BoldPlayCircle,
  bold_record_circle: BoldRecordCircle,
  bold_stop_circle: BoldStopCircle,
  bold_pause_circle: BoldPauseCircle,
  bold_pause: BoldPause,
  bold_scissor: BoldScissor,
  bold_backward: BoldBackward,
  bold_forward: BoldForward,
  bold_previous: BoldPrevious,
  bold_next: BoldNext,
  bold_radio: BoldRadio,
  bold_music_circle: BoldMusicCircle,
  bold_play_circle_2: BoldPlayCircle2,
  bold_play_remove: BoldPlayRemove,
  bold_play_add: BoldPlayAdd,
  bold_backward_5_seconds: BoldBackward5Seconds,
  bold_backward_10_seconds: BoldBackward10Seconds,
  bold_backward_15_seconds: BoldBackward15Seconds,
  bold_forward_5_seconds: BoldForward5Seconds,
  bold_forward_10_seconds: BoldForward10Seconds,
  bold_forward_15_seconds: BoldForward15Seconds,
  bold_music_playlist: BoldMusicPlaylist,
  bold_music_filter: BoldMusicFilter,
  bold_music_dashboard: BoldMusicDashboard,
  bold_music_square_remove: BoldMusicSquareRemove,
  bold_music_square_add: BoldMusicSquareAdd,
  bold_music_square_search: BoldMusicSquareSearch,
  bold_repeat_music: BoldRepeatMusic,
  bold_repeat_one: BoldRepeatOne,
  bold_shuffle: BoldShuffle,
  bold_devices: BoldDevices,
  bold_music_library_2: BoldMusicLibrary2,
  bold_music_square: BoldMusicSquare,
  bold_audio_square: BoldAudioSquare,
  bold_note_square: BoldNoteSquare,
  bold_mini_music_square: BoldMiniMusicSquare,
  bold_video_add: BoldVideoAdd,
  bold_video_remove: BoldVideoRemove,
  bold_video_tick: BoldVideoTick,
  bold_video_time: BoldVideoTime,
  bold_video_octagon: BoldVideoOctagon,
  bold_voice_square: BoldVoiceSquare,
  bold_voice_circle: BoldVoiceCircle,
  bold_maximize_circle: BoldMaximizeCircle,

  //Arrow
  bold_received: BoldReceived,
  bold_send: BoldSend,
  bold_arrow_2: BoldArrow2,
  bold_arrow_3: BoldArrow3,
  bold_arrow_square_up: BoldArrowSquareUp,
  bold_arrow_square_down: BoldArrowSquareDown,
  bold_arrow_square_left: BoldArrowSquareLeft,
  bold_arrow_square_right: BoldArrowSquareRight,
  bold_arrow_right: BoldArrowRight,
  bold_arrow_left: BoldArrowLeft,
  bold_arrow_up: BoldArrowUp,
  bold_arrow_down: BoldArrowDown,
  bold_arrow_up_2: BoldArrowUp2,
  bold_arrow_down_2: BoldArrowDown2,
  bold_arrow_right_2: BoldArrowRight2,
  bold_arrow_left_2: BoldArrowLeft2,
  bold_arrow_circle_left: BoldArrowCircleLeft,
  bold_arrow_circle_right: BoldArrowCircleRight,
  bold_arrow_circle_down: BoldArrowCircleDown,
  bold_arrow_circle_up: BoldArrowCircleUp,
  bold_arrow_swap: BoldArrowSwap,
  bold_arrow_swap_horizontal: BoldArrowSwapHorizontal,
  bold_undo: BoldUndo,
  bold_redo: BoldRedo,
  bold_roteate_left: BoldRoteateLeft,
  bold_rotate_right: BoldRotateRight,
  bold_refresh_2: BoldRefresh2,
  bold_arrow_left_3: BoldArrowLeft3,
  bold_arrow_right_3: BoldArrowRight3,
  bold_arrow_up_3: BoldArrowUp3,
  bold_arrow_bottom: BoldArrowBottom,
  bold_arrow_up_4: BoldArrowUp4,
  bold_arrow_right_4: BoldArrowRight4,
  bold_arrow_left_4: BoldArrowLeft4,
  bold_arrow_down_3: BoldArrowDown3,
  bold_convert: BoldConvert,
  bold_arrange_square: BoldArrangeSquare,
  bold_arrange_square_2: BoldArrangeSquare2,
  bold_back_square: BoldBackSquare,
  bold_forward_square: BoldForwardSquare,
  bold_refresh_square_2: BoldRefreshSquare2,
  bold_refresh_left_square: BoldRefreshLeftSquare,
  bold_refresh_right_square: BoldRefreshRightSquare,
  bold_receive_square: BoldReceiveSquare,
  bold_send_square: BoldSendSquare,
  bold_send_square_2: BoldSendSquare2,
  bold_receive_square_2: BoldReceiveSquare2,
  bold_login: BoldLogin,
  bold_logout: BoldLogout,
  bold_import: BoldImport,
  bold_export: BoldExport,
  bold_export_2: BoldExport2,
  bold_import_2: BoldImport2,
  bold_login_2: BoldLogin2,
  bold_logout_2: BoldLogout2,
  bold_export_3: BoldExport3,
  bold_import_3: BoldImport3,
  bold_import_4: BoldImport4,
  bold_export_4: BoldExport4,
  bold_refresh_3: BoldRefresh3,
  bold_arrange_circle: BoldArrangeCircle,
  bold_arrange_circle_2: BoldArrangeCircle2,
  bold_repeat: BoldRepeat,
  bold_repeat_circle: BoldRepeatCircle,
  bold_refresh_circle: BoldRefreshCircle,

  //Users
  bold_user: BoldUser,
  bold_user_add: BoldUserAdd,
  bold_user_remove: BoldUserRemove,
  bold_user_tick: BoldUserTick,
  bold_user_minus: BoldUserMinus,
  bold_user_search: BoldUserSearch,
  bold_user_edit: BoldUserEdit,
  bold_user_tag: BoldUserTag,
  bold_user_octagon: BoldUserOctagon,
  bold_user_circle_add: BoldUserCircleAdd,
  bold_user_square: BoldUserSquare,
  bold_frame: BoldFrame,
  bold_profile_2_ser: BoldProfile2User,
  bold_profile_remove: BoldProfileRemove,
  bold_profile_tick: BoldProfileTick,
  bold_profile_add: BoldProfileAdd,
  bold_profile_delete: BoldProfileDelete,
  bold_profile_circle: BoldProfileCircle,
  bold_tag_user: BoldTagUser,
  bold_people: BoldPeople,

  //Car
  bold_airplane: BoldAirplane,
  bold_car: BoldCar,
  bold_bus: BoldBus,
  bold_smart_car: BoldSmartCar,
  bold_driving: BoldDriving,
  bold_ship: BoldShip,
  bold_airplane_square: BoldAirplaneSquare,
  bold_gas_station: BoldGasStation,

  //Emails,Messages
  bold_directbox_default: BoldDirectboxDefault,
  bold_directbox_notif: BoldDirectboxNotif,
  bold_directbox_receive: BoldDirectboxReceive,
  bold_directbox_send: BoldDirectboxSend,
  bold_direct_normal: BoldDirectNormal,
  bold_direct: BoldDirect,
  bold_direct_notification: BoldDirectNotification,
  bold_direct_inbox: BoldDirectInbox,
  bold_direct_send: BoldDirectSend,
  bold_message: BoldMessage,
  bold_message_favorite: BoldMessageFavorite,
  bold_message_notif: BoldMessageNotif,
  bold_message_search: BoldMessageSearch,
  bold_message_text: BoldMessageText,
  bold_device_message: BoldDeviceMessage,
  bold_messages: BoldMessages,
  bold_messages_2: BoldMessages2,
  bold_messages_3: BoldMessages3,
  bold_sms: BoldSms,
  bold_sms_tracking: BoldSmsTracking,
  bold_sms_notification: BoldSmsNotification,
  bold_sms_edit: BoldSmsEdit,
  bold_sms_star: BoldSmsStar,
  bold_sms_search: BoldSmsSearch,
  bold_message_remove: BoldMessageRemove,
  bold_message_add: BoldMessageAdd,
  bold_message_tick: BoldMessageTick,
  bold_message_time: BoldMessageTime,
  bold_message_2: BoldMessage2,
  bold_messages_4: BoldMessages4,
  bold_message_circle: BoldMessageCircle,
  bold_message_square: BoldMessageSquare,
  bold_message_text_2: BoldMessageText2,
  bold_message_minus: BoldMessageMinus,
  bold_message_add_2: BoldMessageAdd2,
  bold_message_edit: BoldMessageEdit,

  //Location
  bold_location_minus: BoldLocationMinus,
  bold_location_cross: BoldLocationCross,
  bold_location_tick: BoldLocationTick,
  bold_location_slash: BoldLocationSlash,
  bold_discover: BoldDiscover,
  bold_location: BoldLocation,
  bold_location_add: BoldLocationAdd,
  bold_radar: BoldRadar,
  bold_radar_2: BoldRadar2,
  bold_global_search: BoldGlobalSearch,
  bold_global_edit: BoldGlobalEdit,
  bold_global_refresh: BoldGlobalRefresh,
  bold_global: BoldGlobal,
  bold_direct_up: BoldDirectUp,
  bold_direct_down: BoldDirectDown,
  bold_direct_left: BoldDirectLeft,
  bold_direct_right: BoldDirectRight,
  bold_map: BoldMap,
  bold_gps: BoldGps,
  bold_gps_slash: BoldGpsSlash,
  bold_map_2: BoldMap2,
  bold_routing: BoldRouting,
  bold_routing_2: BoldRouting2,
  bold_arrow_square: BoldArrowSquare,
  bold_picture_frame: BoldPictureFrame,
  bold_arrow: BoldArrow,
  bold_route_square: BoldRouteSquare,

  //School, learning
  bold_briefcase: BoldBriefcase,
  bold_briefcase_timer: BoldBriefcaseTimer,
  bold_briefcase_tick: BoldBriefcaseTick,
  bold_briefcase_cross: BoldBriefcaseCross,
  bold_bookmark: BoldBookmark,
  bold_award: BoldAward,
  bold_book: BoldBook,
  bold_book_3: BoldBook3,
  bold_bookmark_2: BoldBookmark2,
  bold_clipboard: BoldClipboard,
  bold_note: BoldNote,
  bold_note_2: BoldNote2,
  bold_teacher: BoldTeacher,
  bold_gift: BoldGift,
  bold_calculator: BoldCalculator,

  //Delivery
  bold_box: BoldBox,
  bold_3d_cube_scan: Bold3dCubeScan,
  bold_convert_3d_cube: BoldConvert3dCube,
  bold_box_add: BoldBoxAdd,
  bold_box_remove: BoldBoxRemove,
  bold_box_tick: BoldBoxTick,
  bold_box_time: BoldBoxTime,
  bold_box_2: BoldBox2,
  bold_box_search: BoldBoxSearch,
  bold_truck: BoldTruck,
  bold_truck_fast: BoldTruckFast,
  bold_truck_tick: BoldTruckTick,
  bold_truck_time: BoldTruckTime,
  bold_truck_remove: BoldTruckRemove,
  bold_3d_square: Bold3dSquare,
  bold_3d_rotate: Bold3dRotate,

  //Security
  bold_eye: BoldEye,
  bold_eye_slash: BoldEyeSlash,
  bold_frame_2: BoldFrame2,
  bold_shield_tick: BoldShieldTick,
  bold_shield_cross: BoldShieldCross,
  bold_sheild_slash: BoldSheildSlash,
  bold_sheild_search: BoldSheildSearch,
  bold_shield_security: BoldShieldSecurity,
  bold_password_check: BoldPasswordCheck,
  bold_key: BoldKey,
  bold_key_square: BoldKeySquare,
  bold_check: BoldCheck,
  bold_lock: BoldLock,
  bold_unlock: BoldUnlock,
  bold_lock_slash: BoldLockSlash,
  bold_scan: BoldScan,
  bold_scan_barcode: BoldScanBarcode,
  bold_scanning: BoldScanning,
  bold_finger_scan: BoldFingerScan,
  bold_finger_circle: BoldFingerCircle,
  bold_scanner: BoldScanner,
  bold_security_user: BoldSecurityUser,
  bold_lock_2: BoldLock2,
  bold_lock_circle: BoldLockCircle,
  bold_alarm: BoldAlarm,
  bold_radar_3: BoldRadar3,
  bold_security_safe: BoldSecuritySafe,
  bold_security: BoldSecurity,

  //Design, Tools
  bold_bezier: BoldBezier,
  bold_brush: BoldBrush,
  bold_brush_2: BoldBrush2,
  bold_magicpen: BoldMagicpen,
  bold_pen_tool: BoldPenTool,
  bold_bucket: BoldBucket,
  bold_paintbucket: BoldPaintbucket,
  bold_additem: BoldAdditem,
  bold_ruler: BoldRuler,
  bold_designtools: BoldDesigntools,
  bold_shapes: BoldShapes,
  bold_lifebuoy: BoldLifebuoy,
  bold_pen_tool_2: BoldPenTool2,
  bold_brush_3: BoldBrush3,
  bold_pen_close: BoldPenClose,
  bold_pen_add: BoldPenAdd,
  bold_pen_remove: BoldPenRemove,
  bold_path: BoldPath,
  bold_path_2: BoldPath2,
  bold_bucket_circle: BoldBucketCircle,
  bold_bucket_square: BoldBucketSquare,
  bold_brush_4: BoldBrush4,
  bold_scissor_2: BoldScissor2,
  bold_glass: BoldGlass,
  bold_ruler_pen: BoldRulerPen,
  bold_color_swatch: BoldColorSwatch,
  bold_blend_2: BoldBlend2,
  bold_path_square: BoldPathSquare,
  bold_omega_circle: BoldOmegaCircle,
  bold_omega_square: BoldOmegaSquare,
  bold_size: BoldSize,
  bold_shapes_2: BoldShapes2,
  bold_flash_circle: BoldFlashCircle,
  bold_eraser_2: BoldEraser2,
  bold_mask: BoldMask,
  bold_blur: BoldBlur,
  bold_blend_3: BoldBlend3,
  bold_colorfilter: BoldColorfilter,
  bold_colors_square: BoldColorsSquare,
  bold_mask_2: BoldMask2,
  bold_brush_5: BoldBrush5,
  bold_layer: BoldLayer,
  bold_copy: BoldCopy,
  bold_copy_succes: BoldCopySucces,
  bold_recovery_convert: BoldRecoveryConvert,
  bold_main_component: BoldMainComponent,
  bold_component: BoldComponent,
  bold_mask_3: BoldMask3,
  bold_forward_item: BoldForwardItem,
  bold_backward_item: BoldBackwardItem,

  //Grid
  bold_kanban: BoldKanban,
  bold_fatrows: BoldFatrows,
  bold_row_vertical: BoldRowVertical,
  bold_row_horizontal: BoldRowHorizontal,
  bold_slider_horizontal: BoldSliderHorizontal,
  bold_slider_vertical: BoldSliderVertical,
  bold_slider_vertical_2: BoldSliderVertical2,
  bold_slider_horizontal_2: BoldSliderHorizontal2,
  bold_grid_4: BoldGrid4,
  bold_grid_9: BoldGrid9,
  bold_grid_2: BoldGrid2,
  bold_grid_3: BoldGrid3,
  bold_grid_5: BoldGrid5,
  bold_grid_6: BoldGrid6,
  bold_grid_7: BoldGrid7,
  bold_grid_8: BoldGrid8,
  bold_grid_1: BoldGrid1,
  bold_element_2: BoldElement2,
  bold_frame_3: BoldFrame3,
  bold_element_3: BoldElement3,
  bold_element_4: BoldElement4,
  bold_elelement_plus: BoldElelementPlus,
  bold_element_equal: BoldElementEqual,
  bold_3_square: Bold3Square,
  bold_aling_vertically: BoldAlingVertically,
  bold_align_horizontal: BoldAlignHorizontal,
  bold_align_left: BoldAlignLeft,
  bold_align_right: BoldAlignRight,
  bold_align_bottom: BoldAlignBottom,
  bold_frame_4: BoldFrame4,
  bold_maximize_1: BoldMaximize1,
  bold_maximize_2: BoldMaximize2,
  bold_maximize_3: BoldMaximize3,
  bold_maximize_4: BoldMaximize4,
  bold_format_circle: BoldFormatCircle,
  bold_format_square: BoldFormatSquare,
  bold_rotate_left: BoldRotateLeft,
  bold_rotate_right_2: BoldRotateRight2,
  bold_crop: BoldCrop,
  boldMaximize_5: BoldMaximize5,
  bold_convertshape: BoldConvertshape,
  bold_convertshape_2: BoldConvertshape2,
  bold_grid_eraser: BoldGridEraser,
  bold_grid_lock: BoldGridLock,
  "bold_ grid_edit": BoldGridEdit,

  //Shop
  bold_bag: BoldBag,
  bold_bag_happy: BoldBagHappy,
  bold_bag_tick: BoldBagTick,
  bold_bag_cross: BoldBagCross,
  bold_bag_timer: BoldBagTimer,
  bold_bag_tick_2: BoldBagTick2,
  bold_bag_cross_2: BoldBagCross2,
  bold_bag_2: BoldBag2,
  bold_shopping_cart: BoldShoppingCart,
  bold_shopping_bag: BoldShoppingBag,
  bold_shop_add: BoldShopAdd,
  bold_shop_remove: BoldShopRemove,
  bold_shop: BoldShop,
  bold_barcode: BoldBarcode,

  //Crypto, Currency
  bold_card_coin: BoldCardCoin,
  bold_trade: BoldTrade,
  bold_buy_crypto: BoldBuyCrypto,
  bold_bitcoin_card: BoldBitcoinCard,
  bold_bitcoin_convert: BoldBitcoinConvert,
  bold_bitcoin_refresh: BoldBitcoinRefresh,
  mercadolibre: Mercadolibre,
  bold_whatsapp: BoldWhatsapp,
  //Content, Edit
  bold_document_2: BoldDocument2,
  bold_document_text: BoldDocumentText,
  bold_document_favorite: BoldDocumentFavorite,
  bold_document_download: BoldDocumentDownload,
  bold_document_upload: BoldDocumentUpload,
  bold_document_forward: BoldDocumentForward,
  bold_document_previous: BoldDocumentPrevious,
  bold_document_sketch: BoldDocumentSketch,
  bold_document_cloud: BoldDocumentCloud,
  bold_document_copy: BoldDocumentCopy,
  bold_document_normal: BoldDocumentNormal,
  bold_clipboard_text: BoldClipboardText,
  bold_clipboard_tick: BoldClipboardTick,
  bold_clipboard_close: BoldClipboardClose,
  bold_clipboard_export: BoldClipboardExport,
  bold_clipboard_import: BoldClipboardImport,
  bold_note_3: BoldNote3,
  bold_document_text_2: BoldDocumentText2,
  bold_document_like: BoldDocumentLike,
  bold_edit: BoldEdit,
  bold_edit_2: BoldEdit2,
  bold_copyright: BoldCopyright,
  bold_creative_commons: BoldCreativeCommons,
  bold_note_4: BoldNote4,
  bold_note_remove: BoldNoteRemove,
  bold_strickynote: BoldStrickynote,
  bold_note_add: BoldNoteAdd,
  bold_task: BoldTask,
  bold_task_square: BoldTaskSquare,
  bold_bill: BoldBill,
  bold_document_filter: BoldDocumentFilter,
  bold_archive_book: BoldArchiveBook,
  bold_note_text: BoldNoteText,
  bold_note_favorite: BoldNoteFavorite,
  bold_menu_board: BoldMenuBoard,

  //Programming
  bold_command: BoldCommand,
  bold_programming_arrow: BoldProgrammingArrow,
  bold_hierarchy: BoldHierarchy,
  bold_hierarchy_2: BoldHierarchy2,
  bold_command_square: BoldCommandSquare,
  bold_hierarchy_square: BoldHierarchySquare,
  bold_hierarchy_square_2: BoldHierarchySquare2,
  bold_hierarchy_square_3: BoldHierarchySquare3,
  bold_data: BoldData,
  bold_data_2: BoldData2,
  bold_document_code: BoldDocumentCode,
  bold_code_circle: BoldCodeCircle,
  bold_hierarchy_3: BoldHierarchy3,
  bold_programming_arrows: BoldProgrammingArrows,
  bold_document_code_2: BoldDocumentCode2,
  bold_message_programming: BoldMessageProgramming,
  bold_mobile_programming: BoldMobileProgramming,
  bold_code: BoldCode,
  bold_code_2: Boldcode2,
  bold_hashtag_up: BoldHashtagUp,
  bold_hashtag: BoldHashtag,
  bold_hashtag_down: BoldHashtagDown,
  bold_scroll: BoldScroll,
  bold_sidebar_left: BoldSidebarLeft,
  bold_sidebar_right: BoldSidebarRight,
  bold_sidebar_top: BoldSidebarTop,
  bold_sidebar_bottom: BoldSidebarBottom,

  //Essetionel
  bold_trash: BoldTrash,
  bold_battery_empty: BoldBatteryEmpty,
  bold_battery_empty_2: BoldBatteryEmpty2,
  bold_battery_23: BoldBattery23,
  bold_battery_full: BoldBatteryFull,
  bold_battery_charging: BoldBatteryCharging,
  bold_battery_disable: BoldBatteryDisable,
  bold_home: BoldHome,
  bold_home_2: BoldHome2,
  bold_more_square: BoldMoreSquare,
  bold_tick_circle: BoldTickCircle,
  bold_add_circle: BoldAddCircle,
  bold_minus_circle: BoldMinusCircle,
  bold_close_circle: BoldCloseCircle,
  BoldMoreCircle: BoldMoreCircle,
  bold_info_circle: BoldInfoCircle,
  bold_danger: BoldDanger,
  bold_add: BoldAdd,
  bold_minus: BoldMinus,
  bold_add_square: BoldAddSquare,
  bold_minus_square: BoldMinusSquare,
  bold_tick_square: BoldTickSquare,

  bold_closed_square: BoldClosedSquare,
  bold_sort: BoldSort,
  bold_send_3: BoldSend3,
  bold_send_2: BoldSend2,
  bold_share: BoldShare,
  bold_flash_2: BoldFlash2,
  bold_flash_3: BoldFlash3,
  bold_flash_circle_2: BoldFlashCircle2,
  bold_slash: BoldSlash,
  bold_cake: BoldCake,
  bold_grammerly: BoldGrammerly,
  bold_crown: BoldCrown,
  bold_crown_2: BoldCrown2,
  bold_flag: BoldFlag,
  bold_flag_2: BoldFlag2,
  bold_cup: BoldCup,
  bold_emoji_happy: BoldEmojiHappy,
  bold_emoji_normal: BoldEmojiNormal,
  bold_emoji_sad: BoldEmojiSad,
  bold_tag_cross: BoldTagCross,
  bold_menu: BoldMenu,
  bold_cd: BoldCd,

  bold_chart: BoldChart,
  bold_archive_2: BoldArchive2,
  bold_chrome: BoldChrome,
  bold_instagram: BoldInstagram,
  bold_coffe: BoldCoffe,
  bold_pet: BoldPet,
  bold_happyemoji: BoldHappyemoji,
  bold_reserve: BoldReserve,
  bold_sticker: BoldSticker,
  bold_computing: BoldComputing,
  bold_verify: BoldVerify,
  bold_information: BoldInformation,
  bold_autobrightness: BoldAutobrightness,
  bold_triangle: BoldTriangle,
  bold_3d_cube: Bold3dCube,
  bold_glass_2: BoldGlass2,
  bold_weight: BoldWeight,
  bold_discover_2: BoldDiscover2,
  bold_filter: BoldFilter,
  bold_filter_square: BoldFilterSquare,
  bold_sound: BoldSound,
  bold_story: BoldStory,

  bold_slider: BoldSlider,
  bold_status: BoldStatus,
  bold_wifi_square: BoldWifiSquare,
  bold_box_3: BoldBox3,
  bold_trush_square: BoldTrushSquare,
  bold_wifi: BoldWifi,
  bold_speedometer: BoldSpeedometer,
  bold_diamonds: BoldDiamonds,
  bold_level: BoldLevel,
  bold_judge: BoldJudge,
  bold_lamp: BoldLamp,
  bold_tree: BoldTree,
  bold_bubble: BoldBubble,
  bold_mirror: BoldMirror,
  bold_tag_right: BoldTagRight,
  bold_milk: BoldMilk,
  bold_ghost: BoldGhost,
  bold_ranking: BoldRanking,
  bold_mouse_square: BoldMouseSquare,
  bold_mouse_circle: BoldMouseCircle,
  bold_mouse: BoldMouse,
  bold_forbidden: BoldForbidden,

  bold_forbidden_2: BoldForbidden2,
  bold_warning_2: BoldWarning2,
  bold_broom: BoldBroom,
  bold_signpost: BoldSignpost,
  bold_filter_remove: BoldFilterRemove,
  bold_filter_add: BoldFilterAdd,
  bold_filter_search: BoldFilterSearch,
  bold_filter_edit: BoldFilterEdit,
  bold_filter_tick: BoldFilterTick,
  bold_home_3: BoldHome3,
  bold_smart_home: BoldSmartHome,
  bold_home_wifi: BoldHomeWifi,
  bold_safe_home: BoldSafeHome,

  //Marca
  logo_ethics_copy: LogoEthicsCopy,
  logo_ethics_white: LogoEthicsWhite,
  imagotipo_ethics: ImagotipoEthics,
  logo_ethics_copy_white: LogoEthicsCopyWhite,
  collapse: Collapse,
  bold_element: BoldElement,
  bold_document_text_1: BoldDocumentText1,
  bold_chart_1: BoldChart1,
  bold_notification: BoldNotification,
  bold_direct_1: BoldDirect1,
  rocket: Rocket,
  bold_message_question: BoldMessageQuestion,
  "logo-ethics_copy_3": LogoEthicsCopy3,
  collapse_2: Collapse2,
  bold_message_question_2: BoldMessageQuestion2,
  imagotipo_ethics_2: ImagotipoEthics2,
  group_39: Group39,
  imagotipo_ethics_4: ImagotipoEthics4,
  group_39_2: Group392,
  imagotipo_ethics_5: ImagotipoEthics5,
  profile_icon: ProfileIcon,
  profile: Profile,
  circle_yellow: CircleYellow,
  shape: Shape,
};
