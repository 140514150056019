import { useEffect, useState } from "react"
import { createNotificationCenter, fakeGetListNotificationCenter, fakeGetListNotificationSystem, getDefaultNotifications, getListNotificationCenter, getListNotificationSystem, updateSendersNotificationSystem, updateStatusNotificationSystem } from "../../../apis";
import { MCSelect } from "../../../components/MainComponents/Select/Select";
import "./styles.scss";
import { MCButton } from "../../../components/MainComponents/Button/Button";
import { enqueueSnackbar } from "notistack";
import { ModalEditNotificationCenter } from "./ModalEditNotificationCenter";
import { MCTooltip } from "../../../components/MainComponents";
import Icon from "../../../components/Icon/Icon";
import _ from "lodash";

// Funcion para manejar errores y mostrarselos al usuario
const showSnack = (variant, message) => {
  enqueueSnackbar(`${message}`, {
    variant,
    preventDuplicate: true,
    autoHideDuration: 3000,
  });
};

// Lista de notificaciones del NOTIFICATION SYSTEM
const ListOfNotificationSystem = () => {

  const [currentNotificationsSystem, setCurrentNotificationsSystem] = useState([]);

  useEffect(() => {
    getListNotificationSystem()
      .then(resp => {
        setCurrentNotificationsSystem(resp.results);
      })
  }, []);

  const handleUpdateStatus = async (noti, idx) => {
    const { alias = null, is_active = true } = noti;
    try {
      await updateStatusNotificationSystem(alias, {
        is_active: !is_active
      })
      const newCurrentNotificationsSystem = _.cloneDeep(currentNotificationsSystem);
      _.set(newCurrentNotificationsSystem, `${idx}.is_active`, !is_active);
      setCurrentNotificationsSystem(newCurrentNotificationsSystem);
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateSenders = async (noti, idx, type) => {
    const { alias = null } = noti;
    const objToSend = { [type]: !noti[type] }
    try {
      await updateSendersNotificationSystem(alias, objToSend);
      const newCurrentNotificationsSystem = _.cloneDeep(currentNotificationsSystem);
      _.set(newCurrentNotificationsSystem, `${idx}.${type}`, !noti[type]);
      setCurrentNotificationsSystem(newCurrentNotificationsSystem);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ul style={{ listStyle: "none", paddingLeft: 0 }} className="list-of-notification-system">
        {
          currentNotificationsSystem.map((noti, idx) =>
            <li
              key={noti.alias}
              className="d-flex justify-content-between"
              style={{ borderRadius: 4, border: "1px solid gray", padding: "10px 20px", marginBottom: 8, cursor: "pointer" }}>
              <span>{noti.title}</span>
              <div className="d-flex">
                <MCTooltip
                  position="bottom"
                  text={"Estatus"}
                >
                  <div onClick={() => handleUpdateStatus(noti, idx)}>
                    <Icon
                      name={noti.is_active ? "toggleOn_bold" : "toggleOff_bold"}
                      size={24}
                    />
                  </div>
                </MCTooltip>
                <MCTooltip
                  position="bottom"
                  text={"Notificaciones por email"}
                >
                  <div onClick={() => handleUpdateSenders(noti, idx, "send_system_notification")}>
                    <Icon
                      name={noti.send_system_notification ? "notification_bold" : "notification"}
                      size={24}
                    />
                  </div>
                </MCTooltip>
                <MCTooltip
                  position="bottom"
                  text={"Notificaciones por sistema"}
                >
                  <div onClick={() => handleUpdateSenders(noti, idx, "send_email")}>
                    <Icon
                      name={noti.send_email ? "bold_sms" : "outline_sms"}
                      size={24}
                    />
                  </div>
                </MCTooltip>
              </div>
            </li>
          )
        }
      </ul>
    </>
  )
}

// Lista de notificaciones del NOTIFICATION CENTER
const ListOfNotificationCenter = ({ refreshList, refreshListSystem }) => {

  const [currentNotificationsCenter, setCurrentNotificationsCenter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNotificationCenter, setSelectedNotificationCenter] = useState(null);

  useEffect(() => {
    getListNotificationCenter().then(resp => {
      setCurrentNotificationsCenter(resp.results);
    })
  }, []);

  const handleSelectedNotificationCenter = (alias) => {
    setSelectedNotificationCenter(alias);
    setShowModal(true);
  }

  return (
    <>
      <ModalEditNotificationCenter
        alias={selectedNotificationCenter}
        show={showModal}
        onHide={() => setShowModal(false)}
        refreshList={refreshList}
        refreshListSystem={refreshListSystem}
      />
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {
          currentNotificationsCenter.map(({ alias, title, origin }) =>
            <li
              key={alias}
              onClick={() => handleSelectedNotificationCenter(alias)}
              style={{ borderRadius: 4, border: "1px solid gray", padding: "10px 20px", marginBottom: 8, cursor: "pointer" }}>
              {title} -  <span style={{ fontStyle: "italic" }}>{origin}</span>
            </li>
          )
        }
      </ul>
    </>
  )
}

// Se muestra cuando se selecciona una notificacion para crear en el select
const DefaultNotification = ({ notification }) => {

  const { system_message = null, system_info = [], system_notification_type } = notification;
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    setRerender(true);
  }, [notification]);

  useEffect(() => {
    if (rerender === true) {
      setRerender(false);
    }
  }, [rerender]);

  if (rerender) return <></>

  return (
    <div className="default-notification">

      <div className="mb-3">
        <span style={{ fontWeight: "600" }}>Mensaje de la notificación: <span style={{ fontWeight: "400" }}>{system_message}</span></span>
      </div>

      <div>
        <span style={{ fontWeight: "600" }}>Informacion en el sistema</span>
        {
          Array.isArray(system_info) &&
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {
              system_info.map(({ name, alias }) => <li key={alias}>* {name}</li>)
            }
          </ul>
        }
      </div>

      <div>
        <span style={{ fontWeight: "600" }}>Tipo de notificación</span>
        {
          Array.isArray(system_notification_type) &&
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {
              system_notification_type.map(({ name, alias }) => <li key={alias}>* {name}</li>)
            }
          </ul>
        }
      </div>

    </div>
  )
}

export const Notifications = ({
  vision = "profile"
}) => {

  const [defaultNotifications, setDefaultNotifications] = useState([]);
  const [selectedDefaultNotification, setSelectedDefaultNotification] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [refreshListSystem, setRefreshListSystem] = useState(false);

  // Obtener las notificaciones por default para poder crear notifications center
  useEffect(() => {
    getDefaultNotifications()
      .then(resp => {
        setDefaultNotifications(resp.data);
      });
  }, []);

  // Efecto para hacer refresh de las listas
  useEffect(() => {
    if (refreshList) {
      setRefreshList(false);
    }
    if (refreshListSystem) {
      setRefreshListSystem(false);
    }
  }, [refreshList, refreshListSystem]);

  // Funcion para crear un notification center
  const handleCreateNotificationCenter = async () => {
    const alias = selectedDefaultNotification?.alias;
    if (!alias) return;
    try {
      const resp = await createNotificationCenter({ notification_system: alias });
      showSnack("success", "Creado en el centro de notificaciones");
      setRefreshList(true);
    } catch (error) {
      console.log("error")
    }
  }

  return (
    <div className="configuration-notifications">

      {(vision == "settings") &&
        <div className="dyTheme1 dyBorder1 rounded p-30 border mb-20">
          <h5>Configura tus notificaciones</h5>

          <div>
            {
              !refreshList &&
              <ListOfNotificationCenter
                refreshList={() => setRefreshList(true)}
                refreshListSystem={() => setRefreshListSystem(true)}
              />
            }
          </div>

          <div className="mb-3">
            <MCSelect
              optionValue="alias"
              optionLabel="system_title"
              options={defaultNotifications}
              value={selectedDefaultNotification}
              onChange={setSelectedDefaultNotification}
            />
          </div>

          {
            selectedDefaultNotification &&
            <div>
              <DefaultNotification notification={selectedDefaultNotification} />
              <div className="d-flex justify-content-end">
                <MCButton onClick={handleCreateNotificationCenter} variant="primary" label="Agregar al Centro de Notificaciones" />
              </div>
            </div>

          }

        </div>
      }

      {(vision == "profile") &&
        <div className="dyTheme1 dyBorder1 rounded p-30 border mb-20">
          <h5>Configura tus notificaciones del sistema</h5>

          <div>
            {
              !refreshListSystem &&
              <ListOfNotificationSystem
                refreshList={() => setRefreshListSystem(true)}
              />
            }
          </div>

        </div>
      }


    </div>
  )
}
