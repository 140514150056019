import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Row, Col, FormCheck } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Mexico from "../../../../../assets/images/mexico.png";
import { getUserList } from "../../../../../apis/apiUsers";
import { Loading } from "../../../../Loading/String/Loading";
import Icon from "../../../../Icon/Icon";
import { createAttachment, patchInvestigation } from "../../../../../apis";
import { validInitDates } from "../../../../../helpers/dateTransform";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCButton } from "../../../../MainComponents/Button/Button";
import "./Entrevista.scss";
import { GeneralHeader } from "../../../../Title/GeneralHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           {" "}
//           Preguntas avanzadas{" "}
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <small className="mt-20">Descripción del cuestionario</small>
//         <CDBInput
//           type="textarea"
//           size="sm"
//           rows="2"
//           hint="Eje. preguntas recomendadas para casos de abuso sexual"
//         />
//         <small className="mt-20">Cusstom code</small>
//         <CDBInput type="textarea" size="sm" rows="8" hint="" />
//       </Modal.Body>
//       <Modal.Footer>
//         <CDBBtn
//           className="athics-outline-btn mr-3"
//           outline
//           onClick={props.onHide}
//         >
//           CANCELAR
//         </CDBBtn>
//         <CDBBtn className="athics-btn" onClick={props.onHide}>
//           GUARDAR
//         </CDBBtn>
//       </Modal.Footer>
//     </Modal>
//   );
// }

const FilesComponent = ({
  filesArray,
  setFilesArray,
  files,
  setFiles,
  existingFiles,
  isNew,
}) => {
  const inputFiles = useRef(null);
  const [t] = useTranslation("investigation");

  const hdlAttch = (e) => {
    const newFiles = [...e.target.files];
    setFilesArray((prevFiles) => [...prevFiles, ...newFiles]);
    const formData = files || new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("attachments", newFiles[i]);
    }
    setFiles(formData);
  };
  const hdlDragOvAttch = (e) => {
    e.preventDefault();
  };
  const hdlDropAttch = (e) => {
    e.preventDefault();
    const newFiles = [...e.dataTransfer.files];
    setFilesArray((prevFiles) => [...prevFiles, ...newFiles]);
    const formData = files || new FormData();
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("attachments", newFiles[i]);
    }
    setFiles(formData);
  };
  return (
    <>
      {isNew && (
        <div
          className={`preview-input-container fade-in-image`}
          onDrop={hdlDropAttch}
          onDragOver={hdlDragOvAttch}
        >
          <div
            className="attachments pointer"
            onClick={() => inputFiles.current.click()}
          >
            <div className="attachments-icon-container">
              <Icon name="gallery" />
              <Icon name="outline_clipboard_text" />
              <Icon name="volume_high" />
              <Icon name="outline_video" />
              <Icon name="outline_document" />
            </div>

            <div className="attachments-instructions">
              <p>{t("Interview.drag_files")}</p>
              <button className="btn attachments-btn">
                {t("Interview.click_here")}
              </button>
            </div>
          </div>
          <input
            className={`form-control w-100`}
            type="file"
            onChange={hdlAttch}
            multiple
            hidden
            ref={inputFiles}
          />
        </div>
      )}
      <Row>
        {existingFiles !== null && (
          <>
            <h6 className="mt-20">{t("Interview.digital_evidences")}</h6>
            {existingFiles.map((item, idx) => (
              <Col key={idx} lg="3" md="6" className="p-0">
                <div className="pr-md-30">
                  <MCButton
                    label={item.name}
                    variant="primary"
                    className="download-btn"
                    icon="more_dots"
                  />
                </div>
              </Col>
            ))}
          </>
        )}
        <></>
        {files !== null && (
          <>
            <h6 className="mt-20">{t("Interview.upload_evidences")}</h6>
            {[...files.entries()].map((item, idx) => (
              <Col key={idx} lg="3" md="6" className="p-0">
                <div className="pr-md-30">
                  <MCButton
                    label={item[1].name}
                    variant="primary"
                    className="download-btn"
                    icon="outline_send_square"
                  />
                </div>
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export const ConfigInterview = () => {
  const [t] = useTranslation("investigation");

  const navigate = useNavigate();
  const location = useLocation();
  const { gTheme } = useSelector((state) => state.theme);
  const investigationAlias = location.state.investigationAlias;
  const interviews = location.state.interviews || null;
  const currentInterview = location.state.currentInterview || null;
  const indexCurrentInterview = location.state.index;
  const isNew = location.state.currentInterview ? false : true;

  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [filesArrayPrincipal, setFilesArrayPrincipal] = useState([]);
  const [filesPrincipal, setFilesPrincipal] = useState(null);
  const [existingFilesPrincipal, setExistingFilesPrincipal] = useState(
    currentInterview?.attachments?.principal || null
  );

  const [filesArraySigned, setFilesArraySigned] = useState([]);
  const [filesSigned, setFilesSigned] = useState(null);
  const [existingFilesSigned, setExistingFilesSigned] = useState(
    currentInterview?.attachments?.signed || null
  );

  const [filesArraySupport, setFilesArraySupport] = useState([]);
  const [filesSupport, setFilesSupport] = useState(null);
  const [existingFilesSupport, setExistingFilesSupport] = useState(
    currentInterview?.attachments?.support || null
  );
  const [selectedInterviewer, setSelectedInterviewer] = useState(
    currentInterview?.interviewer?.kind || ""
  );
  const [interviewer, setInterviewer] = useState(
    currentInterview?.interviewer || {}
  );
  const [interviewed, setInterviewed] = useState(
    currentInterview?.interviewed || ""
  );
  const [witness, setWitness] = useState(currentInterview?.witness || []);
  const [record, setRecord] = useState(currentInterview?.record || false); // true || false
  const [details, setDetails] = useState(currentInterview?.details || "");
  const [status, setStatus] = useState(currentInterview?.status || "pending");
  const [kind, setKind] = useState(currentInterview?.kind || "in-person"); // "video-conference" || "in-person"
  const [scheduled, setScheduled] = useState(
    validInitDates(currentInterview?.scheduled)
      ? new Date(currentInterview?.scheduled)
      : new Date()
  );
  const [created_at, setCreated_at] = useState(
    validInitDates(currentInterview?.created_at)
      ? new Date(currentInterview?.created_at)
      : new Date()
  ); // on back
  const [interview, setInterview] = useState(currentInterview?.interview || []); // id, question, answer, placeholder, required

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [required, setRequired] = useState(false);

  useEffect(() => {
    getUserList().then((resp) => {
      const selectUserList = resp.users.map((item) => ({
        ...item,
        value: item.id,
        label: item.username,
      }));
      setUserList(selectUserList);
      setIsLoading(false);
    });
  }, []);

  const handleWitness = (e) => {
    const newWitness = e.map((item) => ({
      id: item.id,
      kind: "witness",
      name: item.username,
      role: "witness",
    }));
    setWitness(newWitness);
  };

  const getWittnes = () => {
    const valueSelect = [];
    witness.map((item) => {
      userList.map((item2) => {
        if (item.id === item2.id) {
          valueSelect.push(item2);
        }
      });
    });
    return valueSelect;
  };

  const handleInterviewer = (id) => {
    setSelectedInterviewer(id);
    const interviewer = userList.find((item) => item.id == id);
    if (interviewer) {
      const kind = id;
      const name = interviewer.username;
      const role = "interviewer";
      setInterviewer({ kind, name, role });
    } else {
      setInterviewer({});
    }
  };

  const handleAddQuestion = () => {
    setInterview((antQuestion) => [
      ...antQuestion,
      { question, answer, required },
    ]);
    setQuestion("");
    setAnswer("");
  };

  const handleSubmit = async () => {
    let newForm;
    let filesPrincipal_id, filesSupport_id, filesSigned_id;
    try {
      if (filesPrincipal) {
        const filesResPrincipal = await createAttachment(filesPrincipal);
        filesPrincipal_id = filesResPrincipal.attachments_id;
      }
      const filesToSendPrincipal = filesArrayPrincipal.map((file, idx) => ({
        file: filesPrincipal_id[idx],
        name: file.name,
        purpose: "General",
      }));

      if (filesSupport) {
        const filesResSupport = await createAttachment(filesSupport);
        filesSupport_id = filesResSupport.attachments_id;
      }
      const filesToSendSupport = filesArraySupport.map((file, idx) => ({
        file: filesSupport_id[idx],
        name: file.name,
        purpose: "General",
      }));

      if (filesSigned) {
        const filesResSigned = await createAttachment(filesSigned);
        filesSigned_id = filesResSigned.attachments_id;
      }
      const filesToSendSigned = filesArraySigned.map((file, idx) => ({
        file: filesSigned_id[idx],
        name: file.name,
        purpose: "General",
      }));

      const newInterview = {
        kind,
        record,
        status,
        details,
        interview,
        scheduled,
        created_at,
        attachments: {
          signed: filesToSendSigned,
          support: filesToSendSupport,
          principal: filesToSendPrincipal,
        },
        witness,
        interviewed,
        interviewer,
      };
      if (interviews === null) {
        newForm = [];
      } else {
        newForm = _.cloneDeep(interviews);
      }
      newForm.push(newInterview);

      const objToSave = {
        folder: {
          interviews: newForm,
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      // console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    let newForm;
    try {
      const newInterview = {
        ...currentInterview,
        kind,
        record,
        status,
        details,
        interview,
        scheduled,
        witness,
        interviewed,
        interviewer,
      };
      newForm = _.cloneDeep(interviews);
      _.set(newForm, `${indexCurrentInterview}`, newInterview);
      const objToSave = {
        folder: {
          interviews: newForm,
        },
      };
      // console.log(objToSave);
      const resp = await patchInvestigation(investigationAlias, objToSave);
      navigate(-1);
      //   console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <div className={`page ${gTheme !== "light" ? "dark-mode" : ""} `}>
        <GeneralHeader title={t("investigation.interviews.title")} />

        <Row>
          <Col lg="6" className="p-0">
            <div className="mt-30">
              <div className="dyTheme1 dyBorder1 rounded p-20">
                <h5 className="mt-10">{t("Interview.interviews")}</h5>
                <small className="mt-20"></small>
                <small className="mt-20">{t("Interview.interviewer")}:</small>
                <Row>
                  <Col md="6" className="p-0">
                    <div className="mt-1">
                      {Array.isArray(userList) && (
                        <MCSelect
                          options={userList}
                          onChange={(e) => handleInterviewer(e.value)}
                          value={userList.find(
                            (item) => selectedInterviewer == item.id
                          )}
                        />
                      )}
                    </div>
                  </Col>
                  <Col md="6" className="p-0">
                    {/* <div className="pl-md-30 mt-20">
                      <CDBBtn className="athics-outline-btn w-100" outline>
                        CREAR NUEVO USUARIO
                      </CDBBtn>
                    </div> */}
                  </Col>
                </Row>
                <small className="mt-20">{t("Interview.interviewee")}:</small>
                <Row>
                  <Col md="6" className="px-0 mt-1">
                    <MCInput
                      type="text"
                      value={interviewed}
                      onChange={(e) => setInterviewed(e.target.value)}
                    />
                  </Col>
                </Row>
                <small className="mt-20">
                  {t("Interview.interview_witness")}:
                </small>
                <div className="mt-1 multi-sel">
                  {Array.isArray(userList) && (
                    <MCSelect
                      options={userList}
                      onChange={handleWitness}
                      isMulti
                      value={getWittnes()}
                    />
                  )}
                </div>
              </div>
              <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
                <h5 className="mt-10">{t("Interview.about_interview")}</h5>
                <small className="mt-20"></small>
                <small className="mt-20">
                  {t("Interview.interview_recorded")}
                </small>
                <div className="d-flex mt-2">
                  <MCButton
                    label={t("Interview.yes")}
                    variant="primary"
                    outline={record ? false : true}
                    width={160}
                    className="me-3"
                    onClick={() => setRecord(true)}
                  />
                  <MCButton
                    label="No"
                    variant="primary"
                    outline={!record ? false : true}
                    width={160}
                    onClick={() => setRecord(false)}
                  />
                </div>
                <h6 className="my-20">
                  {t("Interview.interview_attachments")}:
                </h6>
                <Row>
                  <FilesComponent
                    files={filesPrincipal}
                    filesArray={filesArrayPrincipal}
                    setFiles={setFilesPrincipal}
                    setFilesArray={setFilesArrayPrincipal}
                    existingFiles={existingFilesPrincipal}
                    isNew={isNew}
                  />
                </Row>
                <h6 className="my-20">{t("Interview.support_documents")}:</h6>
                <Row>
                  <FilesComponent
                    files={filesSupport}
                    filesArray={filesArraySupport}
                    setFiles={setFilesSupport}
                    setFilesArray={setFilesArraySupport}
                    existingFiles={existingFilesSupport}
                    isNew={isNew}
                  />
                </Row>
                <h6 className="my-20">{t("Interview.documents_signed")}:</h6>
                <Row>
                  <FilesComponent
                    files={filesSigned}
                    filesArray={filesArraySigned}
                    setFiles={setFilesSigned}
                    setFilesArray={setFilesArraySigned}
                    existingFiles={existingFilesSigned}
                    isNew={isNew}
                  />
                </Row>
              </div>
              <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
                <h5 className="mt-10">{t("Interview.objectives_topics")}</h5>
                <small className="mt-20 mb-2">
                  {t("Interview.objectives_details")}:
                </small>
                <MCInput
                  type="textarea"
                  rows="3"
                  value={details}
                  placeholder={t("Interview.enter_objectives_details")}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
              <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
                <h5 className="mt-10">{t("Interview.meet_date")}</h5>
                <small className="mt-20">{t("Interview.how_interview")}</small>
                <div className="mt-2">
                  <MCButton
                    label={t("Interview.in_person")}
                    variant="primary"
                    outline={kind === "in-person" ? false : true}
                    width={160}
                    className="me-3"
                    onClick={() => setKind("in-person")}
                  />
                  <MCButton
                    label={t("Interview.videocall")}
                    variant="primary"
                    outline={kind !== "in-person" ? false : true}
                    width={160}
                    onClick={() => setKind("video-conference")}
                  />
                </div>
                <small className="mt-10 mb-1">
                  {t("Interview.meet_date")}:
                </small>
                <DatePicker
                  selected={scheduled}
                  onChange={(d) => setScheduled(d)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <div className="text-center text-md-right mt-20">
                  <MCButton
                    label={t("Interview.cancel")}
                    variant="primary"
                    outline
                    className="me-3"
                    onClick={() => navigate(-1)}
                  />
                  <MCButton
                    label={t("Interview.save")}
                    variant="primary"
                    onClick={() => (isNew ? handleSubmit() : handleUpdate())}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" className="p-0">
            <div className="pl-md-30 mt-30">
              {/* <div className="dyTheme1 dyBorder1 rounded p-20">
                <h5>Programación de preguntas</h5>
                <small>
                  Te recomendamos precargar todas las preguntas antes de iniciar
                  tu entrevista{" "}
                </small>
              </div> */}
              <div className="dyTheme1 dyBorder1 rounded p-20 mt-20">
                <div className="d-block d-md-flex align-items-center justify-content-between">
                  <div>
                    <h5>{t("Interview.write_questions_to_ask")}</h5>
                    <small>{t("Interview.prepare_questions_to_ask")}</small>
                  </div>
                </div>
                <div className="p-20 mt-20">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="m-0">{t("Interview.new_question")}</h6>
                  </div>
                  <small className="mt-20 mb-2">
                    {t("Interview.question")}:
                  </small>
                  <MCInput
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                  <small className="mt-20 mb-2">{t("Interview.answer")}:</small>
                  <MCInput
                    type="textarea"
                    rows="5"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  {/* <div className='d-block d-sm-flex align-items-center text-center'> */}
                  {/* <CDBBtn className="download-btn w-160 my-2" size="sm">
                    <Paperclip /> 5 archivos adjuntos
                  </CDBBtn> */}
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="d-flex align-items-center">
                      <FormCheck
                        type="switch"
                        checked={required}
                        onChange={(e) => setRequired(e.target.checked)}
                      />
                      <small className="ms-2">{t("Interview.required")}</small>
                    </div>
                    <MCButton
                      label={t("Interview.save_question")}
                      variant="primary"
                      outline
                      onClick={handleAddQuestion}
                    />
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div>
                {interview.map((item, idx) => {
                  // id, question, answer, placeholder, required
                  return (
                    <div
                      className="p-20 dyTheme1 dyBorder1 rounded mt-20"
                      key={idx}
                    >
                      <div className="d-flex align-items-center justify-content-between pb-3 border-bottom">
                        <h6 className="m-0">
                          {`${t("Interview.question")} #${idx + 1}`}
                        </h6>
                        <small>
                          {item.required ? `${t("Interview.required")}*` : ""}
                        </small>
                      </div>
                      <small className="mt-3">{t("Interview.question")}:</small>
                      <h6 className="mt-3">{item.question}</h6>
                      <small className="mt-3">{t("Interview.answer")}:</small>
                      <h6 className="mt-3">{item.answer}</h6>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> */}
    </>
  );
};
