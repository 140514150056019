import React, { useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCInput } from "../../../../MainComponents/Input/Input";
import {
  parseDateToLgComun,
  parseDateToLgComun2,
  parseDateToYYYYMMDD,
} from "../../../../../helpers/dateTransform";
import { createAttachment, patchInvestigation } from "../../../../../apis";
import { useSnackbar } from "notistack";
import { EthicsModal } from "../../../../Modal/EthicsModal";
import { ConcludeSimpInv } from "../ModalContent/ConcludeSimpInv";
import { ReOpenInv } from "../ModalContent/ReOpenInv";
import { useTranslation } from "react-i18next";
import "./Conclusion.scss";
import { FileUpload, MCLabel, MCTooltip } from "../../../../MainComponents";
import {
  BoldCloseCircle,
  BoldTrash,
  OutlineDocumentText,
  Paperclip,
} from "../../../../Icon";

// ---Rendered when a Simple Investigation has been selected---//
export const Conclusion = ({
  investigation,
  investigationAlias,
  setRerender,
}) => {
  // console.log("investigation", investigation);
  const [t] = useTranslation("investigation");
  const [hoursTime, setHoursTime] = useState(0);
  const [avaiableEdit, setAvaiableEdit] = useState(true);
  const [comment, setComment] = useState(investigation?.comment || "");
  const [conclusion, setConclusion] = useState(
    investigation?.conclusion || " "
  );
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [conclude, setConclude] = useState(t("Conclusion.conclusion_date"));
  const [size, setSize] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState(
    investigation.conclusion_files || {}
  );
  // Date calculation algorithm
  const today = new Date();
  const investigationCreatedDate = new Date(investigation.created_at);
  const conclusionDate = investigation?.conclusion_at;
  const timeDiference =
    conclusionDate === null
      ? today - investigationCreatedDate
      : new Date(conclusionDate) - investigationCreatedDate;
  const elapsedTimeDays = Math.floor(timeDiference / (1000 * 60 * 60 * 24));
  //Title to describe status of conclusion Smp Inv
  const comparingTime = (parametro) => {
    if (
      parseDateToYYYYMMDD(new Date()) < parseDateToYYYYMMDD(new Date(parametro))
    ) {
      setConclude(t("Conclusion.scheduled_date"));
    } else {
      setConclude(t("Conclusion.conclusion_date"));
    }
  };

  //It is determined how much time has passed since the registration of the investigation
  useEffect(() => {
    comparingTime(conclusionDate);
    const interval = setInterval(() => {
      const hours = Math.floor(timeDiference / (1000 * 60 * 60));
      setHoursTime(hours);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [conclusionDate, timeDiference]);
  //Open or Close dynamic modal
  const openModal = (component) => {
    setModalContent(component);
    setShow(true);
  };
  const closeModal = (component) => {
    setModalContent(null);
    setShow(false);
  };
  //Set it if close/open modal
  const handleModal = () => setShow((show) => !show);
  // Set if it is available to edit the form before it is submitted
  const handleEdit = () => setAvaiableEdit((edit) => !edit);
  // Prevent possible errors when executing handleEdit function
  const handleMouseDown = (e) => {
    e.preventDefault();
  };
  // Sending edited information
  const handleEditionInfo = async (e) => {
    e.preventDefault();
    const updating = enqueueSnackbar(`${t("messages.updating")}...`, {
      variant: "info",
    });
    let files_id;
    let filesToSend = [];

    try {
      // Verify if have a file loaded
      if (files.length > 0) {
        for (const fileObj of files) {
          const { formData } = fileObj;
          const filesRes = await createAttachment(formData);
          files_id = filesRes.attachments_id;
          filesToSend.push({
            file: files_id,
            name: fileObj.file.name,
          });
        }
      }

      // Add current files
      if (filesUploaded && filesUploaded.length > 0) {
        filesUploaded.forEach(file => {
          filesToSend.push({
            file: file.file,
            name: file.name
          });
        });
      }

      const objToSave = {
        comment,
        conclusion,
        conclusion_at: null,
        folder: {
          conclusion: {
            files: filesToSend,
          },
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      closeSnackbar(updating);
      setAvaiableEdit(true);
      setRerender(true);
      setFiles([]);
    } catch (error) {
      console.error(error);
      closeSnackbar(updating);
    }
  };

  // Function to open investigation
  const handleOpenInfo = async (e) => {
    e.preventDefault();
    const message = t("messages.update_success");
    const updating = enqueueSnackbar(`${t("messages.updating")}...`, {
      variant: "info",
    });
    const objToSave = {
      comment,
      conclusion,
      conclusion_at: null,
    };
    try {
      await patchInvestigation(investigationAlias, objToSave, message);
      closeSnackbar(updating);
      setAvaiableEdit(true);
      setRerender(true);
    } catch (error) {
      console.log(error);
    }
  };

  //Open modal to confirm re-open investigation
  const handleReOpenInv = (e) => {
    e.preventDefault();
    //modal size
    setSize("md");
    openModal(
      <ReOpenInv handleEditionInfo={handleOpenInfo} closeModal={closeModal} />
    );
  };
  // HandleConclude to approves and concludes Smp Inv
  const handleConclude = (e) => {
    e.preventDefault();
    if (conclusion === "" || null) {
      alert(t("messages.required_conclusion"));
      return;
    }
    const objToSave = {
      conclusion: investigation.conclusion,
    };
    //modal size
    setSize("lg");
    openModal(
      <ConcludeSimpInv
        investigationAlias={investigationAlias}
        objToSave={objToSave}
        setRerender={setRerender}
        closeModal={closeModal}
      />
    );
  };


  const handleDelete = async (idx) => {
    const newFiles = filesUploaded.filter((_, index) => index !== idx);
    setFilesUploaded(newFiles);

    let filesToSend = [];

    const updating = enqueueSnackbar(`${t("messages.updating")}...`, {
      variant: "info",
    });

    try {

      if (newFiles && newFiles.length > 0) {
        newFiles.forEach(file => {
          filesToSend.push({
            file: file.file,
            name: file.name
          });
        });
      }

      const objToSave = {
        comment,
        conclusion,
        conclusion_at: null,
        folder: {
          conclusion: {
            files: filesToSend,
          },
        },
      };
      const resp = await patchInvestigation(investigationAlias, objToSave);
      closeSnackbar(updating);
      setAvaiableEdit(true);
      setRerender(true);
    } catch (error) {
      console.error(error);
      closeSnackbar(updating);
    }

  };



  return (
    <div>
      <EthicsModal
        show={show}
        handleModal={handleModal}
        modalContent={modalContent}
        size={size}
      />
      <div className="simpleInvestigation dyTheme1 dyBorder1 rounded">
        {/* Header */}
        <Col className="simpleInvestigation__infoGeneral">
          <Row>
            <div className="simpleInvestigation__header mb-3 border-bottom">
              <div className="d-flex ">
                <h4 className="my-1">{t("Conclusion.conclusion")}</h4>
                {conclusion.trim() === "" || null ? (
                  <MCLabel
                    helptip={t(
                      "Conclusion.before_approving_concluding_investigation_fill_fields"
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
              {investigation.conclusion_at !== null ? (
                <MCButton
                  label={t("Conclusion.reopen_investigation")}
                  variant="danger"
                  onClick={handleReOpenInv}
                />
              ) : (
                <MCButton
                  label={
                    avaiableEdit ? t("Conclusion.edit") : t("Conclusion.cancel")
                  }
                  variant={avaiableEdit ? "primary" : "secondary"}
                  outline={avaiableEdit ? true : false}
                  onClick={handleEdit}
                  onMouseDown={handleMouseDown}
                />
              )}
            </div>
            <div className="simpleInvestigation__subHeader border-bottom">
              <div>
                <small className="mb-2">{t("Conclusion.start_date")}:</small>
                <h6>{parseDateToLgComun2(investigation.created_at)}</h6>
              </div>

              <div>
                <small className="mb-2">{conclude}:</small>
                <h6>
                  {investigation.conclusion_at
                    ? parseDateToLgComun2(investigation.conclusion_at)
                    : ""}
                </h6>
              </div>

              <div>
                <small className="mb-2">
                  {t("Conclusion.elapsed_time")}:{" "}
                  {elapsedTimeDays <= 0
                    ? `(${hoursTime} hr)`
                    : `(${elapsedTimeDays} ${t("Conclusion.day")}${elapsedTimeDays > 1 ? "s" : ""
                    })`}
                </small>
                <ProgressBar
                  animated
                  now={(elapsedTimeDays * 100) / 35 }
                  variant={elapsedTimeDays >= 35 ? "danger" : "info"}
                />
                <div className="simpleInvestigation__line-chart">
                  <div className="simpleInvestigation__line">
                    <div className="simpleInvestigation__axis">
                      <small className="tick">0</small>
                      <small className="tick">8</small>
                      <small className="tick">16</small>
                      <small className="tick">24</small>
                      <small className="tick">35</small>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Row>
        </Col>
        {/* Simple Investigation Form */}
        <Col>
          <Row className="my-3">
            <div>
              {avaiableEdit ? (
                <h6 className="my-1">{t("Conclusion.comments")}: </h6>
              ) : (
                <small className="my-1">{t("Conclusion.comments")}:</small>
              )}
              <MCInput
                value={avaiableEdit ? investigation?.comment || "" : comment}
                type="textarea"
                rows={4}
                placeholder={
                  avaiableEdit
                    ? t("Conclusion.not_comments")
                    : t("Conclusion.add_comments")
                }
                onChange={(e) => setComment(e.target.value)}
                disabled={avaiableEdit}
                className={avaiableEdit ? "informative" : ""}
              />
            </div>
          </Row>

          <Row className="my-1">
            <div>
              {avaiableEdit ? (
                <h6 className="my-1">{t("Conclusion.conclusion")}: </h6>
              ) : (
                <small className="my-1">{t("Conclusion.conclusion")}: </small>
              )}
              <MCInput
                value={
                  avaiableEdit ? investigation?.conclusion || "" : conclusion
                }
                type="textarea"
                rows={4}
                placeholder={
                  avaiableEdit
                    ? t("Conclusion.not_conclusion")
                    : t("Conclusion.add_conclusion")
                }
                onChange={(e) => setConclusion(e.target.value)}
                disabled={avaiableEdit}
                className={avaiableEdit ? "informative" : ""}
              />
            </div>
          </Row>

          <Row className="my-1">
            {!avaiableEdit ? (
              <>
                <small className="my-1">{`${t(
                  "Conclusion.upload_your_files"
                )}:`}</small>
                <FileUpload files={files} setFiles={setFiles} />
              </>
            ) : filesUploaded.length > 0 ? (
              <>
                <MCLabel text={`${t("Conclusion.uploaded_files")}:`} />
                {filesUploaded.map((fileRender, idx) => (

                  <li className="fileUpload__file">
                    <Paperclip className="--paperClip" />

                    <a
                      href={fileRender.url}
                      target="_blank"
                      key={idx}
                    >
                      <small className="fileUpload__name">
                        {fileRender.name}
                      </small>
                    </a>
                    <MCTooltip text={t("Conclusion.delete_file")} position="top">
                      <button onClick={() => handleDelete(idx)}>
                        <BoldCloseCircle />
                      </button>
                    </MCTooltip>
                  </li>

                ))}
              </>
            ) : (
              <>
                <MCLabel text={`${t("Conclusion.uploaded_files")}:`} />
                <small className="text-center mt-2">
                  {`${t("Conclusion.no_files_uploaded_yet")}...`}
                </small>
              </>
            )}
          </Row>

          <Row>
            <div className="simpleInvestigation__actionBtns">
              {investigation.conclusion_at === null ? (
                avaiableEdit ? (
                  conclusion.trim() === "" || null ? (
                    <></>
                  ) : (
                    <MCButton
                      label={t("Conclusion.approve_close")}
                      variant="primary"
                      onClick={handleConclude}
                    />
                  )
                ) : (
                  <MCButton
                    label={t("Conclusion.update")}
                    variant="warning"
                    outline
                    onClick={handleEditionInfo}
                  />
                )
              ) : (
                <p className="mt-5">*{t("Conclusion.investigation_closed")}</p>
              )}
            </div>
          </Row>
        </Col>
      </div>
    </div>
  );
};
