import { useEffect, useState } from "react";
import _, { set } from "lodash";
import { RenderInput, hdlChg, hdlSchm, hdlIsVal } from './PreviewForm'
import { checkTypeOfConditional, resetInputsCatalogues, returnFormData, returnIsValid } from "./formStarter";
import { useTranslation } from "react-i18next";

export const ConditionalInputs = ({ 
  entireSchema, entirePathSchema, setSchemaState,
  entireFormData, entirePathData, setFormData,
  entireIsValid, setIsValid, doValidate,
  conditionals = [], 
  parentValue, 
  parentOrigin,
  constraints,
  setConstraints,
  attachments, 
  setAttachments,
  tryToNext,
  activeStep,
  answered,
}) => {
  
  const [t] = useTranslation("forms");
  const [currentConditionals, setCurrentConditionals] = useState([]);
  const [currentConditionalsIdx, setCurrentConditionalsIdx] = useState(null);
  const [rerendering, setRerendering] = useState(false);
  const [parentValueState, setParentValueState] = useState(parentValue);

  // Para mantener el valor de estos dos campos en caso de que las condicionales cambien en el mismo scope

  function changeValidationForConditionals(getCurrentConditionals, getCurrentConditionalsIdx) {
    if (getCurrentConditionalsIdx!=currentConditionalsIdx) {
      const newSchemaConditionals = Array.isArray(getCurrentConditionals) && resetInputsCatalogues(getCurrentConditionals) || [];
      // let newDataConditionals = Array.isArray(getCurrentConditionals) && returnFormData(getCurrentConditionals, `${parentOrigin}::conditionals::${getCurrentConditionalsIdx}::nestChildren`) || [];
      // let newValidConditionals = Array.isArray(getCurrentConditionals) && returnIsValid(getCurrentConditionals) || [];
      const [newDataConditionals, newValidConditionals] = searchSubjectAndDescription(getCurrentConditionals, getCurrentConditionalsIdx); // modificar si es que hay condicionales con subject y con description
      hdlSchm({action: "resetInputs", pathSchema: `${entirePathData}.conditionals.${getCurrentConditionalsIdx}.nestChildren`, inputs: newSchemaConditionals, entireSchema, setSchemaState})
      hdlChg({e:{target:{value: newDataConditionals}}, entirePathData, params: { conditionals: newDataConditionals }, entireFormData, setFormData});
      hdlIsVal({errors: newValidConditionals, entirePathDataWithKey: `${entirePathData}.conditionals`, entireIsValid, setIsValid});
      setParentValueState(parentValue);
      setRerendering(true);
    }
  }
  
  function changeSchema() {
    if (!rerendering) {
      const getCurrentConditionals = conditionals.find(item=>checkTypeOfConditional(item, parentValue));
      const getCurrentConditionalsIdx = conditionals.findIndex(item=>checkTypeOfConditional(item, parentValue));
      setCurrentConditionals(getCurrentConditionals?.nestChildren);
      setCurrentConditionalsIdx(getCurrentConditionalsIdx);
      if (parentValueState != parentValue) {
        changeValidationForConditionals(getCurrentConditionals?.nestChildren, getCurrentConditionalsIdx);
      }
    }
  }
  function searchSubjectAndDescription(conditionals, condicionalsIdx) {
    let newDataConditionals = Array.isArray(conditionals) && returnFormData(conditionals, `${parentOrigin}::conditionals::${condicionalsIdx}::nestChildren`) || [];
    let newValidConditionals = Array.isArray(conditionals) && returnIsValid(conditionals) || [];
    const newDataConditionalsFinal = newDataConditionals.map((input, idx) => {
      const pathSchema = `${entirePathSchema}.conditionals.${condicionalsIdx}.nestChildren.${idx}`;
      const schema = _.get(entireSchema, `${pathSchema}`);
      if (!(schema.key === "subject" || schema.key === "description")) {
        return input;
      } else {
        // const pathOrigin = `${parentOrigin}::conditionals::${condicionalsIdx}::nestChildren::${idx}`;
        // const schema = _.get(entireSchema, `${pathSchema}`);
        const pathData = `${entirePathData}.conditionals`
        const previousData = _.get(entireFormData, `${pathData}`);
        if (!Array.isArray(previousData)) return input;

        const findedPrev = previousData.find(item=>schema.key in item);
        if (findedPrev) {
          const value = findedPrev[schema.key];
          return { ...input, [schema.key]: value}  
        } else {
          return input;
        }
      }
    });
    const newValidConditionalsFinal = newValidConditionals.map((input, idx) => {
      const pathSchema = `${entirePathSchema}.conditionals.${condicionalsIdx}.nestChildren.${idx}`;
      const schema = _.get(entireSchema, `${pathSchema}`);
      if (!(schema.key === "subject" || schema.key === "description")) {
        return input;
      } else {
        // const pathOrigin = `${parentOrigin}::conditionals::${condicionalsIdx}::nestChildren::${idx}`;
        // const schema = _.get(entireSchema, `${pathSchema}`);
        const pathData = `${entirePathData}.conditionals`
        const previousData = _.get(entireFormData, `${pathData}`);
        if (!Array.isArray(previousData)) return input;
        const findedPrev = previousData.find(item=>schema.key in item);
        if (findedPrev) {
          const value = findedPrev[schema.key];
          const errors = doValidate(value, schema.key, schema.required);
          return { ...input, [schema.key]: errors}  
        } else {
          return input;
        }
      }
    });
    return [newDataConditionalsFinal, newValidConditionalsFinal]
  }

  useEffect(() => {
    if (rerendering) {
      setRerendering(false);
    }
  }, [rerendering]);

  useEffect(() => {
    changeSchema();
  }, [parentValue]);
  
  return (
    <>
      {
        (Array.isArray(currentConditionals)) && (currentConditionals.length > 0) && (currentConditionalsIdx >= 0) && !rerendering &&
        currentConditionals.map((sch,idx) => (
          <>
            <RenderInput
              key={`${parentOrigin}::conditionals::${currentConditionalsIdx}::nestChildren::${idx}`}
              entireSchema={entireSchema}
              entirePathSchema={`${entirePathSchema}.conditionals.${currentConditionalsIdx}.nestChildren.${idx}`}
              setSchemaState={setSchemaState}
              entireFormData={entireFormData}
              entirePathData={`${entirePathData}.conditionals.${idx}`}
              setFormData={setFormData}
              entireIsValid={entireIsValid}
              setIsValid={setIsValid}
              constraints={constraints}
              setConstraints={setConstraints}
              attachments={attachments}
              setAttachments={setAttachments}
              tryToNext={tryToNext}
              activeStep={activeStep}
              origin={`${parentOrigin}::conditionals::${currentConditionalsIdx}::nestChildren::${idx}`}
              answered={answered}
            />
          </>
        ))
      }
    </>
  )
}