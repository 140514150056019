import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MCButton } from "../../../../MainComponents";

export const Scenarios = ({ scenarios=[], toggleModal }) => {
  const [t] = useTranslation("investigation");
  return (
    <div className="dyTheme1 dyBorder1 rounded p-20">
      <div className="border-bottom">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <h5>{t("General.locations")}</h5>
        </div>
        {scenarios?.length > 0 &&
          scenarios.map((scen, index) => (
            <div key={index} className="pb-20 border-bottom">
              <div className="d-block d-md-flex align-items-center justify-content-between">
                <div>
                  <small className="my-2">{t("General.scenario")}</small>
                  <h6 className="m-0">{scen?.name}</h6>
                </div>
                <div>
                  <MCButton
                    label={t("General.edit")}
                    variant="primary"
                    outline
                    onClick={() => toggleModal("scenarios", { id: index })}
                  />
                  {/* <CDBBtn className={`${scenarioClass(scen?.kind)} my-2`}>{ scenario(scen?.kind) }</CDBBtn> */}
                </div>
              </div>

              <Row className="mt-20">
                <Col lg="3" className="p-0">
                  <small className="mb-2">{t("General.site")}:</small>
                  <h6>{scen?.site || t("General.no_date")}</h6>
                </Col>
                <Col lg="9" className="p-0">
                  <small className="mb-2">{t("General.address")}:</small>
                  <h6>{scen?.location || t("General.no_date")}</h6>
                </Col>
              </Row>
            </div>
          ))}
      </div>
      <div className="py-3">
        <h6 className="my-3">{t("General.add_locations")}</h6>
        <MCButton
          label={t("General.add_scenario")}
          variant="primary"
          outline
          className="me-1"
          onClick={() => toggleModal("add-scenario")}
        />
      </div>
    </div>
  );
};
