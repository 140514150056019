import dates_esp from "../translations/ES/dates.json";
import dates_eng from "../translations/EN/dates.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? dates_esp : dates_eng;
// Date Formatter //
export const parseDatoToLgAndHZ = (originDate, locale = "es-MX") => {
  const fecha = new Date(originDate);
  const opcionesFecha = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const opcionesHora = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  const fechaFormateada = fecha.toLocaleDateString(locale, opcionesFecha);
  const horaFormateada = fecha.toLocaleTimeString(locale, opcionesHora);
  const resultado = `${fechaFormateada} - ${horaFormateada}`;
  return resultado;
};

/** @returns DD - Mon - YYYY */
export const parseDateToLgComun = (originDate) => {
  const fecha = new Date(originDate);
  const meses = [
    `${language.mons.jan}`,
    `${language.mons.feb}`,
    `${language.mons.mar}`,
    `${language.mons.apr}`,
    `${language.mons.may}`,
    `${language.mons.jun}`,
    `${language.mons.jul}`,
    `${language.mons.aug}`,
    `${language.mons.sep}`,
    `${language.mons.oct}`,
    `${language.mons.nov}`,
    `${language.mons.dec}`,
  ];
  const mes = meses[fecha.getMonth()];
  const resultado = `${fecha.getDate()} - ${mes} - ${fecha.getFullYear()}`;
  return resultado;
};

/** @returns ENG = "Mont DD, YYYY" || ESP = "DD de Mont del YYYY" */
export const parseDateToLgComun2 = (originalDate) => {
  const fecha = new Date(originalDate);
  const ano = fecha.getFullYear();
  const meses = [
    `${language.monts.january}`,
    `${language.monts.february}`,
    `${language.monts.march}`,
    `${language.monts.april}`,
    `${language.monts.may}`,
    `${language.monts.june}`,
    `${language.monts.july}`,
    `${language.monts.august}`,
    `${language.monts.september}`,
    `${language.monts.october}`,
    `${language.monts.november}`,
    `${language.monts.december}`,
  ];
  const mes = meses[fecha.getMonth()];
  const result = `${mes} ${fecha.getDate()}, ${ano}`;
  const resultado = `${fecha.getDate()} de ${mes} ${
    ano >= 2000 ? "del" : "de"
  } ${ano}`;
  if (lang === "es") {
    return resultado;
  } else {
    return result;
  }
};

/** @return ENG = "Day, DD MM YYYY - 00:00AM/PM" || ESP = "Día, DD de MM del YYYY - 00:00am/pm */
export const parseDateToLgComun3 = (
  originalDate,
  lang = "es",
  hoursToAdjust = 0
) => {
  //const date = new Date(originalDate);
  const date = new Date(originalDate);
  if (hoursToAdjust !== 0) {
    date.setHours(date.getHours() + hoursToAdjust);
  }

  const days = [
    `${language.days.sun}`,
    `${language.days.mon}`,
    `${language.days.tue}`,
    `${language.days.wed}`,
    `${language.days.thu}`,
    `${language.days.fri}`,
    `${language.days.sat}`,
  ];
  const weekDay = days[date.getDay()];
  const mons = [
    `${language.mons.jan}`,
    `${language.mons.feb}`,
    `${language.mons.mar}`,
    `${language.mons.apr}`,
    `${language.mons.may}`,
    `${language.mons.jun}`,
    `${language.mons.jul}`,
    `${language.mons.aug}`,
    `${language.mons.sep}`,
    `${language.mons.oct}`,
    `${language.mons.nov}`,
    `${language.mons.dec}`,
  ];
  const mon = mons[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hour = date.getHours();
  const minutes = date.getMinutes();
  const periodo = hour >= 12 ? "p.m." : "a.m.";
  const period = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  const resultado = `${weekDay}, ${day} de ${mon} ${
    year >= 2000 ? "del" : "de"
  } ${year} - ${hour}:${minutes < 10 ? "0" : ""}${minutes}${periodo}`;
  const result = `${weekDay}, ${day} ${mon} ${year} - ${hour}:${
    minutes < 10 ? "0" : ""
  }${minutes}${period}`;

  if (lang === "es") {
    return resultado;
  } else {
    return result;
  }
};

/** @return ENG = "Day, DD MM YYYY - 00:00:00 hrs" || ESP = "Día, DD de MM del YYYY - 00:00:00 hrs */

export const parseDateToLgComun4 = (
  originalDate,
  lang = "es",
  hoursToAdjust = 0
) => {
  const date = new Date(originalDate);
  if (hoursToAdjust !== 0) {
    date.setHours(date.getHours() + hoursToAdjust);
  }

  const days = [
    `${language.days.sun}`,
    `${language.days.mon}`,
    `${language.days.tue}`,
    `${language.days.wed}`,
    `${language.days.thu}`,
    `${language.days.fri}`,
    `${language.days.sat}`,
  ];
  const weekDay = days[date.getDay()];
  const mons = [
    `${language.mons.jan}`,
    `${language.mons.feb}`,
    `${language.mons.mar}`,
    `${language.mons.apr}`,
    `${language.mons.may}`,
    `${language.mons.jun}`,
    `${language.mons.jul}`,
    `${language.mons.aug}`,
    `${language.mons.sep}`,
    `${language.mons.oct}`,
    `${language.mons.nov}`,
    `${language.mons.dec}`,
  ];
  const mon = mons[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Formatear hora en formato de 24 horas
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds(); // Agregar los segundos

  const resultado = `${weekDay}, ${day} de ${mon} ${
    year >= 2000 ? "del" : "de"
  } ${year} - ${hour < 10 ? "0" : ""}${hour}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  const result = `${weekDay}, ${day} ${mon} ${year} - ${
    hour < 10 ? "0" : ""
  }${hour}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  if (lang === "es") {
    return resultado;
  } else {
    return result;
  }
};

/** @returns YYYY-MM-DD */
export const parseDateToYYYYMMDD = (originDate) => {
  if (!validInitDates(originDate)) return;
  const fecha = new Date(originDate);
  const anio = fecha.getFullYear().toString();
  const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  const dia = ("0" + fecha.getDate()).slice(-2);
  const fechaFormateada = `${anio}-${mes}-${dia}`;
  return fechaFormateada;
};

/** @returns DD-MM-YYYY */
export const parseDateToDDMMYYYY = (originDate) => {
  if (!validInitDates(originDate)) return;
  const fecha = new Date(originDate);
  const anio = fecha.getFullYear().toString();
  const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  const dia = ("0" + fecha.getDate()).slice(-2);
  const fechaFormateada = `${dia}-${mes}-${anio}`;
  return fechaFormateada;
};

/** @returns DD/MM/YYYY */
export const parseDateToDDMMYYYY2 = (originDate) => {
  if (!validInitDates(originDate)) return;
  const fecha = new Date(originDate);
  const anio = fecha.getFullYear().toString();
  const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  const dia = ("0" + fecha.getDate()).slice(-2);
  const fechaFormateada = `${dia}/${mes}/${anio}`;
  return fechaFormateada;
};

export const validInitDates = (date) => {
  var fechaValida = Date.parse(date);
  return !isNaN(fechaValida);
};

/** @returns YYYY/MM/DD */
export const parseDateToYYYYMMDD2 = (originDate) => {
  const fecha = new Date(originDate);
  const anio = fecha.getFullYear().toString();
  const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  const dia = ("0" + fecha.getDate()).slice(-2);
  const fechaFormateada = `${anio}/${mes}/${dia}`;
  return fechaFormateada;
};

/** @returns YYYY-MM-DD 00:00:00 */
export const parseDateToISOString = (originDate) => {
  const fecha = new Date(originDate);
  const year = fecha.getFullYear();
  const month = String(fecha.getMonth() + 1).padStart(2, "0");
  const day = String(fecha.getDate()).padStart(2, "0");
  const hours = String(fecha.getHours() + 1).padStart(2, "0");
  const minutes = String(fecha.getMinutes()).padStart(2, "0");
  const seconds = String(fecha.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

/** @returns DD-MM-YYYY 00:00:00 */
export const parseDateToISOString2 = (originDate) => {
  const fecha = new Date(originDate);
  const year = fecha.getFullYear();
  const month = String(fecha.getMonth() + 1).padStart(2, "0");
  const day = String(fecha.getDate()).padStart(2, "0");
  const hours = String(fecha.getHours() + 1).padStart(2, "0");
  const minutes = String(fecha.getMinutes()).padStart(2, "0");
  const seconds = String(fecha.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

/** @returns DD-MM-YYYY 00:00 */
export const parseDateToISOString3 = (originDate) => {
  const fecha = new Date(originDate);
  const year = fecha.getFullYear();
  const month = String(fecha.getMonth() + 1).padStart(2, "0");
  const day = String(fecha.getDate()).padStart(2, "0");
  const hours = String(fecha.getHours() + 1).padStart(2, "0");
  const minutes = String(fecha.getMinutes()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

/** @returns DD/MM/YYYY 00:00 hrs. */
export const parseDateToISOString4 = (originDate) => {
  const fecha = new Date(originDate);
  const year = fecha.getFullYear();
  const month = String(fecha.getMonth() + 1).padStart(2, "0");
  const day = String(fecha.getDate()).padStart(2, "0");
  const hours = String(fecha.getHours() + 1).padStart(2, "0");
  const minutes = String(fecha.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes} hrs`;
};
