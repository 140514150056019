import { CDBBtn } from "cdbreact";
import _ from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../../../../Icon/Icon";
import { patchInvestigation } from "../../../../../apis";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { MCCheckbox, MCInput } from "../../../../MainComponents/Input/Input";
import { MCLabel } from "../../../../MainComponents/Label/Label";
import { useTranslation } from "react-i18next";

export const AddResource = ({
  investigationAlias,
  formValues,
  toggleModal,
}) => {
  const [t] = useTranslation("report");

  const resourceTypeOpts = [
    {
      value: "material",
      label: t("Resource.material"),
    },
    {
      value: "financial",
      label: t("Resource.financial"),
    },
    {
      value: "human",
      label: t("Resource.human"),
    },
    {
      value: "access",
      label: t("Resource.access"),
    },
  ];

  const [resourceType, setResourceType] = useState(resourceTypeOpts[0]);
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [details, setDetails] = useState("");
  const [present, setPresent] = useState(true);

  const handleSubmit = async (evt) => {
    let newForm;
    evt.preventDefault();
    if (formValues === null) {
      newForm = {
        access: [],
        financial: [],
        human: [],
        material: [],
      };
    } else {
      newForm = _.cloneDeep(formValues);
    }
    if (
      resourceType.value === "financial" ||
      resourceType.value === "access" ||
      resourceType.value === "human" ||
      resourceType.value === "material"
    ) {
      if (
        resourceType.value in newForm &&
        Array.isArray(newForm[resourceType.value])
      ) {
        newForm[resourceType.value].push({ name, about, details, present });
      } else {
        newForm[resourceType.value] = [{ name, about, details, present }];
      }
    }
    const objToSave = {
      folder: {
        resources: newForm,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave);
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  return (
    <div>
      <Row>
        <h5>{t("Resource.investigation_resource")}</h5>
      </Row>

      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("Resource.resource_type")}:</small>
          <MCSelect
            options={resourceTypeOpts}
            value={resourceType}
            onChange={setResourceType}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("Resource.name")}:</small>
          <MCInput
            type="text"
            placeholder={t("Resource.enter_resource_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("Resource.description")}:</small>
          <MCInput
            type="text"
            placeholder={t("Resource.enter_resource_description")}
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("Resource.facilitator")}</small>
          <MCInput
            type="text"
            placeholder={t("Resource.enter_facilitator_description")}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">
            {t("Resource.present")}:
          </small>
          <MCCheckbox
            className="me-1"
            checked={present}
            onChange={(e) => setPresent(e.target.checked)}
            id="present-resource-checkbox"
          />
          <MCLabel
            text={t("Resource.facilitator_present")}
            htmlFor="present-resource-checkbox"
          />
        </Col>
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("Resource.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          <MCButton
            label={t("Resource.save")}
            variant="primary"
            outline
            onClick={handleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};
