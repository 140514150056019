import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MCButton } from "../../../MainComponents";
import { ReactComponent as Ethiquin } from "../../../../assets/icons/alerta_robot.svg";
import "./Information.scss";
import { EGIconButton } from "../../../MainComponents/Button/IconButton/IconButton";
import { BoldCloseCircle, CancelIconCircle } from "../../../Icon";
import { Stack, Typography, useTheme } from "@mui/material";

// Content of confirmation modal
const ConfirmationModal = ({
  show,
  onHide,
  onConfirm,
  title = "",
  description1 = "",
  description2 = "",
  question = "",
}) => {
  const theme = useTheme();
  const [t] = useTranslation("information");

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="confirmationModal"
    >
      <Modal.Body>
        <Row>
          <Col sm={4} className="d-none d-sm-block m-auto">
            <Ethiquin className="px-1 py-2" />
          </Col>
          <Col sm={8} className="p-1">
            <div className="confirmationModal__header mb-3">
              <h3 className="confirmationModal__title m-0">
                <b>{title}</b>
              </h3>
              <EGIconButton
                iconName="cancel_circle"
                onClick={onHide}
                iconColor="primary"
              />
            </div>

            <div className="confirmationModal__description">
              {description1 && (
                <Typography variant="body1" marginBottom={2}>
                  {description1}
                </Typography>
              )}
              {description2 && (
                <Typography variant="body1" marginBottom={2}>
                  {description2}
                </Typography>
              )}
            </div>

            <div className="confirmationModal__actions">
              <small className="mb-4">{question}</small>
              <div className="btns my-2">
                <MCButton
                  label={t("information.cancel")}
                  variant="secondary"
                  onClick={onHide}
                  outline
                  className="mx-3 w-50"
                />
                {
                  onConfirm &&
                  <MCButton
                    label={t("information.confirm")}
                    variant="primary"
                    onClick={onConfirm}
                    className="w-50"
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
