import React, { useRef, useState } from "react";
import { BoldCloseCircle } from "../../Icon";
import Icon from "../../Icon/Icon";
import "./ImagesUpload.scss";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
/**
 * @param {title} Customize the image component with the title of your preference
 * @param {icon} The Icon is dynamic, enter the name of the Icon that best suits the desired customization
 * @param {onChange} The event sets the preselected images to prepare them to send to the endpoint
 * @param {fieldKey} Helper to determine the name of the key to send to the endpoint
 * @returns
 */

export const ImageUpload = ({
  title = "Image",
  icon = "gallery_add",
  onChange = () => {},
  fieldKey,
}) => {
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const isDraggingOver = useRef(false);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("components");

  //onChange input event
  const handleChangeImage = (e) => {
    const files = e.target.files;
    handleImage(files);
    onChange(files[0], fieldKey);
  };
  //onClick on div customatized
  const handleDivClic = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  //Delete previous image
  const handleDeleteImage = () => {
    setImage("");
    setFileName("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    onChange(null, fieldKey);
  };
  //Grab and drop image file
  const handleImageDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImage(files);
    onChange(files[0], fieldKey);
    setShowMessage(false);
    closeSnackbar();
    divRef.current.classList.remove("drag-over");
  };
  //For effects of drag and drop image
  const handleDragEnter = (e) => {
    e.preventDefault();
    isDraggingOver.current = true;
    setShowMessage(true);
    divRef.current.classList.add("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const target = e.target;
    const clientX = e.clientX;
    const clientY = e.clientY;
    const elementBox = target.getBoundingClientRect();

    // Check if the cursor has completely left the div area
    if (
      clientX < (elementBox.left - 1) ||
      clientX > (elementBox.right - 1) ||
      clientY < (elementBox.top - 1) ||
      clientY > (elementBox.bottom - 1)
    ) {
      setShowMessage(false);
      closeSnackbar();
      isDraggingOver.current = false;
      divRef.current.classList.remove("drag-over");
    }
  };

  const handleMouseLeave = () => {
    // If the cursor leaves the div area, clear the drag state
    if (isDraggingOver.current) {
      isDraggingOver.current = false;
      setShowMessage(false);
      closeSnackbar();
      divRef.current.classList.remove("drag-over");
    }
  };
  //Creation of URL from file upload to see previously on screen
  const handleImage = (files) => {
    files[0] && setFileName(files[0].name);
    if (files) {
      if (image) {
        // Release resources from the previous URL object (if exist)
        URL.revokeObjectURL(image);
      }
      setImage(URL.createObjectURL(files[0]));
    }
  };
  // prevents the default behavior of the browser when dragging on the input
  const preventDefaultBehavior = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  // Show message with drag and drop message instructions
  {
    showMessage &&
      enqueueSnackbar(`${t("ImageUpload.drop_add")} ${title}`, {
        variant: "info",
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "top" },
        autoHideDuration: 2000,
      });
  }

  return (
    <div className="imageUpload">
      <small className="imageUpload__title">{title}:</small>
      {/* render image preview */}
      {!image ? (
        <div
          className="imageUploader"
          onClick={handleDivClic}
          onDragOver={preventDefaultBehavior}
          onDrop={handleImageDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onMouseLeave={handleMouseLeave}
          ref={divRef}
        >
          <input
            type="file"
            ref={inputRef}
            onChange={handleChangeImage}
            className="imageUploader__inputImage"
            name="inputImage"
            accept="image/*,.svg"
          />
          <Icon name={icon} size={50} className="imageUploader__icon" />
          <code className="imageUploader__subtitle m-2">
            {t("ImageUpload.click_drag")}
          </code>
        </div>
      ) : (
        <div className="imageViewer">
          <button onClick={handleDeleteImage} className="imageViewer__delete">
            <BoldCloseCircle />
          </button>
          <img src={image} alt={fileName} className="imageViewer__image" />
        </div>
      )}
    </div>
  );
};
