import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown2 } from "../../../Icon";
import { Dropdown, Toast, ToastHeader } from "react-bootstrap";
import { MCButton, MCInput } from "../../../MainComponents";
import "./Care.scss";
import {enqueueSnackbar } from "notistack";


export const ChangeReportStatus = ({
  status,
  handleStatusChange,
  getStatus,
  reportDetail,
  status_locales,
  commentStatus,
  setCommentStatus,
  messageStatus,
  setMessageStatus,
  handleUpdateStatus,
  selectAll,
  setSelectAll,
  changeStatus
}) => {


  // console.log("################################");
  // console.log("SELECT ALL", selectAll);
  // console.log("################################");




  const handleSaveClick = () => {
    // Llamar a la función updateStatus
    handleUpdateStatus();
  
    // Limpiar el campo de texto
    setCommentStatus('');

    enqueueSnackbar(`${t("Conclusion.sending_successful")}`, {
      variant: "success",
    });

  };



  const [activeToast, setActiveToast] = useState(null); // 'observations' or 'change_status'
  // Initial States
  const [enable, setEnable] = useState({
    observations: false,
    messages: false,
    change_status: false,
  });
  // -----> Translate <----- //
  const [t] = useTranslation("report");
  // -----> Status list <----- //
  const reportStatus = [
    {
      status: "new",
      label: t("status.new"),
      description: t("Care.new_description"),
    },
    {
      status: "pending",
      label: t("status.pending"),
      description: t("Care.pending_description"),
    },
    {
      status: "process",
      label: t("status.process"),
      description: t("Care.process_description"),
    },
    {
      status: "finish",
      label: t("status.finish"),
      description: t("Care.finish_description"),
    },
    {
      status: "closed",
      label: t("status.closed"),
      description: t("Care.closed_description"),
    },
  ];
  // Messages before upload status
  const alertChange = {
    new: t("Care.new_description"),
    pending: t("Care.pending_description"),
    process: t("Care.process_description"),
    resolution: t("Care.resolution_description"),
    closed: t("Care.closed_description"),
  };
  // -----> Customizing the drop-down menu message <----- //
  const changeReportStatusBtn = React.forwardRef(
    ({ children, onClick }, ref) => {
      const [isOpen, setIsOpen] = useState(false);

      const handleToggleClick = (e) => {
        e.preventDefault();
        onClick(e);
        setIsOpen(!isOpen);
      };
      
      
      return (
        <button
          className={`p-3 rounded ${status} statusMenu__titleBtn`}
          ref={ref}
          onClick={handleToggleClick}
          disabled={reportDetail?.status?.current == 'draft' ? false : !selectAll}
        >
          {children}
          {
            <ArrowDown2
              className={`statusMenu__icon ${isOpen ? "toggled" : ""}`}
            />
          }
        </button>
      );
    }
  );
  const toggleObservations = () => {
    setActiveToast(activeToast === 'observations' ? null : 'observations');
    setEnable((prev) => ({
      observations: !prev.observations,
      messages: false,
      change_status: false,
    }));
    setCommentStatus("");
  };

  const toggleChangeStatus = () => {
    setActiveToast(activeToast === 'change_status' ? null : 'change_status');
    setEnable((prev) => ({
      change_status: !prev.change_status,
      messages: false,
      observations: false,
    }));
    setCommentStatus("");

  };


  const [isInputVisible, setIsInputVisible] = useState(false);

  const toggleInputVisibility = (e) => {
    e.preventDefault();
    setIsInputVisible(!isInputVisible);
  };


  return (
    <>
      {reportDetail?.status?.current !== 'draft'  ? (

        <div className="px-20">
          <hr />
          <div>
            <button
              onClick={toggleObservations}
              className={`rounded statusMenu__btnObservations ${enable.observations ? "active" : ""
                }`}
            >
              <small>{t("Conclusion.comments")}</small>
            </button>

            <button
              onClick={toggleChangeStatus}
              className={`rounded statusMenu__btnObservations ${enable.change_status ? "active" : ""
                }`}
            >
              <small>{t("Care.change_report_status")}</small>
            </button>



          </div>
          <div>
            {/* Observations about report status change  */}
            <Toast
              show={activeToast === 'observations'}
              onClose={toggleObservations}
              className="w-100 mt-3"
            >
              <Toast.Header className="m-2">
                <strong className="me-auto">{t("Care.status_comments")}:</strong>
              </Toast.Header>
              <Toast.Body className="status-comments">
                <MCInput
                  type="textarea"
                  rows="5"
                  value={commentStatus}
                  onChange={(e) => setCommentStatus(e.target.value)}
                  placeholder={t("Care.reasons_change_status")}
                />
              </Toast.Body>
              <div className="text-end p-3">
                <MCButton
                  label={t("Care.save")}
                  variant={
            "primary"
                  }
                  className="mt-3"
                  onClick={handleSaveClick}
                   disabled={status === reportDetail?.status?.current}
                />
              </div>
            </Toast>
          </div>







          <div>
            <Toast
              show={activeToast === 'change_status'}
              onClose={toggleChangeStatus}
              className="w-100 mt-3"
            >
              <Toast.Header className="m-2">
                <strong className="me-auto">{t("Care.change_report_status")}:</strong>
              </Toast.Header>
              <Toast.Body className="status-comments">
                {/* <h5 className="p-3 mb-0">{t("Care.change_report_status")}</h5> */}
                <h5 className="py-2 px-30">{t("Care.set_new_status")}:</h5>

                <div className="statusMenuContainer">
                  <Dropdown className="statusMenu p-10">
                    <Dropdown.Toggle as={changeReportStatusBtn}>
                      <div className="statusMenu__toggle">
                        <h5 className="mb-0">
                          {status
                            ? status === reportDetail?.status?.current
                              ? `${getStatus(status, status_locales)} (${t(
                                "Care.current_report_status"
                              )})`
                              : `${t("Care.change_status")} ${t("Care.to")} "${getStatus(
                                status,
                                status_locales
                              )}"`
                            : t("Care.select_option_update_report_status")}
                        </h5>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="statusMenu__menu">
                      {reportStatus.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            handleStatusChange(`${item.status}`);
                          }}
                          className="p-0 statusMenu__item"
                        >
                          <div
                            className={`statusMenu__option ${item?.status} ${reportDetail?.status.current === item.status
                              ? "selected"
                              : ""
                              }`}
                          >
                            <h6 className="mb-0">{`${item.label}`}</h6>
                            <small>{item.description}</small>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  {status && (
                    <div className="statusMenu__addComment">
                      <a
                        onClick={toggleInputVisibility}
                        className="addCommentLink"
                      >
                    {'+ ' + t("Care.add_comment_change_status")}                      
                    </a>
                      {isInputVisible && (
                        <MCInput
                          type="textarea"
                          rows="5"
                          value={commentStatus}
                          onChange={(e) => setCommentStatus(e.target.value)}
                          placeholder={t("Care.add_comment_change_status")}
                          className="commentInput"
                        />
                      )}
                    </div>
                  )}
                </div>





                <div className="text-end p-3">
                  <MCButton
                    label={t("Care.update_status")}
                    variant={
                      status === reportDetail?.status?.current ? "secondary" : "primary"
                    }
                    className="mt-3"
                    onClick={handleUpdateStatus}
                    // disabled={status === reportDetail?.status?.current}
                    // disabled={reportDetail?.status?.current == 'draft' ? false : !selectAll}
                    disabled={status === reportDetail?.status?.current || (reportDetail?.status?.current !== 'draft' && !selectAll)}

                  />
                </div>
              </Toast.Body>
            </Toast>
          </div>

          {/* Status Change Notification Banner */}
          {status !== null && status !== reportDetail?.status?.current && (
            <div className={`bg-${status} p-20 mt-20 rounded text-center`}>
              <small>{`${t("Care.alert_change")}`}</small>
              {/* <small className="d-flex align-items-center justify-content-center">
                &#8212;{`${alertChange[status]}`}
              </small> */}
            </div>
          )}

        </div>

      ) : (<>


        <h5 className="p-3 mb-0">{t("Care.change_report_status")}</h5>
        <small className="py-2 px-30">{t("Care.set_new_status")}:</small>

        <Dropdown className="statusMenu p-10">
          <Dropdown.Toggle as={changeReportStatusBtn}>
            <div className="statusMenu__toggle">
              <h5 className="mb-0">
                {status
                  ? status === reportDetail?.status?.current
                    ? `${getStatus(status, status_locales)} (${t(
                      "Care.current_report_status"
                    )})`
                    : `${t("Care.change_status")} ${t("Care.to")} "${getStatus(
                      status,
                      status_locales
                    )}"`
                  : t("Care.select_option_update_report_status")}
              </h5>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="statusMenu__menu">
            {reportStatus.map((item, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  handleStatusChange(`${item.status}`);
                }}
                className="p-0 statusMenu__item"
              >
                {
                  <div
                    className={`statusMenu__option ${item?.status} ${reportDetail?.status.current === item.status
                      ? "selected"
                      : ""
                      }`}
                  >
                    <h6 className="mb-0">{`${item.label}`}</h6>
                    <small>{item.description}</small>
                  </div>
                }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className="text-end p-3">
          <MCButton
            label={t("Care.update_status")}
            variant={
              status === reportDetail?.status?.current ? "secondary" : "primary"
            }
            className="mt-3"
            onClick={handleUpdateStatus}
          />
        </div>
         


      </>)}

      {/* <h5 className="p-3 mb-0">{t("Care.change_report_status")}</h5>
      <small className="py-2 px-30">{t("Care.set_new_status")}:</small>

      <Dropdown className="statusMenu p-10">
        <Dropdown.Toggle as={changeReportStatusBtn}>
          <div className="statusMenu__toggle">
            <h5 className="mb-0">
              {status
                ? status === reportDetail?.status?.current
                  ? `${getStatus(status, status_locales)} (${t(
                      "Care.current_report_status"
                    )})`
                  : `${t("Care.change_status")} ${t("Care.to")} "${getStatus(
                      status,
                      status_locales
                    )}"`
                : t("Care.select_option_update_report_status")}
            </h5>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="statusMenu__menu">
          {reportStatus.map((item, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                handleStatusChange(`${item.status}`);
              }}
              className="p-0 statusMenu__item"
            >
              {
                <div
                  className={`statusMenu__option ${item?.status} ${
                    reportDetail?.status.current === item.status
                      ? "selected"
                      : ""
                  }`}
                >
                  <h6 className="mb-0">{`${item.label}`}</h6>
                  <small>{item.description}</small>
                </div>
              }
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown> */}
      {/* <div className="text-end p-3">
        <MCButton
          label={t("Care.update_status")}
          variant={
            status === reportDetail?.status?.current ? "secondary" : "primary"
          }
          className="mt-3"
          onClick={handleUpdateStatus}
          disabled={status === reportDetail?.status?.current}
        />
      </div> */}
    </>
  );
};
