import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  getOffendersListByReportId,
  getReportDetail,
  getVictimsListByReportId,
  updateSanctions,
  createAttachment,
} from "../../../../apis";
import {
  parseDateToDDMMYYYY2,
  validInitDates,
} from "../../../../helpers/dateTransform";
import { useSnackbar } from "notistack";

const useResolutions = ({ itemToDelete, closeModal }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("report");
  const [loading, setLoading] = useState(false);
  // is editing?
  const [editResolution, setEditResolution] = useState(false);
  // General resolution information from the API
  const [resolutions, setResolutions] = useState([]);
  // This is an updated merger of the content in the resolution and the parties involved
  const [resolutionsUpdated, setResolutionsUpdated] = useState([]);
  // -----> Corrective & preventive measures <----- //
  const [measures, setMeasures] = useState([]);
  // -----> Sanctions <----- //
  const [offenders, setOffenders] = useState([]);
  const [sanctions, setSanctions] = useState([]);
  // -----> Remunerations & compensations <-----//
  const [victims, setVictims] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  // -----> General comments & files about closing report <----- //
  const [comment, setComment] = useState("");
  const [observations, setObservations] = useState("");
  const [files, setFiles] = useState([]);
  const [filesToRender, setFilesToRender] = useState([]);
  const [date, setDate] = useState("");

  // Get info from DataBase
  useEffect(() => {
    getResolutions();
    getInvolvedList();
  }, []);
  // Merging to update the data involved and resolution
  useEffect(() => {
    if (resolutions && resolutions.sanctions && offenders?.length > 0) {
      const updatedSanctions = resolutions.sanctions.map((sanction) => {
        const userOffender = offenders.find(
          (offn) => offn.name === sanction.name
        );
        return userOffender ? { ...sanction, ...userOffender } : sanction;
      });
      setResolutionsUpdated({ ...resolutions, sanctions: updatedSanctions });
    } else if (resolutions && resolutions.benefits && victims?.length > 0) {
      const updatedSanctions = resolutions.beneficiaries.map((benefit) => {
        const userVictim = victims.find(
          (victms) => victms.name === benefit.name
        );
        return userVictim ? { ...beneficiaries, ...userVictim } : benefit;
      });
      setResolutionsUpdated({
        ...beneficiaries,
        beneficiaries: updatedSanctions,
      });
    }
  }, [resolutions, offenders, victims]);

  // Corrective & preventive options
  const measureOptions = [
    {
      label: t("Resolution.internal_process_modifications"),
      value: "internal_process_modifications",
    },
    {
      label: t("Resolution.acquisition_equipment_materials"),
      value: "acquisition_equipment_materials",
    },
    { label: t("Resolution.repairs"), value: "repairs" },
    { label: t("Resolution.hiring"), value: "hiring" },
    { label: t("Resolution.compensations"), value: "compesations" },
    {
      label: t("Resolution.dismissal_third_party_employees"),
      value: "dismissal_third_party_employees",
    },
    { label: t("Resolution.another"), value: "another" },
  ];
  // Sanctions options
  const sanctionsOptions = [
    { label: t("Resolution.verbal_warning"), value: "verbal_reprimand" },
    {
      label: t("Resolution.written_warning"),
      value: "documental_reprimand",
    },
    { label: t("Resolution.economic_sanction"), value: "economic_reprimand" },
    {
      label: t("Resolution.performance_improvement_plans"),
      value: "performance_improvement_plans",
    },
    {
      label: t("Resolution.temporary_suspension"),
      value: "temporary_suspension",
    },
    { label: t("Resolution.dismissal"), value: "dismisal" },
    {
      label: t("Resolution.commencement_legal_proceedings"),
      value: "commencement_legal_proceedings",
    },
    {
      label: t("Resolution.area_or_shift_change"),
      value: "area_or_shift_change",
    },
    {
      label: t("Resolution.none_apply"),
      value: "none_apply",
    },
    { label: t("Resolution.another"), value: "another" },
  ];
  // Benefits options
  const benefitOptions = [
    { label: t("Resolution.monetary"), value: "monetary" },
    { label: t("Resolution.in_kind"), value: "in_kind" },
    { label: t("Resolution.employee_benefits"), value: "employee_benefits" },
    { label: t("Resolution.congratulations"), value: "congratulations" },
    { label: t("Resolution.another"), value: "another" },
  ];
  // Set data from dynamic selections
  const hdlSelectResolutionChange = (idx, selection, resolutionName) => {
    // Set Corrective and preventive measures
    if (resolutionName === "measures") {
      const newMeasure = [...measures];
      if (selection.value === "another") {
        newMeasure[idx] = {
          ...newMeasure[idx],
          measure: selection.value,
          another_measure: "",
        };
      } else {
        const { another_measure, ...rest } = newMeasure[idx];
        newMeasure[idx] = { ...rest, measure: selection.value };
      }
      setMeasures(newMeasure);
    }
    // Set Involved People
    if (resolutionName === "involved") {
      const newInvolved = [...sanctions];
      newInvolved[idx] = {
        ...newInvolved[idx],
        additional: selection.additional,
        confirm: selection.confirm,
        details: selection.details,
        kind: selection.kind,
        name: selection.name,
        relationship: selection.relationship,
        rol: selection.rol,
      };
      setSanctions(newInvolved);
    }
    //     Set Sanctions
    if (resolutionName === "sanctions") {
      const newSanction = [...sanctions];
      const currentSanction = newSanction[idx];
      if (selection.includes("another")) {
        newSanction[idx] = {
          ...currentSanction,
          sanctions: selection,
          another_sanction: currentSanction.another_sanction || "",
        };
      } else {
        const { another_sanction, ...rest } = currentSanction;
        newSanction[idx] = { ...rest, sanctions: selection };
      }
      setSanctions(newSanction);
    }
    // Set Beneficiaries
    if (resolutionName === "beneficiaries") {
      const newBeneficiarie = [...beneficiaries];
      newBeneficiarie[idx] = {
        ...newBeneficiarie[idx],
        additional: selection.additional,
        confirm: selection.confirm,
        details: selection.details,
        kind: selection.kind,
        name: selection.name,
        relationship: selection.relationship,
        rol: selection.rol,
      };
      setBeneficiaries(newBeneficiarie);
    }
    // Set benefits
    if (resolutionName === "benefits") {
      const newBenefit = [...beneficiaries];
      const currentBenefits = newBenefit[idx];
      if (selection.includes("another")) {
        newBenefit[idx] = {
          ...currentBenefits,
          benefits: selection,
          another_benefit: currentBenefits.another_benefit || "",
        };
      } else {
        const { another_benefit, ...rest } = currentBenefits;
        newBenefit[idx] = { ...rest, benefits: selection };
      }
      setBeneficiaries(newBenefit);
    }
  };
  // Set details of resolution data
  const hdlDetailsChange = (idx, e, resolutionName) => {
    const { value } = e.target;
    if (resolutionName === "measures") {
      const newMeasureDetails = [...measures];
      newMeasureDetails[idx].comment = value;
      setMeasures(newMeasureDetails);
    }
    if (resolutionName === "sanctions") {
      const newInvolvedDetails = [...sanctions];
      newInvolvedDetails[idx].comment = value;
      setSanctions(newInvolvedDetails);
    }
    if (resolutionName === "benefits") {
      const newBenefictDetails = [...beneficiaries];
      newBenefictDetails[idx].comment = value;
      setBeneficiaries(newBenefictDetails);
    }
  };
  // Set data if another option was selected
  const hdlAnoterOptionChange = (i, e, resolutionName) => {
    const { value } = e.target;
    if (resolutionName === "measures") {
      const newMeasure = [...measures];
      newMeasure[i].another_measure = value;
      setMeasures(newMeasure);
    }
    if (resolutionName === "sanctions") {
      const newSanction = [...sanctions];
      newSanction[i] = {
        ...newSanction[i],
        another_sanction: value,
      };
      setSanctions(newSanction);
    }
    if (resolutionName === "benefits") {
      const newBenefit = [...beneficiaries];
      newBenefit[i].another_benefit = value;
      setBeneficiaries(newBenefit);
    }
  };
  // Sets resolution info from DataBase
  const getResolutions = async () => {
    await getReportDetail(id).then((resp) => {
      const { resolution } = resp;
      setComment(resolution.comment || "");
      setFilesToRender(resolution.files || []);
      setMeasures(resolution.measures || []);
      setObservations(resolution.observations || "");
      setResolutions(resolution || []);
      setSanctions(resolution.sanctions || []);
      setBeneficiaries(resolution.beneficiaries || []);
      setDate(
        validInitDates(new Date(resolution.date))
          ? new Date(resolution.date)
          : new Date()
      );
    });
  };
  // Get involved people
  const getInvolvedList = async () => {
    //Offenders
    await getOffendersListByReportId(id).then((offendersApi) => {
      setOffenders(offendersApi);
    });
    //Victims
    await getVictimsListByReportId(id).then((victimsApi) => {
      setVictims(victimsApi);
    });
  };
  // Filter of offender people selected
  const getAvailableOffenders = (currentIdx) => {
    const selectedOffenders = sanctions
      .filter((_, idx) => idx !== currentIdx)
      .map((sanction) => sanction.name);
    return offenders?.filter(
      (offender) => !selectedOffenders.includes(offender.name)
    );
  };
  // Add a further sanction if persons involved are available
  const addMoreSanction = getAvailableOffenders().length > 0;
  // Filter of victims (beneficiaries) selected
  const getAvailableVictims = (currentIdx) => {
    const selectBeneficiaries = beneficiaries
      .filter((_, idx) => idx !== currentIdx)
      .map((beneficiary) => beneficiary.name);
    return victims?.filter(
      (beneficiary) => !selectBeneficiaries.includes(beneficiary.name)
    );
  };
  // Add a further if beneficiaries are available
  const addMoreBeneficiaries = getAvailableVictims()?.length !== 0;
  // ---> General dinamyc functions <--- //
  // Add a new resolution
  const hdlAddResolution = (resolutionName) => {
    if (resolutionName === "measures") {
      setMeasures([
        ...measures,
        { measure: "", another_measure: "", comment: "" },
      ]);
    }
    if (resolutionName === "sanctions") {
      setSanctions([
        ...sanctions,
        {
          additional: "",
          another_sanction: "",
          comment: "",
          confirm: "",
          details: "",
          kind: "",
          name: "",
          relationship: "",
          rol: "",
          sanctions: [],
        },
      ]);
    }
    if (resolutionName === "benefits") {
      setBeneficiaries([
        ...beneficiaries,
        {
          additional: "",
          another_benefit: "",
          comment: "",
          confirm: "",
          details: "",
          kind: "",
          name: "",
          relationship: "",
          rol: "",
          benefits: [],
        },
      ]);
    }
  };
  // Delete an especific resolution
  const hdlRemoveResolution = (e) => {
    const { idx, resolutionName } = itemToDelete;
    if (resolutionName === "measures") {
      const updateMeasure = measures.filter((_, i) => i !== idx);
      setMeasures(updateMeasure);
    }
    if (resolutionName === "sanctions") {
      const updateSanction = sanctions.filter((_, i) => i !== idx);
      setSanctions(updateSanction);
    }
    if (resolutionName === "benefits") {
      const uploadBenefit = beneficiaries.filter((_, i) => i !== idx);
      setBeneficiaries(uploadBenefit);
    }
    closeModal();
  };
  // -----> Data validation <-----//
  // Checks if the array is not empty
  const isArrayOfEmptyObjects = (arr) => {
    // Verificar si 'arr' es un arreglo
    if (!Array.isArray(arr)) {
      return false;
    }
    // Verificar si todos los elementos del arreglo son objetos vacíos
    return arr.every(
      (obj) =>
        obj && Object.keys(obj)?.length === 0 && obj.constructor === Object
    );
  };
  // Required fields are duly filled in
  const isFormValid = (resolution) => {
    if (resolution === "measures") {
      return measures.some(
        (measure) =>
          !measure.measure ||
          (measure.measure === "another" &&
            measure.another_measure.trim() === "")
      );
    }
    if (resolution === "sanctions") {
      return (
        sanctions?.some((s) => s.name === "") ||
        sanctions?.some((s) => isArrayOfEmptyObjects(s.sanctions)) ||
        sanctions?.some(
          (s) =>
            s.sanctions?.includes("another") &&
            (!s.another_sanction || s.another_sanction.trim() === "")
        )
      );
    }
    if (resolution === "benefits") {
      return (
        beneficiaries?.some((b) => b.name === "") ||
        beneficiaries?.some((b) => isArrayOfEmptyObjects(b.benefits)) ||
        beneficiaries?.some(
          (b) =>
            b.benefits?.includes("another") &&
            (!b.another_benefit || b.another_benefit.trim() === "")
        )
      );
    }
    return false; // Return false if none of the conditions are met
  };

  // Auxiliary when selecting “another option"
  const isValid = (idx, resolution) => {
    if (resolution === "sanctions") {
      return (
        !sanctions[idx].sanctions.includes("another") ||
        (sanctions[idx]?.another_sanction !== undefined &&
          sanctions[idx]?.another_sanction?.trim() !== "")
      );
    }
    if (resolution === "benefits") {
      return (
        !beneficiaries[idx].benefits.includes("another") ||
        (beneficiaries[idx]?.another_benefit !== undefined &&
          beneficiaries[idx]?.another_benefit?.trim() !== "")
      );
    }
    return false; // Return false if none of the conditions are met
  };

  // -----> Data sending <----- //
  const sendResolutionData = async (e) => {
    e.preventDefault();
    let files_id;
    let filesToSend = [];
    setLoading(true);
    try {
      if (files?.length > 0) {
        for (const fileObj of files) {
          const { formData } = fileObj;
          const filesRes = await createAttachment(formData);
          files_id = filesRes.attachments_id;
          filesToSend.push({
            file: files_id,
            name: fileObj.file.name,
          });
        }
      }
      // add current files
      if (filesToRender && filesToRender?.length > 0) {
        filesToRender.forEach((file) => {
          filesToSend.push({
            file: file.file,
            name: file.name,
          });
        });
      }
      const resolution = {
        beneficiaries,
        comment,
        date,
        files: filesToSend,
        measures,
        observations,
        sanctions,
      };
      const resp = await updateSanctions(id, resolution).then((resp) => {
        if (resp.status === 200) {
          enqueueSnackbar(t("Resolution.data_successfully_updated"), {
            variant: "success",
          });
          setLoading(false);
          setEditResolution(false);
          getResolutions();
          setFiles([]);
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return {
    // General Info from Api
    resolutionsUpdated,
    // >>> Status change comments
    observations,
    setObservations,
    // >>> Corrective & preventive measures
    measures,
    measureOptions,
    // >>> Sanctions
    sanctions,
    setSanctions,
    offenders,
    sanctionsOptions,
    // >>> Remunerations & compensations
    beneficiaries,
    victims,
    benefitOptions,
    // >>> General comments & files closing report
    comment,
    setComment,
    files,
    setFiles,
    filesToRender,
    setFilesToRender,
    date,
    setDate,
    // >>> Add new resolution
    hdlAddResolution,
    // >>> Delete a resolution
    hdlRemoveResolution,
    // >>> Dynamic Selections
    hdlSelectResolutionChange,
    getAvailableOffenders,
    getAvailableVictims,
    // >>> Sanctions limits
    addMoreSanction,
    // >>> Beneficiaries limit
    addMoreBeneficiaries,
    // >>> Details in resolutions
    hdlDetailsChange,
    // >>> If another option was selected
    hdlAnoterOptionChange,
    // >>> Dynamic form validation
    isFormValid,
    // >>> Dynamic data validation
    isValid,
    // >>> Send data to endopint
    sendResolutionData,
    //loading?
    loading,
    editResolution,
    setEditResolution,
    // uploadInfo
    getResolutions,
  };
};

export default useResolutions;
